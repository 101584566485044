/* eslint-disable no-nested-ternary */
import { Badge, Button, Carousel } from 'flowbite-react';
import React from 'react';

import { Typography } from '@mui/material';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';
import { countFileTypes, imageDisplay } from 'utils/helpers/imageFinder';
import { downloadFilesAsBase64 } from 'utils/helpers/zipDownloader';
import { IProduct } from 'utils/Interfaces/products.interface';

const ProdWithVariants: React.FC<{ product: IProduct }> = ({ product }) => {
    const results = Object.keys(countFileTypes(product?.design || [])).map((key) => (
        <div key={key} className=" border-[0.3px] dark:border-gray-800 border-gray-300 flex gap-1 p-2 ">
            <p>{key.toLocaleUpperCase()}:</p>
            <p>{countFileTypes(product?.design as [])[key]}</p>
        </div>
    ));

    return (
        <>
            <Badge color="info" className="inline-block">
                Product is not editable anymore
            </Badge>
            <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 mt-5">
                <div className="flex justify-center " key="carouselforrodvari">
                    <div className=" sticky top-0 w-[270px] h-[270px]">
                        <Carousel
                            className=""
                            leftControl={<BsFillArrowLeftCircleFill className=" w-9 h-9 text-[#ffffff8c]" />}
                            rightControl={<BsFillArrowRightCircleFill className=" w-9 h-9 text-[#ffffff8c]" />}
                            theme={{
                                indicators: {
                                    active: {
                                        off: 'bg-black/30 hover:bg-black/40 dark:bg-gray-800/50 dark:hover:bg-gray-800',
                                        on: ' bg-black/50 dark:bg-gray-800',
                                    },
                                    base: 'h-1 w-3 rounded',
                                    wrapper: 'absolute bottom-2 left-1/2 flex -translate-x-1/2 space-x-2 ',
                                },
                            }}
                        >
                            {imageDisplay(product?.design)}
                        </Carousel>
                        <div className="flex gap-1 justify-center mt-1">{results}</div>
                        {product.design && product.design.length > 0 ? (
                            <div className="mt-1 flex justify-center">
                                <Button onClick={() => downloadFilesAsBase64(product?.design, product?.name)}>
                                    Download all Files
                                </Button>
                            </div>
                        ) : (
                            <div className="mt-1 flex justify-center">
                                <Button disabled>No files attached to download</Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:col-span-2 flex flex-col gap-10 ">
                    <div className="flex flex-col gap-5">
                        <h4>{product.name}</h4>

                        <div className="flex gap-2">
                            <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
                                Product link:
                            </Typography>
                            <div className="max-w-[500px] overflow-hidden whitespace-nowrap">
                                <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
                                    <a
                                        href={product.refrenceLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-blue-500"
                                    >
                                        {product.refrenceLink ?? '--'}
                                    </a>
                                </Typography>
                            </div>
                        </div>

                        <div>
                            <Typography variant="subtitle2">Description: </Typography>
                            <div className="text-sm font-normal dark:text-[#F2F0F2]">
                                {product?.description ?? 'No description provided'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="mb-2">
                            <Typography variant="subtitle2">Product specifications:</Typography>
                        </div>

                        <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {product?.variants &&
                                product.variants.length > 0 &&
                                (product.variants as IProduct[]).map((variant) => (
                                    <>
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border ">
                                            <tbody>
                                                <tr className=" ">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Variant
                                                    </th>
                                                    <td className="">{variant?.name}</td>
                                                </tr>
                                                {/* <tr className="">
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Sku
                                                </th>
                                                <td className="">
                                                    <p className="text-xs">{variant?.globalSKU ?? '--'}</p>
                                                </td>
                                            </tr> */}
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Quantity
                                                    </th>
                                                    <td className="">{variant?.quantity ?? '--'}</td>
                                                </tr>
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Height
                                                    </th>
                                                    <td className="">{variant?.productHeight ?? '--'} CM</td>
                                                </tr>
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Width
                                                    </th>
                                                    <td className="">{variant?.productWidth ?? '--'} CM</td>
                                                </tr>
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Length
                                                    </th>
                                                    <td className="">{variant?.productLength ?? '--'} CM</td>
                                                </tr>
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Weight
                                                    </th>
                                                    <td className="">{variant?.productWeight ?? '--'} KG</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                ))}
                        </div>
                    </div>
                    <div>
                        <div className="mb-2">
                            <Typography variant="subtitle2">Product Stock configuration:</Typography>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 mb-2">
                            {product.stockConfigs &&
                                product.stockConfigs.length > 0 &&
                                product.stockConfigs.map((prodStock) => (
                                    <div className="border dark:border-[#6e6e6ea4] p-2  ">
                                        <div className="flex items-center gap-4">
                                            <p>Variant</p>
                                            <Typography variant="subtitle2">{prodStock?.variant}</Typography>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <p>Country</p>
                                            <Typography variant="subtitle2">{prodStock?.country}</Typography>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <p>Quantity</p>
                                            <Typography variant="subtitle2">{prodStock?.quantity}</Typography>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProdWithVariants;
