import * as Yup from 'yup';

const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d-]([a-z\\d-]*[a-z\\d-])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
);

export const addStoreSchema = (storeType: any) => {
    if (storeType === 'shopify')
        return Yup.object().shape({
            storeName: Yup.string().required('store name is required'),
            storeType: Yup.string().required('store type is required'),
            // storeLink: Yup.string().required('store link is required').url('Invalid Link format'),
            storeLink: Yup.string().matches(pattern, 'Invalid Link format').required('Store link is required'),
            accessToken: Yup.string().required('access token is required'),
            withCC: Yup.boolean().required(),
        });
    if (storeType === 'lightfunnels')
        return Yup.object().shape({
            storeName: Yup.string().required('store name is required'),
            storeType: Yup.string().required('store type is required'),
            storeLink: Yup.string().matches(pattern, 'Invalid Link format').required('Store link is required'),
            funnel: Yup.string().required('Select a funnel to match (funnel is required)'),
            withCC: Yup.boolean().required(),
        });
    return Yup.object().shape({
        storeName: Yup.string().required('store name is required'),
        storeType: Yup.string().required('store type is required'),
        storeLink: Yup.string().matches(pattern, 'Invalid Link format').required('Store link is required'),
        accessToken: Yup.string().optional(),
        withCC: Yup.boolean().required(),
    });
};
