/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import SourcingContainer from 'components/sourcingContainer/SourcingContainer';
import { useNavigate } from 'react-router-dom';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import { ColorModeContext } from 'utils/contexts/theme';
import Package from '../../assets/package.png';
import Shopping from '../../assets/shoppingbag.png';
import Label from '../../assets/label.png';
import LabelWhite from '../../assets/labelWhite.png';
import PackageWhite from '../../assets/packageWhite.png';
import ShoppingWhite from '../../assets/shoppingbagWhite.png';

const SourcingType = () => {
    const navigate = useNavigate();

    const colorMode = useContext(ColorModeContext);

    DynamicTitle('Sourcing');

    return (
        <div className="h-full">
            <div>
                <div className="text-2xl font-popin">Pick Your Sourcing & Shipping Model</div>
                {/* <Box sx={sourcingTypeDescription}>hello</Box> */}
            </div>
            <div className="h-full flex items-center justify-between">
                <div className=" flex w-full justify-center gap-10 flex-wrap">
                    <SourcingContainer
                        icon={colorMode.mode === true ? ShoppingWhite : Shopping}
                        buttonLabel="Dropshipping"
                        onClick={() => {}}
                        disabled
                        description="Small parcels from China to anywhere in the world."
                    />
                    <SourcingContainer
                        icon={colorMode.mode === true ? PackageWhite : Package}
                        buttonLabel="COD Dropshipping"
                        onClick={() => {}}
                        description="Small parcels from China to the Middle East."
                        disabled
                    />
                    <SourcingContainer
                        icon={colorMode.mode === true ? LabelWhite : Label}
                        buttonLabel="Logistio Bulk"
                        onClick={() => navigate('/sourcing-progress', { state: { sourcing: 'logistioBulk' } })}
                        description="Bulk Orders from China to any of Logistio warehouses around the world."
                    />
                    <SourcingContainer
                        icon={colorMode.mode === true ? PackageWhite : Package}
                        buttonLabel="Standard Bulk"
                        onClick={() => {}}
                        description="Bulk Orders from China to any 3PL."
                        disabled
                    />
                    <SourcingContainer
                        icon={colorMode.mode === true ? PackageWhite : Package}
                        buttonLabel="Amazon bulk"
                        onClick={() => {}}
                        description="Bulk Orders From China to Amazon (including Amazon Prep)."
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};
export default SourcingType;
