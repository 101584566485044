/* eslint-disable no-plusplus */
import { IxlsxOrder } from 'services/order';
import { logistioCountries } from 'utils/countryList';
import { WorkSheet, utils, write, writeFile } from 'xlsx';
import vnlinCities from '../vnlin/vnlinCities.json';

type DownloadOrderTemplateFields = Omit<IxlsxOrder, 'orderRef'> & {
    orderNumber: string;
};

export const orderFileHeader: DownloadOrderTemplateFields = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    country: '',
    countryCode: '',
    province: '',
    provinceCode: '',
    city: '',
    zip: '',
    // company: '',
    phone: '',
    sku: '',
    quantity: 0,
    totalPrice: 0,
    currency: '',
    // orderRef: '',
    orderNumber: '',
};

// Downloads xlsx file containing 1 order of multiple items with dummy data (this exemple contains merged rows)
export const downloadCODOrderTemplateFile = () => {
    // create worksheet with dummy data
    const itemsCount = 3;
    // const orderExemple: DownloadOrderTemplateFields[] = [];
    const orderExemple: any[] = [];
    for (let i = 0; i < itemsCount; i++) {
        orderExemple.push({
            'first name (required)': `customer${i + 1} first name`,
            'last name (required)': `customer${i + 1} last name`,
            'address 1': `address 1`,
            'address 2': `address 2`,
            'country (required)': `destination country`,
            'country code': `destination country code`,
            province: `province`,
            'province code': `province code`,
            city: `city name`,
            zip: `zip code`,
            // company: `company name`,
            'phone (required)': `customer phone number`,
            'sku (required)': `product sku`,
            'quantity (required)': 0,
            'total price (required)': '0',
            'currency (required)': '',
            // orderRef: 'custome order ref',
            'order number (required)': 'custome order number',
        });
    }
    const worksheet = utils.json_to_sheet(orderExemple);

    worksheet['!merges'] = [];
    // Merge rows of customer data
    for (let i = 0; i < 11; i++) {
        worksheet['!merges']?.push({
            s: {
                c: i,
                r: 1,
            },
            e: {
                c: i,
                r: itemsCount,
            },
        });
    }
    // merge total quantity cells
    worksheet['!merges']?.push({
        s: {
            c: 13,
            r: 1,
        },
        e: {
            c: 13,
            r: itemsCount,
        },
    });
    // merge currency cells
    worksheet['!merges']?.push({
        s: {
            c: 14,
            r: 1,
        },
        e: {
            c: 14,
            r: itemsCount,
        },
    });
    // merge orderRef cells
    worksheet['!merges']?.push({
        s: {
            c: 15,
            r: 1,
        },
        e: {
            c: 15,
            r: itemsCount,
        },
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'logistio-orders-template');
    write(workbook, { bookType: 'xlsx', type: 'binary' });
    writeFile(workbook, 'logistio-orders-template.xlsx');
};

export const downloadPrePaidOrderTemplateFile = () => {
    // create worksheet with dummy data
    const itemsCount = 3;
    // const orderExemple: DownloadOrderTemplateFields[] = [];
    const orderExemple: any[] = [];
    for (let i = 0; i < itemsCount; i++) {
        orderExemple.push({
            'first name (required)': `customer${i + 1} first name`,
            'last name (required)': `customer${i + 1} last name`,
            'address 1': `address 1`,
            'address 2': `address 2`,
            'country (required)': `destination country`,
            'country code': `destination country code`,
            province: `province`,
            'province code': `province code`,
            'city (required)': `city name`,
            zip: `zip code`,
            // company: `company name`,
            'phone (required)': `customer phone number`,
            'sku (required)': `product sku`,
            'quantity (required)': 0,
            'order number (required)': 'custome order number',
        });
    }
    const worksheet = utils.json_to_sheet(orderExemple);

    worksheet['!merges'] = [];
    // Merge rows of customer data
    for (let i = 0; i < 11; i++) {
        worksheet['!merges']?.push({
            s: {
                c: i,
                r: 1,
            },
            e: {
                c: i,
                r: itemsCount,
            },
        });
    }
    // merge orderRef cells
    worksheet['!merges']?.push({
        s: {
            c: 13,
            r: 1,
        },
        e: {
            c: 13,
            r: itemsCount,
        },
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'logistio-orders-template');
    write(workbook, { bookType: 'xlsx', type: 'binary' });
    writeFile(workbook, 'logistio-orders-template.xlsx');
};

export const downloadXLSXFile = (data: WorkSheet, fileName: string) => {
    const workbook = utils.book_new();
    // Sheet names cannot exceed 31 chars (xlsx requirements)
    let failedFileName = `(failed)-${fileName}.xlsx`;
    if (failedFileName.length > 27) {
        failedFileName = '(failed)-logistio-orders.xlsx';
    }
    utils.book_append_sheet(workbook, data, failedFileName);
    write(workbook, { bookType: 'xlsx', type: 'binary' });
    writeFile(workbook, failedFileName);
};

export const downloadCountriesListFile = () => {
    const countriesList = logistioCountries.map((country) => ({
        label: country.label,
        code: country.code,
        phone: country.phone,
        currency: country.currency,
    }));
    const ws = utils.json_to_sheet(countriesList);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'logistio-countries-list');
    write(wb, { bookType: 'xlsx', type: 'binary' });
    writeFile(wb, 'logistio-countries-list.xlsx');
};

export const downloadCitiesListFile = () => {
    const citiesList: { country: string; city: string }[] = [];
    Object.entries(vnlinCities).forEach(([country, cities]) => {
        cities.forEach((city) => {
            citiesList.push({
                country,
                city: city.label1,
            });
            if (city.label2 && city.label2.length > 0) {
                citiesList.push({
                    country,
                    city: city.label2,
                });
            }
        });
    });
    const ws = utils.json_to_sheet(citiesList);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'logistio-cities-list');
    write(wb, { bookType: 'xlsx', type: 'binary' });
    writeFile(wb, 'logistio-cities-list.xlsx');
};
