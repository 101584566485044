/* eslint-disable react/button-has-type */
import { Box } from '@mui/material';
import { Avatar } from 'flowbite-react';
import React from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import { chatFilesDisplay, uploadingFileDisplay } from 'utils/helpers/imageFinder';
import { MessageFileWrapper } from './MessageFile.styles';

const MessageFile: React.FC<{
    file: string;
    fileName: string;
    fileSize: number;
    received: boolean;
    entity?: {
        firstName: string;
        lastName: string;
        email: string;
        profileImage?: string;
    };
    timestamp?: string;
    uploading?: boolean;
    progressCount?: number;
}> = ({ entity, file, received, timestamp, fileName, fileSize, uploading, progressCount }) => {
    const getMessageTimeStamp = (ts: string) => {
        const isSameDay = new Date(ts).toDateString() === new Date().toDateString();
        if (isSameDay) {
            return new Date(ts).toLocaleTimeString();
        }
        return new Date(ts).toLocaleString();
    };

    return (
        <>
            {uploading && progressCount ? (
                <Box style={MessageFileWrapper(received)}>
                    <div className={`flex flex-col gap-2 max-w-[50%] w-fit ${received ? 'items-start' : 'items-end'}`}>
                        {uploadingFileDisplay(fileName, fileSize, progressCount)}
                    </div>
                </Box>
            ) : (
                <Box style={MessageFileWrapper(received)}>
                    {entity && received && <Avatar img={entity?.profileImage ?? ''} size="md" rounded />}
                    <div className={`flex flex-col gap-2 max-w-[50%] w-fit ${received ? 'items-start' : 'items-end'}`}>
                        {chatFilesDisplay(file, fileName || 'Logistio', fileSize)}

                        {timestamp && (
                            <p className="opacity-70 text-[10px] font-normal">{getMessageTimeStamp(timestamp)}</p>
                        )}
                    </div>
                    {/* {entity && !received && <Avatar img={getUser().profileImage ?? ''} size="md" rounded />} */}
                </Box>
            )}
        </>
    );
};

export default MessageFile;
