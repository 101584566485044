import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';
import { downloadXLSXFile } from 'utils/helpers/orders/downloadXLSXutilsFiles';
import { WorkSheet } from 'xlsx';
import CsvLog from '../../assets/csv-file.png';

const OrderUpload = ({
    uploadCounter,
    failedOrders,
    uploadErrors,
    fileName,
}: {
    uploadCounter: { errors: number; success: number; total: number };
    failedOrders: WorkSheet;
    uploadErrors: Map<
        string,
        {
            orderCustomerFullName: string;
            errors: string[];
        }
    >;
    fileName: string;
}) => {
    const uploadErrorsArr = Array.from(uploadErrors).map((err) => err[1]);
    return (
        <div>
            <h3 style={{ marginBottom: 20 }}>Upload orders</h3>
            {/* progress */}
            <div
                // style={{
                //     display: 'flex',
                //     alignItems: 'center',
                //     justifyContent: 'space-between',
                //     gap: 18,
                //     padding: 10,
                //     border: '1px solid #ececec',
                //     borderRadius: 8,
                // }}
                className="flex items-center justify-center p-2 border border-[#ececec] dark:border-[#616161] rounded-lg gap-3"
            >
                <div
                    style={{
                        width: 56,
                        height: 66,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                        border: 'solid 1px #ececec',
                        objectFit: 'contain',
                    }}
                >
                    <img
                        src={CsvLog}
                        alt="csv-file-log"
                        style={{
                            width: 46,
                            objectFit: 'contain',
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: 9,
                    }}
                >
                    <p>{fileName}</p>
                    <progress
                        style={{ width: 450 }}
                        id="upload-progress"
                        value={uploadCounter.success + uploadCounter.errors}
                        max={uploadCounter.total}
                    />
                    {/* stats */}

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 30,
                            fontSize: 12,
                            color: '#505050',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 5,
                            }}
                        >
                            <p>Success:</p>
                            <p>{uploadCounter.success}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 5,
                            }}
                        >
                            <p>Failed:</p>
                            <p>{uploadCounter.errors}</p>
                        </div>
                        <p>
                            {uploadCounter.total
                                ? ((uploadCounter.errors + uploadCounter.success) * 100) / uploadCounter.total
                                : 0}
                            %
                        </p>
                    </div>
                </div>
            </div>
            {/* details */}
            {uploadErrorsArr.length > 0 && uploadCounter.errors + uploadCounter.success === uploadCounter.total && (
                <>
                    <div className="bg-[#f9f9fb] dark:bg-[#0D1117] border border-[#ececec] dark:border-[#616161] rounded-lg p-2 mt-5 flex flex-col items-center justify-center gap-2 text-sm ">
                        <ErrorIcon style={{ color: 'red' }} />
                        <p style={{ fontSize: 17 }}>Upload completed with errors!</p>
                        {uploadCounter.success > 0 && (
                            <p>
                                Uploaded {uploadCounter.success} of {uploadCounter.total} successfully!
                            </p>
                        )}
                        {failedOrders && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    downloadXLSXFile(failedOrders, fileName);
                                }}
                            >
                                Download failed orders
                            </Button>
                        )}
                    </div>
                    <div
                        // sx={{
                        //     border: '1px solid #ececec',
                        //     background: '#f9f9fb',
                        //     borderRadius: '8px',
                        //     padding: '10px',
                        //     marginTop: '20px',
                        //     display: 'flex',
                        //     flexDirection: 'column',
                        //     alignItems: 'center',
                        //     justifyContent: 'center',
                        //     gap: '7px',
                        // }}
                        className="bg-[#f9f9fb] dark:bg-[#0D1117] border border-[#ececec] dark:border-[#616161] rounded-lg p-2 mt-5 flex flex-col items-center justify-center gap-2 text-sm "
                    >
                        <p>Errors:</p>
                        <ul
                            style={{
                                listStyleType: 'disc',
                                padding: '10px',
                                maxHeight: '500px',
                                overflowY: 'scroll',
                                // borderBottom: '1px solid',
                            }}
                        >
                            {uploadErrorsArr.map((error, _i) => (
                                <>
                                    <li
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${_i}${error.orderCustomerFullName}`}
                                        style={{
                                            fontSize: 'small',
                                            opacity: 0.7,
                                            margin: '5px 0px',
                                        }}
                                    >
                                        {`Errors in order for customer ${error.orderCustomerFullName}:`}
                                    </li>
                                    <ul
                                        style={{
                                            listStyleType: 'disc',
                                            padding: '0px 15px',
                                        }}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${_i + uploadErrorsArr.length}${error.orderCustomerFullName}`}
                                    >
                                        {error.errors.map((errorDescription, subIdx) => (
                                            <li
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`${subIdx}${errorDescription}`}
                                                style={{
                                                    fontSize: 'small',
                                                    opacity: 0.7,
                                                    margin: '5px 0px',
                                                }}
                                                className="text-red-700 dark:text-red-500"
                                            >
                                                {errorDescription}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ))}
                        </ul>
                    </div>
                </>
            )}
            {uploadCounter.success > 0 && uploadCounter.success === uploadCounter.total && (
                <div className="bg-[#f9f9fb] dark:bg-[#0D1117] border border-[#ececec] dark:border-[#616161] rounded-lg p-2 mt-5 flex flex-col items-center justify-center gap-2 text-sm">
                    <CheckCircleIcon style={{ color: 'green' }} />
                    <p>Upload completed!</p>
                </div>
            )}
        </div>
    );
};

export default OrderUpload;
