/* eslint-disable react/style-prop-object */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { getCurrentUser } from 'utils/helpers/auth';
import { Banner } from 'flowbite-react';
import toast from 'react-hot-toast';
import { getStatsByLeads } from 'services/order';
import WidgetPercentIcon from 'components/dashboardCard/widgetPercentIcon';
import { IoMdInformationCircle } from 'react-icons/io';
import { IfilterDashboard } from '../dashboard.types';

const LeadsBased = ({ filter }: { filter: IfilterDashboard }) => {
    const [leadsNumbers, setLeadsNumbers] = useState<{
        'Added Leads': number;
        'Invalid Leads': number;
        'Valid Leads': number;
        'Confirmed Leads': number;
        'Unreachable Leads': number;
        'Pending answers Leads': number;
        'Questions answered Leads': number;
        'Call scheduled Leads': number;
        'Wrong number Leads': number;
        'Canceled Leads': number;
        'Expired Leads': number;
        'Leads waiting for stock availability': number;
        'Leads requiring check': number;
    }>({
        'Added Leads': 0,
        'Invalid Leads': 0,
        'Valid Leads': 0,
        'Confirmed Leads': 0,
        'Unreachable Leads': 0,
        'Pending answers Leads': 0,
        'Questions answered Leads': 0,
        'Call scheduled Leads': 0,
        'Wrong number Leads': 0,
        'Canceled Leads': 0,
        'Expired Leads': 0,
        'Leads waiting for stock availability': 0,
        'Leads requiring check': 0,
    });
    const [actionNumbers, setActionNumbers] = useState<{
        'Calls done': number;
        'Calls Scheduled': number;
        'Questions Asked by clients': number;
        'Questions Answered': number;
        'Expired Leads': number;
    }>({
        'Calls done': 0,
        'Calls Scheduled': 0,
        'Questions Asked by clients': 0,
        'Questions Answered': 0,
        'Expired Leads': 0,
    });

    useEffect(() => {
        getStatsByLeads(filter)
            .then((res) => {
                const leadsNumbersData: typeof leadsNumbers = {
                    'Added Leads': res.data.totalCount || 0,
                    'Invalid Leads': res.data.invalidCount || 0,
                    'Canceled Leads': res.data.canceledCount || 0,
                    'Wrong number Leads': res.data.wrongNumberCount || 0,
                    'Valid Leads':
                        res.data?.totalCount -
                        (res.data?.invalidCount || 0) -
                        (res.data.checkRequiredCount || 0) -
                        (res.data.holdNoStockCount || 0) -
                        (res.data.wrongNumberCount || 0),
                    'Confirmed Leads': 0,
                    'Unreachable Leads': res.data.unreachableCount || 0,
                    'Pending answers Leads': res.data.pendingAnswersCount || 0,
                    'Questions answered Leads': res.data.answeredCount || 0,
                    'Call scheduled Leads': res.data.ScheduledCallCount || 0,
                    'Expired Leads': res.data.expiredCount || 0,
                    'Leads waiting for stock availability': res.data.holdNoStockCount || 0,
                    'Leads requiring check': res.data.checkRequiredCount || 0,
                };
                const actionsNumbersData: typeof actionNumbers = {
                    'Calls done': 0,
                    'Calls Scheduled': 0,
                    'Questions Asked by clients': 0,
                    'Questions Answered': 0,
                    'Expired Leads': 0,
                };

                res.data.stats?.map((stat) => {
                    if ([2, 21, 3, 41, 42, 43, 4, 99, 100, 13, 14, 100, 99].includes(stat.status)) {
                        if (stat.status === 21) {
                            leadsNumbersData['Wrong number Leads'] = stat.count;
                        }

                        if (stat.status === 4) {
                            leadsNumbersData['Confirmed Leads'] = stat.count;
                            actionsNumbersData['Calls done'] = (actionsNumbersData['Calls done'] || 0) + stat.count;
                        }
                        if (stat.status === 13) {
                            actionsNumbersData['Expired Leads'] = stat.count;
                        }
                        if (stat.status === 3) {
                            actionsNumbersData['Calls done'] = (actionsNumbersData['Calls done'] || 0) + stat.count;
                        }
                        if (stat.status === 43) {
                            actionsNumbersData['Calls Scheduled'] = stat.count;
                        }
                        if (stat.status === 41) {
                            actionsNumbersData['Questions Asked by clients'] = stat.count;
                        }
                        if (stat.status === 42) {
                            actionsNumbersData['Questions Answered'] = stat.count;
                        }
                        if (stat.status === 2) {
                            actionsNumbersData['Calls done'] = (actionsNumbersData['Calls done'] || 0) + stat.count;
                        }
                        if (stat.status === 21) {
                            actionsNumbersData['Calls done'] = (actionsNumbersData['Calls done'] || 0) + stat.count;
                        }
                        if (stat.status === 41) {
                            actionsNumbersData['Calls done'] = (actionsNumbersData['Calls done'] || 0) + stat.count;
                        }
                        if (stat.status === 43) {
                            actionsNumbersData['Calls done'] = (actionsNumbersData['Calls done'] || 0) + stat.count;
                        }
                    }
                });

                setLeadsNumbers(leadsNumbersData);
                setActionNumbers(actionsNumbersData);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [filter]);
    return (
        <div>
            <Banner>
                <div className="flex mb-2 w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-transparent md:flex-row">
                    <div className="mb-4 md:mb-0 md:mr-4 flex gap-2">
                        <IoMdInformationCircle size={28} className="text-main " />
                        <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400 gap-2 pt-[2px]">
                            <span className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Note:</span>
                            Status updates for the leads added during the selected time period.
                        </p>
                    </div>
                </div>
            </Banner>
            <h2 className="my-3">Confirmation rate</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <WidgetPercentIcon
                    key={`Valid Leads{${leadsNumbers['Valid Leads']}`}
                    title="Valid Leads"
                    inputValue={leadsNumbers['Valid Leads']}
                    statPourcentage={
                        leadsNumbers['Valid Leads'] > 0
                            ? (leadsNumbers['Valid Leads'] / leadsNumbers['Valid Leads']) * 100
                            : 0
                    }
                />
                <WidgetPercentIcon
                    key={`Confirmed Leads{${leadsNumbers['Confirmed Leads']}`}
                    title="Confirmed Leads"
                    inputValue={leadsNumbers['Confirmed Leads']}
                    statPourcentage={
                        leadsNumbers['Valid Leads'] > 0
                            ? (leadsNumbers['Confirmed Leads'] / leadsNumbers['Valid Leads']) * 100
                            : 0
                    }
                />
            </div>
            <h2 className="my-3">Current status</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                {Object.keys(leadsNumbers).length
                    ? Object.keys(leadsNumbers).map((dataType, x) =>
                          dataType !== 'Valid Leads' ? (
                              <WidgetPercentIcon
                                  key={Object.keys(leadsNumbers)[x]}
                                  title={dataType}
                                  inputValue={leadsNumbers[dataType as keyof typeof leadsNumbers]}
                                  statPourcentage={
                                      leadsNumbers['Added Leads'] > 0
                                          ? (leadsNumbers[dataType as keyof typeof leadsNumbers] /
                                                leadsNumbers['Added Leads']) *
                                            100
                                          : 0
                                  }
                              />
                          ) : null,
                      )
                    : null}
            </div>
            <h2 className="my-3">Actions done</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                {Object.keys(actionNumbers).length
                    ? Object.keys(actionNumbers).map((dataType, x) => (
                          <WidgetPercentIcon
                              key={Object.keys(actionNumbers)[x]}
                              title={dataType}
                              inputValue={actionNumbers[dataType as keyof typeof actionNumbers]}
                          />
                      ))
                    : null}
            </div>
        </div>
    );
};

export default LeadsBased;
