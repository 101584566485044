export const formWrapper = { width: '100%' };
export const RadioWrapper = {
    border: '1px solid',
    borderColor: 'primary.main',
    borderRadius: '8px',
    margin: '5px',
    padding: '0px 10px 0px 0px',
    width: '100%',
};

export const RadioGroupeStyle = {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '6fr 6fr',
    gridGap: '10px',
};
