/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Spinner } from 'flowbite-react';
import CustomButton from 'components/customButton/CustomButton';
import InputField from 'components/inputField/InputField';
import { Formik, Form } from 'formik';
import CustomError from 'components/customError/CustomError';
import { signupSchema } from 'utils/validations/signupValidation';
import { Typography } from '@mui/material';
import { signup } from 'services/auth';
import { Link } from 'react-router-dom';
import { MdDownloadDone } from 'react-icons/md';
import toast from 'react-hot-toast';
import { goBack } from './SignUp.styles';

const SignUp: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const signingUp = (values: { email: string }) => {
        signup(values.email)
            .then(() => {
                setIsSubmitted(true);
                setLoading(false);
            })
            .catch((err) => {
                setIsSubmitted(false);
                setLoading(false);
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    return (
        <>
            <div className="grid place-items-center h-screen ">
                {!loading ? (
                    isSubmitted ? (
                        <div className="sm:max-w-xs md:max-w-sm w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div>
                                    <MdDownloadDone className="text-[#5D64FC] h-20 w-20" />
                                </div>
                                <p className="text-[#5D64FC] text-3xl font-semibold w-96 text-center  ">
                                    Successfully registered, please check your inbox !
                                </p>
                            </div>
                            <div className="flex justify-start mt-2">
                                <Link to="/auth/login" style={goBack}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        Go back to Login page !
                                    </Typography>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            onSubmit={(values) => {
                                setLoading(true);
                                signingUp(values);
                            }}
                            validationSchema={signupSchema}
                        >
                            {(formik) => (
                                <Form className="" onSubmit={formik.handleSubmit}>
                                    <div className="w-[300px] md:w-[400px]">
                                        <InputField
                                            InputType="email"
                                            label="Email"
                                            placeholder="jhondoe@email.com"
                                            // returns onChange, onBlur and value related to this input field
                                            {...formik.getFieldProps('email')}
                                            name="email"
                                        />
                                        <CustomError name="email" component="Box" />

                                        <CustomButton
                                            type="submit"
                                            className="btn"
                                            styleType="primary"
                                            label="Signup"
                                            sizeType="large"
                                        />
                                        <Link to="/auth/login" style={goBack}>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                Go back to Login page !
                                            </Typography>
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )
                ) : (
                    <Spinner aria-label="Extra large spinner example" size="xl" />
                )}
            </div>
        </>
    );
};

export default SignUp;
