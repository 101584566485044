/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { IaddOrder, IxlsxOrder } from 'services/order';
import {
    getUnseenResellerProductPricingUpdates,
    ResellerProductPricingHistory,
} from 'services/reseller/resellerProducts';
import { getCurrentStore } from 'services/stores';
import { logistioCountries } from 'utils/countryList';
import vnlinCities from 'utils/helpers/vnlin/vnlinCities.json';
import { WorkSheet, read, utils } from 'xlsx';
import { uploadCODOrder } from './uploadCODOrder';

type InputOrder = Record<keyof typeof inputOrderHeaderMatch, any> & { __rowNum__?: number; orderNumber?: string };

const countriesList: { [key: string]: { [k in 'code' | 'label' | 'phone' | 'currency' | 'arabicLabel']: string } } = {};
logistioCountries.forEach((country) => {
    countriesList[country.label] = {
        code: country.code,
        label: country.label,
        phone: country.phone,
        currency: country.currency,
        arabicLabel: country.arabicLabel,
    };
    countriesList[country.arabicLabel] = {
        code: country.code,
        label: country.label,
        phone: country.phone,
        currency: country.currency,
        arabicLabel: country.arabicLabel,
    };
});

export const inputOrderHeaderMatch = {
    store: 'store',
    'first name (required)': 'firstName',
    'last name (required)': 'lastName',
    'address 1': 'address1',
    'address 2': 'address2',
    'country (required)': 'country',
    'country code': 'countryCode',
    province: 'province',
    'province code': 'provinceCode',
    city: 'city',
    zip: 'zip',
    'phone (required)': 'phone',
    'sku (required)': 'sku',
    'quantity (required)': 'quantity',
    'total price (required)': 'totalPrice',
    'currency (required)': 'currency',
    'order number (required)': 'orderNumber',
};

const orderKeys = [
    'firstName',
    'lastName',
    'address1',
    'address2',
    'country',
    'countryCode',
    'province',
    'provinceCode',
    'city',
    'zip',
    'phone',
    'sku',
    'quantity',
    'totalPrice',
    'currency',
    'orderNumber',
];

function memoize<Fn extends (...args: any[]) => any>(fn: Fn) {
    const cache = new Map<string, any>();

    return function Fn(...args: Parameters<Fn>): ReturnType<Fn> {
        const cacheKey = JSON.stringify(args);
        if (cache.has(cacheKey)) {
            return cache.get(cacheKey);
        }
        const computedValue = fn(...args);
        cache.set(cacheKey, computedValue);
        return computedValue;
    };
}

/**
 * Calculate similarity between two strings
 * (used for diffing and calculating match) */
function stringSimilarity(input: string, refString: string, substringLength: number = 2): number {
    if (input.length < substringLength || refString.length < substringLength) return 0;

    const map = new Map();
    for (let i = 0; i < input.length - (substringLength - 1); i++) {
        const substr1 = input.substring(i, substringLength + i);
        map.set(substr1, map.has(substr1) ? map.get(substr1) + 1 : 1);
    }

    let match = 0;
    for (let j = 0; j < refString.length - (substringLength - 1); j++) {
        const substr2 = refString.substring(j, substringLength + j);
        const count = map.has(substr2) ? map.get(substr2) : 0;
        if (count > 0) {
            map.set(substr2, count - 1);
            match++;
        }
    }

    return (match * 2) / (input.length + refString.length - (substringLength - 1) * 2);
}

const memoizedStringSimilarity = memoize(stringSimilarity);

/**
 * This function mutates the matchedCity & lowMatchScore objects passed to it
 */
function pickHighestandLowestCityMatch({
    cityLabel,
    vnlinLabel,
    orderCity,
    matchedCity,
    lowMatchScore,
}: {
    cityLabel: string;
    vnlinLabel: string;
    orderCity: string;
    matchedCity: { label: string | null; score: number };
    lowMatchScore: { label: string | null; score: number };
}) {
    if (cityLabel.includes('/')) {
        // city have an arabic label and an english label
        const [cityLangOne, cityLangTwo] = cityLabel.split('/');
        const scoreLangOne = memoizedStringSimilarity(
            orderCity.toLowerCase().replaceAll(' ', ''),
            cityLangOne.toLowerCase().replaceAll(' ', ''),
        );
        const scoreLangTwo = memoizedStringSimilarity(
            orderCity.toLowerCase().replaceAll(' ', ''),
            cityLangTwo.toLowerCase().replaceAll(' ', ''),
        );
        if (scoreLangOne >= 0.5 || scoreLangTwo >= 0.5) {
            // pick the highest score between the current refCity and the existing matchedCity if available or take the current refCity
            if (matchedCity.score < Math.max(scoreLangOne, scoreLangTwo)) {
                Object.assign(matchedCity, {
                    label: vnlinLabel,
                    score: Math.max(scoreLangOne, scoreLangTwo),
                });
            }
        } else if ((scoreLangOne > 0 && scoreLangOne < 0.5) || (scoreLangTwo > 0 && scoreLangTwo < 0.5)) {
            if (lowMatchScore.score < Math.max(scoreLangOne, scoreLangTwo)) {
                Object.assign(lowMatchScore, {
                    label: vnlinLabel,
                    score: Math.max(scoreLangOne, scoreLangTwo),
                });
            }
        }
    } else {
        // city only have an english label
        const score = memoizedStringSimilarity(
            orderCity.toLowerCase().replaceAll(' ', ''),
            cityLabel.toLowerCase().replaceAll(' ', ''),
        );
        if (score >= 0.5) {
            if (matchedCity.score < score) {
                Object.assign(matchedCity, { label: vnlinLabel, score });
            }
        } else if (score > 0 && score < 0.5) {
            if (lowMatchScore && lowMatchScore.score < score) {
                Object.assign(lowMatchScore, {
                    label: vnlinLabel,
                    score,
                });
            }
        }
    }
}

/**
 * Produce order object with the proper keys from an input order with non restricted orderKeys
 * (Minimum similarity score to consider a key as a valid match is 0.5)
 * @example
 * input: {'first name (required asdfasdf)': 'John', 'last': 'doe', ...}
 * output: {firstName: 'John', lastName: 'doe', ...}
 */
function orderFactory(order: InputOrder) {
    const inputKeys = Object.keys(order) as unknown as (keyof InputOrder)[];
    // @ts-ignore: we just initializing the data here ... it will be filled don't worry :)
    const orderData: Omit<IxlsxOrder & { invalidCity?: boolean; closestCityMatch?: string }, 'orderRef'> & {
        __rowNum__?: number;
        orderNumber?: string | undefined;
    } = {};

    const scoredKeys = new Map<string, { inputKey: (typeof inputKeys)[number]; score: number }>();
    // compute string similarity scores and pick the closest match
    orderKeys.forEach((orderKey) => {
        inputKeys.forEach((parsedInputKey) => {
            const similarityScore = memoizedStringSimilarity(
                parsedInputKey.toLowerCase().replaceAll(' ', '').replaceAll('(required)', ''),
                orderKey.toLowerCase().replaceAll(' ', '').replaceAll('(required)', ''),
            );
            if (similarityScore >= 0.5) {
                if (scoredKeys.has(orderKey)) {
                    const matchedKey = scoredKeys.get(orderKey);
                    if (matchedKey && matchedKey.score < similarityScore) {
                        scoredKeys.set(orderKey, { inputKey: parsedInputKey, score: similarityScore });
                    }
                } else {
                    scoredKeys.set(orderKey, { inputKey: parsedInputKey, score: similarityScore });
                }
            }
        });
    });
    Array.from(scoredKeys).forEach(([orderKey, matchedKey]) => {
        Object.assign(orderData, { [orderKey]: order[matchedKey.inputKey] });
    });

    // make sure that we get the expected value for the country so we can guess the correct value of the country label (example: {input: 'qatar', output: 'Qatar'})
    // in case of arabic values we score against the arabic label and we take the english label if min score is reached
    let scoredCountry: {
        country: string;
        score: number;
    } | null = null;
    Object.values(countriesList)
        .map((countryData) => [countryData.label, countryData.arabicLabel])
        .forEach(([englishLabel, arabicLabel]) => {
            const similarityScoreEnglish = memoizedStringSimilarity(
                orderData.country.toLowerCase().replaceAll(' ', ''),
                englishLabel.toLowerCase().replaceAll(' ', ''),
            );
            const similarityScoreArabic = memoizedStringSimilarity(
                orderData.country.toLowerCase().replaceAll(' ', ''),
                arabicLabel.toLowerCase().replaceAll(' ', ''),
            );
            if (similarityScoreEnglish >= 0.5 || similarityScoreArabic >= 0.5) {
                if (!scoredCountry) {
                    scoredCountry = {
                        country: englishLabel,
                        score: similarityScoreEnglish,
                    };
                } else if (scoredCountry.score < similarityScoreEnglish) {
                    scoredCountry.country = englishLabel;
                    scoredCountry.score = similarityScoreEnglish;
                }
            }
        });
    // currency validation expects uppercase values to match against
    orderData.currency = (orderData.currency || '').toUpperCase();

    orderData.__rowNum__ = order.__rowNum__;
    orderData.totalPrice = !Number.isNaN(Number(orderData.totalPrice))
        ? Number(orderData.totalPrice)
        : orderData.totalPrice;

    if (scoredCountry && (scoredCountry as { country: string; score: number }).country) {
        orderData.countryCode = countriesList[(scoredCountry as { country: string; score: number }).country].code;
        orderData.country = (scoredCountry as { country: string; score: number }).country;
        // if we have a valid country, we can guess the appropriate city
        // if no city scored more than 0.5, we remove the city field from the order to make the validation fail later
        const currentCountryCities = vnlinCities[orderData.country as keyof typeof vnlinCities] || [];
        if (currentCountryCities.length === 0) {
            // we remove the city field if no cities are available and we return early
            orderData.city = '';
            return orderData;
        }
        // compute the city similarity scores and pick the closest match
        const matchedCity: { label: string | null; score: number } = { label: null, score: 0 };
        const lowMatchScore: { label: string | null; score: number } = { label: null, score: 0 };
        if (orderData.city && orderData.city.length > 0) {
            currentCountryCities.forEach(({ vnlinLabel, label1, label2 }) => {
                pickHighestandLowestCityMatch({
                    cityLabel: vnlinLabel,
                    vnlinLabel,
                    orderCity: orderData.city,
                    matchedCity,
                    lowMatchScore,
                });
                pickHighestandLowestCityMatch({
                    cityLabel: label1,
                    vnlinLabel,
                    orderCity: orderData.city,
                    matchedCity,
                    lowMatchScore,
                });
                if (label2) {
                    pickHighestandLowestCityMatch({
                        cityLabel: label2,
                        vnlinLabel,
                        orderCity: orderData.city,
                        matchedCity,
                        lowMatchScore,
                    });
                }
            });
        }
        if (matchedCity.label && matchedCity.score > 0) {
            if (orderData.city.toLowerCase() !== matchedCity.label.toLowerCase()) {
                orderData.invalidCity = true;
                orderData.closestCityMatch = matchedCity.label;
            } else {
                // make sure we take the same expected value (the value is case sensitive)
                orderData.city = matchedCity.label;
            }
        } else {
            orderData.invalidCity = true;
            if (lowMatchScore.label && lowMatchScore.score > 0) {
                orderData.closestCityMatch = lowMatchScore.label;
            }
        }
        // orderData.city = matchedCity ? (matchedCity as Omit<typeof matchedCity, 'null'>).label : 'unknown city name??';
    }
    return orderData;
}

const memoizedOrderFactory = memoize(orderFactory);

export async function uploadCODOrderFile(
    files: FileList | null,
    setOpenModal: any,
    setCounter: any,
    setFailedOrders: React.Dispatch<React.SetStateAction<WorkSheet>>,
    setErrors: React.Dispatch<
        React.SetStateAction<
            Map<
                string,
                {
                    orderCustomerFullName: string;
                    errors: string[];
                }
            >
        >
    >,
    setFileName: any,
    setShowConfirmationUi: React.Dispatch<React.SetStateAction<boolean>>,
    setParsedOrders: React.Dispatch<
        React.SetStateAction<{
            withInvalidCities: any[];
            withFailedValidation: any[];
            valid: any[];
        } | null>
    >,
    setUpdatedPricing: React.Dispatch<React.SetStateAction<ResellerProductPricingHistory[]>>,
    callback?: (...args: any[]) => any | void,
) {
    setCounter({ errors: 0, success: 0, total: 0 });
    setErrors(new Map());
    const store = localStorage.getItem('storeId') as string;
    const ordersFile = files instanceof FileList && files[0];

    // 🏷️ parse file and upload the resulting object
    if (ordersFile) {
        // get the store data
        const storeData = await getCurrentStore();

        setOpenModal(true);
        setFileName(ordersFile.name);
        const reader = new FileReader();
        reader.readAsArrayBuffer(ordersFile);
        reader.onloadend = async (content) => {
            /*
             * 1. Parse the input file
             * 2. Validate the orders objects (one by one)
             * 3. Send the orders to the server (one by one)
             */

            // 1. Parse the xlsx/csv file (get data as json)
            const workbook = read(content.target?.result);
            // this include all sheet rows (empty rows also are included)
            const fileDataAsJsonWithReadableHeader = utils.sheet_to_json<
                Record<keyof typeof inputOrderHeaderMatch, any> & { __rowNum__?: number; orderNumber?: string } // Omit<IxlsxOrder, 'orderRef'>
            >(workbook.Sheets[workbook.SheetNames[0]], { defval: '', blankrows: true });

            // match columns headers including `(required)` with headers format that the validation expects as input (no `(required)`)
            // and collect the parsed data (columns/rows as json)
            const fileDataAsJson: (Omit<IxlsxOrder, 'orderRef'> & {
                __rowNum__?: number;
                orderNumber?: string | undefined;
            })[] = [];
            fileDataAsJsonWithReadableHeader.forEach((row) => {
                // only compute non empty rows
                if (Object.values(row).some((value) => !!value)) {
                    const data = memoizedOrderFactory({ ...row, __rowNum__: row.__rowNum__ });
                    fileDataAsJson.push(data);
                }
            });

            // unmerge cells if found
            const merges = workbook.Sheets[workbook.SheetNames[0]]['!merges'];
            const orders: (IaddOrder & { invalidCity?: boolean; closestCityMatch?: string })[] = [];
            if (merges) {
                // 1. Handle the merged cells
                // Given the merge for all cols is the same we only take the first column (A)
                const firstCol = merges.filter((merge) => merge.s.c === 0);
                // From the column data, we take the rows merge ranges and we sort them asc
                const sortedRowsRanges = firstCol
                    .map((rowRange) => ({ s: rowRange.s.r, e: rowRange.e.r }))
                    .sort((rowA, rowB) => rowA.s - rowB.s);
                sortedRowsRanges.forEach((mergedRow) => {
                    // ranges are inclusive that's why we iterate `e` times
                    for (let i = mergedRow.s; i < mergedRow.e + 1; i++) {
                        // `fileDataAsJson` holds the rows by the order they were from the file,
                        // so fileDataAsJson[0] is the first row (the row number 0 in the file is the header
                        // and it's not included in fileDataAsJson array)
                        const row = {
                            ...fileDataAsJson[i - 1],
                            orderRef: `${fileDataAsJson[i - 1].orderNumber}` || '',
                        };
                        delete row.orderNumber;
                        // in case we on the 1st row of the merge then all fields are available in the row object
                        if (i === mergedRow.s) {
                            const skus: IaddOrder['skus'] = [
                                { sku: row.sku!, quantity: row.quantity ? row.quantity : 0 },
                            ];
                            delete row.sku;
                            delete row.quantity;
                            delete row.__rowNum__;
                            orders.push({
                                ...row,
                                skus,
                                fullName: `${row.firstName} ${row.lastName}`,
                                store,
                            });
                        } else {
                            // we just select the sku and the quantity and add them to the last found parent row (merged cells are not available in the row object)
                            orders.at(-1)?.skus.push({ sku: row.sku!, quantity: row.quantity ? row.quantity : 0 });
                        }
                    }
                });
                // 2. Handle unmerged cells
                fileDataAsJson.forEach((el, ind) => {
                    if (Object.values(el).some((cellValue) => !!cellValue)) {
                        const row = { ...el, orderRef: `${el.orderNumber}` || '' };
                        delete row.orderNumber;
                        const rowIsOutOfMergeRange = sortedRowsRanges
                            .map((range) => (row.__rowNum__ || 0) > range.s - 1 && (row.__rowNum__ || 0) < range.e + 1)
                            .every((isInRange) => !isInRange);
                        if (rowIsOutOfMergeRange) {
                            // if we are out of merge ranges there is no empty rows are ignored (cells holding the number 0 are considered empty)
                            const skus: IaddOrder['skus'] = [
                                { sku: row.sku!, quantity: row.quantity ? row.quantity : 0 },
                            ];
                            delete row.sku;
                            delete row.quantity;
                            delete row.__rowNum__;
                            orders.push({
                                ...row,
                                skus,
                                fullName: `${row.firstName} ${row.lastName}`,
                                store,
                            });
                        }
                    }
                });
            } else {
                // in case there is no merges we iterate the whole sheet rows in case there is empty rows in between orders
                fileDataAsJson.forEach((el) => {
                    if (Object.values(el).some((cellValue) => !!cellValue)) {
                        const row = { ...el, orderRef: el.orderNumber || '' };
                        delete row.orderNumber;
                        // if we dont have merges then empty rows are ignored (cells holding the number 0 are considered empty)
                        const skus: IaddOrder['skus'] = [{ sku: row.sku!, quantity: row.quantity ? row.quantity : 0 }];
                        delete row.sku;
                        delete row.quantity;
                        delete row.__rowNum__;
                        orders.push({
                            ...row,
                            skus,
                            fullName: `${row.firstName} ${row.lastName}`,
                            store,
                        });
                    }
                });
            }
            setCounter((counter: any) => ({ ...counter, total: orders.length }));
            const isWithCcEnabled = storeData ? storeData.withCC !== false : false;

            const skus = new Set<string>();
            orders.forEach((order) => {
                order.skus.forEach((item) => {
                    skus.add(item.sku);
                });
            });
            const unseenPricingUpdatesResponse = await getUnseenResellerProductPricingUpdates(Array.from(skus));
            if (unseenPricingUpdatesResponse.data.length > 0) {
                setUpdatedPricing(unseenPricingUpdatesResponse.data);
            } else {
                uploadCODOrder({
                    setShowConfirmationUi,
                    setParsedOrders,
                    orders,
                    onUploadEnd: callback,
                    setFailedOrders,
                    setErrors,
                    setCounter,
                    store,
                    isWithCcEnabled,
                });
            }
        };
    }
}
