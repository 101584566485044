import * as Yup from 'yup';

const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d-]([a-z\\d-]*[a-z\\d-])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[;&a-z\\d%/_:,.?!~+=-]*)*' + // port and path
        '(\\?[;&a-z\\d%/_:,.!~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
);

export const basicInfoValidation = (sourcing: string | number) => {
    if (sourcing === 'logistioBulk' || sourcing === 3)
        return Yup.object().shape({
            productName: Yup.string().required('Product name is required'),
            productLink: Yup.string().matches(pattern, 'Enter correct url!').required('Please enter source website'),
            shippingTo: Yup.array().min(1, 'Pick at least 1 country').max(6, 'Pick maximum 5 countries'),
        });
    if (sourcing === 'bulk' || sourcing === 2)
        return Yup.object().shape({
            productName: Yup.string().required('Product name is required'),
            productLink: Yup.string().required('Product link is required'),
            address: Yup.string().required('Shipping address is required'),
            state: Yup.string().required('state is required'),
            city: Yup.string().required('city is required'),
            zipCode: Yup.string().required('zipCode is required'),
            shippingTo: Yup.array().min(1, 'Pick at least 1 country').max(6, 'Pick maximum 5 countries'),
        });
    if (sourcing === 'dropshipping' || sourcing === 1)
        return Yup.object().shape({
            productName: Yup.string().required('Product name is required'),
            productLink: Yup.string().required('Product link is required'),
            shippingTo: Yup.array().min(1, 'Pick at least 1 country').max(6, 'Pick maximum 5 countries'),
        });
    return undefined;
};

export const productDesignValidation = Yup.object().shape({
    photo: Yup.mixed().required('photo is required'),
});

export const addVariantsValidation = Yup.object().shape({
    quantity: Yup.number().min(1, 'No variants has been added, please specify how many items do you want'),
});

// export const addVariantsValidationifDesactivated = Yup.object().shape({
//     quantity: Yup.number().min(1, 'No variants has been added, please specify how many items do you want'),
// });

export const stockConfigValidation = Yup.array().of(
    Yup.object().shape({
        warehouseId: Yup.string().required('warehouse is required'),
        variant: Yup.string().required('variant is required'),
        quantity: Yup.string().required('quantity is required'),
        country: Yup.string().required('country is required'),
    }),
);
