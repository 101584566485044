/* eslint-disable react/self-closing-comp */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getStatsByCustomer } from 'services/order';
import WidgetPercentIcon from 'components/dashboardCard/widgetPercentIcon';
import toast from 'react-hot-toast';
import { IDashboardProps, IfilterDashboard } from '../dashboard.types';

const OrdersDashboard: React.FC<IDashboardProps> = ({ filter }: { filter: IfilterDashboard }) => {
    const [orders, setOrders] = useState({
        pickAndPack: 0,
        ofd: 0,
        delivered: 0,
        returned: 0,
        reseller: 0,
    });
    useEffect(() => {
        getStatsByCustomer(filter)
            .then(async (res: any) => {
                const stats = res.data.docs;
                const updatedOrders = { ...orders };
                if (stats.length > 0) {
                    updatedOrders.pickAndPack = 0;
                    updatedOrders.ofd = 0;
                    updatedOrders.delivered = 0;
                    updatedOrders.returned = 0;
                    updatedOrders.reseller = 0;
                    stats.forEach((stat: any) => {
                        updatedOrders.pickAndPack += stat.orders.pickAndPack;
                        updatedOrders.ofd += stat.orders.ofd;
                        updatedOrders.delivered += stat.orders.delivered;
                        updatedOrders.returned += stat.orders.returned;
                        updatedOrders.reseller += stat.orders.reseller;
                    });
                } else {
                    updatedOrders.pickAndPack = 0;
                    updatedOrders.ofd = 0;
                    updatedOrders.delivered = 0;
                    updatedOrders.returned = 0;
                    updatedOrders.reseller = 0;
                }
                setOrders(updatedOrders);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [filter]);

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
            <WidgetPercentIcon
                // loading={ppOrdersNumber.loading}
                title="Pick and pack Orders"
                inputValue={orders.pickAndPack}
                statPourcentage={orders.pickAndPack > 0 ? (orders.pickAndPack / orders.pickAndPack) * 100 : 0}
            />
            <WidgetPercentIcon
                // loading={ofdOrdersNumber.loading}
                title="Delivery attempts"
                inputValue={orders.ofd}
            />
            <WidgetPercentIcon
                // loading={returnedOrdersNumber.loading}
                title="Failed delivery"
                inputValue={orders.returned}
                statPourcentage={orders.pickAndPack > 0 ? (orders.returned / orders.pickAndPack) * 100 : 0}
            />
            <WidgetPercentIcon
                // loading={deliverdOrdersNumber.loading}
                title="Delivered Orders "
                inputValue={orders.delivered}
                statPourcentage={orders.pickAndPack > 0 ? (orders.delivered / orders.pickAndPack) * 100 : 0}
            />
            <div className="col-span-full w-full border-t border-gray-200 my-3"></div>
            <WidgetPercentIcon
                // loading={deliverdOrdersNumber.loading}
                title="Reseller"
                inputValue={orders.reseller}
                // statPourcentage={}
            />
        </div>
    );
};

export default OrdersDashboard;
