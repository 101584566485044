/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import CustomButton from 'components/customButton/CustomButton';
import { Typography, Box } from '@mui/material';
import * as Yup from 'yup';
// import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CustomError from 'components/customError/CustomError';
import { addStoreSchema, changePersonalInfos } from 'utils/validations/firstLoginValidation';
import InputField from 'components/inputField/InputField';
import { updateCustomer } from 'services/customer';
import { getCurrentUser } from 'utils/helpers/auth';
import { MdDownloadDone } from 'react-icons/md';
import { addStore } from 'services/stores';
import useMediaQueries from 'utils/hooks/useMediaQuery';
import toast from 'react-hot-toast';
import { ToggleSwitch } from 'flowbite-react';
import {
    StepperStyle,
    confirmationMessage,
    confirmationWrapper,
    stepperWrapper,
    typographyStyle,
} from './welcomePage.styles';

const Welcomepage: React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);

    const customerId = getCurrentUser()._id;

    const [validate, setValidate] = useState<Yup.AnyObjectSchema>();

    const [callCenterToggle, setCallCenterToggle] = useState(true);

    const createAccount = (values: any) => {
        updateCustomer(customerId, values.firstPage)
            .then((res) => {
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        firstName: res.data.customer.firstName,
                        lastName: res.data.customer.lastName,
                        email: res.data.customer.email,
                    }),
                );
            })
            .then(() => {
                return addStore({
                    storeName: values.secondPage.storeName,
                    storeType: 'other',
                    storeLink: values.secondPage.storeLink,
                    fulfillmentProcessing: 'manual',
                    riskyOrderProcessing: 'yes',
                    partiallyFulfilledProcessing: 'manual',
                    deliveryLevels: 'fast',
                    withCC: values.secondPage.withCC,
                    offer: '',
                })
                    .then(() => {
                        return window.location.reload();
                    })
                    .catch(() => {
                        toast.error('Something went wrong, please try again');
                    });
            })
            .catch(() => {
                toast.error('Something went wrong, please try again');
            });
    };
    const mediaQueries = useMediaQueries();
    return (
        <>
            <Formik
                initialValues={{
                    firstPage: {
                        firstName: '',
                        lastName: '',
                    },
                    secondPage: {
                        storeName: '',
                        storeType: 'other',
                        storeLink: '',
                        withCC: callCenterToggle,
                    },
                }}
                onSubmit={(values) => {
                    if (activeStep < 3) setActiveStep((s) => s + 1);
                    else if (activeStep === 3) {
                        createAccount(values);
                    }
                }}
                validationSchema={validate}
            >
                {(formik) => (
                    <Form className="flex flex-col items-center justify-center h-screen w-screen">
                        <Box sx={stepperWrapper}>
                            <StepperStyle activeStep={activeStep} alternativeLabel>
                                <Step>
                                    <StepLabel>Welcome</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Your Information</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Store’s Settings</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Confirmation</StepLabel>
                                </Step>
                            </StepperStyle>
                        </Box>
                        <div className="flex-1 flex flex-col items-center justify-center  w-2/5 mb-[10vh] ">
                            {activeStep === 1 ? (
                                <div className="w-[300px] md:w-[400px]">
                                    <Typography variant="body1" sx={typographyStyle}>
                                        Please fill in your information !
                                    </Typography>
                                    <Box>
                                        <InputField
                                            label="First Name"
                                            {...formik.getFieldProps('firstPage.firstName')}
                                            name="firstPage.firstName"
                                            placeholder="First name here"
                                            // value={formik.values.firstPage.firstName.trim()}
                                            onBlur={(event) => {
                                                formik.setFieldValue('firstPage.firstName', event.target.value.trim());
                                            }}
                                        />
                                        <CustomError name="firstPage.firstName" component="div" />
                                    </Box>
                                    <Box>
                                        <InputField
                                            label="Last Name"
                                            {...formik.getFieldProps('firstPage.lastName')}
                                            name="firstPage.lastName"
                                            placeholder="Last name here"
                                            onBlur={(event) => {
                                                formik.setFieldValue('firstPage.lastName', event.target.value.trim());
                                            }}
                                        />
                                        <CustomError name="firstPage.lastName" component="div" />
                                    </Box>
                                </div>
                            ) : activeStep === 2 ? (
                                <div className="w-[300px] md:w-[400px]">
                                    <Typography variant="body1" sx={typographyStyle}>
                                        Please add your store !
                                    </Typography>

                                    <InputField
                                        label="Store name"
                                        placeholder="Store Name"
                                        {...formik.getFieldProps('secondPage.storeName')}
                                        name="secondPage.storeName"
                                        onBlur={(event) => {
                                            formik.setFieldValue('secondPage.storeName', event.target.value.trim());
                                        }}
                                    />
                                    <CustomError name="secondPage.storeName" component="Box" />
                                    <Box>
                                        <InputField
                                            type="url"
                                            label="Store link"
                                            placeholder="Store link: www.your-store.com"
                                            {...formik.getFieldProps('secondPage.storeLink')}
                                            name="secondPage.storeLink"
                                            onBlur={(event) => {
                                                formik.setFieldValue('secondPage.storeLink', event.target.value.trim());
                                            }}
                                        />
                                        <CustomError name="secondPage.storeLink" component="Box" />
                                    </Box>
                                    <div className="my-2">
                                        <ToggleSwitch
                                            {...formik.getFieldProps('secondPage.withCC')}
                                            onChange={() => {
                                                setCallCenterToggle(!callCenterToggle);
                                                formik.setFieldValue('secondPage.withCC', !callCenterToggle);
                                            }}
                                            name="secondPage.withCC"
                                            checked={callCenterToggle}
                                            label="With call center"
                                        />
                                    </div>
                                </div>
                            ) : activeStep === 3 ? (
                                <Box sx={confirmationWrapper}>
                                    <Box>
                                        <MdDownloadDone className="w-20 h-20 text-[#5C63FA]" />
                                    </Box>
                                    <Box sx={confirmationMessage}>
                                        <Typography variant={mediaQueries.maxSm ? 'h4' : 'h3'}>
                                            CONGRATULATIONS
                                        </Typography>
                                        <Typography variant="body2" sx={{ marginBottom: '20px' }}>
                                            Your account is ready to be used !
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={confirmationWrapper}>
                                    <Typography variant="h3">Welcome to Logistio !</Typography>
                                    <Typography variant="body2" sx={typographyStyle}>
                                        Please tell us about your business !
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    // alignItems: 'center',
                                    gap: 2,
                                    marginTop: '10px',
                                }}
                            >
                                {activeStep > 0 && (
                                    <CustomButton
                                        sizeType="medium"
                                        styleType="primary"
                                        label="< Previous"
                                        onClick={() => activeStep > 0 && setActiveStep((s) => s - 1)}
                                    />
                                )}

                                <CustomButton
                                    sizeType="medium"
                                    type="submit"
                                    styleType="primary"
                                    label={activeStep === 3 ? 'Confirm' : 'Next >'}
                                    onClick={() => {
                                        if (activeStep === 1) setValidate(changePersonalInfos);
                                        else if (activeStep === 2) setValidate(addStoreSchema);
                                        // else if (activeStep === 2) setValidate(productDesignValidation);
                                        else setValidate(Yup.object().shape({}));
                                    }}
                                />
                            </Box>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Welcomepage;
