/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/messageFile';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import React, { forwardRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import { Badge, Modal } from 'flowbite-react';
import StatusTimeline from 'pages/orders/orderStatusTimeline';
import { orderStatusMatching } from 'pages/orders/Orders.styles';
// import ChatHeader from './ChatHeader';
import { FaHistory } from 'react-icons/fa';
import { getHistory } from 'services/order';
import { getOrderTraces } from 'services/orderTrace';
import { IStatusTimeline } from 'pages/orders/orders.types';
import toast from 'react-hot-toast';
import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts } from './chat.styles';

const Chat = forwardRef(() => {
    const location: any = useLocation();
    const [openhistory, setOpenHistory] = useState(false);
    const [history, setHistory] = useState<IStatusTimeline>({ history: [], deliveryAttempts: [] });
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);

    useEffect(() => {
        // Creates a WebSocket connection

        socket.emit('joinOrderChat', JSON.stringify({ order: location.state.orderId, customer: getCurrentUser()._id }));

        socket.on('paginate-order-messages', (data: any) => {
            if (data.totalDocs) setTotalMessages(data.totalDocs);
            if (data.docs) {
                setConvMessages(data.docs);
            }

            setCurrentRef('last');
        });

        socket.on('new-order-message', (data: any) => {
            if (data.docs) {
                if (data.docs.length === 1) {
                    setConvMessages((conv: any) => [...data.docs, ...conv]);
                } else setConvMessages(data.docs);
                socket.emit('newChatMessage', {
                    customer: getCurrentUser()._id,
                    about: 22, // new order message
                    customerId: location.state.data.customer._id,
                    messageId: data.docs[0]._id,
                });
            }

            setCurrentRef('last');
        });

        return () => {
            socket.off('paginate-order-messages');
            socket.off('new-order-message');
        };
    }, []);

    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [progressCount, setProgressCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayuploadingFile, setDisplayUploadingFile] = useState(false);

    const handleOrderHistory = () => {
        getHistory(location.state.orderId)
            .then((orderData) => {
                getOrderTraces({ order: location.state.orderId, logistioStatus: 10 })
                    .then((dataTraces) => {
                        setHistory({ history: orderData?.data, deliveryAttempts: dataTraces?.data.docs });
                        setOpenHistory(true);
                    })
                    .catch(() => {});
            })
            .catch(() => {
                setOpenHistory(false);
                toast.error('Something went wrong when fetching order history');
            });
    };

    useEffect(() => {
        setUploading(progressCount < 100 && progressCount > 0);
    }, [progressCount, displayuploadingFile]);

    if (!location.state.data) {
        toast.error('Something went wrong ');
    }

    return (
        <>
            <Modal
                dismissible
                show={openhistory}
                popup
                onClose={() => setOpenHistory(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Order Status History</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {location.state.data && (
                        <StatusTimeline setOpen={setOpenHistory} history={history} order={location.state.data} />
                    )}
                </Modal.Body>
            </Modal>
            <div className="h-[90vh] bg-background-secondary relative ">
                <div className="flex justify-center gap-3 w-full p-4 h-[50px]">
                    <div className="w-full">
                        <div className="flex justify-between w-full  mb-1">
                            <div className="flex gap-3">
                                <p className="truncate max-w-96 font-normal text-lg font-popin">
                                    {location.state.data.orderRef
                                        ? `Order ref : ${location.state?.data?.orderRef}`
                                        : ''}
                                </p>
                                <button type="button" onClick={handleOrderHistory}>
                                    <FaHistory />
                                </button>
                            </div>
                            <div style={{ width: 'fit-content' }}>
                                <Badge color={orderStatusMatching(location.state.data.status).color}>
                                    {orderStatusMatching(location.state.data.status).title}
                                </Badge>
                            </div>
                        </div>
                    </div>
                </div>

                <Divider />

                <div className="h-[calc(90vh-(50px+24px))]  overflow-hidden hover:overflow-y-scroll">
                    {limit < totalMessages && (
                        <Box sx={LoadPreviousMessagesWrapper}>
                            <Box
                                onClick={() => {
                                    socket.emit(
                                        'paginateOrderMessages',
                                        JSON.stringify({
                                            limit: limit + 20,
                                            customer: getCurrentUser()._id,
                                            order: location.state.data._id,
                                        }),
                                    );
                                    setLimit((lmt) => lmt + 20);
                                    setCurrentRef('first');
                                }}
                                sx={LoadPreviousMessages}
                            >
                                <ExpandLessIcon /> Click to load previous messages
                            </Box>
                        </Box>
                    )}
                    {currentRef === 'first' && <Box ref={myRef} />}
                    {convMessages &&
                        convMessages
                            .slice(0)
                            .reverse()
                            .map((message: any) => (
                                <Box sx={MessageTexts} key={message.id}>
                                    {message.message ? (
                                        <MessageText
                                            entity={message.from}
                                            message={message.message}
                                            received={message.onModelFrom === 'User'}
                                            timestamp={message.createdAt}
                                            key={message.id}
                                        />
                                    ) : message.file ? (
                                        <MessageFile
                                            entity={message.from}
                                            file={message.file}
                                            received={message.onModelFrom === 'User'}
                                            key={message.id}
                                            timestamp={message.createdAt}
                                            fileName={message.fileName}
                                            fileSize={message.fileSize}
                                        />
                                    ) : null}
                                </Box>
                            ))}
                    {currentRef === 'last' && <Box ref={myRef} />}
                    {uploading && displayuploadingFile && (
                        <Box sx={MessageTexts}>
                            <MessageFile
                                file="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                                received={false}
                                fileName={fileName}
                                fileSize={fileSize}
                                uploading={uploading}
                                progressCount={progressCount}
                            />
                        </Box>
                    )}
                </div>
                <Divider />
                <MessageInput
                    socketRef={socket}
                    action="newOrderMessage"
                    setProgressCount={setProgressCount}
                    setFileName={setFileName}
                    setFileSize={setFileSize}
                    setDisplayUploadingFile={setDisplayUploadingFile}
                />
            </div>
        </>
    );
});

export default Chat;
