import * as Yup from 'yup';

export const changeEmailSchema = Yup.object().shape({
    email: Yup.string().email('New Email is invalid').required('New Email is required'),
});
export const changePasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'New Password must be at least 8 characters').required('New Password is required'),
    oldPassword: Yup.string().min(8, 'Old Password must be at least 8 characters').required('Old Password is required'),
});

export const ProfilePasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is Required').min(8, 'New Password must be at least 8 characters'),
    passwordConfirmation: Yup.string()
        .required('Password Confirmation is required')
        .test('Passwords matches', 'Passwords are not matching', function a(value) {
            return this.parent.password === value;
        }),
});

export const updateStocksThresholdSchema = Yup.object().shape({
    stocksThreshold: Yup.number().min(0, 'Minimum stocks threshold is 0').required('Min. threshold field is required'),
});
