import axios from 'utils/axios/axios';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IPagination, IPaginationData } from 'utils/Interfaces/types';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { getBearerToken } from '../utils/helpers/auth';

export interface IStockFilter {
    warehouse?: string;
    country?: string;
    product?: string;
    products?: string[];
    sku?: string;
}

export interface IStock {
    _id: string;
    parentProduct: IProduct;
    warehouse: string;
    country: string;
    customer: string | ICustomer;
    status: number;
    statusDescription?: string;
    product: IProduct;
    quantity: number;
    availableQuantity: number;
    pickingQuantity: number;
    outboundQuantity: number;
    deliveredQuantity: number;
    packagedQuantity: number;
    incomingQuantity: number;
    boxes: {
        boxNumber: number;
        product?: IProduct;
        quantity: number;
    }[];
    externalOrderId?: string;
    trackingCode?: string;
    lastTrace: string;
    warning?: string;
    createdAt?: string;
    updatedAt?: string;
}
export interface IShippment {
    _id: string;
    product: IProduct;
    parentProduct: IProduct;
    stock: string | IStock;
    quantity: number;
    country: string;
    warehouse: string;
    trackingCode?: string;
    lgstTrackingCode?: string;
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    createdAt?: string;
    updatedAt?: string;
}
interface IPaginatedStocks extends IPaginationData<IStock> {}

export interface IshippingHistory {
    _id: string;
    createdAt: string;
    quantity: number;
    trackingCode?: string;
    updatedAt: string;
    status?: number;
}
export const stockStatusMatch = {
    1: 'Preparing stock',
    2: 'Ready for pickup',
    3: 'Shipping',
    30: 'Stock received by carrier ',
    31: 'Sent to GuangZhou Airport ',
    32: 'Instation Scan GZ ',
    33: 'Arrived in loading warehouse ',
    34: 'Sent To HongKong ',
    35: 'Instation Scan HK ',
    36: 'Departed on Flight ',
    37: 'Arrived destination Airport ',
    38: 'Customs clearance start ',
    39: 'Customs Released_Import ',
    40: 'Arrive to headquarters warehouse ',
    4: 'Delivered',
};

export interface IShipmentTrace {
    _id: string;
    time: Date;
    logistioStatus: string;
}

export const getWarehouses = async () => {
    const config = await getBearerToken();
    const response = await axios.get(`/commons/warehouses`, config);
    return response;
};
/**
 * ⚠️ The endpoint that this function is calling is deprecated
 *  */
export const addStock = async (data: any) => {
    const config = await getBearerToken();
    const response = await axios.post(`/stocks/`, data, config);
    return response;
};

export const getStocks = async ({ pagination, filter }: { pagination: IPagination; filter: IStockFilter }) => {
    const config = await getBearerToken();
    const response = await axios.post<IPaginatedStocks>(`/stocks/filter`, { ...pagination, ...filter }, config);
    return response;
};

export const getStocksProducts = async () => {
    const config = await getBearerToken();
    const response = await axios.get<IProduct[]>(`/stocks/products`, config);
    return response;
};

export const getStockShipments = async (id: string) => {
    const config = await getBearerToken();
    const response = await axios.get<IShippment[]>(`/stocks/get-shipments/${id}`, config);
    return response;
};

export const getStockShipmentsByStockId = async (stockId: string) => {
    const config = await getBearerToken();
    const response = await axios.get<IShippment[]>(`/stocks/get-stock-shipments/${stockId}`, config);
    return response;
};

export const getShipmentHistory = async (shipmentId: string) => {
    const config = await getBearerToken();
    const response = await axios.get(`/stocks/shipments/get-shipment-history/${shipmentId}`, config);
    return response;
};
