import { IdateRange } from 'pages/dashboard/dashboard.types';
import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';

export type PRICING_ITEM_ELEMENTS =
    | '_id'
    | 'country'
    | 'deliveredLeadPrice'
    | 'shippedLeadPrice'
    | 'percentFromSellPrice'
    | 'deliveredPriceforCC'
    | 'currencyValue';
export type CONFIG_TYPE = 'PRICING' | 'BANKING';
export interface ICodPricingItem {
    _id?: string;
    country: string;
    newLeadPrice: number;
    confirmedLeadPrice: number;
    deliveredLeadPrice: number;
    shippedLeadPrice: number;
    percentFromSellPrice: number;
    deliveredPriceforCC: number;
    currencyValue: number;
    upDownCross: number;
}
interface IConfigurationHistoryFilter {
    dateRange: IdateRange;
}
export interface ICodNoCCPricing {
    _id?: string;
    country: string;
    deliveredLeadPrice: number;
    shippedLeadPrice: number;
    percentFromSellPrice: number;
    handleFees: number;
    currencyValue: number;
}
export interface IPrepaidNoCCPricing {
    _id?: string;
    country: string;
    shippedLeadPrice: number;
    handleFees: number;
    currencyValue: number;
}
export interface ICurrencyRate {
    _id?: string;
    country: string;
    currencyValue: number;
}
export interface IPricingConfiguration {
    codWithCC: Array<ICodPricingItem>;
    codNoCC: Array<ICodNoCCPricing>;
    prepaidNoCC: Array<IPrepaidNoCCPricing>;
    currencyRate: Array<ICurrencyRate>;
}

export interface ISimpleBank {
    _id?: string;
    accountCurrency: string;
    paymentType: string;
    accountHolder: string;
    accountType: string;
    routingNumber: string;
    accountNumber: string;
    iban: string;
    swiftBic: string;
    description: string;
}

export interface IFinanceService {
    _id?: string;
    email: string;
    serviceType: string;
}

export interface IBankInformations {
    simpleBank: Array<ISimpleBank>;
    financeService: Array<IFinanceService>;
}
export interface IWalletCurrency {
    currency: string;
    currencyValue: number;
}
export interface IConfiguration {
    pricing?: IPricingConfiguration;
    bankInformations?: IBankInformations;
    walletCurrency?: IWalletCurrency;
    order?: {
        assignUnreachableAfterHours: number;
        orderMaxUnreachable: number;
    };
    jobs?: {
        orderAssignSchedule: string;
        maxOngoingOrdersByAgent: number;
    };
    _id?: string;
}
// export interface IPricingConfig extends IContryConfig {
//     _id?: string;
// }
export interface IConfigurationHistory {
    _id?: string;
    changeType: CONFIG_TYPE;
    doneBy: string;
    oldConfiguration: IConfiguration;
    newConfiguration: IConfiguration;
    createdAt: string;
}
export interface IPaginationData<T> {
    pricing: T[];
    message: string;
    _id: string;
}

export const getConfiguration = async () => {
    const config = await getBearerToken();
    const response = await axios.get<IConfiguration>(`/configuration`, config);
    return response;
};
export const getConfigurationHistory = async (data: IConfigurationHistoryFilter) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<IConfigurationHistory[]>(`/configuration/HistoryList`, { ...data }, config);
    return orderHistory;
};
