import { Box } from '@mui/material';
import { Avatar } from 'flowbite-react';
import React, { useState } from 'react';
import { MessageBox, receivedMessage, sentMessage } from './MessageText.styles';
import { MessageTextProps } from './MessageText.types';

const MessageText: React.FC<MessageTextProps> = ({ message, timestamp, received, entity }) => {
    const [showTs, setShowTs] = useState(false);
    const getMessageTimeStamp = (ts: string) => {
        const isSameDay = new Date(ts).toDateString() === new Date().toDateString();
        if (isSameDay) {
            return new Date(ts).toLocaleTimeString();
        }
        return new Date(ts).toLocaleString();
    };
    const urlRegex = /(?:^|[^"'])(\b(?:https?:(?:\/\/)?|www\.)\S+\b)/gi;

    const formatTextWithLinks = (text: any) => {
        const isUrl = urlRegex.test(text);
        if (isUrl) {
            return (
                <a
                    href={text.startsWith('http:') || text.startsWith('https:') ? text : `https://${text}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                >
                    <p>{text}</p>
                </a>
            );
        }
        return <p>{text}</p>;
    };
    return (
        <Box sx={MessageBox(received || false)}>
            {entity && received && <Avatar img={entity?.profileImage ?? ''} size="md" rounded />}
            <div className={`flex flex-col gap-2 max-w-[50%] w-fit ${received ? 'items-start' : 'items-end'}`}>
                <Box
                    sx={received ? receivedMessage : sentMessage}
                    onClick={() => {
                        setShowTs(!showTs);
                    }}
                >
                    {formatTextWithLinks(message)}
                </Box>

                {timestamp && showTs && (
                    <p className="opacity-70 text-[10px] font-normal">{getMessageTimeStamp(timestamp)}</p>
                )}
            </div>
            {/* {entity && !received && <Avatar img={getUser().profileImage ?? ''} size="md" rounded />} */}
        </Box>
    );
};

export default MessageText;
