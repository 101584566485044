/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import { Label, TextInput, Button, Select } from 'flowbite-react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { FaSave } from 'react-icons/fa';
import { HiPencilAlt } from 'react-icons/hi';
import CustomError from 'components/customError/CustomError';
import { logistioCountries } from 'utils/countryList';
import { v4 as uuidv4 } from 'uuid';
import { orderUpdateCoordinateSchema } from 'utils/validations/order';
import vnlinCities from 'utils/helpers/vnlin/vnlinCities.json';

interface IformikValues {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    country: string;
    city: string;
    zip: string;
    updateType: 2;
}
const SelectField = (props: any) => {
    const { name } = props;
    let citiesList: (typeof vnlinCities)[keyof typeof vnlinCities] = [];
    const getCitiesContent = (country: string) => {
        const content = [];
        citiesList =
            vnlinCities[country as keyof typeof vnlinCities].sort((a, b) => {
                let keyA = a.vnlinLabel.toLowerCase();
                let keyB = b.vnlinLabel.toLowerCase();
                if (keyA.includes('/')) {
                    const labels = keyA.split('/'); // this is the english label
                    // eslint-disable-next-line prefer-destructuring
                    keyA = labels[1];
                }
                if (keyB.includes('/')) {
                    const labels = keyB.split('/'); // this is the english label
                    // eslint-disable-next-line prefer-destructuring
                    keyB = labels[1];
                }

                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            }) || [];

        for (const item of citiesList) {
            content.push(
                <option key={uuidv4()} value={item.vnlinLabel}>
                    {item.vnlinLabel.includes('/')
                        ? item.vnlinLabel.split('/').reverse().join('/') // display `eng label`/`ar label`
                        : item.vnlinLabel}
                </option>,
            );
        }
        return content;
    };
    const {
        values: { country },
        setFieldValue,
    } = useFormikContext<IformikValues>();
    const [field] = useField(props);

    React.useEffect(() => {
        // set the value of textC, based on textA and textB
        setFieldValue(name, citiesList[0]?.vnlinLabel || '');
    }, [country, setFieldValue, name]);

    return (
        <div className="relative w-full mb-2 group">
            <div className="mb-2 block">
                <Label htmlFor="cities" value="Select city" />
            </div>
            <Select id="cities" {...props} {...field}>
                <option value="">Select city from the list</option>
                {getCitiesContent(country)}
            </Select>
        </div>
    );
};
export const OrderInformation: React.FC<{
    data: any;
    editInfo: boolean;
    setEditInfo: React.Dispatch<React.SetStateAction<boolean>>;
    updateOrderInformations: React.Dispatch<React.SetStateAction<any>>;
}> = ({ data, editInfo, setEditInfo, updateOrderInformations }) => {
    const editDisplay = [100, 99].includes(Number(data?.status)) || (Number(data?.status) === 1 && !data.callAgent);

    const getCountriesCurrency = React.useCallback((selectedCountry: string) => {
        return (
            logistioCountries.find(
                (country) =>
                    country.label === selectedCountry ||
                    country.label === data?.country ||
                    country.code === data?.country,
            )?.currency || '--'
        );
    }, []);

    return (
        <div className="p-2 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-end space-x-4 w-full">
                <div className="inline-flex items-center  text-base px-4 font-semibold text-gray-900 dark:text-white">
                    {!editInfo && editDisplay && (
                        <Button
                            pill
                            onClick={() => {
                                setEditInfo(true);
                            }}
                        >
                            <HiPencilAlt className="mr-2 h-5 w-5" />
                            Edit
                        </Button>
                    )}
                </div>
            </div>
            {editInfo ? (
                <Formik
                    initialValues={{
                        company: data?.company,
                        province: data?.province,
                        phone: data?.phone,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        address1: data?.address1,
                        address2: data?.address2,
                        country:
                            logistioCountries.find(
                                (country) => country.label === data?.country || country.code === data?.country,
                            )?.label || logistioCountries[0].label,
                        city: data?.city,
                        zip: data?.zip,
                        totalPrice: data?.totalPrice || '',
                        orderRef: data?.orderRef || '',
                    }}
                    validationSchema={orderUpdateCoordinateSchema}
                    onSubmit={(values, onSubmitProps) => {
                        const draft: { [k in keyof typeof values]: (typeof values)[k] } = {
                            ...values,
                            totalPrice: Number(values.totalPrice),
                        };
                        if ((data?.orderRef || '') === draft.orderRef) {
                            delete draft.orderRef;
                        }
                        updateOrderInformations(draft);
                        onSubmitProps.setSubmitting(false);
                    }}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="grid md:grid-cols-2 p-2 gap-4 w-full dark:bg-gray-800">
                                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <div className="items-center justify-between">
                                        <div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="orderRef" value="Order ref" />
                                                </div>
                                                <TextInput
                                                    id="orderRef"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('orderRef')}
                                                    helperText={
                                                        <>
                                                            <CustomError name="orderRef" component="div" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="First name" />
                                                </div>
                                                <TextInput
                                                    id="firstname"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('firstName')}
                                                    helperText={
                                                        <>
                                                            <CustomError name="firstName" component="div" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Last name" />
                                                </div>
                                                <TextInput
                                                    id="lastname"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('lastName')}
                                                />
                                            </div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Company" />
                                                </div>
                                                <TextInput
                                                    id="company"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('company')}
                                                />
                                            </div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Phone" />
                                                </div>
                                                <TextInput
                                                    id="company"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('phone')}
                                                    helperText={
                                                        <>
                                                            <CustomError name="phone" component="div" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <div className="relative w-full group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Province" />
                                                </div>
                                                <TextInput
                                                    id="company"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('province')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <div className="items-center justify-between">
                                        <div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Adress 1" />
                                                </div>
                                                <TextInput
                                                    id="adress1"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('address1')}
                                                    helperText={
                                                        <>
                                                            <CustomError name="address1" component="div" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Adress 2" />
                                                </div>
                                                <TextInput
                                                    id="adress2"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('address2')}
                                                />
                                            </div>
                                            <div className="relative w-full group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="country" value="Country" />
                                                </div>
                                                <Select
                                                    id="countries"
                                                    required
                                                    {...formik.getFieldProps('country')}
                                                    name="country"
                                                    disabled={Number(data?.status) !== 99}
                                                    sizing="md"
                                                    helperText={
                                                        // eslint-disable-next-line react/jsx-fragments
                                                        <React.Fragment>
                                                            <CustomError name="country" component="div" />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    {logistioCountries.map((country) => (
                                                        <option key={country.code} value={country.label}>
                                                            {country.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <SelectField name="city" />
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Zip" />
                                                </div>
                                                <TextInput
                                                    id="company"
                                                    type="text"
                                                    sizing="md"
                                                    {...formik.getFieldProps('zip')}
                                                />
                                            </div>
                                            <div className="relative w-full mb-2 group">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="totalPrice" value="Total price" />
                                                </div>
                                                <div className="flex items-center dark:bg-gray-700 bg-gray-200 rounded-md">
                                                    <TextInput
                                                        className="flex-3"
                                                        id="totalPrice"
                                                        type="text"
                                                        sizing="md"
                                                        {...formik.getFieldProps('totalPrice')}
                                                    />

                                                    <div className="flex-1 flex items-center justify-center">
                                                        <p className="font-medium">
                                                            {getCountriesCurrency(
                                                                formik.getFieldProps('country')?.value,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-4 w-full  justify-between p-2">
                                <div>
                                    <Button
                                        pill
                                        color="failure"
                                        onClick={() => {
                                            setEditInfo(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        pill
                                        type="submit"
                                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                    >
                                        <FaSave className="mr-2 h-5 w-5" />
                                        Save & Confirm
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <div className="grid md:grid-cols-2 p-4 mb-2 gap-4 w-full rounded-lg dark:bg-gray-800">
                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="items-center justify-between mb-4">
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Order ref:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.orderRef || '--'}
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Full Name:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.fullName}
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Company:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.company}
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Phone:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.phone}
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Province:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.province}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="items-center justify-between mb-4">
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Address 1:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.address1}
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Address 2:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.address2}
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Country:
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.country}
                                        </div>
                                    </div>
                                </li>
                                <li className="pt-3 pb-0 sm:pt-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                City :
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.city}
                                        </div>
                                    </div>
                                </li>
                                <li className="pt-3 pb-0 sm:pt-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Zip :
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.zip}
                                        </div>
                                    </div>
                                </li>
                                <li className="pt-3 pb-0 sm:pt-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                                Total price (
                                                {logistioCountries.find(
                                                    (country) =>
                                                        country.label === data?.country ||
                                                        country.code === data?.country,
                                                )?.currency || '--'}
                                                ) :
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {data?.totalPrice || '--'}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
