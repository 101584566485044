/* eslint-disable react/no-array-index-key */
import React from 'react';
import ShippingToCountryFlag from 'components/flags/shippingToCountriesFlags';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { sellerCatergories } from 'utils/sellersCategories';
import { IShopProductCard } from './shopProductCard.types';
import productImage from '../../assets/package.png';

const ShopProductCard: React.FC<IShopProductCard> = ({
    productTitle,
    countries,
    category,
    thumbnail,
    averagePrice,
}) => {
    const findCategoryDetails = (categoryName: string) => {
        const foundCategory = sellerCatergories.find(
            (cat) => cat.name.toLocaleLowerCase() === categoryName.toLocaleLowerCase().trim(),
        );
        return foundCategory;
    };

    const categoryDetails = category?.map((categoryName) => findCategoryDetails(categoryName)).filter(Boolean);

    const productCostsArray: any = [];
    averagePrice.forEach((quot: any) => {
        productCostsArray.push(quot?.sellingPrice);
    });

    const priceDisplay = () => {
        const maxPrice = Math.max(...productCostsArray);
        const minPrice = Math.min(...productCostsArray);
        if (maxPrice === minPrice) return currencyConverter(maxPrice, '');
        if (productCostsArray.length === 0) return currencyConverter(productCostsArray[0], '');
        return `${currencyConverter(maxPrice, '')} - ${currencyConverter(minPrice, '')}`;
    };

    const titleUpperCase = (phrase: string): string => {
        const words = phrase.split(' ');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return capitalizedWords.join(' ');
    };

    return (
        <>
            <div
                className="h-full max-w-[350px] bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:border-gray-700 hover:cursor-pointer"
                title={titleUpperCase(`${productTitle}`)}
            >
                <div className="bg-[#F7F7F7] dark:bg-[#161d27] flex justify-center pt-5 px-2 pb-2">
                    <img
                        className="h-[250px]  object-contain object-center rounded-t-md"
                        src={thumbnail || productImage}
                        // src="https://m.media-amazon.com/images/I/61KC+QfpmIL._AC_UL320_.jpg"
                        alt="product"
                    />
                </div>
                <div className="px-5 py-2 flex flex-col gap-3 border-t border-gray-200 dark:border-gray-700 ">
                    <div>
                        <div className="flex">
                            <p className="truncate text-xl font-medium font-popin text-gray-900 dark:text-white w-80 overflow-hidden overflow-ellipsis  whitespace-nowrap">
                                {titleUpperCase(`${productTitle}`)}
                            </p>
                        </div>
                        <div className="flex gap-1">
                            {categoryDetails?.map((cat) => (
                                <div
                                    title={cat?.name}
                                    className={`truncate bg-${cat?.color}-100 text-${cat?.color}-800 text-xs font-medium px-1 py-0.5 dark:bg-gray-700 dark:text-${cat?.color}-400 border border-${cat?.color}-600 rounded-full`}
                                >
                                    {cat?.name}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col gap-6 ">
                        <div className="flex items-center ">
                            <div className="flex items-center space-x-1 rtl:space-x-reverse">
                                {countries.map((country, index) => (
                                    <ShippingToCountryFlag flag={country} key={index} />
                                ))}
                            </div>
                        </div>
                        <div className="">
                            <span className="text-xl font-medium font-popin  text-gray-900 dark:text-white">
                                {priceDisplay()}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopProductCard;
