/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IStockFilter, IStock, getStocks, getStocksProducts } from 'services/stock';
import { IPagination } from 'utils/Interfaces/types';
import { vnlinWarehouses } from 'utils/helpers/vnlin/warehouses';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import DataTable from 'components/tables/tables';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { IProduct } from 'utils/Interfaces/products.interface';
import InputField from 'components/inputField/InputField';
import { IoIosWarning } from 'react-icons/io';
import { IWarning } from 'utils/Interfaces/warnings.interface';
import { checkStocksWarnings, getActiveWarnings } from 'services/warnings';
import LowStocksModal from 'components/lowStocksModal/LowStocksModal';
import { columns } from './StockListElements';

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

export default function StockList() {
    const location = useLocation();
    const state = location?.state;
    const [stockList, setStockList] = useState<IStock[]>([]);
    // values for filtering
    const [productsList, setProductsList] = useState<{ _id: string; name: string; parentProduct: IProduct }[]>([]);
    const [warnings, setWarnings] = useState<IWarning[]>([]);
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        limit: 10,
    });
    const [filter, setFilter] = useState<IStockFilter>({ product: state?.search || undefined });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    // fetch products list that have stocks for filter
    useEffect(() => {
        checkStocksWarnings().then(() => {
            getActiveWarnings().then((activeWarnings) => {
                setWarnings(activeWarnings);
            });
        });
        getStocksProducts()
            .then((res) => {
                setProductsList(res.data);
            })
            .catch(() => {
                toast.error('Sorry, something went wrong! Try reloading the page.');
            });
    }, []);

    // paginate stocks
    useEffect(() => {
        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        if (!fetchedAndActive) {
            setIsLoading(true);
            getStocks({ pagination, filter })
                .then((res) => {
                    const { data } = res;
                    setStockList(data.docs);
                    setMeta({
                        hasNextPage: data.hasNextPage,
                        hasPrevPage: data.hasPrevPage,
                        limit: data.limit,
                        nextPage: data.nextPage,
                        page: data.page,
                        pagingCounter: data.pagingCounter,
                        prevPage: data.prevPage,
                        totalDocs: data.totalDocs,
                        totalPages: data.totalPages,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.errors?.message);
                });
        }
    }, [filter, pagination]);

    // const handleLimitChange = (event: SelectChangeEvent) => {
    //     setPagination({ ...pagination, page: 1, limit: Number(event.target.value) });
    //     setMeta({ ...meta, page: -1 });
    // };

    const handlePageChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleFilterChange = (newFilter: IStockFilter) => {
        if (newFilter.sku === '') {
            const draftFilter = { ...newFilter };
            delete draftFilter.sku;
            setFilter({ ...draftFilter });
        } else {
            setFilter((prev) => ({ ...prev, ...newFilter }));
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    DynamicTitle('Stocks');

    return (
        <Box>
            <div>
                <div>
                    {warnings.length > 0 && (
                        <section className="bg-red-200 mb-3 flex items-center justify-between gap-1 p-2 sm:rounded-lg hover:border-red-700 border-[1px] border-transparent">
                            <div className="flex items-center justify-start gap-1">
                                <IoIosWarning className="text-red-700 h-8 w-8" />
                                <p className="text-gray-800 font-medium text-sm">
                                    You have {warnings.length} low quantity stocks
                                </p>
                            </div>
                            <LowStocksModal warnings={warnings} />
                        </section>
                    )}
                </div>
                <div className="flex justify-between items-center w-full">
                    {/* Filtering options */}
                    <div className=" flex gap-2 flex-wrap">
                        <FormControl size="small" sx={{ minWidth: 200 }}>
                            <InputLabel id="product-filter-select-label">Product</InputLabel>
                            <Select
                                labelId="product-filter-select-label"
                                label="Product"
                                id="product-filter-select"
                                name="productFilter"
                                value={filter.product || ''}
                                onChange={(e) => {
                                    if (e.target.value !== '') {
                                        handleFilterChange({ product: e.target.value });
                                    } else {
                                        setFilter({ ...filter, product: '' });
                                        setMeta({ ...meta, page: -1 });
                                    }
                                }}
                            >
                                <MenuItem value="">All Products</MenuItem>
                                {productsList &&
                                    productsList.map((product) => {
                                        return (
                                            <MenuItem value={product._id} key={product._id}>
                                                {product?.parentProduct?.name || ''} {product.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl size="small" sx={{ minWidth: 200 }}>
                            <InputLabel id="country-filter-select-label">Country</InputLabel>
                            <Select
                                labelId="country-filter-select-label"
                                label="Country"
                                id="country-filter-select"
                                name="countryFilter"
                                value={filter.country || ''}
                                onChange={(e) => {
                                    if (e.target.value !== '') {
                                        handleFilterChange({ country: e.target.value });
                                    } else {
                                        setFilter({ ...filter, country: '' });
                                        setMeta({ ...meta, page: -1 });
                                    }
                                }}
                            >
                                <MenuItem value="">All Countries</MenuItem>
                                {Object.keys(vnlinWarehouses).map((country, _idx) => {
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <MenuItem value={country} key={_idx}>
                                            {country}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl size="small" sx={{ minWidth: 300 }}>
                            <InputField
                                InputType="search"
                                placeholder="Search by SKU"
                                inputSize="small"
                                name="search"
                                onChange={(e) => {
                                    handleFilterChange({ sku: e.target.value });
                                }}
                                className="!w-[290px]"
                            />
                        </FormControl>
                    </div>
                </div>

                <DataTable
                    rows={stockList}
                    setRows={setStockList}
                    columns={columns}
                    pageState={{
                        isLoading,
                        total: meta.totalDocs,
                        pageSize: pagination.limit,
                        page: pagination.page,
                        count: meta.totalPages,
                    }}
                    setPageState={handlePageChange}
                />
            </div>
        </Box>
    );
}
