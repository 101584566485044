import GeneralButton from 'components/customButton/generalButton';
import CustomError from 'components/customError/CustomError';
import PasswordInputField from 'components/inputField/generalInputField';
import { Label } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateCustomer } from 'services/customer';
import { changePasswordSchema } from 'utils/validations/accountInformationValidation';
import { UserId } from '../../../profileSettings/ProfileSettings.types';

const PasswordInfo: React.FC<UserId> = ({ id }) => {
    const [profileLoader, setProfileLoader] = useState(false);

    const updatePassword = (values: { password: string; oldPassword: string }) => {
        setProfileLoader(true);
        updateCustomer(id, values)
            .then(() => {
                // openNotif(res.data.message);
                toast.success('Password updated successully');
                setProfileLoader(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setProfileLoader(false);
            });
    };
    return (
        <>
            <Formik
                initialValues={{
                    password: '',
                    oldPassword: '',
                }}
                onSubmit={(values, actions) => {
                    updatePassword(values);
                    actions.setSubmitting(false);
                    actions.resetForm();
                }}
                validationSchema={changePasswordSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col gap-10">
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="Old Password" value="Old Password" className="text-lg font-medium" />
                                <PasswordInputField
                                    placeholder="Old Password"
                                    {...formik.getFieldProps('oldPassword')}
                                    name="oldPassword"
                                />
                                <CustomError name="oldPassword" component="div" />
                            </div>
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="New Password" value="New Password" className="text-lg font-medium" />
                                <PasswordInputField
                                    placeholder="New Password"
                                    {...formik.getFieldProps('password')}
                                    name="password"
                                />

                                <CustomError name="password" component="div" />
                            </div>

                            <div className="flex justify-end items-center">
                                <GeneralButton
                                    color="blue"
                                    type="submit"
                                    disabled={profileLoader}
                                    isProcessing={profileLoader}
                                >
                                    Save Changes
                                </GeneralButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PasswordInfo;
