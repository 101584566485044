/* eslint-disable consistent-return */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { getCurrentUser } from 'utils/helpers/auth';
import { Banner } from 'flowbite-react';
import toast from 'react-hot-toast';
import { getStatsByOrders } from 'services/order';
import WidgetPercentIcon from 'components/dashboardCard/widgetPercentIcon';
import { IoMdInformationCircle } from 'react-icons/io';
import { IfilterDashboard } from '../dashboard.types';

const GetStats = ({
    statsData,
}: {
    statsData: {
        'Pick and pack done': number;
        'Failed Deliveries': number;
        'Being delivered': number;
        'Returned to stock (Of failed deliveries)': number;
        'Delivered Orders': number;
    };
}) => {
    return (
        <>
            {Object.keys(statsData).map((dataType, x) => {
                if (dataType !== 'Pick and pack done' && dataType !== 'Returned to stock (Of failed deliveries)') {
                    return (
                        <WidgetPercentIcon
                            key={Object.keys(statsData)[x]}
                            title={dataType}
                            inputValue={statsData[dataType as keyof typeof statsData]}
                            statPourcentage={
                                statsData['Pick and pack done'] > 0
                                    ? (statsData[dataType as keyof typeof statsData] /
                                          statsData['Pick and pack done']) *
                                      100
                                    : 0
                            }
                        />
                    );
                }
                if (dataType === 'Returned to stock (Of failed deliveries)') {
                    return (
                        <WidgetPercentIcon
                            key={Object.keys(statsData)[x]}
                            title={dataType}
                            inputValue={statsData[dataType as keyof typeof statsData]}
                            statPourcentage={
                                statsData['Failed Deliveries'] > 0
                                    ? (statsData[dataType as keyof typeof statsData] / statsData['Failed Deliveries']) *
                                      100
                                    : 0
                            }
                        />
                    );
                }
            })}
        </>
    );
};
const LeadsBased = ({ filter }: { filter: IfilterDashboard }) => {
    const [delivAttempts, setDelivAttempts] = useState(0);
    const [statsData, setStatsData] = useState<{
        'Pick and pack done': number;
        'Failed Deliveries': number;
        'Being delivered': number;
        'Returned to stock (Of failed deliveries)': number;
        'Delivered Orders': number;
    }>({
        'Pick and pack done': 0,
        'Failed Deliveries': 0,
        'Being delivered': 0,
        'Returned to stock (Of failed deliveries)': 0,
        'Delivered Orders': 0,
    });

    useEffect(() => {
        getStatsByOrders(filter)
            .then((res) => {
                const data: typeof statsData = {
                    'Pick and pack done': 0,
                    'Being delivered': 0,
                    'Delivered Orders': 0,
                    'Failed Deliveries': 0,
                    'Returned to stock (Of failed deliveries)': 0,
                };
                let deliveryAttempts = 0;
                res.data.orders.map((stat) => {
                    if (stat.deliveryAttempts) {
                        deliveryAttempts += stat.deliveryAttempts;
                    }
                    if (
                        [8, 9, 10, 11, 12, 81, 82, 83, 84, 101, 102, 103, 104, 105, 111, 112, 113, 114].includes(
                            stat.status,
                        )
                    ) {
                        data['Pick and pack done'] += stat.count;
                    }
                    if (stat.status === 114) {
                        data['Returned to stock (Of failed deliveries)'] = stat.count;
                    }
                    if (stat.status === 12) {
                        data['Delivered Orders'] = stat.count;
                    }

                    if (stat.deliveryFailed) {
                        data['Failed Deliveries'] += stat.count;
                    } else if (
                        [81, 82, 83, 84, 9, 10, 101, 102, 103, 104, 105].includes(stat.status) &&
                        !stat.deliveryFailed
                    ) {
                        data['Being delivered'] += stat.count;
                    }
                });
                setStatsData(data);
                setDelivAttempts(deliveryAttempts);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [filter]);

    return (
        <div>
            <Banner>
                <div className="flex mb-2 w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-transparent md:flex-row">
                    <div className="mb-4 md:mb-0 md:mr-4 flex gap-2">
                        <IoMdInformationCircle size={28} className="text-main" />
                        <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400 gap-2 pt-[2px]">
                            <span className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Note:</span>
                            Status updates for the orders generated during the selected time period
                        </p>
                    </div>
                </div>
            </Banner>
            <h2 className="my-3">Delivery rate</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <WidgetPercentIcon
                    key={`Pick and pack done{${statsData['Pick and pack done']}`}
                    title="Pick and pack done"
                    inputValue={statsData['Pick and pack done']}
                    statPourcentage={
                        statsData['Pick and pack done'] > 0
                            ? (statsData['Pick and pack done'] / statsData['Pick and pack done']) * 100
                            : 0
                    }
                />
                <WidgetPercentIcon
                    key={`DeliveredOrders{${statsData['Delivered Orders']}`}
                    title="Delivered Orders"
                    inputValue={statsData['Delivered Orders']}
                    statPourcentage={
                        statsData['Pick and pack done'] > 0
                            ? (statsData['Delivered Orders'] / statsData['Pick and pack done']) * 100
                            : 0
                    }
                />
            </div>
            <h2 className="my-3">Current status</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <GetStats statsData={statsData} />
            </div>
            <h2 className="my-3">Actions done</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <WidgetPercentIcon key="deliveryAttempts" title="Delivery Attempts done" inputValue={delivAttempts} />
            </div>
        </div>
    );
};

export default LeadsBased;
