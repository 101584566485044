/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/style-prop-object */
import React from 'react';
// import ListSku from 'components/listSku/ListSku';
// import AddOffer from 'components/addOffer/AddOffer';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge, Tooltip } from 'flowbite-react';
import { FaCircle } from 'react-icons/fa6';
import { HiDotsHorizontal } from 'react-icons/hi';
import { getExpiredDate } from 'utils/helpers/expiredDate';
import { getCounteries, getTotalQuantity } from 'utils/helpers/getVariantInfo';
import { getTotalPrice } from 'utils/helpers/product';
import ProductPayment from './productTableActions/productPayment';
import ProductTableActions from './productTableActions/productTableActions';

export const StatusIcon = ({ name, color, style }: any) => (
    // <span
    //     className={`${style} flex gap-1 bg-${color}-100 text-${color}-500 h-[30px] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full !dark:bg-${color}-900 !dark:text-${color}-300 items-center`}
    // >
    //     <FaCircle size="8px" />
    //     {name}
    // </span>
    <span
        className={`${style} flex gap-1 ${color}Tag h-[30px] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full  items-center`}
    >
        <FaCircle size="8px" />
        {name}
    </span>
);
export const statusDisplay: { [keys: string]: IStausInterface } = {
    '1': {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending delivery
            </Badge>
        ),
        statusLabel: 'Pending delivery',
    },
    '12': {
        statusIcon: (
            <Badge color="success" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    '13': {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    '14': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    '20': {
        statusIcon: (
            <Badge color="success" size="sm">
                Partially matched
            </Badge>
        ),
        statusLabel: 'Partially matched',
    },
    '21': {
        statusIcon: (
            <Badge color="success" size="sm">
                Being shipped to warehouse
            </Badge>
        ),
        statusLabel: 'Being shipped to warehouse',
    },
    '99': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Product not available
            </Badge>
        ),
        statusLabel: 'Product not available',
    },
};

export const statusDisplayColor: { [keys: string]: IStausInterface } = {
    '1': {
        statusIcon: <StatusIcon name="Pending" style="blueTag" />,
        statusLabel: 'Pending',
    },
    '2': {
        statusIcon: <StatusIcon name="Under review" style="blueTag" />,
        statusLabel: 'Under review',
    },
    '3': {
        statusIcon: <StatusIcon name="Quoted" style="greenTag" />,
        statusLabel: 'Quoted',
    },
    '41': {
        statusIcon: (
            <Badge color="success" size="sm">
                Reviewing quotations
            </Badge>
        ),
        statusLabel: 'Customer reviewing quotations',
    },
    '42': {
        statusIcon: <StatusIcon name="Quote rejected" style="redTag" />,
        statusLabel: 'Quote rejected',
    },
    '69': {
        statusIcon: <StatusIcon name="Decision submitted" style="blueTag" />,
        statusLabel: 'Decision submitted',
    },
    '43': {
        statusIcon: <StatusIcon name="Payment verification" style="blueTag" />,
        statusLabel: 'Payment verification',
    },
    '5': {
        // eslint-disable-next-line react/style-prop-object
        statusIcon: <StatusIcon name="Payment pending" style="yellowTag" />,
        statusLabel: 'Waiting for payment',
    },
    '6': {
        statusIcon: <StatusIcon name="Paid" style="greenTag" />,
        statusLabel: 'Paid',
    },
    // STATUS 7 WILL BE DEPRECATED
    // '7': {
    //     statusIcon: (
    //         <Badge color="info" size="sm">
    //             --
    //         </Badge>
    //     ),
    //     statusLabel: '--',
    // },
    '8': {
        statusIcon: <StatusIcon name="Preparing stock" style="greenTag" />,
        statusLabel: 'Preparing stock',
    },
    '30': {
        statusIcon: <StatusIcon name="Payment declined" style="redTag" />,
        statusLabel: 'Payment declined',
    },
    '9': {
        statusIcon: (
            <Badge color="success" size="sm">
                Waiting for pickup
            </Badge>
        ),
        statusLabel: 'Waiting for pickup',
    },
    '10': {
        statusIcon: (
            <Badge color="success" size="sm">
                Picked up
            </Badge>
        ),
        statusLabel: 'Picked up',
    },
    '11': {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending delivery
            </Badge>
        ),
        statusLabel: 'Pending delivery',
    },
    '12': {
        statusIcon: (
            <Badge color="success" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    '13': {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    '14': {
        statusIcon: (
            <Badge color="failure" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    '20': {
        statusIcon: (
            <Badge color="success" size="sm">
                Partially matched
            </Badge>
        ),
        statusLabel: 'Partially matched',
    },
    '21': {
        statusIcon: (
            <Badge color="success" size="sm">
                Being shipped to warehouse
            </Badge>
        ),
        statusLabel: 'Being shipped to warehouse',
    },
    '99': {
        statusIcon: <StatusIcon name="Product unavailable" style="redTag" />,
        statusLabel: 'Product not available',
    },
    '100': {
        statusIcon: <StatusIcon name="Expired" style="redTag" />,
        statusLabel: 'Expired',
    },
};
export const initColumns: TableColDef[] = [
    {
        field: 'createdAt',
        headerName: 'created at',
        fieldType: 'date',
        dateFormat: 'DD/MM/YYYY',
        width: '60',
        classField: 'px-[9px]',
    },
    {
        field: 'design',
        headerName: 'Picture',
        fieldType: 'image',
        widthName: true,
    },
    // {
    //     field: 'name',
    //     headerName: 'product Name',
    // },

    {
        field: 'country',
        headerName: 'Country',
        fieldType: 'render',
        renderCell: (params) => {
            const countries = getCounteries(params);
            if (!countries.length) return <></>;
            return (
                <>
                    <div className="flex items-center gap-1">
                        {countries.length > 2 ? (
                            <>
                                <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                                    {countries[0]}
                                </span>
                                <Tooltip content={countries.slice(1).join(' - ')}>
                                    <span className="bg-gray-100 cursor-pointer text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        +{countries.length - 1}
                                    </span>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                {countries.map((el: string) => (
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                                        {el}
                                    </span>
                                ))}
                            </>
                        )}
                    </div>
                </>
            );
        },
    },

    {
        field: 'options',
        headerName: 'Variant',
        fieldType: 'render',
        renderCell: (params) => {
            const variants =
                Object.keys(params?.options || {}).filter((key: string) => params.options[key]?.length) || [];
            if (!variants.length) return <></>;
            return (
                <>
                    <div className="flex items-center gap-1">
                        <>
                            {variants.length > 2 ? (
                                <>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {variants[0]}
                                    </span>
                                    <Tooltip content={variants.slice(1).join(' - ')}>
                                        <span className="bg-gray-100 cursor-pointer text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                            +{variants.length - 1}
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    {variants.map((el: string) => (
                                        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                            {el}
                                        </span>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                </>
            );
        },
    },
    {
        field: 'quantity',
        headerName: 'Total Qty',
        fieldType: 'render',
        classHeader: 'text-center',
        renderCell: (params) => {
            const Total = getTotalQuantity(params);
            return (
                <>
                    <div className="flex items-center  justify-around">{Total}</div>
                </>
            );
        },
    },
    {
        field: 'updatedAt',
        headerName: 'Update at',
        fieldType: 'date',
        dateFormat: 'DD/MM/YYYY',
        width: '60',
        classField: 'px-[9px]',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params) => {
            const expiredIn =
                [3, 5].includes(params.status) &&
                params.expirationDate &&
                getExpiredDate(params.expirationDate)?.expiredIn;
            const status = [8, 21].includes(params.status) ? 6 : params.status;
            return (
                <>
                    <div className="flex items-center gap-1 mb-1">
                        {statusDisplayColor && status && statusDisplayColor[status]?.statusIcon
                            ? statusDisplayColor[status].statusIcon
                            : '--'}
                    </div>
                    {expiredIn && <span className="text-red-500">Expires in {expiredIn}</span>}
                </>
            );
        },
    },
    {
        field: 'Action',
        headerName: '',
        actionType: 'render',
        renderCell: (params) => {
            const store = params?.store;
            const setRows = params?.setRows;
            const setActiveTab = params?.setActiveTab;
            if (params?.store) delete params?.store;
            if (params?.setRows) delete params?.setRows;
            if (params?.setActiveTab) delete params?.setActiveTab;
            return (
                <div className="flex gap-3 justify-end mr-[50%] mb-[10px]">
                    {params.status === 5 && <ProductPayment product={params} />}
                    <ProductTableActions
                        product={params}
                        setRows={setRows}
                        currentStore={store}
                        Icon={<HiDotsHorizontal />}
                        setActiveTab={setActiveTab}
                    />
                </div>
            );
        },
    },
];
export const ToTalPriceCol = {
    field: 'price',
    headerName: 'Total Price($)',
    fieldType: 'render',
    classHeader: 'text-center',
    renderCell: (params: any) => {
        const Total = getTotalPrice(params.quotation);

        return (
            <>
                <div className="flex items-center  justify-around">{Total}</div>
            </>
        );
    },
};
