import { Button, Label, Select } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { IOrder } from 'services/order';
import { sendUploadOrdersRequest } from 'utils/helpers/orders/uploadOrders/sendUploadReq';
import { uploadCODOrder } from 'utils/helpers/orders/uploadOrders/uploadCODOrder';
import vnlinCities from 'utils/helpers/vnlin/vnlinCities.json';
import { WorkSheet } from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

const ConfirmInvalidOrders: React.FC<{
    store: string;
    setErrors: React.Dispatch<
        React.SetStateAction<
            Map<
                string,
                {
                    orderCustomerFullName: string;
                    errors: string[];
                }
            >
        >
    >;
    setCounter: React.Dispatch<React.SetStateAction<{ errors: number; success: number; total: number }>>;
    setFailedOrders: React.Dispatch<React.SetStateAction<WorkSheet>>;
    setShowConfirmationUi: React.Dispatch<React.SetStateAction<boolean>>;
    parsedOrders: {
        withInvalidCities: (IOrder & {
            invalidCity?: boolean;
            closestCityMatch?: string;
        })[];
        withFailedValidation: any[];
        valid: any[];
    } | null;
    setParsedOrders: React.Dispatch<
        React.SetStateAction<{
            withInvalidCities: any[];
            withFailedValidation: any[];
            valid: any[];
        } | null>
    >;
    isWithCcEnabled?: boolean;
    cod?: boolean;
    onUploadEnd?: (...args: any[]) => any | void;
}> = ({
    setShowConfirmationUi,
    onUploadEnd,
    setParsedOrders,
    setFailedOrders,
    store,
    setErrors,
    setCounter,
    parsedOrders,
    cod,
    isWithCcEnabled,
}) => {
    const [ordersWithInvalidCities, setOrdersWithInvalidCities] = useState([
        ...(parsedOrders?.withInvalidCities || []).map((order): typeof order & { key?: string } => {
            // flip the invalidCity field because the rendered select have the
            // closestCityMatch value selected by default and on save the city
            // field will take this value anyway
            if (order.invalidCity && order.closestCityMatch) {
                const draft = { ...order, key: uuidv4() };
                draft.invalidCity = false;
                if (
                    vnlinCities[draft.country as keyof typeof vnlinCities].find(
                        (el) => el.label1 === draft.city || el.label2 === draft.city || el.vnlinLabel === draft.city,
                    ) !== undefined
                ) {
                    draft.city = vnlinCities[draft.country as keyof typeof vnlinCities].find(
                        (el) => el.label1 === draft.city || el.label2 === draft.city || el.vnlinLabel === draft.city,
                    )?.vnlinLabel;
                }

                return draft;
            }
            return { ...order, key: uuidv4() };
        }),
    ]);
    const handleSelectCity = (label: string, idx: number) => {
        const draftData = [...ordersWithInvalidCities];
        draftData[idx].city = label;
        delete draftData[idx].invalidCity;
        setOrdersWithInvalidCities([...draftData]);
    };
    const handleSaveCityFixes = async () => {
        if (cod) {
            await uploadCODOrder({
                setFailedOrders,
                orders: [
                    ...ordersWithInvalidCities.map((order) => {
                        const draft = { ...order };
                        if (draft.invalidCity !== undefined) {
                            delete draft.invalidCity;
                        }
                        if (draft.closestCityMatch) {
                            delete draft.closestCityMatch;
                        }
                        if (draft.key) {
                            delete draft.key;
                        }
                        return draft;
                    }),
                    ...(parsedOrders?.valid || []),
                    ...(parsedOrders?.withFailedValidation || []),
                ],
                setShowConfirmationUi,
                onUploadEnd,
                setParsedOrders,
                store,
                setErrors,
                setCounter,
                isWithCcEnabled: !!isWithCcEnabled,
            });
        } else {
            await sendUploadOrdersRequest({
                setFailedOrders,
                orders: [
                    ...ordersWithInvalidCities.map((order) => {
                        const draft = { ...order };
                        if (draft.invalidCity !== undefined) {
                            delete draft.invalidCity;
                        }
                        if (draft.closestCityMatch) {
                            delete draft.closestCityMatch;
                        }
                        if (draft.key) {
                            delete draft.key;
                        }
                        return draft;
                    }),
                    ...(parsedOrders?.valid || []),
                    ...(parsedOrders?.withFailedValidation || []),
                ],
                setShowConfirmationUi,
                onUploadEnd,
                setParsedOrders,
                store,
                setErrors,
                setCounter,
            });
        }
    };
    const missingRequiredAction = () => {
        let haveInvalidCity: boolean | undefined;
        if (ordersWithInvalidCities.length > 0) {
            haveInvalidCity = ordersWithInvalidCities.some((order) => order.invalidCity);
        }
        return haveInvalidCity;
    };
    const [cities, setCities] = useState<Partial<typeof vnlinCities>>({});

    useEffect(() => {
        Object.keys(vnlinCities).forEach((country) => {
            (vnlinCities[country as keyof typeof vnlinCities] || []).sort((a, b) => {
                let keyA = a.vnlinLabel.toLowerCase();
                let keyB = b.vnlinLabel.toLowerCase();
                if (keyA.includes('/')) {
                    const labels = keyA.split('/'); // this is the english label
                    // eslint-disable-next-line prefer-destructuring
                    keyA = labels[1];
                }
                if (keyB.includes('/')) {
                    const labels = keyB.split('/'); // this is the english label
                    // eslint-disable-next-line prefer-destructuring
                    keyB = labels[1];
                }

                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        });
        setCities({ ...vnlinCities });
    }, []);
    return (
        <div>
            <h4 style={{ marginBottom: 20 }}>Upload Failed</h4>
            <p className="my-2">Found invalid orders! PLease verify the orders data before uploading.</p>
            {parsedOrders?.withInvalidCities && parsedOrders?.withInvalidCities.length > 0 && (
                <div>
                    <p>Verify the city for the following orders:</p>
                    {ordersWithInvalidCities.map((invalidOrder, idx) => (
                        <div
                            className="my-4 p-2 border-gray-600 border-solid border rounded flex flex-col gap-1"
                            key={invalidOrder.key}
                        >
                            <p className="mb-2">
                                Order ref: <span className="font-bold">{invalidOrder.orderRef}</span>
                            </p>
                            <div className="flex items-center justify-between gap-2">
                                <p>
                                    Customer:{' '}
                                    <span className="font-bold">
                                        {invalidOrder.firstName} {invalidOrder.lastName}
                                    </span>
                                </p>
                                <p>
                                    Country: <span className="font-bold">{invalidOrder.country}</span>
                                </p>
                            </div>
                            <div className="flex items-center justify-between gap-2">
                                <p>
                                    City: <span className="font-bold">{invalidOrder.city || '-'}</span>
                                </p>
                                <p>
                                    Closest match found:{' '}
                                    <span className="font-bold">{invalidOrder.closestCityMatch || '-'}</span>
                                </p>
                            </div>
                            <div className="mt-3 max-w-md">
                                <div className="mb-2 block">
                                    <Label htmlFor="city" value="Select a valid city" />
                                </div>
                                <Select
                                    id="city"
                                    value={
                                        invalidOrder.city &&
                                        vnlinCities[invalidOrder.country as keyof typeof vnlinCities].find(
                                            (el) =>
                                                el.label1 === invalidOrder.city ||
                                                el.label2 === invalidOrder.city ||
                                                el.vnlinLabel === invalidOrder.city,
                                        ) !== undefined
                                            ? vnlinCities[invalidOrder.country as keyof typeof vnlinCities].find(
                                                  (el) =>
                                                      el.label1 === invalidOrder.city ||
                                                      el.label2 === invalidOrder.city ||
                                                      el.vnlinLabel === invalidOrder.city,
                                              )?.vnlinLabel
                                            : '-'
                                    }
                                    onChange={(e) => {
                                        handleSelectCity(e.target.value, idx);
                                    }}
                                >
                                    <option value="-" key="-" disabled>
                                        -
                                    </option>
                                    {(cities[invalidOrder.country as keyof typeof vnlinCities] || []).map(
                                        (cityLabels, index) => (
                                            <option
                                                value={cityLabels.vnlinLabel}
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`${index}${cityLabels.vnlinLabel}`}
                                            >
                                                {cityLabels.vnlinLabel.includes('/')
                                                    ? cityLabels.vnlinLabel.split('/').reverse().join('/') // display `eng label`/`ar label`
                                                    : cityLabels.vnlinLabel}
                                            </option>
                                        ),
                                    )}
                                </Select>
                            </div>
                        </div>
                    ))}
                    <div className="flex items-center gap-1">
                        <Button color="success" onClick={handleSaveCityFixes} disabled={missingRequiredAction()}>
                            save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfirmInvalidOrders;
