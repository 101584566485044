/* eslint-disable react/jsx-curly-brace-presence */
import { Tooltip } from 'flowbite-react';
import React, { useState, useEffect, useRef } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';

const AdaptiveTitle: React.FC<{ title: string }> = ({ title }) => {
    const [fontSize, setFontSize] = useState(16);
    const titleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const adjustFontSize = () => {
            if (titleRef.current) {
                const containerWidth = titleRef.current.offsetWidth;
                const contentWidth = titleRef.current.scrollWidth;
                if (contentWidth > containerWidth) {
                    setFontSize((prevSize) => Math.max(prevSize - 1, 10));
                }
            }
        };

        adjustFontSize();
    }, [title]);

    return (
        <div ref={titleRef} className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
            <span style={{ fontSize: `${fontSize}px` }} className="font-medium text-[#A3AED0] font-poppin">
                {title}
            </span>
        </div>
    );
};

const WidgetPercentIcon: React.FC<{
    statPourcentage?: number;
    inputValue: number | string;
    title: string;
    loading?: boolean;
    infoMessage?: string;
}> = ({ statPourcentage, inputValue, title, loading, infoMessage }) => {
    return (
        <>
            {loading ? (
                <div className=" h-28 min-w-0 bg-white dark:bg-gray-700 sm:rounded-lg overflow-hidden py-2 flex flex-col gap-2 shadow-sm border border-gray-100 dark:border-gray-700 animate-pulse">
                    <div>
                        <div className="flex justify-center">
                            <div className="h-2.5 bg-gray-400 rounded-full dark:bg-indigo-200 w-48" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className=" col-span-2 flex flex-col justify-center items-center">
                            <div className=" ">
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-indigo-200 w-36 mb-4" />

                                <div className=" flex justify-end">
                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-indigo-200  mb-4 w-20" />
                                </div>
                            </div>
                        </div>
                        <div className=" flex justify-center items-center ">
                            <div className=" bg-gray-300 rounded-full dark:bg-indigo-200  mb-4 h-16 w-16 " />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="h-28 relative bg-white rounded-2xl dark:bg-gray-800 hover:shadow-lg hover:scale-105">
                    <div className="w-48 h-14 left-20 top-6 absolute">
                        <div className="flex justify-between items-center">
                            <AdaptiveTitle title={title} />
                        </div>
                        <div className="w-36 h-8 left-0 top-6 absolute text-[#2B3674] dark:text-[#6874F5] text-2xl font-semibold font-poppin">
                            {inputValue}
                        </div>
                    </div>
                    <div className="w-14 h-14 left-4 top-6 absolute">
                        <div className="w-14 h-14 bg-violet-50 dark:bg-violet-200 rounded-full flex justify-center items-center ">
                            <div className="w-8 h-8 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                >
                                    <path
                                        d="M9.28398 13.3136H9.55766C10.6113 13.3136 11.4734 14.1757 11.4734 15.2293V24.8082C11.4734 25.8618 10.6113 26.7239 9.55766 26.7239H9.28398C8.23031 26.7239 7.36821 25.8618 7.36821 24.8082V15.2293C7.36821 14.1757 8.23031 13.3136 9.28398 13.3136ZM16.947 7.56628C18.0007 7.56628 18.8628 8.42838 18.8628 9.48205V24.8082C18.8628 25.8618 18.0007 26.7239 16.947 26.7239C15.8934 26.7239 15.0313 25.8618 15.0313 24.8082V9.48205C15.0313 8.42838 15.8934 7.56628 16.947 7.56628ZM24.6101 18.5135C25.6638 18.5135 26.5259 19.3756 26.5259 20.4293V24.8082C26.5259 25.8618 25.6638 26.7239 24.6101 26.7239C23.5564 26.7239 22.6943 25.8618 22.6943 24.8082V20.4293C22.6943 19.3756 23.5564 18.5135 24.6101 18.5135Z"
                                        fill="#5D64FB"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="left-64 top-16 absolute">
                        <div className=" text-green-500 text-sm font-medium font-poppins leading-tight">
                            {!statPourcentage && statPourcentage !== 0 ? '' : `${statPourcentage.toFixed(2)}  %`}
                        </div>
                    </div>
                    {infoMessage && (
                        <div className="absolute right-4 top-4">
                            <Tooltip
                                theme={{
                                    target: 'w-fit',
                                    animation: 'transition-opacity',
                                    arrow: {
                                        base: 'absolute z-10 h-2 w-2 rotate-45',
                                        style: {
                                            dark: 'bg-gray-900 dark:bg-gray-700',
                                            light: 'bg-white',
                                            auto: 'bg-white dark:bg-gray-700',
                                        },
                                        placement: '-4px',
                                    },
                                    base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-xs font-medium shadow-sm',
                                    hidden: 'invisible opacity-0',
                                    style: {
                                        dark: 'bg-gray-900 text-white dark:bg-gray-700',
                                        light: 'border border-gray-200 bg-green text-gray-900',
                                        auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
                                    },
                                    content: 'relative z-20',
                                }}
                                content={infoMessage || title}
                            >
                                <BsInfoCircleFill className="h-5 w-5 fill-zinc-400" role="button" />
                            </Tooltip>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default WidgetPercentIcon;
