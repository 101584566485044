/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { keyframes, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon } from '@mui/material';

const tiltSshaking = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(20deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-20deg); }
  100% { transform: rotate(0deg); }
 `;

const settingsAnim = keyframes`
  0% {
    transform: rotate(-80deg);
    opacity: 1;
  }
   60% {
    transform: rotate(80deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
 `;
const prepayedOrdersAnim = keyframes`
  0% {
    transform: rotate3d(1, 1, 0, -360deg);
    opacity: 1;
  }
  100% {
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
 `;

const codOrderAnim = keyframes`
 0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
 `;

const dashboarddAnim = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  33% {
    transform: translateY(30px);
    opacity: 0;
  }
  66% {
    transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
 `;

const matchingProdAnim = keyframes`
 0% {
    transform: scale(0);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: scale(1);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: scale(0.7);
    animation-timing-function: ease-in;
  }
  72% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  81% {
    transform: scale(0.84);
    animation-timing-function: ease-in;
  }
  89% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  95% {
    transform: scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
 `;

const matchedProdAnim = keyframes`
 0% {
    transform: scaleY(0);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
 `;

const prodListAnim = keyframes`
 0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    transform: translateY(8px);
  }
  80% {
    transform: translateY(6.4px);
  }
  90% {
    transform: translateY(-6.4px);
  }
 `;

const otherAnim = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
 `;

const sourceProdAnim = keyframes`
0% {
    transform: skew(0deg) ;
    transform-origin: 0% ;
  }
  50% {
    transform: skew(-20deg) ;
    transform-origin: 100% 70%;
  }
  0% {
    transform: skew(0deg) ;
    transform-origin: 0% ;
  }
 `;

export const StyledItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'collapsed' && prop !== 'sub',
})<{ selected: boolean | undefined; collapsed: boolean | undefined; sub: boolean | undefined }>(
    ({ theme, selected, collapsed, sub }) => ({
        // color: selected ? 'white' : 'theme.palette.textColor.primary',
        display: collapsed ? 'inline-block' : 'flex',

        margin: collapsed
            ? sub
                ? '0px 0px 2px 45px'
                : '5px  0px 0px 30px'
            : sub
              ? '5px  15px 0px 25px'
              : '5px  15px 0px 15px',
        paddingLeft: '4px',
        backgroundColor: selected ? (collapsed ? 'transparent' : theme.palette.secondaryBackground) : 'transparent',
        borderRadius: '35px ',
        fontWeight: selected ? '500' : '',

        '&:hover': {
            backgroundColor: collapsed ? 'transparent' : theme.palette.secondaryBackground,
        },
    }),
);

const iconNaming = (iconName: string | undefined) => {
    switch (iconName) {
        case 'Dashboard':
            return `${dashboarddAnim} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 normal forwards}`;
        case 'Settings':
            return `${settingsAnim} 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`;
        case 'Notifications':
            return `${tiltSshaking} 0.5s ease 1 alternate forwards`;
        case 'COD orders':
            return `${codOrderAnim} 0.5s ease-in-out 2 alternate forwards`;
        case 'Prepaid orders':
            return `${prepayedOrdersAnim} 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2 alternate forwards`;
        case 'Matching products':
            return `${matchingProdAnim} 1.1s alternate forwards`;
        case 'Matched products':
            return `${matchedProdAnim} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`;
        case 'Product List':
            return `${prodListAnim} 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`;
        case 'Product sourcing':
            return `${sourceProdAnim} 2s alternate forwards`;
        default:
            return `${otherAnim} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`;
    }
};

export const ThisStyle = styled(ListItemIcon, {
    shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'iconName' && prop !== 'hovered',
})<{ selected: boolean | undefined; hovered: boolean | undefined; iconName: string | undefined }>(
    ({ selected, hovered, iconName }) => ({
        color: hovered || selected ? '#fff' : '',

        animation: hovered ? iconNaming(iconName) : ``,

        '&.MuiListItemIcon-root': {
            minWidth: '5px',
        },
    }),
);

export const roundedIcon = (selected: boolean, hovered: boolean) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: hovered || selected ? 'primary.main' : '',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        overflow: 'hidden',
    };
};

export const LinkStyle = { textDecoration: 'none', minWidth: '30px' };

export const ListItemStyle = (collapsed: boolean) => {
    return {
        marginTop: '-1px',
        marginBottom: '-1px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: collapsed ? 'center' : 'flex-end',
    };
};

export const notifStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#578aff',
    color: 'white',
    padding: '2px',
    fontSize: '12px',
    width: '20px',
    height: '20px',
};

// export const labelStyle = { marginLeft: '10px', fontSize: '14px' };
export const labelStyle = () => {
    return {
        marginLeft: '10px',
        fontSize: '14px',
        color: 'textColor.secondary',
    };
};

export const testboxItem = (selected: boolean) => {
    return {
        display: 'flex',
        backgroundColor: selected ? 'yellow' : '',
    };
};
