import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import { ModalProps } from './Modal.types';
import { bodyStyle } from './Modal.styles';

const Modal: React.FC<ModalProps> = ({ body, setOpen, open, disableEscapeKeyDown }) => {
    const closeModal = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                // fullWidth
                // sx={{ maxWidth: '100vw' }}
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableEscapeKeyDown={disableEscapeKeyDown}
            >
                <Box sx={bodyStyle}>{body}</Box>
            </Dialog>
        </div>
    );
};

export default Modal;
