/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@mui/material/Button';
import { ButtonStyle } from './CustomButton.styles';
import { ButtonProps } from './CustomButton.types';

const CustomButton: React.FC<ButtonProps> = ({ styleType, type, onClick, label, sizeType, icon, disabled, size }) => {
    return (
        <div>
            <Button
                sx={ButtonStyle(sizeType, styleType, disabled)}
                type={type}
                onClick={onClick}
                disabled={disabled}
                size={size}
                variant={
                    disabled
                        ? 'contained'
                        : styleType === 'secondary' || styleType === 'danger'
                          ? 'outlined'
                          : 'primary'
                }
            >
                {icon && <img src={icon} alt={label} />}

                {label}
            </Button>
        </div>
    );
};

export default CustomButton;
