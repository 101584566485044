import React from 'react';
import { Box, Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { CounterProps } from './Counter.types';
import { buttonStyle } from './Counter.styles';

const CustomCounter: React.FC<CounterProps> = ({ quantity, setQuantity, disabled }) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setQuantity((prev) => (Number.isNaN(Number(e.target.value)) ? prev : Number(e.target.value)));
    };

    return (
        <Box>
            <OutlinedInput
                id="outlined-adornment-amount"
                sx={{
                    maxWidth: '150px',
                    minWidth: '120px',
                    height: '53px',
                    padding: '10px !important',
                    '& .MuiInputBase-input': { textAlign: 'center' },
                }}
                value={quantity}
                onChange={(e) => {
                    handleChange(e);
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <Button
                            sx={buttonStyle}
                            onClick={() => setQuantity((prev) => (prev < 1 ? 0 : prev - 1))}
                            disabled={disabled || quantity === 0}
                        >
                            -
                        </Button>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <Button sx={buttonStyle} onClick={() => setQuantity((prev) => prev + 1)} disabled={disabled}>
                            +
                        </Button>
                    </InputAdornment>
                }
                placeholder="Quantity"
                disabled={disabled}
            />
        </Box>
    );
};

export default CustomCounter;
