/* eslint-disable no-unused-vars */
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import { Box, Button } from '@mui/material';
import { ReactComponent as Lgst } from '../../assets/LogistioLogo.svg';
import { ReactComponent as LgstSmall } from '../../assets/LogistioLogocollapsed.svg';

export const drawerStyle = {
    '& .MuiDrawer-paper': { borderWidth: 0 },
};

export const StyledList = styled(List, {
    shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean | undefined }>(({ isCollapsed }) => ({
    width: isCollapsed ? '110px' : '220px',
    maxWidth: 360,
    minWidth: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'width 0.3s ease',
}));

export const ArrowButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean | undefined }>(({ isCollapsed, theme }) => ({
    backgroundColor: theme.palette.secondaryBackground,
    borderRadius: '0px',
    borderTopLeftRadius: isCollapsed ? '0%' : '50%',
    borderBottomLeftRadius: isCollapsed ? '0%' : '50%',
    minWidth: '20px',
    width: isCollapsed ? '110px' : '27px',
    height: '25px',
    display: 'flex',
    alignSelf: 'flex-end',
    transition: 'width 0.3s ease',
}));

export const LogistioLogoFull = styled(Lgst)(({ theme }) => ({
    fill: theme.palette.logoColor,
    stroke: theme.palette.logoColor,
    width: '100%',
}));
export const LogistioLogoSmall = styled(LgstSmall)(({ theme }) => ({
    fill: theme.palette.logoColor,
    stroke: theme.palette.logoColor,
    height: 60,
}));

export const StyledAvatar = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean | undefined }>(({ isCollapsed }) => ({
    marginLeft: isCollapsed === true ? '31px' : '10px',
    transition: 'marginLeft 0.3s ease',
}));
export const DrawerTopArea = (isCollapsed: boolean) => {
    return {
        width: '100%',
        display: 'flex',
        flexDirection: isCollapsed ? 'column' : 'row',
        transition: 'flexDirection 0.3s ease',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        marginBottom: '20px',
    };
};

export const logistioLogoOpened = {
    display: 'flex',
    justifyContent: 'flex-start',
};

export const IconStyle = { width: '10px', fill: '#a7a4c0' };
export const styledLink = {
    textDecoration: 'none',
};

export const SidebarContainer = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '220px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    '&:hover': {
        overflowY: 'auto', // Show the scrollbar on hover
    },
};

export const AccountsContainer = {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '360px',
    width: '90%',
    margin: '0 auto',
};

export const AccountsWrapper = {
    border: '1px solid',
    borderColor: 'borderColor',
    borderRadius: '4px',
    // padding: '8px',
};

export const CurrentAccountContainer = (isCollapsed: boolean) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: isCollapsed ? 'center' : 'flex-start',
        width: isCollapsed ? '100%' : '180px',
        transition: 'width 0.3s ease',
    };
};

export const OtherAccountsContainer = {
    overflow: 'hidden',
    maxHeight: '200px',
    minHeight: '140px',
    '&:hover': {
        overflowY: 'scroll',
    },
};

export const SearchArea = {
    padding: '5px',
};
export const openCloseIcon = { cursor: 'pointer' };
