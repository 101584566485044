import { Box, Collapse, List, Tooltip } from '@mui/material';
import MenuItem from 'components/menuItem/MenuItem';
import React, { useEffect, useState } from 'react';
import { IStore } from 'utils/Interfaces/stores.interface';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { getCustomer } from 'services/customer';
import { getCurrentUser } from 'utils/helpers/auth';
import StyleList from './StyleList';

export default function ExpandingNavList({
    isCollapsed,
    prop,
    index,
    currentStore,
}: {
    isCollapsed: boolean;
    prop: any;
    index: number;
    currentStore: IStore;
}) {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const [hovered, setHovered] = useState(false);
    const [customer, setCustomer] = useState<ICustomer>();
    const usr = getCurrentUser();
    useEffect(() => {
        // eslint-disable-next-line no-underscore-dangle
        getCustomer(usr?._id).then((res: any) => {
            setCustomer(res.data);
        });
    }, []);
    const handleHover = () => {
        setHovered(true);
    };

    const handleLeave = () => {
        setHovered(false);
    };

    // const TipTool = ({ children }: { children: any }) => {
    //     if (isCollapsed) {
    //         return (
    //             <Tooltip title={prop.name as string} placement="right" key={index}>
    //                 {children}
    //             </Tooltip>
    //         );
    //     }
    //     return children;
    // };

    return (
        <Box key={index}>
            {isCollapsed ? (
                <Tooltip title={prop.name} placement="right" key={prop.name + index}>
                    <StyleList
                        prop={prop}
                        isCollapsed={isCollapsed}
                        open={open}
                        hovered={hovered}
                        handleClick={handleClick}
                        handleHover={handleHover}
                        handleLeave={handleLeave}
                    />
                </Tooltip>
            ) : (
                <StyleList
                    prop={prop}
                    isCollapsed={isCollapsed}
                    open={open}
                    hovered={hovered}
                    handleClick={handleClick}
                    handleHover={handleHover}
                    handleLeave={handleLeave}
                />
            )}

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {prop.children.map((child: any, indexed: number) => {
                        if (child.type === 'features') {
                            if (child.path === 'lightfunnels-matching') {
                                // check if user have any integration to Lf

                                if (
                                    currentStore?.storeType !== 'lightfunnels' ||
                                    !customer?.lightFunnelsId ||
                                    !customer.lightFunnelsToken
                                ) {
                                    return null;
                                }
                            }
                            if (child.path === 'prepaid-orders' && currentStore?.withCC !== false) {
                                // Hide menu item if store have disabled call center
                                return null;
                            }
                            if (child.path === 'prepaid-invoice' && currentStore?.withCC !== false) {
                                // Hide menu item if store have disabled call center
                                return null;
                            }
                            // console.log('No NO NO this is ther return', child.path);

                            return (
                                <MenuItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={indexed}
                                    collapsed={isCollapsed}
                                    selected={false}
                                    icon={child.icon && <child.icon />}
                                    label={child.name}
                                    path={child.path}
                                    sub
                                />
                            );
                        }
                        return null;
                    })}
                </List>
            </Collapse>
        </Box>
    );
}
