// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';

// export const Container = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     minWidth: '800px',
//     // backgroundColor: 'red',
// }));
export const widthStyling = { width: 400 };
export const LinkStyle = {
    textDecoration: 'none',
    minWidth: '30px',
};
export const RememberMeStyle = { display: 'flex', marginLeft: '-10px' };
export const registerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
};
