import React, { useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { CustomRadioProps } from './CustomRadio.types';
import { formWrapper, RadioGroupeStyle, RadioWrapper } from './CustomRadio.styles';

const CustomRadio: React.FC<CustomRadioProps> = ({ label, values, configValue, setConfigValue }) => {
    const [value, setValue] = React.useState('');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setConfigValue((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        setValue(configValue);
    }, []);

    return (
        <FormControl sx={formWrapper}>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={RadioGroupeStyle}
            >
                {values.map((val) => (
                    <FormControlLabel value={val.value} control={<Radio />} label={val.label} sx={RadioWrapper} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default CustomRadio;
