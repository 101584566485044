import * as Yup from 'yup';

export const payWithBank = Yup.object().shape({
    amount: Yup.number().required(),
    verificationImage: Yup.string().required(),
    accountName: Yup.string().required(),
    status: Yup.number().required(),
});

export const payment = (state: { payWithBank: boolean; payWithWalletBox: boolean }) => {
    if (state.payWithBank && !state.payWithWalletBox)
        return Yup.object().shape({
            bankAmount: Yup.number().required('Bank amout is required'),
            verificationImage: Yup.string().required('Verification image is required'),
            accountName: Yup.string().required('Transaction name is required'),
            status: Yup.number().required(),
            operationCurrency: Yup.string().required('Currency is required'),
        });
    if (state.payWithBank && state.payWithWalletBox)
        return Yup.object().shape({
            walletAmount: Yup.number().required('Wallet amount is required'),
            bankAmount: Yup.number().required('Bank amout is required'),
            verificationImage: Yup.string().required('Verification image is required'),
            accountName: Yup.string().required('Transaction name is required'),
            status: Yup.number().required(),
            operationCurrency: Yup.string().required('Currency is required'),
            totalPrice: Yup.number()
                .required()
                // eslint-disable-next-line func-names
                .test('sum-valid', 'The entered Amount is not equal to the product total Price', function (value) {
                    const { walletAmount, bankAmount } = this.parent;
                    return Number(walletAmount) + Number(bankAmount) === value;
                }),
        });

    return Yup.object().shape({});
};
