import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: theme.palette.primaryBackground,
}));
export const widthStyling = (isSubmitted: boolean) => {
    return {
        marginTop: isSubmitted ? '0px' : '-40px',
        width: '400px',
        transition: 'all 0.5s ease-in-out',
        transform: isSubmitted ? 'translatey(30%)' : 'none',
    };
};

export const acceptTermsStyle = { display: 'flex' };

export const goBack = { display: 'flex', justifyContent: 'flex-start', textDecoration: 'none', marginTop: '5px' };
