import axios from 'utils/axios/axios';
import { IPaginationData } from 'utils/Interfaces/types';
import { getBearerToken } from 'utils/helpers/auth';
import { IOrder } from './order';

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}

export interface Ifilter {
    status?: number;
    createdAt?: object;
    type?: string;
    dateRange?: IdateRange;
    source?: string;
    customer?: string;
}
export const paymetSources = [
    { value: 'wallet', label: 'Wallet Operation' },
    { value: 'bank', label: 'Bank Transaction' },
    { value: 'walletBank', label: 'Wallet Operation and Bank Transaction' },
];
export const paymentTypes = [
    { value: 'product', label: 'Product Sourcing' },
    { value: 'invoice', label: 'Invoice Payment' },
    { value: 'order', label: 'Order Fees' },
];
interface IOptions {
    status?: number;
    createdAt?: object;
    type?: string;
    dateRange?: IdateRange;
    source?: string;
    customer?: string;
    page?: number;
    limit?: number;
}

export interface IOrderDetails {
    orederRef: string;
    orderId: string | IOrder;
    Orderfees?: number;
    orderFees: number;
    handleFees: number;
    shippingFees?: number;
}
export interface IOrderDetailsAgregation extends IOrderDetails {
    orderData?: IOrder;
}

export type PaymentType = 'invoice' | 'product' | 'order';
export type PaymentSource = 'wallet' | 'bank' | 'walletBank' | 'other';
export interface IPayments {
    // payment: {
    _id: string;
    type: PaymentType;
    amount: number;
    source: PaymentSource;
    accountName: string;
    status: number;
    verificationImage?: string;
    product: any;
    walletOperation: any;
    walletAmount?: number;
    bankAmount?: number;
    ordersDetails: IOrderDetails[];

    //  };
}

export interface IPayment {
    payment: IPayments;
}

export interface IPaginatedPayment extends IPaginationData<IPayments> {}

export const getpayments = async (options: IOptions) => {
    const config = await getBearerToken();
    const response = await axios.post<{ response: IPaginatedPayment }>(`/payment/filter`, options, config);
    return response;
};

export const getpayment = async (id: string) => {
    const config = await getBearerToken();
    const response = await axios.get<IPayment>(`/payment/${id}`, config);
    return response;
};
