/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ShippingToCountryFlag from 'components/flags/shippingToCountriesFlags';
import React, { useEffect, useState } from 'react';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { Link } from 'react-router-dom';
import { getResellerStock } from 'services/reseller/resellerStocks';
import toast from 'react-hot-toast';
import { ResellerProduct, ResellerStock } from 'pages/sellers/reseller.types';
import moment from 'moment';
import { MdDeleteForever } from 'react-icons/md';
import { Modal } from 'flowbite-react';
import GeneralButton from 'components/customButton/generalButton';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import {
    Ipagination,
    getResellerProductByCustomer,
    removeResellerProductFromCustomerList,
} from 'services/reseller/resellerProducts';
import { ISharedProductCardProps } from './sharedProductCard.types';

const SharedProoductCard: React.FC<ISharedProductCardProps> = ({
    product,
    setMySharedProducts,
    filter,
    page,
    limit,
}) => {
    const formattedProductName = product.name.split(' ').join('-').toLocaleLowerCase();
    const [loading, setLoading] = useState(false);
    const [resellerStocks, setResellerStocks] = useState<Array<ResellerStock>>([]);
    const [openModal, setOpenModal] = useState(false);
    const [pagination] = useState<Ipagination>({
        page,
        limit,
    });

    useEffect(() => {
        setLoading(true);
        getResellerStock(product._id)
            .then((result) => {
                setResellerStocks(result?.data);
                setLoading(false);
            })
            .catch(() => {
                toast.error('Something went wrong');
                setLoading(false);
            });
    }, []);

    const quotationData: Record<string, ResellerProduct['quotation'][number]> = {};
    product.quotation.forEach((quotation) => {
        quotationData[quotation.country] = quotation;
    });

    const customerResellerProductRemoval = (resellerProductId: string) => {
        setOpenModal(false);
        const deleteToast = toast.loading(`Removing ${product.name} from your shared list`);
        removeResellerProductFromCustomerList(resellerProductId)
            .then(() => {
                toast.success(`${product.name} was removed from your shared list`, { id: deleteToast });
                getResellerProductByCustomer(pagination, filter)
                    .then((result) => setMySharedProducts(result?.data?.docs))
                    .catch((err) => toast.error(err?.response?.data?.errors?.message));
            })
            .catch((err) => toast.error(err?.response?.data?.errors?.message, { id: deleteToast }));
    };

    const titleUpperCase = (phrase: string): string => {
        const words = phrase.split(' ');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return capitalizedWords.join(' ');
    };

    const availableQuantityExist = (availableStockQuantity: number, stockStatus: number): number | string => {
        let availableQuantityResponse: string | number = availableStockQuantity || '--';
        if (stockStatus && [1, 2, 3].includes(stockStatus)) {
            availableQuantityResponse = 'Coming soon';
        } else if (stockStatus && [4, 5].includes(stockStatus) && availableStockQuantity === 0) {
            availableQuantityResponse = 'Not available';
        }
        return availableQuantityResponse;
    };

    return (
        <>
            <div className="">
                <div className="flex items-end justify-between ">
                    <div className="flex gap-2 max-w-[50%]">
                        <p className="mb-3  truncate font-popin font-light text-3xl">
                            {titleUpperCase(`${product?.name}`)}
                        </p>
                        <button type="button" onClick={() => setOpenModal(true)}>
                            <MdDeleteForever className="w-4 h-4 text-red-500 hover:text-red-700" />
                        </button>
                    </div>

                    <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to remove this product from your shared list products?
                                </h3>
                                <div className="flex justify-center gap-4">
                                    <GeneralButton
                                        color="failure"
                                        onClick={() => customerResellerProductRemoval(product._id)}
                                    >
                                        Yes, I am sure
                                    </GeneralButton>
                                    <GeneralButton color="gray" onClick={() => setOpenModal(false)}>
                                        No, cancel
                                    </GeneralButton>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <div>
                        <Link
                            to={`/logistio-shop/${formattedProductName}/${product._id}`}
                            state={{ resellerProduct: product }}
                        >
                            <p className="font-medium text-blue-600 dark:text-blue-500 hover:underline">View product</p>
                        </Link>
                    </div>
                </div>
                <div className="relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                        <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3 sr-only ">
                                    SKU
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Warehouse
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Unit Price
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Available quantity
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                            </tr>
                        </thead>

                        {loading ? (
                            <tbody>
                                <tr className=" border-b dark:bg-gray-800 dark:border-gray-700 animate-pulse">
                                    <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                    </th>
                                    <td className="px-6 py-4 flex gap-2">
                                        {' '}
                                        <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                    </td>
                                    <td className="px-6 py-4">
                                        {' '}
                                        <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                    </td>
                                    <td className="px-6 py-4">
                                        {' '}
                                        <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                    </td>
                                    <td className="px-6 py-4">
                                        {' '}
                                        <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {resellerStocks.map((stock) => {
                                    return (
                                        <tr className=" border-b dark:bg-gray-800 dark:border-gray-700" key={stock._id}>
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {product?.sku}
                                            </th>
                                            <td className="px-6 py-4 flex gap-2">
                                                <ShippingToCountryFlag flag={stock.country} />
                                            </td>
                                            <td className="px-6 py-4">
                                                {currencyConverter(quotationData[stock.country]?.sellingPrice, '')}
                                            </td>
                                            <td className="px-6 py-4">
                                                {availableQuantityExist(stock?.availableQuantity, stock?.status)}
                                            </td>
                                            <td className="px-6 py-4">
                                                {moment(stock?.updatedAt).format('MMMM Do YYYY, HH:mm')}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

export default SharedProoductCard;
