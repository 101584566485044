import React from 'react';
import { FaRegShareFromSquare } from 'react-icons/fa6';
import { IoStorefrontOutline } from 'react-icons/io5';
import { MdOutlineIntegrationInstructions, MdOutlineSupportAgent } from 'react-icons/md';

export const MdOutlineIntegration = () => {
    return (
        <>
            <MdOutlineIntegrationInstructions size={22} />
        </>
    );
};

export const MdOutlineSupport = () => {
    return (
        <>
            <MdOutlineSupportAgent size={22} />
        </>
    );
};

export const IoStorefrontShop = () => {
    return (
        <>
            <IoStorefrontOutline size={22} />
        </>
    );
};

export const FaRegSharedProduct = () => {
    return (
        <>
            <FaRegShareFromSquare size={22} />
        </>
    );
};
