/* eslint-disable no-underscore-dangle */
import GeneralButton from 'components/customButton/generalButton';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { currencies } from 'utils/countryList';
import { getCurrentUser } from 'utils/helpers/auth';
import CustomerBankInformation from './customerBankInformation';
import CustomerBankInformationUpdate from './customerBankInformationupdate';

const CustomerBankInformationDisplay = () => {
    const [customer, setCustomer] = useState<ICustomer>();
    const customerId = getCurrentUser()._id;
    const [customerLoader, setCustomerLoader] = useState(false);
    const [activeEdit, setActiveEdit] = useState(false);

    useEffect(() => {
        setCustomerLoader(true);
        getCustomer(customerId)
            .then((res) => {
                setCustomer(res.data);
                setCustomerLoader(false);
            })
            .catch(() => {
                toast.error('Something went wrong, please try again !');
                setCustomerLoader(false);
            });
    }, []);

    const {
        bankingType,
        transferMethod,
        receiverType,
        accountHolder,
        accountNumber,
        achRoutingNumber,
        accountType,
        country,
        city,
        state,
        postCode,
        recepientAdress,
        swiftBicCode,
        paymentService,
        email,
        ibanRoutingNumber,
        targetCurrency,
    } = customer?.bankAccountInformation || {};

    const bankingTypedisplay = (type: string) => {
        switch (type) {
            case 'FinancialService':
                return 'Financial Service';

            case 'BankAccount':
                return 'Bank Account';

            default:
                return '--';
        }
    };

    const fullTargetCurrency = currencies.filter((curr) => curr.code === targetCurrency);

    return (
        <>
            {customerLoader ? (
                <div className="animate-pulse mt-5">
                    <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                    <div className="flex justify-end mb-1">
                        <div
                            role="status"
                            className="w-full max-w-28 p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700"
                        >
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full " />
                        </div>
                    </div>
                    <div
                        role="status"
                        className="flex flex-col gap-2 w-full p-4  border border-gray-200  divide-gray-200 rounded  dark:divide-gray-700 md:p-6 dark:border-gray-700"
                    >
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                        <div className="flex flex-col gap-2 w-full p-4  border border-gray-200 divide-gray-200 rounded  dark:divide-gray-700 md:p-6 dark:border-gray-700">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-44 " />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 " />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                        </div>
                        <div className="flex flex-col gap-2 w-full p-4 border border-gray-200  divide-gray-200 rounded   dark:divide-gray-700 md:p-6 dark:border-gray-700">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  w-36 " />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 " />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-44 " />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <p className="text-lg font-normal">Your Bank information</p>

                    {customer?.bankAccountInformation && Object.keys(customer?.bankAccountInformation).length > 0 ? (
                        <>
                            {!activeEdit && (
                                <div className="flex justify-end ">
                                    <GeneralButton
                                        color={activeEdit ? 'gray' : 'blue'}
                                        className="w-full max-w-28"
                                        onClick={() => setActiveEdit(!activeEdit)}
                                    >
                                        {activeEdit ? 'Cancel' : 'Edit'}
                                    </GeneralButton>
                                </div>
                            )}
                            {activeEdit ? (
                                <CustomerBankInformationUpdate
                                    customerId={customerId}
                                    bankInformationData={customer.bankAccountInformation}
                                    setCustomer={setCustomer}
                                    setCustomerLoader={setCustomerLoader}
                                    setActiveEdit={setActiveEdit}
                                />
                            ) : (
                                <div>
                                    {customer && customer.bankAccountInformation.bankingType === 'FinancialService' && (
                                        <div className="flex flex-col gap-5 mt-1">
                                            <div className="flex flex-col gap-1">
                                                <div className="flex flex-col gap-2 p-6  border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                    {/* <p className=" text-md font-popin">
                                                        Selected method :{' '}
                                                        <span className="text-black dark:text-white">
                                                            {bankingTypedisplay(bankingType as string)}
                                                        </span>
                                                    </p> */}
                                                    <p className="text-md font-popin">
                                                        Selected service :{' '}
                                                        <span className="text-black dark:text-white">
                                                            {paymentService || '--'}
                                                        </span>
                                                    </p>
                                                    <p className=" text-md font-popin">
                                                        Email :{' '}
                                                        <span className="text-black dark:text-white">
                                                            {email || '--'}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {customer &&
                                        customer.bankAccountInformation.bankingType === 'BankAccount' &&
                                        customer.bankAccountInformation.transferMethod === 'ACH' && (
                                            <div className="flex flex-col gap-5 mt-1">
                                                <div className="flex flex-col gap-1">
                                                    <div className="flex flex-col gap-2 p-6  border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                        <p className=" text-md font-popin">
                                                            Selected method :{' '}
                                                            <span className="text-black dark:text-white">
                                                                {bankingTypedisplay(bankingType as string)}
                                                            </span>
                                                        </p>
                                                        <p className="text-md font-popin">
                                                            Transfer type :{' '}
                                                            <span className="text-black dark:text-white">
                                                                {transferMethod || '--'}
                                                            </span>
                                                        </p>
                                                        <div className="flex flex-col gap-2 p-2  border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                            <p className="text-base font-normal underline">
                                                                Account Information :
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Receiver type :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {receiverType || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Account Holder :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {accountHolder || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Account Number :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {accountNumber || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                ACH routing number :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {achRoutingNumber || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Account Type :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {accountType || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Target Currency :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {`${fullTargetCurrency[0].name} (${fullTargetCurrency[0].code})` ||
                                                                        '--'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-col gap-2 p-2  border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                            <p className="text-base font-normal underline">
                                                                Personnel information :
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Country :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {country || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                City :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {city || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                State :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {state || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Recepient adress :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {recepientAdress || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Post code :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {postCode || '--'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {customer &&
                                        customer.bankAccountInformation.bankingType === 'BankAccount' &&
                                        customer.bankAccountInformation.transferMethod === 'SWIFT' && (
                                            <div className="flex flex-col gap-5 mt-1">
                                                <div className="flex flex-col gap-1">
                                                    <div className="flex flex-col gap-2 p-6 border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                        <p className=" text-md font-popin">
                                                            Selected method :{' '}
                                                            <span className="text-black dark:text-white">
                                                                {bankingTypedisplay(bankingType as string)}
                                                            </span>
                                                        </p>
                                                        <p className="text-md font-popin">
                                                            Transfer type :{' '}
                                                            <span className="text-black dark:text-white">
                                                                {transferMethod || '--'}
                                                            </span>
                                                        </p>
                                                        <div className="flex flex-col gap-2 p-2  border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                            <p className="text-base font-normal underline">
                                                                Account Information :
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Receiver type :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {receiverType || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Account Holder :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {accountHolder || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                SWIFT/ BIC CODE :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {swiftBicCode || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                IBAN / Account number :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {ibanRoutingNumber || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Target Currency :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {`${fullTargetCurrency[0].name} (${fullTargetCurrency[0].code})` ||
                                                                        '--'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-col gap-2 p-2 border-2 border-gray-200 rounded-sm   dark:border-gray-700 ">
                                                            <p className="text-base font-normal underline">
                                                                Personnel information :
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Country :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {country || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                City :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {city || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                State :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {state || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Recepient adress :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {recepientAdress || '--'}
                                                                </span>
                                                            </p>
                                                            <p className=" text-md font-popin">
                                                                Post code :{' '}
                                                                <span className="text-black dark:text-white">
                                                                    {postCode || '--'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}
                        </>
                    ) : (
                        <CustomerBankInformation
                            customerId={customerId}
                            setCustomer={setCustomer}
                            setCustomerLoader={setCustomerLoader}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default CustomerBankInformationDisplay;
