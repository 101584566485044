import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/system';

export const wrapper = {};

export const pageWraper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100wh',
};
export const stepperWrapper = {
    // height: '9vh',
    // pt: 1,
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'orange',
};

export const contentWrapper = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'orange',
};

// export const stepper = { width: '400px', marginTop: '30px', backgroundColor: 'red' };

export const StepperStyle = styled(Stepper)<any>(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginTop: '30px',
        width: '370px',
    },
    [theme.breakpoints.up('md')]: {
        marginTop: '30px',
        width: '800px',
    },
}));

export const typographyStyle = { fontSize: '20px', textAlign: 'left' };

export const confirmationWrapper = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    textAlign: 'center',
};

export const confirmationMessage = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
};

export const butttonsWrapper = {
    display: 'flex',
    gap: '50px',
    justifyContent: 'space-between',
    margin: '50px 0px',
};
