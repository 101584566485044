export const walletStyle = {
    display: 'flex',
    alignItems: 'center',
    minWidth: '80px',
    justifyContent: 'center',
    borderRadius: '30px',
    padding: '5px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'rippleColor',
    },
};

export const amountStyle = {
    ml: 0.5,
};
