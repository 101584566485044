import { Grid, Typography } from '@mui/material';
import { Badge, Card, Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { HiOutlineArchiveBox } from 'react-icons/hi2';
import { IOrder } from 'services/order';
import { IProduct } from 'utils/Interfaces/products.interface';
import { imageDisplay } from 'utils/helpers/imageFinder';
import { v4 as uuidv4 } from 'uuid';

const ProductDetailst: React.FC<{ data: IOrder }> = ({ data }) => {
    const [openModal, setOpenModal] = useState(false);
    const sx = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return (
        <>
            <Modal
                dismissible
                show={openModal}
                popup
                size="5xl"
                onClose={() => setOpenModal(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Product Details</Modal.Header>
                <Modal.Body className="!p-1 overflow-y-auto ">
                    <div className="  p-2 overflow-x-auto min-w-[800px]" key="productDetailsHolder">
                        <Card
                            theme={{
                                root: {
                                    children: 'p-2',
                                },
                            }}
                            className="mb-1"
                        >
                            <Grid container spacing={0}>
                                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="sr-only">Image</div>
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Product Name</Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Product Spec</Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Sku</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Quantity</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                        {data.items &&
                            data.items.length > 0 &&
                            data.items.map((item) => {
                                const rows: (React.JSX.Element | null)[] = [];
                                const product = item?.product as IProduct;
                                let productName = '';
                                let variant = '';
                                let productDesign = [];
                                if (product.itemKind === 'variant') {
                                    productName = product?.parentProduct?.name;
                                    variant = product?.name;
                                    productDesign = product?.parentProduct?.design;
                                } else {
                                    productName = product?.name;
                                    variant = '-';
                                    productDesign = product?.design;
                                }
                                if (item.quantity) {
                                    rows.push(
                                        <Card
                                            key={`${uuidv4()}-productInfo`}
                                            theme={{
                                                root: {
                                                    children: 'p-2',
                                                },
                                            }}
                                            className="mb-1 relative"
                                        >
                                            {product?.isReseller && (
                                                <Badge
                                                    color="secondary"
                                                    className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                    style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                >
                                                    RESELLER
                                                </Badge>
                                            )}
                                            <Grid container spacing={0}>
                                                <Grid item xs={1} sx={sx}>
                                                    {imageDisplay(productDesign, true, 50, 50, undefined)}
                                                </Grid>
                                                <Grid item xs={4} sx={sx}>
                                                    <Typography>{productName}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{variant}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{product?.globalSKU}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sx={sx}>
                                                    <Typography>{item?.quantity}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>,
                                    );
                                }
                                if (item.upsellQuantity) {
                                    rows.push(
                                        <Card
                                            key={`${uuidv4()}-productInfo`}
                                            theme={{
                                                root: {
                                                    children: 'p-2',
                                                },
                                            }}
                                            className="mb-1 relative"
                                        >
                                            {product?.isReseller && (
                                                <Badge
                                                    color="secondary"
                                                    className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                    style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                >
                                                    RESELLER
                                                </Badge>
                                            )}
                                            <Badge
                                                color="success"
                                                className="inline-block absolute top-1 right-1 transform rotate-12 z-10"
                                            >
                                                UPSELL
                                            </Badge>
                                            <Grid container spacing={0}>
                                                <Grid item xs={1} sx={sx}>
                                                    {imageDisplay(productDesign, true, 50, 50, undefined)}
                                                </Grid>
                                                <Grid item xs={4} sx={sx}>
                                                    <Typography>{productName}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{variant}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{product?.globalSKU}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sx={sx}>
                                                    <Typography>{item?.upsellQuantity}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>,
                                    );
                                }
                                return rows;
                            })}
                    </div>
                </Modal.Body>
            </Modal>
            <HiOutlineArchiveBox className="w-5 h-5 cursor-pointer" onClick={() => setOpenModal(true)} />
        </>
    );
};

export default ProductDetailst;
