import React, { useState, useEffect } from 'react';
import { Chip, Box, Typography } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import CustomButton from 'components/customButton/CustomButton';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InputField from 'components/inputField/InputField';
import CustomCounter from 'components/counter/Counter';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Layers from '../../../assets/layers.svg';

import {
    addNewSelectOption,
    addVariantQuantityDescription,
    addVariantQuantityError,
    addVariantQuantityWrapper,
    addVariantWrapper,
    deleteSelectOption,
    newOptionSelectContainer,
    newOptionSelectWrapper,
    optionFieldError,
    optionFieldsContainer,
    optionFieldsWrapper,
    optionInputField,
    optionSelect,
    optionStyle,
    selectOptionFieldMode,
    sourcingProcessSubtitles,
} from '../Sourcing.styles';
import 'react-medium-image-zoom/dist/styles.css';
import NewOptionField from './NewOptionField';
import VariantsQuantitiesTable from './VariantsQuantitiesTable';

type OptionType = 'Size' | 'Color' | 'Material' | 'Style';

const StepOne = (props: any) => {
    const {
        formik,
        checkVariants,
        productOrVariantsCheckBoxHandleChange,
        options,
        usedOptions,
        setOptions,
        setUsedOptions,
        selectedOptions,
        errorMessage,
        setErrorMessage,
        quantity,
        setQuantity,
        newVariants,
        setNewVariants,
        displayError,
        setDisplayError,
        variantErrors,
        setVariantErrors,
        isRestock,
        initialOptions,
    } = props;
    const [editMode, setEditMode] = useState<boolean[]>([]);
    const [showNewOption, setShowNewOption] = useState(false);
    // show error if no quantity for product and no quantity for variants too
    useEffect(() => {
        if (quantity > 0 || Object.keys(newVariants)?.length > 0) {
            setDisplayError(false);
        } else {
            setDisplayError(true);
        }
        if (checkVariants && quantity !== 0) {
            setQuantity(0);
        }
    }, [quantity, newVariants]);

    const selectOption = (e: SelectChangeEvent<String>) => {
        const optionIndex = usedOptions.findIndex((el: string) => el === e.target.value);
        if (optionIndex < 0) {
            const newOptions = options;
            newOptions[e.target.value as OptionType].push('');
            setOptions({
                ...newOptions,
            });
            formik.setFieldValue('options', newOptions);
            setUsedOptions([...usedOptions, e.target.value]);
        }
    };
    const updateOption = (e: SelectChangeEvent<String>, option: OptionType) => {
        setErrorMessage('');
        const i = usedOptions.findIndex((el: OptionType) => el === e.target.value);
        if (i < 0) {
            const newOptions = options;
            newOptions[e.target.value as OptionType] = newOptions[option as OptionType];
            newOptions[option as OptionType] = [];
            setOptions({
                ...newOptions,
            });
            const k = usedOptions;
            const iOption = usedOptions.findIndex((el: OptionType) => el === option);
            if (iOption > -1) {
                k[iOption] = e.target.value;
            }
            setUsedOptions([...usedOptions]);
        } else {
            setErrorMessage(`${e.target.value} already exists`);
        }
    };

    const deleteOption = (option: string, index: number) => {
        const o = options;
        o[option as OptionType] = [];
        setOptions({
            ...o,
        });
        const k = usedOptions;
        k.splice(index, 1);
        setUsedOptions([...k]);
    };
    const updateVariantName = (e: React.ChangeEvent<HTMLInputElement>, option: string, ind: number) => {
        const newOpt = options;
        newOpt[option][ind] = e.target.value;
        setOptions({
            ...newOpt,
        });
        formik.setFieldValue('options', newOpt);
        if (options[option][options[option].length - 1] && options[option][options[option].length - 1].length) {
            const newOptions = options;
            newOptions[option].push('');
            setOptions({
                ...newOptions,
            });
            formik.setFieldValue('options', newOptions);
        }
    };
    const deleteVariant = (option: string, ind: number) => {
        const o = options;
        o[option].splice(ind, 1);
        setOptions({
            ...o,
        });
    };

    const switchEditMode = (index: number) => {
        const x = editMode;
        if (editMode[index] && editMode[index] === true) {
            x[index] = false;
        } else {
            x[index] = true;
        }
        setEditMode([...x]);
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Checkbox
                            disabled={isRestock}
                            checked={checkVariants}
                            onChange={productOrVariantsCheckBoxHandleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography variant="body1">
                            This product has options, such as size or color or material ...
                        </Typography>
                    </Box>

                    {checkVariants && (
                        <CustomCard
                            body={
                                <Box sx={addVariantWrapper}>
                                    <Box sx={sourcingProcessSubtitles}>Add Variants</Box>

                                    <Box>
                                        <Box>
                                            {/* Case no options selected yet */}
                                            {!options.Size.length &&
                                            !options.Color.length &&
                                            !options.Material.length &&
                                            !options.Style.length ? (
                                                <Box sx={optionSelect}>
                                                    <ListIcon
                                                        sx={{
                                                            color: 'textColor.variantColor',
                                                        }}
                                                    />
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Options</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="options"
                                                            placeholder="options"
                                                            onChange={(e: SelectChangeEvent<String>) => selectOption(e)}
                                                            name="here"
                                                        >
                                                            {selectedOptions.map((option: OptionType) => (
                                                                <MenuItem value={option} key={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            ) : (
                                                // case there is selected options already
                                                usedOptions.map((option: OptionType, index: number) => {
                                                    return options[option]?.length ? (
                                                        <Box sx={newOptionSelectWrapper} key={option}>
                                                            <Box sx={newOptionSelectContainer}>
                                                                <ListIcon
                                                                    sx={{
                                                                        color: 'textColor.variantColor',
                                                                    }}
                                                                />
                                                                {editMode[index] && editMode[index] === true ? (
                                                                    <Box sx={optionStyle}>{option}</Box>
                                                                ) : (
                                                                    <FormControl fullWidth>
                                                                        <InputLabel id="demo-simple-select-label">
                                                                            options
                                                                        </InputLabel>

                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            label="options"
                                                                            placeholder="options"
                                                                            {...formik.getFieldProps('options')}
                                                                            value={option}
                                                                            onChange={(e: SelectChangeEvent<String>) =>
                                                                                updateOption(e, option)
                                                                            }
                                                                            name="options"
                                                                        >
                                                                            {selectedOptions.map((opt: OptionType) => (
                                                                                <MenuItem value={opt} key={opt}>
                                                                                    {opt}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )}

                                                                {option && editMode[index] !== false && !isRestock && (
                                                                    <Box sx={deleteSelectOption}>
                                                                        <DeleteForeverIcon
                                                                            sx={{
                                                                                color: 'textColor.errorColor',
                                                                            }}
                                                                            onClick={() => deleteOption(option, index)}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <Box sx={optionFieldsWrapper(editMode, index)}>
                                                                {options[option as OptionType].map(
                                                                    (variant: string, ind: number) => {
                                                                        return (
                                                                            <Box>
                                                                                {/*  list variants under their options or show variants on inputs if editMode is false */}
                                                                                {editMode[index] &&
                                                                                editMode[index] === true ? (
                                                                                    variant !== '' && (
                                                                                        <Chip label={variant} />
                                                                                    )
                                                                                ) : (
                                                                                    <Box sx={optionFieldsContainer}>
                                                                                        <AppsIcon
                                                                                            sx={{
                                                                                                color: 'textColor.variantColor',
                                                                                            }}
                                                                                        />
                                                                                        <Box sx={optionInputField}>
                                                                                            <InputField
                                                                                                defaultValue={variant}
                                                                                                value={variant}
                                                                                                disabled={
                                                                                                    variant !== '' &&
                                                                                                    initialOptions?.includes(
                                                                                                        `${option}_${variant}`,
                                                                                                    )
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    updateVariantName(
                                                                                                        e,
                                                                                                        option,
                                                                                                        ind,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </Box>
                                                                                        {variant !== '' && (
                                                                                            <Box
                                                                                                sx={deleteSelectOption}
                                                                                            >
                                                                                                <DeleteForeverIcon
                                                                                                    sx={{
                                                                                                        color: 'textColor.errorColor',
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        deleteVariant(
                                                                                                            option,
                                                                                                            ind,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Box>
                                                                                        )}
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        );
                                                                    },
                                                                )}
                                                            </Box>

                                                            <Box sx={selectOptionFieldMode}>
                                                                <CustomButton
                                                                    sizeType="small"
                                                                    styleType="secondary"
                                                                    label={
                                                                        editMode[index] && editMode[index] === true
                                                                            ? 'Edit'
                                                                            : 'Done'
                                                                    }
                                                                    onClick={() => switchEditMode(index)}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    ) : null;
                                                })
                                            )}
                                        </Box>

                                        {showNewOption && (
                                            <NewOptionField
                                                formik={formik}
                                                setErrorMessage={setErrorMessage}
                                                options={options}
                                                setOptions={setOptions}
                                                usedOptions={usedOptions}
                                                setUsedOptions={setUsedOptions}
                                                selectedOptions={selectedOptions}
                                                setShowNewOption={setShowNewOption}
                                            />
                                        )}
                                        <Box sx={optionFieldError}>{errorMessage}</Box>
                                        {!isRestock && (
                                            <Box
                                                sx={addNewSelectOption}
                                                onClick={() => {
                                                    setShowNewOption(true);
                                                }}
                                            >
                                                <PostAddIcon /> <Box> Add another option </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            }
                        />
                    )}
                </Grid>
                <Grid item xs={12} lg={3}>
                    <CustomCard
                        // extraStyle={{ backgroundColor: 'red' }}
                        body={
                            <Box sx={addVariantQuantityWrapper}>
                                <Box>
                                    <img src={Layers} alt="" />
                                </Box>
                                <Box sx={addVariantQuantityDescription}>
                                    If you don’t have variants for this product, Please specify the total quantity.
                                </Box>
                                <CustomCounter quantity={quantity} setQuantity={setQuantity} disabled={checkVariants} />
                                {displayError && checkVariants === false && (
                                    <Box sx={addVariantQuantityError}>
                                        Please specify how many Items you want to source or add some variants.
                                    </Box>
                                )}
                            </Box>
                        }
                    />
                </Grid>
            </Grid>
            {/* heeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeere */}
            <VariantsQuantitiesTable
                newVariants={newVariants}
                setNewVariants={setNewVariants}
                variantErrors={variantErrors}
                setVariantErrors={setVariantErrors}
            />
        </Box>
    );
};

export default StepOne;
