import React from 'react';
import { Box } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputField from 'components/inputField/InputField';
import { variantsTableHeadWrapper, variantsTableRows, variantKeyMode, variantDeleteIcon } from '../Sourcing.styles';
import 'react-medium-image-zoom/dist/styles.css';

const VariantsQuantitiesTable = (props: any) => {
    const { newVariants, setNewVariants, variantErrors, setVariantErrors } = props;
    // const [newVariants, setVariantsWithKeys] = useState<
    //     Record<string, { quantity: string | number; disabled: boolean; key: string }>
    // >({});
    // const [variantsNames, setVariantsNames] = useState<string[]>([]);
    // useEffect(() => {
    //     const temp: typeof newVariants = { ...newVariants };
    //     Object.keys(newVariants).forEach((key) => {
    //         if (!temp[key]?.key) {
    //             temp[key] = { quantity: 0, disabled: false, key: '' };
    //             Object.assign(temp[key], { ...newVariants[key], key: uuidv4() });
    //         }
    //     });
    //     setVariantsNames([...Object.keys(temp)]);
    //     setVariantsWithKeys({ ...temp });
    // }, [newVariants]);

    const variantQuantityUpdate = (e: any, key: string, k: string, index: number) => {
        const x = newVariants;
        x[key as string][k as 'quantity'] = e.target.value;
        setNewVariants({
            ...x,
        });
        if (e.target.value.length === 0) {
            const err = [...variantErrors];
            err[index] = 'Required';
            setVariantErrors(err);
        } else if (
            (Number(e.target.value) === 0 || Number(e.target.value) < 0) &&
            !newVariants[key as string].disabled
        ) {
            const err = [...variantErrors];
            err[index] = '*Quantity must be > 0';
            setVariantErrors(err);
        } else if (Number.isNaN(Number(e.target.value)) && !newVariants[key as string].disabled) {
            const err = [...variantErrors];
            err[index] = '*Quantity must be a valid number';
            setVariantErrors(err);
        } else {
            const err = [...variantErrors];
            err[index] = '';
            setVariantErrors(err.filter((elt) => elt !== undefined));
        }
    };

    const untoggleDeleteVariant = (variantIndex: number, newerVariant: any) => {
        if (Number.isNaN(Number(newerVariant.quantity))) {
            const err = [...variantErrors];
            err[variantIndex] = 'Number field';
            setVariantErrors(err);
        } else if (newerVariant.quantity.length === 0) {
            const err = [...variantErrors];
            err[variantIndex] = 'Required';
            setVariantErrors(err);
        } else if (Number(newerVariant.quantity) === 0 && !newerVariant.disabled) {
            const err = [...variantErrors];
            err[variantIndex] = '*Quantity must be > 0';
            setVariantErrors(err);
        } else {
            const err = [...variantErrors];
            err[variantIndex] = '';
            setVariantErrors(err);
        }
    };
    const reactivateVariant = (key: string, index: number) => {
        const h = newVariants;
        h[key as string].disabled = false;
        untoggleDeleteVariant(index, h[key as string]);
        setNewVariants({ ...h });
    };
    return (
        <>
            {Object.keys(newVariants).length > 0 && (
                <CustomCard
                    body={
                        <Box>
                            <Box sx={variantsTableHeadWrapper}>
                                <Box>Index</Box>
                                <Box>Variants</Box>
                                <Box>Quantity</Box>
                                <Box>Action</Box>
                            </Box>
                            {Object.keys(newVariants).map((variantName, index) => {
                                return (
                                    <Box
                                        sx={variantsTableRows(newVariants, variantName)}
                                        key={newVariants[variantName].key}
                                    >
                                        <Box sx={variantKeyMode(newVariants, variantName)}>
                                            Variant&nbsp;{index + 1}
                                        </Box>
                                        <Box sx={variantKeyMode(newVariants, variantName)}>{variantName}</Box>
                                        <Box key={newVariants[variantName].key}>
                                            {!newVariants[variantName as string].disabled && (
                                                <InputField
                                                    value={newVariants[variantName as string].quantity}
                                                    type="number"
                                                    onChange={(e) =>
                                                        variantQuantityUpdate(e, variantName, 'quantity', index)
                                                    }
                                                    disabled={newVariants[variantName as string].disabled}
                                                />
                                            )}
                                            {/* Quantity errors here */}
                                            <Box
                                                sx={{
                                                    color: 'textColor.errorColor',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {' '}
                                                {variantErrors[index]}
                                            </Box>
                                        </Box>
                                        <Box sx={variantDeleteIcon}>
                                            {newVariants[variantName as string].disabled ? (
                                                <ReplayIcon onClick={() => reactivateVariant(variantName, index)} />
                                            ) : (
                                                <>
                                                    <DeleteForeverIcon
                                                        sx={{
                                                            color: 'textColor.errorColor',
                                                        }}
                                                        onClick={() => {
                                                            const h = newVariants;
                                                            h[variantName as string].disabled = true;
                                                            const err = [...variantErrors];
                                                            err[index] = '';
                                                            setVariantErrors(err);
                                                            setNewVariants({ ...h });
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    }
                    extraStyle={{ marginTop: '20px' }}
                />
            )}
        </>
    );
};

export default VariantsQuantitiesTable;
