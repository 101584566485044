export const imagesWrapper = {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
};
export const avatarStyle = {
    backgoundColor: 'secondaryBackground',
    width: 70,
    height: 70,
    border: '1px solid',
    borderColor: 'borderColor',
};
export const imageInfo = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    // alignItems: 'center',
};
export const titleStyle = { fontSize: '14px' };
export const progressBarStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
export const uploadingStatusStyle = { fontSize: '10px', color: 'textColor.secondary' };
export const imageContainer = { cursor: 'pointer' };
export const imageStyle = { width: '20px', height: 20 };
