/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MdContentCopy, MdDone, MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import { Carousel, Spinner } from 'flowbite-react';
import FlagIcon from 'components/flags/flagIcon';
import { AccountHolderProps } from './wallet.types';

const CopyButton: React.FC<{ text: string }> = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const copyText = (copy: string) => {
        navigator.clipboard.writeText(copy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <button
            disabled={copied}
            type="button"
            onClick={() => copyText(text)}
            title={copied ? 'Copied' : 'Copy'}
            key={text}
        >
            {copied ? <MdDone /> : <MdContentCopy />}
        </button>
    );
};

const WalletCardHolder: React.FC<AccountHolderProps> = ({ bankInformations, configfurationLoader }) => {
    const displayGrouping = [...(bankInformations?.simpleBank || []), ...(bankInformations?.financeService || [])];

    return (
        <div className="h-56 flex items-center justify-center ">
            {configfurationLoader ? (
                <Spinner aria-label="Extra large spinner example" size="xl" />
            ) : (
                <Carousel
                    slide={false}
                    className="h-64 "
                    rightControl={
                        <div className="w-10 h-10 bg-gray-300 dark:bg-[#191C24] rounded-full flex justify-center items-center ">
                            <MdOutlineNavigateNext className="text-white dark:text-black w-6 h-6" />
                        </div>
                    }
                    leftControl={
                        <div className="w-10 h-10 bg-gray-300 dark:bg-[#191C24] rounded-full flex justify-center items-center">
                            <MdOutlineNavigateBefore className="text-white dark:text-black  w-6 h-6" />
                        </div>
                    }
                    theme={{
                        indicators: {
                            active: {
                                off: 'bg-black/30 hover:bg-black/40 dark:bg-gray-800/50 dark:hover:bg-gray-800',
                                on: ' bg-black/50 dark:bg-gray-800',
                            },
                            base: 'h-1 w-3 rounded',
                            wrapper: 'absolute bottom-2 left-1/2 flex -translate-x-1/2 space-x-2 ',
                        },
                    }}
                >
                    {bankInformations?.financeService?.length > 0 ||
                    bankInformations?.simpleBank?.length > 0 ||
                    (bankInformations?.financeService?.length > 0 && bankInformations?.simpleBank?.length > 0) ? (
                        displayGrouping.map((slide) =>
                            'serviceType' in slide ? (
                                <div key={slide?._id} className="flex h-full items-center justify-center ">
                                    <div key={slide?._id} className=" w-full">
                                        <Grid container spacing={1}>
                                            <Grid item xs={2} />
                                            <Grid item xs={10}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            key={slide?._id}
                                                            variant="subtitle2"
                                                            fontSize={14}
                                                            fontWeight={500}
                                                        >
                                                            Email <CopyButton text={slide?.email} />
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {slide?.email || '--'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            Service Type
                                                        </Typography>
                                                        <div className="flex gap-2">
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {slide?.serviceType || '--'}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            ) : (
                                <div key={slide?._id} className="flex h-full items-center justify-center ">
                                    <Box key={slide?._id}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={2} />
                                            <Grid item xs={10}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            key={slide?._id}
                                                            variant="subtitle2"
                                                            fontSize={14}
                                                            fontWeight={500}
                                                        >
                                                            Account Holder <CopyButton text={slide?.accountHolder} />
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {slide?.accountHolder || '--'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            Payment Type <CopyButton text={slide?.paymentType} />
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {slide?.paymentType || '--'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            Account Number <CopyButton text={slide?.accountNumber} />
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {slide?.accountNumber || 'xxxxx'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            {slide?.accountCurrency === 'USD' &&
                                                            slide?.paymentType === 'local'
                                                                ? 'Account Type'
                                                                : 'SWIFT / BIC'}
                                                            <CopyButton
                                                                text={
                                                                    slide?.accountCurrency === 'USD' &&
                                                                    slide?.paymentType === 'local'
                                                                        ? slide?.accountType
                                                                        : slide?.swiftBic
                                                                }
                                                            />
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {(slide?.accountCurrency === 'USD' &&
                                                            slide?.paymentType === 'local'
                                                                ? slide?.accountType
                                                                : slide?.swiftBic) || ''}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            {slide?.accountCurrency === 'USD' &&
                                                                slide?.paymentType === 'local' &&
                                                                'ACH routing number'}
                                                            {slide?.accountCurrency === 'USD' &&
                                                                slide?.paymentType === 'international' &&
                                                                'Routing number'}
                                                            {slide?.accountCurrency === 'EUR' && 'IBAN'}{' '}
                                                            {slide?.accountCurrency === 'USD' && (
                                                                <CopyButton text={slide?.routingNumber} />
                                                            )}
                                                            {slide?.accountCurrency === 'EUR' && (
                                                                <CopyButton text={slide?.iban} />
                                                            )}
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {slide?.accountCurrency === 'USD' && slide?.routingNumber}

                                                            {slide?.accountCurrency === 'EUR' && slide?.iban}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            Currency
                                                        </Typography>
                                                        <div className="flex gap-2">
                                                            <FlagIcon flag={slide?.accountCurrency} />
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {slide?.accountCurrency || '--'}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} className="flex gap-2">
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                                                            Description
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={14} fontWeight={400}>
                                                            {slide?.description || '...'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            ),
                        )
                    ) : (
                        <div className="flex h-full items-center justify-center ">
                            Logistio Bank information Coming soon
                        </div>
                    )}
                </Carousel>
            )}
        </div>
    );
};

export default WalletCardHolder;
