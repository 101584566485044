/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { getOrders, updateOrder } from 'services/order';
import toast from 'react-hot-toast';
import { OrderInformation } from './orderInformation';

const EditOrder: React.FC<any> = ({ data, setRows, currentPage, currentFilter }) => {
    const [editInfo, setEditInfo] = useState(false);
    const [order, setOrder] = useState();
    useEffect(() => {
        setOrder(data);
    }, [data]);
    const storeId = localStorage.getItem('storeId');
    const updateOrderInformations = (Feedback: any) => {
        updateOrder({ id: data._id, ...Feedback })
            .then((res) => {
                toast.success(res.data.message);
                setOrder(res.data.data);
                setEditInfo(false);
                getOrders({ page: currentPage, limit: 8 }, { ...currentFilter, store: storeId!, cod: true })
                    .then((result) => {
                        setRows(result.data.docs);
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.errors?.message);
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };
    return (
        <>
            <OrderInformation
                data={order}
                editInfo={editInfo}
                setEditInfo={setEditInfo}
                updateOrderInformations={updateOrderInformations}
            />
        </>
    );
};

export default EditOrder;
