import { styled } from '@mui/material/styles';

export const uploadWrapper = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    border: '1px dashed',
    borderColor: 'textColor.secondary',

    //   cursor: 'pointer',
};

export const CustomFileInput = styled('input')(() => {
    return {
        opacity: 0,
        width: '0.1px',
        height: ' 0.1px',
        cursor: 'pointer',
        fontSize: '12px',
    };
});

export const CustomLabelInput = styled('label')(() => {
    return {
        cursor: 'pointer',
        fontWeight: 500,
        // padding: '6px 16px',
        width: '100%',
        padding: '100px',
    };
});

export const uploadContainer = {
    display: 'flex',
    justifyContent: 'center',
};

export const uploadTextWrapper = {};

export const clickTextStyle = {
    fontSize: '14px',
    fontWeight: '600',
    color: 'textColor.secondary',
    marginBottom: '10px',
};

export const imageSizeStyle = { fontSize: '12px', fontWeight: '500', color: 'textColor.secondary' };

export const imageTypes = { fontSize: '12px', fontWeight: '500', color: 'textColor.secondary' };
