export const variantsWrapper = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
    minHeight: '60px',
    backgroundColor: 'primaryBackground',
    border: '2px solid',
    borderColor: 'borderColor',
    borderRadius: '8px',
    padding: '10px',
};

export const variantsContainer = {
    border: '1px solid',
    borderColor: 'borderColor',
    borderRadius: '8px',
    backgroundColor: 'secondaryBackground',
    padding: '10px',
    margin: '10px',
};

export const addQuotation = { display: 'flex', justifyContent: 'flex-end', margin: '20px 0px' };
export const quotationsOuterBox = {
    mt: 0.5,
    backgroundColor: 'primaryBackground',
    border: '2px solid',
    borderColor: 'borderColor',
    borderRadius: '8px',
    padding: '10px',

    // top: 0,
};
export const quotationsWrapper = (ok: boolean) => {
    return {
        // minHeight: '100px',
        display: 'grid',
        gridGap: '5px',

        gridTemplateColumns: ok ? '1fr 9.25fr ' : '1fr ',
        //  margin: '10px 0px',
        //  alignItems: 'center',
    };
};

export const quotationsContainer = (ok: boolean) => {
    return {
        width: '175px',
        backgroundColor: 'secondaryBackground',
        border: '1px solid',
        borderColor: 'borderColor',
        borderRadius: '8px',
        padding: '10px',
        display: ok ? 'flex' : 'none',
        flexDirection: 'column',
    };
};
export const quotationVariantsWrapper = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    width: '175px',
    flexWrap: 'wrap',
};

export const quotationVariantsContainer = {
    border: '1px solid',
    borderColor: 'borderColor',
    borderRadius: '8px',
    backgroundColor: 'newNotificationBackground',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
};

export const quotationOptionsWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    // mb: 2,
};
export const quotationOptionsContainer = {
    display: 'grid',
    gridGap: '5px',
    width: '100%',
    gridTemplateColumns: '1fr 1.5fr 1.5fr 1.5fr 2fr 1.5fr 2fr 0.3fr',
    // gridTemplateColumns: '1fr 1fr 1.5fr 1.5fr 1.5fr 2fr 1fr 1.5fr 1.5fr 0.3fr',
    // alignItems: 'center',
    textAlign: 'center',
};
export const productDesignImagesContainer = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
};

export const EndQuotationContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
};

export const ButtonsStyling = {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
};
