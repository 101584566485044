import { Buffer } from 'buffer';
import axios from 'utils/axios/axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const refreshTokenCall = async (refresh: string) => {
    const response = await axios.post(
        `${BACKEND_URL}/auth/token`,
        {
            refreshToken: refresh,
        },
        { headers: { Authorization: `Bearer ${refresh}` } },
    );
    return response;
};

export const getCurrentUser = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const decodedtoken = JSON.parse(Buffer.from(token!.split('.')[1], 'base64').toString());
        return decodedtoken;
    }
    return false;
};

export const getUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        const decodedUser = JSON.parse(localStorage.getItem('user')!);
        return decodedUser;
    }
    return false;
};

export const getBearerToken = async () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    let header = { headers: { Authorization: `Bearer ${token}` } };
    let decodedtoken;
    if (token) {
        decodedtoken = JSON.parse(Buffer.from(token!.split('.')[1], 'base64').toString());

        if (Date.now() >= decodedtoken.exp * 1000) {
            await refreshTokenCall(refreshToken!)
                .then((result) => {
                    const newToken = result.data.token;

                    localStorage.setItem('token', newToken);
                    header = { headers: { Authorization: `Bearer ${newToken}` } };
                })
                .catch(() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                });
            return header;
        }
        return { headers: { Authorization: `Bearer ${token}` } };
    }
    return { headers: { Authorization: `` } };
};

export const isAuthenticated = () => {
    const token = window.localStorage.getItem('token');
    return !!token;
};

export const updateUserToken = async () => {
    // const refreshToken = localStorage.getItem('refreshToken');
    const refreshTokenReq = await refreshTokenCall(localStorage.getItem('refreshToken')!);
    localStorage.setItem('token', refreshTokenReq.data.token);
    // localStorage.setItem('refreshToken', refreshTokenReq.data.refreshToken);
};
