import React from 'react';

const ProfileSkeletonLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-20">
                <div className="">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                </div>
                <div className="col-span-2 flex flex-col gap-10 ">
                    <div className=" flex gap-5 items-center  ">
                        <div className="flex items-center justify-center  h-36 bg-gray-300 rounded w-40 dark:bg-gray-700">
                            <svg
                                className="w-10 h-10 text-gray-200 dark:text-gray-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 18"
                            >
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                            </svg>
                        </div>

                        <div className="w-full">
                            <div
                                role="status"
                                className="w-full max-w-48 p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700 mb-1"
                            >
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full " />
                            </div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-48 " />
                        </div>
                    </div>

                    <div>
                        <div className="mb-5">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-48 mb-4  " />
                            <div
                                role="status"
                                className="w-full  p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700 mb-1"
                            >
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-52" />
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-36 mb-4  " />
                            <div
                                role="status"
                                className="w-full  p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700 mb-1"
                            >
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-56" />
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-60 mb-4  " />
                            <div
                                role="status"
                                className="w-full  p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700 mb-1"
                            >
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-48" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="my-10 h-px border-t-0 bg-gray-300 dark:bg-gray-700" />
            <div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 ">
                    <div className="">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
                    </div>
                    <div className="">
                        <div
                            role="status"
                            className="w-full  p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700 mb-1"
                        >
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-48" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileSkeletonLoader;
