import * as Yup from 'yup';

const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d-]([a-z\\d-]*[a-z\\d-])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
);

export const changePersonalInfos = Yup.object().shape({
    firstPage: Yup.object().shape({
        firstName: Yup.string().required('First Name is required').trim('Should not be empty'),
        lastName: Yup.string().required('Last Name is required').trim(),
    }),
});

export const addStoreSchema = Yup.object().shape({
    secondPage: Yup.object().shape({
        storeName: Yup.string().required('Store name is required'),
        storeLink: Yup.string().matches(pattern, 'Enter correct store url').required('Store link is required'),
        withCC: Yup.boolean(),
    }),
});

// export const addStoreSchema = (storeType: any) => {
//     if (storeType === 'shopify')
//         return Yup.object().shape({
//             secondPage: Yup.object().shape({
//                 storeName: Yup.string().required('store name is required'),
//                 storeType: Yup.string().required('store type is required'),
//                 storeLink: Yup.string().required('store link is required'),
//                 accessToken: Yup.string().required('access token is required'),
//             }),
//         });
//     return Yup.object().shape({
//         secondPage: Yup.object().shape({
//             storeName: Yup.string().required('store name is required'),
//             storeType: Yup.string().required('store type is required'),
//             storeLink: Yup.string().required('store link is required'),
//             accessToken: Yup.string().optional(),
//         }),
//     });
// };
