import React from 'react';
import { openPdfInNewTab } from 'utils/helpers/zipDownloader';
import Zoom from 'react-medium-image-zoom';
import FilePdf from '../../assets/pdf.png';

const ImageProofVerification: React.FC<{
    verifImage: {
        file: File | null;
        name: string;
        targetKey: 'verificationImage';
    } | null;
    urlImage: string;
}> = ({ verifImage, urlImage }) => {
    if (
        verifImage?.file &&
        (verifImage.file.type.endsWith('/jpg') ||
            verifImage.file.type.endsWith('/png') ||
            verifImage.file.type.endsWith('/jpeg') ||
            verifImage.file.type.endsWith('/svg') ||
            verifImage.file.type.endsWith('/webp') ||
            verifImage?.file.type.endsWith('/svg%2Bxml'))
    ) {
        return (
            <Zoom>
                <img
                    src={urlImage}
                    alt="productImg"
                    style={{
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 150,
                        height: 150,
                    }}
                />
            </Zoom>
        );
    }
    if (verifImage?.file && verifImage?.file.type.endsWith('/pdf')) {
        return (
            <button
                type="button"
                style={{
                    all: 'unset',
                }}
                onClick={() => openPdfInNewTab(urlImage, verifImage.name)}
            >
                <img
                    src={FilePdf}
                    alt={verifImage.name}
                    style={{
                        cursor: 'pointer',
                        objectFit: 'cover',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 100,
                        height: 100,
                    }}
                />
            </button>
        );
    }
    return null;
};

export default ImageProofVerification;
