/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import moment from 'moment';

export const getExpiredDate = (expirationDate: string | number = '') => {
    if (!expirationDate)
        return {
            daysUntilExpiration: 0,
            minutesUntilExpiration: 0,
            hoursUntilExpiration: 0,
            expiredIn: `Expired`,
        };
    const currentDate = moment();
    const expiration = moment(expirationDate || '');
    const duration = moment.duration(expiration.diff(currentDate));
    const daysUntilExpiration = duration.days();
    const minutesUntilExpiration = duration.minutes() % 60;
    const hoursUntilExpiration = duration.hours();
    const expiredIn =
        daysUntilExpiration === 0
            ? `${
                  hoursUntilExpiration === 1
                      ? `1 hour `
                      : hoursUntilExpiration > 0
                        ? ` ${hoursUntilExpiration} hour `
                        : ''
              } ${minutesUntilExpiration} min`
            : daysUntilExpiration === 1
              ? '1 day'
              : ` ${daysUntilExpiration} days`;
    return {
        daysUntilExpiration,
        minutesUntilExpiration,
        hoursUntilExpiration,
        expiredIn: `${expiredIn} `,
    };
};
