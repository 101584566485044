import React from 'react';
import toast, { ToastBar } from 'react-hot-toast';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosInformationCircle } from 'react-icons/io';

const InfoToast: React.FC<{ t: any; message: string }> = ({ t, message }) => {
    return (
        <ToastBar toast={t}>
            {() => (
                <div
                    id="toast-success"
                    className="flex items-center w-full  max-w-xs p-3  text-gray-500  dark:text-gray-400  "
                >
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg ml-1">
                        <IoIosInformationCircle className="w-6 h-6 text-blue-600" />
                    </div>
                    <div className="ml-0 px-2 text-sm font-normal">{message}</div>
                    {t.type !== 'loading' && (
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            type="button"
                            className="ml-auto -mx-1.5  bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1 hover:bg-gray-100   dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                        >
                            <AiOutlineClose className="h-4 w-4" />
                        </button>
                    )}
                </div>
            )}
        </ToastBar>
    );
};

export default InfoToast;
