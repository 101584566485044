/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Messages from 'pages/messages/Messages';
import Typography from '@mui/material/Typography';
import Quotation from 'pages/quotation/Quotation';
import EditProduct from 'pages/editProduct/EditProduct';
import ProductInfo from './productInfo';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pr: 3, pl: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const productSettings: React.FC = () => {
    const location: any = useLocation();
    const [value, setValue] = useState(0);
    useEffect(() => {
        setValue(location.state.tabId);
    }, []);

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(Number(newValue));
    };

    return (
        <Box sx={{ backgroundColor: 'primaryBackground' }}>
            <Box sx={{ backgroundColor: 'secondaryBackground' }}>
                <Tabs
                    value={value}
                    onChange={(e, newValue) => handleChange(e, newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{ backgroundColor: 'secondaryBackground' }}
                >
                    {location.state.productStatus < 2 && <Tab label="Edit Product" />}
                    {location.state.productStatus >= 2 && <Tab label="Product" />}

                    <Tab label="Quotation" />
                    <Tab label="Chat" />
                </Tabs>
                {location.state.productStatus < 2 && (
                    <TabPanel value={value} index={0}>
                        <EditProduct />
                    </TabPanel>
                )}
                {location.state.productStatus >= 2 && (
                    <TabPanel value={value} index={0}>
                        <ProductInfo />
                    </TabPanel>
                )}

                <TabPanel value={value} index={1}>
                    <Quotation />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Messages />
                </TabPanel>
            </Box>
            <Outlet />
        </Box>
    );
};

export default productSettings;
