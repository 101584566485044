import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { BsBank } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import DynamicTitle from 'utils/helpers/DynamicTitle';

const Settings: React.FC = () => {
    const location = useLocation();
    // eslint-disable-next-line prefer-destructuring
    const pathname = location.pathname;
    const state = location.state as { currentTab: number };
    const renderPath = (param: string) => {
        switch (param) {
            case '/settings/profile-settings':
                return 0;
            case '/settings/wallet':
                return 1;
            case '/settings/bank-information':
                return 2;
            case '/settings/stock-information':
                return 3;
            default:
                return 0;
        }
    };
    const [value, setValue] = useState(renderPath(pathname));
    const history = useNavigate();
    useEffect(() => {
        if (state && state.currentTab) {
            setValue(state.currentTab);
        }
    }, [state]);
    const tabRoutes = [
        {
            name: 'Profile',
            path: '/settings',
            icon: <CgProfile className="w-6 h-6" />,
            id: 0,
        },
        {
            name: 'Wallet',
            path: '/settings/wallet',
            icon: <AccountBalanceWalletOutlinedIcon />,
            id: 1,
        },
        {
            name: 'Bank Information',
            path: '/settings/bank-information',
            icon: <BsBank className="w-5 h-5" />,
            id: 2,
        },
        {
            name: 'Stock Information',
            path: '/settings/stock-information',
            icon: <MdOutlineProductionQuantityLimits className="w-5 h-5" />,
            id: 3,
        },
    ];

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(Number(newValue));
    };

    DynamicTitle('Settings');

    return (
        <div>
            <div>
                <Tabs
                    value={value}
                    onChange={(e, newValue) => handleChange(e, newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Profile settings Tabs"
                    sx={{ marginBottom: '10px' }}
                >
                    {tabRoutes.map(({ name, path, id, icon }) => (
                        <Tab
                            key={id}
                            // value={value - 1}

                            label={name}
                            icon={icon}
                            iconPosition="start"
                            onClick={() => history(path)}
                            sx={{ textTransform: 'none' }}
                        />
                    ))}
                </Tabs>
            </div>

            <Outlet />
        </div>
    );
};

export default Settings;
