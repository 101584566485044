/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { Ifilter, Ipagination, getStores } from 'services/stores';
import { IStore } from 'utils/Interfaces/stores.interface';
import AccountAvatar from '../accountAvatar/AccountAvatar';
import InputField from '../inputField/InputField';
import MenuItem from '../menuItem/MenuItem';
import ExpandingNavList from './ExpandingNavList';
import {
    AccountsContainer,
    AccountsWrapper,
    ArrowButton,
    CurrentAccountContainer,
    DrawerTopArea,
    IconStyle,
    LogistioLogoFull,
    LogistioLogoSmall,
    OtherAccountsContainer,
    // OtherAccountsContainer,
    SearchArea,
    SidebarContainer,
    StyledList,
    logistioLogoOpened,
    openCloseIcon,
} from './SideBar.styles';
import { SideBarProps } from './SideBar.types';

const SideBar: React.FC<SideBarProps> = ({ onCollapse }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [displayAccounts, setDisplayAccounts] = useState(false);
    const [shopSearch, setShopSearch] = useState('');

    const [accounts, setAccounts] = useState<any>([]);
    const [currentStore, setCurrentStore] = useState<IStore>();

    const [filter] = useState<Ifilter>({});
    const [pagination] = useState<Ipagination>({
        page: 1,
        limit: 100,
    });

    useEffect(() => {
        getStores(pagination, filter)
            .then((res) => {
                setAccounts(res.data.docs);
                const tokenId = localStorage.getItem('storeId');
                if (tokenId) {
                    const account = res.data.docs.filter((doc: any) => doc.id === tokenId);
                    if (account.length > 0) {
                        setCurrentStore(account[0]);
                    } else {
                        setCurrentStore(res.data.docs[0]);
                        localStorage.setItem('storeId', res.data.docs[0]._id);
                    }
                } else {
                    setCurrentStore(res.data.docs[0]);
                    localStorage.setItem('storeId', res.data.docs[0]._id);
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [pagination, filter]);

    return (
        <div className="">
            <Box sx={SidebarContainer}>
                <Box>
                    <Box sx={DrawerTopArea(isCollapsed)}>
                        <Box sx={logistioLogoOpened}>
                            {isCollapsed ? (
                                <Link to="/">
                                    <LogistioLogoSmall />{' '}
                                </Link>
                            ) : (
                                <Link to="/">
                                    <LogistioLogoFull />
                                </Link>
                            )}
                        </Box>
                        <div>
                            <ArrowButton
                                isCollapsed={isCollapsed}
                                onClick={() => {
                                    setIsCollapsed(!isCollapsed);
                                    setDisplayAccounts(false);
                                    onCollapse();
                                }}
                                id="storeOpenCloseButton"
                            >
                                {isCollapsed ? (
                                    <ArrowForwardIosIcon sx={IconStyle} />
                                ) : (
                                    <ArrowBackIosIcon sx={IconStyle} />
                                )}
                            </ArrowButton>
                        </div>
                    </Box>

                    <Box sx={AccountsContainer}>
                        <Box sx={AccountsWrapper}>
                            <Box sx={CurrentAccountContainer(isCollapsed)}>
                                <AccountAvatar
                                    isCollapsed={isCollapsed}
                                    shopName={currentStore ? currentStore.storeName : ''}
                                    description={currentStore ? currentStore.storeType : ''}
                                />
                                <Box>
                                    {accounts.length >= 1 ? (
                                        displayAccounts ? (
                                            <ArrowDropUpIcon
                                                onClick={() => {
                                                    setDisplayAccounts(false);
                                                }}
                                                sx={openCloseIcon}
                                                id="openStoreBox"
                                            />
                                        ) : (
                                            <ArrowDropDownIcon
                                                onClick={() => {
                                                    setDisplayAccounts(true);
                                                    setIsCollapsed(false);
                                                    // eslint-disable-next-line no-unused-expressions
                                                    isCollapsed && onCollapse();
                                                }}
                                                sx={openCloseIcon}
                                                id="CloseStoreBox"
                                            />
                                        )
                                    ) : null}
                                </Box>
                            </Box>

                            {displayAccounts && (
                                <Box>
                                    <Divider />
                                    <Box sx={OtherAccountsContainer}>
                                        {accounts
                                            .filter((account: any) =>
                                                account.storeName.toLowerCase().includes(shopSearch.toLowerCase()),
                                            )
                                            .map((account: any) => (
                                                <Box
                                                    onClick={() => {
                                                        setCurrentStore(account);
                                                        localStorage.setItem('storeId', account.id);
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <AccountAvatar
                                                        isCollapsed={isCollapsed}
                                                        shopName={account.storeName}
                                                        description={account.storeType}
                                                    />
                                                </Box>
                                            ))}
                                    </Box>
                                    <Divider />
                                    <Box sx={SearchArea}>
                                        <InputField
                                            InputType="search"
                                            placeholder="search account"
                                            onChange={(e) => setShopSearch(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {!displayAccounts && (
                        <StyledList isCollapsed={isCollapsed}>
                            <Box>
                                {routes.map((prop: any, index: number) => {
                                    if (prop.type === 'features') {
                                        if (
                                            prop.path === 'lightfunnels-matching' &&
                                            currentStore?.storeType !== 'lightfunnels'
                                        )
                                            return;
                                        if (prop.children) {
                                            return (
                                                <ExpandingNavList
                                                    index={index}
                                                    isCollapsed={isCollapsed}
                                                    prop={prop}
                                                    currentStore={currentStore!}
                                                    key={`${prop.name}${index}`}
                                                />
                                            );
                                        }
                                        return (
                                            <MenuItem
                                                key={index}
                                                collapsed={isCollapsed}
                                                selected={false}
                                                icon={<prop.icon />}
                                                label={prop.name}
                                                path={prop.path}
                                            />
                                        );
                                    }
                                })}
                            </Box>
                        </StyledList>
                    )}

                    <StyledList isCollapsed={isCollapsed}>
                        <Divider sx={{ margin: '0 15px 10px 15px' }} />
                        {routes.map((prop: any, index: number) => {
                            if (prop.type === 'parameters') {
                                return (
                                    <MenuItem
                                        key={index}
                                        collapsed={isCollapsed}
                                        selected
                                        icon={<prop.icon />}
                                        label={prop.name}
                                        path={prop.path}
                                    />
                                );
                            }
                        })}
                    </StyledList>
                </Box>
                <StyledList isCollapsed={isCollapsed}>
                    {/* <Box
                        onClick={() => {
                            localStorage.removeItem('token');
                        }}
                    >
                        <MenuItem
                            collapsed={isCollapsed}
                            selected={false}
                            icon={<ExitToAppIcon />}
                            label="Logout"
                            path=""
                        />
                    </Box> */}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body2">
                            {isCollapsed ? '' : 'All rights reserved © 2024 Logistio'}
                        </Typography>
                    </Box>
                </StyledList>
            </Box>
        </div>
    );
};

export default SideBar;
