import { INVOICE_TYPES } from 'pages/invoice/invoice.types';
import { IInvoice, TPaginatedInvoices } from 'utils/Interfaces/invoice.interface';
import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const getInvoiceList = async (filter: any) => {
    const config = await getBearerToken();
    const invoices = await axios.post<TPaginatedInvoices>(`/invoices/filter`, filter, config);
    return invoices;
};

export const getInvoice = async (id: string) => {
    const config = await getBearerToken();
    const invoice = await axios.get<IInvoice>(`/invoices/${id}`, config);
    return invoice;
};
export const getInvoiceLink = async (invoiceId: string) => {
    const config = await getBearerToken();
    const response = await axios.get<Buffer>(`/invoices/getInvoiceLink/${invoiceId}`, {
        responseType: 'arraybuffer',
        ...config,
    });
    return response;
};
export const getInvoicePdf = async (data: { invoiceId: string; timezone: string; invoiceType: INVOICE_TYPES }) => {
    const config = await getBearerToken();
    const response = await axios.post<Buffer>(`/invoices/getPdfInvoice`, data, {
        responseType: 'arraybuffer',
        ...config,
    });
    return response;
};
