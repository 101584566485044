/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line no-unused-expressions */
import React from 'react';
import { Box, Button } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import ProductImageUpload from 'components/productImageUpload/ProductImageUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomError from 'components/customError/CustomError';
import Zoom from 'react-medium-image-zoom';
import { FormikProps } from 'formik';
import PdfImage from '../../../assets/pdf.png';
import FileImage from '../../../assets/file.png';

import {
    productDesignContainer,
    productDesignImagesContainer,
    productDesignWrapper,
    sourcingProcessSubtitles,
} from '../Sourcing.styles';
import 'react-medium-image-zoom/dist/styles.css';

const StepThree = (props: any) => {
    const { formik, allImages, setAllImages, isRestock } = props;

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<any>) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => {
                if (file) {
                    formik.setFieldValue('photo', file);
                    const reader = new FileReader();
                    const fileObjectUrl = URL.createObjectURL(file);
                    reader.onload = () => {
                        setAllImages((prevImages: any) => [
                            ...prevImages,
                            {
                                file,
                                name: file.name,
                                url: fileObjectUrl,
                                new: true,
                            },
                        ]);
                    };
                    reader.readAsDataURL(file);
                }
            });
        }
    };

    const handleDeleteImage = (imageURL: string) => {
        /**
         * 🏷️ Delete action
         * Remove image data from the local state
         * &
         * we release its objectURL from the browser (performance & memory usage optimisation)
         */
        const updatedData: typeof allImages = [];
        const objectURLs: string[] = [];
        allImages.forEach((img: any) => {
            if (img.url !== imageURL) {
                updatedData.push(img);
            } else {
                objectURLs.push(img.url);
            }
        });
        setAllImages([...updatedData]);
        objectURLs.forEach((url) => {
            URL.revokeObjectURL(url);
        });
    };

    // const isItImageOrOtherType = () => {
    //     if(allImages.length > 0 ) {
    //         return
    //     }
    // };

    return (
        <Box sx={productDesignWrapper}>
            <CustomCard
                body={
                    <Box>
                        <Box sx={sourcingProcessSubtitles}>Product Design</Box>
                        {allImages.length > 0 ? (
                            <>
                                <Box sx={productDesignImagesContainer}>
                                    {allImages.length > 0 &&
                                        allImages.map((image: any) => {
                                            return (
                                                <Box sx={{ position: 'relative' }} key={image.url}>
                                                    {image.name.endsWith('.jpg') ||
                                                    image.name.endsWith('.png') ||
                                                    image.name.endsWith('.jpeg') ||
                                                    image.name.endsWith('.JPG') ||
                                                    image.name.endsWith('.PNG') ||
                                                    image.name.endsWith('.webp') ||
                                                    image.name.endsWith('.JPEG') ? (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 2,
                                                                    right: 2,
                                                                    zIndex: 1,
                                                                    padding: '5px',
                                                                    borderRadius: 100,
                                                                    minWidth: 'fit-content',
                                                                }}
                                                                disabled={isRestock && !image.new}
                                                                onClick={() => {
                                                                    handleDeleteImage(image.url);
                                                                }}
                                                            >
                                                                <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                                            </Button>
                                                            <Zoom>
                                                                <img
                                                                    alt="product design"
                                                                    src={image.url}
                                                                    style={{
                                                                        width: 150,
                                                                        height: 150,
                                                                        objectFit: 'cover',
                                                                        overflow: 'hidden',
                                                                        borderRadius: 7,
                                                                    }}
                                                                />
                                                            </Zoom>
                                                        </>
                                                    ) : image.name.endsWith('.pdf') || image.name.endsWith('.PDF') ? (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 2,
                                                                    right: 2,
                                                                    zIndex: 1,
                                                                    padding: '5px',
                                                                    borderRadius: 100,
                                                                    minWidth: 'fit-content',
                                                                }}
                                                                onClick={() => {
                                                                    handleDeleteImage(image.url);
                                                                }}
                                                            >
                                                                <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                                            </Button>

                                                            <img
                                                                alt="product design"
                                                                src={PdfImage}
                                                                style={{
                                                                    width: 150,
                                                                    height: 150,
                                                                    objectFit: 'cover',
                                                                    overflow: 'hidden',
                                                                    borderRadius: 7,
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 2,
                                                                    right: 2,
                                                                    zIndex: 1,
                                                                    padding: '5px',
                                                                    borderRadius: 100,
                                                                    minWidth: 'fit-content',
                                                                }}
                                                                onClick={() => {
                                                                    handleDeleteImage(image.url);
                                                                }}
                                                            >
                                                                <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                                                            </Button>

                                                            <img
                                                                alt="product design"
                                                                src={FileImage}
                                                                style={{
                                                                    width: 150,
                                                                    height: 150,
                                                                    objectFit: 'cover',
                                                                    overflow: 'hidden',
                                                                    borderRadius: 7,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                </Box>
                            </>
                        ) : (
                            <Box>
                                <ProductImageUpload
                                    name="photo"
                                    onChange={(e) => {
                                        handleFileUpload(e, formik);
                                    }}
                                />
                                <Box>
                                    <CustomError name="photo" component="Box" />
                                </Box>
                            </Box>
                        )}
                    </Box>
                }
                extraStyle={allImages.length > 0 && productDesignContainer}
            />
            {allImages.length > 0 && (
                <CustomCard
                    body={
                        <ProductImageUpload
                            onChange={(e) => {
                                handleFileUpload(e, formik);
                            }}
                            userDisplayedImage={allImages}
                        />
                    }
                />
            )}
        </Box>
    );
};

export default StepThree;
