import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import React from 'react';
import { ListItemStyle, roundedIcon, StyledListItemButton, StyledListItemIcon } from './ExpandingNavList.styles';

const StyleList = ({
    handleHover,
    handleLeave,
    handleClick,
    isCollapsed,
    hovered,
    prop,
    open,
}: {
    handleHover: any;
    handleLeave: any;
    handleClick: any;
    isCollapsed: any;
    hovered: any;
    prop: any;
    open: any;
}) => {
    return (
        <StyledListItemButton
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            onClick={handleClick}
            collapsed={isCollapsed}
        >
            <Box sx={ListItemStyle(isCollapsed)}>
                <Box sx={roundedIcon(hovered)}>
                    <StyledListItemIcon hovered={hovered} iconName={prop.name}>
                        <prop.icon />
                    </StyledListItemIcon>
                </Box>

                {!isCollapsed && <Box sx={{ fontSize: '14px', marginLeft: '15px' }}>{prop.name}</Box>}
            </Box>

            {/* {!isCollapsed && <ListItemText primary={prop.name} />} */}
            {open ? (
                <ExpandLess
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                        color: 'textColor.variantBox',
                        padding: '2px',
                        fontSize: '12px',
                        width: '20px',
                        height: '20px',
                        marginLeft: '5px',
                    }}
                />
            ) : (
                <ExpandMore
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                        color: 'textColor.variantBox',
                        padding: '2px',
                        fontSize: '12px',
                        width: '20px',
                        height: '20px',
                        marginLeft: '5px',
                    }}
                />
            )}
        </StyledListItemButton>
    );
};
export default StyleList;
