import { logistioCountries } from 'utils/countryList';

export const currencySymbol = (currency: string) => {
    let symbol = '';
    if (currency === 'USD') {
        symbol = '$';
    }
    if (currency === 'EUR') {
        symbol = '€';
    }
    return symbol;
};

export const currencyConverter = (price: any, currency: string) => {
    let a = 'en-US';
    let b = 'USD';

    // if (currency && (currency === 'USD' || currency === '$')) {
    //     a = 'en-US';
    // }
    if (currency && (currency === 'EUR' || currency === '€')) {
        a = 'en-DE';
        b = 'EUR';
    }

    const currencyStyle = Intl.NumberFormat(a, {
        style: 'currency',
        currency: b,
        useGrouping: true,
    });

    return currencyStyle.format(price);
};

export const currencytest = (price: any) => {
    const currencyStyle = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        useGrouping: true,
    });

    return currencyStyle.format(price);
};

export const getCurrencyByCountryCode = (countryCode: string) => {
    const countryFounder = logistioCountries.find((country) => country.label === countryCode);
    return countryFounder ? countryFounder.currency : '...';
};
