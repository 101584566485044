/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/system/Box';
import Welcomepage from 'pages/welcomePage/welcomepage';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCustomer } from 'services/customer';
import { getCurrentUser, isAuthenticated } from 'utils/helpers/auth';

interface IRouteInfo {
    redirectionLink?: string;
}
const PrivateRoute: React.FC<IRouteInfo> = ({ redirectionLink }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isAuth = isAuthenticated();
    const customerId = getCurrentUser()._id;
    const [isDisabled, setIsDisabled] = useState();

    const [loading, setLoading] = useState(true);

    const [customerFirstLogin, setCustomerFirstLogin] = useState<boolean>();

    const accountDisabled = () => {
        localStorage.removeItem('token');
        return <Navigate to="/auth/login" state={{ from: location }} replace />;
    };

    useEffect(() => {
        getCustomer(customerId)
            .then((res) => {
                setCustomerFirstLogin(res.data.firstLogin);
                setIsDisabled(res.data.status);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    navigate('/auth/login', {
                        state: { from: location, previousLink: redirectionLink },
                        replace: true,
                    });
                }
            });
    }, []);
    return isAuth ? (
        loading ? (
            <Box
                sx={{
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        ) : customerFirstLogin ? (
            <Welcomepage />
        ) : // http://localhost:3001/redirects/lightfunnels
        isDisabled === 3 ? (
            accountDisabled()
        ) : (
            <Outlet />
        )
    ) : (
        <Navigate to="/auth/login" state={{ from: location, previousLink: redirectionLink }} replace />
    );
};

export default PrivateRoute;
