/* eslint-disable  */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { Box, FormControl, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import React, { useEffect, useState } from 'react';
import { getWallet, getWalletOperations, Ifilter, Ipagination, getStatus } from 'services/wallet';
import Select from '@mui/material/Select';
import { NumericFormat } from 'react-number-format';

import {
    cardsBox,
    dateAmount,
    dateTime,
    filtersContainer,
    historyCardContainer,
    historyCards,
    historyCardsContainer,
    leftContainer,
    statusColor,
    walletCardContainer,
    walletContainer,
} from './wallet.styles';
import WalletCardHolder from './walletCardHolder';
import WalletCardVerification from './walletCardVerification';
import Datepicker from 'react-tailwindcss-datepicker';
import { IdateRange } from './wallet.types';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import { getCurrentUser } from 'utils/helpers/auth';
import { getCustomer } from 'services/customer';
import { currencyConverter, currencySymbol, currencytest } from 'utils/helpers/currencySymbol';
import { IBankInformations, IConfiguration, getConfiguration } from 'services/configuration';
import moment from 'moment';
import toast from 'react-hot-toast';

const Wallet = () => {
    const [walletAmount, setWalletAmount] = useState();
    const [walletId, setWalletId] = useState();
    const [OperationList, setOperationList] = useState<any>([]);
    const [configuration, setConfiguration] = useState<IConfiguration>({});
    const [filter, setFilter] = useState<Ifilter>({
        wallet: walletId,
    });
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 100,
    });

    const handleWalletChange = (newFilter: React.SetStateAction<Ifilter>) => {
        const updatedFilter: any = { ...filter, ...newFilter };

        Object.keys(updatedFilter).forEach((key) => {
            if (updatedFilter[key] === '' || updatedFilter[key] === 0) {
                delete updatedFilter[key];
            }
        });
        setFilter(updatedFilter);
    };

    const [rangevalue, setRangeValue] = useState<IdateRange>({
        startDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2).toDateString(),
        endDate: new Date().toDateString(),
    });
    const handleRangeFilterChange = (newValue: IdateRange | null) => {
        if (newValue) {
            const startD = moment(newValue.startDate);
            const endD = moment(newValue.endDate);
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            setRangeValue({
                startDate: startD.toDate(),
                endDate: endD.toDate(),
            });
            setFilter({
                dateRange: {
                    startDate: startD.toDate(),
                    endDate: endD.toDate(),
                },
            });
        }
    };

    const [filStatus, setFilStatus] = useState(filter.status?.toString());
    const [filOp, setFilOp] = useState(filter.operationType?.toString());

    const [customerCurrency, setCustomerCurrency] = useState('');

    const customerId = getCurrentUser()._id;

    const [customerCurrencyLoader, setCustomerCurrencyLoader] = useState(false);
    useEffect(() => {
        setCustomerCurrencyLoader(true);
        getCustomer(customerId)
            .then((res) => {
                setCustomerCurrency(res?.data.currency);
                setCustomerCurrencyLoader(false);
            })
            .catch(() => {
                toast.error(
                    'Something went wrong while fetching your data, please try reloading the page, if the problem persists please contact our support',
                );
                setCustomerCurrencyLoader(false);
            });
    }, []);

    const [walletAmountLoader, setWalletAmountLoader] = useState(false);
    useEffect(() => {
        setWalletAmountLoader(true);
        getWallet()
            .then((res) => {
                setWalletAmount(res.data.wallet.amount);
                setWalletId(res.data.wallet._id);
                setWalletAmountLoader(false);
            })
            .catch(() => {
                toast.error(
                    'Something went wrong while fetching your wallet amount, please try reloading the page, if the problem persists please contact our support',
                );
                setWalletAmountLoader(false);
            });
    }, []);

    const [configfurationLoader, setConfigurationLoader] = useState(false);
    useEffect(() => {
        setConfigurationLoader(true);
        getConfiguration()
            .then((res) => {
                setConfiguration(res?.data);
                setConfigurationLoader(false);
            })
            .catch(() => {
                toast.error(
                    'Something went wrong while fetching Logistio Bank information, please try reloading the page, if the problem persists please contact our support',
                );
                setConfigurationLoader(false);
            });
    }, []);

    const [operationLoader, setOperationLoader] = useState(false);
    useEffect(() => {
        setOperationLoader(true);
        getWalletOperations(pagination, filter)
            .then((res) => {
                setOperationList(res.data.response.docs);
                setOperationLoader(false);
            })
            .catch(() => {
                toast.error(
                    'Something went wrong while fetching your Operation history, please try reloading the page, if the problem persists please contact our support',
                );
                setOperationLoader(false);
            });
    }, [pagination, filter]);

    DynamicTitle('Wallet');

    const renderSpinners = (count: number) => {
        const spinners = [];
        for (let i = 0; i < count; i++) {
            spinners.push(
                <div
                    key={i}
                    role="status"
                    className="p-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
                >
                    <div className="flex items-center justify-between">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>,
            );
        }
        return spinners;
    };
    return (
        <>
            <div className="">
                <div className="grid grid-cols-5 gap-5">
                    <div className="col-span-3 ">
                        <div className=" h-full flex flex-col justify-between">
                            <div>
                                <CustomCard
                                    extraStyle={walletCardContainer}
                                    body={
                                        <Box sx={walletContainer}>
                                            {walletAmountLoader && customerCurrencyLoader ? (
                                                <div
                                                    role="status"
                                                    className="max-w-sm animate-pulse  flex items-center gap-2"
                                                >
                                                    <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 "></div>
                                                    <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 "></div>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <Typography variant="subtitle2" fontSize={30}>
                                                    {walletAmount !== undefined &&
                                                        customerCurrency &&
                                                        currencyConverter(walletAmount, customerCurrency)}
                                                </Typography>
                                            )}
                                        </Box>
                                    }
                                />
                            </div>
                            <div>
                                <WalletCardHolder
                                    configfurationLoader={configfurationLoader}
                                    bankInformations={configuration?.bankInformations as IBankInformations}
                                />
                            </div>
                            <div>
                                <WalletCardVerification
                                    OperationList={OperationList}
                                    setOperationList={setOperationList}
                                    walletId={walletId}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-span-2">
                        <CustomCard
                            extraStyle={historyCardsContainer}
                            body={
                                <>
                                    <Box>
                                        <Typography variant="subtitle2" fontSize={20} sx={{ mb: 1, ml: 1 }}>
                                            Operations history
                                        </Typography>
                                        <Box sx={filtersContainer}>
                                            <FormControl sx={{ minWidth: 110 }} size="small">
                                                <InputLabel id="demo-simple-select-label">Operation</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={filOp}
                                                    label="Operation Type"
                                                    onChange={(e) => {
                                                        handleWalletChange({ operationType: e.target.value });
                                                    }}
                                                >
                                                    <MenuItem value="">Show all</MenuItem>
                                                    <MenuItem value="IN">In</MenuItem>
                                                    <MenuItem value="OUT">Out</MenuItem>
                                                    <MenuItem value="REFUND">REFUND</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ minWidth: 100 }} size="small">
                                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={filStatus}
                                                    label="Operation Type"
                                                    onChange={(e) => {
                                                        handleWalletChange({ status: Number(e.target.value) });
                                                    }}
                                                >
                                                    <MenuItem value="">Show all</MenuItem>
                                                    <MenuItem value="1">Pending</MenuItem>
                                                    <MenuItem value="2">Confirmed</MenuItem>
                                                    <MenuItem value="3">Rejected</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <Datepicker
                                                inputClassName="w-full border-gray-300 py-2 pl-4 pr-10 font-light text-sm rounded-md focus:ring-0 font-normal bg-custom-background dark:border-slate-600 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20 "
                                                inputId="deliveryDate"
                                                value={rangevalue}
                                                containerClassName="relative z-100"
                                                useRange
                                                onChange={handleRangeFilterChange}
                                            />
                                        </Box>
                                    </Box>

                                    <Box sx={cardsBox}>
                                        {operationLoader
                                            ? renderSpinners(6)
                                            : OperationList.map((op: any) => (
                                                  <CustomCard
                                                      key={op.id}
                                                      extraStyle={historyCardContainer}
                                                      body={
                                                          <Box sx={historyCards}>
                                                              <Typography
                                                                  variant="subtitle2"
                                                                  fontSize={14}
                                                                  fontWeight={400}
                                                              >
                                                                  {op.accountName ? op.accountName : '-'}
                                                              </Typography>
                                                              <Typography variant="body1" fontSize={14}>
                                                                  {op.operationType}
                                                              </Typography>
                                                              <Typography
                                                                  sx={statusColor(op.status)}
                                                                  variant="body1"
                                                                  fontSize={14}
                                                              >
                                                                  {getStatus(op.status)}
                                                              </Typography>
                                                              <Box sx={dateAmount}>
                                                                  <Box sx={dateTime}>
                                                                      <Typography variant="subtitle1" fontSize={12}>
                                                                          {/* {new Date(op.createdAt).toLocaleString()} */}
                                                                          {moment(op.createdAt).format(
                                                                              'MMM Do YY, HH:mm',
                                                                          )}
                                                                      </Typography>
                                                                  </Box>
                                                                  <Typography variant="subtitle2" fontSize={14}>
                                                                      {op.amount &&
                                                                          customerCurrency &&
                                                                          currencyConverter(
                                                                              op.amount,
                                                                              op.operationCurrency,
                                                                          )}
                                                                  </Typography>
                                                              </Box>
                                                          </Box>
                                                      }
                                                  />
                                              ))}
                                    </Box>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Wallet;
