import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const lightfunnelsMatch = async (data: any) => {
    const config = await getBearerToken();
    return axios.post(`/integrations/lightfunnels`, data, config);
};
export const getfunnels = async () => {
    const config = await getBearerToken();
    return axios.get(`/integrations/lightfunnels/getfunnels`, config);
};
