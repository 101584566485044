/* eslint-disable no-unused-vars */
import GeneralButton from 'components/customButton/generalButton';
import CustomError from 'components/customError/CustomError';
import { Label, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateCustomer } from 'services/customer';
import { changeEmailSchema } from 'utils/validations/accountInformationValidation';
import { UserEmailProps } from '../../../profileSettings/ProfileSettings.types';

const customTheme = {
    field: {
        input: {
            base: 'border-gray-300 w-full  dark:border-slate-600',
            colors: {
                general: 'bg-custom-background',
            },
        },
    },
};

const AccountInformation: React.FC<UserEmailProps> = ({ id, email }) => {
    const [profileLoader, setProfileLoader] = useState(false);

    const updateEmail = (values: any) => {
        setProfileLoader(true);
        updateCustomer(id, values)
            .then(() => {
                toast.success('Email Updated Successfully');
                setProfileLoader(false);
            })

            .catch(() => {
                toast.error('Something went wrong updating your email');
                setProfileLoader(false);
            });
    };

    return (
        <div>
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={(values) => {
                    updateEmail(values);
                }}
                validationSchema={changeEmailSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col gap-10">
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="Current Email" value="Current Email" className="text-lg font-medium" />

                                <TextInput
                                    theme={customTheme}
                                    color="general"
                                    id="currentEmail"
                                    type="text"
                                    value={email}
                                    sizing="md"
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="New Email" value="New Email" className="text-lg font-medium" />

                                <TextInput
                                    theme={customTheme}
                                    color="general"
                                    id="NewEmail"
                                    type="text"
                                    placeholder="Example: Joe.doe@gmail.com"
                                    sizing="md"
                                    {...formik.getFieldProps('email')}
                                    name="email"
                                    helperText={
                                        <>
                                            <CustomError name="email" component="div" />
                                        </>
                                    }
                                />
                            </div>
                            <div className="flex justify-end items-center">
                                <GeneralButton
                                    color="blue"
                                    type="submit"
                                    disabled={profileLoader}
                                    isProcessing={profileLoader}
                                >
                                    Save Changes
                                </GeneralButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AccountInformation;
