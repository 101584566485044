/* eslint-disable no-nested-ternary */
import React from 'react';
import { currencyConverter, getCurrencyByCountryCode } from 'utils/helpers/currencySymbol';
import { IProductDetailsCard } from './productDetailsCard.types';

const ProductDetailsCard: React.FC<IProductDetailsCard> = ({ icon, title, value, country }) => {
    const valueConfiguration = () => {
        if (title.toLocaleLowerCase() === 'quantity') {
            return value;
        }
        if (title.toLocaleLowerCase() === 'unit price') {
            return currencyConverter(value, '');
        }
        if (value && title.toLocaleLowerCase() === 'recommended price') {
            return `${value} ${getCurrencyByCountryCode(country as string)}`;
        }
        return 'Not provided';
    };
    return (
        <>
            <div className="w-full h-32 group flex flex-col py-3 gap-1 items-center justify-center bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ">
                <div>{icon}</div>

                <p className=" font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                    {title}
                </p>
                <p className="text-base text-gray-500">
                    {/* {title.toLocaleLowerCase() === 'quantity'
                        ? `${value}`
                        : title.toLocaleLowerCase() === 'recommended price'
                          ? `${value} ${getCurrencyByCountryCode(country as string)}`
                          : currencyConverter(value, '')} */}
                    {`${valueConfiguration()}`}
                </p>
            </div>
        </>
    );
};

export default ProductDetailsCard;
