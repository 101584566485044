import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IPaginationData } from 'utils/Interfaces/types';
import { IUser } from 'utils/Interfaces/user.interface';
import { IOrder } from './order';

export interface IChat {
    _id: string;
    type: 1 | 2 | 3;
    customer: ICustomer | string;
    user: IUser | string;
    product?: IProduct | string;
    order?: IOrder | string;
    lastMessageTs: string;
    haveUnseenForCustomer: boolean;
    haveUnseenForUser: boolean;
}

type TPaginatedConversations = IPaginationData<IChat>;

export const getConversations = async (data: { type: IChat['type']; page?: number; limit?: number }) => {
    const config = await getBearerToken();

    const response = await axios.post<TPaginatedConversations>(`/chat/filter`, data, config);

    return response.data as TPaginatedConversations;
};

export const getConversationById = async (chatId: string) => {
    const config = await getBearerToken();
    const response = await axios.get<IChat>(`/chat/${chatId}`, config);
    return response.data;
};

export const getUnseenChatsCount = async (chatType: IChat['type']) => {
    const config = await getBearerToken();
    const response = await axios.get<number>(`/chat/unseenCount/${chatType}`, config);
    return response;
};
