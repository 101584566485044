import React from 'react';
import { ProductImageProps } from './ProductImage.types';
import { CustomImage } from './ProductImage.styles';
import Package from '../../assets/package.png';

const ProductImage: React.FC<ProductImageProps> = ({ src, alt }) => {
    return <CustomImage src={src || Package} alt={alt} />;
};

export default ProductImage;
