import React from 'react';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';
import { CustomCardProps } from './CustomCards.types';
import { StyledCard } from './CustomCard.style';

const CustomCard: React.FC<CustomCardProps> = ({ body, extraStyle }) => {
    return (
        <Box>
            <CardContent sx={{ ...StyledCard, ...extraStyle }}>{body}</CardContent>
        </Box>
    );
};

export default CustomCard;
