export const buttonStyle = {
    minWidth: '25px',
    maxHeight: '25px',
    backgroundColor: 'primary.main',
    color: 'white',
    '&:hover': {
        backgroundColor: 'primary.main',
        color: 'white',
    },
};
