import { TableColDef } from 'components/tables/tables.types';
import moment from 'moment';
import { Badge, Button } from 'flowbite-react';
import React, { ReactElement } from 'react';
import { IOrder } from 'services/order';
import { FaRegCopy } from 'react-icons/fa6';
import { copyToClickboard } from 'utils/copyToClickBoard';
import { orderStatusMatching } from './Orders.styles';
import CallCenterDetails from './orderDataDetails/callCenterDetails';
import ClientInfoCell from './orderDataDetails/clientInfoCell';
import ProductDetailst from './orderDataDetails/productDetailst';

export const getCODOrderColumns = async (isWithCC?: boolean): Promise<TableColDef<IOrder>[]> => {
    const codOrderColumns: TableColDef<IOrder>[] = [
        {
            field: 'orderRef',
            headerName: 'Order Ref',
            fieldType: 'render',
            renderCell: (params) => <p>{params.orderRef ? params.orderRef : '-'}</p>,
        },

        {
            field: 'ClientInfo',
            headerName: 'Client Info',
            fieldType: 'render',
            renderCell: (params) => (
                <>
                    <ClientInfoCell data={params} />
                </>
            ),
        },

        {
            field: 'ProductInfo',
            headerName: 'Product Info',
            fieldType: 'render',
            renderCell: (row) => (
                <>
                    <ProductDetailst data={row} />
                </>
            ),
        },
        {
            field: 'country',
            headerName: 'Country',
        },

        {
            field: 'totalPrice',
            headerName: 'Total Price',
            fieldType: 'render',
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    {params.currency ? params.currency : ''} {params.totalPrice}
                </div>
            ),
        },
    ];

    if (isWithCC) {
        codOrderColumns.push({
            field: 'callCenterAttempt',
            headerName: 'Call Center',
            fieldType: 'render',
            renderCell: (params) => (
                <>
                    {params.withCC !== false ? (
                        <CallCenterDetails data={params} />
                    ) : (
                        <div style={{ width: 'fit-content' }}>
                            <Badge color="gray" title="call center is disabled for this store">
                                disabled
                            </Badge>
                        </div>
                    )}
                </>
            ),
        });
    }

    codOrderColumns.push({
        field: 'lgstTrackingCode',
        headerName: 'Tracking Code',
        fieldType: 'render',
        renderCell: (params: IOrder) =>
            params?.lgstTrackingCode ? (
                <div className="flex items-center gap-2">
                    <p className="font-bold">{params?.lgstTrackingCode}</p>
                    <Button
                        size="l"
                        color="white"
                        title="Copy Tracking code to clipboard"
                        onClick={() => {
                            copyToClickboard(params?.lgstTrackingCode || '', 'Tracking code');
                        }}
                    >
                        <FaRegCopy className="w-4 h-4" />
                    </Button>
                </div>
            ) : (
                <p> -</p>
            ),
    });
    codOrderColumns.push({
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ width: 'fit-content' }}>
                    <Badge color={orderStatusMatching(params.status).color}>
                        {orderStatusMatching(params.status).title}
                    </Badge>
                </div>
                {params.statusDescription && (
                    <p>
                        {params.statusDescription.substring(0, 20) +
                            (params.statusDescription.substring(20).length > 0 ? '...' : '')}
                    </p>
                )}
            </div>
        ),
    });
    codOrderColumns.push({
        field: 'date',
        headerName: 'DATE',
        // fieldType: 'date',
        fieldType: 'render',
        renderCell: (params) => {
            return (
                <div className="flex-col gap-1 text-xs w-max">
                    <p>
                        <span className="opacity-70">Created at: </span>
                        {moment(params.createdAt).format('MMMM Do YYYY, HH:mm')}
                    </p>
                    <p>
                        <span className="opacity-70">Updated at: </span>
                        {moment(params.updatedAt).format('MMMM Do YYYY, HH:mm')}
                    </p>
                </div>
            );
        },
    });
    codOrderColumns.push({
        field: 'Action',
        headerName: 'Actions',
        actionType: 'EditOrder',
        // renderCell: (params) => <OrdersTableActions orderId={params.id} data={params} />,
    });
    return codOrderColumns;
};

export const prePaidOrderColumns: TableColDef<IOrder>[] = [
    {
        field: 'orderRef',
        headerName: 'Order Ref',
        fieldType: 'render',
        renderCell: (params) => <p>{params.orderRef ? params.orderRef : '-'}</p>,
    },
    {
        field: 'ClientInfo',
        headerName: 'Client Info',
        fieldType: 'render',
        renderCell: (params) => (
            <>
                <ClientInfoCell data={params} />
            </>
        ),
    },
    {
        field: 'ProductInfo',
        headerName: 'Product Info',
        fieldType: 'render',
        renderCell: (row) => (
            <>
                <ProductDetailst data={row} />
            </>
        ),
    },
    {
        field: 'country',
        headerName: 'Country',
    },
    {
        field: 'lgstTrackingCode',
        headerName: 'Tracking Code',
        fieldType: 'render',
        renderCell: (params: IOrder) =>
            params?.lgstTrackingCode ? (
                <div className="flex items-center gap-2">
                    <p className="font-bold">{params?.lgstTrackingCode}</p>
                    <Button
                        size="l"
                        color="white"
                        title="Copy Tracking code to clipboard"
                        onClick={() => {
                            copyToClickboard(params?.lgstTrackingCode || '', 'Tracking code');
                        }}
                    >
                        <FaRegCopy className="w-4 h-4" />
                    </Button>
                </div>
            ) : (
                <p> -</p>
            ),
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ width: 'fit-content' }}>
                    <Badge color={orderStatusMatching(params.status).color}>
                        {orderStatusMatching(params.status).title}
                    </Badge>
                </div>
                {params.statusDescription && (
                    <p>
                        {params.statusDescription.substring(0, 20) +
                            (params.statusDescription.substring(20).length > 0 ? '...' : '')}
                    </p>
                )}
            </div>
        ),
    },
    {
        field: 'date',
        headerName: 'DATE',
        // fieldType: 'date',
        fieldType: 'render',
        renderCell: (params) => {
            return (
                <div className="flex-col gap-1 text-xs w-max">
                    <p>
                        <span className="opacity-70">Created at: </span>
                        {moment(params.createdAt).format('MMMM Do YYYY, HH:mm')}
                    </p>
                    <p>
                        <span className="opacity-70">Updated at: </span>
                        {moment(params.updatedAt).format('MMMM Do YYYY, HH:mm')}
                    </p>
                </div>
            );
        },
    },
    {
        field: 'Action',
        headerName: 'Actions',
        actionType: 'EditOrder',
        // renderCell: (params) => <OrdersTableActions orderId={params.id} data={params} />,
    },
];
export interface IStausInterface {
    statusIcon: ReactElement;
    statusLabel: string;
}
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="info" size="sm">
                New
            </Badge>
        ),
        statusLabel: 'New',
    },
    2: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Canceled
            </Badge>
        ),
        statusLabel: 'Canceled',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Unreachable
            </Badge>
        ),
        statusLabel: 'Unreachable',
    },
    4: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    41: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending answer
            </Badge>
        ),
        statusLabel: 'Pending answer',
    },
    42: {
        statusIcon: (
            <Badge color="success" size="sm">
                Question answered
            </Badge>
        ),
        statusLabel: 'Question answered',
    },
    43: {
        statusIcon: (
            <Badge color="success" size="sm">
                Scheduled call
            </Badge>
        ),
        statusLabel: 'Question answered',
    },
    5: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Waiting for shipment
            </Badge>
        ),
        statusLabel: 'Waiting for shipment',
    },
    6: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for shipment
            </Badge>
        ),
        statusLabel: 'Out for shipment',
    },
    7: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Shipped
            </Badge>
        ),
        statusLabel: 'Shipped',
    },
    8: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pick and pack
            </Badge>
        ),
        statusLabel: 'Pick and pack',
    },
    81: {
        statusLabel: 'Order received by carrier',
        statusIcon: (
            <Badge color="warning" size="sm">
                Order received by carrier
            </Badge>
        ),
    },
    82: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrive to headquarters warehouse
            </Badge>
        ),
        statusLabel: 'Arrive to headquarters warehouse',
    },
    83: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order transferred
            </Badge>
        ),
        statusLabel: 'Order transferred',
    },
    84: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrival at Hub
            </Badge>
        ),
        statusLabel: 'Arrival at Hub',
    },
    9: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    10: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery attempted
            </Badge>
        ),
        statusLabel: 'Delivery attempted',
    },
    101: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Hub
            </Badge>
        ),
        statusLabel: 'Order returned to Hub',
    },
    102: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Warehouse
            </Badge>
        ),
        statusLabel: 'Order returned to Warehouse',
    },
    103: {
        statusIcon: (
            <Badge color="warning" size="sm">
                New delivery request
            </Badge>
        ),
        statusLabel: 'New delivery request',
    },
    104: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Wait to WMS
            </Badge>
        ),
        statusLabel: 'Wait to WMS',
    },
    105: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery scheduled
            </Badge>
        ),
        statusLabel: 'Delivery scheduled',
    },
    11: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    111: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - Out
            </Badge>
        ),
        statusLabel: 'Return to origin - Out',
    },
    112: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - In
            </Badge>
        ),
        statusLabel: 'Return to origin - In',
    },
    113: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Transport management system to Warehouse
            </Badge>
        ),
        statusLabel: 'Transport management system to Warehouse',
    },
    114: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Back to stock
            </Badge>
        ),
        statusLabel: 'Back to stock',
    },
    12: {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    13: {
        statusIcon: (
            <Badge color="pink" size="sm">
                Expired
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    14: {
        statusIcon: (
            <Badge color="red" size="sm">
                Invalid
            </Badge>
        ),
        statusLabel: 'Invalid',
    },
    55: {
        statusIcon: (
            <Badge color="dark" size="sm">
                Unsupported
            </Badge>
        ),
        statusLabel: 'Unsupported status',
    },
    99: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Check required
            </Badge>
        ),
        statusLabel: 'Check required',
    },
    100: {
        statusIcon: (
            <Badge color="red" size="sm">
                Hold - No stock available
            </Badge>
        ),
        statusLabel: 'Hold - No stock available',
    },
    21: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Wrong number
            </Badge>
        ),
        statusLabel: 'Wrong number',
    },
};
