import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';
import { AvatarProps } from './UserAvatar.types';
import {
    AvatarContainer,
    identityStyle,
    labelStyle,
    AvatarStyle,
    LargeAvatarStyle,
    mediumAvatarStyle,
} from './UserAvatar.styles';

const UserAvatar: React.FC<AvatarProps> = ({ label, isCollapsed, isLarge, isMedium, image, identity }) => {
    const avatarStyle = (name: any, imge: any) => {
        if (isLarge) return LargeAvatarStyle(name, imge);
        if (isMedium) return mediumAvatarStyle(name, imge);
        return AvatarStyle(name, imge);
    };

    return (
        <Box sx={AvatarContainer}>
            <Avatar sx={identity ? identityStyle : avatarStyle(label, image)} src={image || ''}>
                {label?.charAt(0)}
                {label?.indexOf(' ') !== -1 && label?.substring(label.indexOf(' ') + 1, label.length).charAt(0)}
            </Avatar>
            {!isCollapsed && !isLarge && <Box sx={labelStyle}> {label} </Box>}
        </Box>
    );
};

export default UserAvatar;
