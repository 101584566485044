/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React from 'react';
import { FaBoxOpen } from 'react-icons/fa';

interface traceProps {
    time: string;
    status: string;
    description?: string;
}
interface TimeLineProps {
    timelines: traceProps[];
}
export const Timeline = ({ timelines }: TimeLineProps) => {
    return (
        <div className="sm:p-4 w-full">
            {timelines.map((trace, index) => (
                <div key={index} className="flex mb-8">
                    <div className="w-48 text-left">
                        <>
                            <p className="text-[#007FFF] text-xs font-work-sans">{moment(trace.time).format('dddd')}</p>
                            <p className="text-[#007FFF] text-sm font-bold font-work-sans">
                                {moment(trace.time).format('MMMM D, YYYY')}
                            </p>
                        </>
                        <p className="text-[#76849D] font-xs font-work-sans">
                            {moment(trace.time).format('HH:mm:ss')} Local time
                        </p>
                    </div>

                    <div className="w-14 relative flex justify-center items-start">
                        {index !== timelines.length - 1 && <div className="absolute w-px h-full bg-[#46D5B3] top-8" />}

                        <div className="rounded-full p-2  w-8 h-8 bg-[#46D5B3] flex justify-center items-center">
                            <FaBoxOpen className="text-white w-5 h-5" />
                        </div>
                    </div>
                    <div className="w-3/4 pt-1 pl-2">
                        <p className="font-normal text-[#000000] dark:text-[#FFFFFF] font-work-sans font-sm">
                            {trace.status}
                        </p>
                        <p className="text-[#76849D] font-xs font-work-sans">
                            {trace.description && trace.description}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};
