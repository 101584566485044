/* eslint-disable no-unused-vars */
import { Grid, Typography } from '@mui/material';
import { Badge, Card } from 'flowbite-react';
import moment from 'moment';
import React from 'react';
import { IOrder } from 'services/order';
import { imageDisplay } from 'utils/helpers/imageFinder';
import { v4 as uuidv4 } from 'uuid';
import { IProduct } from 'utils/Interfaces/products.interface';
import { actionReason, orderStatusMatching, validActionStatus } from '../Orders.styles';

const OrderDataDetails: React.FC<{ data: IOrder }> = ({ data }) => {
    const centerAgentFeedback = () => {
        if (data.callCenterFeedback && data.callCenterFeedback.length > 0) {
            return data.callCenterFeedback.map((feed, index) => (
                <div key={feed}>
                    {index + 1}: {feed}
                </div>
            ));
        }
        return <div>No feedback from call center agent...</div>;
    };

    const sx = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return (
        <div className="overflow-x-auto overflow-y-auto min-w-[800px]">
            <div className=" grid grid-cols-1 gap-2 md:grid-cols-2 mb-2 ">
                <div>
                    <div className=" border  dark:border-gray-500 border-gray-200 rounded-lg overflow-hidden">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  ">
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Name:
                                    </th>
                                    <td className="px-6 py-4">
                                        {data.firstName} {data.lastName}
                                    </td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Phone
                                    </th>
                                    <td className="px-6 py-4">{data.phone}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Adresss 1
                                    </th>
                                    <td className="px-6 py-4">{data.address1}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Adresss 2
                                    </th>
                                    <td className="px-6 py-4">{data.address2}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        City
                                    </th>
                                    <td className="px-6 py-4">{data.city}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Province
                                    </th>
                                    <td className="px-6 py-4">{data.province}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Zip Code
                                    </th>
                                    <td className="px-6 py-4">{data.zip}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Country
                                    </th>
                                    <td className="px-6 py-4">{data.country}</td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Country code
                                    </th>
                                    <td className="px-6 py-4">{data.countryCode}</td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Company
                                    </th>
                                    <td className="px-6 py-4">{data.company}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <div className="border  dark:border-gray-500 border-gray-200 col-span-12 rounded-lg overflow-hidden">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Order status
                                    </th>
                                    <td className="px-6 py-4">
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                            <div style={{ width: 'fit-content' }}>
                                                <Badge color={orderStatusMatching(data.status as any).color}>
                                                    {orderStatusMatching(data.status as any).title}
                                                </Badge>
                                            </div>
                                            {data.statusDescription && <p>{data.statusDescription}</p>}
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Date
                                    </th>
                                    <td className="px-6 py-4">{moment(data.createdAt).format('lll')}</td>
                                </tr>
                                {/* <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Tracking Number
                                    </th>
                                    <td className="px-6 py-4">{data.trackingCode ? data.trackingCode : '- -'}</td>
                                </tr> */}
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Total order Price
                                    </th>
                                    <td className="px-6 py-4">
                                        {data.currency ? data.currency : ''} {data.totalPrice}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {data.withCC !== false && (
                        <div className="border  dark:border-gray-500 border-gray-200 col-span-12 rounded-lg overflow-hidden">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            Calls Attempts
                                        </th>
                                        <td className="px-6 py-4">
                                            <Badge>{data?.callCenterAttempt ?? '0'}</Badge>
                                        </td>
                                    </tr>
                                    <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            Order status action reason
                                        </th>
                                        <td className="px-6 py-4">
                                            {data?.actionReason ? (
                                                <Badge
                                                    color={
                                                        validActionStatus.includes(data?.actionReason)
                                                            ? 'failure'
                                                            : 'info'
                                                    }
                                                >
                                                    {actionReason(data?.actionReason)}
                                                </Badge>
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            Center Agent feedback
                                        </th>
                                        <td className="px-6 py-4">{centerAgentFeedback()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <div className="" key="productDetailsHolder">
                <div>
                    <Card
                        theme={{
                            root: {
                                children: 'p-2',
                            },
                        }}
                        className="mb-1"
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="sr-only">Image</div>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>Product Name</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>Product Spec</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>Sku</Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>Quantity</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                    {data?.items &&
                        data.items.length > 0 &&
                        data.items.map((item: any) => {
                            const product = item?.product as IProduct;
                            let productName = '';
                            let variant = '';
                            let productDesign;
                            if (product.itemKind === 'variant') {
                                productName = product?.parentProduct?.name;
                                variant = product?.name;
                                productDesign = product?.parentProduct?.design;
                            } else {
                                productName = product?.name;
                                variant = '-';
                                productDesign = product.design;
                            }
                            return (
                                <>
                                    {item.quantity > 0 && (
                                        <Card
                                            key={`${uuidv4()}-productInfo`}
                                            theme={{
                                                root: {
                                                    children: 'p-2',
                                                },
                                            }}
                                            className="mb-1 relative"
                                        >
                                            {item.product?.isReseller && (
                                                <Badge
                                                    color="secondary"
                                                    className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                    style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                >
                                                    RESELLER
                                                </Badge>
                                            )}
                                            <Grid container spacing={0}>
                                                <Grid item xs={1} sx={sx}>
                                                    {imageDisplay(productDesign, true, 50, 50, undefined)}
                                                </Grid>
                                                <Grid item xs={4} sx={sx}>
                                                    <Typography>{productName || '--'}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{variant}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{product?.globalSKU}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sx={sx}>
                                                    <Typography>{item.quantity}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )}
                                    {item.upsellQuantity && (
                                        <Card
                                            key={`${uuidv4()}-upsell`}
                                            theme={{
                                                root: {
                                                    children: 'p-2',
                                                },
                                            }}
                                            className="mb-1 bg-gray-100 relative"
                                        >
                                            {item.product?.isReseller && (
                                                <Badge
                                                    color="secondary"
                                                    className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                    style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                >
                                                    RESELLER
                                                </Badge>
                                            )}
                                            <Badge
                                                color="success"
                                                className="inline-block absolute top-1 right-0 transform rotate-12"
                                            >
                                                UPSELL
                                            </Badge>

                                            <Grid container spacing={0}>
                                                <Grid item xs={1} sx={sx}>
                                                    {imageDisplay(productDesign, true, 50, 50, undefined)}
                                                </Grid>
                                                <Grid item xs={4} sx={sx}>
                                                    <Typography>{productName || '--'}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{variant}</Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={sx}>
                                                    <Typography>{product.globalSKU}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sx={sx}>
                                                    <Typography>{item.upsellQuantity}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )}
                                </>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default OrderDataDetails;
