import { Badge } from 'flowbite-react';
import React from 'react';
import { ColorCustomerAccountStatus, getStatus } from './Status.styles';
import { IPaymentsStatusProps } from './Status.types';

const PaymentsStatus: React.FC<IPaymentsStatusProps> = ({ status }) => {
    return (
        <>
            <Badge color={ColorCustomerAccountStatus(status).color}>{getStatus(status)}</Badge>
        </>
    );
};

export default PaymentsStatus;
