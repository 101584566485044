/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import { Avatar, Checkbox } from 'flowbite-react';
import moment from 'moment';
import PaymentActions from 'pages/payments/paymentsTableActions/paymentActions';
import React from 'react';
import { HiMinus } from 'react-icons/hi';
import Package from 'assets/no-image.png';
import PrepaidInvoiceActions from 'pages/invoice/prepaidInvoice/invoiceActions';
import OrdersTableActions from 'pages/orders/ordersTableActions';
import CodInvoiceActions from 'pages/invoice/codWithCCInvoice/invoiceActions';
import { IOrder } from 'services/order';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { tableRowProps } from '../tables.types';

const TableRow: React.FC<tableRowProps> = ({
    el,
    index,
    selectable,
    setSelectedRows,
    columns,
    icons,
    labels,
    setRows,
    currentPage,
    currentFilter,
    itemsSelected,
    store,
    setActiveTab,
}) => {
    return (
        <tr
            key={el._id}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
            {selectable && (
                // eslint-disable-next-line react/no-array-index-key
                <th scope="col" className="p-4" key={`selectable${el._id}${index}`}>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            value={el._id}
                            checked={itemsSelected[el._id] !== undefined || false}
                            onChange={(e) => {
                                setSelectedRows(el, e.target?.checked, index);
                            }}
                        />
                    </div>
                </th>
            )}
            {columns?.map((header) => {
                if (header.field === 'Action') {
                    switch (header.actionType) {
                        case 'Invoice':
                            return (
                                <td className="px-6 py-4" key={`${el._id} ${header.field}`}>
                                    <CodInvoiceActions invoice={el} setRows={setRows} />
                                </td>
                            );
                        case 'PrepaidInvoice':
                            return (
                                <td className="px-6 py-4" key={`${el._id} ${header.field}`}>
                                    <PrepaidInvoiceActions invoice={el} setRows={setRows} />
                                </td>
                            );
                        case 'EditOrder':
                            return (
                                <td className="px-6 py-4" key={`${el._id} ${header.field}`}>
                                    <OrdersTableActions
                                        orderId={el.id}
                                        data={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<IOrder[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        // case 'viewInvoice':
                        //     return (
                        //         <td className="px-6 py-4" key={`${el._id} ${header.field}`}>
                        //             <InvoiceActions invoice={el} setRows={setRows} />
                        //         </td>
                        //     );
                        case 'render':
                            return header.renderCell ? (
                                <td className="px-[9px] py-4 max-w-[200px]" key={`${el._id} ${header.field}`}>
                                    {header.renderCell({
                                        ...el,
                                        setRows,
                                        store,
                                        setActiveTab,
                                    })}
                                </td>
                            ) : null;
                        case 'Payment':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <PaymentActions payment={el} setRows={setRows} currentPage={currentPage} />
                                </td>
                            );
                        default: {
                            return (
                                <td className="px-6 py-4 max-w-[200px]" key={`${el._id} ${header.field}`}>
                                    <HiMinus />
                                </td>
                            );
                        }
                    }
                }
                if (header?.fieldType === 'status') {
                    return (
                        <td className="px-6 py-4 max-w-[200px]" key={`${el._id} ${header.field}`}>
                            <div className="flex items-center">
                                {icons && icons[el[String(header?.field)]]
                                    ? icons[el[String(header?.field)]].statusIcon
                                    : '--'}
                            </div>
                        </td>
                    );
                }
                /* when you need to replace a field from data of text or a number with a text
                                            for example you have a status ot a type as a number and you will display 
                                            the label correspondant for this field   */
                if (header?.fieldType === 'indentifier') {
                    return (
                        <td className="px-6 py-4 max-w-[200px]" key={`${el._id} ${header.field}`}>
                            <div className="flex items-center">{labels && labels[el[String(header?.field)]]}</div>
                        </td>
                    );
                }
                if (header.fieldType === 'link') {
                    return (
                        <td className="px-4 py-4 truncate max-w-[200px] hover" key={`${el._id} ${header.field}`}>
                            {header.icon ? (
                                <>
                                    <a
                                        href={
                                            el[header?.field].startsWith('http:') ||
                                            el[header?.field].startsWith('https:')
                                                ? el[header?.field]
                                                : `//${el[header?.field]}`
                                        }
                                        // href={el[header?.field]}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {header.icon}
                                    </a>
                                </>
                            ) : (
                                <a
                                    href={
                                        el[header?.field].startsWith('http:') || el[header?.field].startsWith('https:')
                                            ? el[header?.field]
                                            : `https://${el[header?.field]}`
                                    }
                                    // href={el[header?.field]}
                                    className="font-medium max-w-[200px] text-blue-600 dark:text-blue-500 hover:underline"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="inline-block overflow-hidden overflow-ellipsis  whitespace-nowrap w-80">
                                        {el[header?.field]}
                                    </span>
                                </a>
                            )}
                        </td>
                    );
                }
                if (header.fieldType === 'image') {
                    const isThere = el[header?.field].filter(
                        (prodImg: any) =>
                            prodImg.endsWith('.jpg') ||
                            prodImg.endsWith('.webp') ||
                            prodImg.endsWith('.png') ||
                            prodImg.endsWith('.jpeg'),
                    );
                    return (
                        <td className="px-4 py-4 flex gap-3" key={`${el._id} ${header.field}`}>
                            {isThere && isThere.length > 0 ? (
                                <Avatar
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    img={(props) => (
                                        <img alt="img" referrerPolicy="no-referrer" src={isThere[0]} {...props} />
                                    )}
                                />
                            ) : (
                                <Avatar
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    img={(props) => (
                                        <img alt="img" referrerPolicy="no-referrer" src={Package} {...props} />
                                    )}
                                />
                            )}
                            {header.widthName && <span className="capitalize my-auto">{el.name}</span>}
                        </td>
                    );
                }
                if (header.fieldType === 'imageArray') {
                    return (
                        <td className="px-4 py-4">
                            {el[header?.field] && el[header?.field].length > 0 ? (
                                <Avatar
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    img={(pr) => (
                                        <img
                                            alt="img"
                                            referrerPolicy="no-referrer"
                                            src={el[header?.field][0]}
                                            {...pr}
                                        />
                                    )}
                                />
                            ) : (
                                <Avatar
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    img={(pr) => <img alt="img" referrerPolicy="no-referrer" src={Package} {...pr} />}
                                />
                            )}
                        </td>
                    );
                }
                if (header.fieldType === 'profileImage') {
                    return (
                        <td className="px-4 py-4">
                            <Avatar
                                // eslint-disable-next-line react/no-unstable-nested-components
                                img={() => (
                                    // <img
                                    //     alt="img"
                                    //     referrerPolicy="no-referrer"
                                    //     src={el[header?.field]}
                                    //     {...props}
                                    // />
                                    <Avatar img={el[header?.field]} />
                                )}
                            />
                        </td>
                    );
                }
                /* when you need to combine between two fields to display in specific sell
                                              Example: you need to display the fullName of a customer while,
                                               you have as an input two fiel firstName and lastName  */
                if (header.fieldType === 'combo') {
                    return (
                        <td
                            className={`px-6 py-4 max-w-[200px] ${header?.classField}`}
                            key={`${el._id} ${header.field}`}
                        >
                            {header?.child &&
                                header?.child.map((ch: string) => {
                                    return el && el[header?.field] && el[header?.field][ch]
                                        ? ` ${el[header?.field][ch]}`
                                        : '-';
                                })}
                        </td>
                    );
                }
                if (header.fieldType === 'comboFields') {
                    return (
                        <td
                            className={`px-[9px] py-4 max-w-[200px] ${header?.classField}`}
                            key={`${el._id} ${header?.field}`}
                        >
                            {header?.child &&
                                header?.child.map((ch: string) => {
                                    return el && el[ch] ? (
                                        <>
                                            <>{el[ch]}</>
                                            <br />
                                        </>
                                    ) : (
                                        <>
                                            <>-</>
                                            <br />
                                        </>
                                    );
                                })}
                        </td>
                    );
                }
                if (header.fieldType === 'date') {
                    return (
                        <td
                            className={`px-4 truncate min-w-${header.width || '80'} hover ${header?.classField}`}
                            key={`${el._id} ${header.field}`}
                        >
                            {moment(el[header?.field]).format(header.dateFormat || 'MMMM Do YYYY, HH:mm')}
                        </td>
                    );
                }
                if (header.fieldType === 'render' && header.renderCell) {
                    return (
                        <td
                            className={`px-[9px] py-4 max-w-[200px] ${header?.classField}`}
                            key={`${el._id} ${header?.field}`}
                        >
                            {header.renderCell(el)}
                        </td>
                    );
                }
                if (header.fieldType === 'currency') {
                    return (
                        <td
                            className={`px-4 py-4 truncate ... max-w-[200px] hover ${header?.classField}`}
                            key={`${el._id} ${header.field}`}
                        >
                            {header?.child &&
                                header?.child.map(() => {
                                    return currencyConverter(
                                        el[header?.field],
                                        el.operationCurrency ? el.operationCurrency : 'USD',
                                    );
                                })}
                        </td>
                    );
                }
                return (
                    <td
                        className={`px-6 py-4 truncate ... max-w-[200px] hover ${header?.classField}`}
                        key={`${el._id} ${header.field}`}
                    >
                        {el[header?.field]}
                    </td>
                );
            })}
        </tr>
    );
};
export default TableRow;
