import { IPaginationData } from 'utils/Interfaces/types';
import { getBearerToken } from 'utils/helpers/auth';
import axios from 'utils/axios/axios';
import { IOrder } from './order';

export interface IFilterTraces {
    dateRange?: {
        startDate?: number;
        endDate?: number;
    };
    logistioStatus?: number;
    traceId?: string;
    order?: string;
    limit?: number;
    page?: number;
}

export interface IOrderTrace {
    _id: string;
    order: string | IOrder;
    traceId: number;
    status: string;
    logistioStatus: number;
    time: string;
    location: string;
    description: string;
}
interface IPaginatedOrders extends IPaginationData<IOrderTrace> {}
export const getOrderTraces = async (options: IFilterTraces) => {
    const config = await getBearerToken();
    const response = await axios.post<IPaginatedOrders>(`/orderTraces/filter`, options, config);
    return response;
};
