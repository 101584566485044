/* eslint-disable no-underscore-dangle */
import { IxlsxOrder } from 'services/order';
import { logistioCountries } from 'utils/countryList';
import vnlinCities from 'utils/helpers/vnlin/vnlinCities.json';
import { InputOrder } from '../uploadPrepaidOrderFile';
import { memoize } from './memoize';
import { stringSimilarity } from './stringSimilarity';

const orderKeys = [
    'firstName',
    'lastName',
    'address1',
    'address2',
    'country',
    'countryCode',
    'province',
    'provinceCode',
    'city',
    'zip',
    'phone',
    'sku',
    'quantity',
    'orderNumber',
];

const countriesList: { [key: string]: { [k in 'code' | 'label' | 'phone' | 'currency' | 'arabicLabel']: string } } = {};
logistioCountries.forEach((country) => {
    countriesList[country.label] = {
        code: country.code,
        label: country.label,
        phone: country.phone,
        currency: country.currency,
        arabicLabel: country.arabicLabel,
    };
    countriesList[country.arabicLabel] = {
        code: country.code,
        label: country.label,
        phone: country.phone,
        currency: country.currency,
        arabicLabel: country.arabicLabel,
    };
});

const memoizedStringSimilarity = memoize(stringSimilarity);
function pickHighestandLowestCityMatch({
    cityLabel,
    vnlinLabel,
    orderCity,
    matchedCity,
    lowMatchScore,
}: {
    cityLabel: string;
    vnlinLabel: string;
    orderCity: string;
    matchedCity: { label: string | null; score: number };
    lowMatchScore: { label: string | null; score: number };
}) {
    if (cityLabel.includes('/')) {
        // city have an arabic label and an english label
        const [cityLangOne, cityLangTwo] = cityLabel.split('/');
        const scoreLangOne = memoizedStringSimilarity(
            orderCity.toLowerCase().replaceAll(' ', ''),
            cityLangOne.toLowerCase().replaceAll(' ', ''),
        );
        const scoreLangTwo = memoizedStringSimilarity(
            orderCity.toLowerCase().replaceAll(' ', ''),
            cityLangTwo.toLowerCase().replaceAll(' ', ''),
        );
        if (scoreLangOne >= 0.5 || scoreLangTwo >= 0.5) {
            // pick the highest score between the current refCity and the existing matchedCity if available or take the current refCity
            if (matchedCity.score < Math.max(scoreLangOne, scoreLangTwo)) {
                Object.assign(matchedCity, { label: vnlinLabel, score: Math.max(scoreLangOne, scoreLangTwo) });
            }
        } else if ((scoreLangOne > 0 && scoreLangOne < 0.5) || (scoreLangTwo > 0 && scoreLangTwo < 0.5)) {
            if (lowMatchScore.score < Math.max(scoreLangOne, scoreLangOne)) {
                Object.assign(lowMatchScore, {
                    label: vnlinLabel,
                    score: Math.max(scoreLangOne, scoreLangTwo),
                });
            }
        }
    } else {
        // city only have an english label
        const score = memoizedStringSimilarity(
            orderCity.toLowerCase().replaceAll(' ', ''),
            cityLabel.toLowerCase().replaceAll(' ', ''),
        );
        if (score >= 0.5) {
            if (matchedCity.score < score) {
                Object.assign(matchedCity, { label: vnlinLabel, score });
            }
        } else if (score > 0 && score < 0.5) {
            if (lowMatchScore.score < score) {
                Object.assign(lowMatchScore, {
                    label: vnlinLabel,
                    score,
                });
            }
        }
    }
}
/**
 * Produce order object with the proper keys from an input order with non restricted orderKeys
 * (Minimum similarity score to consider a key as a valid match is 0.6 inc)
 * @example
 * input: {'first name (required asdfasdf)': 'John', 'last': 'doe', ...}
 * output: {firstName: 'John', lastName: 'doe', ...}
 */
export function orderFactory(order: InputOrder) {
    const inputKeys = Object.keys(order) as unknown as (keyof InputOrder)[];
    // @ts-ignore: we just initializing the data here ... it will be filled don't worry :)
    const orderData: Omit<IxlsxOrder & { invalidCity?: boolean; closestCityMatch?: string }, 'orderRef'> & {
        __rowNum__?: number;
        orderNumber?: string | undefined;
    } = {};

    const scoredKeys = new Map<string, { inputKey: (typeof inputKeys)[number]; score: number }>();
    // compute string similarity scores and pick the closest match
    orderKeys.forEach((orderKey) => {
        inputKeys.forEach((parsedInputKey) => {
            const similarityScore = memoizedStringSimilarity(
                parsedInputKey.toLowerCase().replaceAll(' ', '').replaceAll('(required)', ''),
                orderKey.toLowerCase().replaceAll(' ', '').replaceAll('(required)', ''),
            );
            if (similarityScore >= 0.5) {
                if (scoredKeys.has(orderKey)) {
                    const matchedKey = scoredKeys.get(orderKey);
                    if (matchedKey && matchedKey.score < similarityScore) {
                        scoredKeys.set(orderKey, { inputKey: parsedInputKey, score: similarityScore });
                    }
                } else {
                    scoredKeys.set(orderKey, { inputKey: parsedInputKey, score: similarityScore });
                }
            }
        });
    });
    Array.from(scoredKeys).forEach(([orderKey, matchedKey]) => {
        Object.assign(orderData, { [orderKey]: order[matchedKey.inputKey] });
    });

    // make sure that we get the expected value for the country so we can guess the correct value of the country label (example: {input: 'qatar', output: 'Qatar'})
    // in case of arabic values we score against the arabic label and we take the english label if min score is reached
    let scoredCountry: {
        country: string;
        score: number;
    } | null = null;
    Object.values(countriesList)
        .map((countryData) => [countryData.label, countryData.arabicLabel])
        .forEach(([englishLabel, arabicLabel]) => {
            if (orderData.country) {
                const similarityScoreEnglish = memoizedStringSimilarity(
                    orderData.country.toLowerCase().replaceAll(' ', ''),
                    englishLabel.toLowerCase().replaceAll(' ', ''),
                );
                const similarityScoreArabic = memoizedStringSimilarity(
                    orderData.country.toLowerCase().replaceAll(' ', ''),
                    arabicLabel.toLowerCase().replaceAll(' ', ''),
                );
                if (similarityScoreEnglish >= 0.5 || similarityScoreArabic >= 0.5) {
                    if (!scoredCountry) {
                        scoredCountry = {
                            country: englishLabel,
                            score: Math.max(similarityScoreArabic, similarityScoreEnglish),
                        };
                    } else if (scoredCountry.score < Math.max(similarityScoreArabic, similarityScoreEnglish)) {
                        scoredCountry.country = englishLabel;
                        scoredCountry.score = Math.max(similarityScoreArabic, similarityScoreEnglish);
                    }
                }
            }
        });

    orderData.__rowNum__ = order.__rowNum__;
    if (scoredCountry && (scoredCountry as { country: string; score: number }).country) {
        orderData.countryCode = countriesList[(scoredCountry as { country: string; score: number }).country].code;
        orderData.country = (scoredCountry as { country: string; score: number }).country;

        // if we have a valid country, we can guess the appropriate city
        // if no city scored more than 0.5, we remove the city field from the order to make the validation fail later
        const currentCountryCities = vnlinCities[orderData.country as keyof typeof vnlinCities] || [];
        if (currentCountryCities.length === 0) {
            // we remove the city field if no cities are available and we return early
            orderData.city = '';
            return orderData;
        }
        // compute the city similarity scores and pick the closest match
        const matchedCity: { label: string | null; score: number } = { label: null, score: 0 };
        const lowMatchScore: { label: string | null; score: number } = { label: null, score: 0 };
        if (orderData.city && orderData.city.length > 0) {
            currentCountryCities.forEach(({ vnlinLabel, label1, label2 }) => {
                pickHighestandLowestCityMatch({
                    cityLabel: vnlinLabel,
                    vnlinLabel,
                    orderCity: orderData.city,
                    matchedCity,
                    lowMatchScore,
                });
                pickHighestandLowestCityMatch({
                    cityLabel: label1,
                    vnlinLabel,
                    orderCity: orderData.city,
                    matchedCity,
                    lowMatchScore,
                });
                if (label2) {
                    pickHighestandLowestCityMatch({
                        cityLabel: label1,
                        vnlinLabel,
                        orderCity: orderData.city,
                        matchedCity,
                        lowMatchScore,
                    });
                }
            });
        }
        if (matchedCity.label && matchedCity.score > 0) {
            if (orderData.city.toLowerCase() !== matchedCity.label.toLowerCase()) {
                orderData.invalidCity = true;
                orderData.closestCityMatch = matchedCity.label;
            } else {
                // make sure we take the same expected value (the value is case sensitive)
                orderData.city = matchedCity.label;
            }
        } else {
            orderData.invalidCity = true;
            if (lowMatchScore.label && lowMatchScore.score > 0) {
                orderData.closestCityMatch = lowMatchScore.label;
            }
        }
    }
    return orderData;
}
