/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getWallet } from 'services/wallet';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { walletStyle, amountStyle } from './wallet.styles';

const Wallet: React.FC<{ currency: string }> = ({ currency }) => {
    const [walletAmount, setWalletAmount] = useState<any>(0);

    useEffect(() => {
        getWallet().then((res) => {
            setWalletAmount(res.data.wallet.amount);
        });
    }, []);
    // const currecySign = getCurrentUser().currency;
    const navigate = useNavigate();

    return (
        <>
            <Box sx={walletStyle} onClick={() => navigate('/settings/wallet', { state: { currentTab: 1 } })}>
                <AccountBalanceWalletOutlinedIcon sx={{ color: 'navBarIconColor' }} />
                <Typography variant="subtitle2" fontSize={17} sx={amountStyle}>
                    {walletAmount !== undefined && currency && currencyConverter(walletAmount, currency)}
                    {/* {walletAmount ? currencytest(walletAmount) : 0} */}
                </Typography>
            </Box>
        </>
    );
};

export default Wallet;
