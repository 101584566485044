import React from 'react';
import { Modal, ModalProps } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import GeneralButton from 'components/customButton/generalButton';
import { ResellerProductPricingHistory } from 'services/reseller/resellerProducts';
import { ResellerProduct } from 'pages/sellers/reseller.types';
import { toast } from 'react-hot-toast';
import { BsCopy } from 'react-icons/bs';

function UnseenPricingUpdatesModal({
    show,
    onClose,
    onProceed,
    onAbort,
    updatedPricing,
}: {
    show: boolean;
    onClose: ModalProps['onClose'];
    onProceed: () => void;
    onAbort: () => void;
    updatedPricing: ResellerProductPricingHistory[];
}) {
    return (
        <Modal show={show} size="3xl" onClose={onClose} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <div className="flex items-center justify-center gap-1 mb-4 ">
                        <HiOutlineExclamationCircle className="mb-1 h-6 w-6" />
                        <h3 className="text-lg font-normal">Reseller products pricing updates</h3>
                    </div>
                    <p className="mb-5 text-gray-500 dark:text-gray-400">
                        Some of your orders have products with an updated pricing.
                    </p>
                    <div className="mb-6">
                        <div className="py-3 text-base font-semibold grid grid-cols-4 w-full">
                            <p className="p-1 text-center">sku</p>
                            <p className="p-1 text-center">name</p>
                            <p className="p-1 text-center">old price</p>
                            <p className="p-1 text-center">new price</p>
                        </div>
                        {updatedPricing.map((update) => (
                            <div
                                key={JSON.stringify(update)}
                                className="grid grid-cols-4 w-full border-b border-gray-200"
                            >
                                <p className="p-1 text-center font-bold flex items-center gap-1 justify-center">
                                    <button
                                        type="button"
                                        title="copy sku to your clipboard"
                                        onClick={() => {
                                            const { sku } = update.resellerProduct as ResellerProduct;
                                            navigator.clipboard.writeText(sku);
                                            toast.success(`SKU: ${sku} copied to clipboard`);
                                        }}
                                    >
                                        <BsCopy />
                                    </button>
                                    {(update.resellerProduct as ResellerProduct).sku}
                                </p>
                                <p className="p-1 text-center" title={(update.resellerProduct as ResellerProduct).name}>
                                    {(update.resellerProduct as ResellerProduct).name.length > 20
                                        ? `${(update.resellerProduct as ResellerProduct).name.substring(0, 20)}...`
                                        : (update.resellerProduct as ResellerProduct).name}
                                </p>
                                <p className="p-1 text-center">{update.oldPrice} USD</p>
                                <p className="p-1 text-center font-bold">{update.newPrice} USD</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center gap-4">
                        <GeneralButton
                            color="success"
                            onClick={() => {
                                onProceed();
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            continue
                        </GeneralButton>
                        <GeneralButton
                            color="gray"
                            onClick={() => {
                                onAbort();
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            cancel
                        </GeneralButton>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UnseenPricingUpdatesModal;
