/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line no-unused-expressions */
import React from 'react';
import { Chip, Box, TextField } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import InputField from 'components/inputField/InputField';
import CustomError from 'components/customError/CustomError';
import Autocomplete from '@mui/material/Autocomplete';
import { logistioCountries } from 'utils/countryList';
import { basicInfoWrapper, sourcingProcessSubtitles } from '../Sourcing.styles';
import 'react-medium-image-zoom/dist/styles.css';

const StepZero = (props: any) => {
    const { formik, isRestock } = props;

    return (
        <CustomCard
            body={
                <Box sx={basicInfoWrapper}>
                    <Box sx={sourcingProcessSubtitles}>Basic Information</Box>
                    <Box>
                        <InputField
                            label="Product Name"
                            placeholder="Product Name"
                            {...formik.getFieldProps('productName')}
                            name="productName"
                            disabled={isRestock}
                        />

                        <CustomError name="productName" component="Box" />
                    </Box>

                    <Box>
                        <InputField
                            label="Product Link"
                            placeholder="Product Link"
                            {...formik.getFieldProps('productLink')}
                            name="productLink"
                            disabled={isRestock}
                        />
                        <CustomError name="productLink" component="Box" />
                    </Box>
                    <Box>
                        <Autocomplete
                            multiple
                            id="fixed-tags-demo"
                            {...formik.getFieldProps('shippingTo')}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('shippingTo', newValue);
                            }}
                            options={logistioCountries}
                            getOptionLabel={(option: any) => option.label}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option: any, index) => (
                                    <Chip label={option.label} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Shipping to" placeholder="Shipping to" />
                            )}
                        />
                        <Box>
                            <CustomError name="shippingTo" component="Box" />
                        </Box>
                    </Box>

                    <Box>
                        <InputField
                            label="Description"
                            placeholder="Description"
                            multiline
                            {...formik.getFieldProps('description')}
                            name="description"
                        />
                        <CustomError name="description" component="Box" />
                    </Box>
                </Box>
            }
        />
    );
};

export default StepZero;
