/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { getInvoiceList } from 'services/invoice';
import { getCurrentStore } from 'services/stores';
import { IInvoice } from 'utils/Interfaces/invoice.interface';
import { IStore } from 'utils/Interfaces/stores.interface';
import { IPaginationData } from 'utils/Interfaces/types';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import DataTable from '../../../components/tables/tables';
import { IdateRange, Ifilter, Ipagination } from '../invoice.types';
import { columns, statusDisplay } from './invoiceListElements';

type Imeta = Exclude<IPaginationData<null>, { docs: null }>;

const invoiceList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState<Array<IInvoice>>([]);
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 10,
    });
    const [Rangevalue, setRangeValue] = useState<IdateRange>({
        startDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2).toDateString(),
        endDate: new Date().toDateString(),
    });
    const navigate = useNavigate();
    const [filter, setFilter] = useState<Ifilter>({ invoiceType: 'PrepaidNoCC' });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const handlePageChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_storeType, setStoreType] = useState<IStore['storeType'] | null>(null);
    const handleRangeFilterChange = (newValue: IdateRange | null) => {
        if (newValue) {
            const startD = moment(newValue.startDate);
            const endD = moment(newValue.endDate);
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            setRangeValue({
                startDate: startD.toDate(),
                endDate: endD.toDate(),
            });
            setFilter({
                ...filter,
                dateRange: {
                    startDate: startD.toDate(),
                    endDate: endD.toDate(),
                },
            });
            setPagination({ ...pagination, page: 1 });
        }
    };
    useEffect(() => {
        getCurrentStore().then((res) => {
            if (res) {
                setStoreType(res.storeType);
            }
            // this page is unaccessible when call center is active in the store
            if (res?.withCC !== false) {
                navigate('/');
            }
        });
    }, []);
    useEffect(() => {
        const queryOptions = { ...pagination, ...filter };
        getInvoiceList(queryOptions).then((result: any) => {
            setInvoices(result.data.docs);
            setIsLoading(false);
            setMeta({
                hasNextPage: result.data.hasNextPage,
                hasPrevPage: result.data.hasPrevPage,
                limit: result.data.limit,
                nextPage: result.data.nextPage,
                page: result.data.page,
                pagingCounter: result.data.pagingCounter,
                prevPage: result.data.prevPage,
                totalDocs: result.data.totalDocs,
                totalPages: result.data.totalPages,
            });
        });
    }, [filter, pagination]);
    DynamicTitle('Invoice');
    return (
        <>
            <div className="flex items-center mb-2 flex-wrap gap-1 ">
                <div>
                    <Datepicker
                        inputClassName="w-full border-gray-300 py-2 pl-4 pr-10 font-light text-sm rounded-md focus:ring-0 font-normal bg-custom-background dark:border-slate-600 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20"
                        inputId="deliveryDate"
                        value={Rangevalue}
                        containerClassName="relative z-20"
                        useRange
                        onChange={handleRangeFilterChange}
                    />
                </div>
            </div>
            <DataTable
                rows={invoices}
                columns={columns}
                icons={statusDisplay}
                setRows={setInvoices}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePageChange}
            />
        </>
    );
};

export default invoiceList;
