/* eslint-disable no-underscore-dangle */
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import { getInvoicePdf } from 'services/invoice';
import { IInvoice } from 'utils/Interfaces/invoice.interface';

export const downloadInvoice = async (invoice: IInvoice) => {
    const downloadToast = toast.loading('Loading invoice...');
    try {
        const response = await getInvoicePdf({
            invoiceId: invoice._id,
            timezone: moment.tz.guess(),
            invoiceType: invoice.invoiceType,
        });
        toast.loading('Generating Invoice...', { id: downloadToast });

        const blob = new Blob([response.data]);
        const reader = new FileReader();
        reader.onloadend = () => {
            const downloadLink = document.createElement('a');
            downloadLink.href = reader.result as string;
            downloadLink.download = response.headers['Content-Disposition'] || `${invoice.reference}.pdf`;
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            toast.success('Invoice loaded successfully', { id: downloadToast });
        };
        reader.readAsDataURL(blob);
    } catch (e) {
        toast.error((e as any)?.response?.data?.errors?.message || 'Unable to load invoice', {
            id: downloadToast,
        });
    }
};
