/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { getCurrentUser } from 'utils/helpers/auth';
import { StockInfo } from './StockInfo';

const StockInformation = () => {
    const [customer, setCustomer] = useState<ICustomer>();
    const customerId = getCurrentUser()._id;
    const [customerLoader, setCustomerLoader] = useState(false);

    // this is a test
    useEffect(() => {
        setCustomerLoader(true);
        getCustomer(customerId)
            .then((res) => {
                setCustomer(res.data);
                setCustomerLoader(false);
            })
            .catch(() => {
                toast.error('Something went wrong, please try again !');
                setCustomerLoader(false);
            });
    }, []);
    return (
        <>
            {!customerLoader && customer ? (
                <StockInfo stocksThreshold={customer?.stocksThreshold} customerId={customer?._id} />
            ) : (
                <div className="animate-pulse">
                    <div className="dark:bg-gray-800 bg-gray-200 border-l-gray-400 border-l-2 p-2">
                        <div
                            role="status"
                            className="w-full mb-2 p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded-md  dark:divide-gray-700  dark:border-gray-700"
                        >
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-10 " />
                        </div>
                        <div className="flex justify-between items-center">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-1/3" />
                            <div
                                role="status"
                                className="w-28 p-4 space-y-4 border border-gray-200 divide-y  divide-gray-200 rounded  dark:divide-gray-700  dark:border-gray-700"
                            >
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full " />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default StockInformation;
