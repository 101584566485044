/**
 * Calculates the similarity between two strings based on the number of common
 * substrings.
 *
 * @param input The first string for comparison.
 * @param refString The second string for comparison.
 * @param substringLength The length of substrings to be considered (default: 2).
 * @returns A numerical value between 0 and 1 representing the similarity between
 * the strings 1 being total match and 0 being no match
 *
 * Note: The order of 'input' and 'refString' does not affect the result.
 * Swapping 'input' and 'refString' will yield the same similarity result.
 */
export function stringSimilarity(input: string, refString: string, substringLength: number = 2): number {
    if (input.length < substringLength || refString.length < substringLength) return 0;

    const map = new Map();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < input.length - (substringLength - 1); i++) {
        const substr1 = input.substring(i, substringLength + i);
        map.set(substr1, map.has(substr1) ? map.get(substr1) + 1 : 1);
    }

    let match = 0;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < refString.length - (substringLength - 1); j++) {
        const substr2 = refString.substring(j, substringLength + j);
        const count = map.has(substr2) ? map.get(substr2) : 0;
        if (count > 0) {
            map.set(substr2, count - 1);
            // eslint-disable-next-line no-plusplus
            match++;
        }
    }

    return (match * 2) / (input.length + refString.length - (substringLength - 1) * 2);
}
