/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { IaddOrder, IxlsxOrder } from 'services/order';
import {
    getUnseenResellerProductPricingUpdates,
    ResellerProductPricingHistory,
} from 'services/reseller/resellerProducts';

import { WorkSheet, read, utils } from 'xlsx';
import { sendUploadOrdersRequest } from './sendUploadReq';
import { memoize } from './utils/memoize';
import { orderFactory } from './utils/orderFactory';

export type InputOrder = Record<keyof typeof inputOrderHeaderMatch, any> & {
    __rowNum__?: number;
    orderNumber?: string;
};

export const inputOrderHeaderMatch = {
    store: 'store',
    'first name (required)': 'firstName',
    'last name (required)': 'lastName',
    'address 1': 'address1',
    'address 2': 'address2',
    'country (required)': 'country',
    'country code': 'countryCode',
    province: 'province',
    'province code': 'provinceCode',
    city: 'city',
    zip: 'zip',
    'phone (required)': 'phone',
    'sku (required)': 'sku',
    'quantity (required)': 'quantity',
    'order number (required)': 'orderNumber',
};

const memoizedOrderFactory = memoize(orderFactory);

/**
 * Takes an xlsx file
 * parses the xlsx file and gets its json representation
 * calls sendUploadReq with the json data
 */
export async function uploadPrePaidOrderFile(
    files: FileList | null,
    setOpenModal: any,
    setCounter: any,
    setFailedOrders: React.Dispatch<React.SetStateAction<WorkSheet>>,
    setErrors: React.Dispatch<
        React.SetStateAction<
            Map<
                string,
                {
                    orderCustomerFullName: string;
                    errors: string[];
                }
            >
        >
    >,
    setFileName: any,
    setShowConfirmationUi: React.Dispatch<React.SetStateAction<boolean>>,
    setParsedOrders: React.Dispatch<
        React.SetStateAction<{
            withInvalidCities: any[];
            withFailedValidation: any[];
            valid: any[];
        } | null>
    >,
    setUpdatedPricing: React.Dispatch<React.SetStateAction<ResellerProductPricingHistory[]>>,
    onUploadEnd?: (...args: any[]) => any | void,
) {
    setCounter({ errors: 0, success: 0, total: 0 });
    setErrors(new Map());
    const store = localStorage.getItem('storeId') as string;
    const ordersFile = files instanceof FileList && files[0];

    // 🏷️ parse file and upload the resulting object
    if (ordersFile) {
        setOpenModal(true);
        setFileName(ordersFile.name);
        const reader = new FileReader();
        reader.readAsArrayBuffer(ordersFile);
        reader.onloadend = async (content) => {
            /*
             * 1. Parse the input file
             * 2. Validate the orders objects (one by one)
             * 3. Send the orders to the server (one by one)
             */

            // 1. Parse the xlsx/csv file (get data as json)
            const workbook = read(content.target?.result);
            // this include all sheet rows (empty rows also are included)
            const fileDataAsJsonWithReadableHeader = utils.sheet_to_json<
                Record<keyof typeof inputOrderHeaderMatch, any> & { __rowNum__?: number; orderNumber?: string } // Omit<IxlsxOrder, 'orderRef'>
            >(workbook.Sheets[workbook.SheetNames[0]], { defval: '', blankrows: true });

            // match columns headers including `(required)` with headers format that the validation expects as input (no `(required)`)
            // and collect the parsed data (columns/rows as json)
            const fileDataAsJson: (Omit<IxlsxOrder, 'orderRef'> & {
                __rowNum__?: number;
                orderNumber?: string | undefined;
            })[] = [];
            fileDataAsJsonWithReadableHeader.forEach((row) => {
                // only compute non empty rows
                if (Object.values(row).some((value) => !!value)) {
                    const data = memoizedOrderFactory({ ...row, __rowNum__: row.__rowNum__ });
                    fileDataAsJson.push(data);
                }
            });

            // unmerge cells if found
            const merges = workbook.Sheets[workbook.SheetNames[0]]['!merges'];
            const orders: (IaddOrder & { invalidCity?: boolean; closestCityMatch?: string })[] = [];
            if (merges) {
                // 1. Handle the merged cells
                // Given the merge for all cols is the same we only take the first column (A)
                const firstCol = merges.filter((merge) => merge.s.c === 0);
                // From the column data, we take the rows merge ranges and we sort them asc
                const sortedRowsRanges = firstCol
                    .map((rowRange) => ({ s: rowRange.s.r, e: rowRange.e.r }))
                    .sort((rowA, rowB) => rowA.s - rowB.s);

                sortedRowsRanges.forEach((mergedRow) => {
                    // ranges are inclusive that's why we iterate `e` times
                    for (let i = mergedRow.s; i < mergedRow.e + 1; i++) {
                        // `fileDataAsJson` holds the rows by the order they were from the file,
                        // so fileDataAsJson[0] is the first row (the row number 0 in the file is the header
                        // and it's not included in fileDataAsJson array)
                        const row = { ...fileDataAsJson[i - 1], orderRef: fileDataAsJson[i - 1].orderNumber || '' };
                        delete row.orderNumber;
                        // in case we on the 1st row of the merge then all fields are available in the row object
                        if (i === mergedRow.s) {
                            const skus: IaddOrder['skus'] = [
                                { sku: row.sku!, quantity: row.quantity ? row.quantity : 0 },
                            ];
                            delete row.sku;
                            delete row.quantity;
                            delete row.__rowNum__;
                            orders.push({
                                ...row,
                                skus,
                                fullName: `${row.firstName} ${row.lastName}`,
                                store,
                            });
                        } else {
                            // we just select the sku and the quantity and add them to the last found parent row (merged cells are not available in the row object)
                            orders.at(-1)?.skus.push({ sku: row.sku!, quantity: row.quantity ? row.quantity : 0 });
                        }
                    }
                });

                // 2. Handle unmerged cells
                fileDataAsJson.forEach((el) => {
                    if (Object.values(el).some((cellValue) => !!cellValue)) {
                        const row = el;
                        const rowIsOutOfMergeRange = sortedRowsRanges
                            .map((range) => (row.__rowNum__ || 0) > range.s - 1 && (row.__rowNum__ || 0) < range.e + 1)
                            .every((isInRange) => !isInRange);
                        if (rowIsOutOfMergeRange) {
                            // if we are out of merge ranges there is no empty rows are ignored (cells holding the number 0 are considered empty)
                            const skus: IaddOrder['skus'] = [
                                { sku: row.sku!, quantity: row.quantity ? row.quantity : 0 },
                            ];
                            delete row.sku;
                            delete row.quantity;
                            delete row.__rowNum__;
                            orders.push({
                                ...row,
                                skus,
                                fullName: `${row.firstName} ${row.lastName}`,
                                store,
                            });
                        }
                    }
                });
            } else {
                // in case there is no merges we iterate the whole sheet rows in case there is empty rows in between orders
                fileDataAsJson.forEach((el) => {
                    if (Object.values(el).some((cellValue) => !!cellValue)) {
                        const row = { ...el, orderRef: el.orderNumber || '' };
                        delete row.orderNumber;
                        // if we dont have merges then empty rows are ignored (cells holding the number 0 are considered empty)
                        const skus: IaddOrder['skus'] = [{ sku: row.sku!, quantity: row.quantity ? row.quantity : 0 }];
                        delete row.sku;
                        delete row.quantity;
                        delete row.__rowNum__;
                        orders.push({
                            ...row,
                            skus,
                            fullName: `${row.firstName} ${row.lastName}`,
                            store,
                        });
                    }
                });
            }
            setCounter((counter: any) => ({ ...counter, total: orders.length }));

            const skus = new Set<string>();
            orders.forEach((order) => {
                order.skus.forEach((item) => {
                    skus.add(item.sku);
                });
            });
            const unseenPricingUpdatesResponse = await getUnseenResellerProductPricingUpdates(Array.from(skus));
            if (unseenPricingUpdatesResponse.data.length > 0) {
                setUpdatedPricing(unseenPricingUpdatesResponse.data);
                setOpenModal(false);
            } else {
                setOpenModal(true);
                await sendUploadOrdersRequest({
                    onUploadEnd,
                    setFailedOrders,
                    setCounter,
                    setErrors,
                    store,
                    orders,
                    setShowConfirmationUi,
                    setParsedOrders,
                });
            }
        };
    }
}
