/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import GeneralDrawer from 'components/generalDrawler/generalDrawler';
import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { trackOrders } from 'services/publicServices/track';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { IoCloseOutline } from 'react-icons/io5';

interface TrackingNumbersProps {
    openDetails: boolean;
    setOpenDetails: any;
    trackingCodes: string[];
    setTrackingCodes: any;
    setTrackingData: any;
}
const TrackingNumbersMobile = ({
    openDetails,
    setOpenDetails,
    setTrackingCodes,
    trackingCodes,
    setTrackingData,
}: TrackingNumbersProps) => {
    const [trackingNumbers, setTrackingNumbers] = useState<string[]>(trackingCodes);

    useEffect(() => {
        if (trackingCodes) setTrackingNumbers(trackingCodes);
    }, [trackingCodes]);

    const handleKeyDown = (e: any) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            setTrackingNumbers([...trackingNumbers, '']);
        }
    };

    const handleAddTrackingNumber = async () => {
        const regex = /^(LGST|LGSTS)\d+$/;
        const validTrackingCodes = trackingNumbers.filter((elt: string) => {
            if (!regex.test(elt) && elt) {
                toast.error(`${elt} doesn't match the required Logistio format`);
                return false;
            }
            if (elt) {
                return true;
            }
            return false;
        });

        const toastId = toast.loading('Getting your tracking data...');
        if (validTrackingCodes?.length) {
            await trackOrders(validTrackingCodes)
                .then((res) => {
                    toast.dismiss(toastId);
                    setTrackingData(res.data);
                    setTrackingCodes(Object.keys(res.data));
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message || 'Something went wrong!', { id: toastId });
                });
        }
    };

    const handleChange = (e: any, index: number) => {
        const { value } = e.target;
        const newTrackingNumbers = [...trackingNumbers];
        newTrackingNumbers[index] = value;
        setTrackingNumbers(newTrackingNumbers);
    };

    const handleRemove = (index: any) => {
        const newTrackingNumbers = trackingNumbers.filter((_, i) => i !== index);
        setTrackingNumbers(newTrackingNumbers);
    };

    return (
        <>
            <GeneralDrawer
                anchor="bottom"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { height: `calc(100% - 56px)` },
                }}
            >
                <div className="py-2 px-5 overflow-y-hidden h-full  bg-white dark:bg-[#000246]">
                    <div className="flex items-center justify-between w-full mb-4 h-12">
                        <div>
                            <button
                                className="flex items-center justify-center  font-bold text-xs text-[#007FFF] "
                                onClick={() => setOpenDetails(false)}
                            >
                                <MdOutlineKeyboardArrowLeft className="text-[#007FFF] w-5 h-5" />
                                Back
                            </button>
                        </div>
                        <div>
                            <p className="text-[#000246] dark:text-white text-sm font-bold">Tracking numbers</p>
                        </div>

                        <div>
                            <button
                                className="flex items-center justify-center font-bold text-xs text-[#007FFF] "
                                onClick={handleAddTrackingNumber}
                            >
                                Track
                            </button>
                        </div>
                    </div>
                    <div
                        className="border border-[#E8EBEF] dark:border dark:border-white rounded p-2 h-5/6 overflow-y-auto bg-dark-900"
                        onKeyDown={handleKeyDown}
                    >
                        {trackingNumbers?.map((number, index) => (
                            <div key={index} className="flex items-center justify-between mb-2">
                                <div className="mr-2">
                                    <span className="text-xs text-[#C1C8D2] dark:text-[#C1C8D2] font-work-sans ">
                                        {index + 1}.
                                    </span>
                                </div>
                                <input
                                    autoFocus
                                    className="dark:bg-transparent text-[#42516B] dark:text-white  border-none  leading-3 outline-none w-full"
                                    value={number}
                                    onChange={(e) => handleChange(e, index)}
                                />
                                {trackingNumbers?.length > 1 && (
                                    <button
                                        className="w-4 h-4  rounded-full flex items-center justify-center bg-[#C6CCD7] dark:bg-[#C6CCD7] font-bold text-s text-gray-400 hover:text-red-500"
                                        onClick={() => handleRemove(index)}
                                    >
                                        <IoCloseOutline className="text-white dark:text-[#667085] font-bold text-xs w-13 h-13" />
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </GeneralDrawer>
        </>
    );
};
export default TrackingNumbersMobile;
