/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Tooltip, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import CustomButton from 'components/customButton/CustomButton';
import CustomBtn from 'components/customButton/Custombtn';
import CustomBtnIcon from 'components/customButton/customBtnIcon';
import QuotationLoadingBlock from 'components/loadingBlocks/quotationLoadingBlock';
import Modal from 'components/modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { getCustomer } from 'services/customer';
import { getProduct, updateProduct } from 'services/product';
import { IProduct, IQuotation } from 'utils/Interfaces/products.interface';
import { getCurrentUser } from 'utils/helpers/auth';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { countFileTypes, filesDisplay } from 'utils/helpers/imageFinder';
import { downloadFilesAsBase64 } from 'utils/helpers/zipDownloader';
import { getExpiredDate } from 'utils/helpers/expiredDate';
import { quotationVariantsContainer } from './Quotation.styles';
import PaymentModal from './paymentModal';

interface GroupedQuotation {
    firstNonHiddenIdx: number | null;
    country: string;
    options: IProduct['quotation'][number]['options'];
    variants: IProduct['quotation'][number]['variants'];
}

const groupQuotationByCountries = (quotationsData: IProduct['quotation']) => {
    // 1. get available coutries list
    const availableCountries = new Set<string>();
    quotationsData.forEach((quotation) => {
        quotation.options.forEach((opt) => {
            availableCountries.add(opt.country);
        });
    });
    // 2. iterate the quotations data and group it by country (key: country)
    // 2.1 init group
    const groupedQuotationsByCountry = new Map<string, GroupedQuotation>();
    availableCountries.forEach((country) => {
        groupedQuotationsByCountry.set(country, { country, options: [], variants: [], firstNonHiddenIdx: null });
    });

    // 2.2 group quotations by country
    quotationsData.forEach((quotation) => {
        quotation.options.forEach((opt) => {
            const draft = groupedQuotationsByCountry.get(opt.country)!;
            draft.options.push(opt);
            if (quotation.variants.length > 0) {
                draft.variants.push(quotation.variants[0]);
            }
            groupedQuotationsByCountry.set(opt.country, draft);
        });
    });
    // 3. get the first non hidden option for each quotation (used to ui only)
    availableCountries.forEach((country) => {
        const quotation = groupedQuotationsByCountry.get(country);
        if (quotation) {
            quotation?.options.forEach((option, idx) => {
                if (!option.isHidden && quotation.firstNonHiddenIdx === null) {
                    quotation.firstNonHiddenIdx = idx;
                }
            });
            groupedQuotationsByCountry.set(country, quotation);
        }
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resultArr = [...groupedQuotationsByCountry].map(([_key, value]) => value);
    return resultArr;
};
const quotationDataFactory = (groupedQuotations: GroupedQuotation[]): IProduct['quotation'] => {
    const quotationsData = new Map<string, IProduct['quotation'][number]>();

    groupedQuotations.forEach((grpQuot) => {
        // case we have variants
        if (grpQuot.variants.length > 0) {
            grpQuot.variants.forEach((variant, variantIdx) => {
                const currentVariantQuotation = quotationsData.get(variant._id);
                if (currentVariantQuotation?.variants) {
                    quotationsData.set(variant._id, {
                        variants: currentVariantQuotation?.variants,
                        options: [...currentVariantQuotation.options, grpQuot.options[variantIdx]],
                    });
                } else {
                    quotationsData.set(variant._id, {
                        variants: [variant],
                        options: [grpQuot.options[variantIdx]],
                    });
                }
            });
        } else {
            // case where there is no variants
            grpQuot.options.forEach((option, optionIdx) => {
                const currentCountryQuotation = quotationsData.get(option.country);
                if (currentCountryQuotation?.options) {
                    quotationsData.set(option.country, {
                        variants: [],
                        options: [...currentCountryQuotation.options, grpQuot.options[optionIdx]],
                    });
                } else {
                    quotationsData.set(option.country, {
                        variants: [],
                        options: [grpQuot.options[optionIdx]],
                    });
                }
            });
        }
    });

    const quotationsByVariantsArr = [...quotationsData].map((quotationMapItem) => quotationMapItem[1]);
    return quotationsByVariantsArr;
};

const getOptionsTotalQuantity = (options: GroupedQuotation['options']) => {
    let total = 0;
    options.forEach((option) => {
        if (option.accepted !== false) {
            total += Number(option.quantity);
        }
    });
    return total;
};
const Quotation = () => {
    const location: any = useLocation();
    const [open, setOpen] = useState(false);
    const [acceptedQuotations, setAcceptedQuotations] = useState<GroupedQuotation[]>([]);
    const [bulkQuantity, setBulkQuantity] = useState<any>(0);
    const [customerVariants, setCustomerVariants] = useState<any>({});
    const [productStatus, setProductStatus] = useState<number>();
    const [currentProduct, setCurrentProduct] = useState<IProduct>();
    const [totalPrice, setTotalPrice] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProduct(location.state.productId)
            .then((res) => {
                setCurrentProduct(res.data.product);
                let groupedQuotations: GroupedQuotation[] = [];
                if (res.data.product.quotation && res.data.product.quotation.length > 0) {
                    groupedQuotations = groupQuotationByCountries(res.data.product.quotation);
                }
                setAcceptedQuotations(groupedQuotations);

                setCustomerVariants(res.data.product.variants);
                setProductStatus(res.data.product.status);
                if (res.data.product.quantity > 0) {
                    setBulkQuantity(res.data.product.quantity);
                }
                setLoading(false);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Loading product data failed: ', err);
                toast.error('Sorry, Unable to load product data. Please try again!');
            });
    }, []);

    useEffect(() => {
        let quotationTotalPrice = 0;
        if (acceptedQuotations.length > 0) {
            acceptedQuotations.forEach((quotation) => {
                quotation.options.forEach((option, idx) => {
                    if (option.accepted) {
                        quotationTotalPrice += Number(option.unitPrice) * Number(option.quantity);
                    }
                    if (idx === quotation.options.length - 1 && quotation.options.some((opt) => opt.accepted)) {
                        quotationTotalPrice += Number(option.totalShippingLineFee || option.shippingLineFee);
                    }
                });
            });
        }
        setTotalPrice(quotationTotalPrice);
    }, [acceptedQuotations]);

    const cancelQuotationDecision = (quotationIdx: number, optionIdx: number) => {
        const draftQuotations = [...acceptedQuotations];
        delete draftQuotations[quotationIdx].options[optionIdx].accepted;
        setAcceptedQuotations([...draftQuotations]);
    };

    const decideOnQuotation = (quotationIdx: number, optionIdx: number, accept: boolean) => {
        const draftQuotations = [...acceptedQuotations];
        draftQuotations[quotationIdx].options[optionIdx] = {
            ...draftQuotations[quotationIdx].options[optionIdx],
            accepted: accept,
        };
        setAcceptedQuotations([...draftQuotations]);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [display, _setDisplay] = useState<any>([]);
    const [customerCurrency, setCustomerCurrency] = useState('');
    useEffect(() => {
        getCustomer(getCurrentUser()._id).then((res) => {
            setCustomerCurrency(res?.data.currency);
        });
    }, []);

    const updateQuotationStatus = () => {
        const formData = new FormData();
        const toastLoader = toast.loading('Saving offers...');

        formData.append('quotation', JSON.stringify(quotationDataFactory(acceptedQuotations)));
        /**
         * 🙂 update product status when he accept or refuses a quotation *
         * if he accepts all => status 41 and the status will be in ERP 'customer reviewing quotation' in green 💚
         * if he refuses all => status 42  and the status will be in ERP 'customer reviewing quotation' in red  💗
         * if he refused at least one, the product status will be 69 and the status will be in ERP 'agent reviewing quotation' in red  💗
         * Please note that:
         * 1. the approve and proceed button will activate if he accepted all quotations
         * 2. the submit decision button will activate if he refused at least one quotations
         * => this is only for the account manager to track the customer what he is doing so that he negociate with him for better deals.
         */

        if (
            acceptedQuotations.every((quotation) =>
                quotation.options.every(
                    (option) => (option.accepted === false && option.isHidden === true) || option.accepted,
                ),
            )
        ) {
            // if all accepted => status accepted (41)
            formData.append('status', JSON.stringify(productStatus === 1 ? 8 : 5)); // quotation accepted
        } else if (
            acceptedQuotations.every((quotation) => quotation.options.every((option) => option.accepted === false))
        ) {
            // if all refused => status refused (42)
            formData.append('status', JSON.stringify(42)); // quotation refused
        } else if (
            acceptedQuotations.every((quotation) => quotation.options.every((option) => option.accepted !== undefined))
        ) {
            // if accepted & refused => status partially refused (69)
            formData.append('status', JSON.stringify(69)); // quotation partially refused
        }

        updateProduct({
            id: location.state.productId,
            updatedData: formData,
        }).then(() => {
            toast.success('decision submitted', { id: toastLoader });

            getProduct(location.state.productId)
                .then((res) => {
                    setProductStatus(res.data.product.status);
                    setAcceptedQuotations(groupQuotationByCountries(res.data.product.quotation));
                    if (res.data.product.quantity > 0) {
                        setBulkQuantity(res.data.product.quantity);
                    }
                    setLoading(false);
                    res.data.product.status === 5 && setOpen(true);
                })
                .catch(() => {
                    toast.error('Something went wrong, please try again');
                });
        });
    };
    const approveBtn = () => {
        if (
            acceptedQuotations &&
            acceptedQuotations.length > 0 &&
            productStatus &&
            ![2, 42, 69, 100].includes(productStatus)
        ) {
            const allOptionfilled = acceptedQuotations.some((quotation) =>
                quotation.options.some((option) => option.accepted === undefined),
            );
            if (productStatus === 5)
                return (
                    <CustomBtn
                        type="main"
                        onClick={() => {
                            setOpen(true);
                        }}
                        label="Pay Now"
                    />
                    // <CustomButton
                    //     key="activeReviewQuotation"
                    //     sizeType="large"
                    //     styleType="main"
                    //     label="Pay Now"
                    //     onClick={() => {
                    //         setOpen(true);
                    //     }}
                    // />
                );
            return (
                <CustomButton
                    key="activeReviewQuotation"
                    sizeType="large"
                    styleType={[5, 30, 99].includes(productStatus || 0) ? 'disabled' : 'success'}
                    label="Submit decision"
                    onClick={() => {
                        updateQuotationStatus();
                    }}
                    disabled={allOptionfilled || [5, 30, 99].includes(productStatus || 0)}
                    className="!bg-main !hover:bg-main-hover !focus:bg-main-focus"
                />
            );
            // }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_getVariantName, setVariantName] = useState(new Map());

    // const quotationVariantsOptions = (quotation: GroupedQuotation) => {
    //     const variantName = new Map();
    //     if (quotation && quotation.variants.length > 0) {
    //         quotation.variants.forEach((variantQuot, index) => {
    //             variantName.set(index, variantQuot.name);
    //         });
    //         setVariantName(variantName);
    //     }
    // };

    useEffect(() => {
        const quotationVariantsOptions = (quotation: GroupedQuotation) => {
            const variantName = new Map();
            if (quotation && quotation.variants.length > 0) {
                quotation.variants.forEach((variantQuot, index) => {
                    variantName.set(index, variantQuot.name);
                });
                setVariantName(variantName);
            }
        };
        if (acceptedQuotations && acceptedQuotations.length > 0) {
            acceptedQuotations
                .filter((quotation) => quotation.options.some((option) => option.accepted === true))
                .map((quotation) => {
                    quotationVariantsOptions(quotation);
                });
        }
    }, [acceptedQuotations]);

    // const countFileTypes = (files: string[]): Record<string, number> => {
    //     const fileTypeCounts: Record<string, number> = {};

    //     files.forEach((file) => {
    //         const fileExtension = file.split('.').pop()?.toLowerCase() || 'unknown';
    //         const groupedExtension =
    //             fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg'
    //                 ? 'Images'
    //                 : fileExtension === 'svg%2bxml' ||
    //                     fileExtension === 'SVG%2BXML' ||
    //                     fileExtension === 'SVG' ||
    //                     fileExtension === 'svg'
    //                   ? 'SVG'
    //                   : fileExtension;

    //         fileTypeCounts[groupedExtension] = (fileTypeCounts[groupedExtension] || 0) + 1;
    //     });

    //     return fileTypeCounts;
    // };

    const results = Object.keys(countFileTypes(currentProduct?.quotationImages || [])).map((key) => (
        <div key={key} className=" border-[0.3px] dark:border-gray-800 border-gray-300 flex gap-1 p-2 ">
            <p>{key.toLocaleUpperCase()}:</p>
            <p>{countFileTypes(currentProduct?.quotationImages as [])[key]}</p>
        </div>
    ));

    const getCountryTotalPrice = (quotation: IQuotation) => {
        let totalItemsPrice = 0;
        if (quotation.options.every((opt) => !opt.accepted)) {
            quotation.options.forEach((opt) => {
                totalItemsPrice += Number(opt.unitPrice) * Number(opt.quantity);
            });
        } else {
            quotation.options.forEach((opt) => {
                if (opt.accepted || (opt.isHidden === undefined && opt.accepted === undefined)) {
                    totalItemsPrice += Number(opt.unitPrice) * Number(opt.quantity);
                }
            });
        }
        return totalItemsPrice + Number(quotation.options[0]?.totalShippingLineFee || 0);
    };

    const expiredDate = useMemo(() => {
        if (!!currentProduct?.expirationDate && productStatus && ![3, 5].includes(productStatus)) return 0;

        const { expiredIn } = getExpiredDate(currentProduct?.expirationDate);
        return expiredIn;
    }, [currentProduct?.expirationDate, productStatus]);

    return (
        <div className=" ">
            {loading ? (
                <QuotationLoadingBlock />
            ) : (
                <>
                    <div className="overflow-x-auto ">
                        {[6, 43, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 99].includes(productStatus || 0) ? (
                            // when product status is `paid(6)` || `processingPayment(43)` or anything beyond processingPayment

                            <div className=" overflow-x-auto min-w-[800px] overflow-y-auto ">
                                {/* show payment actions if product status is `waiting for payment` or `payment declined` */}
                                {productStatus && productStatus === 99 ? (
                                    <p className="text-xs font-medium dark:bg-red-200 dark:text-red-600 text-red-600 bg-red-200 border-l-red-600 border-l-2 p-2">
                                        *Product not available.
                                    </p>
                                ) : productStatus && productStatus === 43 ? (
                                    <p className="text-xs font-medium dark:bg-blue-200 dark:text-blue-600 text-blue-600 bg-red-200 border-l-blue-600 border-l-2 p-2">
                                        *Product payment is under review.
                                    </p>
                                ) : (
                                    <p className="text-xs font-medium dark:bg-green-200 dark:text-green-600 text-green-600 bg-green-200 border-l-green-600 border-l-2 p-2">
                                        *Product is PAID.
                                    </p>
                                )}

                                {acceptedQuotations && acceptedQuotations.length > 0 && (
                                    <>
                                        <div className=" border-2 rounded-lg border-[#dedcdc] dark:border-[#30363d] p-2 bg-[#EAEDF7] dark:bg-[#191C24]">
                                            {bulkQuantity === 0 ? (
                                                <div className={` grid  grid-cols-10  text-center`}>
                                                    <div className="col-span-2 ">Variants</div>
                                                    {/* <div>Moq</div> */}
                                                    <div>Quantity</div>
                                                    <div>Unit price</div>
                                                    <div>Shipping line</div>
                                                    <div>Total shipping fee</div>
                                                    <div>Country</div>
                                                    <div className="col-span-1 ">Shipping duration estimate</div>
                                                    <div>Unit total price</div>
                                                    <div>Total price</div>
                                                </div>
                                            ) : (
                                                <div className={` grid  grid-cols-10 text-center`}>
                                                    {/* <div>Moq</div> */}
                                                    <div>Quantity</div>
                                                    <div>Unit price</div>
                                                    <div>Shipping line</div>
                                                    <div>Total shipping fee</div>
                                                    <div>Country</div>
                                                    <div className="col-span-1 ">Shipping duration estimate</div>
                                                    <div>Unit total price</div>
                                                    <div>Total price</div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                                <Box
                                    sx={{
                                        minHeight: '0vh',
                                        maxHeight: '64vh',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <Box>
                                        {acceptedQuotations &&
                                            acceptedQuotations.length > 0 &&
                                            acceptedQuotations.map((quotation, index: number) => (
                                                <>
                                                    {quotation.options.some((opt) => !opt.isHidden) && (
                                                        <div className=" border-2 rounded-lg border-[#dedcdc] dark:border-[#30363d] p-2">
                                                            {quotation.options
                                                                // .filter((opt) => !opt.isHidden)
                                                                .map((option, idx: number) =>
                                                                    option.isHidden ? null : (
                                                                        <div
                                                                            className={`grid grid-flow-row-dense grid-cols-10
                                                                            }  text-center p-1`}
                                                                        >
                                                                            {bulkQuantity === 0 && (
                                                                                <div className=" flex justify-center items-center col-span-2 ">
                                                                                    {quotation.variants[idx].name}
                                                                                </div>
                                                                            )}
                                                                            <div className="flex justify-center items-center">
                                                                                {option.quantity}
                                                                            </div>
                                                                            <div className="flex justify-center items-center">
                                                                                {option.unitPrice &&
                                                                                    customerCurrency &&
                                                                                    currencyConverter(
                                                                                        option.unitPrice,
                                                                                        customerCurrency,
                                                                                    )}
                                                                            </div>
                                                                            <div className="flex justify-center items-center">
                                                                                {(quotation.firstNonHiddenIdx !==
                                                                                    null &&
                                                                                    idx ===
                                                                                        quotation.firstNonHiddenIdx) ||
                                                                                (quotation.firstNonHiddenIdx === null &&
                                                                                    idx === 0)
                                                                                    ? option.shippingLine === 'Vnlin'
                                                                                        ? ' Logistio Line 1'
                                                                                        : 'Logistio Line'
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="flex justify-center items-center">
                                                                                {(quotation.firstNonHiddenIdx !==
                                                                                    null &&
                                                                                    idx ===
                                                                                        quotation.firstNonHiddenIdx) ||
                                                                                (quotation.firstNonHiddenIdx === null &&
                                                                                    idx === 0)
                                                                                    ? option.totalShippingLineFee &&
                                                                                      customerCurrency &&
                                                                                      currencyConverter(
                                                                                          option.totalShippingLineFee,
                                                                                          customerCurrency,
                                                                                      )
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="flex justify-center items-center">
                                                                                {(quotation.firstNonHiddenIdx !==
                                                                                    null &&
                                                                                    idx ===
                                                                                        quotation.firstNonHiddenIdx) ||
                                                                                (quotation.firstNonHiddenIdx === null &&
                                                                                    idx === 0)
                                                                                    ? option.country
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="flex justify-center items-center col-span-1  ">
                                                                                {(quotation.firstNonHiddenIdx !==
                                                                                    null &&
                                                                                    idx ===
                                                                                        quotation.firstNonHiddenIdx) ||
                                                                                (quotation.firstNonHiddenIdx === null &&
                                                                                    idx === 0)
                                                                                    ? option.shippingDuration
                                                                                        ? `${option.shippingDuration} days`
                                                                                        : '-'
                                                                                    : ''}
                                                                            </div>
                                                                            <div className=" flex justify-center items-center">
                                                                                {customerCurrency &&
                                                                                option.unitPrice &&
                                                                                option.totalShippingLineFee &&
                                                                                option.quantity &&
                                                                                !Number.isNaN(
                                                                                    Number(option.unitPrice) +
                                                                                        Number(
                                                                                            option.totalShippingLineFee,
                                                                                        ) /
                                                                                            getOptionsTotalQuantity(
                                                                                                quotation.options,
                                                                                            ),
                                                                                ) &&
                                                                                getOptionsTotalQuantity(
                                                                                    quotation.options,
                                                                                ) !== 0
                                                                                    ? currencyConverter(
                                                                                          Number(option.unitPrice) +
                                                                                              Number(
                                                                                                  option.totalShippingLineFee,
                                                                                              ) /
                                                                                                  getOptionsTotalQuantity(
                                                                                                      quotation.options,
                                                                                                  ),
                                                                                          customerCurrency,
                                                                                      )
                                                                                    : ' - '}
                                                                            </div>
                                                                            <div className=" flex justify-center items-center">
                                                                                {idx ===
                                                                                Math.round(
                                                                                    quotation.options.length / 2,
                                                                                ) -
                                                                                    1 ? (
                                                                                    <>
                                                                                        {!Number.isNaN(
                                                                                            getCountryTotalPrice(
                                                                                                quotation,
                                                                                            ),
                                                                                        ) && (
                                                                                            <Typography>
                                                                                                {getCountryTotalPrice(
                                                                                                    quotation,
                                                                                                ).toLocaleString(
                                                                                                    'en-US',
                                                                                                    {
                                                                                                        style: 'currency',
                                                                                                        currency: 'USD',
                                                                                                    },
                                                                                                )}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <div />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ),
                                                                )}
                                                        </div>
                                                    )}

                                                    <Box>
                                                        <Box>
                                                            {display &&
                                                                display.length > 0 &&
                                                                display[index] === true && (
                                                                    <Box sx={{ width: '100%', margin: '10px 0px' }}>
                                                                        <Box sx={{ margin: '10px 0px' }}>
                                                                            {' '}
                                                                            Total Price per variant for accepted
                                                                            options:{' '}
                                                                        </Box>
                                                                        {quotation.variants.map((variant: any) => {
                                                                            return customerVariants.map(
                                                                                (cVariant: any) => {
                                                                                    return (
                                                                                        cVariant.name ===
                                                                                            variant.name &&
                                                                                        quotation.options.map(
                                                                                            (option) =>
                                                                                                option.accepted &&
                                                                                                option.accepted ===
                                                                                                    true && (
                                                                                                    <Box
                                                                                                        sx={{
                                                                                                            display:
                                                                                                                'grid',
                                                                                                            gridGap:
                                                                                                                '5px',
                                                                                                            margin: '10px 0px',
                                                                                                            alignItems:
                                                                                                                'center',
                                                                                                            gridTemplateColumns:
                                                                                                                '3fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 5fr',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Box
                                                                                                            sx={
                                                                                                                quotationVariantsContainer
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                variant.name
                                                                                                            }
                                                                                                        </Box>
                                                                                                        <Box>
                                                                                                            {' '}
                                                                                                            {option.type ===
                                                                                                            '0'
                                                                                                                ? 'No MOQ'
                                                                                                                : option.type ===
                                                                                                                    '10'
                                                                                                                  ? 'MOQ 10'
                                                                                                                  : option.type ===
                                                                                                                      '50'
                                                                                                                    ? 'MOQ 50'
                                                                                                                    : option.type ===
                                                                                                                        '100'
                                                                                                                      ? 'MOQ 100'
                                                                                                                      : null}{' '}
                                                                                                        </Box>
                                                                                                        <Box>
                                                                                                            {
                                                                                                                cVariant.quantity
                                                                                                            }
                                                                                                        </Box>
                                                                                                        <Box> x </Box>
                                                                                                        <Box>
                                                                                                            {Number(
                                                                                                                option.unitPrice,
                                                                                                            ) +
                                                                                                                Number(
                                                                                                                    option.shippingLineFee ||
                                                                                                                        option.totalShippingLineFee ||
                                                                                                                        0,
                                                                                                                )}
                                                                                                        </Box>
                                                                                                        <Box> = </Box>
                                                                                                        <Box>
                                                                                                            {cVariant.quantity *
                                                                                                                (Number(
                                                                                                                    option.unitPrice,
                                                                                                                ) +
                                                                                                                    Number(
                                                                                                                        option.shippingLineFee ||
                                                                                                                            option.totalShippingLineFee ||
                                                                                                                            0,
                                                                                                                    ))}
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                ),
                                                                                        )
                                                                                    );
                                                                                },
                                                                            );
                                                                        })}
                                                                    </Box>
                                                                )}
                                                        </Box>
                                                    </Box>
                                                </>
                                            ))}
                                    </Box>
                                </Box>
                                {/* <Box sx={{ overflowY: 'auto', minHeight: '0vh' }}>
                                    {acceptedQuotations &&
                                        acceptedQuotations.length > 0 &&
                                        acceptedQuotations
                                            .filter((quotation) =>
                                                quotation.options.filter((option) => option.accepted === true),
                                            )
                                            .map((quotation) => {
                                                return (
                                                    <Box sx={{ mb: 1 }}>
                                                        <Box
                                                            sx={{
                                                                alignItems: 'center',
                                                                mb: 1,
                                                                // backgroundColor: 'green',
                                                            }}
                                                        >
                                                            <Box>
                                                                {quotation.options.map((option, index) => {
                                                                    if (option.accepted) {
                                                                        return (
                                                                            <div
                                                                                className={`border-2 rounded-lg border-[#dedcdc] dark:border-[#30363d] p-2  grid grid-flow-row-dense grid-cols-${
                                                                                    bulkQuantity === 0 ? '10' : '10'
                                                                                }  text-center`}
                                                                            >
                                                                                {bulkQuantity === 0 && (
                                                                                    <div className="col-span-2 flex justify-center items-center ">
                                                                                        {getVariantName.get(index)}
                                                                                    </div>
                                                                                )}
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.quantity}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.unitPrice &&
                                                                                        customerCurrency &&
                                                                                        currencyConverter(
                                                                                            option.unitPrice,
                                                                                            customerCurrency,
                                                                                        )}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.shippingLine === 'Vnlin'
                                                                                        ? ' Logistio Line 1'
                                                                                        : 'Logistio Line'}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.shippingLineFee &&
                                                                                        customerCurrency &&
                                                                                        currencyConverter(
                                                                                            option.totalShippingLineFee,
                                                                                            customerCurrency,
                                                                                        )}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.country}
                                                                                </div>
                                                                                <div className="flex justify-center items-center col-span-2">
                                                                                    {option.shippingDuration
                                                                                        ? `${option.shippingDuration} days`
                                                                                        : '-'}
                                                                                </div>
                                                                                <div className=" flex justify-center items-center">
                                                                                    {option.unitPrice &&
                                                                                        option.quantity &&
                                                                                        option.shippingLineFee &&
                                                                                        !Number.isNaN(
                                                                                            Number(option.unitPrice) *
                                                                                                Number(
                                                                                                    option.quantity,
                                                                                                ) +
                                                                                                Number(
                                                                                                    option.shippingLineFee,
                                                                                                ),
                                                                                        ) &&
                                                                                        currencyConverter(
                                                                                            Number(option.unitPrice) *
                                                                                                Number(
                                                                                                    option.quantity,
                                                                                                ) +
                                                                                                Number(
                                                                                                    option.shippingLineFee,
                                                                                                ),
                                                                                            customerCurrency,
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                </Box> */}
                            </div>
                        ) : (
                            // when product status is `waitingForPayment(5)` or anything status before that (quoted, pending ....)

                            <div className=" overflow-x-auto min-w-[800px] overflow-y-auto ">
                                {productStatus === 1 ||
                                    (productStatus === 2 && (
                                        <p className="text-xs font-medium dark:bg-yellow-100 dark:text-yellow-500 text-yellow-500 bg-yellow-100 border-l-yellow-500 border-l-2 p-2">
                                            *Product is not yet quoted.
                                        </p>
                                    ))}

                                {[5, 30].includes(productStatus || 0) && (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '10px',
                                                margin: '20px 0px',
                                                padding: '20px',
                                                border: '1px solid',
                                                borderColor: 'borderColor',
                                                borderRadius: '7px',
                                                backgroundColor: 'primaryBackground',
                                            }}
                                        >
                                            <Box>
                                                <Typography sx={{ margin: 0 }}>
                                                    Total price to pay:{' '}
                                                    {totalPrice &&
                                                        customerCurrency &&
                                                        currencyConverter(totalPrice, customerCurrency)}{' '}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <div className="flex w-full justify-end">
                                    {productStatus &&
                                        [3, 5].includes(productStatus) &&
                                        currentProduct?.expirationDate && (
                                            <span
                                                className={`${
                                                    productStatus === 3
                                                        ? 'w-[calc(100%-240px)]'
                                                        : 'w-[calc(100%-160px)]'
                                                } flex justify-center items-center text-red-500`}
                                            >
                                                {`You have ${expiredDate} left to submit your response.`}
                                            </span>
                                        )}

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            gap: '10px',
                                            margin: '10px 0px',
                                        }}
                                    >
                                        {approveBtn()}
                                    </Box>
                                </div>
                                {productStatus === 42 && (
                                    <p className="text-xs font-medium dark:bg-red-200 dark:text-red-600 text-red-600 bg-red-200 border-l-red-600 border-l-2 p-2">
                                        *Quotation refused.
                                    </p>
                                )}
                                {productStatus === 100 && (
                                    <p className="text-xs font-medium dark:bg-red-200 dark:text-red-600 text-red-600 bg-red-200 border-l-red-600 border-l-2 p-2">
                                        *Expired.
                                    </p>
                                )}
                                {productStatus === 69 && (
                                    <p className="bg-blue-500 text-gray-100 rounded-md py-1 px-2 font-bold my-1 w-[100%] text-center">
                                        Quotations are being reviewed.
                                    </p>
                                )}

                                <Box>
                                    <div className=" border-2 rounded-lg border-[#dedcdc] dark:border-[#30363d] p-2  ">
                                        {bulkQuantity === 0 ? (
                                            <div className={` grid  grid-cols-12  text-center`}>
                                                <div className="col-span-2 ">Variants</div>
                                                {/* <div>Moq</div> */}
                                                <div>Quantity</div>
                                                <div>Unit price</div>
                                                <div>Shipping line</div>
                                                <div>Total shipping fee</div>
                                                <div>Country</div>
                                                <div>Shipping duration estimate</div>
                                                <div>Unit total price</div>
                                                <div>Total Price</div>
                                                <div> Action </div>
                                                <div>Status</div>
                                            </div>
                                        ) : (
                                            <div className={` grid  grid-cols-10  text-center`}>
                                                {/* <div>Moq</div> */}
                                                <div>Quantity</div>
                                                <div>Unit price</div>
                                                <div>Shipping line</div>
                                                <div>Total shipping fee</div>
                                                <div>Country</div>
                                                <div>Shipping duration estimate</div>
                                                <div>Total Price</div>
                                                <div>Unit total price</div>
                                                <div> Action</div>
                                                <div>Status</div>
                                            </div>
                                        )}
                                    </div>

                                    <Box sx={{ minHeight: '0vh', maxHeight: '64vh', overflowY: 'auto' }}>
                                        <Box>
                                            {productStatus !== 1 &&
                                                acceptedQuotations &&
                                                acceptedQuotations.length > 0 &&
                                                acceptedQuotations.map((quotation, index: number) => (
                                                    <>
                                                        {quotation.options.some((opt) => !opt.isHidden) && (
                                                            <div className=" border-2 rounded-lg border-[#dedcdc] dark:border-[#30363d] p-2 bg-[#EAEDF7] dark:bg-[#191C24]   ">
                                                                {quotation.options
                                                                    // .filter((opt) => !opt.isHidden)
                                                                    .map((option, idx: number) =>
                                                                        option.isHidden ? null : (
                                                                            <div
                                                                                className={`grid grid-flow-row-dense grid-cols-${
                                                                                    bulkQuantity === 0 ? '12' : '10'
                                                                                }  text-center`}
                                                                            >
                                                                                {bulkQuantity === 0 && (
                                                                                    <div className="col-span-2 flex justify-center items-center ">
                                                                                        {quotation.variants[idx].name}
                                                                                    </div>
                                                                                )}
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.quantity}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {option.unitPrice &&
                                                                                        customerCurrency &&
                                                                                        currencyConverter(
                                                                                            option.unitPrice,
                                                                                            customerCurrency,
                                                                                        )}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {(quotation.firstNonHiddenIdx !==
                                                                                        null &&
                                                                                        idx ===
                                                                                            quotation.firstNonHiddenIdx) ||
                                                                                    (quotation.firstNonHiddenIdx ===
                                                                                        null &&
                                                                                        idx === 0)
                                                                                        ? option.shippingLine ===
                                                                                          'Vnlin'
                                                                                            ? ' Logistio Line 1'
                                                                                            : 'Logistio Line'
                                                                                        : ''}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {(quotation.firstNonHiddenIdx !==
                                                                                        null &&
                                                                                        idx ===
                                                                                            quotation.firstNonHiddenIdx) ||
                                                                                    (quotation.firstNonHiddenIdx ===
                                                                                        null &&
                                                                                        idx === 0)
                                                                                        ? option.totalShippingLineFee &&
                                                                                          customerCurrency &&
                                                                                          currencyConverter(
                                                                                              option.totalShippingLineFee,
                                                                                              customerCurrency,
                                                                                          )
                                                                                        : ''}
                                                                                </div>
                                                                                <div className="flex justify-center items-center">
                                                                                    {(quotation.firstNonHiddenIdx !==
                                                                                        null &&
                                                                                        idx ===
                                                                                            quotation.firstNonHiddenIdx) ||
                                                                                    (quotation.firstNonHiddenIdx ===
                                                                                        null &&
                                                                                        idx === 0)
                                                                                        ? option.country
                                                                                        : ''}
                                                                                </div>
                                                                                <div className="flex justify-center items-center col-span-1">
                                                                                    {(quotation.firstNonHiddenIdx !==
                                                                                        null &&
                                                                                        idx ===
                                                                                            quotation.firstNonHiddenIdx) ||
                                                                                    (quotation.firstNonHiddenIdx ===
                                                                                        null &&
                                                                                        idx === 0)
                                                                                        ? option.shippingDuration
                                                                                            ? `${option.shippingDuration} days`
                                                                                            : '-'
                                                                                        : ''}
                                                                                </div>
                                                                                <div className=" flex justify-center items-center">
                                                                                    {customerCurrency &&
                                                                                    option.unitPrice &&
                                                                                    option.totalShippingLineFee &&
                                                                                    option.quantity &&
                                                                                    !Number.isNaN(
                                                                                        Number(option.unitPrice) +
                                                                                            Number(
                                                                                                option.totalShippingLineFee,
                                                                                            ) /
                                                                                                getOptionsTotalQuantity(
                                                                                                    quotation.options,
                                                                                                ),
                                                                                    ) &&
                                                                                    getOptionsTotalQuantity(
                                                                                        quotation.options,
                                                                                    ) !== 0
                                                                                        ? currencyConverter(
                                                                                              Number(option.unitPrice) +
                                                                                                  Number(
                                                                                                      option.totalShippingLineFee,
                                                                                                  ) /
                                                                                                      getOptionsTotalQuantity(
                                                                                                          quotation.options,
                                                                                                      ),
                                                                                              customerCurrency,
                                                                                          )
                                                                                        : ' - '}
                                                                                </div>
                                                                                <div className=" flex justify-center items-center">
                                                                                    {idx ===
                                                                                    Math.round(
                                                                                        quotation.options.length / 2,
                                                                                    ) -
                                                                                        1 ? (
                                                                                        <>
                                                                                            {!Number.isNaN(
                                                                                                getCountryTotalPrice(
                                                                                                    quotation,
                                                                                                ),
                                                                                            ) && (
                                                                                                <Typography>
                                                                                                    {getCountryTotalPrice(
                                                                                                        quotation,
                                                                                                    ).toLocaleString(
                                                                                                        'en-US',
                                                                                                        {
                                                                                                            style: 'currency',
                                                                                                            currency:
                                                                                                                'USD',
                                                                                                        },
                                                                                                    )}
                                                                                                </Typography>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div />
                                                                                    )}
                                                                                </div>
                                                                                <div className="">
                                                                                    {![
                                                                                        2, 5, 69, 30, 42, 8, 100,
                                                                                    ].includes(productStatus || 0) ? (
                                                                                        // action buttons (accept refuse)
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                justifyContent:
                                                                                                    'center',
                                                                                                alignItems: 'center',
                                                                                            }}
                                                                                        >
                                                                                            {option.accepted ===
                                                                                            true ? (
                                                                                                <CustomButton
                                                                                                    sizeType="small"
                                                                                                    styleType="danger"
                                                                                                    label="Cancel"
                                                                                                    onClick={() => {
                                                                                                        cancelQuotationDecision(
                                                                                                            index,
                                                                                                            idx,
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            ) : option.accepted ===
                                                                                              false ? (
                                                                                                <CustomButton
                                                                                                    sizeType="small"
                                                                                                    styleType="danger"
                                                                                                    label="Cancel"
                                                                                                    onClick={() => {
                                                                                                        cancelQuotationDecision(
                                                                                                            index,
                                                                                                            idx,
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            ) : (
                                                                                                <>
                                                                                                    {/* Accept quotation button */}
                                                                                                    <CustomBtnIcon
                                                                                                        icon={
                                                                                                            <Tooltip title="Accept Quotation">
                                                                                                                <DoneIcon />
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                        size="small"
                                                                                                        color="primary"
                                                                                                        onClick={() => {
                                                                                                            decideOnQuotation(
                                                                                                                index,
                                                                                                                idx,
                                                                                                                true,
                                                                                                            );
                                                                                                        }}
                                                                                                    />

                                                                                                    {/* Refuse quotation button */}
                                                                                                    <CustomBtnIcon
                                                                                                        icon={
                                                                                                            <Tooltip title="Refuse Quotation">
                                                                                                                <CloseIcon />
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                        size="small"
                                                                                                        color="error"
                                                                                                        onClick={() => {
                                                                                                            decideOnQuotation(
                                                                                                                index,
                                                                                                                idx,
                                                                                                                false,
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </>
                                                                                            )}
                                                                                        </Box>
                                                                                    ) : (
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                justifyContent:
                                                                                                    'center',
                                                                                                alignItems: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Typography>-</Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                </div>
                                                                                <div className=" flex justify-center items-center">
                                                                                    {productStatus === 2 ? (
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                justifyContent:
                                                                                                    'center',
                                                                                                alignItems: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Typography>-</Typography>
                                                                                        </Box>
                                                                                    ) : (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                option.accepted === true
                                                                                                    ? 'Accepted'
                                                                                                    : option.accepted ===
                                                                                                        false
                                                                                                      ? 'Refused'
                                                                                                      : 'Pending'
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    borderRadius: '50%',
                                                                                                    width: 12,
                                                                                                    height: 12,
                                                                                                    background: `${
                                                                                                        option.accepted
                                                                                                            ? '#93c66e'
                                                                                                            : option.accepted ===
                                                                                                                false
                                                                                                              ? '#ff7777'
                                                                                                              : '#578aff'
                                                                                                    }`,
                                                                                                }}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ),
                                                                    )}
                                                            </div>
                                                        )}

                                                        <Box>
                                                            <Box>
                                                                {display &&
                                                                    display.length > 0 &&
                                                                    display[index] === true && (
                                                                        <Box sx={{ width: '100%', margin: '10px 0px' }}>
                                                                            <Box sx={{ margin: '10px 0px' }}>
                                                                                {' '}
                                                                                Total Price per variant for accepted
                                                                                options:{' '}
                                                                            </Box>
                                                                            {quotation.variants.map((variant: any) => {
                                                                                return customerVariants.map(
                                                                                    (cVariant: any) => {
                                                                                        return (
                                                                                            cVariant.name ===
                                                                                                variant.name &&
                                                                                            quotation.options.map(
                                                                                                (option) =>
                                                                                                    option.accepted &&
                                                                                                    option.accepted ===
                                                                                                        true && (
                                                                                                        <Box
                                                                                                            sx={{
                                                                                                                display:
                                                                                                                    'grid',
                                                                                                                gridGap:
                                                                                                                    '5px',
                                                                                                                margin: '10px 0px',
                                                                                                                alignItems:
                                                                                                                    'center',
                                                                                                                gridTemplateColumns:
                                                                                                                    '3fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 5fr',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Box
                                                                                                                sx={
                                                                                                                    quotationVariantsContainer
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    variant.name
                                                                                                                }
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                {' '}
                                                                                                                {option.type ===
                                                                                                                '0'
                                                                                                                    ? 'No MOQ'
                                                                                                                    : option.type ===
                                                                                                                        '10'
                                                                                                                      ? 'MOQ 10'
                                                                                                                      : option.type ===
                                                                                                                          '50'
                                                                                                                        ? 'MOQ 50'
                                                                                                                        : option.type ===
                                                                                                                            '100'
                                                                                                                          ? 'MOQ 100'
                                                                                                                          : null}{' '}
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                {
                                                                                                                    cVariant.quantity
                                                                                                                }
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                {' '}
                                                                                                                x{' '}
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                {Number(
                                                                                                                    option.unitPrice,
                                                                                                                ) +
                                                                                                                    Number(
                                                                                                                        option.shippingLineFee,
                                                                                                                    )}
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                {' '}
                                                                                                                ={' '}
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                {cVariant.quantity *
                                                                                                                    (Number(
                                                                                                                        option.unitPrice,
                                                                                                                    ) +
                                                                                                                        Number(
                                                                                                                            option.shippingLineFee,
                                                                                                                        ))}
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    ),
                                                                                            )
                                                                                        );
                                                                                    },
                                                                                );
                                                                            })}
                                                                        </Box>
                                                                    )}
                                                            </Box>
                                                        </Box>
                                                    </>
                                                ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        )}
                    </div>
                    <div className="flex">
                        <div className="w-10/12 flex justify-end items-center mr-1">Total Price:</div>
                        <div className="w-2/12 flex justify-center items-center border dark:border-gray-700 border-gray-200 rounded-lg">
                            {currencyConverter(totalPrice, customerCurrency)}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                        <div>
                            <Typography className="border-2 border-spacing-2 p-1 rounded-lg border-[#dedcdc] dark:border-[#30363d] flex justify-between items-center">
                                <div className="flex gap-2">
                                    <p>Product quotation images and files:</p>
                                    <p>
                                        {currentProduct && currentProduct.quotationImages.length < 1 && (
                                            <span className="text-xs text-red-300">*No available images</span>
                                        )}
                                    </p>
                                </div>
                                {currentProduct && currentProduct.quotationImages.length > 0 && (
                                    <Button
                                        onClick={() =>
                                            downloadFilesAsBase64(
                                                currentProduct?.quotationImages as string[],
                                                currentProduct?.name as string,
                                            )
                                        }
                                    >
                                        Download all Files
                                    </Button>
                                )}
                            </Typography>

                            {currentProduct && currentProduct.quotationImages.length > 0 && (
                                <div className="flex gap-1">{results}</div>
                            )}

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    gap: 2,
                                }}
                            >
                                {currentProduct &&
                                    currentProduct.quotationImages.map((imgLink: string) => {
                                        return (
                                            <div>{filesDisplay(imgLink, currentProduct?.name)}</div>
                                            // <div>
                                            //     {imgLink.endsWith('.jpg') ||
                                            //     imgLink.endsWith('.png') ||
                                            //     imgLink.endsWith('.jpeg') ||
                                            //     imgLink.endsWith('.svg') ||
                                            //     imgLink.endsWith('.svg%2Bxml') ? (
                                            //         <Zoom>
                                            //             <img
                                            //                 src={imgLink}
                                            //                 alt="Quotation Images"
                                            //                 style={{
                                            //                     objectFit: 'cover',
                                            //                     borderRadius: 8,
                                            //                     overflow: 'hidden',
                                            //                     width: 100,
                                            //                     height: 100,
                                            //                 }}
                                            //             />
                                            //         </Zoom>
                                            //     ) : imgLink.endsWith('.pdf') ? (
                                            //         <div className="relative" title="Click to view PDF">
                                            //             <img
                                            //                 title="Click to view PDF"
                                            //                 src={FilePdf}
                                            //                 alt="PDF File"
                                            //                 className="cursor-pointer "
                                            //                 style={{
                                            //                     objectFit: 'cover',
                                            //                     borderRadius: 8,
                                            //                     overflow: 'hidden',
                                            //                     width: 100,
                                            //                     height: 100,
                                            //                 }}
                                            //                 onClick={() =>
                                            //                     openPdfInNewTab(imgLink, currentProduct.name)
                                            //                 }
                                            //             />

                                            //             <IoSearch
                                            //                 onClick={() =>
                                            //                     openPdfInNewTab(imgLink, currentProduct.name)
                                            //                 }
                                            //                 className="absolute left-4 cursor-pointer top-1 h-5 w-5  text-red-500  hover:bg-red-200 hover:ring-2 ring-red-200 "
                                            //             />
                                            //         </div>
                                            //     ) : (
                                            //         <img
                                            //             src={FileFile}
                                            //             alt="Quotation Files"
                                            //             style={{
                                            //                 objectFit: 'cover',
                                            //                 borderRadius: 8,
                                            //                 overflow: 'hidden',
                                            //                 width: 100,
                                            //                 height: 100,
                                            //             }}
                                            //         />
                                            //     )}
                                            // </div>
                                        );
                                    })}
                            </Box>
                        </div>

                        <div>
                            <Typography className=" border-2 border-spacing-2 p-1 rounded-lg border-[#dedcdc] dark:border-[#30363d] flex justify-between items-center">
                                <div>
                                    Product quotation videos:{' '}
                                    {currentProduct && currentProduct?.video.length < 1 && (
                                        <span className="text-xs text-red-300">*No available video</span>
                                    )}
                                </div>
                                <div>
                                    {currentProduct && currentProduct?.video.length > 0 && (
                                        <Button
                                            onClick={() =>
                                                downloadFilesAsBase64(
                                                    currentProduct?.video as string[],
                                                    currentProduct?.name as string,
                                                )
                                            }
                                        >
                                            Download all videos
                                        </Button>
                                    )}
                                </div>
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 2,
                                }}
                            >
                                {currentProduct &&
                                    currentProduct.video &&
                                    currentProduct.video.length > 0 &&
                                    currentProduct.video.map((url: string) => (
                                        // eslint-disable-next-line jsx-a11y/media-has-caption
                                        <video
                                            key={url}
                                            controls
                                            style={{
                                                width: '349px',
                                                aspectRatio: '16/9',
                                                objectFit: 'cover',
                                                borderRadius: '7px',
                                                overflow: 'hidden',
                                            }}
                                            src={url.startsWith('https://') ? url : `https://${url}`}
                                        />
                                    ))}
                            </Box>
                        </div>
                    </div>
                </>
            )}
            <Modal
                open={open}
                setOpen={setOpen}
                body={
                    <PaymentModal
                        totalPrice={totalPrice}
                        product={location.state.productId}
                        setOpenModal={setOpen}
                        customerCurrency={customerCurrency}
                    />
                }
            />
        </div>
    );
};

export default Quotation;
