import * as Yup from 'yup';

// const errorType = 'Field must be a number';

export const UpdateBankConfig = Yup.object().shape({
    // type: Yup.string().typeError(errorType).optional(),
    name: Yup.string().required('Name is required'),
    recipientEmail: Yup.string().email('Email form is invalid').required('Receipient email is required'),
    receiverType: Yup.string().required('Receiver Type is required'),
    // targetCurrency: Yup.string().required('Target currency is required'),
    type: Yup.string().required('Type is required'),
    // eslint-disable-next-line func-names
    accountNumber: Yup.string().test('is-bigint-non-zero-and-less-than-max', ' Min is 0.', function (value) {
        // Check if the input is a valid positive non zero number
        if (!value) {
            return this.createError({ message: 'Account Number is required' });
        }
        if (/^-?\d+$/.test(value!)) {
            // Check if the length is within the limit and the integer value is <= 99999999999999999 (BigInt)
            const maxAllowedValue = BigInt('99999999999999999');
            const minAllowedValue = BigInt(0);

            const inputBigInt = BigInt(value || '');
            if (inputBigInt <= minAllowedValue) {
                return this.createError({ message: 'Min value is 0' });
            }
            if (inputBigInt > maxAllowedValue) {
                return this.createError({ message: 'Max value is 99999999999999999' });
            }
            // we use value.length + 1 because min/max allowed values end with an `n` (BigInt)
            if (value!.length + 1 < `${minAllowedValue}`.length || value!.length + 1 > `${maxAllowedValue}`.length) {
                return this.createError({
                    message: `Account number length should be between ${`${minAllowedValue}`.length} and ${
                        `${maxAllowedValue}`.length
                    } digits.`,
                });
            }
            return true;
        }
        return this.createError({ message: 'Account number should be a valid number.' });
    }),
    routingNumber: Yup.string().required('Routing Number is required'),
    // accountType: Yup.string().required('Account Type is required'),
    accountType: Yup.string()
        .required('Account Type is required')
        .oneOf(['SAVING', 'CHECKING'], 'Account Type must be either "SAVING" or "CHECKING"'),
    addressCountryCode: Yup.string().required(' Adress country Code is required'),
    addressCity: Yup.string().required('Adress city is required'),
    addressFirstLine: Yup.string().required('Adress first Line is required'),
    addressState: Yup.string().required('Adress state is required'),
    addressPostCode: Yup.string().required('Adress post code is required'),
});

const ALPHANUMERIC_REGEX = /^\w*$/;
const NUMERIC_REGEX = /^[0-9]*$/;

export const customerBankInformation = Yup.object().shape({
    bankingType: Yup.string().oneOf(['FinancialService', 'BankAccount']).required('Funds type is required'),
    paymentService: Yup.string()
        .nullable(true)
        .when('bankingType', {
            is: (val: string) => val === 'financialService',
            then: (schema) => schema.required('Field required').oneOf(['Payoneer', 'Paypal']),
        }),
    email: Yup.string()
        .nullable(true)
        .when('bankingType', {
            is: (val: string) => val === 'FinancialService',
            then: (schema) => schema.required('Field required').email('Invalid email format'),
        }),

    transferMethod: Yup.string()
        .nullable(true)
        .when('bankingType', {
            is: (val: string) => val === 'BankAccount',
            then: (schema) => schema.required('Field required').oneOf(['ACH', 'SWIFT']),
        }),
    accountHolder: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required'),
        }),
    receiverType: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required').oneOf(['PERSON', 'BUSINESS']),
        }),

    accountNumber: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH',
            then: (schema) =>
                schema.matches(NUMERIC_REGEX, 'Invalid format,only numbers are allowed').required('Field required'),
        }),
    achRoutingNumber: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH',
            then: (schema) =>
                schema.matches(NUMERIC_REGEX, 'Invalid format, only numbers are allowed').required('Field required'),
        }),

    accountType: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH',
            then: (schema) => schema.required('Field required').oneOf(['SAVING', 'CHECKING']),
        }),
    ibanRoutingNumber: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'SWIFT',
            then: (schema) =>
                schema
                    .matches(ALPHANUMERIC_REGEX, 'Invalid format, only alphanumeric characters are allowed.')
                    .required('Field required'),
        }),

    swiftBicCode: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'SWIFT',
            then: (schema) =>
                schema
                    .matches(ALPHANUMERIC_REGEX, 'Invalid format, only alphanumeric characters are allowed.')
                    .required('Field required'),
        }),

    city: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required'),
        }),
    country: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required'),
        }),

    postCode: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required'),
        }),

    recepientAdress: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required'),
        }),
    targetCurrency: Yup.string()
        .nullable(true)
        .when('transferMethod', {
            is: (val: string) => val === 'ACH' || val === 'SWIFT',
            then: (schema) => schema.required('Field required'),
        }),
});
