/* eslint-disable no-unused-vars */
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import {
    FaRegSharedProduct,
    IoStorefrontShop,
    MdOutlineIntegration,
    MdOutlineSupport,
} from 'components/sideBarIcons/sideBarIcons';
import Redirection from 'integrations/lightfunnels/lightfunnelsRedirection';
import ConsentScreen from 'integrations/lightfunnels/logistioConsentScreen';
import Conversations from 'pages/conversations/Conversations';
import Dashboard from 'pages/dashboard/Dashboard';
import IntegrationPage from 'pages/integrations/integrationPage';
import InvoiceList from 'pages/invoice/codWithCCInvoice/invoiceList';
import PrepaidInvoiceList from 'pages/invoice/prepaidInvoice/invoiceList';
import Orders from 'pages/orders/CodOrders';
import PrepaidOrders from 'pages/orders/PrepaidOrders';
import OrderDetails from 'pages/orders/details/OrderDetails';
import Payments from 'pages/payments/payments';
import ProductList from 'pages/productList/ProductList';
import productSettings from 'pages/productSettings/productSettings';
import SourcingProcess from 'pages/productSourcing/LogistioBulk/SourcingProcess';
import SourcingType from 'pages/productSourcing/SourcingType';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import LogistioShop from 'pages/sellers/logistioShop/logistioShop';
import SellerProductDetail from 'pages/sellers/sellersProductsDetails/sellerProductDetail';
import SharedProducts from 'pages/sellers/sharedProducts/sharedProducts';
import Settings from 'pages/settings/Settings';
import CustomerBankInformationDisplay from 'pages/settings/bankInformation/customerBankInformationDisplay';
import ProfilePage from 'pages/settings/profileSettings/profilePage';
import StockInformation from 'pages/settings/stockInformation/StockInformation';
import StockList from 'pages/stock/StockList';
import Stores from 'pages/stores/Stores';
import SupportChat from 'pages/support/SupportChat';
import Wallet from 'pages/wallet/wallet';
import LightFunnelsMatching from 'pages/lightfunnelsMatching/LightFunnelsMatching';
import { MdJoinInner } from 'react-icons/md';
import TrackingPage from 'pages/trackingPages/trackingPage';
import TrackingResults from 'pages/trackingPages/trackingResult';
import Login from './pages/login/Login';
import SignUp from './pages/signUp/SignUp';

const routes = [
    {
        path: '',
        // name: 'Dashboard',
        // icon: DashboardOutlinedIcon,
        element: Dashboard,
        layout: '/',
        // type: 'features',
    },
    {
        path: 'dashboard',
        name: 'Dashboard',
        icon: DashboardOutlinedIcon,
        element: Dashboard,
        layout: '/',
        type: 'features',
    },

    {
        name: 'Logistio shop',
        icon: IoStorefrontShop,
        layout: '/',
        type: 'features',
        children: [
            {
                path: 'logistio-shop',
                name: 'All products',
                icon: Inventory2OutlinedIcon,
                element: LogistioShop,
                layout: '/',
                type: 'features',
            },
            {
                path: 'shared-products',
                name: 'My product list',
                icon: FaRegSharedProduct,
                element: SharedProducts,
                layout: '/',
                type: 'features',
            },
        ],
    },

    {
        path: 'logistio-shop/:productname/:id',
        name: '',
        element: SellerProductDetail,
        layout: '/',
    },

    {
        path: 'settings',
        name: 'Settings',
        icon: SettingsOutlinedIcon,
        element: Settings,
        layout: '/',
        // title: 'Test Settings',
        routes: [
            {
                path: '/profile-settings',
                name: 'profile settings',
                element: ProfilePage,
                layout: '/settings',
            },
            {
                path: '/wallet',
                name: 'wallet',
                element: Wallet,
                layout: '/settings',
            },
            {
                path: '/bank-information',
                name: 'Bank information',
                element: CustomerBankInformationDisplay,
                layout: '/settings',
            },
            {
                path: '/stock-information',
                name: 'Stock information',
                element: StockInformation,
                layout: '/settings',
            },
        ],
        type: 'parameters',
    },
    {
        path: 'stores',
        name: 'Stores',
        icon: StoreMallDirectoryOutlinedIcon,
        element: Stores,
        layout: '/',
        type: 'parameters',
    },
    // {
    //     path: 'notifications',
    //     name: 'Notifications',
    //     icon: NotificationsOutlinedIcon,
    //     element: Notifications,
    //     layout: '/',
    //     routes: [
    //         {
    //             path: '/purchases',
    //             name: 'PurchasesNotifications',
    //             element: PurchasesNotifications,
    //             layout: '/notifications',
    //         },
    //         {
    //             path: '/all',
    //             name: 'AllNotifications',
    //             element: AllNotifications,
    //             layout: '/notifications',
    //         },
    //         {
    //             path: '/products',
    //             name: 'ProductNotifications',
    //             element: ProductNotifications,
    //             layout: '/notifications',
    //         },
    //         {
    //             path: '/orders',
    //             name: 'OrdersNotifications',
    //             element: OrdersNotifications,
    //             layout: '/notifications',
    //         },
    //     ],
    //     type: 'parameters',
    // },

    {
        path: '/reset-password',
        name: 'ResetPassword',
        element: ResetPassword,
        layout: '/auth',
    },
    {
        path: 'sourcing',
        name: 'Product sourcing',
        icon: PrecisionManufacturingOutlinedIcon,
        element: SourcingType,
        layout: '/',
        type: 'features',
    },
    {
        path: 'lightfunnels-matching',
        name: 'Lightfunnels matching',
        icon: MdJoinInner,
        element: LightFunnelsMatching,
        layout: '/',
        type: 'features',
    },
    {
        name: 'Logistio bulk',
        icon: ShoppingBagOutlinedIcon,
        layout: '/',
        type: 'features',
        children: [
            {
                path: 'products',
                name: 'Product list',
                icon: Inventory2OutlinedIcon,
                element: ProductList,
                layout: '/',
                type: 'features',
            },
            {
                path: 'restock',
                name: 'Restock',
                icon: FaRegSharedProduct,
                element: ProductList,
                layout: '/',
                type: 'features',
            },
            // {
            //     path: 'shared-products',
            //     name: 'Shared products',
            //     icon: FaRegSharedProduct,
            //     element: SharedProducts,
            //     layout: '/',
            //     type: 'features',
            // },
        ],
    },
    // {
    //     // path: 'products',
    //     name: 'Products',
    //     icon: ShoppingBagOutlinedIcon,
    //     // element: ProductList,
    //     layout: '/',
    //     type: 'features',
    //     children: [
    //         // {
    //         //     path: 'sourcing',
    //         //     name: 'Product sourcing',
    //         //     icon: PrecisionManufacturingOutlinedIcon,
    //         //     element: SourcingType,
    //         //     layout: '/',
    //         //     type: 'features',
    //         // },
    //         {
    //             path: 'products',
    //             name: 'My products List',
    //             icon: Inventory2OutlinedIcon,
    //             element: ProductList,
    //             layout: '/',
    //             type: 'features',
    //         },
    //         {
    //             path: 'shared-products',
    //             name: 'Shared products',
    //             icon: FaRegSharedProduct,
    //             element: SharedProducts,
    //             layout: '/',
    //             type: 'features',
    //         },

    //         {
    //             path: 'lightfunnels-matching',
    //             name: 'Lightfunnels matching',
    //             icon: JoinInnerIcon,
    //             element: LightFunnelsMatching,
    //             layout: '/',
    //             type: 'features',
    //         },
    //     ],
    // },
    {
        path: 'sourcing-progress',
        name: 'SourcingProcess',
        icon: '',
        element: SourcingProcess,
        layout: '/',
        type: 'subpage',
    },

    {
        name: 'Orders',
        icon: LocalShippingOutlinedIcon,
        layout: '/',
        type: 'features',
        children: [
            {
                path: 'prepaid-orders',
                name: 'Prepaid orders',
                icon: CreditCardOutlinedIcon,
                element: PrepaidOrders,
                layout: '/',
                type: 'features',
            },
            {
                path: 'cod-orders',
                name: 'COD orders',
                icon: LocalAtmOutlinedIcon,
                element: Orders,
                layout: '/',
                type: 'features',
            },
        ],
    },
    {
        path: 'inventory',
        name: 'Inventory',
        icon: WarehouseOutlinedIcon,
        element: StockList,
        layout: '/',
        type: 'features',
    },
    {
        path: 'conversations',
        name: 'Chat',
        icon: ChatIcon,
        element: Conversations,
        layout: '/',
        type: 'features',
    },
    {
        path: 'orders/chat',
        name: 'odersDetails',
        icon: ShoppingCartSharpIcon,
        element: OrderDetails,
        layout: '/',
    },

    {
        path: '/signup',
        name: 'Signup',
        element: SignUp,
        layout: '/auth',
    },
    {
        path: '/login',
        name: 'Login',
        element: Login,
        layout: '/auth',
    },

    // Trackign order pages
    {
        path: '',
        name: 'Tracking',
        element: TrackingPage,
        layout: '/tracking',
    },
    {
        path: '/tracking-results',
        name: 'Tracking results',
        element: TrackingResults,
        layout: '/tracking',
    },

    {
        // path: 'finance',
        name: 'Finance',
        icon: AccountBalanceOutlinedIcon,
        // element: 'div',
        layout: '/',
        type: 'features',
        children: [
            {
                path: 'invoice',
                name: 'COD Invoices',
                icon: RequestQuoteIcon,
                element: InvoiceList,
                layout: '/',
                type: 'features',
            },
            {
                path: 'prepaid-invoice',
                name: 'Prepaid Invoices',
                icon: ReceiptLongIcon,
                element: PrepaidInvoiceList,
                layout: '/',
                type: 'features',
            },
            {
                path: 'payments',
                name: 'Payments',
                icon: PointOfSaleIcon,
                element: Payments,
                layout: '/',
                type: 'features',
            },
        ],
    },

    {
        path: 'product-settings',
        name: 'Product Settings',
        icon: Inventory2OutlinedIcon,
        element: productSettings,
        layout: '/',
        type: 'subpage',
    },
    {
        path: 'support-chat',
        name: 'Support Chat',
        icon: MdOutlineSupport,
        element: SupportChat,
        layout: '/',
        type: 'parameters',
    },
    {
        path: 'lightfunnels',
        element: ConsentScreen,
        layout: '/integrations',
    },
    {
        path: '/lightfunnels',
        element: Redirection,
        layout: '/redirects',
    },
    {
        path: 'integration',
        name: 'Integration',
        icon: MdOutlineIntegration,
        element: IntegrationPage,
        layout: '/',
        type: 'parameters',
    },
    // {
    //     path: 'testing',
    //     name: 'TEST',
    //     icon: 'IoCodeWorkingOutline',
    //     element: SellerProductDetail,
    //     layout: '/',
    //     type: 'parameters',
    // },
];

export default routes;
