/* eslint-disable no-unused-vars */
export const sourcingContainerWrapper = {
    width: '260px',
    padding: '0px',
    border: '0px solid',
    borderColor: 'borderColor',
    borderRadius: '8px',
    backgroundColor: 'sourcingContainer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
};
export const sourcingContainerStyle = {
    // margin: '60px 30px 40px 30px',
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
};

export const sourcingContainrTitle = {
    color: 'textColor.tertiary',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
};

export const sourcingContainrDescription = {
    fontSize: '12px',
    color: 'textColor.secondary',
    maxWidth: '200px',
    textAlign: 'center',
};

export const sourcingContainerButton = (disabled?: boolean) => {
    return {
        backgroundColor: disabled ? '' : 'primary.main',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '0',
    };
};
