/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { IProduct } from 'utils/Interfaces/products.interface';

export const getVariantQuantity = (product: IProduct) => {
    let quantity = 0;
    const quotation = product?.quotation;
    if (!quotation?.length) return product.quantity;
    quotation.forEach(async (quote) => {
        (quote.options || []).forEach((el) => {
            if (el.accepted || el.accepted === undefined) {
                quantity += el.quantity ? Number(el.quantity) : 0;
            }
        });
    });
    return quantity;
};

export const getCounteries = (product: IProduct) => {
    const Countries: string[] = [];
    const quotation = product?.parentProduct?.quotation;
    const shipment =
        product?.shippingTo?.length > 1
            ? product?.shippingTo
            : product?.shippingTo?.length === 1
              ? product.shippingTo[0]?.split(',')
              : [];
    if (!quotation?.length) return shipment;
    quotation.forEach(async (quote) => {
        (quote.options || []).forEach((el) => {
            if (el.accepted || el.accepted === undefined) {
                Countries.push(el.country);
            }
        });
    });
    return Countries;
};

export const getTotalQuantity = (product: IProduct) => {
    let quantity = 0;
    const quotation = product?.quotation;
    if (quotation?.length && ![42, 99].includes(product.status))
        quotation.forEach(async (quote) => {
            (quote.options || []).forEach((el) => {
                if (el.accepted || el.accepted === undefined) {
                    quantity += el.quantity ? Number(el.quantity) : 0;
                }
            });
        });
    else if (product?.variants?.length)
        ((product.variants as IProduct[]) || []).forEach((el) => {
            quantity += el?.quantity ? Number(el.quantity) : 0;
        });
    else return product.quantity;
    return quantity;
};
