import React from 'react';

const SellerProductDetailsLoader = () => {
    return (
        <div>
            <div className="flex gap-10 flex-wrap    animate-pulse  ">
                <div className=" h-[392px] w-[392px] 2xl:h-[492px] 2xl:w-[492px] ">
                    <svg
                        className="w-full h-full text-gray-200 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18"
                    >
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>

                <div className=" flex-grow max-w-[750px] mt-12 ">
                    <div className="flex justify-between items-center flex-wrap">
                        <div>
                            <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mb-4" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4" />
                            <div className="flex gap-1">
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20" />
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20" />
                            </div>
                        </div>
                    </div>
                    <hr className="my-4 h-px border-t-0 bg-gray-300 dark:bg-gray-700  w-full " />

                    <div className=" bg-gray-200 flex items-center p-3  h-12 rounded-lg dark:bg-gray-700 w-48">
                        <div className=" bg-gray-400 flex items-center p-3  h-5 rounded-lg dark:bg-gray-800 w-10 me-1" />
                        <div className=" bg-gray-400 flex items-center p-3  h-5 rounded-lg dark:bg-gray-800 w-10 me-1" />
                        <div className=" bg-gray-400 flex items-center p-3  h-5 rounded-lg dark:bg-gray-800 w-10 me-1" />
                    </div>
                    <div className="flex gap-1 justify-between flex-wrap mt-5">
                        <div className=" bg-gray-200 flex items-center p-3  h-40 rounded-lg dark:bg-gray-700  flex-grow min-w-0" />
                        <div className=" bg-gray-200 flex items-center p-3  h-40 rounded-lg dark:bg-gray-700 flex-grow min-w-0 " />
                        <div className=" bg-gray-200 flex items-center p-3  h-40 rounded-lg dark:bg-gray-700 flex-grow min-w-0" />
                    </div>
                    <div className="flex gap-2 my-5">
                        <div className=" bg-gray-200 flex items-center p-3  h-20 rounded-lg dark:bg-gray-700  w-44" />
                        <div className=" border-4 border-gray-200  flex items-center p-3  h-20 rounded-lg dark:border-gray-700 w-44" />
                    </div>
                    <div className="my-10">
                        <div className="flex gap-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4" />
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className=" bg-gray-200 flex items-center p-3  h-24  rounded-lg dark:bg-gray-700  flex-grow min-w-0" />
                            <div className=" bg-gray-200 flex items-center p-3  h-24 rounded-lg dark:bg-gray-700  flex-grow min-w-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellerProductDetailsLoader;
