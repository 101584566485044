/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
    // eslint-disable-next-line no-unused-vars
    interface ButtonPropsVariantOverrides {
        primary: true;
        secondary: true;
        tertiary: true;
        quaternary: true;
        variantBox: true;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        selected: { [keys: string]: string };
        errorColor?: { [keys: string]: string };
        textColor: { [keys: string]: string };
        primaryBackground: string;
        secondaryBackground: string;
        grayBackground: string;
        inputBackground: string;
        borderColor: string;
        tertiaryBackground: string;
        newNotificationBackground?: string;
        sourcingContainer?: string;
        variantColor?: string;
        navBarIconColor?: string;
        rippleColor?: string;
        logoColor?: string;
    }
    // allow configuration using `createTheme`
    interface PaletteOptions {
        selected?: { [keys: string]: string };
        errorColor?: { [keys: string]: string };
        textColor?: { [keys: string]: string };
        primaryBackground?: string;
        secondaryBackground?: string;
        tertiaryBackground?: string;
        grayBackground?: string;
        borderColor?: string;
        inputBackground?: string;
        newNotificationBackground?: string;
        sourcingContainer?: string;
        variantColor?: string;
        navBarIconColor?: string;
        rippleColor?: string;
        logoColor?: string;
    }
}

// eslint-disable-next-line import/no-mutable-exports
let customTheme = createTheme({
    palette: {
        primaryBackground: '#EAEDF7',
        secondaryBackground: '#f9f9fb',
        tertiaryBackground: '#fafbff',
        borderColor: '#dedcdc',
        grayBackground: '#f4f4f9',
        sourcingContainer: '#fff',
        newNotificationBackground: '#f4f4f9',
        navBarIconColor: '#4C4F53',
        rippleColor: '#EFEFF1',
        logoColor: '#000246',
        primary: {
            main: '#5D64FC',
            dark: '#5D64FC',
            light: '#6bbafa',
        },
        secondary: {
            main: '#eef3ff',
            dark: '#6bbafa',
        },
        // secondary: {
        //     main: '#31394D',
        // },
        textColor: {
            primary: '#7D8DA6', // text
            secondary: '#7e8cab',
            tertiary: '#505050',
            quaternary: '#F2F0F9',
            selected: '#fff',
            errorColor: '#D74242',
            variantColor: '#505050',
            variantBox: '#505050',
        },
    },
});
// eslint-disable-next-line no-unused-vars
customTheme = createTheme(customTheme, {
    typography: {
        fontFamily: 'Roboto',
        subtitle2: {
            // color: '#505050',
            color: customTheme.palette.textColor.tertiary,
            fontWeight: 600,
            fontSize: '14px',
            // marginBottom: '30px',
        },
        subtitle1: {
            color: customTheme.palette.textColor.tertiary,
        },
        h4: {
            // color: '#505050',
            color: customTheme.palette.textColor.tertiary,
            fontWeight: 600,
            fontSize: '20px',

            margin: '10px 0px',
        },
        body1: {
            // color: '#7e8cab',
            color: customTheme.palette.textColor.primary,
            fontWeight: 500,
            fontSize: '14px',
            margin: '10px 0px',
        },
        body2: {
            // color: '#7e8cab',
            color: customTheme.palette.textColor.primary,
            fontWeight: 500,
            fontSize: '12px',
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    background: customTheme.palette.primaryBackground,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: customTheme.palette.primaryBackground,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: customTheme.palette.secondaryBackground,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    backgroundColor: customTheme.palette.secondaryBackground,
                },
            },
        },
    },
});
export default customTheme;
