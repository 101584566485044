/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Box, Select, FormControl, InputLabel, MenuItem, TextareaAutosize, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Button, ToggleSwitch } from 'flowbite-react';
import InputField from 'components/inputField/InputField';
import CustomError from 'components/customError/CustomError';
import { addStoreSchema } from 'utils/validations/storeValidation';
import { addStore, getStores, updateStore } from 'services/stores';
import { LabelStyling } from 'components/inputField/InputField.styles';
import { getCurrentUser } from 'utils/helpers/auth';
import { getCustomer } from 'services/customer';
import { AiOutlineClose } from 'react-icons/ai';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import GeneralButton from 'components/customButton/generalButton';
import toast from 'react-hot-toast';
import { StoreDrawerProps } from './StoreDrawer.types';

const StoreDrawer: React.FC<StoreDrawerProps> = ({ open, setOpen, submitFunction, store, setStores, funnels }) => {
    const [storeTypeStatus, setStoreTypeStatus] = useState<any>();
    const [customer, setCustomer] = useState<ICustomer>();
    const usr = getCurrentUser();
    useEffect(() => {
        getCustomer(usr?._id)
            .then((res: any) => {
                setCustomer(res.data);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, []);

    const [submitLoader, setSubmitLoader] = useState(false);
    return (
        <Box>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div className="px-5 py-5 md:w-96 w-screen ">
                    <div className="flex  justify-end">
                        <Button
                            color="gray"
                            theme={{
                                color: {
                                    gray: 'text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-900 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700 :ring-cyan-700 focus:text-cyan-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700 focus:ring-2',
                                },
                            }}
                            onClick={() => setOpen(false)}
                        >
                            <AiOutlineClose />
                        </Button>
                    </div>
                    <Formik
                        initialValues={{
                            storeName: store ? store.storeName : '',
                            storeType: store ? store.storeType : '',
                            storeLink: store ? store.storeLink : '',
                            offer: store ? store.offer : '',
                            accessToken: store ? store.accessToken : '',
                            funnel: store ? store.funnel : '',
                            withCC: store && store.withCC !== undefined ? store.withCC : true, // defaulting to true
                        }}
                        onSubmit={(values) => {
                            setSubmitLoader(true);
                            if (submitFunction === 'update') {
                                if (values.storeType === 'other') {
                                    updateStore(store.id, {
                                        storeName: values.storeName,
                                        storeLink: values.storeLink,
                                        offer: values.offer,
                                        accessToken: '__',
                                        withCC: values.withCC,
                                    })
                                        .then(() => {
                                            toast.success('Store Updated Successfully');
                                            setOpen(false);
                                            setSubmitLoader(false);
                                        })
                                        .then(() => {
                                            getStores({ page: 1, limit: 100 }, {}).then((res) => {
                                                setStores(res.data.docs);
                                            });
                                        })
                                        .catch((err) => {
                                            toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
                                            // setNotifType('error');
                                            // setNotifMessage(
                                            //     err?.response?.data?.errors?.message || 'Something went wrong',
                                            // );
                                            // setOpenNotif(true);
                                            setSubmitLoader(false);
                                        });
                                } else if (values.storeType === 'lightfunnels') {
                                    updateStore(store.id, {
                                        storeName: values.storeName,
                                        storeLink: values.storeLink,
                                        offer: values.offer,
                                        funnel: values.funnel,
                                        accessToken: '__',
                                        withCC: values.withCC,
                                    })
                                        .then(() => {
                                            toast.success('Store Updated Successfully');
                                            // setNotifType('success');
                                            // setNotifMessage('Store Updated Successfully');
                                            // setOpenNotif(true);
                                            setOpen(false);
                                            setSubmitLoader(false);
                                        })
                                        .then(() => {
                                            getStores({ page: 1, limit: 100 }, {}).then((res) => {
                                                setStores(res.data.docs);
                                            });
                                        })
                                        .catch((err) => {
                                            toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
                                            // setNotifType('error');
                                            // setNotifMessage(
                                            //     err?.response?.data?.errors?.message || 'Something went wrong',
                                            // );
                                            // setOpenNotif(true);
                                            setSubmitLoader(false);
                                        });
                                } else {
                                    updateStore(store.id, {
                                        storeName: values.storeName,
                                        storeType: values.storeType,
                                        storeLink: values.storeLink,
                                        accessToken: values.accessToken,
                                        withCC: values.withCC,
                                    })
                                        .then(() => {
                                            toast.success('Store Updated Successfully');
                                            // setNotifType('success');
                                            // setNotifMessage('Store Updated Successfully');
                                            // setOpenNotif(true);
                                            setOpen(false);
                                            setSubmitLoader(false);
                                        })
                                        .then(() => {
                                            getStores({ page: 1, limit: 100 }, {}).then((res) => {
                                                setStores(res.data.docs);
                                            });
                                        })
                                        .catch((err) => {
                                            toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
                                            // setNotifType('error');
                                            // setNotifMessage(
                                            //     err?.response?.data?.errors?.message || 'Something went wrong',
                                            // );
                                            // setOpenNotif(true);
                                            setSubmitLoader(false);
                                        });
                                }
                            } else if (submitFunction === 'add') {
                                if (values.storeType === 'other') {
                                    addStore({
                                        storeName: values.storeName,
                                        storeType: values.storeType,
                                        storeLink: values.storeLink,
                                        fulfillmentProcessing: 'manual',
                                        riskyOrderProcessing: 'yes',
                                        partiallyFulfilledProcessing: 'manual',
                                        deliveryLevels: 'fast',
                                        offer: values.offer,
                                        withCC: values.withCC,
                                    })
                                        .then(() => {
                                            toast.success('Store Added Successfully');
                                            // setNotifType('success');
                                            // setNotifMessage('Store Added Successfully');
                                            // setOpenNotif(true);
                                            setOpen(false);
                                            setSubmitLoader(false);
                                        })
                                        .then(() => {
                                            getStores({ page: 1, limit: 100 }, {}).then((res) => {
                                                setStores(res.data.docs);
                                            });
                                        })
                                        .catch((err) => {
                                            toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
                                            // setNotifType('error');
                                            // setNotifMessage(
                                            //     err?.response?.data?.errors?.message || 'Something went wrong',
                                            // );
                                            // setOpenNotif(true);
                                            setSubmitLoader(false);
                                        });
                                } else if (values.storeType === 'lightfunnels') {
                                    addStore({
                                        storeName: values.storeName,
                                        storeType: values.storeType,
                                        storeLink: values.storeLink,
                                        fulfillmentProcessing: 'manual',
                                        riskyOrderProcessing: 'yes',
                                        partiallyFulfilledProcessing: 'manual',
                                        deliveryLevels: 'fast',
                                        offer: values.offer,
                                        funnel: String(values.funnel),
                                        withCC: values.withCC,
                                    })
                                        .then(() => {
                                            toast.success('Store Added Successfully');
                                            // setNotifType('success');
                                            // setNotifMessage('Store Added Successfully');
                                            // setOpenNotif(true);
                                            setOpen(false);
                                            setSubmitLoader(false);
                                        })
                                        .then(() => {
                                            getStores({ page: 1, limit: 100 }, {}).then((res) => {
                                                setStores(res.data.docs);
                                            });
                                        })
                                        .catch((err) => {
                                            toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
                                            // setNotifType('error');
                                            // setNotifMessage(
                                            //     err?.response?.data?.errors?.message || 'Something went wrong',
                                            // );
                                            // setOpenNotif(true);
                                            setSubmitLoader(false);
                                        });
                                } else {
                                    addStore({
                                        storeName: values.storeName,
                                        storeType: values.storeType,
                                        storeLink: values.storeLink,
                                        accessToken: values.accessToken,
                                        fulfillmentProcessing: 'manual',
                                        riskyOrderProcessing: 'yes',
                                        partiallyFulfilledProcessing: 'manual',
                                        deliveryLevels: 'fast',
                                        offer: values.offer,
                                        withCC: values.withCC,
                                    })
                                        .then(() => {
                                            toast.success('Store Added Successfully');
                                            // setNotifType('success');
                                            // setNotifMessage('Store Added Successfully');
                                            // setOpenNotif(true);
                                            setOpen(false);
                                            setSubmitLoader(false);
                                        })
                                        .then(() => {
                                            getStores({ page: 1, limit: 100 }, {}).then((res) => {
                                                setStores(res.data.docs);
                                            });
                                        })
                                        .catch((err) => {
                                            toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
                                            // setNotifType('error');
                                            // setNotifMessage(
                                            //     err?.response?.data?.errors?.message || 'Something went wrong',
                                            // );
                                            // setOpenNotif(true);
                                            setSubmitLoader(false);
                                        });
                                }
                            }
                        }}
                        validationSchema={addStoreSchema(storeTypeStatus)}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <div className="mb-4">
                                    <InputField
                                        label="Store name"
                                        placeholder="Store name"
                                        // returns onChange, onBlur and value related to this input field
                                        {...formik.getFieldProps('storeName')}
                                        name="storeName"
                                    />
                                    <CustomError name="storeName" component="Box" />
                                    <FormControl fullWidth>
                                        {submitFunction === 'add' && (
                                            <>
                                                <InputLabel id="demo-simple-select-label">Store type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Store type"
                                                    placeholder="store type"
                                                    {...formik.getFieldProps('storeType')}
                                                    name="storeType"
                                                    onChange={(event) => {
                                                        formik.setFieldValue('storeType', event.target.value);
                                                        setStoreTypeStatus(event.target.value);
                                                    }}
                                                >
                                                    {/* ⌛ ⌛ Store Type: Shopify type not supported yet This section will be hidden for now uncomment the code below when needed ⌛ ⌛ */}
                                                    {/* <MenuItem value="shopify">shopify</MenuItem> */}
                                                    {customer?.lightFunnelsId && customer?.lightFunnelsToken ? (
                                                        <MenuItem value="lightfunnels">Lightfunnels</MenuItem>
                                                    ) : (
                                                        <MenuItem value="lightfunnels" disabled>
                                                            Lightfunnels(Lightfunnels integration required)
                                                        </MenuItem>
                                                    )}
                                                    <MenuItem value="other">Other</MenuItem>
                                                </Select>
                                                <CustomError name="storeType" component="Box" />
                                            </>
                                        )}
                                    </FormControl>
                                    {storeTypeStatus === 'shopify' || formik.values.storeType === 'shopify' ? (
                                        <Box>
                                            <InputField
                                                label="Store link"
                                                placeholder="Store link"
                                                {...formik.getFieldProps('storeLink')}
                                                name="storeLink"
                                            />
                                            <CustomError name="storeLink" component="Box" />
                                            {storeTypeStatus === 'other' ? (
                                                ''
                                            ) : (
                                                <Box>
                                                    <InputField
                                                        placeholder="Access token"
                                                        label="Access token"
                                                        {...formik.getFieldProps('accessToken')}
                                                        name="accessToken"
                                                        disabled={storeTypeStatus === 'other'}
                                                    />
                                                    <CustomError name="accessToken" component="Box" />
                                                </Box>
                                            )}
                                        </Box>
                                    ) : storeTypeStatus === 'other' || formik.values.storeType === 'other' ? (
                                        <Box>
                                            <InputField
                                                label="store link"
                                                placeholder="Store link"
                                                {...formik.getFieldProps('storeLink')}
                                                name="storeLink"
                                            />
                                            <CustomError name="storeLink" component="Box" />
                                        </Box>
                                    ) : storeTypeStatus === 'lightfunnels' ||
                                      formik.values.storeType === 'lightfunnels' ? (
                                        <Box>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Funnels</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Funnels"
                                                    placeholder="Funnel"
                                                    {...formik.getFieldProps('funnel')}
                                                    name="funnel"
                                                    onChange={(event) => {
                                                        formik.setFieldValue('funnel', event.target.value);
                                                        // setStoreTypeStatus(event.target.value);
                                                    }}
                                                >
                                                    {funnels?.map((funnel: any) => (
                                                        // eslint-disable-next-line no-underscore-dangle
                                                        <MenuItem value={funnel?.node?._id}>
                                                            {funnel?.node?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <CustomError name="funnel" component="Box" />
                                            </FormControl>
                                            <InputField
                                                label="store link"
                                                placeholder="Store link"
                                                {...formik.getFieldProps('storeLink')}
                                                name="storeLink"
                                            />
                                            <CustomError name="storeLink" component="Box" />
                                        </Box>
                                    ) : (
                                        ''
                                    )}
                                    <Box>
                                        <Typography variant="body2" sx={LabelStyling}>
                                            Offer
                                        </Typography>
                                        <TextareaAutosize
                                            placeholder="Add your offer..."
                                            minRows={3}
                                            className="bg-background-secondary rounded-md w-full border-[#BFBFC1] dark:border-[#505050] "
                                            aria-label="minimum height"
                                            // returns onChange, onBlur and value related to this input field
                                            {...formik.getFieldProps('offer')}
                                            name="offer"
                                        />
                                    </Box>
                                    <div className="my-2">
                                        <ToggleSwitch
                                            disabled={!!store}
                                            onChange={() => {
                                                formik.setFieldValue('withCC', !formik.values.withCC);
                                            }}
                                            name="withCC"
                                            checked={formik.values.withCC}
                                            label="With call center"
                                        />
                                    </div>
                                </div>
                                {/* <CustomButton
                                    type="submit"
                                    className="btn"
                                    styleType="primary"
                                    label={submitFunction === 'update' ? 'Update Store' : 'Add Store'}
                                    sizeType="large"
                                /> */}
                                <div className="flex w-full  justify-end">
                                    <GeneralButton
                                        color="blue"
                                        type="submit"
                                        size="sm"
                                        disabled={formik.isSubmitting || submitLoader}
                                        isProcessing={submitLoader}
                                    >
                                        {submitFunction === 'update' ? 'Update Store' : 'Add Store'}
                                    </GeneralButton>{' '}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Drawer>
        </Box>
    );
};

export default StoreDrawer;
