import AttachFile from '@mui/icons-material/AttachFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import InputField from 'components/inputField/InputField';
import Modal from 'components/modal/Modal';
import DataTable from 'components/tables/tables';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { IFilter, IOrder, IPagination, getOrders, prepaidOrderStatusList } from 'services/order';
import { ResellerProductPricingHistory } from 'services/reseller/resellerProducts';
import { getCurrentStore } from 'services/stores';
import { IStore } from 'utils/Interfaces/stores.interface';
import { logistioCountries } from 'utils/countryList';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import {
    downloadCitiesListFile,
    downloadCountriesListFile,
    downloadPrePaidOrderTemplateFile,
} from 'utils/helpers/orders/downloadXLSXutilsFiles';
import { exportExcelFile } from 'utils/helpers/orders/exportOrder';
import { uploadPrePaidOrderFile } from 'utils/helpers/orders/uploadOrders/uploadPrepaidOrderFile';
import { primaryButtonSmall, primaryOutlinedBtnSmall } from 'utils/helpers/themeStyle/buttons';
import { WorkSheet } from 'xlsx';
import ConfirmInvalidOrders from './ConfirmInvalidOrders';
import { prePaidOrderColumns } from './OrderElements';
import OrderUpload from './OrdersUpload';
import UnseenPricingUpdatesModal from './UnseenPricingUpdatesModal';
import { IdateRange } from './orders.types';

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

const PrepaidOrders = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [showConfirmationUi, setShowConfirmationUi] = useState(false);
    const [parsedOrders, setParsedOrders] = useState<{
        withInvalidCities: IOrder[];
        withFailedValidation: IOrder[];
        valid: IOrder[];
    } | null>(null);
    const [uploadCounter, setUploadCounter] = useState({ errors: 0, success: 0, total: 0 });
    const [failedOrders, setFailedOrders] = useState<WorkSheet>([]);
    const [uploadErrors, setUploadErrors] = useState(
        new Map<string, { orderCustomerFullName: string; errors: string[] }>(),
    );
    const [fileName, setFileName] = useState('');
    const [storeType, setStoreType] = useState<IStore['storeType'] | null>(null);
    const [ordersSelected, setOrdersSelected] = useState<IOrder[]>([]);
    useEffect(() => {
        getCurrentStore().then((res) => {
            if (res) {
                setStoreType(res.storeType);
            }
            // this page is unaccessible when call center is active in the store
            if (res?.withCC !== false) {
                navigate('/');
            }
        });
    }, []);

    const [ordersList, setOrdersList] = useState<IOrder[]>([]);
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        limit: 10,
    });
    const [rangevalue, setRangeValue] = useState<IdateRange>({
        startDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2).toDateString(),
        endDate: new Date().toDateString(),
    });
    const storeId = localStorage.getItem('storeId');
    const [filter, setFilter] = useState<IFilter>({ store: storeId! });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    const handleRangeFilterChange = (newValue: IdateRange | null) => {
        if (newValue) {
            let newRange: typeof newValue | undefined;
            if (newValue.startDate && newValue.endDate) {
                const startD = moment(newValue.startDate);
                const endD = moment(newValue.endDate);
                endD.set('hour', 23);
                endD.set('minute', 59);
                endD.set('second', 59);
                startD.set('hour', 0);
                startD.set('minute', 0);
                startD.set('second', 0);
                newRange = {
                    startDate: startD.format(),
                    endDate: endD.format(),
                };
            }
            setRangeValue(newValue);
            setFilter((oldFilter) => ({ ...oldFilter, dateRange: newRange }));
            setPagination({ ...pagination, page: 1 });
            setMeta({ ...meta, page: -1 });
        }
    };

    // fetch orders on component mount & pagination or filter change
    useEffect(() => {
        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        if (!fetchedAndActive) {
            setIsLoading(true);
            getOrders(pagination, { ...filter, prePaid: true })
                .then((res) => {
                    const { data } = res;
                    setOrdersList(data.docs);

                    setMeta({
                        hasNextPage: data.hasNextPage,
                        hasPrevPage: data.hasPrevPage,
                        limit: data.limit,
                        nextPage: data.nextPage,
                        page: data.page,
                        pagingCounter: data.pagingCounter,
                        prevPage: data.prevPage,
                        totalDocs: data.totalDocs,
                        totalPages: data.totalPages,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(
                        err?.response?.data?.errors?.message ||
                            'Something went wrong when displaying orders, please try again!',
                    );
                });
        }
    }, [filter, pagination]);

    const handlePageChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleStatusFilterChange = (newFilter: IFilter) => {
        if (newFilter.status === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.status;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setPagination((prev) => ({ ...prev, page: 1 }));
        setMeta({ ...meta, page: -1 });
    };

    const handleCountryFilterChange = (newFilter: IFilter) => {
        if (newFilter.country === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.country;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setPagination((prev) => ({ ...prev, page: 1 }));
        setMeta({ ...meta, page: -1 });
    };

    const handleNameFilterChange = (newFilter: IFilter) => {
        if (newFilter.fullName === '' || newFilter.fullName === null) {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.fullName;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setPagination((prev) => ({ ...prev, page: 1 }));
        setMeta({ ...meta, page: -1 });
    };

    // const handleTrackingCodeFilterChange = (newFilter: IFilter) => {
    //     if (newFilter.trackingCode === '') {
    //         setFilter((oldFilter) => {
    //             const temp = oldFilter;
    //             delete temp.trackingCode;
    //             return { ...temp };
    //         });
    //     } else {
    //         setFilter({ ...filter, ...newFilter });
    //     }
    //     setPagination((prev) => ({ ...prev, page: 1 }));
    //     setMeta({ ...meta, page: -1 });
    // };

    const handleOrderRefFilterChange = (newFilter: IFilter) => {
        if (newFilter.orderRef === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.orderRef;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setPagination((prev) => ({ ...prev, page: 1 }));
        setMeta({ ...meta, page: -1 });
    };

    // const handleSelectOrder = (data: string[]) => {
    //     let selectedOrders: any = [];
    //
    //     ordersList.forEach((order) => {
    //         data.forEach((id) => {
    //             // eslint-disable-next-line no-underscore-dangle
    //             if (order._id === id) {
    //                 selectedOrders = [...selectedOrders, order];
    //             }
    //         });
    //     });
    //     setSelectedForExport([...selectedOrders]);
    // };

    const refetchOrders = () => {
        getOrders({ page: 1, limit: pagination.limit }, { store: storeId!, prePaid: true })
            .then((res) => {
                const { data } = res;
                setOrdersList(data.docs);

                setMeta({
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setPagination({ limit: data.limit, page: data.page });
                setFilter({ store: storeId!, cod: false });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(
                    err?.response?.data?.errors?.message ||
                        err?.response?.data?.validation?.body?.message ||
                        'Something went wrong when displaying orders, please try again!',
                );
            });
    };
    const [updatedPricing, setUpdatedPricing] = useState<ResellerProductPricingHistory[]>([]);
    const [files, setFiles] = useState<FileList | null>(null);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const handleOrderUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setFiles(e.target.files);
        await uploadPrePaidOrderFile(
            e.target.files,
            setOpenModal,
            setUploadCounter,
            setFailedOrders,
            setUploadErrors,
            setFileName,
            setShowConfirmationUi,
            setParsedOrders,
            setUpdatedPricing,
            refetchOrders,
        );
    };
    const onProceed = async (ordersFiles: typeof files) => {
        await uploadPrePaidOrderFile(
            ordersFiles,
            setOpenModal,
            setUploadCounter,
            setFailedOrders,
            setUploadErrors,
            setFileName,
            setShowConfirmationUi,
            setParsedOrders,
            setUpdatedPricing,
            refetchOrders,
        );
    };

    useEffect(() => {
        setOpenNotificationModal(() => updatedPricing.length > 0);
    }, [updatedPricing]);
    DynamicTitle('Prepaid Orders');

    return (
        <Box>
            <UnseenPricingUpdatesModal
                show={openNotificationModal}
                onProceed={async () => {
                    setUpdatedPricing([]);
                    await onProceed(files);
                }}
                onAbort={() => {
                    setOpenModal(false);
                    setUpdatedPricing([]);
                }}
                onClose={() => {
                    setOpenNotificationModal(false);
                }}
                updatedPricing={updatedPricing}
            />
            <Modal
                open={openModal}
                setOpen={setOpenModal}
                body={
                    showConfirmationUi ? (
                        <ConfirmInvalidOrders
                            setCounter={setUploadCounter}
                            setErrors={setUploadErrors}
                            store={storeId!}
                            setFailedOrders={setFailedOrders}
                            setParsedOrders={setParsedOrders}
                            onUploadEnd={refetchOrders}
                            setShowConfirmationUi={setShowConfirmationUi}
                            parsedOrders={parsedOrders}
                        />
                    ) : (
                        <OrderUpload
                            uploadErrors={uploadErrors}
                            failedOrders={failedOrders}
                            uploadCounter={uploadCounter}
                            fileName={fileName}
                        />
                    )
                }
            />
            {/* Export and Upload orders */}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
                    {storeType && storeType.toLowerCase() === 'other' && (
                        <>
                            {/* Upload orders */}
                            <button type="button" className={primaryOutlinedBtnSmall}>
                                <label
                                    htmlFor="file"
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                >
                                    <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        hidden
                                        accept=".xlsx, .csv"
                                        multiple={false}
                                        onChange={(e) => handleOrderUpload(e)}
                                    />
                                    <AttachFile />
                                    Upload orders
                                </label>
                            </button>
                            {/* Download order file template */}
                            <button
                                type="button"
                                className={primaryOutlinedBtnSmall}
                                onClick={downloadPrePaidOrderTemplateFile}
                            >
                                <FileDownloadIcon />
                                Download orders template
                            </button>
                            {/* Download countries list file */}
                            <button
                                type="button"
                                className={primaryOutlinedBtnSmall}
                                onClick={downloadCountriesListFile}
                            >
                                <FileDownloadIcon />
                                Download countries list
                            </button>
                            {/* Download cities list file */}
                            <button type="button" className={primaryOutlinedBtnSmall} onClick={downloadCitiesListFile}>
                                <FileDownloadIcon />
                                Download cities list
                            </button>
                        </>
                    )}
                </Box>
                {/* Export orders */}
                <button
                    type="button"
                    className={primaryButtonSmall}
                    disabled={ordersSelected.length < 1}
                    onClick={() => {
                        exportExcelFile(ordersSelected);
                    }}
                >
                    <FileDownloadIcon />
                    <span className="sr-only">Notifications</span>
                    Export orders
                    {ordersSelected.length > 0 ? (
                        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                            {ordersSelected.length}
                        </div>
                    ) : null}
                </button>
            </Box>

            {/* Orders filters */}
            <div className="flex justify-between flex-wrap ">
                <div className="flex gap-1 flex-wrap">
                    <InputField
                        placeholder="Search by client name"
                        InputType="search"
                        inputSize="small"
                        onChange={(e) => {
                            // handleTrackingCodeFilterChange({ trackingCode: e.target.value });
                            handleNameFilterChange({ fullName: e.target.value });
                        }}
                    />

                    {/* <InputField
                        placeholder="Search by Tracking Number"
                        InputType="search"
                        inputSize="small"
                        onChange={(e) => {
                            handleTrackingCodeFilterChange({ trackingCode: e.target.value });
                        }}
                    /> */}

                    <InputField
                        placeholder="Search by order ref"
                        InputType="search"
                        inputSize="small"
                        onChange={(e) => {
                            handleOrderRefFilterChange({ orderRef: e.target.value });
                        }}
                    />
                </div>
                <div className="flex items-center gap-1 flex-wrap">
                    <FormControl sx={{ minWidth: 110 }} size="small">
                        <InputLabel id="status-filter-label">Status</InputLabel>
                        <Select
                            labelId="status-filter-label"
                            id="status-filter-select"
                            value={filter.status?.toString()}
                            label="Status"
                            onChange={(e) => {
                                handleStatusFilterChange({ status: e.target.value.toString() });
                            }}
                            MenuProps={{
                                MenuListProps: {
                                    style: {
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        margin: '0px',
                                    },
                                },
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        margin: '1px 0px',
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">All status</MenuItem>
                            {prepaidOrderStatusList.map((status) => {
                                return (
                                    <MenuItem key={status.id} value={status.id}>
                                        {status.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 110 }} size="small">
                        <InputLabel id="countries-filter-label">Countries</InputLabel>
                        <Select
                            labelId="countries-filter-label"
                            id="countries-filter-select"
                            value={filter.country?.toString()}
                            label="Countries"
                            onChange={(e) => {
                                handleCountryFilterChange({ country: e.target.value });
                            }}
                            MenuProps={{
                                MenuListProps: {
                                    style: {
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        margin: '0px',
                                    },
                                },
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        margin: '1px 0px',
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">All Countries</MenuItem>
                            {logistioCountries.map((country) => {
                                return (
                                    <MenuItem key={country.code} value={country.label}>
                                        {country.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Datepicker
                        inputClassName="w-full  border-gray-300 py-2 pl-4 pr-10 font-light text-sm rounded-md focus:ring-0 font-normal bg-custom-background dark:border-slate-600 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20"
                        inputId="deliveryDate"
                        value={rangevalue}
                        containerClassName="relative z-20"
                        useRange
                        onChange={handleRangeFilterChange}
                    />
                </div>
            </div>
            {/* Table */}
            <DataTable
                rows={ordersList}
                setRows={setOrdersList}
                columns={prePaidOrderColumns}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePageChange}
                filterState={filter}
                setRowsSelected={setOrdersSelected}
                selectable
            />
        </Box>
    );
};

export default PrepaidOrders;
