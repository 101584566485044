export const walletContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
};
export const leftContainer = {
    minHeight: '79vh',
    // display: 'flex',
    // alignContent: 'space-between',
};
export const amountContainer = {};
export const leftbottomContainer = {};
export const rightContainer = {};

export const walletCardContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    border: 'none',
    padding: '0px',
    '&.MuiCardContent-root': {
        padding: '0px',
    },
};

export const historyCardsContainer = {
    minHeight: '65vh',
    backgroundColor: 'transparent',
    '&.MuiCardContent-root': {
        padding: '5px 5px 0px 5px',
    },
};

export const historyCards = {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr 0.5fr 1fr ',
    alignItems: 'center',
    textAlign: 'center',
};

export const historyCardContainer = {
    border: 'none',
    '&.MuiCardContent-root': {
        padding: '5px',
    },
};

export const cardsBox = {
    overflowY: 'auto',
    // justifyContent: 'center',
    height: '64vh',
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
};

export const verificationCard = {
    border: 'none',
};

export const dateAmount = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};
export const dateTime = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const renderOpSatatusColor = (param: number) => {
    switch (param) {
        case 1:
            return '#578aff';
        case 2:
            return '#93c66e';
        case 3:
            return '#ff7777';
        default:
            return '#578aff';
    }
};

export const statusColor = (label: number) => {
    return {
        color: renderOpSatatusColor(label),
    };
};

export const filtersContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 1,
};
