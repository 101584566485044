/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box, Tooltip } from '@mui/material';
import { MenuItemProps } from './MenuItem.types';
import { StyledItem, labelStyle, LinkStyle, ListItemStyle, roundedIcon, ThisStyle } from './MenuItem.styles';

const MenuItem: React.FC<MenuItemProps> = ({ collapsed, path, icon, label, sub }) => {
    const { pathname } = useLocation();
    const [hovered, setHovered] = React.useState(false);

    const handleHover = () => {
        setHovered(true);
    };

    const handleLeave = () => {
        setHovered(false);
    };

    const isItSelected = pathname.split('/')[1] === `/${path}`.split('/')[1];

    // const TipTool = ({ children }: { children: any }) => {
    //     if (collapsed) {
    //         return (
    //             <Tooltip title={label as string} placement="right" key={path + label}>
    //                 {children}
    //             </Tooltip>
    //         );
    //     }
    //     return children;
    // };

    return (
        <Link to={path} style={LinkStyle}>
            {collapsed ? (
                <Tooltip title={label as string} placement="right" key={path + label}>
                    <StyledItem
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                        collapsed={collapsed}
                        sub={sub}
                        selected={isItSelected}
                    >
                        <Box sx={ListItemStyle(collapsed!)}>
                            <Box sx={roundedIcon(isItSelected, hovered)}>
                                {/* <ListItemIcon sx={thisStyle(pathname === `/${path}`, hovered)}>{icon}</ListItemIcon> */}
                                <ThisStyle hovered={hovered} selected={isItSelected} iconName={label}>
                                    {icon}
                                </ThisStyle>
                            </Box>

                            {!collapsed && <Box sx={labelStyle()}>{label}</Box>}
                        </Box>

                        {/* {notification && <Box sx={notifStyle}>{notification}</Box>} */}
                    </StyledItem>
                </Tooltip>
            ) : (
                <StyledItem
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    collapsed={collapsed}
                    sub={sub}
                    selected={isItSelected}
                >
                    <Box sx={ListItemStyle(collapsed!)}>
                        <Box sx={roundedIcon(isItSelected, hovered)}>
                            {/* <ListItemIcon sx={thisStyle(pathname === `/${path}`, hovered)}>{icon}</ListItemIcon> */}
                            <ThisStyle hovered={hovered} selected={isItSelected} iconName={label}>
                                {icon}
                            </ThisStyle>
                        </Box>

                        {!collapsed && <Box sx={labelStyle()}>{label}</Box>}
                    </Box>

                    {/* {notification && <Box sx={notifStyle}>{notification}</Box>} */}
                </StyledItem>
            )}
        </Link>
    );
};

export default MenuItem;
