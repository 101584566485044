import { IdateRange } from 'pages/orders/orders.types';
import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IStore } from 'utils/Interfaces/stores.interface';
import { IPaginationData } from 'utils/Interfaces/types';
import { IUser } from 'utils/Interfaces/user.interface';
// TODO: add missing types declarations for `logistioVariant` & `customer` & `store`
export interface IOrder {
    _id: string;
    product: IProduct;
    logistioVariant: Object;
    store: string | IStore;
    customer: Object | ICustomer;
    externalOrderId?: string;
    address1?: string;
    address2?: string;
    city?: string;
    company?: string;
    country: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    phone?: string;
    province?: string;
    zip?: string;
    provinceCode?: string;
    countryCode?: string;
    cancelReason?: string;
    currency: string;
    totalPrice: number;
    items?: {
        product: string | IProduct;
        quantity?: number;
        upsellQuantity?: number;
    }[];
    shipmentLineStatus?: string;
    status: number;
    shipmentLineOrderId?: string;
    trackingCode?: string;
    createdAt: string;
    updatedAt: string;
    callCenterFeedback?: string[];
    callCenterAttempt?: number;
    cod: boolean;
    prePaid: boolean;
    actionReason?: number;
    orderRef?: string;
    statusDescription?: any;
    deliveryDate: Date;
    withCC: boolean;
    lastTimeAssigned?: Date;
    callAgent?: string | IUser;
    lgstTrackingCode?: string;
}
export interface IOrderHistory {
    _id: string;
    order: string & IOrder;
    oldStatus: number;
    newStatus: number;
    onModel: string;
    doneBy: string;
    createdAt: string;
}
export interface IxlsxOrder {
    address1: string;
    address2: string;
    city: string;
    company?: string;
    country: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    phone: string;
    province: string;
    provinceCode: string;
    zip: string;
    sku?: string;
    quantity?: number;
    totalPrice: number;
    currency: string;
    orderRef?: string;
}

export interface IOrderStatus {
    id: number;
    label: string;
}

// export const orderStatusList: IOrderStatus[] = [
//     { id: 1, label: 'New' },
//     { id: 2, label: 'Canceled' },
//     { id: 3, label: 'Unreachable' },
//     { id: 4, label: 'Confirmed' },
//     { id: 5, label: 'Waiting for shipment' },
//     { id: 6, label: 'Out for shipment' },
//     { id: 7, label: 'Shipped' },
//     { id: 99, label: 'Check required' },
//     { id: 8, label: 'Pick and pack' },
//     { id: 9, label: 'Out for delivery' },
//     { id: 10, label: 'Delivery attempted' },
//     { id: 11, label: 'Delivery failed' },
//     { id: 12, label: 'Delivered' },
//     { id: 13, label: 'Expired' },
// ];

export const orderStatusList: IOrderStatus[] = [
    { id: 1, label: 'New' },
    { id: 14, label: 'Invalid' },
    { id: 2, label: 'Canceled' },
    { id: 21, label: 'Wrong number' },
    { id: 3, label: 'Unreachable' },
    { id: 4, label: 'Confirmed' },
    { id: 43, label: 'Scheduled call' },
    { id: 41, label: 'Pending answer' },
    { id: 42, label: 'Question answered' },
    { id: 13, label: 'Expired' },
    // { id: 5, label: 'Waiting for shipment' },
    // { id: 6, label: 'Out for shipment' },
    // { id: 7, label: 'Shipped' },
    { id: 99, label: 'Check required' },
    { id: 8, label: 'Pick and pack' },
    { id: 81, label: 'Order received by carrier' },
    { id: 82, label: 'Arrive to headquarters warehouse' },
    { id: 83, label: 'Order transferred' },
    { id: 84, label: 'Arrival at Hub' },

    { id: 9, label: 'Out for delivery' },
    { id: 10, label: 'Delivery attempted' },
    { id: 101, label: 'Order returned to Hub' },
    { id: 102, label: 'Order returned to Warehouse' },
    { id: 103, label: 'New delivery request' },
    { id: 104, label: 'Wait to WMS' },
    { id: 105, label: 'Delivery scheduled' },

    { id: 11, label: 'Delivery failed' },
    { id: 111, label: 'Return to origin - Out' },
    { id: 112, label: 'Return to origin - In' },
    { id: 113, label: 'Transport management system to Warehouse' },
    { id: 114, label: 'Back to stock' },

    { id: 12, label: 'Delivered' },
    { id: 100, label: 'Hold - No stock available' },
];

export const prepaidOrderStatusList: IOrderStatus[] = [
    { id: 99, label: 'Check required' },
    { id: 8, label: 'Pick and pack' },
    { id: 81, label: 'Order received by carrier' },
    { id: 82, label: 'Arrive to headquarters warehouse' },
    { id: 83, label: 'Order transferred' },
    { id: 84, label: 'Arrival at Hub' },

    { id: 9, label: 'Out for delivery' },
    { id: 10, label: 'Delivery attempted' },
    { id: 101, label: 'Order returned to Hub' },
    { id: 102, label: 'Order returned to Warehouse' },
    { id: 103, label: 'New delivery request' },
    { id: 104, label: 'Wait to WMS' },

    { id: 11, label: 'Delivery failed' },
    { id: 111, label: 'Return to origin - Out' },
    { id: 112, label: 'Return to origin - In' },
    { id: 113, label: 'Transport management system to Warehouse' },
    { id: 114, label: 'Back to stock' },

    { id: 12, label: 'Delivered' },
    { id: 100, label: 'Hold - No stock available' },
];
export type IaddOrder = Omit<Partial<IxlsxOrder>, 'sku' | 'quantity'> & {
    store: string;
    fullName: string;
    skus: { sku: string; quantity: number }[];
};

export type TPaginatedOrders = IPaginationData<IOrder>;

export interface IPagination {
    page: number;
    limit: number;
}

export interface IFilter {
    externalOrderId?: string;
    store?: string;
    createdAt?: string;
    fullName?: string;
    product?: string;
    status?: string;
    cod?: boolean;
    prePaid?: boolean;
    country?: string;
    _id?: string;
    firstName?: string;
    trackingCode?: string;
    dateRange?: IdateRange;
    orderRef?: string;
}
export interface IOrderTrace {
    _id: string;
    order: string | IOrder;
    traceId: number;
    status: string;
    logistioStatus: number;
    time: string;
    location: string;
    description: string;
}
export interface ICurrentStateStats {
    count: number;
    status: number;
    deliveryFailed?: boolean;
    deliveryAttempts?: number;
}
export interface IStatsByLeads {
    totalCount: number;
    unreachableCount: number;
    checkRequiredCount: number;
    holdNoStockCount: number;
    invalidCount: number;
    pendingAnswersCount: number;
    answeredCount: number;
    ScheduledCallCount: number;
    expiredCount: number;
    canceledCount: number;
    wrongNumberCount: number;
    stats: ICurrentStateStats[];
}
export interface IStatsByOrders {
    orders: ICurrentStateStats[];
}

export const getOrders = async (pagination: IPagination, filter: IFilter) => {
    const config = await getBearerToken();
    const response = await axios.post<TPaginatedOrders>(`/orders/filter`, { ...pagination, ...filter }, config);
    return response;
};

export const uploadOrder = async (data: IaddOrder, type: 'cod' | 'prePaid') => {
    const config = await getBearerToken();
    const response = await axios.post<{ message?: string; errors?: { message: string }; newOrderId: string }>(
        `/orders`,
        { ...data, [type]: true },
        config,
    );
    return response;
};

export const uploadOrders = async (orders: IaddOrder[], type: 'cod' | 'prePaid', storeId: string) => {
    const config = await getBearerToken();
    const response = await axios.post<{
        message?: string;
        errors?: { order: IaddOrder; message: string }[];
        uploadedOrdersIds: string[];
    }>(`/orders/multiple`, { orders, [type]: true, store: storeId }, config);
    return response;
};

export const getHistory = async (OrderId: string) => {
    const config = await getBearerToken();
    const orderHistory = await axios.get<IOrderHistory[]>(`/orders/history/${OrderId}`, config);
    return orderHistory;
};
export const getOrdersList = async (data: any) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<IOrder[]>(`/orders/all`, { ...data }, config);
    return orderHistory;
};

export const getCurrentStateStats = async (data: any) => {
    const config = await getBearerToken();
    const stats = await axios.post<ICurrentStateStats[]>(`/orders/stats`, { ...data }, config);
    return stats;
};
export const getStatsByLeads = async (data: any) => {
    const config = await getBearerToken();
    const stats = await axios.post<IStatsByLeads>(`/orders/statsbyleads`, { ...data }, config);
    return stats;
};
export const getStatsByOrders = async (data: any) => {
    const config = await getBearerToken();
    const stats = await axios.post<IStatsByOrders>(`/orders/statsbyorders`, { ...data }, config);
    return stats;
};
export const getStatsByCustomer = async (data: any) => {
    const config = await getBearerToken();
    const orderHistory = await axios.post<IOrderHistory[]>(`/stats/filterStats`, data, config);
    return orderHistory;
};
export const updateOrder = async (data: any) => {
    const config = await getBearerToken();

    const orderUpdate = await axios.put(`/orders/${data.id}`, data, config);
    return orderUpdate;
};

export const deleteOrder = async (orderId: string) => {
    const config = await getBearerToken();

    const orderUpdate = await axios.put<string>(`/orders/delete/${orderId}`, {}, config);
    return orderUpdate;
};
