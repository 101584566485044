/* eslint-disable no-underscore-dangle */
import { IPaginatedProduct, IProduct } from 'utils/Interfaces/products.interface';
import { IStore } from 'utils/Interfaces/stores.interface';
import axios from 'utils/axios/axios';
import { socket } from 'utils/socketIO';
import { getBearerToken, getCurrentUser } from '../utils/helpers/auth';
import { getCurrentStore } from './stores';

export interface ILFProductOptions {
    id?: string;
    label: string;
    value?: string;
    options?: Array<string>;
    option_types?: Array<any>;
}
export interface IOrderBump {
    id: string;
    title: string;
    price: number;
    sku: string;
    enabled: boolean;
    compare_at_price: number;
    description: string;
    image_uid: string;
    image?: {
        id: string;
        path: string;
    };
    file_uid: string;
}
export interface ILFVariant {
    id: string;
    _id: number;
    sku: string;
    title: string;
    image?: {
        id: string;
        path: string;
    };
    price?: number;
    compare_at_pricev: number;
    file_uid?: string;
    image_uid?: string;
    product_id?: number;
}
export interface TLFProduct {
    id: string;
    _id: number;
    title: string;
    price: number;
    sku: string;
    thumbnail: {
        path: string;
    } | null;
    options: ILFProductOptions;
    // NOTE: Check variants type when no variant is available (it's probably null)
    variants: ILFVariant[];
    order_bump: IOrderBump;
    created_at: string;
}
export interface IaddInput {
    product: string;
    externalProduct: object;
    logistioVariant?: object;
    externalVariant?: object;
    quantity?: number;
    externalSku: string;
    matchType: string;
    store: string;
    customer: string;
}
export type LG_CHECKED_VARIANT_MATCH = {
    product: Pick<IProduct, 'name' | '_id' | 'globalSKU' | 'design'>;
    variant: IProduct;
};

export type LF_CHECKED_VARIANT_MATCH = {
    product: Pick<TLFProduct, 'title' | '_id' | 'id' | 'sku' | 'thumbnail' | 'variants' | 'options'>;
    variant: TLFProduct['variants'][number];
};
export type LF_CHECKED_ORDER_BUMP_MATCH = {
    product: Pick<TLFProduct, 'title' | '_id' | 'id' | 'sku' | 'thumbnail' | 'variants' | 'options'>;
    order_bump: TLFProduct['order_bump'];
};
export type CHECKED_MATCH = {
    lgProduct: IProduct | LG_CHECKED_VARIANT_MATCH | null;
    lfProduct: TLFProduct | LF_CHECKED_VARIANT_MATCH | LF_CHECKED_ORDER_BUMP_MATCH | null;
    isLfVariant: boolean | null;
    isLfOrderBump: boolean | null;
    isLgVariant: boolean | null;
};
export type UNLINK_MATCH = {
    lfProduct: TLFProduct | LF_CHECKED_VARIANT_MATCH | LF_CHECKED_ORDER_BUMP_MATCH | null;
    isLfVariant: boolean | null;
    isLfOrderBump: boolean | null;
};

export interface Ipagination {
    page: number;
    limit: number;
    type?: number;
    sort?: string;
}
export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
export interface Ifilter {
    name?: string;
    description?: string;
    referenceLink?: string;
    status?: number;
    dateRange?: IdateRange;
    store?: IStore['_id'];
    includeReseller?: boolean;
    itemKind?: 'variant' | 'product';
    productPhase?: 'Processing' | 'Paid' | 'Rejected';
    search?: string;
    isRestock?: boolean;

    /**
     * `type` filter is handled by the pagination
     * ad its been used to represent tabulations
     * so no need to be included here
     */
}

export type PaginationAndFiltres = Ipagination & Ifilter;

export const addProduct = async (
    formData: any,
    progressConfig?: {
        // eslint-disable-next-line no-unused-vars
        onUploadProgress?: (ProgressEvent: any) => void;
        // eslint-disable-next-line no-unused-vars
        onDownloadProgress?: (ProgressEvent: any) => void;
    },
) => {
    // const config = await getBearerToken();

    const config:
        | {
              headers: {
                  Authorization: string;
              };
              // eslint-disable-next-line no-unused-vars
              onUploadProgress?: (ProgressEvent: any) => void;
              // eslint-disable-next-line no-unused-vars
              onDownloadProgress?: (ProgressEvent: any) => void;
          }
        | undefined = await getBearerToken();
    if (config && progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (config && progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }
    const response = await axios.post<{ message: string; productId?: string }>(`/products/`, formData, config);
    if (response.statusText === 'OK' && response.data.productId) {
        socket.emit('newProductCreated', {
            // eslint-disable-next-line no-underscore-dangle
            customer: getCurrentUser()._id,
            productId: response.data.productId,
            about: 12, // socket subjects matching available here: https://github.com/MartechLabs/logistio-erp-backend/blob/cb189ce20d6eda4ac6f7a7ab65092e903de62697/src/socketIO/utils.ts#L26
        });
    }

    // 🏷️ Return an axios response object w/out the product id as it's only needed for the socket
    delete response.data.productId;
    return response;
};

export const getProducts = async (pagination: Ipagination, filter: Ifilter) => {
    const storeData = await getCurrentStore();
    const payload = filter;
    if (storeData?._id) {
        payload.store = storeData._id;
    }
    const config = await getBearerToken();
    const response = await axios.post<{ response: IPaginatedProduct }>(
        `/products/filter`,
        { ...pagination, ...payload },
        config,
    );
    return response;
};

export const getProductsStockByCustomer = async () => {
    const config = await getBearerToken();
    const response = await axios.post<{ response: any }>(`/products/productstock`, {}, config);
    return response;
};

export const getProduct = async (id: string) => {
    const config = await getBearerToken();
    const response = await axios.get<{ product: IProduct }>(`/products/${id}`, config);
    return response;
};

// export const messageFileUpload = async (formData: any) => {
//     const config = await getBearerToken();
//     const response = await axios.post(`/commons/upload`, formData, config);
//     return response;
// };

export const messageFileUpload = async (
    formData: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const tokenResponse = await getBearerToken();
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = tokenResponse;
    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }

    const response = await axios.post(`/commons/upload`, formData, config);
    return response;
};

export const updateProduct = async (data: any) => {
    const config = await getBearerToken();
    const response = await axios.put(`/products/${data.id}`, data.updatedData, config);
    return response;
};

// 🏷️ Fetch a product with sku fields by its `id`
// NOTE: This probably is a duplicate of getProduct(id)
// -- Need to look into this and remove it if necessary
// -- also make sure to refactor components that depend on this like `productTableAction.tsx`
export const getProductWithSkus = async (productId: string) => {
    const config = await getBearerToken();
    const productResponse = await axios.get<{ product: IProduct }>(`/products/${productId}`, config);
    const { product } = productResponse.data;
    return product;
};

export const getLFProducts = async (lfStoreId?: string) => {
    const config = await getBearerToken();
    const response = await axios.post<TLFProduct[]>(
        `/integrations/lightfunnels/funnelProducts`,
        { funnel: lfStoreId },
        config,
    );

    return response;
};

export const unlinkLFProducts = async (checkedMatches: UNLINK_MATCH) => {
    const config = await getBearerToken();
    const response = await axios.post<any>(
        `/integrations/lightfunnels/unlinkProducts`,
        { matchedList: checkedMatches },
        config,
    );

    return response;
};

export const matchLFProducts = async (checkedMatches: CHECKED_MATCH) => {
    const config = await getBearerToken();
    const response = await axios.post<any>(
        `/integrations/lightfunnels/matchProducts`,
        { matchedList: checkedMatches },
        config,
    );

    return response;
};

export const resubmitProduct = async (productId: string) => {
    const config = await getBearerToken();
    const response = await axios.post<any>(`/products/resubmit`, { productId }, config);
    return response;
};
