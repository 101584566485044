/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/messageFile';
import MessageHeader from 'components/messageHeader/MessageHeader';
import MessageText from 'components/messageText/MessageText';
import React, { useEffect, useState } from 'react';
import { getProduct } from 'services/product';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';

import MessageInput from 'components/messageInput/MessageInput';
import { IProduct } from 'utils/Interfaces/products.interface';
import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts } from './chat.styles';

const ProductChat = ({ productId, chatId }: { productId: string; chatId: string }) => {
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [productInfo, setProductInfo] = useState<{ [key: string]: any }>({});
    const [totalMessages, setTotalMessages] = useState(0);
    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);
    useEffect(() => {
        // Creates a WebSocket connection

        socket.emit(
            'joinProductChat',
            JSON.stringify({
                product: productId,
                customer: getCurrentUser()._id,
            }),
        );

        socket.on('paginate-product-messages', (data: any) => {
            if (JSON.parse(data).totalDocs) setTotalMessages(JSON.parse(data).totalDocs);
            if (JSON.parse(data).limit) setLimit(JSON.parse(data).limit);
            if (JSON.parse(data).docs) {
                setConvMessages(JSON.parse(data).docs);
            }
            setCurrentRef('last');
        });

        socket.on('new-product-message', (data: any) => {
            if (JSON.parse(data).docs && (JSON.parse(data).docs as any[]).every((doc) => doc.chat === chatId)) {
                setConvMessages((conv: any) => [...JSON.parse(data).docs, ...conv]);
                if (getCurrentUser()._id !== JSON.parse(data).docs[0].to) {
                    socket.emit('newChatMessage', {
                        customer: getCurrentUser()._id,
                        about: 21, // new product message
                        customerId: getCurrentUser()._id,
                        messageId: JSON.parse(data).docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        getProduct(productId).then(async (res) => {
            // eslint-disable-next-line prefer-destructuring
            const product = res.data.product;
            let skus = '';

            if (product.globalSKU && !product.variants?.length) {
                skus = `${product.globalSKU}`;
            } else if (product.variants.length > 0) {
                product.variants.forEach((variant) => {
                    if ((variant as IProduct).globalSKU) {
                        skus += `[${(variant as IProduct).name}: ${(variant as IProduct).globalSKU}] `;
                    }
                });
            }

            setProductInfo({
                image: res.data.product.design,
                name: res.data.product.name,
                sku: skus,
                status: res.data.product.status,
                type: res.data.product.type,
                customer: res.data.product.customer,
                id: res.data.product._id,
                productInfo: product,
            });
        });
        return () => {
            socket.off('paginate-product-messages');
            socket.off('new-product-message');
        };
    }, [productId]);

    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);

    const [progressCount, setProgressCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayuploadingFile, setDisplayUploadingFile] = useState(false);

    useEffect(() => {
        setUploading(progressCount < 100 && progressCount > 0);
    }, [progressCount, displayuploadingFile]);

    return (
        <div className="h-[90vh] bg-background-secondary  ">
            {productInfo && (
                <MessageHeader
                    productImage={productInfo.image}
                    productTitle={productInfo.name}
                    sku={productInfo.sku}
                    status={productInfo.status}
                    type={productInfo.type}
                    productId={productInfo.id}
                    customerId={productInfo.customer}
                    productInfo={productInfo.productInfo}
                />
            )}

            <Divider />
            <div className="h-[calc(90vh-(50px+70px))] bg-background-secondary  overflow-hidden hover:overflow-y-scroll">
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateProductMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        product: productId,
                                        customer: getCurrentUser()._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}

                {currentRef === 'first' && <Box ref={myRef} />}
                {convMessages &&
                    convMessages
                        .slice(0)
                        .reverse()
                        .map((message: any) => (
                            <Box sx={MessageTexts} key={message._id}>
                                {message.message ? (
                                    <MessageText
                                        entity={message.from}
                                        message={message.message}
                                        received={message.onModelFrom === 'User'}
                                        timestamp={message.createdAt}
                                    />
                                ) : message.file ? (
                                    <MessageFile
                                        entity={message.from}
                                        file={message.file}
                                        timestamp={message.createdAt}
                                        received={message.onModelFrom === 'User'}
                                        fileName={message.fileName}
                                        fileSize={message.fileSize}
                                    />
                                ) : null}
                            </Box>
                        ))}
                {currentRef === 'last' && <Box ref={myRef} />}
                {uploading && displayuploadingFile && (
                    <Box sx={MessageTexts}>
                        <MessageFile
                            file="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                            received={false}
                            fileName={fileName}
                            fileSize={fileSize}
                            uploading={uploading}
                            progressCount={progressCount}
                        />
                    </Box>
                )}
            </div>

            <div className=" w-full">
                <MessageInput
                    socketRef={socket}
                    productId={productId}
                    action="newProductMessage"
                    setProgressCount={setProgressCount}
                    setFileName={setFileName}
                    setFileSize={setFileSize}
                    setDisplayUploadingFile={setDisplayUploadingFile}
                />
            </div>
        </div>
    );
};

export default ProductChat;
