/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import Tablesku from 'components/listSku/TableSku';
import { Dropdown, Modal } from 'flowbite-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiChat, HiDotsVertical } from 'react-icons/hi';
import { IoIosBarcode } from 'react-icons/io';
import { IoEyeOutline, IoOpenOutline } from 'react-icons/io5';
import { RiBankCardLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProducts, getProductWithSkus, resubmitProduct } from 'services/product';
import { getCurrentStore } from 'services/stores';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IStore } from 'utils/Interfaces/stores.interface';
import ProductPayment from './productPayment';

const ProductTableActions: React.FC<{
    product: IProduct;
    currentStore?: IStore;
    Icon?: any;
    setRows?: any;
    setActiveTab?: (phase: string) => void | undefined;
}> = ({ product, currentStore, Icon, setRows, setActiveTab }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [currentProduct, setProduct] = useState<IProduct | null>(null);
    const [openModalSkus, setOpenModalSkus] = useState(false);
    const [openModalPayment, setOpenModalPayment] = useState(false);
    const [showSkus, setShowSkus] = useState(false);

    const handleShowSkus = async () => {
        const store = currentStore || (await getCurrentStore());
        if (store && store.storeType.toLowerCase() !== 'other') {
            getProductWithSkus(product._id)
                .then((productWithSku) => {
                    if (
                        (productWithSku.variants.length === 0 && !productWithSku.globalSKU) ||
                        (productWithSku.variants as IProduct[]).some((variant) => !variant.globalSKU)
                    ) {
                        toast.error('Sorry, unable to display skus. Please try again!');
                    } else {
                        setProduct(productWithSku);
                        setOpenModalSkus(true);
                    }
                })
                .catch(() => {
                    toast.error('Unable to display skus. Please try again!');
                });
        } else {
            setProduct(product);
            setOpenModalSkus(true);
        }
    };

    useEffect(() => {
        if (currentStore) {
            if (
                currentStore.storeType.toLocaleLowerCase() === 'other' ||
                currentStore.storeType.toLocaleLowerCase() === 'lightfunnels'
            ) {
                setShowSkus(true);
            } else {
                setShowSkus(false);
            }
        } else {
            getCurrentStore().then((store) => {
                if (
                    (store && store.storeType.toLocaleLowerCase() === 'other') ||
                    (store && store.storeType.toLocaleLowerCase() === 'lightfunnels')
                ) {
                    setShowSkus(true);
                } else {
                    setShowSkus(false);
                }
            });
        }
    }, []);
    const getTabsNavigationState = (tabId: number) => ({
        tabId,
        productId: product._id,
        customerId: product.customer,
        productStatus: product.status,
        productName: product.name,
        isRestock: product.isRestock,
    });
    const gotTo = (e: string) => {
        switch (e) {
            case 'edit':
                navigate('/product-settings', {
                    state: getTabsNavigationState(0),
                });
                break;
            case 'quotation':
                navigate('/product-settings', {
                    state: getTabsNavigationState(1),
                });
                break;
            case 'chat':
                navigate('/product-settings', {
                    state: getTabsNavigationState(2),
                });
                break;
            case 'inventory':
                navigate('/inventory', {
                    state: {
                        search: product._id,
                    },
                });
                break;
            case 'restock':
                navigate('/sourcing-progress', {
                    state: { updatedProduct: product.parentProduct || product },
                });
                break;

            default:
                break;
        }
    };

    const handleresubmitProduct = async () => {
        const toastLoader = toast.loading('Saving offers...');

        await resubmitProduct(product._id)
            .then((res) => {
                toast.success(res.data.response, { id: toastLoader });
            })
            .then(async () => {
                if (setActiveTab) setActiveTab('Processing');
                else {
                    const res = await getProducts(
                        {
                            page: 1,
                            limit: 10,
                            type: 0,
                            sort: '-updatedAt',
                        },
                        {
                            itemKind: 'product',
                            productPhase: 'Processing',
                            isRestock: pathname?.includes('restock'),
                        },
                    );
                    setRows(res.data.response.docs || []);
                }
            })
            .catch((error) => {
                toast.error(`Sorry, somthing went wrong: ${error}`, { id: toastLoader });
            });
    };
    return (
        <>
            <Modal
                dismissible
                show={openModalSkus}
                popup
                size="5xl"
                onClose={() => setOpenModalSkus(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ',
                        },
                    },
                }}
            >
                <Modal.Header>
                    Product Skus -{' '}
                    {
                        // eslint-disable-next-line no-nested-ternary
                        product?.name || '--'
                    }{' '}
                </Modal.Header>
                <Modal.Body className=" overflow-y-auto ">
                    <Tablesku product={currentProduct} />
                </Modal.Body>
            </Modal>
            {product.status === 30 && (
                <ProductPayment
                    product={product}
                    withoutBtn
                    openModal={openModalPayment}
                    setopenModal={setOpenModalPayment}
                />
            )}

            <Dropdown inline arrowIcon={false} label={Icon || <HiDotsVertical />}>
                <Dropdown.Item icon={IoEyeOutline} onClick={() => gotTo(product.status === 3 ? 'quotation' : 'edit')}>
                    Product details
                </Dropdown.Item>
                {showSkus && (
                    <Dropdown.Item icon={IoIosBarcode} onClick={handleShowSkus}>
                        Display Skus
                    </Dropdown.Item>
                )}
                <Dropdown.Item icon={HiChat} onClick={() => gotTo('chat')}>
                    Chat
                </Dropdown.Item>
                {product.status === 30 && (
                    <Dropdown.Item icon={RiBankCardLine} onClick={() => setOpenModalPayment(true)}>
                        Repay
                    </Dropdown.Item>
                )}
                {product.status === 100 && (
                    <Dropdown.Item icon={IoOpenOutline} onClick={handleresubmitProduct}>
                        Resubmit
                    </Dropdown.Item>
                )}
                {[6, 8, 21].includes(product.status) && !product.isRestock && (
                    <>
                        <Dropdown.Item icon={IoOpenOutline} onClick={() => gotTo('inventory')}>
                            Go to inventory
                        </Dropdown.Item>
                        <Dropdown.Item icon={IoOpenOutline} onClick={() => gotTo('restock')}>
                            Restock
                        </Dropdown.Item>
                    </>
                )}
            </Dropdown>
        </>
    );
};

export default ProductTableActions;
