/* eslint-disable no-underscore-dangle */
import React from 'react';
import PaymentsStatus from 'components/status/paymentsStatus';
import { Table } from 'flowbite-react';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { filesDisplay } from 'utils/helpers/imageFinder';
import { IPaymentProps } from './payments.types';

const BankPayment: React.FC<IPaymentProps> = ({ paymentData }) => {
    return (
        <>
            <div className="container mx-auto mb-2">
                <div className="grid grid-cols-2 gap-4 justify-items-center">
                    <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                        <div>Account Name:</div>
                        <div>{paymentData.accountName}</div>
                        <div>Status: </div>
                        <div>
                            <PaymentsStatus status={paymentData.status} />
                        </div>
                    </div>
                    <div className="col-span-1 grid grid-rows-2 gap-2 items-center">
                        <div>
                            <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                <div>Declared Amount: </div>
                                <div>{currencyConverter(paymentData.amount, 'USD')}</div>
                            </div>
                        </div>
                        {/* <div>
                            {paymentData.source === 'walletBank' && paymentData.type === 'product' && (
                                <div>
                                    <div>
                                        <div>
                                            <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                                <div>Wallet Amount: </div>
                                                <div>{paymentData.walletAmount} $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="col-span-1 grid grid-cols-2 gap-2 items-center">
                                                <div>Bank Amount: </div>
                                                <div>{paymentData.bankAmount} $</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container mx-auto mb-2">
                {paymentData.verificationImage && (
                    <div className=" w-full flex justify-center">
                        {filesDisplay(paymentData.verificationImage, paymentData.accountName, 100, 100)}
                    </div>
                )}
            </div>

            <div className="mb-2">
                {paymentData && paymentData.type === 'order' && (
                    <div className="text-black justify-center w-full">
                        <div className="overflow-x-auto overflow-y-auto max-h-48">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>Order Ref</Table.HeadCell>
                                    <Table.HeadCell>Fees</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {paymentData?.ordersDetails?.map((orderInfo) => {
                                        return (
                                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell>{orderInfo?.orederRef}</Table.Cell>
                                                <Table.Cell>
                                                    {orderInfo?.Orderfees?.toFixed(2) ||
                                                        orderInfo?.orderFees?.toFixed(2)}{' '}
                                                    $
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default BankPayment;
