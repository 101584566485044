import GeneralButton from 'components/customButton/generalButton';
import CustomError from 'components/customError/CustomError';
import InfoToast from 'components/notificationBar/infoToast';
import { TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { updateStocksThresholdSchema } from 'utils/validations/accountInformationValidation';

const customTheme = {
    field: {
        input: {
            base: 'border-gray-300 w-full  dark:border-slate-600',
            colors: {
                general: 'bg-custom-background',
            },
        },
    },
};

//  a test

export const StockInfo: React.FC<{ customerId: string; stocksThreshold: number }> = ({
    customerId,
    stocksThreshold,
}) => {
    const [profileLoader, setProfileLoader] = useState(false);

    const updateStockThreshold = (value: { stocksThreshold: ICustomer['stocksThreshold'] }) => {
        setProfileLoader(true);
        const newThreshold = value.stocksThreshold;

        if (Number(newThreshold) === stocksThreshold) {
            // switch the notif to info
            // toast.success(`Stocks threshold value is already ${newThreshold}`);
            toast.custom((t) => <InfoToast t={t} message={`Stocks threshold value is already ${newThreshold}`} />);
            return;
        }
        updateCustomer(customerId, { stocksThreshold: Number(newThreshold) })
            .then(() => {
                toast.success(`Stocks threshold value updated to ${value.stocksThreshold} successully`);
                setProfileLoader(false);
            })
            .catch(() => {
                toast.error('Something went wrong updating stocks threshold');
                setProfileLoader(false);
            });
    };
    return (
        <>
            <Formik
                initialValues={{
                    stocksThreshold,
                }}
                onSubmit={(values) => {
                    updateStockThreshold(values);
                }}
                validationSchema={updateStocksThresholdSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="dark:bg-gray-800 bg-gray-200 border-l-gray-400 border-l-2 p-2">
                            <div className="">
                                <TextInput
                                    theme={customTheme}
                                    color="general"
                                    id="stocksThreshold"
                                    type="text"
                                    placeholder="Example: 20"
                                    sizing="md"
                                    {...formik.getFieldProps('stocksThreshold')}
                                    name="stocksThreshold"
                                    helperText={
                                        <>
                                            <CustomError name="stocksThreshold" component="div" />
                                        </>
                                    }
                                />
                                <div className="flex justify-between items-center">
                                    <p className="text-xs  font-medium   text-gray-400 ">
                                        *This value will be used to show in-app alerts about low stock quantities.
                                    </p>
                                    <GeneralButton
                                        color="blue"
                                        type="submit"
                                        disabled={profileLoader}
                                        isProcessing={profileLoader}
                                    >
                                        Save Changes
                                    </GeneralButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};
