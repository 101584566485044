import React from 'react';
import './flagsIcons.css';

const ShippingToCountryFlag: React.FC<{ flag: string }> = ({ flag }) => {
    const flagCode = () => {
        switch (flag.toLocaleLowerCase()) {
            case 'kuwait':
                return 'kw';
            case 'saudi arabia':
                return 'sa';
            case 'qatar':
                return 'qa';
            case 'oman':
                return 'om';
            case 'bahrain':
                return 'bh';
            case 'united arab emirates':
                return 'uae';
            default:
                return 'xx';
        }
    };

    return (
        <>
            <span className={`flag-icon flag-icon-${flagCode()}`} title={flag} />
        </>
    );
};

export default ShippingToCountryFlag;
