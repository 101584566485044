import axios from 'utils/axios/axios';

import { getBearerToken } from 'utils/helpers/auth';

export const payWithWallet = async (data: any) => {
    const config = await getBearerToken();
    const walletPay = await axios.post(`/payment/`, data, config);

    return walletPay;
};
export const payQuotaion = async (data: {}) => {
    const config = await getBearerToken();
    const walletPay = await axios.post(`/payment/`, data, config);

    return walletPay;
};
