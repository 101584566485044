import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { AvatarContainer, AvatarStyle, ShopDetails, ShopName } from './AccountAvatar.styles';
import { AccountAvatarProps } from './AccountAvatar.types';

const AccountAvatar: React.FC<AccountAvatarProps> = ({ isCollapsed, shopName, description }) => {
    return (
        <Box sx={AvatarContainer(isCollapsed || false)}>
            <Avatar sx={AvatarStyle} variant="rounded">
                {shopName.substring(0, 2).toUpperCase()}{' '}
            </Avatar>
            {!isCollapsed && (
                <Box sx={ShopDetails}>
                    <Typography variant="body2" sx={ShopName}>
                        {shopName}
                    </Typography>
                    <Typography variant="body2">{description}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default AccountAvatar;
