import React from 'react';

import { Outlet } from 'react-router-dom';

const AuthLayout: React.FC = () => {
    return (
        <div className="bg-[#EAEDF7] dark:bg-[#191C24]">
            <Outlet />
        </div>
    );
};

export default AuthLayout;
