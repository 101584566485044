import { AxiosError } from 'axios';
import { IaddOrder, uploadOrder } from 'services/order';
import { utils, WorkSheet } from 'xlsx';
import * as yup from 'yup';
import vnlinCities from 'utils/helpers/vnlin/vnlinCities.json';
import { logistioCountries } from 'utils/countryList';
import { phoneNumbersLength } from '../../vnlin/phoneNumbersLength';
import { inputOrderHeaderMatch } from './uploadCODOrderFile';

const countriesList: { [key: string]: { [k in 'code' | 'label' | 'phone' | 'currency' | 'arabicLabel']: string } } = {};
logistioCountries.forEach((country) => {
    countriesList[country.label] = {
        code: country.code,
        label: country.label,
        phone: country.phone,
        currency: country.currency,
        arabicLabel: country.arabicLabel,
    };
    countriesList[country.arabicLabel] = {
        code: country.code,
        label: country.label,
        phone: country.phone,
        currency: country.currency,
        arabicLabel: country.arabicLabel,
    };
});
let country = '';
const getOrderSchema = (isCcEnabled: boolean) => {
    return yup.object().shape({
        store: yup.string().required(),
        fullName: yup.string().required(),
        skus: yup
            .array()
            .of(
                yup.object({
                    sku: yup
                        .string()
                        .min(16, 'SKU are at least 16 characters in length')
                        .required('SKU is a required field'),
                    quantity: yup.number().min(1, 'minimum quantity is 1').required('quantity is a required field'),
                }),
            )
            .required(),
        address1: yup.string(),
        address2: yup.string(),
        city: isCcEnabled
            ? yup.string()
            : yup
                  .string()
                  .when('country', {
                      is: (countryValue: string) => {
                          if (countryValue && vnlinCities[countryValue as keyof typeof vnlinCities]) {
                              country = countryValue;
                              return true;
                          }
                          return false;
                      },
                      then: (schema) =>
                          schema.oneOf(vnlinCities[country as keyof typeof vnlinCities].map((el) => el.vnlinLabel)),
                  })
                  .required(),
        // company: yup.string().required(),
        country: yup.string().oneOf(Object.keys(countriesList)).required(),
        countryCode: yup.string(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phone: yup
            .string()
            .required()
            .when('country', {
                is: (countryValue: string) => {
                    if (countryValue && phoneNumbersLength[countryValue as keyof typeof phoneNumbersLength]) {
                        country = countryValue;
                        return true;
                    }
                    return false;
                },
                then: (schema) =>
                    schema.length(
                        phoneNumbersLength[country as keyof typeof phoneNumbersLength],
                        phoneNumbersLength[country as keyof typeof phoneNumbersLength]
                            ? `${country} phone numbers length must be ${
                                  phoneNumbersLength[country as keyof typeof phoneNumbersLength]
                              }`
                            : `Invalid phone number! Country must be one of ${Object.values(countriesList).map(
                                  (cl) => cl.label,
                              )}`,
                    ),
            }),
        province: yup.string(),
        provinceCode: yup.string(),
        zip: yup.string(),
        totalPrice: yup.number().required(),
        currency: yup
            .string()
            .required()
            .when('country', {
                is: (countryValue: string) => {
                    if (countryValue && countriesList[countryValue as keyof typeof countriesList]) {
                        country = countryValue;
                        return true;
                    }
                    return false;
                },
                then: (schema) =>
                    schema.equals(
                        [countriesList[country]?.currency] ||
                            schema.oneOf(Object.values(countriesList).map((cl) => cl.currency)),
                        countriesList[country]?.currency
                            ? `currency must be ${countriesList[country].currency}`
                            : `currency must be one of ${Object.values(countriesList).map((cl) => cl.currency)}`,
                    ),
            }),
        orderRef: yup.string(),
    });
};
type TArgs = {
    orders: (IaddOrder & { invalidCity?: boolean; closestCityMatch?: string })[];
    isWithCcEnabled: boolean;
    store: string;
    setErrors: React.Dispatch<
        React.SetStateAction<
            Map<
                string,
                {
                    orderCustomerFullName: string;
                    errors: string[];
                }
            >
        >
    >;
    setCounter: React.Dispatch<React.SetStateAction<{ errors: number; success: number; total: number }>>;
    setFailedOrders: React.Dispatch<React.SetStateAction<WorkSheet>>;
    setShowConfirmationUi: React.Dispatch<React.SetStateAction<boolean>>;
    setParsedOrders: React.Dispatch<
        React.SetStateAction<{
            withInvalidCities: any[];
            withFailedValidation: any[];
            valid: any[];
        } | null>
    >;
    onUploadEnd?: (...args: any[]) => any | void;
};
export async function uploadCODOrder({
    orders,
    isWithCcEnabled,
    store,
    setCounter,
    setErrors,
    setFailedOrders,
    setShowConfirmationUi,
    setParsedOrders,
    onUploadEnd,
}: TArgs) {
    setCounter((prev) => ({ ...prev, errors: 0, success: 0 }));
    // Validate and upload orders
    const failedOrdersUploads: IaddOrder[] = [];
    const ordersWithInvalidCities: typeof orders = [];
    const ordersToUpload: IaddOrder[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const order of orders) {
        try {
            // 2. Validate orders data
            if (order.invalidCity && !isWithCcEnabled) {
                ordersWithInvalidCities.push(order);
            } else {
                const orderSchema = getOrderSchema(isWithCcEnabled);
                const orderData = await orderSchema.validate({ ...order, store }, { abortEarly: false });
                ordersToUpload.push({ ...orderData, store });
            }
        } catch (err) {
            // If failed get the error message and save failed order data
            // to be downloaded by the user as xlsx workbook

            // get yup validation errors
            if ((err as yup.ValidationError).inner) {
                setErrors((errs) => {
                    const orderCustomerFullName = `${order.firstName} ${order.lastName}`;
                    errs.set(orderCustomerFullName, {
                        orderCustomerFullName,
                        errors: [
                            ...new Set([
                                ...(errs.get(orderCustomerFullName)?.errors || []),
                                ...(err as yup.ValidationError).inner.map((el) => el.message),
                            ]),
                        ],
                    });
                    return errs;
                });
                // setErrors( errs => [
                //     ...errs,
                //     `Order for ${order.firstName} ${order.lastName}: ${(err as yup.ValidationError).inner
                //         .map((el, index) => `[ Error ${index + 1}: ${el.message} ]`)
                //         .join(' ')}`,
                // ] )
            }
            setCounter((counter: any) => ({ ...counter, errors: counter.errors + 1 }));
            failedOrdersUploads.push({ ...order, store });
        }
    }

    // handle orders with invalid cities
    // - collected the parsed orders data
    // - show confirmation prompt (UI to prompt the user about the invalid orders and to take the necessary actions)
    if (ordersWithInvalidCities.length > 0) {
        setShowConfirmationUi(true);
        setParsedOrders(() => ({
            withFailedValidation: failedOrdersUploads,
            withInvalidCities: ordersWithInvalidCities,
            valid: ordersToUpload,
        }));
        return;
    }
    setShowConfirmationUi(false);
    if (ordersToUpload.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const order of ordersToUpload) {
            try {
                // make sure that order ref fields existing
                const data = { ...order, orderRef: order.orderRef };
                // @ts-ignore
                delete data.orderNumber;
                // @ts-ignore
                delete data.invalidCity;
                // @ts-ignore
                delete data.closestCityMatch;
                // 3. upload orders data
                await uploadOrder(data, 'cod')
                    .then(() => {
                        setCounter((counter: any) => ({ ...counter, success: counter.success + 1 }));
                    })
                    .catch((error) => {
                        if (error.response.data.errors) {
                            throw new Error(error.response.data.errors.message);
                        } else {
                            throw error;
                        }
                    });
            } catch (err) {
                if ((err as AxiosError).isAxiosError) {
                    if (
                        ((err as AxiosError).response?.data?.message as string).toLowerCase() === 'validation failed' &&
                        (err as AxiosError).response?.data?.validation?.body
                    ) {
                        const errBody: { keys: string[]; message: string; source: string } = (err as AxiosError)
                            .response?.data?.validation?.body;
                        setErrors((errs) => {
                            const orderCustomerFullName = `${order.firstName} ${order.lastName}`;
                            errs.set(orderCustomerFullName, {
                                orderCustomerFullName,
                                errors: [
                                    ...new Set([...(errs.get(orderCustomerFullName)?.errors || []), errBody.message]),
                                ],
                            });
                            return errs;
                        });
                    }
                } else {
                    // get non validation errors
                    setErrors((errs) => {
                        const orderCustomerFullName = `${order.firstName} ${order.lastName}`;
                        errs.set(orderCustomerFullName, {
                            orderCustomerFullName,
                            errors: [...new Set([...(errs.get(orderCustomerFullName)?.errors || []), `${err}`])],
                        });
                        return errs;
                    });
                }
                setCounter((counter: any) => ({ ...counter, errors: counter.errors + 1 }));
                failedOrdersUploads.push({ ...order, store });
            }
        }
    }

    // Create xlsx worksheet containing the failed orders with merged cells
    if (failedOrdersUploads.length > 0) {
        // transform back the data to its initial type when parsed from the file
        // const failedOrdersInitShap: (Omit<IxlsxOrder, 'orderRef'> & { orderNumber: string })[] = [];
        const failedOrdersInitShap: (Record<Exclude<keyof typeof inputOrderHeaderMatch, 'store'>, any> & {
            __rowNum__?: number;
            orderNumber?: string;
        })[] = [];
        failedOrdersUploads.forEach((order) => {
            order.skus.forEach((sku) => {
                failedOrdersInitShap.push({
                    'first name (required)': order.firstName!,
                    'last name (required)': order.lastName!,
                    'address 1': order.address1!,
                    'address 2': order.address2!,
                    'country (required)': order.country!,
                    'country code': order.countryCode!,
                    province: order.province!,
                    'province code': order.provinceCode!,
                    city: order.city!,
                    zip: order.zip!,
                    // company: order.company!,
                    'phone (required)': order.phone!,
                    'sku (required)': sku.sku!,
                    'quantity (required)': sku.quantity!,
                    'total price (required)': order.totalPrice!,
                    'currency (required)': order.currency!,
                    'order number (required)': order?.orderRef ?? '',
                });
            });
        });

        const failedOrdersWS = utils.json_to_sheet(failedOrdersInitShap);
        // Merge order rows
        failedOrdersWS['!merges'] = [];
        let lastMergeIndex = 1;
        failedOrdersUploads.forEach((failedOrder) => {
            // merge customer data cells (first/last name, address...)
            const mergeRowsLength = failedOrder.skus.length - 1;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 11; i++) {
                failedOrdersWS['!merges']?.push({
                    s: {
                        c: i,
                        r: lastMergeIndex,
                    },
                    e: {
                        c: i,
                        r: lastMergeIndex + mergeRowsLength,
                    },
                });
            }
            // merge total quantity cells
            failedOrdersWS['!merges']?.push({
                s: {
                    c: 13,
                    r: lastMergeIndex,
                },
                e: {
                    c: 13,
                    r: lastMergeIndex + mergeRowsLength,
                },
            });
            // merge currency cells
            failedOrdersWS['!merges']?.push({
                s: {
                    c: 14,
                    r: lastMergeIndex,
                },
                e: {
                    c: 14,
                    r: lastMergeIndex + mergeRowsLength,
                },
            });
            // merge orderRef cells
            failedOrdersWS['!merges']?.push({
                s: {
                    c: 15,
                    r: lastMergeIndex,
                },
                e: {
                    c: 15,
                    r: lastMergeIndex + mergeRowsLength,
                },
            });
            lastMergeIndex += mergeRowsLength + 1;
        });
        setFailedOrders(failedOrdersWS);
    }
    if (onUploadEnd) {
        onUploadEnd();
    }
}
