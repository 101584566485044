/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import { ListItemIcon } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { keyframes, styled } from '@mui/material/styles';

const othersAnim = keyframes`
 
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
 `;

const produtsAnim = keyframes`
 
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
 `;

const ordersAnim = keyframes`
 
   0% {
    transform: translateX(-35px) skewX(0deg);
    opacity: 0;
  }
  
  55% {
    transform: translateX(20px) skewX(-40deg);
    opacity: 1;
  }
  
  88% {
    transform: translateX(-10px) skewX(20deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0px) skewX(0deg);
    opacity: 1;
  }
 `;

export const StyledListItemButton = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'collapsed',
})<{ collapsed: boolean | undefined }>(({ theme, collapsed }) => ({
    // color: selected ? 'white' : 'theme.palette.textColor.primary',
    color: theme.palette.textColor.secondary,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    margin: collapsed ? '2px 15px 2px 29px' : '5px 15px 2px 15px',
    transition: 'margin 0.1s ease',
    justifyContent: 'space-between',
    paddingLeft: '0px',
    minWidth: '30px',
    borderRadius: '35px',
    '&:hover': {
        backgroundColor: theme.palette.secondaryBackground,
        color: 'textColor.secondary',
    },
}));

const iconNaming = (iconName: string | undefined) => {
    switch (iconName) {
        case 'Orders':
            return `${ordersAnim} 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 alternate forwards`;
        case 'Products':
            return `${produtsAnim} 0.9s both`;

        default:
            return `${othersAnim} 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`;
    }
};

export const StyledListItemIcon = styled(ListItemIcon, {
    shouldForwardProp: (prop) => prop !== 'hovered' && prop !== 'iconName',
})<{ hovered: boolean | undefined; iconName: string | undefined }>(({ hovered, iconName }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '10px',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    // animation: hovered ? `${tiltSshaking} 0.4s infinite` : ``,
    animation: hovered ? iconNaming(iconName) : ``,
    color: hovered ? '#FFF' : '',
}));

export const ListItemStyle = (collapsed: boolean) => {
    return {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4px',
        justifyContent: collapsed ? 'center' : 'flex-end',
        transition: 'justifyContent 0.3s ease',
        marginTop: '-1px',
        marginBottom: '-1px',
    };
};

export const roundedIcon = (hovered: boolean) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: hovered ? 'primary.main' : '',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        overflow: 'hidden',
    };
};
