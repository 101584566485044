import { AxiosError } from 'axios';
import { IaddOrder, uploadOrders } from 'services/order';
import { logistioCountries } from 'utils/countryList';
import vnlinCities from 'utils/helpers/vnlin/vnlinCities.json';
import { utils, WorkSheet } from 'xlsx';
import * as yup from 'yup';
import { phoneNumbersLength } from '../../vnlin/phoneNumbersLength';
import { inputOrderHeaderMatch } from './uploadPrepaidOrderFile';

type TArgs = {
    orders: (IaddOrder & { invalidCity?: boolean; closestCityMatch?: string })[];
    store: string;
    setErrors: React.Dispatch<
        React.SetStateAction<
            Map<
                string,
                {
                    orderCustomerFullName: string;
                    errors: string[];
                }
            >
        >
    >;
    setCounter: React.Dispatch<React.SetStateAction<{ errors: number; success: number; total: number }>>;
    setFailedOrders: React.Dispatch<React.SetStateAction<WorkSheet>>;
    setShowConfirmationUi: React.Dispatch<React.SetStateAction<boolean>>;
    setParsedOrders: React.Dispatch<
        React.SetStateAction<{
            withInvalidCities: any[];
            withFailedValidation: any[];
            valid: any[];
        } | null>
    >;
    onUploadEnd?: (...args: any[]) => any | void;
};

/**
 * validate orders schema & upload the orders data
 * */
export async function sendUploadOrdersRequest({
    orders,
    store,
    setErrors,
    setCounter,
    setFailedOrders,
    setShowConfirmationUi,
    setParsedOrders,
    onUploadEnd,
}: TArgs) {
    const countriesList: { [key: string]: { [k in 'code' | 'label' | 'phone' | 'currency' | 'arabicLabel']: string } } =
        {};
    setCounter((prev) => ({ ...prev, errors: 0, success: 0 }));
    logistioCountries.forEach((country) => {
        countriesList[country.label] = {
            code: country.code,
            label: country.label,
            phone: country.phone,
            currency: country.currency,
            arabicLabel: country.arabicLabel,
        };
        countriesList[country.arabicLabel] = {
            code: country.code,
            label: country.label,
            phone: country.phone,
            currency: country.currency,
            arabicLabel: country.arabicLabel,
        };
    });

    let country = '';
    const orderSchema = yup.object().shape({
        store: yup.string().required(),
        fullName: yup.string().required(),
        skus: yup
            .array()
            .of(
                yup.object({
                    sku: yup
                        .string()
                        .min(16, 'SKU are at least 16 characters in length')
                        .required('SKU is a required field'),
                    quantity: yup.number().min(1, 'minimum quantity is 1').required('quantity is a required field'),
                }),
            )
            .required(),
        address1: yup.string(),
        address2: yup.string(),
        country: yup.string().oneOf(Object.keys(countriesList)).required(),
        city: yup
            .string()
            .when('country', {
                is: (countryValue: string | undefined) => {
                    if (countryValue && vnlinCities[country as keyof typeof vnlinCities]) {
                        country = countryValue;
                        return true;
                    }
                    return false;
                },
                then: (schema) =>
                    schema.oneOf(vnlinCities[country as keyof typeof vnlinCities].map((el) => el.vnlinLabel)),
            })
            .required(),
        // company: yup.string().required(),
        countryCode: yup.string(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phone: yup
            .string()
            .required()
            .when('country', {
                is: (countryValue: string | undefined) => {
                    if (countryValue && phoneNumbersLength[country as keyof typeof phoneNumbersLength]) {
                        country = countryValue;
                        return true;
                    }
                    return false;
                },
                then: (schema) =>
                    schema.length(
                        phoneNumbersLength[country as keyof typeof phoneNumbersLength],
                        phoneNumbersLength[country as keyof typeof phoneNumbersLength]
                            ? `${country} phone numbers length must be ${
                                  phoneNumbersLength[country as keyof typeof phoneNumbersLength]
                              }`
                            : `Invalid phone number! Country must be one of ${Object.values(countriesList).map(
                                  (cl) => cl.label,
                              )}`,
                    ),
            }),
        province: yup.string(),
        provinceCode: yup.string(),
        zip: yup.string(),
        orderRef: yup.string(),
    });

    // Validate and upload orders
    const failedOrdersValidation: IaddOrder[] = [];
    const ordersToUpload: (IaddOrder & { prePaid?: boolean; cod?: boolean })[] = [];

    const ordersWithInvalidCities: typeof orders = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const order of orders) {
        try {
            if (order.invalidCity) {
                ordersWithInvalidCities.push(order);
            } else {
                const orderData = await orderSchema.validate({ ...order, store }, { abortEarly: false });
                ordersToUpload.push({ ...orderData, prePaid: true });
            }
        } catch (err) {
            if ((err as yup.ValidationError).inner) {
                setErrors((errs) => {
                    const orderCustomerFullName = `${order.firstName} ${order.lastName}`;
                    errs.set(orderCustomerFullName, {
                        orderCustomerFullName,
                        errors: [
                            ...new Set([
                                ...(errs.get(orderCustomerFullName)?.errors || []),
                                ...(err as yup.ValidationError).inner.map((el) => el.message),
                            ]),
                        ],
                    });
                    return errs;
                });
            }
            setCounter((counter: any) => ({ ...counter, errors: counter.errors + 1 }));
            failedOrdersValidation.push({ ...order, store });
        }
    }
    // If invalid orders are found,
    // - Stop upload process
    // - Collect the parsed orders data
    // - Show confirmation prompt (UI to prompt the user about the invalid orders and to take the necessary actions)
    if (ordersWithInvalidCities.length > 0) {
        setShowConfirmationUi(true);
        setParsedOrders(() => ({
            withInvalidCities: ordersWithInvalidCities,
            withFailedValidation: failedOrdersValidation,
            valid: ordersToUpload,
        }));
        return;
    }
    setShowConfirmationUi(false);

    if (ordersToUpload.length > 0) {
        // upload orders now
        await uploadOrders(
            ordersToUpload.map((order) => {
                const draft = { ...order };
                if (!draft.orderRef || draft.orderRef.length === 0) {
                    // @ts-ignore
                    draft.orderRef = draft.orderNumber;
                    // @ts-ignore
                    delete draft.orderNumber;
                }
                return draft;
            }),
            'prePaid',
            store,
        )
            .then((res) => {
                setCounter((counter: any) => {
                    const draft = { ...counter };
                    if (res.data.uploadedOrdersIds.length > 0) {
                        Object.assign(draft, { success: counter.success + res.data.uploadedOrdersIds.length });
                    }
                    if (res.data.errors && res.data.errors.length > 0) {
                        Object.assign(draft, { errors: counter.errors + res.data.errors?.length });
                    }
                    return draft;
                });
                if (res.data.errors && res.data.errors.length > 0) {
                    res.data.errors.forEach((errorData) => {
                        failedOrdersValidation.push({ ...errorData.order, store });
                        setErrors((errs) => {
                            const orderCustomerFullName = `${errorData.order.firstName} ${errorData.order.lastName}`;
                            errs.set(orderCustomerFullName, {
                                orderCustomerFullName,
                                errors: [
                                    ...new Set([...(errs.get(orderCustomerFullName)?.errors || []), errorData.message]),
                                ],
                            });
                            return errs;
                        });
                    });
                }
            })
            .catch((err) => {
                setCounter((counter: any) => ({ ...counter, errors: counter.errors + ordersToUpload.length }));
                ordersToUpload.forEach((order) => {
                    failedOrdersValidation.push({ ...order, store });
                    setErrors((errs) => {
                        const orderCustomerFullName = `${order.firstName} ${order.lastName}`;
                        let errorMessage = 'Something went wrong! Please try again or contact your account manager';
                        if ((err as AxiosError).isAxiosError) {
                            errorMessage = (err as AxiosError)?.response?.data?.errors?.message || errorMessage;
                        }
                        errs.set(orderCustomerFullName, {
                            orderCustomerFullName,
                            errors: [...new Set([...(errs.get(orderCustomerFullName)?.errors || []), errorMessage])],
                        });
                        return errs;
                    });
                });
            });
    }

    // Create xlsx worksheet containing the failed orders with merged cells
    if (failedOrdersValidation.length > 0) {
        // transform back the data to its initial type when parsed from the file
        // const failedOrdersInitShap: (Omit<IxlsxOrder, 'orderRef'> & { orderNumber: string })[] = [];
        const failedOrdersInitShap: (Record<Exclude<keyof typeof inputOrderHeaderMatch, 'store'>, any> & {
            __rowNum__?: number;
            orderNumber?: string;
        })[] = [];
        failedOrdersValidation.forEach((order) => {
            order.skus.forEach((sku) => {
                failedOrdersInitShap.push({
                    'first name (required)': order.firstName!,
                    'last name (required)': order.lastName!,
                    'address 1': order.address1!,
                    'address 2': order.address2!,
                    'country (required)': order.country!,
                    'country code': order.countryCode!,
                    province: order.province!,
                    'province code': order.provinceCode!,
                    city: order.city!,
                    zip: order.zip!,
                    'phone (required)': order.phone!,
                    'sku (required)': sku.sku!,
                    'quantity (required)': sku.quantity!,
                    'order number (required)': order?.orderRef ?? '',
                });
            });
        });

        const failedOrdersWS = utils.json_to_sheet(failedOrdersInitShap);
        // Merge order rows
        failedOrdersWS['!merges'] = [];
        let lastMergeIndex = 1;
        failedOrdersValidation.forEach((failedOrder) => {
            // merge customer data cells (first/last name, address...)
            const mergeRowsLength = failedOrder.skus.length - 1;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 11; i++) {
                failedOrdersWS['!merges']?.push({
                    s: {
                        c: i,
                        r: lastMergeIndex,
                    },
                    e: {
                        c: i,
                        r: lastMergeIndex + mergeRowsLength,
                    },
                });
            }
            // merge orderRef cells
            failedOrdersWS['!merges']?.push({
                s: {
                    c: 13,
                    r: lastMergeIndex,
                },
                e: {
                    c: 13,
                    r: lastMergeIndex + mergeRowsLength,
                },
            });
            lastMergeIndex += mergeRowsLength + 1;
        });
        setFailedOrders(failedOrdersWS);
    }
    if (onUploadEnd) {
        onUploadEnd();
    }
}
