/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// import ProductTable from 'components/productTable/ProductTable';
import InputField from 'components/inputField/InputField';
import DataTable from 'components/tables/tables';
import { Tabs, TabsRef } from 'flowbite-react';
import moment from 'moment';
import { FaPlus } from 'react-icons/fa6';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { getProducts, IdateRange, Ifilter, Ipagination } from 'services/product';
import { getCurrentStore } from 'services/stores';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import { PRODUCT_PHASES, productStatus } from 'utils/helpers/product';
import { primaryButtonSmall } from 'utils/helpers/themeStyle/buttons';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IStore } from 'utils/Interfaces/stores.interface';
import { initColumns, statusDisplayColor, ToTalPriceCol } from './ProductListElements';

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
interface Ireset {
    status: boolean;
    callCenter: boolean;
    callAgent: boolean;
}
const ProductList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tabsRef = useRef<TabsRef | null>(null);
    const [store, setStore] = useState<IStore | null>();
    useEffect(() => {
        getCurrentStore().then((data) => data && setStore(data));
    }, []);
    const [productList, setProductList] = useState<Array<IProduct>>([]);
    const [resetValue, setresetValue] = useState<Ireset>({ status: false, callCenter: false, callAgent: false });

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 10,
        type: 0,
        sort: '-updatedAt',
    });
    // const initDate = {
    //     startDate: moment
    //         .tz(new Date(), 'Asia/Shanghai')
    //         .set('D', moment.tz(new Date(), 'Asia/Shanghai').get('D') - 2)
    //         .set('hour', 0)
    //         .set('minute', 0)
    //         .set('second', 0)
    //         .format(),
    //     endDate: moment.tz(new Date(), 'Asia/Shanghai').set('hour', 23).set('minute', 59).set('second', 59).format(),
    // };

    const [filter, setFilter] = useState<Ifilter>({
        itemKind: 'product',
        productPhase: 'Processing',
        // dateRange: initDate,
        isRestock: location.pathname === '/restock',
    });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);
    const [rangevalue, setRangeValue] = useState<IdateRange>({
        startDate: '', // moment.tz(initDate.startDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
        endDate: '', // moment.tz(initDate.endDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
    });

    const handlePageChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    function handleChangeFilter(event: any, isTab?: boolean) {
        if (isTab) setFilter({ ...filter, ...event }); // productPhase: event, status: undefined });
        else if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    }
    const handleRangeFilterChange = (newValue: IdateRange | null) => {
        if (newValue?.endDate && newValue?.startDate) {
            const startD = moment(newValue.startDate);
            const endD = moment(newValue.endDate);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            setRangeValue({
                startDate: startD.format('YYYY-MM-DD'),
                endDate: endD.format('YYYY-MM-DD'),
            });
            setFilter({
                ...filter,
                dateRange: {
                    startDate: startD.format(),
                    endDate: endD.format(),
                },
            });
        } else {
            setFilter({
                ...filter,
                dateRange: undefined,
            });
            setRangeValue({
                startDate: null,
                endDate: null,
            });
        }
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    };
    const setActiveTab = (phase: string) => tabsRef.current?.setActiveTab(PRODUCT_PHASES.indexOf(phase));

    const debouncedFilter = useDebouncedValue(filter, 100);
    const debouncedPagination = useDebouncedValue(pagination, 100);

    // fetch products on component mount & pagination or filter change
    useEffect(() => {
        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        const fetchedAndActive = meta.page === pagination.page;
        // const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        /**
         * ignore request if page is available
         */
        if (!fetchedAndActive || location.pathname) {
            setIsLoading(true);
            getProducts(debouncedPagination, { ...debouncedFilter, isRestock: location.pathname === '/restock' })
                .then((res) => {
                    const data = res.data.response;
                    const list = data.docs;

                    if (filter) setProductList(list);
                    setMeta({
                        hasNextPage: data.hasNextPage,
                        hasPrevPage: data.hasPrevPage,
                        limit: data.limit,
                        nextPage: data.nextPage,
                        page: data.page,
                        pagingCounter: data.pagingCounter,
                        prevPage: data.prevPage,
                        totalDocs: data.totalDocs,
                        totalPages: data.totalPages,
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);

                    // setErrorData({
                    //     notifMessage: err?.response?.data?.errors?.message,
                    //     notifType: 'error',
                    //     open: true,
                    // });
                });
        }
    }, [debouncedFilter, debouncedPagination, location.pathname]);

    DynamicTitle(location.pathname === '/restock' ? 'Restock' : 'Products');

    const findStatus = useMemo(() => {
        const status = productStatus.find((el) => filter?.status === el.id);
        return status ? `${status?.id}_${status?.type}` : '';
    }, [filter]);

    return (
        <Box>
            <Box sx={{ width: '100%' }}>
                <div className="flex justify-between my-4">
                    <div className="flex ">
                        <MdOutlineShoppingCart size={32} />{' '}
                        <span className="font-medium text-2xl">
                            {location.pathname === '/restock' ? 'Restock' : 'Product list'}
                        </span>
                    </div>
                    {/* {managedCallCenters.length === 1 && ( */}
                    <button
                        type="button"
                        className={`gap-2 !bg-main !hover:bg-main-hover !focus:bg-main-focus ${primaryButtonSmall}`}
                        onClick={() => navigate('/sourcing-progress')}
                    >
                        <FaPlus /> Logistio Bulk
                    </button>
                    {/* )} */}
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'secondaryBackground',
                    }}
                    className="flex gap-1 justify-between"
                >
                    <div className="flex gap-2">
                        <FormControl size="small">
                            <InputLabel id=" status-prod-filter-select-label">Status</InputLabel>
                            <Select
                                className="min-w-[180px]"
                                labelId="prod-status-filter-select-label"
                                sx={{ width: 120 }}
                                label="Status"
                                id="prod-Status-filter-select"
                                name="status"
                                value={findStatus}
                                onChange={(e) => {
                                    const val = e.target.value ? e.target.value.split('_') : [];
                                    // eslint-disable-next-line no-unused-expressions
                                    val?.length && setActiveTab(val[1]);
                                    handleChangeFilter(
                                        {
                                            status: Number(val[0]) || undefined,
                                            productPhase: val[1] || PRODUCT_PHASES[0],
                                        },
                                        true,
                                    );
                                }}
                            >
                                <MenuItem value={`_${filter?.productPhase}`}>All status</MenuItem>
                                {productStatus.map((prod) => {
                                    return (
                                        <MenuItem value={`${prod.id}_${prod.type}`} key={prod.id}>
                                            {prod.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Datepicker
                            inputClassName="w-full  border-gray-300  pl-4 pr-10 font-light text-sm rounded-md focus:ring-0 font-normal bg-custom-background dark:border-slate-600 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20"
                            inputId="deliveryDate"
                            value={rangevalue}
                            containerClassName="relative z-20 my-auto"
                            useRange
                            onChange={handleRangeFilterChange}
                            // className="my-auto"
                        />
                    </div>
                    <InputField
                        InputType="search"
                        placeholder="Search by product name or by SKU"
                        inputSize="small"
                        name="search"
                        onChange={(e) => {
                            handleChangeFilter(e);
                        }}
                        className="!w-[290px]"
                    />
                    {/* </div> */}
                </Box>
                <Tabs
                    aria-label="Tabs with icons"
                    // eslint-disable-next-line react/style-prop-object
                    theme={{
                        tablist: {
                            tabitem: {
                                base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                styles: {
                                    default: {
                                        base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                        active: {
                                            on: 'text-main border-main border-b-2',
                                            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                        },
                                    },
                                },
                            },
                        },
                    }}
                    ref={tabsRef}
                    onActiveTabChange={(tab) => {
                        if (!filter.status && PRODUCT_PHASES[tab] === filter.productPhase) return;
                        handleChangeFilter({ productPhase: PRODUCT_PHASES[tab], status: undefined }, true);
                    }}
                >
                    {PRODUCT_PHASES.map((item: any) => {
                        const cols = [...initColumns];
                        if (item === 'Paid') cols.splice(5, 0, { ...ToTalPriceCol });
                        return (
                            <Tabs.Item
                                title={
                                    // reset the status when we select one of the tab
                                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                                    <div
                                        onClick={() => setresetValue({ ...resetValue, status: true })}
                                        className="h-full py-4"
                                    >
                                        {item}
                                    </div>
                                }
                                key={item}
                                style={{ height: '64px !important', padding: '16px 24px' }}
                                className="!py-0 xs:!h-14"
                            >
                                <DataTable
                                    key={item}
                                    rows={productList}
                                    setRows={setProductList}
                                    setActiveTab={setActiveTab}
                                    columns={cols}
                                    icons={statusDisplayColor}
                                    pageState={{
                                        isLoading,
                                        total: meta.totalDocs,
                                        pageSize: pagination.limit,
                                        page: pagination.page,
                                        count: meta.totalPages,
                                    }}
                                    setPageState={handlePageChange}
                                    store={store}
                                />
                            </Tabs.Item>
                        );
                    })}
                </Tabs>
            </Box>
        </Box>
    );
};

export default ProductList;
