import React, { useEffect, useState } from 'react';
import { useLocation, Location, Navigate, useNavigate } from 'react-router-dom';
import { trackOrders } from 'services/publicServices/track';

import useMediaQueries from 'utils/hooks/useMediaQuery';

import TrackingLogistioCodes from './trackingLogistioCodes/trackingLosgistioCodes';
import TrackingLogistioCodesMobile from './trackingLogistioCodesMobile/trackingLogistioCodesMobile';

const TrackingResults = () => {
    const location: Location<{ trackingData: Awaited<ReturnType<typeof trackOrders>>['data'] }> = useLocation();
    const navigate = useNavigate();
    const [trackingData, setTrackingData] = useState<typeof location.state.trackingData>({});
    const [trackingCodes, setTrackingCodes] = useState<string[]>([]);
    const mediaQueries = useMediaQueries();

    if (!location.state) {
        return <Navigate to="/tracking" />;
    }

    useEffect(() => {
        if (location.state && location.state.trackingData) {
            const { trackingData: requestedTrackingData } = location.state;
            setTrackingData(requestedTrackingData);
            setTrackingCodes(Object.keys(requestedTrackingData));
            // NOTE: clearing the location state without causing a rerender.
            // The next time when the user reloads the page he will be
            // redirected to /tracking (triggered by the else block bellow)
            // But I think its better to still display the same data as it persists
            // between window reloads
            window.history.replaceState({}, '');
        } else {
            navigate('/tracking', { state: undefined });
        }
    }, []);

    return (
        <>
            {mediaQueries.lg ? (
                <TrackingLogistioCodes
                    key="TrackingResultWeb"
                    trackingCodes={trackingCodes}
                    trackingData={trackingData}
                    setTrackingData={setTrackingData}
                    setTrackingCodes={setTrackingCodes}
                />
            ) : (
                <TrackingLogistioCodesMobile
                    key="TrackingResultMobile"
                    trackingCodes={trackingCodes}
                    trackingData={trackingData}
                    setTrackingData={setTrackingData}
                    setTrackingCodes={setTrackingCodes}
                />
            )}
        </>
    );
};

export default TrackingResults;
