/* eslint-disable react/jsx-fragments */
import GeneralButton from 'components/customButton/generalButton';
import CustomError from 'components/customError/CustomError';
import InfoIcon from 'components/infoIcon/infoIcon';
import { Label, Radio, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { countries, currencies } from 'utils/countryList';
import { customerBankInformation } from 'utils/validations/updateBankConfig';

const customTheme = {
    field: {
        input: {
            base: 'border-gray-300 w-full  dark:border-slate-600',
            colors: {
                general: 'bg-custom-background',
            },
        },
    },
};

const CustomerBankInformation: React.FC<{
    customerId: string;
    setCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>;
    setCustomerLoader: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ customerId, setCustomer, setCustomerLoader }) => {
    const [financialServiceForm, setFinancialServiceForm] = useState(false);
    const [accountBankForm, setAccountBankForm] = useState(false);
    const [paymentServiceForm, setPaymentServiceForm] = useState(false);
    const [swiftForm, setSwiftForm] = useState(false);
    const [achForm, setAchForm] = useState(false);

    const [loader, setLoader] = useState(false);

    const [typeBanking, setTypeBanking] = useState('');
    const [achOrSwift, setAchOrSwift] = useState('');

    const updateBankInformation = (values: any) => {
        setLoader(true);
        updateCustomer(customerId, { bankAccountInformation: values })
            .then((result) => {
                toast.success('Your bank information added successfully.');
                setLoader(false);
                setCustomer(result.data.customer);
            })
            .catch((e) => {
                toast.error(e?.response?.data?.message || 'Something went wrong adding your bank information');
                setLoader(false);
                setCustomerLoader(false);
            });
    };

    const informationValuesFunction = () => {
        let inforamtion: Record<string, any> = {};
        if (typeBanking === 'FinancialService') {
            inforamtion = {
                email: '',
                bankingType: 'FinancialService',
                paymentService: '',
            };
        } else if (typeBanking === 'BankAccount') {
            if (achOrSwift === 'SWIFT') {
                inforamtion = {
                    accountHolder: '',
                    city: '',
                    state: '',
                    country: 'Afghanistan',
                    bankingType: 'BankAccount',
                    ibanRoutingNumber: '',
                    targetCurrency: 'AFA',
                    postCode: '',
                    receiverType: 'BUSINESS',
                    recepientAdress: '',
                    swiftBicCode: '',
                    transferMethod: 'SWIFT',
                };
            }
            if (achOrSwift === 'ACH') {
                inforamtion = {
                    accountHolder: '',
                    accountNumber: '',
                    accountType: 'CHECKING',
                    achRoutingNumber: '',
                    city: '',
                    state: '',
                    targetCurrency: 'AFA',
                    country: 'Afghanistan',
                    bankingType: 'BankAccount',
                    postCode: '',
                    receiverType: 'BUSINESS',
                    recepientAdress: '',
                    transferMethod: 'ACH',
                };
            }
        } else {
            inforamtion = {
                accountHolder: '',
                accountNumber: '',
                accountType: '',
                achRoutingNumber: '',
                city: '',
                state: '',
                country: 'Afghanistan',
                email: '',
                bankingType: '',
                ibanRoutingNumber: '',
                paymentService: '',
                postCode: '',
                receiverType: '',
                recepientAdress: '',
                swiftBicCode: '',
                transferMethod: '',
                targetCurrency: 'AFA',
            };
        }
        return { ...inforamtion };
    };
    const informationValues: Record<string, any> = informationValuesFunction();

    return (
        <>
            <Formik
                initialValues={{ ...informationValues }}
                onSubmit={(values) => {
                    setAchForm(false);
                    setSwiftForm(false);
                    setFinancialServiceForm(false);
                    setAccountBankForm(false);
                    setPaymentServiceForm(false);
                    updateBankInformation(values);
                }}
                validationSchema={customerBankInformation}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
                        <div className="flex flex-col gap-10">
                            <div>
                                <p>
                                    Please specify and complete the following information to receive payment from
                                    Logistio.
                                </p>
                                <p>All our payments will be made in USD.</p>
                            </div>

                            <div className="flex flex-col gap-3  lg:w-2/3  ">
                                <fieldset className="flex max-w-md flex-col gap-3 ">
                                    <p className="">1-How would you prefer to receive your funds?</p>
                                    <div className="flex gap-10">
                                        <div className="flex items-center gap-2">
                                            <Radio
                                                checked={formik.values.bankingType === 'BankAccount'}
                                                id="BankAccount"
                                                {...formik.getFieldProps('bankingType')}
                                                value="BankAccount"
                                                onChange={() => {
                                                    setAccountBankForm(true);
                                                    setFinancialServiceForm(false);
                                                    setSwiftForm(false);
                                                    setAchForm(false);
                                                    setPaymentServiceForm(false);
                                                    formik.resetForm({
                                                        values: {
                                                            accountHolder: '',
                                                            accountNumber: '',
                                                            accountType: 'CHECKING',
                                                            achRoutingNumber: '',
                                                            city: '',
                                                            state: '',
                                                            country: 'Afghanistan',
                                                            bankingType: 'BankAccount',
                                                            ibanRoutingNumber: '',
                                                            postCode: '',
                                                            receiverType: 'BUSINESS',
                                                            recepientAdress: '',
                                                            swiftBicCode: '',
                                                            transferMethod: '',
                                                            targetCurrency: 'AFA',
                                                        },
                                                    });
                                                    setTypeBanking('BankAccount');
                                                    formik.setFieldValue('bankingType', 'BankAccount');
                                                }}
                                            />

                                            <Label htmlFor="united-state">Account bank</Label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Radio
                                                checked={formik.values.bankingType === 'FinancialService'}
                                                id="financialService"
                                                {...formik.getFieldProps('bankingType')}
                                                value="FinancialService"
                                                onChange={() => {
                                                    setAccountBankForm(false);
                                                    setFinancialServiceForm(true);
                                                    setSwiftForm(false);
                                                    setAchForm(false);
                                                    setPaymentServiceForm(false);
                                                    formik.resetForm({
                                                        values: {
                                                            email: '',
                                                            bankingType: 'FinancialService',
                                                            paymentService: 'Paypal',
                                                        },
                                                    });
                                                    setTypeBanking('FinancialService');
                                                    setPaymentServiceForm(true);
                                                    formik.setFieldValue('bankingType', 'FinancialService');
                                                }}
                                            />
                                            <Label htmlFor="germany">Paypal</Label>
                                        </div>
                                    </div>
                                </fieldset>

                                {/* Account Bank Area */}
                                <div>
                                    {accountBankForm && (
                                        <div className="">
                                            <fieldset className="flex max-w-md flex-col gap-3 mb-5  ">
                                                <p className="">2- Define the account and complete the information.</p>
                                                <div className="flex gap-10">
                                                    <div className="flex items-center gap-2">
                                                        <Radio
                                                            checked={formik.values.transferMethod === 'ACH'}
                                                            id="ach"
                                                            {...formik.getFieldProps('transferMethod')}
                                                            value="ACH"
                                                            onChange={() => {
                                                                setSwiftForm(false);
                                                                setAchForm(true);
                                                                formik.resetForm({
                                                                    values: {
                                                                        accountHolder: '',
                                                                        accountNumber: '',
                                                                        accountType: 'CHECKING',
                                                                        achRoutingNumber: '',
                                                                        city: '',
                                                                        state: '',
                                                                        country: 'Afghanistan',
                                                                        bankingType: 'BankAccount',
                                                                        postCode: '',
                                                                        receiverType: 'BUSINESS',
                                                                        recepientAdress: '',
                                                                        transferMethod: 'ACH',
                                                                        targetCurrency: 'AFA',
                                                                    },
                                                                });
                                                                setAchOrSwift('ACH');
                                                                formik.setFieldValue('transferMethod', 'ACH');
                                                            }}
                                                        />
                                                        <Label htmlFor="ach">ACH</Label>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <Radio
                                                            id="SWIFT"
                                                            checked={formik.values.transferMethod === 'SWIFT'}
                                                            {...formik.getFieldProps('transferMethod')}
                                                            value="SWIFT"
                                                            onChange={() => {
                                                                setSwiftForm(true);
                                                                setAchForm(false);
                                                                formik.resetForm({
                                                                    values: {
                                                                        accountHolder: '',
                                                                        city: '',
                                                                        state: '',
                                                                        country: 'Afghanistan',
                                                                        bankingType: 'BankAccount',
                                                                        ibanRoutingNumber: '',
                                                                        postCode: '',
                                                                        receiverType: 'BUSINESS',
                                                                        recepientAdress: '',
                                                                        swiftBicCode: '',
                                                                        transferMethod: 'SWIFT',
                                                                        targetCurrency: 'AFA',
                                                                    },
                                                                });
                                                                setAchOrSwift('SWIFT');
                                                                formik.setFieldValue('transferMethod', 'SWIFT');
                                                            }}
                                                        />
                                                        <Label htmlFor="SWIFT">SWIFT</Label>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            {/* SWIFT FORM AREA */}
                                            <div className="">
                                                {swiftForm && (
                                                    <div className="flex flex-col gap-5">
                                                        <div className="flex flex-col gap-5">
                                                            {/* ACCOUNT INFORMATION */}
                                                            <div className="flex flex-col gap-3  ">
                                                                <p className="text-lg underline">Account Information</p>
                                                                <div className="flex flex-wrap ">
                                                                    <div className=" w-full lg:w-1/2 px-5">
                                                                        <div className="relative w-full mb-4 group">
                                                                            <div id="select">
                                                                                <div className=" block">
                                                                                    <Label
                                                                                        htmlFor="receiverType"
                                                                                        value="Select the type of your account"
                                                                                        className="text-xs font-normal"
                                                                                    />
                                                                                </div>
                                                                                <Select
                                                                                    theme={{
                                                                                        field: {
                                                                                            select: {
                                                                                                base: 'border-gray-300  dark:border-slate-600 w-full',
                                                                                                colors: {
                                                                                                    general:
                                                                                                        'bg-custom-background',
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    color="general"
                                                                                    id="receiverType"
                                                                                    required
                                                                                    {...formik.getFieldProps(
                                                                                        `receiverType`,
                                                                                    )}
                                                                                    name="receiverType"
                                                                                    helperText={
                                                                                        <React.Fragment>
                                                                                            <CustomError
                                                                                                name="receiverType"
                                                                                                component="div"
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    defaultValue="BUSINESS"
                                                                                >
                                                                                    <option value="BUSINESS">
                                                                                        BUSINESS
                                                                                    </option>
                                                                                    <option value="PERSON">
                                                                                        PERSON
                                                                                    </option>
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" flex gap-2 items-center ">
                                                                                <Label
                                                                                    htmlFor="SWIFT / BIC code"
                                                                                    value="SWIFT / BIC code *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                                <InfoIcon
                                                                                    content={
                                                                                        <>
                                                                                            <div>
                                                                                                Everything you need, to
                                                                                                find the right SWIFT/BIC
                                                                                                code for your transfer.
                                                                                                <span>
                                                                                                    <a
                                                                                                        href="https://wise.com/gb/swift-codes/"
                                                                                                        target="_blank"
                                                                                                        rel="noreferrer"
                                                                                                        className="hover:underline hover:text-blue-600 text-blue-500 font-normal uppercase"
                                                                                                    >
                                                                                                        Click here
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    placement="top"
                                                                                    iconStyle="w-5 h-5 mb-1"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="swiftBicCode"
                                                                                type="text"
                                                                                placeholder="Example : AAAA BB CC 123"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'swiftBicCode',
                                                                                )}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="swiftBicCode"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-4 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="targetCurrency"
                                                                                    value="Currency"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <Select
                                                                                theme={{
                                                                                    field: {
                                                                                        select: {
                                                                                            base: 'border-gray-300 w-full dark:border-slate-600 w-full',
                                                                                            colors: {
                                                                                                general:
                                                                                                    'bg-custom-background',
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                color="general"
                                                                                id="targetCurrency"
                                                                                required
                                                                                {...formik.getFieldProps(
                                                                                    `targetCurrency`,
                                                                                )}
                                                                                name="targetCurrency"
                                                                                helperText={
                                                                                    <React.Fragment>
                                                                                        <CustomError
                                                                                            name="targetCurrency"
                                                                                            component="div"
                                                                                        />
                                                                                    </React.Fragment>
                                                                                }
                                                                            >
                                                                                {currencies.map((currency) => {
                                                                                    return (
                                                                                        <option
                                                                                            value={currency.code}
                                                                                        >{`${currency.name} (${currency.code})`}</option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="lg:w-1/2  px-5 w-full">
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Account holder"
                                                                                    value="Account holder"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="name"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'accountHolder',
                                                                                )}
                                                                                placeholder="Specify your account name"
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="accountHolder"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="IBAN / Account number"
                                                                                    value="IBAN / Account number *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="ibanRoutingNumber"
                                                                                type="text"
                                                                                placeholder="Example: TN 12  ABCD 102030 12345678"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'ibanRoutingNumber',
                                                                                )}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="ibanRoutingNumber"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* PERSONAL INFORMATION */}
                                                            <div className="flex flex-col gap-3">
                                                                <p className="text-lg underline">
                                                                    Personal information
                                                                </p>
                                                                <div className="flex flex-wrap">
                                                                    <div className="lg:w-1/2  px-5 w-full">
                                                                        <div className="relative w-full mb-4 group">
                                                                            <div id="select">
                                                                                <div className=" block">
                                                                                    <Label
                                                                                        htmlFor="Country"
                                                                                        value="Country *"
                                                                                        className="text-xs font-normal"
                                                                                    />
                                                                                </div>
                                                                                <Select
                                                                                    theme={{
                                                                                        field: {
                                                                                            select: {
                                                                                                base: 'border-gray-300 w-full dark:border-slate-600 w-full',
                                                                                                colors: {
                                                                                                    general:
                                                                                                        'bg-custom-background',
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    color="general"
                                                                                    id="country"
                                                                                    required
                                                                                    {...formik.getFieldProps(`country`)}
                                                                                    name="country"
                                                                                    helperText={
                                                                                        <React.Fragment>
                                                                                            <CustomError
                                                                                                name="country"
                                                                                                component="div"
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                >
                                                                                    {countries.map((countryVal) => (
                                                                                        <option
                                                                                            value={countryVal.label}
                                                                                            key={countryVal.phone}
                                                                                        >
                                                                                            {countryVal.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="state"
                                                                                    value="State *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="state"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps('state')}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="state"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Post code"
                                                                                    value="Post code *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="postCode"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps('postCode')}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="postCode"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className=" w-full lg:w-1/2 px-5">
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="city"
                                                                                    value="City *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="city"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps('city')}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="city"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Recepient adress"
                                                                                    value="Recepient adress *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="recepient adress"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'recepientAdress',
                                                                                )}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="recepientAdress"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* LOGISTIO DETAILS */}
                                                            <div className="w-full  flex flex-col gap-3 ">
                                                                <p className="text-lg underline">Logistio details</p>
                                                                <div className="px-5">
                                                                    <div className="block">
                                                                        <Label
                                                                            htmlFor="Currency of our transfers"
                                                                            value="Currency of our transfers"
                                                                            className="text-xs font-normal"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        theme={customTheme}
                                                                        color="general"
                                                                        id="USD"
                                                                        type="text"
                                                                        sizing="md"
                                                                        value="USD"
                                                                        disabled
                                                                        readOnly
                                                                        className="w-full lg:w-1/2"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* SAVE CANCEL BUTTON */}
                                                        <div className="flex items-center justify-end gap-5 ">
                                                            <GeneralButton
                                                                color="gray"
                                                                size="md"
                                                                className="w-full max-w-28"
                                                                onClick={() => {
                                                                    setAccountBankForm(false);
                                                                    setFinancialServiceForm(false);
                                                                    setSwiftForm(false);
                                                                    setAchForm(false);
                                                                    formik.resetForm({ values: {} });
                                                                }}
                                                            >
                                                                Cancel
                                                            </GeneralButton>
                                                            <GeneralButton
                                                                type="submit"
                                                                size="md"
                                                                color="blue"
                                                                className="w-full max-w-28"
                                                            >
                                                                Save
                                                            </GeneralButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {/* ACH FORM AREA */}
                                            <div className="">
                                                {achForm && (
                                                    <div className="flex flex-col gap-5">
                                                        <div className="flex flex-col gap-5 ">
                                                            {/* ACCOUNT INFORMATION */}
                                                            <div className="flex flex-col gap-3 ">
                                                                <p className="text-lg underline">Account Information</p>
                                                                <div className="flex flex-wrap ">
                                                                    <div className=" w-full lg:w-1/2 px-5">
                                                                        <div className="relative w-full mb-4 group">
                                                                            <div id="select">
                                                                                <div className=" block">
                                                                                    <Label
                                                                                        htmlFor="receiverType"
                                                                                        value="Select the type of your account"
                                                                                        className="text-xs font-normal"
                                                                                    />
                                                                                </div>
                                                                                <Select
                                                                                    theme={{
                                                                                        field: {
                                                                                            select: {
                                                                                                base: 'border-gray-300 w-full dark:border-slate-600 w-full',
                                                                                                colors: {
                                                                                                    general:
                                                                                                        'bg-custom-background',
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    color="general"
                                                                                    id="receiverType"
                                                                                    required
                                                                                    {...formik.getFieldProps(
                                                                                        `receiverType`,
                                                                                    )}
                                                                                    name="receiverType"
                                                                                    helperText={
                                                                                        <React.Fragment>
                                                                                            <CustomError
                                                                                                name="receiverType"
                                                                                                component="div"
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                >
                                                                                    <option value="BUSINESS">
                                                                                        BUSINESS
                                                                                    </option>
                                                                                    <option value="PERSON">
                                                                                        Person
                                                                                    </option>
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Account number"
                                                                                    value="Account number *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="accountNumber"
                                                                                type="text"
                                                                                placeholder="Example: 00 123 456 789"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'accountNumber',
                                                                                )}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="accountNumber"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div id="select">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="type"
                                                                                    value="Account Type"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <Select
                                                                                theme={{
                                                                                    field: {
                                                                                        select: {
                                                                                            base: 'border-gray-300 w-full dark:border-slate-600',
                                                                                            colors: {
                                                                                                general:
                                                                                                    'bg-custom-background',
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                color="general"
                                                                                id="type"
                                                                                required
                                                                                {...formik.getFieldProps(`accountType`)}
                                                                                name="accountType"
                                                                                helperText={
                                                                                    <React.Fragment>
                                                                                        <CustomError
                                                                                            name="accountType"
                                                                                            component="div"
                                                                                        />
                                                                                    </React.Fragment>
                                                                                }
                                                                            >
                                                                                <option value="CHECKING">
                                                                                    CHECKING
                                                                                </option>
                                                                                <option value="SAVING">SAVING</option>
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="lg:w-1/2  px-5 w-full">
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Account holder"
                                                                                    value="Account holder"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="name"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'accountHolder',
                                                                                )}
                                                                                placeholder="Specify your account name"
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="accountHolder"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="ACH routing number"
                                                                                    value="ACH routing number *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="achRoutingNumber"
                                                                                type="text"
                                                                                placeholder="Example : 999 888 777"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'achRoutingNumber',
                                                                                )}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="achRoutingNumber"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-4 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="targetCurrency"
                                                                                    value="Currency"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <Select
                                                                                theme={{
                                                                                    field: {
                                                                                        select: {
                                                                                            base: 'border-gray-300 w-full dark:border-slate-600 w-full',
                                                                                            colors: {
                                                                                                general:
                                                                                                    'bg-custom-background',
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                color="general"
                                                                                id="targetCurrency"
                                                                                required
                                                                                {...formik.getFieldProps(
                                                                                    `targetCurrency`,
                                                                                )}
                                                                                name="targetCurrency"
                                                                                helperText={
                                                                                    <React.Fragment>
                                                                                        <CustomError
                                                                                            name="targetCurrency"
                                                                                            component="div"
                                                                                        />
                                                                                    </React.Fragment>
                                                                                }
                                                                            >
                                                                                {currencies.map((currency) => {
                                                                                    return (
                                                                                        <option
                                                                                            value={currency.code}
                                                                                        >{`${currency.name} (${currency.code})`}</option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* PERSONAL INFORMATION */}
                                                            <div className="flex flex-col gap-3">
                                                                <p className="text-lg underline">
                                                                    Personnel information
                                                                </p>
                                                                <div className="flex flex-wrap">
                                                                    <div className="lg:w-1/2  px-5 w-full">
                                                                        <div className="relative w-full mb-4 group">
                                                                            <div id="select">
                                                                                <div className=" block">
                                                                                    <Label
                                                                                        htmlFor="Country"
                                                                                        value="Country *"
                                                                                        className="text-xs font-normal"
                                                                                    />
                                                                                </div>
                                                                                <Select
                                                                                    theme={{
                                                                                        field: {
                                                                                            select: {
                                                                                                base: 'border-gray-300 w-full dark:border-slate-600 w-full',
                                                                                                colors: {
                                                                                                    general:
                                                                                                        'bg-custom-background',
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    color="general"
                                                                                    id="country"
                                                                                    required
                                                                                    {...formik.getFieldProps(`country`)}
                                                                                    name="country"
                                                                                    helperText={
                                                                                        <React.Fragment>
                                                                                            <CustomError
                                                                                                name="country"
                                                                                                component="div"
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                >
                                                                                    {countries.map((countryVal) => (
                                                                                        <option
                                                                                            value={countryVal.label}
                                                                                            key={countryVal.phone}
                                                                                        >
                                                                                            {countryVal.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="state"
                                                                                    value="State *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="state"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps('state')}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="state"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Post code"
                                                                                    value="Post code *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="postCode"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps('postCode')}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="postCode"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className=" w-full lg:w-1/2 px-5">
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="city"
                                                                                    value="City *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="city"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps('city')}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="city"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="relative w-full mb-6 group">
                                                                            <div className=" block">
                                                                                <Label
                                                                                    htmlFor="Recepient adress"
                                                                                    value="Recepient adress *"
                                                                                    className="text-xs font-normal"
                                                                                />
                                                                            </div>
                                                                            <TextInput
                                                                                theme={customTheme}
                                                                                color="general"
                                                                                id="recepient adress"
                                                                                type="text"
                                                                                sizing="md"
                                                                                {...formik.getFieldProps(
                                                                                    'recepientAdress',
                                                                                )}
                                                                                helperText={
                                                                                    <>
                                                                                        <CustomError
                                                                                            name="recepientAdress"
                                                                                            component="div"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* LOGISTIO DETAILS */}
                                                            <div className="w-full  flex flex-col gap-3">
                                                                <p className="text-lg underline">Logistio details</p>
                                                                <div className="px-5">
                                                                    <div className="block ">
                                                                        <Label
                                                                            htmlFor="Currency of our transfers"
                                                                            value="Currency of our transfers"
                                                                            className="text-xs font-normal"
                                                                        />
                                                                    </div>
                                                                    <TextInput
                                                                        theme={customTheme}
                                                                        color="general"
                                                                        id="USD"
                                                                        type="text"
                                                                        sizing="md"
                                                                        value="USD"
                                                                        disabled
                                                                        readOnly
                                                                        className="w-full lg:w-1/2"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* SAVE CANCEL BUTTON */}
                                                        <div className="flex items-center justify-end gap-5">
                                                            <GeneralButton
                                                                color="gray"
                                                                size="md"
                                                                className="w-full max-w-28"
                                                                onClick={() => {
                                                                    setAccountBankForm(false);
                                                                    setFinancialServiceForm(false);
                                                                    setSwiftForm(false);
                                                                    setAchForm(false);
                                                                    formik.resetForm({ values: {} });
                                                                }}
                                                            >
                                                                Cancel
                                                            </GeneralButton>
                                                            <GeneralButton
                                                                type="submit"
                                                                size="md"
                                                                color="blue"
                                                                className="w-full max-w-28"
                                                            >
                                                                Save
                                                            </GeneralButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Finance Service Area */}
                                <div className="">
                                    {financialServiceForm && (
                                        <div className="flex flex-col gap-5">
                                            <fieldset className="flex max-w-md flex-col gap-3  ">
                                                <p className="">2- Select one of the available services.</p>
                                                <div className="flex gap-10">
                                                    {/* <div className="flex items-center gap-2">
                                                        <Radio
                                                            id="Payoneer"
                                                            checked={formik.values.paymentService === 'Payoneer'}
                                                            {...formik.getFieldProps('paymentService')}
                                                            value="Payoneer"
                                                            onChange={() => {
                                                                setPaymentServiceForm(true);
                                                                formik.setFieldValue('paymentService', 'Payoneer');
                                                            }}
                                                        />
                                                        <Label htmlFor="united-state">Payoneer</Label>
                                                    </div> */}
                                                    {/* <div className="flex items-center gap-2">
                                                        <Radio
                                                            checked={formik.values.paymentService === 'Paypal'}
                                                            id="Paypal"
                                                            {...formik.getFieldProps('paymentService')}
                                                            value="Paypal"
                                                            onChange={() => {
                                                                setPaymentServiceForm(true);
                                                                formik.setFieldValue('paymentService', 'Paypal');
                                                            }}
                                                        />
                                                        <Label htmlFor="germany">Paypal</Label>
                                                    </div> */}
                                                </div>
                                            </fieldset>
                                            <div className="">
                                                {paymentServiceForm && (
                                                    <div className="flex flex-col gap-2">
                                                        <div className=" w-full  flex flex-col gap-3 ">
                                                            <div className=" block">
                                                                <Label htmlFor="Email" value="Email *" />
                                                            </div>
                                                            <TextInput
                                                                theme={customTheme}
                                                                color="general"
                                                                id="email"
                                                                type="text"
                                                                placeholder="Example: name@gmail.com"
                                                                sizing="md"
                                                                {...formik.getFieldProps('email')}
                                                                helperText={
                                                                    <>
                                                                        <CustomError name="email" component="div" />
                                                                    </>
                                                                }
                                                                className=" w-full lg:w-1/2"
                                                            />
                                                        </div>

                                                        <div className="w-full  flex flex-col gap-3">
                                                            <p className="text-lg underline">Logistio details</p>
                                                            <div className="px-5">
                                                                <div className="block">
                                                                    <Label
                                                                        htmlFor="Currency of our transfers"
                                                                        value="Currency of our transfers"
                                                                        className="text-xs font-normal"
                                                                    />
                                                                </div>
                                                                <TextInput
                                                                    theme={customTheme}
                                                                    color="general"
                                                                    id="USD"
                                                                    type="text"
                                                                    placeholder="Example: name@gmail.com"
                                                                    sizing="md"
                                                                    value="USD"
                                                                    disabled
                                                                    readOnly
                                                                    className="w-full lg:w-1/2"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center justify-end gap-5">
                                                            <GeneralButton
                                                                color="gray"
                                                                size="md"
                                                                className="w-full max-w-28"
                                                                onClick={() => {
                                                                    setAchForm(false);
                                                                    setSwiftForm(false);
                                                                    setFinancialServiceForm(false);
                                                                    setAccountBankForm(false);
                                                                    setPaymentServiceForm(false);
                                                                    formik.resetForm({
                                                                        values: {},
                                                                    });
                                                                }}
                                                            >
                                                                Cancel
                                                            </GeneralButton>
                                                            <GeneralButton
                                                                type="submit"
                                                                size="md"
                                                                color="blue"
                                                                disabled={loader}
                                                                isProcessing={loader}
                                                                className="w-full max-w-28"
                                                            >
                                                                Save
                                                            </GeneralButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CustomerBankInformation;
