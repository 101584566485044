/* eslint-disable prettier/prettier */
import React from 'react';

const ConsentScreen = () => {
    window.location.href = `https://app.lightfunnels.com/admin/oauth?client_id=${process.env.REACT_APP_LF_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_LF_URI}&scope=orders,funnels,products&response_type=code`;
    //= ===================================================================================================================
    // window.location.href = `https://app.lightfunnels.com/admin/oauth?client_id=${
    //     process.env.REACT_APP_LF_CLIENT_ID
    // }&redirect_uri=${`http://localhost:3000/redirects/lightfunnels`}&scope=orders,funnels,products&state=123`;

    return <p>logistio Integration....</p>;
};

export default ConsentScreen;
