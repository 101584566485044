import React from 'react';
import './flagsIcons.css';

const FlagIcon: React.FC<{ flag: string }> = ({ flag }) => {
    return (
        <>
            <span className={`flag-icon flag-icon-${flag.toLocaleLowerCase()}`} />
        </>
    );
};

export default FlagIcon;
