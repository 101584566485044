import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputField from 'components/inputField/InputField';
import CustomButton from 'components/customButton/CustomButton';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomError from 'components/customError/CustomError';
import { resetPassword } from 'services/auth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { BoxContainer, Container } from './ResetPassword.styles';

const ResetPassword = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={(values) => {
                    resetPassword(values.email)
                        .then((res) => {
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('refreshToken', res.data.refreshToken);
                            toast.success('Password reset with success');
                        })
                        .then(() => {
                            setTimeout(() => {
                                navigate('/auth/login');
                            }, 1000);
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.errors?.message);
                        });
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Email is invalid').required('Email is required'),
                })}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Box sx={BoxContainer}>
                            <Typography variant="h4" component="div">
                                Password Recovery
                            </Typography>
                            <Typography variant="body1" component="div">
                                Enter your email and we’ll send you a new password.
                            </Typography>

                            <InputField
                                InputType="email"
                                label="Email"
                                placeholder="jhondoe@email.com"
                                // returns onChange, onBlur and value related to this input field
                                {...formik.getFieldProps('email')}
                                name="email"
                            />
                            <CustomError name="email" component="Box" />
                            <CustomButton
                                type="submit"
                                className="btn"
                                styleType="primary"
                                label="Reset Password"
                                sizeType="large"
                            />
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default ResetPassword;
