/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { getCurrentUser } from 'utils/helpers/auth';
import EmaiPart from './emailSection/emaiPart';
import PasswordPart from './passwordSection/passwordPart';
import ProfilePicPart from './personnalInfoSection/profilePicPart';
import ProfileSkeletonLoader from './profileSkeletonLoader';

const ProfilePage = () => {
    const [customer, setCustomer] = useState<ICustomer>();
    const customerId = getCurrentUser()._id;
    const [customerLoader, setCustomerLoader] = useState(false);

    useEffect(() => {
        setCustomerLoader(true);
        getCustomer(customerId)
            .then((res) => {
                setCustomer(res.data);
                setCustomerLoader(false);
            })
            .catch(() => {
                toast.error('Something went wrong, please try again !');
                setCustomerLoader(false);
            });
    }, []);

    return (
        <div className=" my-10 ">
            {customer && !customerLoader ? (
                <div className=" flex flex-col gap-5">
                    <ProfilePicPart
                        customer={customer as ICustomer}
                        customerId={customerId}
                        setCustomer={setCustomer}
                    />
                    <hr className="my-12 h-px border-t-0 bg-gray-300 dark:bg-gray-700" />
                    <div>
                        <EmaiPart customerEmail={customer?.email} customerId={customerId} />
                    </div>
                    <hr className="my-12 h-px border-t-0 bg-gray-300 dark:bg-gray-700" />
                    <div>
                        <PasswordPart customerId={customerId} />
                    </div>
                </div>
            ) : (
                <ProfileSkeletonLoader />
            )}
        </div>
    );
};

export default ProfilePage;
