import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'flowbite-react';
import { IWarning } from 'utils/Interfaces/warnings.interface';
import { IStock } from 'services/stock';
import { vnlinWarehouses } from 'utils/helpers/vnlin/warehouses';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { getCustomer } from 'services/customer';
import { getCurrentUser } from 'utils/helpers/auth';
import useMediaQueries from 'utils/hooks/useMediaQuery';
import { v4 as uuidv4 } from 'uuid';

const warehouses: { [id: string]: (typeof vnlinWarehouses)[keyof typeof vnlinWarehouses] } = {};
Object.values(vnlinWarehouses).forEach((wh) => {
    warehouses[wh.id] = wh;
});

const LowStocksModal: React.FC<{ warnings: IWarning[] }> = ({ warnings }) => {
    const [openModal, setOpenModal] = useState(false);
    const [stocksThreshold, setStocksThreshold] = useState(0);

    useEffect(() => {
        // eslint-disable-next-line no-underscore-dangle
        getCustomer(getCurrentUser()._id).then((res) => {
            setStocksThreshold(res.data.stocksThreshold);
        });
    }, []);

    const mediaQueries = useMediaQueries();
    return (
        <>
            <Button
                size="xs"
                pill
                color="failure"
                onClick={() => {
                    setOpenModal(true);
                }}
            >
                <p>See More</p>
                <HiOutlineArrowRight className="ml-2 h-3 w-5" />
            </Button>
            <Modal
                dismissible
                show={openModal}
                popup
                size={mediaQueries.maxSm ? 'md' : '2xl'}
                onClose={() => setOpenModal(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999] h-screen',
                        },
                    },
                }}
            >
                <Modal.Header>Low stock warnings</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    <div className="p-3" key={uuidv4()}>
                        {warnings.map((warning) => {
                            return warning.aboutModel === 'Stock' ? (
                                <div
                                    key={uuidv4()}
                                    className="m-1 p-4 dark:bg-gray-800 bg-gray-100 rounded-md border-0"
                                >
                                    <p>
                                        Product:{' '}
                                        <span className="font-medium">
                                            {(warning?.aboutId as IStock)?.product?.itemKind === 'variant'
                                                ? `${(warning?.aboutId as IStock)?.parentProduct?.name} ${(
                                                      warning?.aboutId as IStock
                                                  )?.product?.name}`
                                                : (warning?.aboutId as IStock)?.product?.name}
                                        </span>
                                    </p>
                                    <div className="m-2 mx-0">
                                        <p>
                                            Country:{' '}
                                            <span className="font-medium">{(warning?.aboutId as IStock)?.country}</span>
                                        </p>
                                        <p>
                                            Warehouse:{' '}
                                            <span className="font-medium">
                                                {(warning?.aboutId as IStock)?.country} Warehouse{' '}
                                            </span>{' '}
                                        </p>
                                    </div>
                                    <div className="m-2 mx-0">
                                        {(warning.aboutId as IStock)?.product?.itemKind === 'variant' ? (
                                            <div
                                                key={uuidv4()}
                                                className={`flex gap-2 w-fit my-1 p-1 px-2 border-[1px] rounded-md ${
                                                    Number((warning.aboutId as IStock).availableQuantity) <=
                                                    warning.threshold
                                                        ? 'border-red-500 text-red-500'
                                                        : ''
                                                }`}
                                            >
                                                <p>
                                                    Variant:{' '}
                                                    <span className="font-medium">
                                                        {(warning.aboutId as IStock)?.product?.name}
                                                    </span>{' '}
                                                </p>
                                                <div className="border-2 rounded-md h-5" />
                                                <p>
                                                    Sku:{' '}
                                                    <span className="font-medium">
                                                        {(warning.aboutId as IStock)?.product?.globalSKU ?? '-'}
                                                    </span>{' '}
                                                </p>
                                                <div className="border-2 rounded-md h-5" />
                                                <p>
                                                    Available quantity:{' '}
                                                    <span className="font-medium">
                                                        {(warning.aboutId as IStock)?.availableQuantity}
                                                    </span>{' '}
                                                </p>
                                            </div>
                                        ) : (
                                            <div
                                                className={`flex gap-2 w-fit my-1 p-1 px-2 border-[1px] rounded-md ${
                                                    Number((warning.aboutId as IStock)?.availableQuantity) <=
                                                    warning.threshold
                                                        ? 'border-red-500 text-red-500'
                                                        : ''
                                                }`}
                                                key={uuidv4()}
                                            >
                                                <p>
                                                    Sku:{' '}
                                                    <span className="font-medium">
                                                        {(warning?.aboutId as IStock)?.parentProduct?.globalSKU ?? '-'}
                                                    </span>{' '}
                                                </p>
                                                <div className="border-2 rounded-md h-5" />
                                                <p>
                                                    Available quantity:{' '}
                                                    <span className="font-medium">
                                                        {(warning.aboutId as IStock)?.availableQuantity}
                                                    </span>{' '}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <p>
                                        Min. quantity: <span className="font-medium">{stocksThreshold}</span>
                                    </p>
                                </div>
                            ) : null;
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LowStocksModal;
