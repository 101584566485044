import React from 'react';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { ColorModeContext } from 'utils/contexts/theme';
import { useLocation } from 'react-router-dom';

const ThemeSwitch = () => {
    const colorMode = React.useContext(ColorModeContext);
    const { pathname } = useLocation();
    const isTrackingPages = ['/tracking', '/tracking/tracking-results'].includes(pathname);

    const getBoxStyles = () => {
        if (!isTrackingPages) return null;
        return { border: '1px solid', borderColor: colorMode.mode ? '#FFFFFF' : '#A8C7FF', borderRadius: '4px' };
    };
    return (
        <>
            <Box
                sx={{
                    color: 'text.primary',
                    ...getBoxStyles(),
                }}
            >
                <IconButton
                    // sx={{ ml: 1 }}
                    onClick={() => {
                        colorMode.colorMode();
                    }}
                    color="inherit"
                >
                    {colorMode.mode ? (
                        <WbSunnyOutlinedIcon sx={{ color: isTrackingPages ? '#0057FF' : 'navBarIconColor' }} />
                    ) : (
                        <Brightness2OutlinedIcon sx={{ color: isTrackingPages ? '#007FFF' : 'navBarIconColor' }} />
                    )}
                </IconButton>
            </Box>
        </>
    );
};

export default ThemeSwitch;
