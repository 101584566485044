import axios from 'utils/axios/axios';

export const login = (email: string, password: string) => {
    return axios.post(`/auth/signin`, { email, password });
};

export const signup = async (email: string) => {
    const response = await axios.post(`/auth/signup`, { email });
    return response;
};

export const resetPassword = async (email: string) => {
    const response = await axios.post(`/auth/reset`, { email });
    return response;
};
