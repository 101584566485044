export const vnlinWarehouses = {
    'United Arab Emirates': {
        id: '64',
        name: 'UAE warehouse',
        transportMode: 7630,
    },
    Oman: {
        id: '64',
        name: 'OM warehouse',
        transportMode: 7630,
    },
    Bahrain: {
        id: '64',
        name: 'BH warehouse',
        transportmode: 7630,
    },
    Qatar: { id: '90', name: 'Qatar warehouse', transportMode: 7625 },
    Kuwait: { id: '91', name: 'Kuwait warehouse', transportMode: 7626 },
    'Saudi Arabia': {
        id: '99',
        name: 'Riyadh Second Warehouse',
        transportMode: 7622,
    },
};
