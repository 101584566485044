import TrackingPageNavbar from 'components/navbar/trackingPageNavbar';
import React from 'react';
import { Outlet } from 'react-router-dom';

const TrackingLayout = () => {
    return (
        <div className="flex bg-[#E8EBEF] dark:bg-[#000246]">
            <div className="w-full h-screen overflow-y-hidden flex flex-col  ">
                <TrackingPageNavbar />
                <div className=" flex-1 h-full">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default TrackingLayout;
