/* eslint-disable react/style-prop-object */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { getCurrentUser } from 'utils/helpers/auth';
import toast from 'react-hot-toast';
import { ICurrentStateStats, getCurrentStateStats } from 'services/order';

import WidgetPercentIcon from 'components/dashboardCard/widgetPercentIcon';
import { orderStatusMatching } from 'pages/orders/Orders.styles';
import { IfilterDashboard } from '../dashboard.types';

// import AccountingDashboardBasedLeads from './dashboardLeadBased/accountingDashboard';
// import LeadsDashboardBasedLeads from './dashboardLeadBased/leadsDashboard';
// import OrdersDashboardBasedLeads from './dashboardLeadBased/ordersDashboard';

const CurrentStateStats = ({ filter }: { filter: IfilterDashboard }) => {
    const [stats, setStats] = useState<ICurrentStateStats[]>([]);
    const [statsData, setStatsData] = useState<{}>({});
    const [totalCountStats, setTotalCountStats] = useState<number>(0);

    // const [customerCurrency, setCustomerCurrency] = useState('USD');
    // const [customer, setCustomer] = useState<any>({});

    const statusMatch: any = {
        2: 'Canceled Leads',
        3: 'Unreachable Leads',
        43: 'Scheduled Calls',
        41: 'Pending Answers',
        42: 'Questions Answered',
        8: 'Pick & Pack Orders',
        14: 'Invalid Leads',
        13: 'Expired Leads',
    };

    useEffect(() => {
        getCurrentStateStats(filter)
            .then((res) => {
                const data: {
                    'Failed Deliveries'?: number;
                    'Being delivered'?: number;
                } = {};
                let totalCount = 0;
                res.data.map((stat) => {
                    if (stat.deliveryFailed) {
                        data['Failed Deliveries'] = (data['Failed Deliveries'] || 0) + stat.count;
                    } else if (
                        [81, 82, 83, 84, 9, 10, 101, 102, 103, 104, 105].includes(stat.status) &&
                        !stat.deliveryFailed
                    ) {
                        data['Being delivered'] = (data['Being delivered'] || 0) + stat.count;
                    }
                    totalCount += stat.count;
                });
                setTotalCountStats(totalCount);
                setStatsData(data);
                setStats(res.data.sort((a, b) => a.status - b.status));
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [filter]);

    return (
        <div>
            {stats.length ? (
                <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                    {stats.map((stat) =>
                        stat.status === 1 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title={orderStatusMatching(stat.status).title}
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}
                    {stats.map((stat) =>
                        stat.status === 99 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title={orderStatusMatching(stat.status).title}
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}
                    {stats.map((stat) =>
                        stat.status === 21 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title="Wrong Numbers"
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}
                    {stats.map((stat) =>
                        [2, 3, 41, 42, 43, 4].includes(stat.status) ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title={statusMatch[stat.status]}
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}

                    {stats.map((stat) =>
                        stat.status === 8 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title="Pick & Pack Orders"
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}

                    {Object.keys(statsData).length
                        ? Object.keys(statsData).map((dataType, x) => (
                              <WidgetPercentIcon
                                  key={Object.keys(statsData)[x]}
                                  title={dataType}
                                  inputValue={statsData[dataType as keyof typeof statsData]}
                                  statPourcentage={
                                      totalCountStats > 0
                                          ? (statsData[dataType as keyof typeof statsData] / totalCountStats) * 100
                                          : 0
                                  }
                              />
                          ))
                        : null}
                    {stats.map((stat) =>
                        stat.status === 14 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title="Invalid Leads"
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}
                    {stats.map((stat) =>
                        stat.status === 13 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title="Expired Leads"
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}

                    {stats.map((stat) =>
                        stat.status === 100 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title={orderStatusMatching(stat.status).title}
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}

                    {stats.map((stat) =>
                        stat.status === 12 ? (
                            <WidgetPercentIcon
                                key={stat.status}
                                title="Delivered Orders"
                                inputValue={stat.count}
                                statPourcentage={totalCountStats > 0 ? (stat.count / totalCountStats) * 100 : 0}
                            />
                        ) : null,
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default CurrentStateStats;
