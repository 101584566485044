import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getProductWithSkus } from 'services/product';
import { IProduct } from 'utils/Interfaces/products.interface';
import toast from 'react-hot-toast';
import SimpleProd from './ProductInfoDisplay/simpleProd';
import ProdWithVariants from './ProductInfoDisplay/prodWithVariants';

const ProductInfo = () => {
    const location: any = useLocation();
    const [product, setProduct] = useState<IProduct | null>();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        getProductWithSkus(location.state.productId)
            .then((res) => {
                setProduct(res);
                setIsLoading(false);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, []);

    return (
        <>
            {isLoading ? (
                <div className="grid grid-col-1 md:grid-cols-3 mt-5 gap-10">
                    <div>
                        <div
                            role="status"
                            className="flex items-center justify-center h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                        >
                            <svg
                                className="w-10 h-10 text-gray-200 dark:text-gray-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 16 20"
                            >
                                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div className="col-span-2">
                        <div role="status" className="max-w-xl animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mb-4" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[420px] mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]" />
                            <span className="sr-only">Loading...</span>
                        </div>

                        <div
                            role="status"
                            className="mt-5 max-w-lg p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
                        >
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5" />
                            <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700" />
                            <div className="flex items-baseline mt-4 space-x-6">
                                <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700" />
                                <div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700" />
                                <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700" />
                                <div className="w-full h-64 bg-gray-200 rounded-t-lg dark:bg-gray-700" />
                                <div className="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700" />
                                <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700" />
                                <div className="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700" />
                            </div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {product?.variants && product.variants.length > 0 ? <ProdWithVariants product={product} /> : null}
                    {product?.quantity && product.quantity > 0 ? <SimpleProd product={product} /> : null}
                </div>
            )}
        </>
    );
};

export default ProductInfo;
