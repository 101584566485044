export const StoreListItemStyle = {
    border: '1px solid',
    borderColor: 'borderColor',
    borderRadius: '8px',
    margin: '5px 0',
    backgroundColor: 'primaryBackground',
};
export const storeListHeader = {
    display: 'grid',
    gridTemplateColumns: '11fr 0.5fr 0.5fr',
    gridGap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'primary.main',
};
export const iconStyle = {
    color: 'textColor.tertiary',
    cursor: 'pointer',
};

export const editStyle = {
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
};

export const configWrapper = { padding: '10px', display: 'flex', flexDirection: 'column', gap: '20px' };

export const configItem = { display: 'grid', gridTemplateColumns: '6fr 6fr', gridGap: '30px' };

export const buttonStyle = { display: 'flex', justifyContent: 'flex-end' };
