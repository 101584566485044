export const AvatarContainer = (isCollapsed: boolean) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: isCollapsed ? 'center' : 'flex-start',
        width: isCollapsed ? '60%' : '90%',
        margin: '8px',
        cursor: 'pointer',
    };
};
export const AvatarStyle = { backgroundColor: 'primary.dark', fontSize: '16px', color: 'textColor.quaternary' };
export const ShopDetails = { marginLeft: '10px' };
export const ShopName = { color: 'textColor.secondary' };
