import axios from 'utils/axios/axios';
import { ResellerProduct } from 'pages/sellers/reseller.types';
import { IPaginatedResellerProduct } from 'utils/Interfaces/resellersProducts.interface';
import { getBearerToken } from 'utils/helpers/auth';
import { ICustomer } from 'utils/Interfaces/customer.interface';

export interface Ipagination {
    page: number;
    limit: number;
    type?: number;
}

export interface Ifilter {
    name?: string;
    shippingTo?: [string];
    status?: 1 | 2 | 3 | 4;
    openForSale?: boolean;
    categories?: [string];
    priceDisplay?: 'lowToHigh' | 'highToLow';
}

interface IData {
    resellerProductId: string;
    storeId: string;
}

export interface ResellerProductPricingHistory {
    resellerProduct: ResellerProduct | string;
    country: string;
    oldPrice: number;
    newPrice: number;
    seenBy: (string | ICustomer)[];
    createdAt?: string;
    updatedAt?: string;
}
export const getResellersProducts = async (pagination: Ipagination, filter: Ifilter) => {
    const config = await getBearerToken();
    const response = await axios.post<IPaginatedResellerProduct<ResellerProduct>>(
        `/reseller/products/filter`,
        { ...pagination, ...filter },
        config,
    );
    return response;
};

export const getResellerProduct = async (id: string) => {
    const config = await getBearerToken();
    const resellerProduct = await axios.get<ResellerProduct>(`/reseller/products/getById/${id}`, config);
    return resellerProduct;
};

export const addProductToCustomer = async (data: IData) => {
    const config = await getBearerToken();
    const response = await axios.post(`/reseller/products/add`, data, config);
    return response;
};

export const getResellerProductByCustomer = async (pagination: Ipagination, filter: Ifilter) => {
    const config = await getBearerToken();
    const response = await axios.post<IPaginatedResellerProduct<ResellerProduct>>(
        `/reseller/products/getForCustomer`,
        { ...pagination, ...filter },
        config,
    );
    return response;
};

export const removeResellerProductFromCustomerList = async (resellerProductId: string) => {
    const config = await getBearerToken();
    const response = await axios.post(`/reseller/products/remove`, { resellerProductId }, config);
    return response;
};

export const getUnseenResellerProductPricingUpdates = async (skus: string[]) => {
    const config = await getBearerToken();
    const response = await axios.post<ResellerProductPricingHistory[]>(
        `/reseller/products/unseenPricingHistory`,
        { skus },
        config,
    );
    return response;
};

export const getFile = async (url: string) => {
    const config = await getBearerToken();
    const response = await axios.get<Buffer>(`/reseller/products/getFile?url=${url}`, {
        ...config,
        responseType: 'arraybuffer',
    });
    return response;
};
