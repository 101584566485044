// import { styled } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';

export const LabelStyling = {
    // color: '#666666',
    // fontSize: '14px',
    // fontWeight: '500',
};

export const StyledTextField = {
    width: '100%',
    margin: '10px 0px',
    borderRadius: '8px',
    outline: 'none',
    // height: '50px',
    // backgroundColor: 'inputBackground',
};
