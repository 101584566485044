import JSZip from 'jszip';
import toast from 'react-hot-toast';
import { getFile } from 'services/reseller/resellerProducts';

function getFileExtension(url: any) {
    const parts = url.split('.');
    return parts[parts.length - 1];
}

export function downloadFilesAsBase64(fileUrls: string[], productName: string) {
    const zip = new JSZip();
    const fetchPromises: Promise<any>[] = [];

    fileUrls.forEach((url: string, index: number) => {
        const filename = `file_${index + 1}`;

        const fetchPromise = fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                return new Promise<string>((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result as string);
                    };
                    reader.readAsDataURL(blob);
                });
            })
            .then((base64Data: string) => {
                zip.file(`${filename}.${getFileExtension(url)}`, base64Data.split(',')[1], { base64: true });
            })
            .catch(() => {
                // console.log(`Error fetching file from ${url}`);
            });

        fetchPromises.push(fetchPromise);
    });

    // Use Promise.all to wait for all fetchPromises to complete
    Promise.all(fetchPromises).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const url = window.URL.createObjectURL(content);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${productName}` || 'Product';
            a.click();

            window.URL.revokeObjectURL(url);
        });
    });
}

export function downloadVideos(fileUrls: string[], productName: string, setLoading?: any) {
    const zip = new JSZip();
    const fetchPromises: Promise<any>[] = [];
    const toastLoader = toast.loading('Downloading videos in progress...');
    setLoading(true);
    fileUrls.forEach((url: string, index: number) => {
        const filename = `file_${index + 1}`;
        const extension = getFileExtension(url);
        const fetchPromise = getFile(url.startsWith('https://') ? url : `https://${url}`)
            .then((response) => {
                const { data: buffer, headers } = response;
                const contentType = headers['content-type'];
                return new Blob([buffer], { type: `${contentType}` || `video/${extension}` });
            })
            .then((blob) => {
                toast.success(`Video ${filename} downloaded`, { id: toastLoader });
                zip.file(`${filename}.${extension}`, blob);
            })
            .catch(() => {
                toast.error(`Sorry, Failed to download videos.`, { id: toastLoader });
                setLoading(false);
            });
        fetchPromises.push(fetchPromise);
    });

    // Use Promise.all to wait for all fetchPromises to complete
    Promise.all(fetchPromises).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const url = window.URL.createObjectURL(content);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${productName}` || 'Product';
            a.click();

            window.URL.revokeObjectURL(url);

            setLoading(false);
            toast.success('Videos successfully downloaded ', { id: toastLoader });
        });
    });
}
export function downloadSingleFileAsBase64(fileUrl: string, productName: string) {
    const fetchPromises: Promise<any>[] = [];
    const links: HTMLAnchorElement[] = [];

    const filename = `${productName}`;

    const fetchPromise = fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
            return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(blob);
            });
        })
        .then((base64Data: string) => {
            const link = document.createElement('a');

            link.setAttribute('download', `${filename}`);

            link.setAttribute('href', base64Data);
            links.push(link);
        })
        .catch(() => {
            // console.log('error');
        });

    fetchPromises.push(fetchPromise);

    Promise.all(fetchPromises).then(() => {
        links.forEach((link) => {
            link.click();
        });
    });
}

export function openPdfInNewTab(fileUrl: string, productName: string) {
    const fetchPromise = fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
            return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(blob);
            });
        })
        .then((base64Data: string) => {
            // Creating a new HTML document with title and iframe
            const newDocument = document.implementation.createHTMLDocument();
            // naming the PDF file while opening it
            newDocument.title = `${productName} PDF`;
            newDocument.body.innerHTML = `<iframe width='100%' height='100%' src='${base64Data}'></iframe>`;

            // Open the new document in a new window
            const newTab = window.open();
            if (newTab) {
                newTab.document.write(newDocument.documentElement.outerHTML);
            } else {
                // console.log('Could not open new tab.');
            }
        })
        .catch(() => {
            // console.log(`Error fetching file from ${fileUrl}`);
        });

    return fetchPromise;
}
