import { Modal, Table } from 'flowbite-react';
import moment from 'moment';
import { IInvoiceActions } from 'pages/invoice/invoice.types';
import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import { paymentStatus, statusDisplay } from './invoiceListElements';

// TODO: Implement the appropriat actions for the invoice

// eslint-disable-next-line no-unused-vars
const CodInvoiceActions = (props: IInvoiceActions) => {
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const { invoice } = props;
    const CustomerCurrency = 'USD';
    const totalFees =
        invoice.shippedLeads +
        invoice.confirmedLeads +
        invoice.addedLeads +
        invoice.deliveredLeads +
        invoice.deliveredForCC +
        invoice.totalPercentFromSellPrice +
        (invoice?.handleFees || 0) +
        (invoice?.resellerFees || 0) +
        invoice.upDownCrossSellFees;

    return (
        <>
            <Modal
                dismissible
                show={openInvoiceModal}
                popup
                onClose={() => setOpenInvoiceModal(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999] h-screen',
                        },
                    },
                }}
            >
                <Modal.Header>
                    <div>Invoice</div>
                    <div> {statusDisplay[invoice.status]?.statusIcon}</div>
                </Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    <div className="flex w-full justify-between px-2 pb-2">
                        <div>
                            <span className="font-medium text-gray-900 dark:text-white">Date</span>{' '}
                            {moment(invoice.createdAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                    <div className="px-2 pb-2">
                        <span className="font-medium text-gray-900 dark:text-white">Reference</span> {invoice.reference}
                    </div>
                    <div className="px-2">
                        <span className="font-medium text-gray-900 dark:text-white">From </span>{' '}
                        {moment(invoice.from).format('DD/MM/YYYY HH:mm')}{' '}
                        <span className="font-medium text-gray-900 dark:text-white"> to </span>
                        {moment(invoice.to).format('DD/MM/YYYY HH:mm')}
                    </div>
                    {invoice.balanceToPay >= 0 && (
                        <div className="px-2 flex justify-end font-medium gap-1">
                            {' '}
                            <span className="text-gray-900 dark:text-white">Amount paid by Logistio </span>{' '}
                            <span className="text-base">
                                {currencyConverter(invoice.balanceToPay, CustomerCurrency)}
                            </span>
                        </div>
                    )}
                    <div className="px-2 pb-2">
                        <Table>
                            <Table.Head>
                                <Table.HeadCell>ITEM</Table.HeadCell>
                                <Table.HeadCell>NUMBER</Table.HeadCell>
                                <Table.HeadCell>TOTAL</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        New leads fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.addedLeadsNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>{currencyConverter(invoice?.addedLeads, CustomerCurrency)}</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Confirmed leads fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.confirmedLeadsNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.confirmedLeads, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Delivered leads fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.deliveredForCCNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.deliveredForCC, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        COD fees
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>
                                        {currencyConverter(invoice?.totalPercentFromSellPrice, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Up/Down/Cross sell fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.upDownCrossSellNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.upDownCrossSellFees || 0, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Delivered orders fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.deliveredLeadsNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.deliveredLeads, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Returned orders fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.shippedLeadsNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.shippedLeads, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>

                                {Object.values(invoice.resellerFeesDetails || {})?.map((reseller) => {
                                    return (
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
                                            {' '}
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {` Reseller product(${reseller?.productName})`}
                                            </Table.Cell>
                                            <Table.Cell>{reseller?.totalItems ?? '--'}</Table.Cell>
                                            <Table.Cell>
                                                {currencyConverter(reseller?.totalFees || 0, CustomerCurrency)}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total reseller products fees
                                    </Table.Cell>
                                    <Table.Cell>{invoice?.resellerProductsNumber ?? '--'}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.resellerFees || 0, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Ads credits / loans
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>
                                        {currencyConverter(invoice?.CreditAdsAmount, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                {Object.values(invoice.creditForCogsDetails || {})?.map((credit) => {
                                    return (
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
                                            {' '}
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {` Cogs credits / loans(${credit?.product})`}
                                            </Table.Cell>
                                            <Table.Cell>--</Table.Cell>
                                            <Table.Cell>
                                                {currencyConverter(credit?.total || 0, CustomerCurrency)}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total Cogs credits / loans
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>
                                        {currencyConverter(invoice?.totalCreaditCogs, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total Fees
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>{currencyConverter(totalFees, CustomerCurrency)}</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Total Sales
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>
                                        {currencyConverter(invoice?.totalCollected, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Profit
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>
                                        {currencyConverter(invoice?.sellerProfit, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table>
                            <Table.Head>
                                <Table.HeadCell>ITEM</Table.HeadCell>
                                <Table.HeadCell>STATUS</Table.HeadCell>
                                <Table.HeadCell>TOTAL</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Handle fees ( Paid )
                                    </Table.Cell>
                                    <Table.Cell>{paymentStatus['1'].statusIcon}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice?.handleFees || 0, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Last Period Unpaid Balance
                                    </Table.Cell>
                                    <Table.Cell>{paymentStatus['2'].statusIcon}</Table.Cell>
                                    <Table.Cell>
                                        {currencyConverter(invoice.previousUnpaidDebt || 0, CustomerCurrency)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>

            <BiSearch
                className="w-5 h-5 hover:cursor-pointer dark:text-gray-400  "
                onClick={() => {
                    setOpenInvoiceModal(true);
                }}
            />
        </>
    );
};
export default CodInvoiceActions;
