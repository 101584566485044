import * as Yup from 'yup';

export const walletVerif = Yup.object().shape({
    amount: Yup.number().required('Amount is required'),
    verificationImage: Yup.string().required('Photo proof is required'),
    accountName: Yup.string().required('Transaction transfer name is required'),
    status: Yup.number().required(),
    operationType: Yup.string().required(),
    operationCurrency: Yup.string().required('Currency is required'),
});
