/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { forwardRef, useEffect, useState } from 'react';
// import MessageContainer from 'components/messageContainer/MessageContainer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/messageFile';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import { useLocation } from 'react-router-dom';
import { getProduct } from 'services/product';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import { IProduct } from 'utils/Interfaces/products.interface';
import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts } from './Messages.styles';

const Messages = forwardRef(() => {
    const location: any = useLocation();
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [productInfo, setProductInfo] = useState<any>([]);

    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);
    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);
    useEffect(() => {
        // Creates a WebSocket connection

        socket.emit(
            'joinProductChat',
            JSON.stringify({ product: location.state.productId, customer: getCurrentUser()._id }),
        );

        socket.on('paginate-product-messages', (data: any) => {
            if (JSON.parse(data).totalDocs) setTotalMessages(JSON.parse(data).totalDocs);
            // setConvMessages(JSON.parse(data).docs);
            if (JSON.parse(data).docs) {
                setConvMessages(JSON.parse(data).docs);
            }

            setCurrentRef('last');
        });

        socket.on('new-product-message', (data: any) => {
            if (JSON.parse(data).docs) {
                if (JSON.parse(data).docs.length === 1) {
                    setConvMessages((conv: any) => [...JSON.parse(data).docs, ...conv]);
                } else setConvMessages(JSON.parse(data).docs);
                socket.emit('newChatMessage', {
                    customer: getCurrentUser()._id,
                    about: 21, // new product message
                    customerId: getCurrentUser()._id,
                    messageId: JSON.parse(data).docs[0]._id,
                });
            }

            setCurrentRef('last');
        });

        getProduct(location.state.productId).then(async (res) => {
            let skus = '';
            if (res.data.product.globalSKU) {
                skus = `${res.data.product.globalSKU}`;
            } else if (res.data.product.variants.length > 0) {
                res.data.product.variants.forEach((variant) => {
                    if ((variant as IProduct).globalSKU) {
                        skus += `[${(variant as IProduct).name}: ${(variant as IProduct).globalSKU}] `;
                    }
                });
            }
            setProductInfo({
                image: res.data.product.design,
                name: res.data.product.name,
                sku: skus,
                status: res.data.product.status,
                type: res.data.product.type,
            });
        });
        return () => {
            socket.off('paginate-product-messages');
            socket.off('new-product-message');
        };
    }, []);

    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [progressCount, setProgressCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayuploadingFile, setDisplayUploadingFile] = useState(false);

    useEffect(() => {
        setUploading(progressCount < 100 && progressCount > 0);
    }, [progressCount, displayuploadingFile]);
    return (
        <div>
            <div className="h-[calc(100vh-(10vh+18vh))] overflow-hidden hover:overflow-y-scroll">
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateProductMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        product: location.state.productId,
                                        customer: getCurrentUser()._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}
                {currentRef === 'first' && <Box ref={myRef} />}
                {convMessages &&
                    convMessages
                        .slice(0)
                        .reverse()
                        .map((message: any) => (
                            <Box sx={MessageTexts} key={message.id}>
                                {message.message ? (
                                    <MessageText
                                        entity={message.from}
                                        message={message.message}
                                        received={message.onModelFrom === 'User'}
                                        key={message.id}
                                        timestamp={message.createdAt}
                                    />
                                ) : message.file ? (
                                    <MessageFile
                                        entity={message.from}
                                        file={message.file}
                                        received={message.onModelFrom === 'User'}
                                        fileName={message.fileName}
                                        fileSize={message.fileSize}
                                        timestamp={message.createdAt}
                                    />
                                ) : null}
                            </Box>
                        ))}
                {currentRef === 'last' && <Box ref={myRef} />}
                {uploading && displayuploadingFile && (
                    <Box sx={MessageTexts}>
                        <MessageFile
                            file="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wNPpW45HWrITlj89fzAHTMQvxzolD3mx_-Swrw2tvQ&s"
                            received={false}
                            fileName={fileName}
                            fileSize={fileSize}
                            uploading={uploading}
                            progressCount={progressCount}
                        />
                    </Box>
                )}
            </div>
            <Divider />
            <MessageInput
                socketRef={socket}
                setProgressCount={setProgressCount}
                setFileName={setFileName}
                setFileSize={setFileSize}
                setDisplayUploadingFile={setDisplayUploadingFile}
            />
        </div>
    );
});

export default Messages;
