/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawler/generalDrawler';
import React, { useEffect, useState } from 'react';
import { getShipmentHistory, IShipmentTrace, IShippment, stockStatusMatch } from 'services/stock';
import { IoMdCloseCircle } from 'react-icons/io';
import DataTable from 'components/tables/tables';
import { TableColDef } from 'components/tables/tables.types';
import { Badge, Button } from 'flowbite-react';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { VscHistory } from 'react-icons/vsc';
import { FaRegCopy } from 'react-icons/fa6';
import { copyToClickboard } from 'utils/copyToClickBoard';

const statusColorMatchType = {
    1: 'info', // new
    2: 'purple', // ready for pickup
    3: 'pink', // shipping
    30: 'pink', // Stock received by carrier
    31: 'pink', // Sent to GuangZhou Airport
    32: 'pink', // Instation Scan GZ
    33: 'pink', // Arrived in loading warehouse
    34: 'pink', // Sent To HongKong
    35: 'pink', // Instation Scan HK
    36: 'pink', // Departed on Flight
    37: 'pink', // Arrived destination Airport
    38: 'pink', // Customs clearance start
    39: 'pink', // Customs Released_Import
    40: 'pink', // Arrive to headquarters warehouse
    4: 'success', // shipped
};

const ShippingHistory: React.FC<{ data: IShippment }> = ({ data }) => {
    const [shippingHistory, setShippingHistory] = useState<IShipmentTrace[]>([]);

    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (data?._id && openDetails) {
            const toastId = toast.loading('Loading stock shipments details data...');
            setIsLoading(true);
            getShipmentHistory(data?._id)
                .then((res) => {
                    toast.dismiss(toastId);
                    setShippingHistory(res.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error('Something went wrong', { id: toastId });
                });
        }
    }, [data._id, openDetails]);

    const columns: TableColDef[] = [
        {
            field: 'time',
            headerName: 'Date',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) =>
                params?.time ? <p>{format(new Date(params?.time), 'dd/MM/yyyy')}</p> : <p>-</p>,
        },

        {
            field: 'time',
            headerName: 'Time',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) =>
                params?.time ? <p>{format(new Date(params?.time), 'hh:mm:ss')}</p> : <p>-</p>,
        },

        {
            field: 'status',
            headerName: 'Status',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) => {
                const statusValue = params?.logistioStatus?.toString() as '1' | '2' | '3' | '4';

                return (
                    <>
                        <Badge color={statusColorMatchType[statusValue]} size="sm" style={{ width: 'fit-content' }}>
                            {stockStatusMatch[statusValue]}
                        </Badge>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <VscHistory className="w-5 h-5 cursor-pointer" onClick={() => setOpenDetails(true)} />
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Shipping History</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetails(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <div className="flex items-center w-full my-7">
                        <p className="text-xl font-medium">
                            {data?.product?.itemKind === 'variant'
                                ? data?.parentProduct?.restockFor?.name || data?.parentProduct?.name
                                : data?.product?.name}
                        </p>
                        {data?.product?.itemKind === 'variant' && (
                            <p className="text-l font-medium pt-2 pl-2">{data?.product?.name || ''}</p>
                        )}
                    </div>

                    <div className="my-2 relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Date
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Tracking Code
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                </tr>
                            </thead>

                            {isLoading ? (
                                <tbody>
                                    <tr className="border-b bg-white dark:bg-gray-800 dark:border-gray-700 animate-pulse">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>

                                        <td className="px-6 py-4">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {[data].map((shipment: IShippment) => {
                                        const statusValue = shipment?.status?.toString() as '1' | '2' | '3' | '4';
                                        return (
                                            <tr
                                                className="border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                                                key={shipment._id}
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {shipment?.createdAt
                                                        ? format(new Date(shipment?.createdAt), 'dd/MM/yyyy')
                                                        : '-'}
                                                </th>

                                                <td className="px-6 py-4 flex gap-2">
                                                    {shipment?.lgstTrackingCode ? (
                                                        <div className="flex items-center gap-2">
                                                            <p className="font-bold">{shipment?.lgstTrackingCode}</p>
                                                            <Button
                                                                size="l"
                                                                color="white"
                                                                title="Copy Tracking Code to clipboard"
                                                                onClick={() => {
                                                                    copyToClickboard(
                                                                        shipment?.lgstTrackingCode || '',
                                                                        'Tracking Code',
                                                                    );
                                                                }}
                                                            >
                                                                <FaRegCopy className="w-4 h-4" />
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>

                                                <td className="px-6 py-4">
                                                    <Badge
                                                        color={statusColorMatchType[statusValue]}
                                                        size="sm"
                                                        style={{ width: 'fit-content' }}
                                                    >
                                                        {stockStatusMatch[statusValue]}
                                                    </Badge>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </table>
                    </div>
                    <div className="py-2">
                        <DataTable
                            rows={shippingHistory}
                            setRows={setShippingHistory}
                            columns={columns}
                            pageState={{
                                isLoading,
                                total: 0,
                                pageSize: 0,
                                page: 0,
                                count: 0,
                            }}
                            setPageState={() => {}}
                        />
                    </div>
                </div>
            </GeneralDrawer>
        </>
    );
};
export default ShippingHistory;
