import { HiCheckCircle, HiClock, HiMinusCircle } from 'react-icons/hi2';

export const StatusContainer = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1,
    border: 'solid 1px #ececef',
    padding: '5px',
};

export const StatusQuotationContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '5px',
};

const renderColor = (param: string) => {
    switch (param) {
        case 'Received':
            return '#93c66e';
        case 'Pending':
            return '#ff9a5c';
        case 'Processing':
            return '#5c558a';
        case 'Shipped':
            return '#bc63e3';
        case 'Cancelled':
            return '#ff7777';
        default:
            return '#578aff';
    }
};

export const StatusBall = (label: string) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColor(label),
        borderRadius: '50%',
        marginRight: '4px',
    };
};

// const renderColorQuotation = (param: boolean) => {
//     switch (param) {
//         case true:
//             return '#93c66e';
//         case false:
//             return '#ff7777';
//         default:
//             return '#578aff';
//     }
// };

const renderColorQuotationn = (paramO: boolean, paramT: boolean) => {
    const accepted = paramO;
    const refused = paramT;

    if (refused === true) {
        return '#ff7777';
    }
    if (accepted === true) {
        return '#93c66e';
    }
    return '#578aff';
};

export const StatusBallQuotation = (label: boolean, labelT: boolean) => {
    return {
        width: '2px',
        padding: '4px',
        background: renderColorQuotationn(label, labelT),
        borderRadius: '50%',
        marginRight: '0px',
    };
};
export const ColorCustomerAccountStatus = (param: number) => {
    switch (param) {
        case 3:
            return { color: 'failure', icon: HiMinusCircle };
        case 2:
            return { color: 'success', icon: HiCheckCircle };
        default:
            return { color: 'info', icon: HiClock };
    }
};

export const getStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Pending';
        case 2:
            return 'Confirmed';
        case 3:
            return 'Rejected';
        default:
            return 'Pending';
    }
};
