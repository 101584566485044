import React from 'react';
import { createRoot } from 'react-dom/client';
import ThemeMode from 'utils/contexts/theme';
import NotificationToast from 'components/notificationBar/notificationToast';
import App from './App';
import './index.css';
import './utils/helpers/zoomCssConfig.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <ThemeMode>
            <NotificationToast />
            <App />
        </ThemeMode>
    </React.StrictMode>,
);
