/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import Navbar from 'components/navbar/Navbar';
import SideBar from 'components/sideBar/SideBar';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        // <Box sx={LayoutStyle}>
        //     <SideBar onCollapse={() => setCollapsed(!collapsed)} />

        //     <StyledMain collapsed={collapsed}>
        //         <Navbar />
        //         <Box sx={ChildLayout(pathname)}>
        //             <Outlet context={collapsed} />
        //         </Box>
        //     </StyledMain>
        // </Box>
        <div className="flex">
            <div className=" dark:bg-[#191C24] bg-[#EAEDF7]">
                <SideBar onCollapse={() => setCollapsed(!collapsed)} />
            </div>
            <div className="w-full h-screen overflow-y-auto flex flex-col  pt-5  px-14">
                <Navbar />
                <div className=" flex-1 h-full">
                    <Outlet context={collapsed} />
                </div>
            </div>
        </div>
    );
};

export default Layout;
