/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormikContextType, useFormikContext } from 'formik';
import { vnlinWarehouses } from 'utils/helpers/vnlin/warehouses';
import { v4 as uuidv4 } from 'uuid';
import { variantsTableHeadWrapper, variantsTableRows } from '../Sourcing.styles';

interface IStockConfig {
    warehouseId: string;
    variant?: string;
    quantity: string;
    country: 'United Arab Emirates' | 'Qatar' | 'Kuwait' | 'Saudi Arabia' | '';
}

const initWithVariant: IStockConfig = { quantity: '', variant: '', warehouseId: '', country: '' };
const initWithoutVariant: IStockConfig = { quantity: '', warehouseId: '', country: '' };

const StockConfiguration = ({
    variants,
    noVariantsQuantity,
    errors,
    setErrors,
    setValidStockConfig,
    validStockConfigCheck,
    shippingTo,
}: {
    variants: { [key: string]: { quantity: string; disabled: boolean } };
    noVariantsQuantity: number;
    errors: string[];
    setErrors: React.Dispatch<React.SetStateAction<string[]>>;
    setValidStockConfig?: React.Dispatch<React.SetStateAction<boolean>>;
    validStockConfigCheck?: boolean;
    shippingTo?: any;
}) => {
    const {
        values,
        setFieldValue,
    }: FormikContextType<{
        shippingTo: { code: string; label: string; phone: string }[];
        stockConfig: IStockConfig[];
    }> = useFormikContext();
    /**
     * TODO: use formik errors for validation
     * const formikContext: FormikContextType<{ shippingTo: { code: string; label: string; phone: string }[] }> =
     *     useFormikContext();
     * const { values, setFieldValue } = formikContext;
     * const formikErrors = formikContext.errors;
     * const [field, meta] = useField('stockConfig');
     * */

    const [stockConfig, setStockConfig] = useState<IStockConfig[]>([...values.stockConfig]);
    const [umapkeys, setUmapkeys] = useState<string[]>([]);

    /**
     /* Check if the configuration add by the user is valid
     * valid if:
     *  - all variants are stocked & total quantity of each variant is stocked
     * false otherwise
     */
    useEffect(() => {
        if (!noVariantsQuantity) {
            if (stockConfig.length > 0 && Object.values(stockConfig[0]).every((val) => val !== '')) {
                const validStocks: { [key: string]: boolean } = {};
                // group stock config by variant name and sum each variant quantity
                const stockedVariantsQuantity = new Map<string, number>();
                stockConfig.forEach((config) => {
                    if (stockedVariantsQuantity.has(config.variant!)) {
                        const newSum = stockedVariantsQuantity.get(config.variant!)! + Number(config.quantity);
                        stockedVariantsQuantity.set(config.variant!, newSum);
                    } else {
                        stockedVariantsQuantity.set(config.variant!, Number(config.quantity));
                    }
                });

                const variantsNames = Object.keys(variants).filter((vari) => !variants[vari].disabled);

                // compare the stocked quantities of variants with the quantity of each variant
                variantsNames.forEach((variantName) => {
                    const stockedQuantity = stockedVariantsQuantity.get(variantName);

                    if (stockedQuantity === Number(variants[variantName].quantity)) {
                        validStocks[variantName] = true;
                    } else {
                        validStocks[variantName] = false;
                    }
                });

                const validStockConfig =
                    Object.values(validStocks).every((valid) => valid) &&
                    Object.keys(validStocks).length === Object.values(variants).filter((v) => !v.disabled).length;
                if (setValidStockConfig) {
                    setValidStockConfig(
                        validStockConfig &&
                            (errors.length === 0 ||
                                (errors.length > 0 &&
                                    errors.every((el) => {
                                        return el?.length === 0;
                                    }))),
                    );
                }
            } else if (setValidStockConfig) {
                setValidStockConfig(false);
            }
        } else {
            // check if total quantity is reached
            let totalQuantityConfigured = 0;
            stockConfig.forEach((config) => {
                totalQuantityConfigured += Number(config.quantity);
            });
            if (setValidStockConfig) {
                setValidStockConfig(
                    totalQuantityConfigured === noVariantsQuantity &&
                        (errors.length === 0 ||
                            (errors.length > 0 &&
                                errors.every((el) => {
                                    return el === '';
                                }))),
                );
            }
        }
    }, [errors]);

    useEffect(() => {
        // if the product does not have variants
        const initLineData = Object.keys(variants).length > 0 ? [initWithVariant] : [initWithoutVariant];
        if (
            values.stockConfig.length > 0 &&
            Object.values(values.stockConfig.at(-1)!).findIndex((el) => el === '') === -1
        ) {
            // stockConfig have data and we don't have an empty inputs line we need to add empty inputs line
            if (Object.keys(variants)?.length) {
                setStockConfig([
                    ...values.stockConfig.filter((item) => {
                        // Check if the variant exists in the variants object
                        // check if a removed variant and country doesn"t appear in the stock config
                        return (
                            variants.hasOwnProperty(item.variant || '') &&
                            shippingTo?.some((shippingItem: any) => shippingItem.label === item.country)
                        );
                    }),
                    ...initLineData,
                ]);
            } else {
                setStockConfig(
                    values?.stockConfig?.length && shippingTo?.length
                        ? [
                              ...values.stockConfig?.filter(
                                  (stockItem) =>
                                      shippingTo?.some((shippingItem: any) => shippingItem.label === stockItem.country),
                              ),
                          ]
                        : [...initLineData],
                );
            }

            const uniqueKeys: string[] = [];
            if (umapkeys.length === 0) {
                values.stockConfig.forEach(() => {
                    uniqueKeys.push(uuidv4());
                });
            }
            setUmapkeys([...uniqueKeys, uuidv4()]);
        } else if (
            values.stockConfig.length > 0 &&
            Object.values(values.stockConfig.at(-1)!).findIndex((el) => el === '') > -1
        ) {
            // stockConfig have data and we have an empty inputs line we need to show data as it is
            setStockConfig([...values.stockConfig]);
            const uniqueKeys: string[] = [];
            if (umapkeys.length === 0) {
                values.stockConfig.forEach(() => {
                    uniqueKeys.push(uuidv4());
                });
            }
            setUmapkeys([...uniqueKeys]);
        } else {
            // stockConfig don't have data we need to show only an empty inputs line
            setStockConfig(values?.stockConfig?.length ? [...values.stockConfig] : [...initLineData]);
            setUmapkeys([uuidv4()]);
        }
    }, [variants]);

    // const [sumQuan, setSumQuan] = useState();

    const handleStockConfigValues = (e: any, idx: number) => {
        // handle values update
        const { name, value } = e.target;
        const dirtyConfig = [...stockConfig];
        dirtyConfig[idx] = { ...dirtyConfig[idx], [name]: value };
        if (name === 'country') {
            // select the appropriate warehouse depending on the value of the country
            dirtyConfig[idx] = {
                ...dirtyConfig[idx],
                warehouseId: vnlinWarehouses[value as keyof typeof vnlinWarehouses].id,
            };
        }
        setStockConfig(dirtyConfig);
    };

    const updateErrors = () => {
        // group quantities by variant
        const dirtyConfig = [...stockConfig];
        const quantities: Record<string, any> = {};
        dirtyConfig.forEach((conf) => {
            if (conf.variant) {
                if (quantities[conf.variant]) {
                    quantities[conf.variant] += Number(conf.quantity);
                } else {
                    quantities[conf.variant] = Number(conf.quantity);
                }
            } else if (quantities.product) {
                quantities.product += Number(conf.quantity);
            } else {
                quantities.product = Number(conf.quantity);
            }
        });
        const errs = JSON.stringify(dirtyConfig) !== JSON.stringify(values.stockConfig) ? [] : [...errors];
        dirtyConfig.forEach((conf, i) => {
            if (conf.variant) {
                // validate maximum
                if (
                    Number.isNaN(Number(dirtyConfig[i]?.quantity)) ||
                    Number(dirtyConfig[i].quantity) <= 0 ||
                    dirtyConfig[i].quantity.toString().includes('.')
                ) {
                    errs[i] = `*Quantity must be a valid number`;
                } else if (quantities[conf.variant] > Number(variants[conf.variant]?.quantity)) {
                    errs[i] = `*Total quantity of ${Number(variants[conf.variant]?.quantity)} exceeded`;
                } else if (quantities[conf.variant] < Number(variants[conf.variant]?.quantity)) {
                    errs[i] = `Total quantity of ${Number(variants[conf.variant]?.quantity)} must be reached`;
                } else if (quantities[conf.variant] === Number(variants[conf.variant]?.quantity)) {
                    // clear old errors
                    errs[i] = '';
                }
            } else if (quantities.product || Number.isNaN(quantities.product)) {
                if (
                    Number.isNaN(Number(dirtyConfig[i].quantity)) ||
                    Number(dirtyConfig[i].quantity) <= 0 ||
                    dirtyConfig[i].quantity.toString().includes('.')
                ) {
                    errs[i] = `*Quantity must be a valid number`;
                } else if (quantities.product > noVariantsQuantity) {
                    errs[i] = `*Total quantity of ${noVariantsQuantity} exceeded`;
                } else if (quantities.product < noVariantsQuantity) {
                    errs[i] = `Total quantity of ${noVariantsQuantity} must be reached`;
                } else if (quantities.product === noVariantsQuantity) {
                    // clear old errors
                    errs[i] = '';
                }
            }
        });
        setErrors([...errs]);
    };

    useEffect(() => {
        updateErrors();
    }, [stockConfig]);

    const handleRemove = (idx: number) => {
        const dirtyConfig = [...stockConfig];
        let errorsDraft = [...errors];

        if (dirtyConfig.length === 0) {
            setErrors([...errorsDraft]);
            setStockConfig([...dirtyConfig]);
            return;
        }
        // validate total quantity
        const haveVariants = Object.keys(variants).length > 0;
        let totalQuantity = 0;
        if (haveVariants) {
            if (dirtyConfig[idx] && dirtyConfig[idx].variant) {
                totalQuantity = Number(variants[dirtyConfig[idx].variant!]?.quantity || 0);
            }
        } else {
            totalQuantity = noVariantsQuantity;
        }
        // collect the errors indexes to be updated
        const errorsIndexesToUpdate: number[] = [];

        // claculate quantity reached
        let currentSum = 0;
        dirtyConfig.forEach((config, index) => {
            if (haveVariants && config.variant === dirtyConfig[idx].variant && index !== idx) {
                errorsIndexesToUpdate.push(index);
                currentSum += Number(config.quantity);
            } else if (!haveVariants) {
                errorsIndexesToUpdate.push(index);
                currentSum += Number(config.quantity);
            }
        });

        errorsDraft.splice(idx, 1);
        dirtyConfig.splice(idx, 1);
        const umapKeysDraft = [...umapkeys];
        umapKeysDraft.splice(idx, 1);
        setUmapkeys([...umapKeysDraft]);

        // Check for errors
        if (!haveVariants) {
            if (currentSum > Number(totalQuantity)) {
                errorsDraft = [...errorsDraft.map(() => `*Total quantity of ${totalQuantity} exceeded`)];
            } else if (currentSum < Number(totalQuantity)) {
                errorsDraft = [...errorsDraft.map(() => `Total quantity of ${totalQuantity} must be reached`)];
            } else if (currentSum === Number(totalQuantity)) {
                // remove all errors for total quantity not reached
                errorsDraft = errorsDraft.map((err) => {
                    if (err.includes('Total quantity of') || err.includes('must be reached')) return '';
                    return err;
                });
            }
        } else if (haveVariants) {
            errorsIndexesToUpdate.forEach((index) => {
                if (currentSum > Number(totalQuantity)) {
                    errorsDraft[index] = `*Total quantity of ${totalQuantity} exceeded`;
                } else if (currentSum < Number(totalQuantity)) {
                    errorsDraft[index] = `Total quantity of ${totalQuantity} must be reached`;
                } else if (currentSum === Number(totalQuantity)) {
                    // clear old errors
                    errorsDraft[index] = '';
                }
            });
        }
        setErrors([...errorsDraft]);
        setStockConfig([...dirtyConfig]);
    };

    const handleAdd = () => {
        // require all previous field
        const predicate = (el: IStockConfig) => Object.values(el).some((value) => !value);
        const haveEmpty = stockConfig.some(predicate);
        const indexOfEmpty = stockConfig.findIndex(predicate);
        if (haveEmpty) {
            const err = [...errors];
            err[indexOfEmpty] = '*All fields are required';
            setErrors([...err]);
        } else {
            const err = [...errors];
            err[err.indexOf('*All fields are required')] = '';
            setErrors([...err]);
        }

        // Add row
        setStockConfig((prv) => {
            if (Object.keys(variants).length > 0) {
                return [...prv, initWithVariant];
            }
            return [...prv, initWithoutVariant];
        });
    };

    useEffect(() => {
        setFieldValue('stockConfig', stockConfig);
    }, [stockConfig]);

    const isTherenoDisabled = () => {
        const isThere = Object.keys(variants).some((isIt) => variants[isIt].disabled === false);
        return isThere;
    };

    /**
     * No duplicate destination country
     * Disable country if:
     * 1. case variants: if the country is already selected for a variant => disable the country for this variant
     * 2. case no variants: if the country is already selected then disable it for the other configs
     */
    const isCountryDisabled = (currentConfig: IStockConfig, country: string): boolean => {
        if (Object.keys(variants).length > 0) {
            return stockConfig.some((config) => config.country === country && config.variant === currentConfig.variant);
        }
        return stockConfig.some((config) => config.country === country);
    };

    return (
        <Box>
            {/* Header */}
            {!isTherenoDisabled() && Object.keys(variants).length > 0 && (
                <Box
                    sx={{
                        width: '100%',
                        color: 'textColor.errorColor',
                        fontSize: '12px',
                    }}
                >
                    *All your variants are disabled, you need at least one active variant !
                </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
                {Object.keys(variants).length > 0 ? (
                    Object.keys(variants).map((variant) => (
                        <Box
                            sx={{
                                border: '1px solid',
                                borderColor: 'borderColor',
                                padding: '7px 14px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                            key={variant}
                        >
                            <Box
                                sx={{
                                    padding: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                            >
                                <Typography sx={{ fontSize: '12px', margin: 0 }}>Variant:</Typography>
                                <Typography sx={{ fontWeight: 'bolder', fontSize: '14px', margin: 0 }}>
                                    {variant}
                                </Typography>
                            </Box>
                            <Box sx={{ height: '10px', border: '1px solid', borderColor: 'borderColor' }} />
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Typography sx={{ fontSize: '12px', margin: 0 }}>Total quantity:</Typography>
                                <Typography sx={{ fontWeight: 'bolder', fontSize: '14px', margin: 0 }}>
                                    {variants[variant].quantity}
                                </Typography>
                            </Box>
                            {variants[variant].disabled === true && (
                                <>
                                    <Box sx={{ height: '10px', border: '1px solid', borderColor: 'borderColor' }} />
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <Typography sx={{ fontSize: '12px', margin: 0 }}>Disabled</Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    ))
                ) : (
                    <Box
                        key={uuidv4()}
                        sx={{
                            border: '1px solid',
                            borderColor: 'borderColor',
                            padding: '7px 14px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <Typography sx={{ fontSize: '12px', margin: 0 }}>Total quantity:</Typography>
                        <Typography sx={{ fontWeight: 'bolder', fontSize: '14px', margin: 0 }}>
                            {noVariantsQuantity}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box
                sx={variantsTableHeadWrapper}
                style={{
                    gridTemplateColumns: `${Object.keys(variants).length > 0 ? '2fr 3fr 1fr 1fr' : '1fr 1fr 1fr'}`,
                    gap: 20,
                }}
            >
                {Object.keys(variants).length > 0 && <Box>Variants</Box>}
                <Box>Country</Box>
                {/* <Box>Warehouse</Box> */}
                <Box>Quantity</Box>
                <Box sx={{ textAlign: 'center' }}>Action</Box>
            </Box>
            {/* Rows */}
            <>
                {stockConfig.map((stockElement, idx) => {
                    return (
                        <Box
                            sx={{
                                backgroundColor: 'primaryBackground',
                            }}
                            key={umapkeys[idx]}
                        >
                            <Box
                                sx={{
                                    ...variantsTableRows({ ignore: { disabled: false } }, 'ignore'),
                                    backgroundColor: 'primaryBackground',
                                }}
                                style={{
                                    gridTemplateColumns: `${
                                        Object.keys(variants).length > 0 ? '2fr 3fr 1fr 1fr' : '1fr 1fr 1fr'
                                    }`,
                                }}
                            >
                                {/* select variant */}
                                {Object.keys(variants).length > 0 && (
                                    <FormControl fullWidth>
                                        <InputLabel id="stock-config-variant">Variant</InputLabel>
                                        <Select
                                            name="variant"
                                            label="Variant"
                                            labelId="stock-config-variant"
                                            onChange={(e) => {
                                                handleStockConfigValues(e, idx);
                                            }}
                                            value={stockElement.variant}
                                        >
                                            {Object.keys(variants).map((variant, _i) => {
                                                if (variants[variant].disabled) {
                                                    return null;
                                                }
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <MenuItem value={variant} key={_i}>
                                                        {variant}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                {/* select country */}
                                <FormControl fullWidth>
                                    <InputLabel id="stock-config-country">Country</InputLabel>
                                    <Select
                                        disabled={Object.keys(variants).length > 0 && !stockElement.variant}
                                        name="country"
                                        label="Country"
                                        labelId="stock-config-country"
                                        onChange={(e) => {
                                            handleStockConfigValues(e, idx);
                                        }}
                                        value={stockElement.country}
                                    >
                                        {values.shippingTo.map((country, _i) => {
                                            return (
                                                <MenuItem
                                                    value={country.label}
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={_i}
                                                    disabled={isCountryDisabled(stockElement, country.label)}
                                                >
                                                    {country.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                {/* select warehouse: Automatically set when selecting the country */}
                                {/* <FormControl fullWidth>
                                    <InputLabel id="stock-config-warehouse">Warehouse</InputLabel>
                                    <Select
                                        disabled={!stockElement.country}
                                        label="warehouse"
                                        name="warehouseId"
                                        labelId="stock-config-warehouse"
                                        onChange={(e) => {
                                            handleStockConfigValues(e, idx);
                                        }}
                                        value={stockElement.warehouseId}
                                    >
                                        {stockElement.country !== '' ? (
                                            [
                                                vnlinWarehouses[
                                                    stockElement.country as
                                                        | 'United Arab Emirates'
                                                        | 'Qatar'
                                                        | 'Kuwait'
                                                        | 'Saudi Arabia'
                                                ],
                                            ].map((warehouse, _i) => {
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <MenuItem value={warehouse.id} key={_i}>
                                                        {warehouse.name}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <MenuItem value=""> </MenuItem>
                                        )}
                                    </Select>
                                </FormControl> */}
                                {/* input quantity */}
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={!stockElement.warehouseId}
                                        name="quantity"
                                        label="Quantity"
                                        value={stockElement.quantity}
                                        onChange={(e) => {
                                            handleStockConfigValues(e, idx);
                                        }}
                                    />
                                </FormControl>
                                <Button
                                    variant="text"
                                    color="warning"
                                    onClick={() => {
                                        handleRemove(idx);
                                    }}
                                >
                                    Remove
                                </Button>
                            </Box>
                            {errors[idx] ? (
                                <Box
                                    sx={{
                                        width: '100%',
                                        color: 'textColor.errorColor',
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        p: 2,
                                        mt: -1,
                                    }}
                                >
                                    {errors[idx]}
                                </Box>
                            ) : null}
                        </Box>
                    );
                })}
            </>
            <Button
                variant="text"
                color="primary"
                onClick={() => {
                    handleAdd();
                }}
                disabled={validStockConfigCheck}
            >
                Add
            </Button>
        </Box>
    );
};

export default StockConfiguration;
