export const storesHeader = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
};

export const storeFilters = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const StoresContainer = {
    minHeight: '0px',
    maxHeight: '77vh',
    overflowY: 'auto',
    paddingRight: '10px',
};
