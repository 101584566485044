import { IQuotation } from 'utils/Interfaces/products.interface';

export const getCombinations = (all: any) => {
    return (
        all.length &&
        all.reduce((acc: any, cu: any) => {
            const ret: any = [];
            if (!cu?.length) return ret;
            acc.forEach((obj: any) => {
                cu.forEach((firstObj: any) => {
                    ret.push(`${obj} / ${firstObj}`);
                });
            });
            return ret;
        })
    );
};

export const productStatus = [
    // { id: 11, label: 'Draft', type: 'Processing' },
    { id: 1, label: 'Pending', type: 'Processing' },
    { id: 2, label: 'Under review', type: 'Processing' },
    { id: 3, label: 'Quoted', type: 'Processing' },
    { id: 69, label: 'Decision submitted', type: 'Processing' },
    { id: 5, label: 'Payment pending', type: 'Processing' },
    { id: 43, label: 'Payment verification', type: 'Processing' },
    { id: 30, label: 'Payment declined', type: 'Rejected' },
    { id: 99, label: 'Product unavailable', type: 'Rejected' },
    { id: 42, label: 'Quote rejected', type: 'Rejected' },
    { id: 6, label: 'Paid', type: 'Paid' },
    { id: 100, label: 'Expired', type: 'Rejected' },
];
export const productStatus2 = [
    { id: 1, label: 'Pending' },
    { id: 2, label: 'Under review' },
    { id: 3, label: 'Quoted' },
    { id: 41, label: 'Reviewing quotations' },
    // { id: 42, label: 'Reviewing quotations' }, // 🏷️ logic is done in the backend if user select reviewing quotation in product filter it will return both status [41,42]
    { id: 5, label: 'Waiting for payment' },
    { id: 43, label: 'Processing payment' },
    { id: 6, label: 'Paid' },
    { id: 30, label: 'Payment declined' },
    // { id: 7, label: 'Preparing' }, // 🏷️ DEPRECATED
    { id: 8, label: 'Preparing stock' },
    // { id: 9, label: 'Waiting for pickup' },
    // { id: 10, label: 'Picked up' },
    // { id: 11, label: 'Pending delivery' },
    // { id: 12, label: 'Out for delivery' },
    // { id: 13, label: 'Delivered' },
    // { id: 14, label: 'Delivery failed' },
    // { id: 20, label: 'Partially matched' },
    // { id: 21, label: 'Being shipped to warehouse' },
];
export const PRODUCT_PHASES = ['Processing', 'Paid', 'Rejected'];

export const getStatus = (status: number, type: number) => {
    switch (status) {
        case 1:
            return 'Pending';
        case 2:
            return 'Under review';
        case 3:
            return 'Quoted';
        case 41:
            return 'Reviewing quotations';
        case 42:
            return 'Reviewing quotations';
        case 5:
            return 'Waiting for payment';
        case 43:
            return 'Payment being processed';
        case 98:
            return 'The user rejected the shipping offer';
        case 6:
            return 'Paid';
        case 30:
            return 'Payement declined';
        case 7:
            return 'Preparing';
        case 8:
            if (type === 2) {
                return 'ready';
            }
            return 'Being shipped to warehouse';
        case 9:
            return 'Waiting for pickup';
        case 10:
            return 'Picked up';
        case 11:
            return 'Pending delivery';
        case 12:
            return 'Out for delivery ';
        case 13:
            return 'Delivered';
        case 14:
            return 'Delivery failed';
        case 20:
            return 'Partially matched';
        case 21:
            return 'Being shipped to warehouse';
        case 99:
            return 'The offer rejected by the user';
        default:
            return 'Pending';
    }
};

export const getDeliveryStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'pending';
        case 2:
            return 'confirmed';
        case 3:
            return 'picked up';
        case 4:
            return 'pending delivery';
        case 5:
            return 'out for delivery';
        case 6:
            return 'delivered, ready';

        default:
            return 'pending';
    }
};

export const getQuotationStatus = (status: boolean) => {
    switch (status) {
        case true:
            return 'Accepted';
        case false:
            return 'Refused';

        default:
            return 'Pending';
    }
};
export const getTotalPrice = (quotations: IQuotation[]) => {
    let totalItemsPrice = 0;
    const countryList: {
        [key: string]: number;
    } = {};

    // eslint-disable-next-line no-unused-expressions
    quotations?.length &&
        (quotations || []).forEach(({ options }: any) => {
            options.forEach(
                ({ unitPrice, quantity, totalShippingLineFee, shippingLineFee, country, accepted }: any) => {
                    if (accepted) totalItemsPrice += Number(unitPrice) * Number(quantity);
                    if (accepted && !(country in countryList))
                        countryList[country] = totalShippingLineFee
                            ? Number(totalShippingLineFee)
                            : Number(shippingLineFee);
                },
            );
        });
    // eslint-disable-next-line no-return-assign
    Object.values(countryList).forEach((el) => (totalItemsPrice += el));
    return Number(totalItemsPrice.toFixed(2));
};
