/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import {
    Checkbox,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { currencyConverter } from 'utils/helpers/currencySymbol';
import CustomButton from 'components/customButton/CustomButton';
import CustomError from 'components/customError/CustomError';
import InputField from 'components/inputField/InputField';
import { payQuotaion } from 'services/quotationPayment';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import * as Yup from 'yup';
import { payment } from 'utils/validations/payWithBankValidations';
import { Badge } from 'flowbite-react';
import CustomBtnIcon from 'components/customButton/customBtnIcon';
import { AiOutlineClose } from 'react-icons/ai';
import { IConfiguration, getConfiguration } from 'services/configuration';
import { MdContentCopy, MdDone } from 'react-icons/md';
import toast from 'react-hot-toast';
import { IoOpenOutline } from 'react-icons/io5';
import { IProduct } from 'utils/Interfaces/products.interface';
import { getProduct } from 'services/product';
import ImageProofVerification from '../../components/imageProofVerfication/imageProofVerification';

const CopyButton: React.FC<{ text: string }> = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const copyText = (copy: string) => {
        navigator.clipboard.writeText(copy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <button
            disabled={copied}
            type="button"
            onClick={() => copyText(text)}
            title={copied ? 'Copied' : 'Copy'}
            key={text}
        >
            {copied ? <MdDone /> : <MdContentCopy />}
        </button>
    );
};

const PaymentModal: React.FC<{
    totalPrice: number;
    product: string;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    customerCurrency: string;
}> = ({ totalPrice, product, customerCurrency, setOpenModal }) => {
    const [verifImage, setVerifImage] = useState<{
        file: File | null;
        name: string;
        // url: URL.createObjectURL(file),
        targetKey: 'verificationImage';
    } | null>(null);
    const [urlImage, setUrlImage] = useState('');
    const [state, setState] = useState({
        payWithBank: false,
        payWithWalletBox: false,
    });
    const [validate, setValidate] = useState<Yup.AnyObjectSchema | Yup.ArraySchema<any>>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const { payWithBank, payWithWalletBox } = state;

    const [productData, setProductData] = useState<IProduct | null>(null);
    useEffect(() => {
        getProduct(product)
            .then((res) => {
                setProductData(res.data.product);
            })
            .catch(() => {
                toast.error('Failed to load product data');
            });
    }, []);
    const handlePayment = (data: any, onSubmitProps: any) => {
        payQuotaion(data)
            .then(() => {
                toast.success(
                    payWithBank || (payWithBank && payWithWalletBox)
                        ? 'Payment is being processed by admins'
                        : 'Product Paid',
                );

                return Promise.resolve();
            })
            .then(() => {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(window.location.reload());
                    }, 1000);
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong with product payment');
                onSubmitProps.setSubmitting(false);
                onSubmitProps.resetForm();
                setUrlImage('');
            });
    };
    const handleSubmit = (values: any, onSubmitProps: any) => {
        const formData = new FormData();
        let source = 'other';
        if (payWithBank && payWithWalletBox) {
            source = 'walletBank';
            formData.append('walletAmount', values.walletAmount);
            formData.append('bankAmount', String(values.bankAmount));
            formData.append('accountName', values.accountName);
            formData.append('amount', String(Number(values.bankAmount) + Number(values.walletAmount)));
            formData.append('status', '1');
            if (productData && productData.isRestock) {
                formData.append('type', 'restock');
            } else {
                formData.append('type', 'product');
            }
            formData.append('source', source);
            formData.append('product', product);
            formData.append('operationCurrency', values.operationCurrency);
            if (verifImage && verifImage.file) {
                formData.append(verifImage.name, verifImage.file, verifImage.targetKey);
            }
        } else if (payWithBank) {
            source = 'bank';
            formData.append('accountName', values.accountName);
            formData.append('amount', String(Number(values.bankAmount) + Number(values.walletAmount)));
            formData.append('status', '1');
            if (productData && productData.isRestock) {
                formData.append('type', 'restock');
            } else {
                formData.append('type', 'product');
            }
            formData.append('source', source);
            formData.append('product', product);
            formData.append('operationCurrency', values.operationCurrency);
            if (verifImage && verifImage.file) {
                formData.append(verifImage.name, verifImage.file, verifImage.targetKey);
            }
        } else if (payWithWalletBox) {
            source = 'wallet';
            formData.append('amount', String(Number(values.bankAmount) + Number(values.walletAmount)));
            formData.append('status', '2');
            if (productData && productData.isRestock) {
                formData.append('type', 'restock');
            } else {
                formData.append('type', 'product');
            }
            formData.append('source', source);
            formData.append('product', product);
        }
        handlePayment(formData, onSubmitProps);
    };

    const [openDrawer, setOpenDrawer] = useState(false);
    const toggleDrawer = () => () => {
        setOpenDrawer(false);
    };
    const [configuration, setConfiguration] = useState<IConfiguration>({});
    const drawerOpener = () => {
        getConfiguration().then((res) => {
            setConfiguration(res?.data);
            setOpenDrawer(true);
        });
    };

    return (
        <>
            <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer} sx={{ zIndex: 9999 }}>
                <Box sx={{ width: '500px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 1, mt: 1 }}>
                        <CustomBtnIcon
                            icon={<AiOutlineClose />}
                            onClick={() => {
                                setOpenDrawer(false);
                            }}
                            size="small"
                            color="error"
                        />
                    </Box>
                    <div>
                        {configuration?.bankInformations?.simpleBank?.length === 0 &&
                        configuration?.bankInformations?.financeService?.length === 0 ? (
                            <div className="flex justify-center w-full">Bank Information coming soon</div>
                        ) : (
                            ''
                        )}
                    </div>
                    <Box>
                        {configuration?.bankInformations?.simpleBank?.map((bankInfo) => {
                            return (
                                <>
                                    <div key={bankInfo._id} className=" rounded mb-1">
                                        <Box>
                                            <Grid container spacing={1} justifyContent="center">
                                                <Grid item xs={10}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Account Holder
                                                                <CopyButton text={bankInfo?.accountHolder} />
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.accountHolder || '--'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Payment Type
                                                                <CopyButton text={bankInfo?.paymentType} />
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.paymentType || '--'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Account Number{' '}
                                                                <CopyButton text={bankInfo?.accountNumber} />
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.accountNumber || 'xxxx'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                {bankInfo?.accountCurrency === 'USD' &&
                                                                bankInfo?.paymentType === 'local'
                                                                    ? 'Account Type'
                                                                    : 'SWIFT / BIC'}

                                                                <CopyButton
                                                                    text={
                                                                        bankInfo?.accountCurrency === 'USD' &&
                                                                        bankInfo?.paymentType === 'local'
                                                                            ? bankInfo?.accountType
                                                                            : bankInfo?.swiftBic
                                                                    }
                                                                />
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {(bankInfo?.accountCurrency === 'USD' &&
                                                                bankInfo?.paymentType === 'local'
                                                                    ? bankInfo?.accountType
                                                                    : bankInfo?.swiftBic) || ''}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                {bankInfo?.accountCurrency === 'USD' &&
                                                                    bankInfo?.paymentType === 'local' &&
                                                                    'ACH routing number'}
                                                                {bankInfo?.accountCurrency === 'USD' &&
                                                                    bankInfo?.paymentType === 'international' &&
                                                                    'Routing number'}
                                                                {bankInfo?.accountCurrency === 'EUR' && 'IBAN'}

                                                                {bankInfo?.accountCurrency === 'USD' && (
                                                                    <CopyButton text={bankInfo?.routingNumber} />
                                                                )}

                                                                {bankInfo?.accountCurrency === 'EUR' && (
                                                                    <CopyButton text={bankInfo?.iban} />
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.accountCurrency === 'USD' &&
                                                                    bankInfo?.routingNumber}

                                                                {bankInfo?.accountCurrency === 'EUR' && bankInfo?.iban}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Currency
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.accountCurrency || '--'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Description
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.description || '....'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider sx={{ mx: 1, my: 1 }} />
                                    </div>
                                </>
                            );
                        })}
                    </Box>
                    <Box>
                        {configuration?.bankInformations?.financeService?.map((bankInfo) => {
                            return (
                                <>
                                    <div key={bankInfo._id} className=" rounded mb-1">
                                        <Box>
                                            <Grid container spacing={1} justifyContent="center">
                                                <Grid item xs={10}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Email <CopyButton text={bankInfo?.email} />
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.email || '--'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                            >
                                                                Type
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize={14}
                                                                fontWeight={400}
                                                            >
                                                                {bankInfo?.serviceType || '--'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider sx={{ mx: 1, my: 1 }} />
                                    </div>
                                </>
                            );
                        })}
                    </Box>
                </Box>
            </Drawer>
            <Box sx={{ width: '500px' }}>
                <Formik
                    initialValues={{
                        walletAmount: '',
                        accountName: '',
                        bankAmount: state.payWithBank && state.payWithWalletBox ? '' : totalPrice,
                        verificationImage: '',
                        operationCurrency: '',
                        status: 1,
                        product,
                        totalPrice,
                    }}
                    onSubmit={(values, onSubmitProps) => handleSubmit(values, onSubmitProps)}
                    validationSchema={validate}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography>Total Price to pay:</Typography>
                                        <Badge color="pink">
                                            {totalPrice &&
                                                customerCurrency &&
                                                currencyConverter(totalPrice, customerCurrency)}{' '}
                                        </Badge>
                                    </Box>
                                </Box>
                                <CustomError name="totalPrice" component="div" />

                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ width: 'fit-content', backgroundColor: 'transparent' }}
                                        control={
                                            <Checkbox
                                                checked={payWithWalletBox}
                                                onChange={(event) => {
                                                    if (!event.target.checked) {
                                                        formik.setFieldValue('bankAmount', totalPrice);
                                                    }
                                                    handleChange(event);
                                                }}
                                                name="payWithWalletBox"
                                            />
                                        }
                                        label="Pay with Wallet"
                                    />
                                    {payWithWalletBox && payWithBank === false && (
                                        <Typography>*You are paying the whole Amount only with Wallet</Typography>
                                    )}
                                    {payWithWalletBox && payWithBank && (
                                        <>
                                            <InputField
                                                inputSize="small"
                                                label="Set the amount you want to pay with Wallet"
                                                placeholder="Amount"
                                                InputType="text"
                                                // returns onChange, onBlur and value related to this input field
                                                {...formik.getFieldProps('walletAmount')}
                                                name="walletAmount"
                                            />
                                            <CustomError name="walletAmount" component="div" />
                                        </>
                                    )}

                                    <FormControlLabel
                                        sx={{ width: 'fit-content', backgroundColor: 'transparent' }}
                                        control={
                                            <Checkbox
                                                checked={payWithBank}
                                                onChange={handleChange}
                                                name="payWithBank"
                                            />
                                        }
                                        label="Pay with Bank"
                                    />

                                    {state.payWithBank && (
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <InputField
                                                        label="Payer name"
                                                        inputSize="small"
                                                        placeholder="Payer name"
                                                        InputType="text"
                                                        // returns onChange, onBlur and value related to this input field
                                                        {...formik.getFieldProps('accountName')}
                                                        name="accountName"
                                                    />
                                                    <CustomError name="accountName" component="div" />
                                                </Grid>

                                                <Grid item xs={12} lg={6}>
                                                    <InputField
                                                        label="Bank amount"
                                                        placeholder="Amount"
                                                        inputSize="small"
                                                        InputType="text"
                                                        // returns onChange, onBlur and value related to this input field
                                                        {...formik.getFieldProps('bankAmount')}
                                                        name="bankAmount"
                                                        disabled={state.payWithBank && state.payWithWalletBox === false}
                                                    />
                                                    <CustomError name="bankAmount" component="div" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
                                                    <Box>
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <Typography variant="subtitle1">
                                                                        Upload Proof of Payment :
                                                                    </Typography>
                                                                    <Box>
                                                                        <IconButton
                                                                            color="primary"
                                                                            aria-label="upload picture"
                                                                            component="label"
                                                                        >
                                                                            <input
                                                                                hidden
                                                                                accept="image/png, image/jpg, image/jpeg, .pdf"
                                                                                type="file"
                                                                                name="verificationImage"
                                                                                onChange={(e) => {
                                                                                    const file =
                                                                                        e.target.files &&
                                                                                        e.target.files[0];
                                                                                    if (file) {
                                                                                        formik.setFieldValue(
                                                                                            'verificationImage',
                                                                                            file,
                                                                                        );
                                                                                        const reader = new FileReader();
                                                                                        setUrlImage(
                                                                                            URL.createObjectURL(file),
                                                                                        );
                                                                                        reader.onload = () => {
                                                                                            setVerifImage({
                                                                                                file,
                                                                                                name: file.name,
                                                                                                // url: URL.createObjectURL(file),
                                                                                                targetKey:
                                                                                                    'verificationImage',
                                                                                            });
                                                                                        };
                                                                                        reader.readAsDataURL(file);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <PhotoCamera />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <CustomError name="verificationImage" component="div" />
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <ImageProofVerification
                                                                    verifImage={verifImage}
                                                                    urlImage={urlImage}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
                                                    <Typography variant="body2">Currency</Typography>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        <InputLabel id="demo-simple-select-label">
                                                            Select Currency
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Operation Type"
                                                            {...formik.getFieldProps('operationCurrency')}
                                                        >
                                                            <MenuItem value="USD">US Dollar</MenuItem>
                                                            <MenuItem value="EUR">Euro</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <CustomError name="operationCurrency" component="div" />
                                                </Grid>
                                            </Grid>
                                            {/* <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 3 }}>
                                                <img src={urlImage} alt="Upload ..." width="120px" />
                                                <Box sx={{ width: '100%' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography variant="subtitle1">
                                                            Upload Proof of Payment :
                                                        </Typography>
                                                        <Box>
                                                            <IconButton
                                                                color="primary"
                                                                aria-label="upload picture"
                                                                component="label"
                                                            >
                                                                <input
                                                                    hidden
                                                                    accept="image/*, .pdf, .ai, .psd"
                                                                    type="file"
                                                                    name="verificationImage"
                                                                    onChange={(e) => handleUpload(e, formik)}
                                                                />
                                                                <PhotoCamera />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                    <CustomError name="verificationImage" component="div" />
                                                    {urlImage && (
                                                        <Zoom>
                                                            <img
                                                                src={urlImage ?? Upload}
                                                                alt="productImg"
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: 8,
                                                                    overflow: 'hidden',
                                                                    width: 150,
                                                                    height: 150,
                                                                }}
                                                            />
                                                        </Zoom>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {state.payWithBank && state.payWithWalletBox && (
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <InputField
                                                        label="Name"
                                                        placeholder="Account Name"
                                                        InputType="text"
                                                        // returns onChange, onBlur and value related to this input field
                                                        {...formik.getFieldProps('accountName')}
                                                        name="accountName"
                                                    />
                                                    <CustomError name="accountName" component="div" />
                                                </Grid>

                                                <Grid item xs={12} lg={6}>
                                                    <InputField
                                                        label="Bank amount"
                                                        placeholder="Amount"
                                                        InputType="text"
                                                        // returns onChange, onBlur and value related to this input field
                                                        {...formik.getFieldProps('bankAmount')}
                                                        name="bankAmount"
                                                        disabled={state.payWithBank && !state.payWithWalletBox}
                                                    />
                                                    <CustomError name="bankAmount" component="div" />
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="subtitle1">
                                                            Upload Proof of Payment :
                                                        </Typography>
                                                        <Box>
                                                            <IconButton
                                                                color="primary"
                                                                aria-label="upload picture"
                                                                component="label"
                                                            >
                                                                <input
                                                                    hidden
                                                                    accept="image/*, .pdf, .ai, .psd"
                                                                    type="file"
                                                                    name="verificationImage"
                                                                    onChange={(e) => {
                                                                        const file =
                                                                            e.target.files && e.target.files[0];
                                                                        if (file) {
                                                                            formik.setFieldValue(
                                                                                'verificationImage',
                                                                                file,
                                                                            );
                                                                            const reader = new FileReader();
                                                                            setUrlImage(URL.createObjectURL(file));
                                                                            reader.onload = () => {
                                                                                setVerifImage({
                                                                                    file,
                                                                                    name: file.name,
                                                                                    // url: URL.createObjectURL(file),
                                                                                    targetKey: 'verificationImage',
                                                                                });
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                        }
                                                                    }}
                                                                />
                                                                <PhotoCamera />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                    <CustomError name="verificationImage" component="div" />
                                                    {urlImage && (
                                                        <Zoom>
                                                            <img
                                                                src={urlImage ?? Upload}
                                                                alt="productImg"
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: 8,
                                                                    overflow: 'hidden',
                                                                    width: 150,
                                                                    height: 150,
                                                                }}
                                                            />
                                                        </Zoom>
                                                    )}
                                                </Box>
                                            </Box> */}
                                        </Box>
                                    )}
                                </FormGroup>
                                <Box
                                    sx={{
                                        width: '100%',
                                        gap: '10px',
                                        marginY: '10px',
                                    }}
                                >
                                    <div className="text-[#7D8DA6] ">
                                        You can access our banking information to complete your transaction by clicking
                                        on the following link:{' '}
                                        <span
                                            onClick={drawerOpener}
                                            className="text-[#1C64F2] inline-flex gap-1 cursor-pointer"
                                        >
                                            View Our Bank Information <IoOpenOutline />
                                        </span>
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        gap: '10px',
                                    }}
                                >
                                    <CustomButton
                                        styleType="tertiary"
                                        sizeType="medium"
                                        label="Cancel"
                                        onClick={() => setOpenModal(false)}
                                    />
                                    <CustomButton
                                        label="Confirm"
                                        sizeType="medium"
                                        styleType="primary"
                                        type="submit"
                                        disabled={
                                            formik.isSubmitting ||
                                            (state.payWithBank === false && state.payWithWalletBox === false)
                                        }
                                        onClick={() => {
                                            setValidate(payment(state));
                                        }}
                                        className="!bg-main !hover:bg-main-hover !focus:bg-main-focus"
                                    />
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default PaymentModal;
