import React from 'react';
import PasswordInfo from './passwordInfo';

const PasswordPart: React.FC<{
    customerId: string;
}> = ({ customerId }) => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mb-3">
            <div className="">
                <p className=" text-xl font-medium">Change password</p>
                <p className="text-base dark:text-gray-500 text-gray-600 ">
                    Update your password to maintain account security.
                </p>
            </div>
            <div className="col-span-2 flex flex-col gap-10">
                <div>
                    <PasswordInfo id={customerId} />
                </div>
            </div>
        </div>
    );
};
export default PasswordPart;
