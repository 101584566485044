/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getStatsByCustomer } from 'services/order';
import WidgetPercentIcon from 'components/dashboardCard/widgetPercentIcon';
import toast from 'react-hot-toast';
import { IDashboardProps, IfilterDashboard } from '../dashboard.types';

const LeadsDashboardBasedActions: React.FC<IDashboardProps> = ({ filter }: { filter: IfilterDashboard }) => {
    const [leads, setLeads] = useState({
        newLeads: 0,
        canceledLeads: 0,
        validLeads: 0,
        unreachable: 0,
        confirmedLeads: 0,
        expired: 0,
        wrongNumber: 0,
        pendingAnswer: 0,
        questionAnsewerd: 0,
        scheduledCall: 0,
        invalid: 0,
        upDownCrossSell: 0,
    });

    useEffect(() => {
        getStatsByCustomer(filter)
            .then(async (res: any) => {
                const stats = res.data.docs;
                const updatedLeads = { ...leads };
                if (stats.length > 0) {
                    updatedLeads.newLeads = 0;
                    updatedLeads.canceledLeads = 0;
                    // updatedLeads.validLeads = 0;
                    updatedLeads.unreachable = 0;
                    updatedLeads.confirmedLeads = 0;
                    updatedLeads.wrongNumber = 0;
                    updatedLeads.expired = 0;
                    updatedLeads.pendingAnswer = 0;
                    updatedLeads.questionAnsewerd = 0;
                    updatedLeads.scheduledCall = 0;
                    updatedLeads.invalid = 0;
                    updatedLeads.upDownCrossSell = 0;
                    stats.forEach((stat: any) => {
                        updatedLeads.newLeads += stat.leads.newLeads;
                        updatedLeads.canceledLeads += stat.leads.canceledLeads;
                        updatedLeads.unreachable += stat.leads.unreachable;
                        updatedLeads.confirmedLeads += stat.leads.confirmedLeads;
                        updatedLeads.wrongNumber += stat.leads.wrongNumber;
                        updatedLeads.expired += stat.leads.expired;
                        updatedLeads.pendingAnswer += stat.leads.pendingAnswer;
                        updatedLeads.questionAnsewerd += stat.leads.questionAnsewerd;
                        updatedLeads.scheduledCall += stat.leads.scheduledCall;
                        updatedLeads.invalid += stat.leads.invalid;
                        updatedLeads.upDownCrossSell += stat.orders.upDownCrossSell;
                    });
                } else {
                    updatedLeads.newLeads = 0;
                    updatedLeads.canceledLeads = 0;
                    updatedLeads.unreachable = 0;
                    updatedLeads.confirmedLeads = 0;
                    updatedLeads.wrongNumber = 0;
                    updatedLeads.expired = 0;
                    updatedLeads.pendingAnswer = 0;
                    updatedLeads.questionAnsewerd = 0;
                    updatedLeads.scheduledCall = 0;
                    updatedLeads.invalid = 0;
                    updatedLeads.upDownCrossSell = 0;
                }
                updatedLeads.validLeads = updatedLeads.newLeads - updatedLeads.wrongNumber;
                setLeads(updatedLeads);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [filter]);

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <WidgetPercentIcon
                    // loading={newLeadsNumber.loading}
                    title="New Leads"
                    inputValue={leads.newLeads}
                    statPourcentage={leads.newLeads > 0 ? (leads.newLeads / leads.newLeads) * 100 : 0}
                />
                <WidgetPercentIcon
                    // loading={reachedLeads.loading}
                    title="Wrong Number leads"
                    inputValue={leads.wrongNumber}
                    statPourcentage={leads.newLeads > 0 ? (leads.wrongNumber / leads.newLeads) * 100 : 0}
                />
                <WidgetPercentIcon
                    // loading={reachedLeads.loading}
                    title="Valid leads"
                    inputValue={leads.validLeads}
                    statPourcentage={leads.newLeads > 0 ? (leads.validLeads / leads.newLeads) * 100 : 0}
                />
                <WidgetPercentIcon
                    // loading={confirmedLeadsNumber.loading}
                    title="Confirmed Leads"
                    // infoMessage="Total Leads confirmed"
                    inputValue={leads.confirmedLeads}
                    statPourcentage={leads.validLeads > 0 ? (leads.confirmedLeads / leads.validLeads) * 100 : 0}
                />
                <WidgetPercentIcon
                    // loading={wrongLeadsNumber.loading}
                    title="Canceled Leads"
                    inputValue={leads.canceledLeads}
                    statPourcentage={leads.validLeads > 0 ? (leads.canceledLeads / leads.validLeads) * 100 : 0}
                />
                <WidgetPercentIcon
                    // loading={noReplyLeadsNumber.loading}
                    title="Unreachable total calls"
                    inputValue={leads.unreachable}
                />
                <WidgetPercentIcon
                    // loading={expiredLeadsNumber.loading}
                    title="Scheduled Calls"
                    inputValue={leads.scheduledCall}
                />
                <WidgetPercentIcon
                    // loading={expiredLeadsNumber.loading}
                    title="Pending Answers"
                    inputValue={leads.pendingAnswer}
                />
                <WidgetPercentIcon
                    // loading={expiredLeadsNumber.loading}
                    title="Question Answered"
                    inputValue={leads.questionAnsewerd}
                />
                <WidgetPercentIcon
                    // loading={expiredLeadsNumber.loading}
                    title="Expired leads"
                    inputValue={leads.expired}
                />
                <WidgetPercentIcon
                    // loading={upDownCrossNumber.loading}
                    title="Up/Down/Cross Sell "
                    inputValue={leads.upDownCrossSell}
                    // statPourcentage={}
                />
            </div>
        </>
    );
};

export default LeadsDashboardBasedActions;
