export const titleStyle = {};
export const productSourcingWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
};

export const productSourcingTitle = {
    color: 'textColor.tertiary',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '10px',
};

export const productSourcingDescription = { fontSize: '12px', color: 'textColor.secondary', maxWidth: '800px' };

export const productSourcingContainer = { display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '40px' };

export const sourcingProcessWrapper = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    marginBottom: '-20px',
};

export const sourcingProcessGridStyle = { marginBottom: '80px' };

export const sourcingProcessSubtitles = {
    fontSize: '14px',
    color: 'textColor.tertiary',
    fontWeight: '600',
    marginButtom: '10px',
};

export const sourcingProcessStepperStyle = {
    '& .MuiStepConnector-root > span': {
        minHeight: '20px',
        marginLeft: '8px',
    },

    marginTop: '30px',
};

export const sourcingProcessStepLabel = {
    '& .& .MuiStepLabel-iconContainer': {
        width: '50px',
        height: '50px',
    },
    '& .MuiStepLabel-iconContainer > svg': {
        width: '40px',
        height: '40px',
    },
    '& .MuiStepLabel-iconContainer > .Mui-active': {
        border: ' 1px solid',
        borderColor: 'primary.main',
        borderRadius: '50%',
        padding: '2px',
    },
};

export const sourcingProcessStepLabelStyle = {
    fontSize: '10px',
    color: 'textColor.secondary',
};

export const sourcingProcessStepDescriptionStyle = {
    fontSize: '14px',
    color: 'primary.main',
};

export const basicInfoWrapper = { display: 'flex', flexDirection: 'column', gap: '10px' };
export const addVariantWrapper = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
};

export const addVariantInputsStyle = {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
};

export const addVariantButtonStyle = { margin: '0px 50px' };
export const addVariantQuantityWrapper = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
};
export const addVariantQuantityDescription = {
    padding: '10px',
    fontSize: '14px',
    color: 'textColor.secondary',
};
export const addVariantQuantityError = {
    color: 'textColor.errorColor',
    marginBottom: '10px',
    fontSize: 12,
};

export const optionSelect = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
};
export const newOptionSelectWrapper = {
    borderBottom: '1px solid',
    borderColor: 'borderColor',
    marginBottom: '10px',
};
export const newOptionSelectContainer = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
};
export const optionStyle = {
    fontWeight: '600',
    color: 'textColor.variantColor',
};
export const deleteSelectOption = {
    cursor: 'pointer',
};
export const optionFieldsWrapper = (editMode: any, index: any) => {
    return {
        display: 'flex',
        gap: '10px',
        margin: '10px',
        marginLeft: '40px',
        flexDirection: editMode[index] && editMode[index] === true ? 'row' : 'column',
    };
};
export const selectOptionFieldMode = {
    margin: '10px 0px',
    // marginLeft: '40px',
    display: 'flex',
    justifyContent: 'end',

    // backgroundColor: 'red',
};
export const optionFieldsContainer = {
    marginLeft: '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
};
export const optionInputField = {
    width: '90%',
    maxWidth: '400px',
    minWidth: '220px',
};

export const showNewOptionField = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
};

export const optionFieldError = { color: 'red', margin: '5px 0px' };

export const addNewSelectOption = {
    display: 'flex',
    gap: '20px',
    margin: '20px 0px',
    color: 'primary.main',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
        color: 'primary.dark',
    },
};
export const variantsTableHeadWrapper = {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '2fr 6fr 3fr 1fr',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'borderColor',
    margin: '20px 0px',
    padding: '20px 15px',
    color: 'textColor.secondary',
    fontWeight: '600',
    backgroundColor: 'tertiaryBackground',
};
export const variantsTableRows = (newVariants: any, key: any) => {
    return {
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: '2fr 6fr 3fr 1fr',
        alignItems: 'center',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: 'borderColor',
        margin: '10px 0px',
        padding: '0px 15px',
        color: 'textColor.secondary',
        backgroundColor: newVariants[key as string].disabled ? 'primaryBackground' : 'secondaryBackground',
    };
};
export const variantKeyMode = (newVariants: any, key: any) => {
    return {
        fontWeight: '500',
        color: newVariants[key as string].disabled ? 'textColor.secondary' : 'textColor.variantColor',
    };
};

export const variantDeleteIcon = {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
};

export const productDesignWrapper = { display: 'flex', flexDirection: 'column', gap: '20px' };
export const productDesignImagesContainer = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
};

export const productDesignContainer = { height: '350px', overflow: 'auto' };

export const footerWrapper = () => {
    return {
        backgroundColor: 'primaryBackground',
        margin: '0px -56px -20px -56px',
        // padding: collapsed ? '20px' : '20px 35px 20px 20px',
        padding: '17px',
        // position: 'fixed',
        // bottom: '15px',
        // width: collapsed ? '93%' : 'calc(100% - 200px)',
    };
};

export const footerContainer = { display: 'flex', justifyContent: 'space-between' };

export const footerButtonsStyle = { display: 'flex', gap: '20px' };

export const sourcingTypeWrapper = { display: 'flex', flexDirection: 'column', gap: '50px', justifyContent: 'center' };

export const sourcingTypeHeader = {
    backgroundColor: 'secondaryBackground',
};
export const sourcingTypeHeading = {
    color: 'textColor.tertiary',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
};

export const sourcingTypeDescription = { fontSize: '12px', color: 'textColor.secondary', maxWidth: '800px' };

export const sourcingTypesContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    // backgroundColor: 'red',
};

export const SourcingTypeElements = {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '60px',
    rowGap: '30px',
    justifyContent: 'start',
};
