/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { Button } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaShoppingCart } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { MdInventory2 } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { IChat, getConversationById, getConversations, getUnseenChatsCount } from 'services/chat';
import { IOrder } from 'services/order';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IPaginationData } from 'utils/Interfaces/types';
import { getCurrentUser } from 'utils/helpers/auth';
import { imageDisplay } from 'utils/helpers/imageFinder';
import { socket } from 'utils/socketIO';
import FilterModal from './FilterModal';
import OrderChat from './OrderChat';
import ProductChat from './ProductChat';

type TPagination = {
    page?: number;
    limit?: number;
};

export type TFilter = {
    type: 1 | 2;
    product?: string;
    customer?: string;
    orderRef?: string;
    globalSKU?: string;
};

type TMeta = Omit<Partial<IPaginationData<null>>, 'docs type'>;

interface ISocketNotification {
    _id: string;
    aboutModel: 'Message' | 'ProductHistory';
    content: any;
    createdAt: string;
    updatedAt: string;
    doneBy: { _id: string; email: string; firstName?: string; lastName?: string };
    onModel: 'User' | 'Customer';
    forCustomer: string;
    subject: number;
    seenBy: string[];
}

const tabs: { label: 'Product' | 'Order'; icon: IconType; type: TFilter['type'] }[] = [
    { label: 'Product', icon: MdInventory2, type: 1 },
    { label: 'Order', icon: FaShoppingCart, type: 2 },
];

export default function Conversations() {
    const location = useLocation();
    const locationState = location.state as { chatId: string; type: 'product' | 'order' } | undefined;

    const [conversations, setConversations] = useState<IChat[]>([]);
    const [pagination, setPagination] = useState<TPagination>({});
    const [filter, setFilter] = useState<TFilter>({ type: 1 });
    const [meta, setMeta] = useState<TMeta>({ page: -1 });
    const [activeTab, setActiveTab] = useState<TFilter['type']>(1);
    const [activeConv, setActiveConv] = useState<IChat | null>(null);

    const [chatLoading, setChatLoading] = useState(false);
    const [unseenCount, setUnseenCount] = useState({
        product: '0',
        order: '0',
    });

    // get unsen chats counts
    useEffect(() => {
        // get unseen products chats count
        const initUnseenCountPromises = [getUnseenChatsCount(1), getUnseenChatsCount(2)];
        Promise.all(initUnseenCountPromises)
            .then((resolved) => {
                resolved.forEach((res, idx) => {
                    // first index is for product and the second is for order
                    let count = '0';
                    if (res.data > 9) {
                        count = '+9';
                    } else if (res.data > 0) {
                        count = `${res.data}`;
                    }
                    if (idx === 0) {
                        setUnseenCount((prev) => ({
                            ...prev,
                            product: count,
                        }));
                    } else if (idx === 1) {
                        setUnseenCount((prev) => ({
                            ...prev,
                            order: count,
                        }));
                    }
                });
            })
            .catch(() => {
                toast.error('Sorry, something went wrong unexpectedly. Unable to load unseen chats count');
            });
    }, []);

    // Socket listeners
    useEffect(() => {
        socket.on('new-notification', async (data: ISocketNotification) => {
            if (data.doneBy._id !== getCurrentUser()._id) {
                if (activeConv?._id === data.content.chat._id) {
                    // automatically mark as seen if conversation is currently active
                    const socketData = { chatId: data.content.chat._id, customer: getCurrentUser()._id };
                    socket.emit('markMessagesAsSeen', socketData);
                } else {
                    // get unseen products chats count
                    getUnseenChatsCount((data.subject - 20) as 1 | 2 | 3)
                        .then((res) => {
                            setUnseenCount((prev) => {
                                let count = '0';
                                if (res.data > 9) {
                                    count = '+9';
                                } else if (res.data > 0) {
                                    count = `${res.data}`;
                                }
                                const draftUnseenCount = { ...prev };
                                if (data.subject - 20 === 1) {
                                    draftUnseenCount.product = count;
                                } else if (data.subject - 20 === 2) {
                                    draftUnseenCount.order = count;
                                }
                                return { ...draftUnseenCount };
                            });
                            // update unseen field for the active conversation
                            if (activeConv?._id) {
                                setConversations((prev) =>
                                    prev.map((conv) => {
                                        if (conv.haveUnseenForCustomer && conv._id === activeConv?._id) {
                                            return { ...conv, haveUnseen: false };
                                        }
                                        return conv;
                                    }),
                                );
                            }
                        })
                        .catch(() => {
                            toast.error(
                                'Sorry, something went wrong unexpectedly. Unable to update unseen chats count',
                            );
                        });
                    // fetch the updated list of conversations
                    if (activeTab === filter.type) {
                        getConversations({ ...pagination, ...filter })
                            .then(async (res) => {
                                setConversations(res.docs);
                            })
                            .catch(() => {
                                toast.error(
                                    'Sorry, something went wrong unexpectedly. Unable to load new conversations. Try reloading the page.',
                                );
                            });
                    }
                }
            }
        });

        socket.on('marked-messages-as-seen', async (socketData: { subject: IChat['type']; chatId: string }) => {
            const { subject, chatId } = socketData;
            // get unseen products chats count
            getUnseenChatsCount(subject)
                .then((res) => {
                    setUnseenCount((prev) => {
                        let count = '0';
                        if (res.data > 9) {
                            count = '+9';
                        } else if (res.data > 0) {
                            count = `${res.data}`;
                        }
                        const draftUnseenCount = { ...prev };
                        if (subject === 1) {
                            draftUnseenCount.product = count;
                        } else if (subject === 2) {
                            draftUnseenCount.order = count;
                        }
                        return { ...draftUnseenCount };
                    });
                    // update unseen field for the active conversation
                    if (activeTab === filter.type) {
                        setConversations((prev) => [
                            ...prev.map((conv) => {
                                if (conv.haveUnseenForCustomer && conv._id === chatId) {
                                    return { ...conv, haveUnseenForCustomer: false };
                                }
                                return conv;
                            }),
                        ]);
                    }
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong unexpectedly. Unable to update unseen chats count');
                });
        });

        return () => {
            socket.off('marked-messages-as-seen');
            socket.off('new-notification');
        };
    }, [activeTab, activeConv, filter.type]);

    // paginate conversations if redirected to this page
    useEffect(() => {
        if (locationState && locationState.chatId) {
            // select the proper chat tab depending on the chat type
            setPagination({});
            setMeta({ page: -1 });
            switch (locationState.type) {
                case 'product':
                    setActiveTab(1);
                    setFilter(() => ({ type: 1 }));
                    break;
                case 'order':
                    setActiveTab(2);
                    setFilter(() => ({ type: 2 }));
                    break;
                default:
                    return;
            }
            const toastLoader = toast.loading(`Loading ${locationState.type} conversation`);

            getConversationById(locationState.chatId)
                .then((chat) => {
                    setActiveConv(chat);
                    toast.success(`${locationState.type} conversation loaded successfully!`, { id: toastLoader });
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong unexpectedly', { id: toastLoader });
                });
        }
    }, [locationState]);

    // paginate conversations
    useEffect(() => {
        const isPageActive = pagination.page === meta.page && pagination.limit === meta.limit;
        if (!isPageActive) {
            setChatLoading(true);
            const toastLoader = toast.loading('Loading conversations');
            getConversations({ ...pagination, ...filter })
                .then(async (res) => {
                    setConversations(res.docs);
                    setMeta({
                        hasNextPage: res.hasNextPage,
                        hasPrevPage: res.hasPrevPage,
                        pagingCounter: res.pagingCounter,
                        nextPage: res.nextPage,
                        prevPage: res.prevPage,
                        totalDocs: res.totalDocs,
                        totalPages: res.totalPages,
                        page: res.page,
                        limit: res.limit,
                    });
                    setPagination({ page: res.page, limit: res.limit });
                    toast.success('Loaded conversations successfully', { id: toastLoader });
                    setChatLoading(false);
                })
                .catch(() => {
                    toast.error(
                        'Sorry, something went wrong unexpectedly. Unable to load new conversations. Try reloading the page.',
                        { id: toastLoader },
                    );
                });
        }
    }, [pagination, meta, filter, unseenCount.product, unseenCount.order]);

    const handleFilterChange = (data: Partial<TFilter>) => {
        // update filter state
        setFilter((prev) => {
            // remove empty fields from filter object
            if (Object.values(data)[1] === '') {
                const draftState = { ...prev };
                delete draftState[Object.keys(data)[1] as keyof TFilter];
                return draftState;
            }
            return { ...prev, ...data };
        });
        // update pagination metadata to let the fetcher know that we need new data
        setMeta({ page: -1 });
    };

    const showMoreMessages = () => {
        // increment pagination limit by 10
        setPagination((prev) => ({ ...prev, limit: prev.limit ? prev.limit + 10 : 10 }));
        // update pagination metadata to let the fetcher know that we need new data
        setMeta({ page: -1 });
    };

    const handleTabsChange = (type: 1 | 2) => {
        if (activeTab !== type) {
            setActiveConv(null);
            setActiveTab(type);
            setFilter({ type });
            setPagination({});
            setMeta({ page: -1 });
        }
    };

    const resetFilter = () => {
        setFilter({ type: activeTab });
        setPagination({});
        setMeta({ page: -1 });
    };

    const openConversation = (conversation: IChat) => {
        setActiveConv(conversation);
        if (conversation.haveUnseenForCustomer) {
            const socketData = { chatId: conversation._id, customer: getCurrentUser()._id };
            socket.emit('markMessagesAsSeen', socketData);
        }
    };

    const chatSkeleton = () => {
        const elem = [];
        for (let i = 0; i < 5; i += 1) {
            elem.push(
                <>
                    <div
                        role="status"
                        className="space-y-8 animate-pulse md:space-y-0 md:space-x-3 md:flex md:items-center  mt-2"
                    >
                        <div className="flex items-center justify-center w-32 h-24 bg-gray-300 rounded dark:bg-gray-700">
                            <svg
                                className="w-5 h-5 text-gray-200 dark:text-gray-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 18"
                            >
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                            </svg>
                        </div>
                        <div className="w-full">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]" />
                        </div>
                    </div>
                </>,
            );
        }
        return elem;
    };

    return (
        <div className="flex flex-wrap gap-5 mx-[-50px]">
            <section className="  relative max-h-[90vh] w-full lg:w-[25vw]   flex flex-col justify-start p-2 gap-1 overflow-y-scroll">
                <div className="">
                    <div className="sticky top-[-10px] z-10 bg-white dark:bg-[#0D1117] w-full  mb-1 ">
                        <div className="text-center">
                            <Button.Group>
                                {tabs.map((tab) => (
                                    <Button
                                        color={activeTab === tab.type ? 'info' : 'gray'}
                                        onClick={() => {
                                            handleTabsChange(tab.type);
                                        }}
                                        key={tab.type}
                                    >
                                        {tab.label === 'Order' && unseenCount.order !== '0' && (
                                            <p className="text-white mr-2 w-6 h-6 flex items-center justify-center rounded-full bg-red-600">
                                                {unseenCount.order}
                                            </p>
                                        )}
                                        {tab.label === 'Product' && unseenCount.product !== '0' && (
                                            <p className="text-white mr-2 w-6 h-6 flex items-center justify-center rounded-full bg-red-600">
                                                {unseenCount.product}
                                            </p>
                                        )}
                                        <tab.icon />
                                        <p className="pl-2 text-base">{tab.label}</p>
                                    </Button>
                                ))}
                            </Button.Group>
                        </div>
                        {/* Filters */}
                        <FilterModal
                            filter={filter}
                            handleFilterUpdate={handleFilterChange}
                            chatType={activeTab}
                            resetFilter={resetFilter}
                        />
                    </div>
                    {chatLoading ? (
                        <>{chatSkeleton()}</>
                    ) : conversations && conversations.length > 0 ? (
                        <div className=" flex flex-col justify-start items-start gap-1">
                            {conversations.map((conv) => {
                                if (!conv.customer || !conv.user) {
                                    return null;
                                }
                                if (conv.type === 1 && conv.product) {
                                    return (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                openConversation(conv);
                                            }}
                                            key={conv._id}
                                            className={`dark:hover:bg-gray-700 hover:bg-gray-100 w-full rounded-lg py-4 px-4 gap-2 relative ${
                                                activeConv && activeConv._id === conv._id
                                                    ? 'dark:bg-gray-700 bg-gray-100'
                                                    : 'dark:bg-gray-800 bg-gray-50'
                                            }`}
                                        >
                                            <div className="flex items-center gap-2 ">
                                                <div className="hidden md:flex shrink-0">
                                                    {imageDisplay(
                                                        (conv?.product as IProduct).design,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true,
                                                    )}
                                                </div>

                                                <div className="flex flex-col justify-start items-start gap-1 overflow-hidden whitespace-nowrap w-full">
                                                    <div className="flex gap-2 w-full">
                                                        <p className="opacity-70 text-sm">Product: </p>
                                                        <p
                                                            title={(conv?.product as IProduct).name ?? ''}
                                                            className="font-medium font-mono truncate  w-full  text-left text-sm"
                                                        >
                                                            {(conv?.product as IProduct).name ?? ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                {conv.haveUnseenForCustomer && (
                                                    <div className="absolute top-3 right-3 w-3 h-3 rounded-full bg-red-600" />
                                                )}
                                            </div>
                                        </button>
                                    );
                                }
                                if (conv.type === 2 && conv.order) {
                                    return (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                openConversation(conv);
                                            }}
                                            key={conv._id}
                                            className={`m-1 dark:hover:bg-gray-700 hover:bg-gray-100 w-full rounded-lg py-4 px-6  gap-2 ${
                                                activeConv && activeConv._id === conv._id
                                                    ? 'dark:bg-gray-700 bg-gray-100'
                                                    : 'dark:bg-gray-800 bg-gray-50'
                                            }`}
                                        >
                                            <div className="flex items-center gap-3 relative">
                                                <div className="flex flex-col justify-start items-start gap-1 overflow-hidden whitespace-nowrap">
                                                    <div className="flex gap-2 w-full">
                                                        <p className="opacity-70 text-sm">Order ref: </p>
                                                        <p
                                                            title={(conv?.order as IOrder).orderRef ?? ''}
                                                            className="font-medium font-mono truncate  w-full  text-left text-sm"
                                                        >
                                                            {(conv?.order as IOrder).orderRef ?? ''}
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 w-full">
                                                        <p className="opacity-70 text-sm"> Uploaded at: </p>
                                                        <p
                                                            title={
                                                                new Date(
                                                                    (conv?.order as IOrder).createdAt,
                                                                ).toLocaleDateString() ?? ''
                                                            }
                                                            className="font-medium font-mono truncate  w-full  text-left text-sm"
                                                        >
                                                            {new Date(
                                                                (conv?.order as IOrder).createdAt,
                                                            ).toLocaleDateString() ?? ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                {conv.haveUnseenForCustomer && (
                                                    <div className="absolute top-0 right-0 w-3 h-3 rounded-full bg-red-600" />
                                                )}
                                            </div>
                                        </button>
                                    );
                                }
                                return null;
                            })}
                            {meta.hasNextPage && (
                                <Button className="w-full mt-2" onClick={showMoreMessages}>
                                    Show more
                                </Button>
                            )}
                        </div>
                    ) : (
                        <p>No conversations to show</p>
                    )}
                </div>
            </section>
            <section className="flex-1 dark:bg-[#0D1117]  bg-white h-[90vh]">
                {activeConv && activeConv.product ? (
                    <ProductChat productId={(activeConv.product as IProduct)._id} chatId={activeConv._id} />
                ) : activeConv && activeConv.order ? (
                    <OrderChat
                        orderId={(activeConv.order as IOrder)._id}
                        chatId={activeConv._id}
                        activeOrder={activeConv.order as IOrder}
                    />
                ) : null}
            </section>
        </div>
    );
}
