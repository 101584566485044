import { IOrder, IOrderHistory, IOrderTrace } from 'services/order';

export interface TabBadgeProps {
    tabName: string;
    badgeContent?: number;
}
export interface IStatusTimeline {
    history: IOrderHistory[];
    deliveryAttempts: IOrderTrace[];
}
export interface HistoryProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    history: IStatusTimeline;
    order: IOrder;
}

export interface ActionsProps {
    orderId: string;
    data: IOrder;
    setRows: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage: number;
    currentFilter?: Record<string, any>;
}

export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
export interface IOrderoptionFilter {
    value: string;
    label: string;
}
export const orderTypes: IOrderoptionFilter[] = [
    { value: 'cod', label: 'COD Orders' },
    { value: 'prePaid', label: 'Prepaid Orders' },
];
