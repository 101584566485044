export const searchArea = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
export const subtitle = { color: 'textColor.tertiary' };
export const searchContainer = { width: '19vw' };
export const tabsStyle = {
    width: '80vw',
    minWidth: '20vw',
    '& .MuiTabs-scrollButtons': {
        color: 'textColor.variantBox',
    },
    '& 	.MuiTabs-scroller': {
        overflow: 'hidden',
    },
};

export const orderStatusMatching = (param: number) => {
    switch (param) {
        case 1:
            return { color: 'info', title: 'New' };
        case 2:
            return { color: 'failure', title: 'Canceled' };
        case 3:
            return { color: 'failure', title: 'Unreachable' };
        case 4:
            return { color: 'success', title: 'Confirmed' };
        case 41:
            return { color: 'warning', title: 'Pending answer' };
        case 42:
            return { color: 'success', title: 'Question answered' };
        case 43:
            return { color: 'info', title: 'Scheduled call' };
        case 5:
            return { color: 'warning', title: 'Waiting for shipment' };
        case 6:
            return { color: 'info', title: 'Out for shipment' };
        case 7:
            return { color: 'purple', title: 'Shipped' };
        case 8:
            return { color: 'warning', title: 'Pick and pack' };
        case 81:
            return { title: 'Order received by carrier', color: 'warning' };
        case 82:
            return { color: 'warning', title: 'Arrive to headquarters warehouse' };
        case 83:
            return {
                color: 'warning',
                title: 'Order transferred',
            };
        case 84:
            return {
                color: 'warning',
                title: 'Arrival at Hub',
            };
        case 9:
            return { color: 'info', title: 'Out for delivery' };
        case 10:
            return { color: 'warning', title: 'Delivery attempted' };
        case 101:
            return {
                color: 'warning',
                title: 'Order returned to Hub',
            };
        case 102:
            return {
                color: 'warning',
                title: 'Order returned to Warehouse',
            };
        case 103:
            return {
                color: 'warning',
                title: 'New delivery request',
            };
        case 104:
            return {
                color: 'warning',
                title: 'Wait to WMS',
            };
        case 105:
            return {
                color: 'warning',
                title: 'Delivery scheduled',
            };
        case 11:
            return { color: 'failure', title: 'Delivery failed' };
        case 111:
            return {
                color: 'purple',
                title: 'Return to origin - Out',
            };
        case 112:
            return {
                color: 'purple',
                title: 'Return to origin - In',
            };
        case 113:
            return {
                color: 'purple',
                title: 'Transport management system to Warehouse',
            };
        case 114:
            return {
                color: 'purple',
                title: 'Back to stock',
            };
        case 12:
            return { color: 'success', title: 'Delivered' };
        case 13:
            return { color: 'failure', title: 'Expired' };
        case 14:
            return { color: 'red', title: 'Invalid' };
        case 99:
            return { color: 'purple', title: 'Check required' };
        case 100:
            return { color: 'red', title: 'Hold - No stock available' };
        case 21:
            return { color: 'red', title: 'Wrong number' };
        default:
            return { color: 'info', title: 'new' };
    }
};

export const validActionStatus = [1, 2, 3, 4, 55, 6, 7, 8];
export const actionReason = (param: number) => {
    switch (param) {
        case 1:
            return 'Cancelled: Change of mind';
        case 2:
            return 'Cancelled: Bought already';
        case 3:
            return 'Cancelled: Did not order';
        case 4:
            return 'Cancelled: Price not accepted';
        case 55:
            return 'Other';
        // case 6:
        //     return 'Unreachable: Wrong phone number';
        case 7:
            return 'Unreachable: No answer';
        case 8:
            return 'Unreachable: Phone off';
        default:
            return 'No action';
    }
};
