import { getOrderStatusLabel, getStockStatusLabel } from 'pages/trackingPages/trackingLogistioCodes/statusMatching';
import axios from 'utils/axios/axios';

export const trackOrders = (lgstTrackingCodes: string[]) => {
    return axios.post<{
        [lgstTrackingCode: string]: ({
            time: string;
            location: string;
            description?: string;
            destinationCountry?: string;
        } & (
            | {
                  type: 'order_shipment';
                  status: Exclude<ReturnType<typeof getOrderStatusLabel>, Error>;
              }
            | {
                  type: 'stock_shipment';
                  status: Exclude<ReturnType<typeof getStockStatusLabel>, Error>;
                  destinationCountry: string;
              }
        ))[];
    }>(`/public/track`, { lgstTrackingCodes });
};
