import React from 'react';
import IconButton from '@mui/material/IconButton';
import { IconBtnProps } from './CustomButton.types';

const CustomBtnIcon: React.FC<IconBtnProps> = ({ icon, color, onClick, disabled, size }) => {
    return (
        <>
            <IconButton color={color} onClick={onClick} disabled={disabled} size={size}>
                {icon}
            </IconButton>
        </>
    );
};

export default CustomBtnIcon;
