/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, InputLabel } from '@mui/material';
import CustomButton from 'components/customButton/CustomButton';
import StoreListItem from 'components/storeListItem/StoreListItem';
import { getStores, Ifilter, Ipagination } from 'services/stores';
import StoreDrawer from 'components/storeDrawer/StoreDrawer';
import InputField from 'components/inputField/InputField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import { getfunnels } from 'services/lightfunnels';
import toast from 'react-hot-toast';
import { StoresContainer } from './Stores.styles';

const Stores = () => {
    DynamicTitle('Stores');

    const [open, setOpen] = useState(false);
    const [stores, setStores] = useState<any>([]);
    const [funnels, setFunnels] = useState<any>([]);

    const [filter, setFilter] = useState<Ifilter>({});
    const [pagination] = useState<Ipagination>({
        page: 1,
        limit: 100,
    });

    let [searchInput, setSearchInput] = useState<any>('');
    const [storeSelect, setStoreSelect] = useState<string>();

    const handleChangeVariant = (event: SelectChangeEvent) => {
        setStoreSelect(event.target.value);
    };

    useEffect(() => {
        getfunnels()
            .then((res) => {
                setFunnels(res?.data || []);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, []);
    useEffect(() => {
        switch (storeSelect) {
            case 'shopify':
                setFilter({ ...filter, storeType: 'shopify' });

                break;
            case 'lightfunnels':
                setFilter({ ...filter, storeType: 'lightfunnels' });

                break;
            case 'other':
                setFilter({ ...filter, storeType: 'other' });

                break;
            default:
                setFilter({});
        }
    }, [storeSelect]);

    const handleSearchItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    useEffect(() => {
        if (searchInput !== '') {
            setFilter({ ...filter, storeName: searchInput });
        } else {
            setFilter({});
        }
    }, [searchInput]);

    useEffect(() => {
        getStores(pagination, filter)
            .then((res) => {
                const data = res.data.docs;
                setStores(data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong');
            });
    }, [pagination, filter]);

    return (
        <Box>
            <StoreDrawer open={open} setOpen={setOpen} submitFunction="add" setStores={setStores} funnels={funnels} />

            <div className="flex flex-wrap items-center justify-between gap-2">
                <div className="flex items-center gap-1 flex-wrap">
                    <InputField
                        InputType="search"
                        placeholder="Search store name"
                        inputSize="small"
                        onChange={handleSearchItems}
                    />
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="demo-simple-select-label">Store Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filter.storeType?.toString()}
                            label="Product Type"
                            onChange={handleChangeVariant}
                        >
                            <MenuItem value="">Show all</MenuItem>
                            <MenuItem value="shopify">Shopify</MenuItem>
                            <MenuItem value="lightfunnels">Lightfunnels</MenuItem>
                            <MenuItem value="other">Others</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <CustomButton
                        sizeType="medium"
                        styleType="primary"
                        label="Add store"
                        size="medium"
                        onClick={() => setOpen(true)}
                    />
                </div>
            </div>
            <Box sx={StoresContainer}>
                {stores &&
                    stores.map((store: any) => (
                        <StoreListItem key={store.id} store={store} funnels={funnels} setStores={setStores} />
                    ))}
            </Box>
        </Box>
    );
};

export default Stores;
