export function memoize<Fn extends (...args: any[]) => any>(fn: Fn) {
    const cache = new Map<string, any>();

    return function Fn(...args: Parameters<Fn>): ReturnType<Fn> {
        const cacheKey = JSON.stringify(args);
        if (cache.has(cacheKey)) {
            return cache.get(cacheKey);
        }
        const computedValue = fn(...args);
        cache.set(cacheKey, computedValue);
        return computedValue;
    };
}
