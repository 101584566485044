import { Tooltip } from 'flowbite-react';
import React from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';

const DashboardWidgetPercent: React.FC<{
    // statPourcentage?: number;
    inputValue: number | string;
    title: string;
    loading?: boolean;
    errorHandler: boolean;
    infoMessage?: string;
}> = ({ inputValue, title, loading, errorHandler, infoMessage }) => {
    return (
        <>
            {loading ? (
                <div className=" h-28 min-w-0 bg-white dark:bg-gray-700 sm:rounded-lg overflow-hidden py-2 flex flex-col gap-2 shadow-sm border border-gray-100 dark:border-gray-700 animate-pulse">
                    <div>
                        <div className="flex justify-center">
                            <div className="h-2.5 bg-gray-400 rounded-full dark:bg-indigo-200 w-48" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className=" col-span-2 flex flex-col justify-center items-center">
                            <div className=" ">
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-indigo-200 w-36 mb-4" />

                                <div className=" flex justify-end">
                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-indigo-200  mb-4 w-20" />
                                </div>
                            </div>
                        </div>
                        <div className=" flex justify-center items-center ">
                            <div className=" bg-gray-300 rounded-full dark:bg-indigo-200  mb-4 h-16 w-16 " />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="SmallSales h-28 pl-6 bg-white dark:bg-gray-800 hover:shadow-lg rounded-2xl w-full">
                    <div className="flex justify-between items-center pr-3 pt-2">
                        <div className="font-medium text-base text-[#A3AED0] font-popin">{title}</div>
                        {infoMessage && (
                            <Tooltip
                                theme={{
                                    target: 'w-fit',
                                    animation: 'transition-opacity',
                                    arrow: {
                                        base: 'absolute z-10 h-2 w-2 rotate-45',
                                        style: {
                                            dark: 'bg-gray-900 dark:bg-gray-700',
                                            light: 'bg-white',
                                            auto: 'bg-white dark:bg-gray-700',
                                        },
                                        placement: '-4px',
                                    },
                                    base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm',
                                    hidden: 'invisible opacity-0',
                                    style: {
                                        dark: 'bg-gray-900 text-white dark:bg-gray-700',
                                        light: 'border border-gray-200 bg-green text-gray-900',
                                        auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
                                    },
                                    content: 'relative z-20',
                                }}
                                content={infoMessage || title}
                            >
                                <BsInfoCircleFill className="h-5 w-5 fill-zinc-400" role="button" />
                            </Tooltip>
                        )}
                    </div>
                    <div className="flex flex-cols-2 gap-1 w-90">
                        <div className=" flex flex-col ">
                            <div className="font-poppin font-semibold text-2xl text-[#6874F5]">
                                $ {errorHandler ? '--' : inputValue}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardWidgetPercent;
