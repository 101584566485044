import { IdateRange } from 'pages/dashboard/dashboard.types';
import { IWalletOperationAggregation } from 'pages/wallet/wallet.types';
import axios from 'utils/axios/axios';

import { getBearerToken } from 'utils/helpers/auth';

export interface Ipagination {
    page: number;
    limit: number;
}
export interface Ifilter {
    wallet?: string;
    status?: number;
    operationType?: string;
    createdAt?: string;
    dateRange?: object;
}
export interface IAggregation {
    store?: string;
    prePaid?: boolean;
    cod?: boolean;
    dateRange?: IdateRange;
    lifeCycleTracking?: boolean;
    createdAt?: object;
}
export type PaginationAndFiltres = Ipagination & Ifilter;
export const getWallet = async () => {
    const config = await getBearerToken();
    const wal = await axios.get(`/wallet/`, config);
    return wal;
};
export const getWalletOperations = async (pagination: Ipagination, filter: Ifilter) => {
    const config = await getBearerToken();
    const response = await axios.post(`/walletOperation/filter`, { ...pagination, ...filter }, config);
    return response;
};

export const chargeWallet = async (data: any) => {
    const config = await getBearerToken();
    const wal = await axios.post(`/walletOperation/`, data, config);
    // const filter = { status: 2 };
    // const walletsop = await axios.post(`/walletOperation/filter`, filter, config);
    // const customerId = getCurrentUser()._id;
    // socket.emit('joinNotifications', { customer: customerId });

    return wal;
};
export const getStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Pending';
        case 2:
            return 'Confirmed';
        case 3:
            return 'Rejected';
        default:
            return 'Pending';
    }
};
export const aggregateWalletOperations = async (data: IAggregation) => {
    const config = await getBearerToken();
    const response = await axios.post<IWalletOperationAggregation[]>(`/walletOperation/aggregate`, { ...data }, config);
    return response;
};
