/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ThemeSwitch from 'components/Switch/themeSwitch';
import Wallet from 'components/wallet/wallet';
import AvatarMenu from 'components/avatarMenu/avatarMenu';
import { getCustomer } from 'services/customer';
import { getCurrentUser, getUser } from 'utils/helpers/auth';
import { AllUserProps } from 'pages/profileSettings/ProfileSettings.types';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

interface bredCInterface {
    element: string;
    gobackUrl?: string;
}
const Navbar = () => {
    const { pathname, state } = useLocation();
    const [customer, setCustomer] = useState<AllUserProps>();

    const customerId = getCurrentUser()._id;
    const getName = `${getUser().firstName} ${getUser().lastName}`;
    const name = getName.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

    const breadCrumbsConfiguration = () => {
        const pathnameSplit = pathname.split('/');
        const isLogistioShop = pathnameSplit.includes('logistio-shop');

        if (pathnameSplit.length > 2 && isLogistioShop) {
            return pathnameSplit.slice(1, -1).map((el) => ({ element: el, gobackUrl: '' }));
        }
        const list = pathnameSplit
            .slice(1)
            .map((el) => ({ element: el.replaceAll('-', ' '), gobackUrl: '' }) as bredCInterface);
        if (list?.length === 1 && list[0].element === 'products') {
            list.splice(0, 1, { element: 'products list' });
        }
        if (list?.length === 1 && list[0].element === 'product settings' && state?.productName) {
            list.splice(
                0,
                1,
                {
                    element: 'products list',
                    gobackUrl: `/${state.isRestock ? 'restock' : 'products'}`,
                },
                { element: state.productName },
            );
        }
        return list;
    };

    const breadCrumbs = breadCrumbsConfiguration();

    useEffect(() => {
        getCustomer(customerId).then((res) => {
            setCustomer(res.data);
        });
    }, []);

    return (
        <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center">
                <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li className="inline-flex items-center text-lg font-light text-gray-700 hover:text-[#5C64FC] dark:text-gray-400 dark:hover:text-white">
                            <Link to="/dashboard" className="inline-flex items-center ">
                                <p className="">Home</p>
                            </Link>
                        </li>
                    </ol>
                    {breadCrumbs.map((bredC) => {
                        const label = bredC.element.charAt(0).toUpperCase() + bredC.element.slice(1);
                        const title = label === 'Products' ? 'Product List' : label;

                        return (
                            <ol
                                className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse "
                                key={bredC.element}
                            >
                                <li className="inline-flex items-center text-lg font-light text-gray-700 hover:text-[#5C64FC] dark:text-gray-400 dark:hover:text-white">
                                    <div className="inline-flex items-center ">
                                        <MdOutlineArrowForwardIos className="w-3 h-3 mx-3" />
                                        {bredC?.gobackUrl ? (
                                            <Link to={bredC?.gobackUrl || ''} className="inline-flex items-center ">
                                                <p
                                                    className="text-lg font-light text-gray-700  dark:text-gray-400 truncate max-w-52  hover:text-[#5C64FC]"
                                                    title={title}
                                                >
                                                    {title}
                                                </p>
                                            </Link>
                                        ) : (
                                            <p
                                                className="text-lg font-light text-gray-700  dark:text-gray-400 truncate max-w-52 "
                                                title={title}
                                            >
                                                {title}
                                            </p>
                                        )}
                                    </div>
                                </li>
                            </ol>
                        );
                    })}
                </nav>
            </div>
            <div className="flex items-center justify-end ">
                <Wallet currency={customer?.currency as string} />
                <ThemeSwitch />
                {/* <Notifications /> */}
                <AvatarMenu name={name} />
            </div>
        </div>
    );
};

export default Navbar;
