/* eslint-disable no-restricted-syntax */
import React, { useEffect, useMemo, useState } from 'react';
import { getStatsByCustomer } from 'services/order';
import DashboardWidgetPercent from 'components/dashboardCard/dashboardWidgetPercent';
import { Banner, Button } from 'flowbite-react';
import { BiSolidCommentError } from 'react-icons/bi';
import { HiX } from 'react-icons/hi';

import toast from 'react-hot-toast';
import { IDashboardProps, IerrorHandler } from '../dashboard.types';

const AccountingStats: React.FC<IDashboardProps> = (data) => {
    const { filter, dashboardStore } = data;
    const [accounting, setAccounting] = useState({
        newLeadsFees: 0,
        upDownCrossSellFees: 0,
        totalSales: 0,
        profit: 0,
        confirmedLeadsFees: 0,
        deliveredLeasFees: 0,
        deliveredFees: 0,
        returnedFees: 0,
        codFees: 0,
        totalResellerFees: 0,
        handleFees: 0,
        shippingFees: 0,
    });
    const [credits, setCredits] = useState({
        ads: 0,
        cogs: 0,
    });
    const [errorHandler, setErrorHandler] = useState<IerrorHandler>({
        status: false,
        visibility: false,
        errorText: '',
    });

    // const [customerCurrency] = useState('USD');
    useMemo(() => {
        setErrorHandler((prev) => {
            return { ...prev, status: false, visibility: false, errorText: '' };
        });
    }, []);
    useEffect(() => {
        getStatsByCustomer({
            ...filter,
        })
            .then(async (res: any) => {
                const stats = res.data.docs;
                const updatedAccounting = { ...accounting };
                const updatedCredits = { ...credits };
                if (stats.length > 0) {
                    updatedAccounting.newLeadsFees = 0;
                    updatedAccounting.upDownCrossSellFees = 0;
                    updatedAccounting.totalSales = 0;
                    updatedAccounting.profit = 0;
                    updatedAccounting.confirmedLeadsFees = 0;
                    updatedAccounting.deliveredLeasFees = 0;
                    updatedAccounting.deliveredFees = 0;
                    updatedAccounting.returnedFees = 0;
                    updatedAccounting.codFees = 0;
                    updatedAccounting.totalResellerFees = 0;
                    updatedAccounting.handleFees = 0;
                    updatedAccounting.shippingFees = 0;
                    updatedCredits.ads = 0;
                    updatedCredits.cogs = 0;
                    stats.forEach((stat: any) => {
                        updatedAccounting.newLeadsFees += stat.accounting.newLeadsFees;
                        updatedAccounting.upDownCrossSellFees += stat.accounting.upDownCrossSellFees;
                        updatedAccounting.totalSales += stat.accounting.totalSales;
                        updatedAccounting.profit += stat.accounting.profit;
                        updatedAccounting.confirmedLeadsFees += stat.accounting.confirmedLeadsFees;
                        updatedAccounting.deliveredLeasFees += stat.accounting.deliveredLeasFees;
                        updatedAccounting.deliveredFees += stat.accounting.deliveredFees;
                        updatedAccounting.returnedFees += stat.accounting.returnedFees;
                        updatedAccounting.codFees += stat.accounting.codFees;
                        updatedAccounting.totalResellerFees += stat.accounting.totalResellerFees;
                        updatedAccounting.handleFees += stat.accounting.handleFees;
                        updatedAccounting.shippingFees += stat.accounting.shippingFees;
                        updatedCredits.ads += stat.credits.ads;
                        updatedCredits.cogs += stat.credits.cogs;
                    });
                } else {
                    updatedAccounting.newLeadsFees = 0;
                    updatedAccounting.upDownCrossSellFees = 0;
                    updatedAccounting.totalSales = 0;
                    updatedAccounting.profit = 0;
                    updatedAccounting.confirmedLeadsFees = 0;
                    updatedAccounting.deliveredLeasFees = 0;
                    updatedAccounting.deliveredFees = 0;
                    updatedAccounting.returnedFees = 0;
                    updatedAccounting.codFees = 0;
                    updatedAccounting.totalResellerFees = 0;
                    updatedAccounting.handleFees = 0;
                    updatedAccounting.shippingFees = 0;
                    updatedCredits.ads = 0;
                    updatedCredits.cogs = 0;
                }
                setCredits(updatedCredits);
                setAccounting(updatedAccounting);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, [filter]);
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-3">
            {((!dashboardStore && !filter.prePaid) || dashboardStore?.withCC) && (
                <>
                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={newLeadsFees.loading}
                        title="New Leads Fees"
                        inputValue={accounting.newLeadsFees.toFixed(2)}
                        // statPourcentage={newLeadsFees.percent}
                    />
                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={confirmedLeadsFees.loading}
                        title="Confirmed Leads Fees"
                        inputValue={accounting.confirmedLeadsFees.toFixed(2)}
                        // statPourcentage={confirmedLeadsFees.percent}
                    />

                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={deliverdOrderFees.loading}
                        title="Delivered Leads Fees"
                        inputValue={accounting.deliveredLeasFees.toFixed(2)}
                        // statPourcentage={deliverdOrderFees.percent}
                    />
                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={upDownCrossFees.loading}
                        title="Up/Down/Cross Fees"
                        inputValue={accounting.upDownCrossSellFees.toFixed(2)}
                        // statPourcentage={upDownCrossFees.percent}
                    />
                </>
            )}
            {(!dashboardStore || !dashboardStore.withCC) && (
                <>
                    {filter.prePaid === true && (
                        <DashboardWidgetPercent
                            errorHandler={errorHandler.status}
                            // loading={shippingFees.loading}
                            title="Shipping Fees"
                            // infoMessage="Shipping fees are applied to prepaid orders."
                            inputValue={accounting.shippingFees.toFixed(2)}
                            // statPourcentage={deliverdOrderFees.percent}
                        />
                    )}

                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={handleFees.loading}
                        title="Handle Fees"
                        infoMessage="Paid"
                        inputValue={accounting.handleFees.toFixed(2)}
                        // statPourcentage={upDownCrossFees.percent}
                    />
                </>
            )}
            <DashboardWidgetPercent
                errorHandler={errorHandler.status}
                // loading={profits.loading}
                title="Total Reseller Fees"
                inputValue={accounting.totalResellerFees.toFixed(2)}
            />
            {(filter.cod === true || filter.cod !== false) && (
                <>
                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={deliveredLeadsFees.loading}
                        title="Delivered Orders Fees"
                        inputValue={accounting.deliveredFees.toFixed(2)}
                        // statPourcentage={deliveredLeadsFees.percent}
                    />

                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={returnedOrderFees.loading}
                        title="Returned Orders Fees"
                        inputValue={accounting.returnedFees.toFixed(2)}
                        // statPourcentage={returnedOrderFees.percent}
                    />
                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={codFees.loading}
                        title="COD Fees"
                        inputValue={accounting.codFees.toFixed(2)}
                        // statPourcentage={codFees.percent}
                    />
                    <DashboardWidgetPercent
                        errorHandler={errorHandler.status}
                        // loading={totalSales.loading}
                        title="Total Sales"
                        inputValue={accounting.totalSales.toFixed(3)}
                    />
                    {dashboardStore && !dashboardStore?.withCC && (
                        <DashboardWidgetPercent
                            errorHandler={errorHandler.status}
                            infoMessage="Total sales - Total fees"
                            // loading={profits.loading}
                            title="Profits"
                            inputValue={(
                                accounting.totalSales -
                                (accounting.deliveredFees +
                                    accounting.returnedFees +
                                    accounting.codFees +
                                    accounting.totalResellerFees +
                                    credits.cogs +
                                    credits.ads)
                            ).toFixed(2)}
                        />
                    )}
                    {dashboardStore && dashboardStore?.withCC && (
                        <DashboardWidgetPercent
                            errorHandler={errorHandler.status}
                            // loading={profits.loading}
                            title="Profits"
                            inputValue={(
                                accounting.totalSales -
                                (accounting.newLeadsFees +
                                    accounting.confirmedLeadsFees +
                                    accounting.upDownCrossSellFees +
                                    accounting.deliveredLeasFees +
                                    accounting.deliveredFees +
                                    accounting.returnedFees +
                                    accounting.codFees +
                                    accounting.totalResellerFees +
                                    credits.cogs +
                                    credits.ads)
                            ).toFixed(2)}
                        />
                    )}
                    {!dashboardStore && (
                        <DashboardWidgetPercent
                            errorHandler={errorHandler.status}
                            // loading={profits.loading}
                            title="Profits"
                            inputValue={(
                                accounting.totalSales -
                                (accounting.newLeadsFees +
                                    accounting.confirmedLeadsFees +
                                    accounting.upDownCrossSellFees +
                                    accounting.deliveredLeasFees +
                                    accounting.deliveredFees +
                                    accounting.returnedFees +
                                    accounting.codFees +
                                    accounting.totalResellerFees +
                                    credits.cogs +
                                    credits.ads)
                            ).toFixed(2)}
                        />
                    )}
                </>
            )}
            {errorHandler.visibility && (
                <Banner>
                    <div className="fixed left-[calc(78%-2rem)] top-[calc(95%-2rem)] z-50 flex w-[calc(50%-2rem)] -translate-x-1/2 flex-col justify-between rounded-lg border border-gray-100 bg-red-300 p-4 shadow-sm dark:border-red-600 dark:bg-red-200 md:flex-row lg:max-w-7xl">
                        <div className=" lex  gap-2 mb-3 mr-4 flex flex-col items-start md:mb-0 md:flex-row md:items-center">
                            <BiSolidCommentError className="h-5 w-5" color="#f50c0c" />

                            <p className="flex items-center text-sm font-normal text-gray-800 dark:text-gray-950">
                                {errorHandler?.errorText}
                            </p>
                        </div>
                        <div className="flex flex-shrink-0 items-center" />
                        <Button
                            color="gray"
                            className="border-0 bg-transparent text-gray-500 dark:text-gray-400"
                            onClick={() => {
                                setErrorHandler((prev) => {
                                    return { ...prev, visibility: false };
                                });
                            }}
                        >
                            <HiX className="h-4 w-4" />
                        </Button>
                    </div>
                </Banner>
            )}
        </div>
    );
};

export default AccountingStats;
