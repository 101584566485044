/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Zoom from 'react-medium-image-zoom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PdfImage from '../../assets/pdf.png';
import FileImage from '../../assets/file.png';
import { ProductImageUploadingProps } from './ProductImageUploading.types';
import { progressBarStyle } from './ProductImageUploading.styles';

const ProductImageUploading: React.FC<ProductImageUploadingProps> = ({
    img,
    progress,
    allImages,
    setAllImages,
    index,
    imgName,
}) => {
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        if (progress === 100)
            setTimeout(() => {
                setSuccess(true);
            }, 500);
    }, [progress]);

    return (
        <>
            <div className="flex gap-1">
                <Box sx={{ position: 'relative' }}>
                    {imgName.endsWith('.jpg') ||
                    imgName.endsWith('.png') ||
                    imgName.endsWith('.jpeg') ||
                    imgName.endsWith('.JPG') ||
                    imgName.endsWith('.PNG') ||
                    imgName.endsWith('.JPEG') ||
                    imgName.endsWith('.wepb') ||
                    imgName.endsWith('.WEPB') ? (
                        <Zoom>
                            <img
                                alt="product design"
                                src={img}
                                style={{
                                    width: 150,
                                    height: 150,
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    borderRadius: 7,
                                }}
                            />
                        </Zoom>
                    ) : imgName.endsWith('.pdf') || imgName.endsWith('.PDF') ? (
                        <Zoom>
                            <img
                                alt="product design"
                                src={PdfImage}
                                style={{
                                    width: 150,
                                    height: 150,
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    borderRadius: 7,
                                }}
                            />
                        </Zoom>
                    ) : (
                        <Zoom>
                            <img
                                alt="product design"
                                src={FileImage}
                                style={{
                                    width: 150,
                                    height: 150,
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    borderRadius: 7,
                                }}
                            />
                        </Zoom>
                    )}
                    <Box sx={progressBarStyle}>
                        {!success && <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />}
                    </Box>

                    <Button
                        variant="contained"
                        color="error"
                        sx={{
                            position: 'absolute',
                            top: 2,
                            right: 2,
                            zIndex: 1,
                            padding: '5px',
                            borderRadius: 100,
                            minWidth: 'fit-content',
                        }}
                        onClick={() => {
                            const images = [...allImages];
                            images.splice(index, 1);
                            setAllImages(images);
                        }}
                    >
                        <DeleteForeverIcon sx={{ fontSize: '21px' }} />
                    </Button>
                </Box>
            </div>
        </>
    );
};

export default ProductImageUploading;
