/* eslint-disable no-nested-ternary */

export const ButtonStyle = (sizeType: string, styleType: string, disabled?: boolean) => {
    return {
        backgroundColor: disabled
            ? ''
            : styleType === 'primary'
              ? 'primary.main'
              : styleType === 'success'
                ? '#388e3c'
                : 'transparent',
        '&.MuiButton-outlined': {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: styleType === 'secondary' ? 'primary.dark' : '#991717',
        },

        fontWeight: 500,
        fontSize: '14px',
        textDecoration: styleType === 'tertiary' ? ' underline' : '',
        color: disabled
            ? 'red'
            : styleType === 'primary' || styleType === 'success'
              ? '#fff'
              : styleType === 'disabled'
                ? '#991717 !important'
                : styleType === 'secondary'
                  ? 'primary.dark'
                  : styleType === 'danger'
                    ? '#991717'
                    : 'textColor.secondary',
        width: sizeType === 'small' ? '80px' : sizeType === 'medium' ? '130px' : '100%',

        '&:hover': {
            backgroundColor:
                styleType === 'primary'
                    ? '#545bf0'
                    : styleType === 'secondary'
                      ? 'primary.main'
                      : styleType === 'danger'
                        ? '#991717'
                        : styleType === 'success'
                          ? '#2a852f'
                          : '#D9DEF2',

            color:
                styleType === 'primary' ||
                styleType === 'success' ||
                styleType === 'secondary' ||
                styleType === 'danger'
                    ? 'textColor.quaternary'
                    : 'textColor.secondary',
        },
        paddingRight: '10px',
        paddingLeft: '10px',
        // marginTop: '10px',
        // marginBottom: '10px',
    };
};
