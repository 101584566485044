/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { getpayments, IdateRange, Ifilter, IPayments, paymetSources } from 'services/payments';
import { Select } from 'flowbite-react';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './paymentsTableActions/paymentsElements';

interface Ipagination {
    page: number;
    limit: number;
}
interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

const Payments = () => {
    const [paymentsList, setPaymentsList] = useState<Array<IPayments>>([]);

    // const [paymentData, setPaymentData] = useState<IPayment | {}>({});
    const [customerId] = useState('');
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 10,
    });
    const [filter, setFilter] = useState<Ifilter>({});
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);
    const [rangevalue, setRangeValue] = useState<IdateRange>({
        startDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2).toDateString(),
        endDate: new Date().toDateString(),
    });
    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    // fetch stores on component mount & pagination or filter change
    useEffect(() => {
        /**
         * apply pagination & filter if filter options exists
         * else ignore filter options and only use pagination
         */
        const queryOptions = Object.values(debouncedFilter)[0]
            ? customerId !== ''
                ? { ...debouncedPagination, ...debouncedFilter }
                : { ...debouncedPagination, ...debouncedFilter }
            : customerId !== ''
              ? { ...debouncedPagination, customer: customerId }
              : { ...debouncedPagination };

        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        // const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        /**
         * handle customer id filter change doesn't block request
         */
        // if (!fetchedAndActive) {
        setIsLoading(true);
        getpayments(queryOptions)
            .then((res) => {
                const data = res.data.response;
                setPaymentsList(data.docs);
                setMeta({
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                // toast.error(err.response.data.errors.message);
            });
    }, [debouncedFilter, debouncedPagination, customerId]);

    const handlePageChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };
    const handleRangeFilterChange = (newValue: IdateRange | null) => {
        if (newValue) {
            const startD = moment(newValue.startDate);
            const endD = moment(newValue.endDate);
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            setRangeValue({
                startDate: startD.toDate(),
                endDate: endD.toDate(),
            });
            setFilter({
                ...filter,
                dateRange: {
                    startDate: startD.toDate(),
                    endDate: endD.toDate(),
                },
            });
            setPagination({ ...pagination, page: 1 });
            setMeta({ ...meta, page: -1 });
        }
    };
    return (
        <>
            <div className="flex items-center mb-2 flex-wrap gap-1 ">
                <div id="select">
                    <Select
                        id="operationType"
                        required
                        value={filter.status?.toString()}
                        onChange={(e) => {
                            setFilter({ ...filter, status: Number(e.target.value) });
                            setMeta({ ...meta, page: -1 });
                        }}
                    >
                        <option value="">All Status</option>
                        <option value="1">Pending</option>
                        <option value="2">Confirmed</option>
                        <option value="3">Rejected</option>
                    </Select>
                </div>
                <div id="sourcePayment">
                    <Select
                        id="sourcePaymentSelect"
                        required
                        value={filter.source?.toString()}
                        onChange={(e) => {
                            setFilter({ ...filter, source: e.target.value });
                            setMeta({ ...meta, page: -1 });
                        }}
                    >
                        <option value="">All Sources</option>
                        {paymetSources.map((sourceType) => {
                            return <option value={sourceType.value}>{sourceType.label}</option>;
                        })}
                    </Select>
                </div>

                <div>
                    <Datepicker
                        inputClassName="w-full  border-gray-300  pl-4 pr-10 font-light text-sm rounded-md focus:ring-0 font-normal bg-custom-background dark:border-slate-600 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20"
                        inputId="deliveryDate"
                        value={rangevalue}
                        containerClassName="relative z-20"
                        useRange
                        onChange={handleRangeFilterChange}
                    />
                </div>
            </div>

            <DataTable
                rows={paymentsList}
                columns={columns}
                icons={statusDisplay}
                setRows={setPaymentsList}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePageChange}
            />
        </>
    );
};

export default Payments;
