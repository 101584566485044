import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { trackOrders } from 'services/publicServices/track';
import { trackingCodeValidation } from 'utils/validations/trackingCodeValidation';
import trackingBackground from '../../assets/trackBackground.png';

const TrackingPage = () => {
    const navigate = useNavigate();
    const handleSubmit = async (trackingNumbers: string) => {
        const lgstTrackingCodes: string[] = [];
        trackingNumbers
            .replaceAll(' ', '')
            .split(',')
            .forEach((el) => {
                if (el) lgstTrackingCodes.push(el);
            });

        const regex = /^(LGST|LGSTS)\d+$/;
        const validTrackingCodes = lgstTrackingCodes.filter((elt: string) => {
            if (!regex.test(elt) && elt) {
                toast.error(
                    `Please verify the format of your tracking code, ${elt} doesn't match the required Logistio format`,
                );
                return false;
            }
            if (elt) {
                return true;
            }
            return false;
        });

        const screenWidth = window.innerWidth;
        const tostPosition = screenWidth <= 640 ? 'top-center' : 'top-right';
        if (validTrackingCodes?.length) {
            const toastId = toast.loading('Getting your tracking data...', { position: tostPosition });
            await trackOrders(validTrackingCodes)
                .then((res) => {
                    toast.success('Tracking data is available', { id: toastId, position: tostPosition });
                    navigate('/tracking/tracking-results', {
                        state: { trackingData: res.data },
                    });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message || 'Something went wrong!', {
                        id: toastId,
                        position: tostPosition,
                    });
                });
        }
    };
    return (
        <>
            <div className="bg-[#E4EFFF] dark:bg-[#000246] h-full ">
                <div
                    className="flex sm:items-center flex-row  bg-no-repeat h-full px-8 sm:bg-[length:65vw_71vh]  bg-[length:170vw_50vh] sm:bg-right-bottom bg-[right_bottom_-3rem] bg-[position:65%_110%]"
                    style={{
                        backgroundImage: `url(${trackingBackground})`,
                    }}
                >
                    <div className="w-full max-w-lg  sm:ml-[48px] sm:p-4  flex flex-col gap-4 relative sm:top-[-5vh] top-[10vh] h-fit">
                        <div className="text-center sm:text-start">
                            <p className="font-semibold sm:text-5xl text-3xl text-[#000246] dark:text-white  sm:w-[70%]">
                                Tracking your order{' '}
                            </p>
                            <p className="text-[#42516B] dark:text-[#667085] text-lg p-2">
                                Stay informed about your shipments in real time.
                            </p>
                        </div>
                        <div>
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                initialValues={{
                                    trackingNumbers: '',
                                }}
                                onSubmit={({ trackingNumbers }) => {
                                    handleSubmit(trackingNumbers);
                                }}
                                validationSchema={trackingCodeValidation}
                            >
                                {(formik) => (
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className=" block sm:flex  gap-2 p-1 sm:bg-white rounded-md sm:border-2 sm:border-[#A8C7FF]">
                                            <input
                                                {...formik.getFieldProps('trackingNumbers')}
                                                name="trackingNumbers"
                                                id="trackingNumbers"
                                                className="focus:outline-none !focus:bg-white w-[100%] sm:w-[70%] px-2 h-10	rounded-md mb-2 sm:mb-0 dark:text-black"
                                                placeholder="Enter up to 100 Tracking number (eg. LGST878692)"
                                            />
                                            <button
                                                type="submit"
                                                className="text-white bg-[#007FFF] focus:outline-none font-medium rounded-md   w-[100%] sm:w-[30%] text-sm px-5 py-2.5  flex  items-center justify-center gap-2 "
                                            >
                                                Track
                                            </button>
                                        </div>

                                        <ErrorMessage name="trackingNumbers" component="div" className="text-red-600" />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="bottom-0 bg-white 	fixed w-full sm:h-8 lg:h-8  text-[#8D99AE] text-center sm:px-8  sm:flex justify-between lg:items-center lg:px-4">
                    <span>All rights reserved © 2024 Logistio</span>
                    <div className="flex justify-center gap-2 text-center">
                        <span>Cookies</span>|<span>Terms of Use</span>|<span>Privacy Policy</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrackingPage;
