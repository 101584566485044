/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
import { Alert } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi2';
import { getCustomer } from 'services/customer';
import { lightfunnelsMatch } from 'services/lightfunnels';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { getCurrentUser } from 'utils/helpers/auth';

const Redirection = () => {
    const params = new URLSearchParams(window.location.search);
    const [errorIntegration, setErrorIntegration] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { REACT_APP_URI } = process.env;
    useEffect(() => {
        const customerId = getCurrentUser()._id;
        getCustomer(customerId)
            .then((res) => {
                const customer: ICustomer = res.data;
                if (customer.lightFunnelsId && customer.lightFunnelsToken) {
                    window.location.href = `${REACT_APP_URI}`;
                } else {
                    lightfunnelsMatch({ code: params.get('code') })
                        .then(() => {
                            window.location.href = `${REACT_APP_URI}`;
                        })
                        .catch((err) => {
                            setErrorMessage(err?.response?.data?.errors?.message);
                            setErrorIntegration(true);
                        });
                }
            })
            .catch(() => {
                lightfunnelsMatch({ code: params.get('code') })
                    .then(() => {
                        window.location.href = `${REACT_APP_URI}`;
                    })
                    .catch((err) => {
                        setErrorMessage(err?.response?.data?.errors?.message);
                        setErrorIntegration(true);
                    });
            });
    }, []);
    return errorIntegration ? (
        <div className="flex justify-center mt-10">
            <Alert color="failure" icon={HiInformationCircle}>
                <span>
                    <p>
                        <span className="font-medium">{errorMessage}</span>
                    </p>
                </span>
            </Alert>
        </div>
    ) : (
        <div className="flex justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
                    Redirecting to Logistio...
                </h2>
            </div>
        </div>
    );
};

export default Redirection;
