/* eslint-disable no-unused-vars */
import axios from 'utils/axios/axios';

import { IPaginatedStore, IStore } from 'utils/Interfaces/stores.interface';
import { getBearerToken } from '../utils/helpers/auth';

export interface Ipagination {
    page: number;
    limit: number;
}

export interface Ifilter {
    storeName?: string;
    storeType?: string;
}

export type PaginationAndFiltres = Ipagination & Ifilter;

export const addStore = async (data: any) => {
    const config = await getBearerToken();
    const response = await axios.post(`/stores/`, data, config);
    return response;
};
export const updateStore = async (id: any, data: any) => {
    const config = await getBearerToken();
    const response = await axios.put(`/stores/${id}`, data, config);
    return response;
};

export const getStores = async (pagination: Ipagination, filter: Ifilter) => {
    const config = await getBearerToken();
    const response = await axios.post<IPaginatedStore>(`/stores/filter`, { ...pagination, ...filter }, config);
    return response;
};

export const getStore = async (id: string) => {
    const config = await getBearerToken();
    const response = await axios.get<IStore>(`/stores/${id}`, config);
    return response;
};

export const getCurrentStore = async () => {
    const storeId = localStorage.getItem('storeId');
    if (storeId) {
        const store = (await getStore(storeId)).data;
        return store;
    }
    return null;
};
