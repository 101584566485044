import { Tooltip } from 'flowbite-react';
import React from 'react';
import { FcInfo } from 'react-icons/fc';

const InfoIcon: React.FC<{
    content: any;
    placement: 'top' | 'right' | 'bottom' | 'left';
    arrow?: boolean;
    className?: string;
    iconStyle?: string;
}> = ({ content, placement, arrow, className, iconStyle }) => {
    return (
        <>
            <Tooltip
                content={content}
                placement={placement}
                animation="duration-500"
                arrow={arrow}
                className={className}
                theme={{
                    target: 'w-fit',
                    animation: 'transition-opacity',
                    arrow: {
                        base: 'absolute z-10 h-2 w-2 rotate-45',
                        style: {
                            dark: 'bg-gray-900 dark:bg-gray-700',
                            light: 'bg-white',
                            auto: 'bg-white dark:bg-gray-700',
                        },
                        placement: '-4px',
                    },
                    base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm',
                    hidden: 'invisible opacity-0',
                    style: {
                        dark: 'bg-gray-900 text-white dark:bg-gray-700',
                        light: 'border border-gray-200 bg-green text-gray-900',
                        auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
                    },
                    content: 'relative z-20',
                }}
            >
                <FcInfo className={iconStyle} />
            </Tooltip>
        </>
    );
};

export default InfoIcon;
