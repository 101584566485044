import React, { useState } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { InputFieldProps } from './InputField.types';

const PasswordInputField: React.FC<InputFieldProps> = ({ onBlur, placeholder, value, name, onChange, disabled }) => {
    const [showMP, setShowMP] = useState(false);
    const showPassword = () => {
        setShowMP(!showMP);
    };
    return (
        <>
            <div className="relative w-full">
                <div className=" absolute top-1  p-1 right-3   flex items-center justify-center  rounded-full  dark:hover:bg-gray-700 hover:bg-gray-300 cursor-pointer">
                    {showMP ? (
                        <IoEyeOff className="w-6 h-6 " onClick={showPassword} />
                    ) : (
                        <IoEye className="w-6 h-6 " onClick={showPassword} />
                    )}
                </div>
                <input
                    className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-custom-background border-gray-300 text-gray-900  dark:border-gray-600  dark:text-white dark:placeholder-gray-400   p-2.5 text-sm pr-10 rounded-lg"
                    id="email4"
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    type={showMP ? 'text' : 'password'}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                />
            </div>
        </>
    );
};
export default PasswordInputField;
