import { TableColDef } from 'components/tables/tables.types';
import React from 'react';
import { IStock } from 'services/stock';
import Package from 'assets/package.png';
import { Button } from 'flowbite-react';
import { FaRegCopy } from 'react-icons/fa6';
import { copyToClickboard } from 'utils/copyToClickBoard';
import ShipmentDetails from './shipmentDetails/shipmentDetails';

/**
 *
 * ⚠️ Filtring is not handled by MUI
 * Filtrable fileds supported by the backend:
 * warehouse: string | containing the vnlin warehouse id
 * country: string | the country name associated to the available vnlin warehouses
 * product: string | product id
 *
 */

export const columns: TableColDef[] = [
    {
        field: 'country',
        headerName: 'Country',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{`${params?.country} `}</p>,
    },

    {
        field: 'product',
        headerName: 'Product Name',
        fieldType: 'render',
        renderCell: (params: IStock) => (
            <div className="flex items-center">
                <img
                    src={params?.parentProduct?.design?.[0] || params?.product?.design?.[0] || Package || ''}
                    alt={Package}
                    referrerPolicy="no-referrer"
                    style={{
                        width: 60,
                        height: 60,
                        borderRadius: 7,
                        paddingRight: 5,
                    }}
                />
                <p className="font-bold">{`${
                    params?.parentProduct?.restockFor?.name ||
                    params?.parentProduct?.name ||
                    params?.product?.name ||
                    ''
                } `}</p>
            </div>
        ),
    },
    {
        field: 'product',
        headerName: 'Variant / SKU',
        fieldType: 'render',
        renderCell: (params: IStock) =>
            params?.product?.itemKind === 'variant' ? (
                <div className="flex flex-col ">
                    <div>
                        <p className="font-bold text-gray-700 dark:text-gray-400">{params?.product?.name}</p>
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <p className="font-normal  text-gray-400 dark:text-gray-600  ">
                                {params?.product?.globalSKU}
                            </p>
                            <Button
                                size="l"
                                color="white"
                                title="Copy tracking code to clipboard"
                                onClick={() => {
                                    copyToClickboard(params?.product?.globalSKU || '', 'SKU');
                                }}
                            >
                                <FaRegCopy className="w-4 h-4" />
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex  items-center gap-2">
                        <p className="font-bold  ">{params?.product?.globalSKU}</p>
                        <Button
                            size="l"
                            color="white"
                            title="Copy tracking code to clipboard"
                            onClick={() => {
                                copyToClickboard(params?.product?.globalSKU || '', 'SKU');
                            }}
                        >
                            <FaRegCopy className="w-4 h-4" />
                        </Button>
                    </div>
                </div>
            ),
    },
    {
        field: 'quantity',
        headerName: 'Total Qty',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{` ${params?.quantity || 0} `}</p>,
    },

    {
        field: 'incomingQuantity',
        headerName: 'Incomming Qty',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{` ${params?.incomingQuantity || 0} `}</p>,
    },

    {
        field: 'availableQuantity',
        headerName: 'Available Qty',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{` ${params?.availableQuantity || 0} `}</p>,
    },

    {
        field: 'outboundQuantity',
        headerName: 'Outside Qty',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{` ${params?.outboundQuantity || 0} `}</p>,
    },

    {
        field: 'deliveredQuantity',
        headerName: 'Delivered Qty',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{` ${params?.deliveredQuantity || 0} `}</p>,
    },

    {
        field: 'packagedQuantity',
        headerName: 'Original Package Returns',
        fieldType: 'render',
        renderCell: (params: IStock) => <p className="font-bold">{` ${params?.packagedQuantity || 0} `}</p>,
    },

    {
        field: 'Details',
        headerName: '',
        fieldType: 'render',
        renderCell: (params) => (
            <>
                <ShipmentDetails data={params} />
            </>
        ),
    },
];
