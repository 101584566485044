import { styled } from '@mui/material/styles';

export const iconStyle = {
    color: 'primary.main',
    cursor: 'pointer',
};

export const dividerStyle = { height: '30px', marginLeft: '10px' };

export const inputStyle = { width: '100%', backgroundColor: 'inputBackground' };
export const CustomFileInput = styled('input')(() => {
    return {
        opacity: 0,
        width: '0.1px',
        height: ' 0.1px',
        cursor: 'pointer',
        fontSize: '12px',
        padding: '!0px important',
    };
});
export const CustomLabelInput = styled('label')(() => {
    return {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    };
});
export const ButtonStyling = {
    backgroundColor: 'transparent',
};
