import { useEffect, useMemo, useState } from 'react';

function useMediaQuery(query: string) {
    const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
    const [match, setMatch] = useState(mediaQuery.matches);

    useEffect(() => {
        const onChange = () => setMatch(mediaQuery.matches);
        mediaQuery.addEventListener('change', onChange);

        return () => mediaQuery.removeEventListener('change', onChange);
    }, [mediaQuery]);

    return match;
}

function useMediaQueries() {
    const maxSm = useMediaQuery('(max-width: 640px)');
    const sm = useMediaQuery('(min-width: 640px)');
    const md = useMediaQuery('(min-width: 768px)');
    const lg = useMediaQuery('(min-width: 1024px)');

    return { md, lg, sm, maxSm };
}

export default useMediaQueries;
