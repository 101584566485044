/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import InputField from 'components/inputField/InputField';
import BlanckMatchingCard from 'components/matchingProductCard/blanckMatchingCard';
import { Avatar, Badge, Button, Card } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import {
    CHECKED_MATCH,
    getLFProducts,
    LF_CHECKED_ORDER_BUMP_MATCH,
    LF_CHECKED_VARIANT_MATCH,
    LG_CHECKED_VARIANT_MATCH,
    matchLFProducts,
    TLFProduct,
    unlinkLFProducts,
    getProducts,
} from 'services/product';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { IProduct } from 'utils/Interfaces/products.interface';
import { HiCheck } from 'react-icons/hi2';
import toast from 'react-hot-toast';
import { BiUnlink } from 'react-icons/bi';
import ProductImage from '../../assets/product.svg';

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

const LightFunnelsMatching = () => {
    const [lgProducts, setLgProducts] = useState<IProduct[]>([]);
    const [lfProducts, setLfProducts] = useState<TLFProduct[]>([]);
    const [checkedMatches, setCheckedMatches] = useState<CHECKED_MATCH>({
        lgProduct: null,
        lfProduct: null,
        isLfVariant: null,
        isLfOrderBump: null,
        isLgVariant: null,
    });
    const getImgeDesin = (designArray: string[]) => {
        const imgList = designArray.filter(
            (prodImg) => prodImg.endsWith('.jpg') || prodImg.endsWith('.png') || prodImg.endsWith('.jpeg'),
        );
        return imgList;
    };
    // Lightfunnels products are filterd locally by name (we query all products and apply a filter on them)
    const [displayLfProducts, setDisplayLfProducts] = useState<TLFProduct[]>([]);
    // products filters by name
    const [lgProductNameFilter, setLgProductNameFilter] = useState('');
    const [lfProductNameFilter, setLfProductNameFilter] = useState('');
    const debouncedLgProductNameFilter = useDebouncedValue(lgProductNameFilter);
    const debouncedLfProductNameFilter = useDebouncedValue(lfProductNameFilter);

    // logistio products pagination
    const [lgProductsPagination, setLgProductsPagination] = useState({ page: 1, limit: 10 });
    const [lgProductsPaginationMeta, setLgProductsPaginationMeta] = useState<Partial<Imeta>>({});

    const paginateLgProducts = () => {
        // we simply increase the products count by page
        setLgProductsPagination((prev) => ({ ...prev, limit: prev.limit + 10 }));
    };

    useEffect(() => {
        // fetch lfproducts
        getLFProducts()
            .then((res) => {
                setLfProducts(res.data);
                setDisplayLfProducts(res.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    }, []);

    useEffect(() => {
        // fetch lgProducts
        const lgProductsFilter = debouncedLgProductNameFilter !== '' ? { name: debouncedLgProductNameFilter } : {};
        getProducts(lgProductsPagination, { includeReseller: true, itemKind: 'product', ...lgProductsFilter })
            .then((res) => {
                setLgProductsPaginationMeta({
                    hasNextPage: res.data.response.hasNextPage,
                    hasPrevPage: res.data.response.hasPrevPage,
                    limit: res.data.response.limit,
                    nextPage: res.data.response.nextPage,
                    page: res.data.response.page,
                    pagingCounter: res.data.response.pagingCounter,
                    prevPage: res.data.response.prevPage,
                    totalDocs: res.data.response.totalDocs,
                    totalPages: res.data.response.totalPages,
                });
                setLgProducts(res.data.response.docs);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    }, [debouncedLgProductNameFilter, lgProductsPagination.limit]);

    useEffect(() => {
        setDisplayLfProducts(() =>
            debouncedLfProductNameFilter !== ''
                ? lfProducts.filter((product) =>
                      product.title
                          .toLowerCase()
                          .replaceAll(' ', '')
                          .includes(debouncedLfProductNameFilter.toLowerCase().replaceAll(' ', '')),
                  )
                : lfProducts,
        );
    }, [debouncedLfProductNameFilter]);

    const handleCheckProduct = (
        event: React.ChangeEvent<HTMLInputElement>,
        type: 'lf' | 'lg',
        value: CHECKED_MATCH['lfProduct'] | CHECKED_MATCH['lgProduct'],
        isLgVariant: boolean | null,
        isLfVariant: boolean | null,
        isLfOrderBump: boolean | null,
    ) => {
        const newValue = event.target.checked ? value : null;
        switch (type) {
            case 'lf':
                setCheckedMatches((prev) => ({
                    ...prev,
                    lfProduct: newValue as CHECKED_MATCH['lfProduct'],
                    isLfVariant,
                    isLfOrderBump,
                }));
                break;
            case 'lg':
                setCheckedMatches((prev) => ({
                    ...prev,
                    lgProduct: newValue as CHECKED_MATCH['lgProduct'],
                    isLgVariant,
                }));
                break;

            default:
                // console.error('invalid type provided to match handler');
                break;
        }
    };
    const handleUnlinkProduct = (
        value: CHECKED_MATCH['lfProduct'],
        isLfVariant: boolean | null,
        isLfOrderBump: boolean | null,
    ) => {
        unlinkLFProducts({
            lfProduct: value,
            isLfVariant,
            isLfOrderBump,
        })
            .then(() => {
                toast.success('Products Unlinked successfully');
                getLFProducts()
                    .then((res) => {
                        setLfProducts(res.data);
                        setDisplayLfProducts(res.data);
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.errors?.message);
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };
    const handleConfirmation = () => {
        matchLFProducts(checkedMatches)
            .then(() => {
                toast.success('Products Matched successfully');
                getLFProducts()
                    .then((res) => {
                        setLfProducts(res.data);
                        setDisplayLfProducts(res.data);
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.errors?.message);
                        // setNotifType('error');
                        // setNotificationMSG(err);
                        // setOpenNotification(true);
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };

    const handleCancel = () => {
        setCheckedMatches({
            lgProduct: null,
            lfProduct: null,
            isLfVariant: null,
            isLfOrderBump: null,
            isLgVariant: null,
        });
    };
    const matchedProductCheck = (sku: string) => {
        let existance = false;
        // eslint-disable-next-line consistent-return
        lgProducts.forEach((prod) => {
            if (prod.globalSKU === sku) {
                existance = true;
                return true;
            }
            // eslint-disable-next-line consistent-return
            (prod.variants as IProduct[]).map((variantLg: IProduct) => {
                if (variantLg.globalSKU === sku) {
                    existance = true;

                    return true;
                }
            });
        });
        return existance;
    };
    return (
        <div>
            <div className="flex items-start justify-center dark:bg-[#0D1117]">
                {/* Logistio products listing */}
                <div className="h-[75vh] overflow-y-scroll p-1 pt-0 flex-grow relative">
                    <div className="border-t-[1px] border-b-[1px] border-gray-200 dark:border-gray-500 sticky top-0 z-10 bg-white dark:bg-gray-800 p-2 mb-2">
                        <p className="font-semibold">Sourced Products</p>
                        <InputField
                            placeholder="Search sourced products by name"
                            InputType="search"
                            onChange={(e) => {
                                setLgProductNameFilter(e.target.value);
                                if (lgProductsPagination.limit > 10) {
                                    // reset pagination
                                    setLgProductsPagination((prev) => ({ ...prev, limit: 10 }));
                                }
                            }}
                        />
                    </div>
                    {lgProducts.map((product) => {
                        const design = getImgeDesin(product.design);
                        if (product.variants.length === 0) {
                            return (
                                <Card key={`card5${product?._id}`}>
                                    <div className="flex gap-4 items-center justify-start" key={`div1${product?._id}`}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                !!checkedMatches.lgProduct &&
                                                (checkedMatches.lgProduct as IProduct)?._id === product._id
                                            }
                                            onChange={(e) => {
                                                handleCheckProduct(e, 'lg', product, false, null, false);
                                            }}
                                        />
                                        <Avatar img={design[0] || ProductImage} size="lg" />
                                        <p>{product.name}</p>
                                        <p>{product.globalSKU || 'missing sku!'}</p>
                                    </div>
                                </Card>
                            );
                        }
                        return (product?.variants as IProduct[]).map((variant) => (
                            <Card key={`card2${variant?._id}`}>
                                <div className="flex gap-4 items-center justify-start" key={`div2${variant?._id}`}>
                                    <input
                                        type="checkbox"
                                        checked={
                                            !!checkedMatches.lgProduct &&
                                            (checkedMatches.lgProduct as LG_CHECKED_VARIANT_MATCH).variant &&
                                            (checkedMatches.lgProduct as LG_CHECKED_VARIANT_MATCH).variant._id ===
                                                variant._id
                                        }
                                        onChange={(e) => {
                                            handleCheckProduct(
                                                e,
                                                'lg',
                                                {
                                                    product: {
                                                        name: product.name,
                                                        _id: product._id,
                                                        globalSKU: product.globalSKU,
                                                        design: product.design,
                                                    },
                                                    variant,
                                                },
                                                true,
                                                null,
                                                false,
                                            );
                                        }}
                                    />
                                    <Avatar img={design[0] || ProductImage} size="lg" />
                                    <p>
                                        {product?.name} - {variant?.name}
                                    </p>
                                    <p>{variant?.globalSKU || 'missing sku!!!!!'}</p>
                                </div>
                            </Card>
                        ));
                    })}
                    <Button
                        color="light"
                        className="w-full mt-1 mb-1"
                        disabled={!lgProductsPaginationMeta.hasNextPage}
                        onClick={paginateLgProducts}
                    >
                        Show more
                    </Button>
                </div>
                {/* Lightfunnels products */}
                <div className="h-[75vh] overflow-y-scroll p-1 pt-0 relative flex-grow">
                    <div className="border-t-[1px] border-b-[1px] border-gray-200 dark:border-gray-500 sticky top-0 z-10 bg-white dark:bg-gray-800 p-2 mb-2">
                        <p className="font-semibold">Lightfunels products</p>
                        <InputField
                            placeholder="Search lightfunnels products by name"
                            InputType="search"
                            onChange={(e) => {
                                setLfProductNameFilter(e.target.value);
                            }}
                        />
                    </div>

                    {displayLfProducts?.map((product) => {
                        if (product && (!product?.variants || product?.variants.length === 0)) {
                            return (
                                <>
                                    <Card key={`card3${product?._id}`}>
                                        <div
                                            className="flex gap-4 items-center justify-start"
                                            key={`div${product?._id}`}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={
                                                    !!checkedMatches.lfProduct &&
                                                    (checkedMatches.lfProduct as TLFProduct)?._id === product._id
                                                }
                                                onChange={(e) => {
                                                    handleCheckProduct(e, 'lf', product, null, false, false);
                                                }}
                                            />
                                            <Avatar img={product.thumbnail?.path || ProductImage} size="lg" />
                                            <p>{product.title}</p>
                                            {lgProducts.some(
                                                (prod) =>
                                                    prod.globalSKU === product.sku ||
                                                    (prod.variants as IProduct[]).some(
                                                        (variant) => variant.globalSKU === product.sku,
                                                    ),
                                            ) ? (
                                                <Badge icon={HiCheck}>Matched : {product.sku}</Badge>
                                            ) : null}

                                            {matchedProductCheck(product.sku) && (
                                                <Button
                                                    size="xs"
                                                    color="failure"
                                                    pill
                                                    onClick={() => {
                                                        handleUnlinkProduct(product, false, false);
                                                    }}
                                                >
                                                    <BiUnlink className="h-4 w-5" />
                                                </Button>
                                            )}
                                        </div>
                                    </Card>
                                    <Card key={`card4${product?.order_bump?.id}`}>
                                        <div
                                            className="flex gap-4 items-center justify-start"
                                            key={`div4${product?.order_bump?.id}`}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={
                                                    !!checkedMatches.lfProduct &&
                                                    !!checkedMatches.isLfOrderBump &&
                                                    (checkedMatches?.lfProduct as LF_CHECKED_ORDER_BUMP_MATCH)
                                                        ?.order_bump?.id === product?.order_bump?.id
                                                }
                                                onChange={(e) => {
                                                    handleCheckProduct(
                                                        e,
                                                        'lf',
                                                        {
                                                            product,
                                                            order_bump: product?.order_bump,
                                                        },
                                                        null,
                                                        false,
                                                        true,
                                                    );
                                                }}
                                            />
                                            <Avatar img={product?.order_bump?.image?.path || ProductImage} size="lg" />
                                            <p>
                                                {`${product.title} | ${(product?.order_bump.title as string).replaceAll(
                                                    ':',
                                                    ' - ',
                                                )}`}
                                            </p>
                                            {lgProducts.some(
                                                (prod) =>
                                                    prod.globalSKU === product?.order_bump?.sku ||
                                                    (prod.variants as IProduct[]).some(
                                                        (variant) => variant.globalSKU === product?.order_bump?.sku,
                                                    ),
                                            ) ? (
                                                <Badge icon={HiCheck}>Matched : {product?.order_bump?.sku}</Badge>
                                            ) : null}

                                            {matchedProductCheck(product?.order_bump?.sku) && (
                                                <Button
                                                    size="xs"
                                                    color="failure"
                                                    pill
                                                    onClick={() => {
                                                        handleUnlinkProduct(
                                                            {
                                                                product,
                                                                order_bump: product?.order_bump,
                                                            },
                                                            false,
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    <BiUnlink className="h-4 w-5" />
                                                </Button>
                                            )}
                                        </div>
                                    </Card>
                                </>
                            );
                        }

                        return (
                            <>
                                {product.variants.map((variant) => (
                                    <Card key={`card4${variant?._id}`}>
                                        <div
                                            className="flex gap-4 items-center justify-start"
                                            key={`div4${variant?._id}`}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={
                                                    !!checkedMatches.lfProduct &&
                                                    (checkedMatches?.lfProduct as LF_CHECKED_VARIANT_MATCH)?.variant
                                                        ?._id === variant._id
                                                }
                                                onChange={(e) => {
                                                    handleCheckProduct(
                                                        e,
                                                        'lf',
                                                        {
                                                            product,
                                                            variant,
                                                        },
                                                        null,
                                                        true,
                                                        false,
                                                    );
                                                }}
                                            />
                                            <Avatar img={variant?.image?.path || ProductImage} size="lg" />
                                            <p>{(variant.title as string).replaceAll(':', ' - ')}</p>
                                            {lgProducts.map((prod) => {
                                                if (prod.globalSKU === variant.sku) {
                                                    return <Badge icon={HiCheck}>Matched : {variant.sku}</Badge>;
                                                }
                                                // eslint-disable-next-line consistent-return
                                                return (prod?.variants as IProduct[]).map((variantLg: IProduct) => {
                                                    if (variantLg?.globalSKU === variant?.sku) {
                                                        return <Badge icon={HiCheck}>Matched : {variant.sku}</Badge>;
                                                    }
                                                });
                                            })}

                                            {matchedProductCheck(variant.sku) && (
                                                <Button
                                                    size="xs"
                                                    color="failure"
                                                    pill
                                                    onClick={() => {
                                                        handleUnlinkProduct(
                                                            {
                                                                product,
                                                                variant,
                                                            },
                                                            true,
                                                            false,
                                                        );
                                                    }}
                                                >
                                                    <BiUnlink className="h-4 w-5" />
                                                </Button>
                                            )}
                                        </div>
                                    </Card>
                                ))}
                                <Card key={`card4${product?.order_bump?.id}`}>
                                    <div
                                        className="flex gap-4 items-center justify-start"
                                        key={`div4${product?.order_bump?.id}`}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={
                                                !!checkedMatches.lfProduct &&
                                                (checkedMatches?.lfProduct as LF_CHECKED_ORDER_BUMP_MATCH)?.order_bump
                                                    ?.id === product?.order_bump?.id
                                            }
                                            onChange={(e) => {
                                                handleCheckProduct(
                                                    e,
                                                    'lf',
                                                    {
                                                        product,
                                                        order_bump: product?.order_bump,
                                                    },
                                                    null,
                                                    false,
                                                    true,
                                                );
                                            }}
                                        />
                                        <Avatar img={product?.order_bump?.image?.path || ProductImage} size="lg" />

                                        <p>
                                            {`${product.title} | ${(product?.order_bump.title as string).replaceAll(
                                                ':',
                                                ' - ',
                                            )}`}
                                        </p>
                                        {lgProducts.map((prod) => {
                                            if (prod.globalSKU === product?.order_bump.sku) {
                                                return (
                                                    <Badge icon={HiCheck}>Matched : {product?.order_bump.sku}</Badge>
                                                );
                                            }
                                            // eslint-disable-next-line consistent-return
                                            return (prod?.variants as IProduct[]).map((variantLg: IProduct) => {
                                                if (variantLg?.globalSKU === product?.order_bump?.sku) {
                                                    return (
                                                        <Badge icon={HiCheck}>
                                                            Matched : {product?.order_bump.sku}
                                                        </Badge>
                                                    );
                                                }
                                            });
                                        })}

                                        {matchedProductCheck(product?.order_bump?.sku) && (
                                            <Button
                                                size="xs"
                                                color="failure"
                                                pill
                                                onClick={() => {
                                                    handleUnlinkProduct(
                                                        {
                                                            product,
                                                            order_bump: product?.order_bump,
                                                        },
                                                        false,
                                                        true,
                                                    );
                                                }}
                                            >
                                                <BiUnlink className="h-4 w-5" />
                                            </Button>
                                        )}
                                    </div>
                                </Card>
                            </>
                        );
                    })}
                </div>
            </div>
            <div className="bg-gray-200 p-2 flex justify-center items-center gap-4 flex-wrap dark:bg-[#0D1117]">
                <div className="flex items-center justify-between gap-2 p-1 dark:bg-[#0D1117]">
                    {checkedMatches.lgProduct &&
                        (!checkedMatches.isLgVariant ? (
                            <Card key="card" className="w-80">
                                <div className="flex gap-4 items-center ">
                                    <Avatar
                                        img={(checkedMatches.lgProduct as IProduct)?.design[0] || ProductImage}
                                        size="lg"
                                    />
                                    <div className="flex flex-col">
                                        <p>{(checkedMatches.lgProduct as IProduct).name}</p>
                                        <p>{(checkedMatches.lgProduct as IProduct).globalSKU || 'missing sku!'}</p>
                                    </div>
                                </div>
                            </Card>
                        ) : (
                            <Card className="w-80">
                                <div className="flex gap-4 items-center ">
                                    <Avatar
                                        img={
                                            (checkedMatches.lgProduct as LG_CHECKED_VARIANT_MATCH).product.design[0] ||
                                            ProductImage
                                        }
                                        size="lg"
                                    />
                                    <div className="flex flex-col">
                                        <p>
                                            {(checkedMatches.lgProduct as LG_CHECKED_VARIANT_MATCH).product.name} -{' '}
                                            {(checkedMatches.lgProduct as LG_CHECKED_VARIANT_MATCH).variant.name}
                                        </p>
                                        <p>
                                            {(checkedMatches.lgProduct as LG_CHECKED_VARIANT_MATCH).variant.globalSKU ||
                                                'missing sku!'}
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    {!checkedMatches.lgProduct && <BlanckMatchingCard phrase="logistio products list" />}
                    {checkedMatches.lfProduct &&
                        (() => {
                            if (checkedMatches.isLfVariant) {
                                return (
                                    <Card className="w-80">
                                        <div className="flex gap-4 items-center   ">
                                            <Avatar
                                                img={
                                                    (checkedMatches.lfProduct as LF_CHECKED_VARIANT_MATCH).variant
                                                        ?.image?.path || ProductImage
                                                }
                                                size="lg"
                                            />
                                            <p>
                                                {(
                                                    checkedMatches?.lfProduct as LF_CHECKED_VARIANT_MATCH
                                                ).variant.title.replaceAll(':', ' - ')}
                                            </p>
                                        </div>
                                    </Card>
                                );
                            }
                            if (checkedMatches.isLfOrderBump) {
                                return (
                                    <Card className="w-80">
                                        <div className="flex gap-4 items-center  ">
                                            <Avatar
                                                img={
                                                    (checkedMatches.lfProduct as LF_CHECKED_ORDER_BUMP_MATCH)?.product
                                                        .thumbnail?.path || ProductImage
                                                }
                                                size="lg"
                                            />
                                            <p>
                                                {(
                                                    checkedMatches?.lfProduct as LF_CHECKED_ORDER_BUMP_MATCH
                                                ).order_bump.title.replaceAll(':', ' - ')}
                                            </p>
                                        </div>
                                    </Card>
                                );
                            }
                            return (
                                <Card className="w-80">
                                    <div className="flex gap-4 items-center  ">
                                        <Avatar
                                            img={
                                                (checkedMatches.lfProduct as TLFProduct)?.thumbnail?.path ||
                                                ProductImage
                                            }
                                            size="lg"
                                        />
                                        <p>{(checkedMatches.lfProduct as TLFProduct)?.title}</p>
                                    </div>
                                </Card>
                            );
                        })()}
                    {!checkedMatches.lfProduct && <BlanckMatchingCard phrase="lightfunnels products list" />}
                </div>
                <div className="flex items-center justify-center gap-3 p-1">
                    <Button color="failure" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmation}
                        disabled={Object.values(checkedMatches).some((value) => value === null)}
                    >
                        Confirm match
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LightFunnelsMatching;
