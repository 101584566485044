import { IOrder } from 'services/order';
import { IStock } from 'services/stock';
import { PiAirplaneTiltFill, PiAirplaneTiltLight } from 'react-icons/pi';

import { FaCircleArrowLeft } from 'react-icons/fa6';
import { MdOutlineDone } from 'react-icons/md';
import { GoArrowUp, GoDash } from 'react-icons/go';

import { BiBadge } from 'react-icons/bi';
import { trackOrders } from 'services/publicServices/track';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';

export function getOrderStatusLabel(status: IOrder['status']) {
    switch (status) {
        case 1:
            return 'New';
        case 2:
            return 'Canceled by call cente';
        case 21:
            return 'Wrong number';
        case 3:
            return 'Client unreachable by call center';
        case 4:
            return 'Confirmed by call center';
        case 41:
            return 'Pending answer';
        case 42:
            return 'Question answered';
        case 43:
            return 'Scheduled call';
        case 5:
            return 'Waiting for shipment';
        case 6:
            return 'Out for shipment';
        case 7:
            return 'Shipped';
        case 8:
            return 'Pick and pack';
        case 9:
            return 'Out for delivery';
        case 10:
            return 'Delivery attempted';
        case 101:
            return 'Order returned to Hub';
        case 102:
            return 'Order returned to Warehouse';
        case 103:
            return 'New delivery request';
        case 104:
            return 'Wait to WMS';
        case 105:
            return 'Delivery scheduled';
        case 11:
            return 'Delivery failed';
        case 12:
            return 'Delivered';
        case 13:
            return 'Expired';
        case 99:
            return 'Check required';
        case 114:
            return 'Back to stock';
        case 100:
            return 'Hold - No stock available';
        default:
            return new Error(`Invalid status. Found ${status}`);
    }
}

export function getStockStatusLabel(status: IStock['status']) {
    switch (status) {
        case 1:
            return 'Preparing stock';
        case 2:
            return 'Ready for pickup';
        case 3:
            return 'Shipping';
        case 30:
            return 'Stock received by carrier';
        case 31:
            return 'Sent to GuangZhou Airport';
        case 32:
            return 'Instation Scan GZ';
        case 33:
            return 'Arrived in loading warehouse';
        case 34:
            return 'Sent To HongKong';
        case 35:
            return 'Instation Scan HK';
        case 36:
            return 'Departed on Flight';
        case 37:
            return 'Arrived destination Airport';
        case 38:
            return 'Customs clearance start';
        case 39:
            return 'Customs Released_Import';
        case 40:
            return 'Arrive to headquarters warehouse';
        case 4:
            return 'Delivered';
        default:
            return new Error(`Invalid status. Found ${status}`);
    }
}

export const trackingFilters = [
    { label: 'All', value: '' },
    {
        label: 'Not Found',
        value: 'notFound',
        icon: GoDash,
        color: 'dark:text-gray-950 dark:bg-white bg-[#42516B] text-white rounded-full',
    },
    { label: 'In Transit', value: 'inTransit', icon: PiAirplaneTiltFill, color: 'text-purple-500' },
    { label: 'Pick Up', value: 'pickUp', icon: GoArrowUp, color: 'text-white bg-yellow-300 rounded-full' },
    { label: 'Delivered', value: 'delivered', icon: MdOutlineDone, color: 'text-white rounded-full bg-green-500' },
    { label: 'Returned', value: 'returned', icon: FaCircleArrowLeft, color: 'text-blue-500' },
];

export const getIconByStatus = (status: string) => {
    switch (status) {
        case 'Delivered':
            return { icon: MdOutlineDone, color: 'text-white bg-green-500 rounded-full h-12 w-12' };

        case 'Sent to GuangZhou Airport':
        case 'Instation Scan GZ':
        case 'Arrived in loading warehouse':
        case 'Departed on Flight':
        case 'Arrived destination Airport':
            return { icon: PiAirplaneTiltLight, color: 'text-white bg-purple-500 rounded-full h-12 w-12' };

        case 'Pick and pack':
            return { icon: GoArrowUp, color: 'text-white bg-yellow-300 rounded-full h-12 w-12' };

        case 'Back to stock':
            return { icon: FaCircleArrowLeft, color: ' bg-white text-blue-500 rounded-full h-12 w-12' };

        default:
            return { icon: BiBadge, color: 'text-white bg-green-500 rounded-full h-12 w-12' };
    }
};

export const countValues = (field: string, data: Awaited<ReturnType<typeof trackOrders>>['data']) => {
    switch (field) {
        case 'notFound':
            return Object.values(data)?.filter((elt) => elt?.length === 0)?.length || 0;
        case 'inTransit':
            return (
                Object.values(data)
                    ?.map((elt) => elt[0])
                    ?.filter(
                        (elt) =>
                            elt?.type === 'stock_shipment' &&
                            [
                                'Sent to GuangZhou Airport',
                                'Instation Scan GZ',
                                'Arrived in loading warehouse',
                                'Departed on Flight',
                                'Arrived destination Airport',
                            ].includes(elt?.status),
                    )?.length || 0
            );
        case 'pickUp':
            return (
                Object.values(data)
                    ?.map((elt) => elt[0])
                    ?.filter((elt) => elt?.type === 'order_shipment' && elt?.status === 'Pick and pack')?.length || 0
            );
        case 'delivered':
            return (
                Object.values(data)
                    ?.map((elt) => elt[0])
                    ?.filter((elt) => elt?.status === 'Delivered')?.length || 0
            );
        case 'returned':
            return (
                Object.values(data)
                    ?.map((elt) => elt[0])
                    ?.filter((elt) => elt?.status === 'Back to stock' && elt?.type === 'order_shipment')?.length || 0
            );
        default:
            return Object.keys(data)?.length;
    }
};

export const displayDifferenceBetweenTraces = (lastTraceTime: string, firstTraceTime: string) => {
    if (differenceInDays(new Date(lastTraceTime), new Date(firstTraceTime)) > 0) {
        return ` (${differenceInDays(new Date(lastTraceTime), new Date(firstTraceTime))} Days)`;
    }

    if (differenceInHours(new Date(lastTraceTime), new Date(firstTraceTime)) > 0) {
        return ` (${differenceInHours(new Date(lastTraceTime), new Date(firstTraceTime))} Hours)`;
    }

    if (differenceInMinutes(new Date(lastTraceTime), new Date(firstTraceTime)) > 0) {
        return ` (${differenceInMinutes(new Date(lastTraceTime), new Date(firstTraceTime))} Minutes)`;
    }

    return null;
};
