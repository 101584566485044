import React from 'react';
import AccountInformation from './AccountInformation';

const EmaiPart: React.FC<{
    customerId: string;
    customerEmail: string;
}> = ({ customerEmail, customerId }) => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            <div className="">
                <p className=" text-xl font-medium">Change email</p>
                <p className="text-base dark:text-gray-500 text-gray-600 ">
                    Update your email address for continued updates and notifications.
                </p>
            </div>
            <div className="col-span-2 flex flex-col gap-10">
                <div>
                    <AccountInformation id={customerId} email={customerEmail} />
                </div>
            </div>
        </div>
    );
};

export default EmaiPart;
