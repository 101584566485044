import { Box, Typography } from '@mui/material/';
import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageDisplay } from 'utils/helpers/imageFinder';
import Tablesku from 'components/listSku/TableSku';
import { IoIosBarcode } from 'react-icons/io';
import Status from '../status/Status';
import { MessageStyleHeader } from './MessageHeader.styles';
import { MessageHeaderProps } from './MessageHeader.types';

const MessageHeader: React.FC<MessageHeaderProps> = ({
    productImage,
    productTitle,
    sku,
    price,
    status,
    type,
    productId,
    customerId,
    productInfo,
}) => {
    const navigate = useNavigate();
    const failedLoadingSkuTxt = 'Unable to show skus! Try reloading the page.';
    const loadingSkuTxt = 'Loading skus...';
    const [skuText, setSkuText] = useState(loadingSkuTxt);

    useEffect(() => {
        if (sku !== '' && sku !== undefined) {
            setSkuText(sku);
        }
        const skuTxtTimeout = setTimeout(() => {
            if (sku === '' || sku === undefined) {
                setSkuText(failedLoadingSkuTxt);
            }
        }, 1000);
        return () => {
            clearTimeout(skuTxtTimeout);
        };
    }, [sku]);

    const [open, setOpen] = useState(false);

    const displaySkus = () => {
        const singleSKuRegex = /^LS-\d+$/;

        if (singleSKuRegex.test(skuText)) {
            return (
                <Typography color={skuText === failedLoadingSkuTxt ? 'red' : undefined} variant="body2">
                    SKU: {skuText}
                </Typography>
            );
        }
        if (skuText.startsWith('[')) {
            return (
                <>
                    <Modal
                        dismissible
                        show={open}
                        popup
                        onClose={() => setOpen(false)}
                        theme={{
                            content: {
                                base: 'w-fit',
                                inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] w-fit',
                            },
                        }}
                    >
                        {' '}
                        <Modal.Header className="p-4 pb-0">Product Skus - {productTitle || '--'}</Modal.Header>
                        <Modal.Body className=" flex !p-4 ">
                            <Tablesku product={productInfo || null} />
                        </Modal.Body>
                    </Modal>

                    <Button color="gray" size="small" onClick={() => setOpen(true)} className="p-1">
                        <IoIosBarcode />
                    </Button>
                </>
            );
        }
        return <div>{skuText}</div>;
    };

    const goToProduct = () => {
        navigate('/product-settings', {
            state: {
                tabId: 0,
                productId,
                customerId,
                productStatus: status,
                // currency: customerCurrency,
            },
        });
    };

    return (
        <Box sx={MessageStyleHeader}>
            <div className="flex justify-center gap-3 w-full">
                <div>{imageDisplay(productImage, true, 70, 70, undefined)}</div>

                <div className="w-full">
                    <div className="flex justify-between gap-3 w-full flex-wrap mb-1">
                        <button
                            type="button"
                            onClick={goToProduct}
                            title={productTitle}
                            className="hover:text-blue-500"
                        >
                            <p className="truncate max-w-96 font-normal text-base font-popin ">{productTitle}</p>
                        </button>
                        <Status label={status} type={type} />
                    </div>

                    {displaySkus()}
                    {price && <Typography variant="body2">Price Sample:{price}</Typography>}
                </div>
            </div>
        </Box>
    );
};

export default MessageHeader;
