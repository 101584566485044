import { ResellerStock } from 'pages/sellers/reseller.types';
import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';

export interface Ipagination {
    page: number;
    limit: number;
    type?: number;
}

export interface Ifilter {
    name?: string;
    shippingTo?: [string];
    status?: 1 | 2 | 3 | 4;
    openForSale?: boolean;
    categories?: [string];
    priceDisplay?: 'lowToHigh' | 'highToLow';
}

export const getResellerStock = async (resellerProductId: string) => {
    const config = await getBearerToken();
    const resellerStock = await axios.get<ResellerStock[]>(
        `/reseller/stocks/getForProduct/${resellerProductId}`,
        config,
    );
    return resellerStock;
};
