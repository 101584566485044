/* eslint-disable no-unused-vars */
import React from 'react';
import CustomButton from 'components/customButton/CustomButton';
import InputField from 'components/inputField/InputField';
import { Formik, Form } from 'formik';
import { loginSchema } from 'utils/validations/loginValidation';
import CustomError from 'components/customError/CustomError';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { login } from 'services/auth';
import { getCurrentUser } from 'utils/helpers/auth';
import toast from 'react-hot-toast';
import { LinkStyle, RememberMeStyle, registerStyle } from './Login.styles';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { state }: any = useLocation();

    return (
        <div className="flex flex-col justify-center items-center h-screen ">
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={(values) => {
                    login(values.email, values.password)
                        .then((res) => {
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('refreshToken', res.data.refreshToken);

                            localStorage.setItem('user', JSON.stringify(getCurrentUser()));
                            toast.success('Successfully Logged in');
                        })
                        .then(() => {
                            setTimeout(() => {
                                if (state?.previousLink) {
                                    // navigate(state?.previousLink);
                                    window.location.href = state?.previousLink;
                                    return;
                                }
                                navigate('/dashboard');
                            });
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.errors?.message);
                        });
                }}
                validationSchema={loginSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="w-[300px] md:w-[400px]">
                            <InputField
                                InputType="email"
                                label="Email"
                                placeholder="jhondoe@email.com"
                                // returns onChange, onBlur and value related to this input field
                                {...formik.getFieldProps('email')}
                                name="email"
                            />
                            <CustomError name="email" component="Box" />

                            <InputField
                                placeholder="Your password here"
                                label="Password"
                                InputType="password"
                                // returns onChange, onBlur and value related to this input field
                                {...formik.getFieldProps('password')}
                                name="password"
                            />
                            <CustomError name="password" component="Box" />
                            <Box sx={{ mb: 1 }}>
                                <Box sx={RememberMeStyle}>
                                    <Checkbox defaultChecked />
                                    <Typography variant="body1">Remember me</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    <Link to="/auth/reset-password" style={LinkStyle}>
                                        <Typography variant="body2">Forgot password?</Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </div>
                        <CustomButton
                            type="submit"
                            className="btn"
                            styleType="primary"
                            label="Login"
                            sizeType="large"
                        />
                        <Link to="/auth/signup" style={registerStyle}>
                            <Typography
                                variant="body2"
                                sx={{
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                Don&#x27;t have an account yet? Register now!
                            </Typography>
                        </Link>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Login;
