/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */

import CustomError from 'components/customError/CustomError';
import { Avatar, Button, Spinner } from 'flowbite-react';
import { Form, Formik } from 'formik';

import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { getCustomer, updateCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { profilePicture } from 'utils/validations/personalInformationValidation';

const ProfilePicture: React.FC<{
    id: string;
    profImg: string;
    setCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>;
}> = ({ id, profImg, setCustomer }) => {
    const [verifImage, setVerifImage] = useState<any>([]);
    const [urlImage, setUrlImage] = useState('');
    const [imgLoading, setImgLoading] = useState(false);

    const updatePicture = (values: any) => {
        setImgLoading(true);
        updateCustomer(id, values)
            .then((res) => {
                setUrlImage(res.data.customer.profileImage);
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        firstName: res.data.customer.firstName,
                        lastName: res.data.customer.lastName,
                        phone: res.data.customer.phone,
                        profileImage: res.data.customer.profileImage,
                    }),
                );
                getCustomer(id).then((result) => {
                    setCustomer(result.data);
                });
                toast.success('Profile Picture updated Successfully');
                setImgLoading(false);
                setUrlImage('');
            })
            // .then(updateUserToken)
            .catch(() => {
                toast.error('Something went wrong while updating your profile picture');
            });
    };

    return (
        <>
            <Formik
                initialValues={{
                    profileImage: '',
                }}
                onSubmit={(values, onSubmitProps) => {
                    const formData = new FormData();

                    if (verifImage && verifImage.file) {
                        formData.append(verifImage.name, verifImage.file, verifImage.targetKey);
                    }

                    updatePicture(formData);

                    onSubmitProps.setSubmitting(false);
                    // onSubmitProps.resetForm();
                }}
                validationSchema={profilePicture}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} className=" flex gap-5 items-center">
                        <div>
                            <div>
                                <input
                                    type="file"
                                    id="upload"
                                    hidden
                                    accept="image/*"
                                    name="profileImage"
                                    onChange={(e) => {
                                        const file = e.target.files && e.target.files[0];
                                        if (file) {
                                            formik.setFieldValue('profileImage', file);
                                            const reader = new FileReader();
                                            setUrlImage(URL.createObjectURL(file));
                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                            reader.onload = () => {
                                                setVerifImage({
                                                    file,
                                                    name: file.name,
                                                    url: URL.createObjectURL(file),
                                                    targetKey: 'profileImage',
                                                });
                                            };
                                            reader.readAsDataURL(file);
                                            // const formData = new FormData();
                                            // formData.append('profileImage', URL.createObjectURL(file), 'profileImage');
                                        }
                                    }}
                                />
                                <label htmlFor="upload" className="cursor-pointer">
                                    <Avatar
                                        // rounded
                                        // placeholderInitials="aze aze"
                                        img={urlImage && urlImage.length > 0 ? urlImage : profImg}
                                        size="xl"
                                        color="light"
                                    />
                                </label>
                            </div>
                            <CustomError name="profileImage" />
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <Button
                                color="light"
                                className="w-full max-w-48"
                                type="submit"
                                disabled={imgLoading || formik.isSubmitting || urlImage.length === 0}
                            >
                                {imgLoading ? (
                                    <>
                                        <Spinner aria-label="uploading" className="mr-1" /> Uploading
                                    </>
                                ) : (
                                    'Upload Photo'
                                )}
                            </Button>
                            <p className=" text-sm  font-normal dark:text-gray-500 text-gray-600 ">
                                ACCEPTES JPG, JPEG, SVG or PNG.
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ProfilePicture;
