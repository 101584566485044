import React from 'react';
import ProductImage from 'components/productImage/ProductImage';
import { Card } from 'flowbite-react';
import ShoppingBag from '../../assets/shoppingbag.png';

const BlanckMatchingCard: React.FC<{ phrase: string }> = ({ phrase }) => {
    return (
        <Card
            className="w-80"
            theme={{
                root: {
                    base: 'flex rounded-lg border border-[#cca023] bg-white shadow-md dark:border-[#ffedba]  dark:bg-gray-800',
                },
            }}
        >
            <div className="flex items-center gap-4">
                <ProductImage src={ShoppingBag} />
                <p className="text-[#cca023] font-mono font-semibold">Please select a Product from your {phrase}</p>
            </div>
        </Card>
    );
};

export default BlanckMatchingCard;
