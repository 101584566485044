import React from 'react';

const SharedProductLoader = () => {
    return (
        <>
            <div className="animate-pulse ">
                <div className="flex flex-col gap-10">
                    <div>
                        <div className="flex items-end justify-between">
                            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mb-4" />
                            <div>
                                <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-28 mb-4" />
                            </div>
                        </div>
                        <div className="relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                                <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 sr-only ">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-end justify-between">
                            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mb-4" />
                            <div>
                                <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-28 mb-4" />
                            </div>
                        </div>
                        <div className="relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                                <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 sr-only ">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-end justify-between">
                            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mb-4" />
                            <div>
                                <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-28 mb-4" />
                            </div>
                        </div>
                        <div className="relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                                <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 sr-only ">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-end justify-between">
                            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mb-4" />
                            <div>
                                <div className="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-28 mb-4" />
                            </div>
                        </div>
                        <div className="relative overflow-x-auto shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                                <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 sr-only ">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                    <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="px-6 py-4 flex gap-2">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="px-6 py-4">
                                            {' '}
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SharedProductLoader;
