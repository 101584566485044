/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import CustomButton from 'components/customButton/CustomButton';
import React from 'react';
import { Badge } from 'flowbite-react';
import {
    sourcingContainerButton,
    sourcingContainerStyle,
    sourcingContainerWrapper,
    sourcingContainrDescription,
    sourcingContainrTitle,
} from './SourcingContainer.styles';
import { SourcingContainerProps } from './SourcingContainer.types';

const SourcingContainer: React.FC<SourcingContainerProps> = ({
    title,
    icon,
    description,
    buttonLabel,
    onClick,
    disabled,
}) => {
    return (
        <Box sx={sourcingContainerWrapper}>
            {disabled && (
                <Box sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                    <Badge color="success">Coming Soon</Badge>
                </Box>
            )}

            <Box sx={sourcingContainerStyle}>
                <div className="w-24 h-24 flex justify-center items-center">
                    <img src={icon} alt="" width="80px" />
                </div>
                {title && <Box sx={sourcingContainrTitle}>{title}</Box>}
                {description && <Box sx={sourcingContainrDescription}>{description}</Box>}
            </Box>
            <Box sx={sourcingContainerButton(disabled)}>
                <CustomButton
                    label={buttonLabel}
                    sizeType="large"
                    styleType={disabled ? 'disabled' : 'primary'}
                    onClick={onClick}
                    disabled={disabled}
                />
            </Box>
        </Box>
    );
};

export default SourcingContainer;
