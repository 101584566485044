import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import StoreDrawer from 'components/storeDrawer/StoreDrawer';
import { updateStore } from 'services/stores';
import CustomRadio from 'components/customRadio/CustomRadio';
import CustomButton from 'components/customButton/CustomButton';
import toast from 'react-hot-toast';
import AccountAvatar from '../accountAvatar/AccountAvatar';
import {
    buttonStyle,
    configItem,
    configWrapper,
    editStyle,
    storeListHeader,
    StoreListItemStyle,
} from './StoreListItem.styles';
import { StoreListItemProps } from './StoreListItem.types';

const StoreListItem: React.FC<StoreListItemProps> = ({ store, setStores, funnels }) => {
    const [open, setOpen] = useState(false);
    const [openConfig] = useState(false);

    const [fulfillmentProcessing, setFulfillmentProcessing] = useState('manual');
    const [riskyOrderProcessing, setRiskyOrderProcessing] = useState('no');

    const [partiallyFulfilledProcessing, setPartiallyFulfilledProcessing] = useState('manual');
    const [deliveryLevels, setDeliveryLevels] = useState('super fast');

    useEffect(() => {
        setFulfillmentProcessing(store.fulfillmentProcessing);
        if (store.riskyOrderProcessing === true) {
            setRiskyOrderProcessing('yes');
        } else {
            setRiskyOrderProcessing('no');
        }
        setPartiallyFulfilledProcessing(store.partiallyFulfilledProcessing);
        setDeliveryLevels(store.deliveryLevels);
    }, []);

    const updateCurrentStore = () => {
        updateStore(store.id, {
            fulfillmentProcessing,
            riskyOrderProcessing,
            partiallyFulfilledProcessing,
            deliveryLevels,
        })
            .then(() => {
                toast.success('Successfully Updated');
            })
            .then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(() => {
                toast.error('Something Went Wrong');
            });
    };
    return (
        <Box>
            <StoreDrawer
                open={open}
                setOpen={setOpen}
                submitFunction="update"
                // setOpenNotif={setOpenNotif}
                // setNotifMessage={setNotifMessage}
                // setNotifType={setNotifType}
                store={store}
                setStores={setStores}
                funnels={funnels}
            />
            <Box sx={StoreListItemStyle}>
                <Box sx={storeListHeader}>
                    <AccountAvatar shopName={store.storeName} description={store.storeType} />
                    <Box
                        onClick={() => {
                            setOpen(true);
                        }}
                        sx={editStyle}
                    >
                        Edit
                    </Box>
                    {/* ⌛ ⌛ Store Gear ( Settings) : This section was made for prepaid stores ( not cod) . 
                        This section will be hidden for now uncomment the code below when needed ⌛ ⌛ */}
                    {/* {openConfig === false ? (
                        <SettingsIcon sx={iconStyle} onClick={() => setOpenConfig(true)} />
                    ) : (
                        <CloseIcon sx={iconStyle} onClick={() => setOpenConfig(false)} />
                    )} */}
                </Box>
                {openConfig && (
                    <Box sx={configWrapper}>
                        <Box sx={configItem}>
                            <Box>
                                <CustomRadio
                                    label="Fulfillment processing"
                                    values={[
                                        { label: 'Manual', value: 'manual' },
                                        { label: 'Automatic', value: 'automatic' },
                                    ]}
                                    configValue={fulfillmentProcessing}
                                    setConfigValue={setFulfillmentProcessing}
                                />
                            </Box>
                            <Box>
                                <CustomRadio
                                    label="Partially fulfilled processing"
                                    values={[
                                        { label: 'Yes', value: 'yes' },
                                        { label: 'No', value: 'no' },
                                    ]}
                                    configValue={riskyOrderProcessing}
                                    setConfigValue={setRiskyOrderProcessing}
                                />
                            </Box>
                        </Box>
                        <Box sx={configItem}>
                            <Box>
                                <CustomRadio
                                    label="Risky order processing"
                                    values={[
                                        { label: 'Manually Process Risky Orders', value: 'manual' },
                                        { label: 'Automatically Process Risky Orders', value: 'automatic' },
                                    ]}
                                    configValue={partiallyFulfilledProcessing}
                                    setConfigValue={setPartiallyFulfilledProcessing}
                                />
                            </Box>
                        </Box>
                        <Box sx={buttonStyle}>
                            <CustomButton
                                sizeType="large"
                                styleType="primary"
                                label="Apply to this store"
                                onClick={updateCurrentStore}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default StoreListItem;
