/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { utils, write, writeFile } from 'xlsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import ExcelJS from 'exceljs';
import moment from 'moment';
import { statusDisplay } from 'pages/orders/OrderElements';
import { IOrder } from 'services/order';
import { getCurrentStore } from 'services/stores';
import { IProduct } from 'utils/Interfaces/products.interface';

// remove the `_id` and the store id from the order object
const getCleanData = (orders: any[]) => {
    const data = orders.map((el) => {
        const order = el;
        // eslint-disable-next-line no-underscore-dangle
        delete order?.__v;
        delete order?._id;
        delete order?.callCenterAttempt;
        delete order?.unreachableCount;
        delete order?.callCenterFeedback;
        // delete order?.items;
        delete order?.updatedAt;
        delete order?.fixedDeliveryDate;
        delete order?.logistioId;
        delete order?.callAgent;
        delete order?.actionReason;
        delete order?.lastUnreachableTimestamp;
        delete order?.store;
        order.customer = `${order?.customer.firstName!} ${order?.customer.lastName!}`; // TODO: Maybe we need to replace this with firstname and lastname
        order.product = order?.items[0]?.product?.name;
        order.refrenceLink = order.items[0].product.refrenceLink;
        order.quantity = order.items[0].quantity || order.items[0].upsellQuantity;
        order.status = statusDisplay[order?.status]?.statusLabel || '--';
        order.logistioId = order._id; // giving the customer meaningful header for the id field
        return order;
    });
    return data;
};

export default function exoprtOrders(data: any[]) {
    const orders = getCleanData(data);
    const worksheet = utils.json_to_sheet(orders);
    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'logistio-orders');
    write(workbook, { bookType: 'xlsx', type: 'binary' });
    writeFile(workbook, 'logistio-order.xlsx');
}
// const toDataURL = (url: string): Promise<any> => {
//     const promise = new Promise((resolve) => {
//         const xhr = new XMLHttpRequest();
//         xhr.onload = () => {
//             const reader = new FileReader();
//             reader.readAsDataURL(xhr.response);
//             reader.onloadend = () => {
//                 resolve({ base64Url: reader.result });
//             };
//         };
//         xhr.open('GET', url);
//         // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
//         xhr.responseType = 'blob';
//         xhr.send();
//     });

//     return promise;
// };

export const exportExcelFile = async (data: IOrder[]) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('My Sheet');
    sheet.getRow(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };

    sheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e1e3e3' },
    };

    sheet.getRow(1).font = {
        name: 'Poppins Black',
        size: 12,
    };
    sheet.getRow(1).height = 25;
    sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    const store = await getCurrentStore();
    const columns = [
        {
            header: 'Reference',
            key: 'orderRef',
            width: 20,
        },
        {
            header: 'Date',
            key: 'date',
            width: 20,
        },
        {
            header: 'Client Name',
            key: 'client',
            width: 20,
        },
        { header: 'Product', key: 'product', width: 32 },
        // { header: 'Variant', key: 'variant', width: 32 },
        { header: 'SKU', key: 'sku', width: 32 },
        {
            header: 'Quantity',
            key: 'quantity',
            width: 15,
        },

        { header: 'Store', key: 'store', width: 20 },
        {
            header: 'Type',
            key: 'type',
            width: 32,
        },
        {
            header: 'Country',
            key: 'country',
            width: 20,
        },
        {
            header: 'Status',
            key: 'status',
            width: 20,
        },
        {
            header: 'Total price',
            key: 'totalPrice',
            width: 15,
        },
        {
            header: 'Currency',
            key: 'currency',
            width: 15,
        },
        {
            header: 'Tracking Code',
            key: 'trackingCode',
            width: 15,
        },
    ];

    if (store?.withCC) {
        columns.push({
            header: 'Call Attempts',
            key: 'callCenter',
            width: 15,
        });
    }
    sheet.columns = columns;
    let startRow = 1;
    const promise = Promise.all(
        data?.map(async (order) => {
            const rows = {};
            Object.assign(rows, {
                client: `${order.firstName || '--'} ${order.lastName || '--'}`,
                store: `${store?.storeName || '--'}`,
                date: `${moment(order?.createdAt).format('DD/MM/YYYY HH:mm')}`,
                type: `${
                    !order?.cod ? 'Prepaid' : order?.withCC ? 'COD with call center' : 'COD without call center '
                }`,
                // callCenter: `${order?.withCC ? 'Yes' : 'No'}`,
                status: statusDisplay[order?.status].statusLabel,
                orderRef: `${order?.orderRef}`,
                country: `${order?.country}`,
                totalPrice: order?.totalPrice || '--',
                currency: `${order?.currency ? order?.currency : '--'}`,
                trackingCode: `${order?.trackingCode || '--'}`,
            });
            if (store?.withCC) {
                Object.assign(rows, { callCenter: `${order?.callCenterAttempt || '--'}` });
            }
            if (order?.items && order?.items?.length > 0) {
                order.items.forEach((item) => {
                    // const variant = item?.product as IProduct;
                    const product = item?.product as IProduct;

                    Object.assign(rows, {
                        product: `${product?.parentProduct?.name || ''} ${product?.name || '--'}`,
                        sku: `${product?.globalSKU || '--'}`,
                        quantity: item?.quantity || item?.upsellQuantity || '--',
                    });
                    sheet.addRow(rows);
                });
                sheet.mergeCells(startRow + 1, 1, startRow + order?.items.length, 1);
                sheet.mergeCells(startRow + 1, 2, startRow + order?.items.length, 2);
                sheet.mergeCells(startRow + 1, 3, startRow + order?.items.length, 3);
                // sheet.mergeCells(startRow + 1, 4, startRow + order?.items.length, 4);
                // sheet.mergeCells(startRow + 1, 5, startRow + order?.items.length, 5);
                sheet.mergeCells(startRow + 1, 7, startRow + order?.items.length, 7);
                sheet.mergeCells(startRow + 1, 8, startRow + order?.items.length, 8);
                sheet.mergeCells(startRow + 1, 9, startRow + order?.items.length, 9);
                sheet.mergeCells(startRow + 1, 10, startRow + order?.items.length, 10);
                sheet.mergeCells(startRow + 1, 11, startRow + order?.items.length, 11);
                sheet.mergeCells(startRow + 1, 12, startRow + order?.items.length, 12);
                sheet.mergeCells(startRow + 1, 13, startRow + order?.items.length, 13);
                sheet.mergeCells(startRow + 1, 14, startRow + order?.items.length, 14);

                startRow += order?.items.length;
            } else {
                startRow += 1;
                Object.assign(rows, {
                    product: `${(order?.product as IProduct)?.name || '--'}`,
                    quantity: (order?.product as IProduct)?.quantity || '--',
                    sku: `${(order?.product as IProduct)?.globalSKU || '--'}`,
                });
                sheet.addRow(rows);
            }
        }),
    );

    promise.then(() => {
        const priceCol = sheet.getColumn(5);
        const rowsLength = sheet.rowCount;
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < rowsLength; i++) {
            sheet.getRow(i + 1).height = 30;
            sheet.getRow(i + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getRow(i + 1).font = {
                name: 'Poppins Black',
                family: 4,
                size: 10,
            };
        }
        // iterate over all current cells in this column
        priceCol.eachCell((cell) => {
            const cellValue = Number(sheet.getCell(cell?.address).value);
            if (cellValue > 50 && cellValue < 1000) {
                sheet.getCell(cell?.address).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF0000' },
                };
            }
        });

        workbook.xlsx.writeBuffer().then((dataBlob) => {
            const blob = new Blob([dataBlob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Logistio-Orders.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    });
};
