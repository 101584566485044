import React from 'react';
import Box from '@mui/material/Box';
import { getStatus } from 'utils/helpers/product';
import { StatusProps } from './Status.types';
import { StatusContainer, StatusBall } from './Status.styles';

const Status: React.FC<StatusProps> = ({ label, type }) => {
    return (
        <Box sx={StatusContainer}>
            <Box sx={StatusBall(label)} />
            {getStatus(Number(label), type)}
        </Box>
    );
};

export default Status;
