import GeneralButton from 'components/customButton/generalButton';
import CustomError from 'components/customError/CustomError';
import { Label, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { updateCustomer } from 'services/customer';
import { changePersonalInfos } from 'utils/validations/personalInformationValidation';
import { UserProps } from '../../../profileSettings/ProfileSettings.types';
import './phoneInput.css';

const customTheme = {
    field: {
        input: {
            base: 'border-gray-300 w-full  dark:border-slate-600',
            colors: {
                general: 'bg-custom-background',
            },
        },
    },
};

const PersonalInformation: React.FC<UserProps> = ({ firstName, lastName, phone, id }) => {
    const [profileLoader, setProfileLoader] = useState(false);

    const personalInfo = (values: any) => {
        setProfileLoader(true);
        updateCustomer(id, values)
            .then((res) => {
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        firstName: res.data.customer.firstName,
                        lastName: res.data.customer.lastName,
                        phone: res.data.customer.phone,
                        profileImage: res.data.customer.profileImage,
                    }),
                );
                toast.success('Personal Information updated successfully');
                setProfileLoader(false);
            })
            .catch(() => {
                toast.error('Something went wrong updating your personal information');
                setProfileLoader(false);
            });
    };

    return (
        <div>
            <Formik
                initialValues={{
                    firstName,
                    lastName,
                    phone,
                }}
                onSubmit={(values) => {
                    personalInfo(values);
                }}
                validationSchema={changePersonalInfos}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
                        <div className="grid grid-cols-1 gap-5 lg:gap-10 lg:grid-cols-2">
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="firstName" value="First name" className="text-lg font-medium" />
                                <TextInput
                                    theme={customTheme}
                                    color="general"
                                    id="firstNAme"
                                    type="text"
                                    placeholder="Joe"
                                    sizing="md"
                                    {...formik.getFieldProps('firstName')}
                                    name="firstName"
                                    helperText={
                                        <>
                                            <CustomError name="firstName" component="div" />
                                        </>
                                    }
                                />
                            </div>
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="lastName" value="Last name" className="text-lg font-medium" />
                                <TextInput
                                    theme={customTheme}
                                    color="general"
                                    id="lastName"
                                    type="text"
                                    placeholder="Joe"
                                    sizing="md"
                                    {...formik.getFieldProps('lastName')}
                                    name="lastName"
                                    helperText={
                                        <>
                                            <CustomError name="lastName" component="div" />
                                        </>
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-col gap-3">
                                <Label htmlFor="phoneNumber" value="Phone number" className="text-lg font-medium" />
                                <PhoneInput
                                    id="phoneNumber"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={(value) => formik.setFieldValue('phone', value)}
                                    onBlur={formik.handleBlur('phone')}
                                    placeholder="Enter phone number"
                                    className="mb-1"
                                />

                                <CustomError name="phone" component="div" />
                            </div>
                        </div>

                        <div className="flex justify-end items-center">
                            <GeneralButton
                                color="blue"
                                type="submit"
                                disabled={profileLoader}
                                isProcessing={profileLoader}
                            >
                                Save Changes
                            </GeneralButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PersonalInformation;
