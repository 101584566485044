import { Badge } from 'flowbite-react';
import React from 'react';
import { IInvoice } from 'utils/Interfaces/invoice.interface';
import { IStausInterface, TableColDef } from '../../../components/tables/tables.types';
import PrintInvoice from './invoicePrint';

export const columns: TableColDef[] = [
    {
        field: 'store',
        headerName: 'Store',
        fieldType: 'combo',
        child: ['storeName'],
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },
    {
        field: 'generationTiming',
        headerName: 'Timing',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'Action',
        headerName: 'View',
        fieldType: 'Action',
        actionType: 'PrepaidInvoice',
    },
    {
        field: 'invoiceLink',
        headerName: 'Print',
        fieldType: 'render',
        renderCell: (params: IInvoice) => (
            <>
                <PrintInvoice invoice={params} />
            </>
        ),
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
};
