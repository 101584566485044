import { Badge } from 'flowbite-react';
import React from 'react';
import { IInvoice } from 'utils/Interfaces/invoice.interface';
import { IStausInterface, TableColDef } from '../../../components/tables/tables.types';
import PrintInvoice from './invoicePrint';

export const timingDisplay: { [keys: string]: string } = {
    Daily: 'Daily',
    TwiceAWeek: 'Twice a week',
    Weekly: 'Weekly',
};

export const columns: TableColDef[] = [
    {
        field: 'store',
        headerName: 'Store',
        fieldType: 'combo',
        child: ['storeName'],
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },

    {
        field: 'generationTiming',
        headerName: 'Timing',
        fieldType: 'render',
        renderCell: (params: IInvoice) => <>{timingDisplay[params.generationTiming]}</>,
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'Action',
        headerName: 'View',
        fieldType: 'Action',
        actionType: 'Invoice',
    },
    {
        field: 'invoiceLink',
        headerName: 'Print',
        fieldType: 'render',
        renderCell: (params: IInvoice) => (
            <>
                <PrintInvoice invoice={params} />
            </>
        ),
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending
            </Badge>
        ),
        statusLabel: 'Treating',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
    3: {
        statusIcon: (
            <Badge color="pink" size="sm">
                Postponed
            </Badge>
        ),
        statusLabel: 'Postponed ',
    },
};
export const paymentStatus: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
    2: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Unpaid
            </Badge>
        ),
        statusLabel: 'Unpaid',
    },
};
