import React from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { IInvoice } from 'utils/Interfaces/invoice.interface';
import { downloadInvoice } from 'utils/invoice/printInvoice';

const PrintInvoice: React.FC<{ invoice: IInvoice }> = ({ invoice }) => {
    return (
        <>
            <div>
                <AiFillPrinter className="w-5 h-5 cursor-pointer" onClick={() => downloadInvoice(invoice)} />
            </div>
        </>
    );
};

export default PrintInvoice;
