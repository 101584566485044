// export const AvatarContainer = { display: 'flex', alignItems: 'center' };
// export const labelStyle = { fontSize: '14px', color: 'textColor.primary' };
// export const mediumAvatarStyle = {
//     bgcolor: 'grey',
//     marginRight: '5px',
//     fontSize: '20px',
//     width: '70px',
//     height: '70px',
// };

// export const LargeAvatarStyle = {
//     bgcolor: 'grey',
//     marginRight: '5px',
//     width: '118px',
//     height: '118px',
//     fontSize: '29px',
// };

export const identityStyle = {
    width: '400px',
    height: '250px',
    borderRadius: '8px',
};

export const AvatarContainer = {};
export const labelStyle = { fontSize: '14px', color: 'textColor.primary', marginLeft: '7px' };

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const AvatarStyle = (name: any, imge: string) => {
    return {
        bgcolor: imge && imge.length > 0 ? 'transparent' : stringToColor(name),
        marginRight: '0px',
        fontSize: '14px',
        width: '35px',
        height: '35px',
    };
};

export const mediumAvatarStyle = (name: any, imge: string) => {
    return {
        bgcolor: imge && imge.length > 0 ? 'transparent' : stringToColor(name),
        // marginRight: '-1px',
        fontSize: '20px',
        width: '70px',
        height: '70px',
    };
};
export const LargeAvatarStyle = (name: any, imge: string) => {
    return {
        bgcolor: imge && imge.length > 0 ? 'transparent' : stringToColor(name),
        // marginRight: '5px',
        width: '118px',
        height: '118px',
        fontSize: '29px',
    };
};
