import { Button } from 'flowbite-react';
import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { BtnProps } from './CustomButton.types';

const GeneralButton: React.FC<BtnProps> = ({ children, color, size, ...rest }) => {
    return (
        <>
            <Button
                color={color}
                processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
                size={size ?? 'sm'}
                {...rest}
                theme={{
                    base: 'group flex items-stretch items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none',
                    fullSized: 'w-full',
                    color: {
                        dark: 'text-white bg-gray-800 border border-transparent enabled:hover:bg-gray-900   dark:bg-gray-800 dark:enabled:hover:bg-gray-700  dark:border-gray-700',
                        failure:
                            'text-white bg-red-700 border border-transparent enabled:hover:bg-red-800   dark:bg-red-600 dark:enabled:hover:bg-red-700 ',
                        gray: 'text-gray-900 bg-white border border-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700  focus:text-cyan-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700 ',
                        info: 'text-white bg-cyan-700 border border-transparent enabled:hover:bg-cyan-800   dark:bg-cyan-600 dark:enabled:hover:bg-cyan-700 0',
                        light: 'text-gray-900 bg-white border border-gray-300 enabled:hover:bg-gray-100   dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-gray-700',
                        purple: 'text-white bg-purple-700 border border-transparent enabled:hover:bg-purple-800   dark:bg-purple-600 dark:enabled:hover:bg-purple-700 ',
                        success:
                            'text-white bg-green-700 border border-transparent enabled:hover:bg-green-800   dark:bg-green-600 dark:enabled:hover:bg-green-700 ',
                        warning: 'text-white bg-yellow-400 border border-transparent enabled:hover:bg-yellow-500  ',
                        blue: 'text-white bg-blue-700 border border-transparent enabled:hover:bg-blue-800   dark:bg-blue-600 dark:hover:bg-blue-700 ',
                        cyan: 'text-cyan-900 bg-white border border-cyan-300 enabled:hover:bg-cyan-100   dark:bg-cyan-600 dark:text-white dark:border-cyan-600 dark:enabled:hover:bg-cyan-700 dark:enabled:hover:border-cyan-700 ',
                        green: 'text-green-900 bg-white border border-green-300 enabled:hover:bg-green-100   dark:bg-green-600 dark:text-white dark:border-green-600 dark:enabled:hover:bg-green-700 dark:enabled:hover:border-green-700 ',
                        indigo: 'text-indigo-900 bg-white border border-indigo-300 enabled:hover:bg-indigo-100   dark:bg-indigo-600 dark:text-white dark:border-indigo-600 dark:enabled:hover:bg-indigo-700 dark:enabled:hover:border-indigo-700 ',
                        lime: 'text-lime-900 bg-white border border-lime-300 enabled:hover:bg-lime-100   dark:bg-lime-600 dark:text-white dark:border-lime-600 dark:enabled:hover:bg-lime-700 dark:enabled:hover:border-lime-700 ',
                        pink: 'text-pink-900 bg-white border border-pink-300 enabled:hover:bg-pink-100  dark:bg-pink-600 dark:text-white dark:border-pink-600 dark:enabled:hover:bg-pink-700 dark:enabled:hover:border-pink-700 ',
                        red: 'text-red-900 bg-white border border-red-300 enabled:hover:bg-red-100  dark:bg-red-600 dark:text-white dark:border-red-600 dark:enabled:hover:bg-red-700 dark:enabled:hover:border-red-700 ',
                        teal: 'text-teal-900 bg-white border border-teal-300 enabled:hover:bg-teal-100   dark:bg-teal-600 dark:text-white dark:border-teal-600 dark:enabled:hover:bg-teal-700 dark:enabled:hover:border-teal-700 ',
                        yellow: 'text-yellow-900 bg-white border border-yellow-300 enabled:hover:bg-yellow-100   dark:bg-yellow-600 dark:text-white dark:border-yellow-600 dark:enabled:hover:bg-yellow-700 dark:enabled:hover:border-yellow-700 ',
                    },
                    disabled: 'cursor-not-allowed opacity-50',
                    isProcessing: 'cursor-wait',
                    spinnerSlot: 'absolute h-full top-0 flex items-center animate-fade-in',
                    spinnerLeftPosition: {
                        xs: 'left-2',
                        sm: 'left-3',
                        md: 'left-4',
                        lg: 'left-5',
                        xl: 'left-6',
                    },
                    gradient: {
                        cyan: 'text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 enabled:hover:bg-gradient-to-br  focus:ring-cyan-300 dark:focus:ring-cyan-800',
                        failure:
                            'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 enabled:hover:bg-gradient-to-br  focus:ring-red-300 dark:focus:ring-red-800',
                        info: 'text-white bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 enabled:hover:bg-gradient-to-br  focus:ring-cyan-300 dark:focus:ring-cyan-800 ',
                        lime: 'text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 enabled:hover:bg-gradient-to-br  focus:ring-lime-300 dark:focus:ring-lime-800',
                        pink: 'text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 enabled:hover:bg-gradient-to-br  focus:ring-pink-300 dark:focus:ring-pink-800',
                        purple: 'text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 enabled:hover:bg-gradient-to-br  focus:ring-purple-300 dark:focus:ring-purple-800',
                        success:
                            'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 enabled:hover:bg-gradient-to-br  focus:ring-green-300 dark:focus:ring-green-800',
                        teal: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 enabled:hover:bg-gradient-to-br  focus:ring-teal-300 dark:focus:ring-teal-800',
                    },
                    gradientDuoTone: {
                        cyanToBlue:
                            'text-white bg-gradient-to-r from-cyan-500 to-cyan-500 enabled:hover:bg-gradient-to-bl  focus:ring-cyan-300 dark:focus:ring-cyan-800',
                        greenToBlue:
                            'text-white bg-gradient-to-br from-green-400 to-cyan-600 enabled:hover:bg-gradient-to-bl  focus:ring-green-200 dark:focus:ring-green-800',
                        pinkToOrange:
                            'text-white bg-gradient-to-br from-pink-500 to-orange-400 enabled:hover:bg-gradient-to-bl  focus:ring-pink-200 dark:focus:ring-pink-800',
                        purpleToBlue:
                            'text-white bg-gradient-to-br from-purple-600 to-cyan-500 enabled:hover:bg-gradient-to-bl  focus:ring-cyan-300 dark:focus:ring-cyan-800',
                        purpleToPink:
                            'text-white bg-gradient-to-r from-purple-500 to-pink-500 enabled:hover:bg-gradient-to-l  focus:ring-purple-200 dark:focus:ring-purple-800',
                        redToYellow:
                            'text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 enabled:hover:bg-gradient-to-bl  focus:ring-red-100 dark:focus:ring-red-400',
                        tealToLime:
                            'text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 enabled:hover:bg-gradient-to-l enabled:hover:from-teal-200 enabled:hover:to-lime-200 enabled:hover:text-gray-900  focus:ring-lime-200 dark:focus:ring-teal-700',
                    },
                    inner: {
                        base: 'flex items-stretch items-center transition-all duration-200',
                        position: {
                            none: '',
                            start: 'rounded-r-none',
                            middle: 'rounded-none',
                            end: 'rounded-l-none',
                        },
                        outline: 'border border-transparent',
                        isProcessingPadding: {
                            xs: 'pl-8',
                            sm: 'pl-10',
                            md: 'pl-12',
                            lg: 'pl-16',
                            xl: 'pl-20',
                        },
                    },
                    label: 'ml-2 inline-flex h-4 w-4 items-center justify-center rounded-full bg-cyan-200 text-xs font-semibold text-cyan-800',
                    outline: {
                        color: {
                            gray: 'border border-gray-900 dark:border-white',
                            default: 'border-0',
                            light: '',
                        },
                        off: '',
                        on: 'flex justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white w-full',
                        pill: {
                            off: 'rounded-md',
                            on: 'rounded-full',
                        },
                    },
                    pill: {
                        off: 'rounded-lg',
                        on: 'rounded-full',
                    },
                    size: {
                        xs: 'text-xs px-2 py-1',
                        sm: 'text-sm px-3 py-1.5',
                        md: 'text-sm px-4 py-2',
                        lg: 'text-base px-5 py-2.5',
                        xl: 'text-base px-6 py-3',
                    },
                }}
            >
                {children}
            </Button>
        </>
    );
};

export default GeneralButton;
