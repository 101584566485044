import React from 'react';
// import Typography from '@mui/material/Typography';
import Chat from 'pages/chat/Chat';
// import EditOrder from './EditOrder';

const OrderDetails: React.FC = () => {
    return (
        <div className="w-full">
            <Chat />
        </div>
    );
};

export default OrderDetails;
