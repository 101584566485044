import GeneralButton from 'components/customButton/generalButton';
import { Badge, Dropdown, Modal } from 'flowbite-react';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaHistory, FaRegTrashAlt } from 'react-icons/fa';
import { HiChat, HiDotsVertical, HiPencil } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoMdPaper } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { IOrder, deleteOrder, getHistory, getOrders } from 'services/order';
import { getOrderTraces } from 'services/orderTrace';
import EditOrder from './details/EditOrder';
import OrderDataDetails from './orderDataDetails/orderDataDetails';
import StatusTimeline from './orderStatusTimeline';
import { ActionsProps, IStatusTimeline } from './orders.types';

const OrdersTableActions: React.FC<ActionsProps> = ({ orderId, data, setRows, currentPage, currentFilter }) => {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigateChat = () => {
        navigate('/orders/chat', {
            state: {
                tabId: 0,
                orderId,
                data,
            },
        });
    };

    const [selectedOrder, setSelectedOrder] = useState<IOrder>();
    const [openhistory, setOpenHistory] = useState(false);
    const [history, setHistory] = useState<IStatusTimeline>({ history: [], deliveryAttempts: [] });
    const handleOrderHistory = () => {
        getHistory(orderId)
            .then((orderData) => {
                getOrderTraces({ order: orderId, logistioStatus: 10 })
                    .then((dataTraces) => {
                        setHistory({ history: orderData?.data, deliveryAttempts: dataTraces?.data.docs });

                        setSelectedOrder(data);
                        setOpenHistory(true);
                        setAnchorEl(null);
                    })
                    .catch(() => {});
            })
            .catch(() => {
                setOpenHistory(false);
                toast.error('Something went wrong when fetching order history');
            });
    };

    const refetchOrders = (toastId: string) => {
        const storeId = localStorage.getItem('storeId');
        if (!storeId) {
            toast.error('Unable to revalidate the orders list. Unable to determine current store.');
            return;
        }
        if (!selectedOrder) {
            toast.error('Unable to revalidate the orders list. No order is selected.');
            return;
        }
        getOrders(
            { page: currentPage, limit: 10 },
            {
                ...currentFilter,
                store: storeId,
                cod: selectedOrder.cod,
                prePaid: selectedOrder.prePaid,
            },
        )
            .then((result) => {
                setRows(result.data.docs);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message, {
                    id: toastId,
                });
            });
    };

    const [openAllData, setOpenAllData] = useState(false);
    const handleAllData = () => {
        setSelectedOrder(data);
        setOpenAllData(true);
        setAnchorEl(null);
    };

    const [openEditOrder, setOpenEditOrder] = useState(false);
    const handleOrderUpdate = () => {
        setSelectedOrder(data);
        setOpenEditOrder(true);
        setAnchorEl(null);
    };

    const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
    const handleDeleteOrder = () => {
        setSelectedOrder(data);
        setOpenDeleteOrder(true);
        setAnchorEl(null);
    };

    const navigationList = [
        {
            label: 'Update order',
            action: handleOrderUpdate,
            actionIcon: HiPencil,
        },
        {
            label: 'History',
            action: handleOrderHistory,
            actionIcon: FaHistory,
        },
        {
            label: 'Show all data',
            action: handleAllData,
            actionIcon: IoMdPaper,
        },
        {
            label: 'Chat',
            action: navigateChat,
            actionIcon: HiChat,
        },
        {
            label: 'Delete order',
            action: handleDeleteOrder,
            actionIcon: FaRegTrashAlt,
        },
    ] as const;

    return (
        <>
            <Modal dismissible show={openDeleteOrder} popup onClose={() => setOpenDeleteOrder(false)}>
                <Modal.Body>
                    {selectedOrder &&
                        ((selectedOrder.cod && [1, 99, 100].includes(selectedOrder.status)) ||
                            (selectedOrder?.prePaid && [99, 100].includes(selectedOrder.status))) &&
                        selectedOrder.callAgent === undefined && (
                            <div className="text-center py-3 flex flex-col items-center justify-center gap-4">
                                <h3>Delete order</h3>
                                <div className="mx-auto flex items-center justify-center bg-red-300 w-fit p-2 rounded-[999px]">
                                    <RiDeleteBin6Line className="text-red-600 h-7 w-7" />
                                </div>
                                <p className="text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this order? <br />
                                    By continue the order will not be treated.
                                </p>
                                <div className="flex justify-center gap-1">
                                    <p className="font-bold">ORDER REF:</p>
                                    <Badge className="w-fit">{selectedOrder.orderRef || '--'}</Badge>
                                </div>
                                <div className="flex justify-center gap-4">
                                    <GeneralButton
                                        color="failure"
                                        onClick={() => {
                                            const toastId = toast.loading('Deleting order...');
                                            // eslint-disable-next-line no-underscore-dangle
                                            deleteOrder(selectedOrder._id)
                                                .then(() => {
                                                    toast.success('Order deleted successfully', { id: toastId });
                                                    refetchOrders(toastId);
                                                })
                                                .catch((error) => {
                                                    toast.error(
                                                        error?.response?.data?.errors?.message ||
                                                            'Unable to delete order something went wrong',
                                                        {
                                                            id: toastId,
                                                        },
                                                    );
                                                })
                                                .finally(() => {
                                                    setOpenDeleteOrder(false);
                                                });
                                        }}
                                    >
                                        Yes, delete
                                    </GeneralButton>
                                    <GeneralButton
                                        color="gray"
                                        onClick={() => {
                                            setOpenDeleteOrder(false);
                                        }}
                                    >
                                        No, cancel
                                    </GeneralButton>
                                </div>
                            </div>
                        )}
                </Modal.Body>
            </Modal>
            <Modal
                dismissible
                show={openhistory}
                popup
                onClose={() => setOpenHistory(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Order Status History</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {selectedOrder && (
                        <StatusTimeline setOpen={setOpenHistory} history={history} order={selectedOrder} />
                    )}
                </Modal.Body>
            </Modal>
            <Modal
                dismissible
                show={openAllData}
                size="6xl"
                popup
                onClose={() => setOpenAllData(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Order details</Modal.Header>
                <Modal.Body className=" overflow-y-auto max-h-[34rem]">
                    <OrderDataDetails data={data} />
                </Modal.Body>
            </Modal>
            <Modal
                dismissible
                show={openEditOrder}
                popup
                onClose={() => setOpenEditOrder(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Edit Order</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto">
                    {selectedOrder && (
                        <EditOrder
                            data={selectedOrder}
                            setRows={setRows}
                            currentPage={currentPage}
                            currentFilter={currentFilter}
                        />
                    )}
                </Modal.Body>
            </Modal>
            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {navigationList.map((navigation) => {
                    /*
                     *  NOTE: display the delete action only if
                     * the order is not assigned to a call agent
                     * and one of the following cases is valid:
                     *   - order's store with cc:
                     *      - COD and order status is:
                     *        1: new
                     *        99: check required
                     *        100: hold no stock
                     *   - order's store w/out cc:
                     *      - Prepaid or COD and the order status is:
                     *        99: check required
                     *        100: hold no stock
                     * */
                    if (navigation.label !== 'Delete order') {
                        return (
                            <Dropdown.Item
                                icon={navigation.actionIcon}
                                onClick={navigation.action}
                                key={navigation.label}
                            >
                                {navigation.label}
                            </Dropdown.Item>
                        );
                    }
                    if (
                        (data.callAgent === undefined &&
                            data.withCC &&
                            data.cod &&
                            [1, 99, 100].includes(data.status)) ||
                        (!data.withCC && [99, 100].includes(data.status))
                    ) {
                        return (
                            <Dropdown.Item
                                icon={navigation.actionIcon}
                                onClick={navigation.action}
                                key={navigation.label}
                            >
                                {navigation.label}
                            </Dropdown.Item>
                        );
                    }
                    return null;
                })}
            </Dropdown>
        </>
    );
};

export default OrdersTableActions;
