import React from 'react';

import { Alert } from '@mui/material';
import { ErrorMessage } from 'formik';

import { ErrorProps } from './CustomError.types';

const CustomError: React.FC<ErrorProps> = ({ name, component }) => {
    return (
        <>
            <ErrorMessage name={name} component={component} render={(msg) => <Alert severity="error">{msg}</Alert>} />
        </>
    );
};

export default CustomError;
