import { useRef, useEffect } from 'react';

function DynamicTitle(name: any, prevailOnUnmount = false) {
    const defaultTitle = useRef('Logistio');

    useEffect(() => {
        document.title = `${name} | Logistio`;
    }, [name]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        [],
    );
}
export default DynamicTitle;
