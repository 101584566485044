import AvatarMenu from 'components/avatarMenu/avatarMenu';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUser, isAuthenticated } from 'utils/helpers/auth';
import { LuUser2 } from 'react-icons/lu';
import ThemeSwitch from 'components/Switch/themeSwitch';
import { ReactComponent as LogistioLogo } from '../../assets/LogistioLogo.svg';

const TrackingPageNavbar = () => {
    const isAuth = isAuthenticated();
    const { pathname } = useLocation();

    const getNameIfIsAuth = () => {
        let authName;
        if (isAuth) {
            const getName = `${getUser().firstName} ${getUser().lastName}`;
            const name = getName.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
            authName = name;
        } else authName = '';

        return authName;
    };
    return (
        <>
            <nav
                className={`bg-[${
                    pathname === '/tracking/tracking-results' ? '#E8EBEF' : '#E4EFFF'
                }] dark:bg-[#000246] w-full  h-fit`}
            >
                <div className=" flex flex-wrap items-center justify-between mx-auto py-2 px-4 sm:py-4 sm:px-8">
                    <Link to="/tracking">
                        <LogistioLogo
                            width={90}
                            className="dark:fill-[#FFF] lg:w-[81px] lg:h-[26px] sm:w-[81px] sm:h-[26px]"
                        />
                    </Link>
                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        <div className="flex items-center gap-6">
                            <ThemeSwitch />
                            {isAuth ? (
                                <AvatarMenu name={getNameIfIsAuth()} />
                            ) : (
                                <Link to="/auth/login">
                                    <div className="text-white bg-[#007FFF] focus:outline-none font-medium rounded-md text-sm  px-2 py-2 sm:px-4 sm:py-2 text-center sm:w-[128px] sm:h-[38px] ">
                                        <LuUser2 size={20} className="sm:hidden" />
                                        <span className="hidden sm:block">Login</span>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default TrackingPageNavbar;
