/* eslint-disable no-underscore-dangle */
import IntegrationBox from 'components/integrationBox/integrationBox';
import React, { useEffect, useState } from 'react';

import { getCustomer } from 'services/customer';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import { getCurrentUser } from 'utils/helpers/auth';
import ClickFunnel from '../../assets/clickFunnel.png';

const IntegrationPage = () => {
    const CLIENT_ID = process.env.REACT_APP_LF_CLIENT_ID;
    const [customer, setCustomer] = useState<ICustomer>();
    const usr = getCurrentUser();
    useEffect(() => {
        getCustomer(usr?._id).then((res: any) => {
            setCustomer(res.data);
        });
    }, []);
    return (
        <>
            <div className="flex flex-wrap gap-4">
                <IntegrationBox
                    title="Lightfunnels"
                    img={ClickFunnel}
                    imgAlt="clickFunnel image"
                    link={`https://app.lightfunnels.com/admin/apps/${CLIENT_ID}`}
                    customer={customer}
                />
            </div>
        </>
    );
};

export default IntegrationPage;
