import React from 'react';
import useMediaQueries from 'utils/hooks/useMediaQuery';
import { getIconByStatus } from './statusMatching';

interface StatusIconProps {
    status: string;
}
const StatusIcon = ({ status }: StatusIconProps) => {
    const { icon: Icon, color } = getIconByStatus(status);
    const mediaQueries = useMediaQueries();
    return (
        <div>
            <Icon className={color} size={mediaQueries.lg ? 30 : 24} />
        </div>
    );
};

export default StatusIcon;
