import NotFound from 'components/404NotFound/notFound';
import ApiLayout from 'layouts/apiLayout';
import AuthLayout from 'layouts/authLayout';
import Layout from 'layouts/layout';
import TrackingLayout from 'layouts/trackingLayout';
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import PrivateRoute from 'utils/helpers/ProtectedRoute';
import PublicRoute from 'utils/helpers/PublicRoute';
import { v4 as uuidv4 } from 'uuid';

const App: React.FC = () => {
    const getRoutes = (definedRoutes: any, routePath: any) => {
        return definedRoutes.map((prop: any) => {
            if (prop.layout === routePath) {
                if (prop.children) {
                    return prop.children.map((child: any) => {
                        return (
                            <Route
                                // key={`${child.layout}${child.path}`}
                                key={uuidv4()}
                                path={child.layout + child.path}
                                element={<child.element />}
                            />
                        );
                    });
                }
                if (prop.routes) {
                    return (
                        <Route
                            key={uuidv4()}
                            path={prop.layout + prop.path}
                            element={
                                <prop.element>
                                    <Outlet />
                                </prop.element>
                            }
                        >
                            {prop.routes.map((property: any, index: number) => {
                                if (
                                    property.path === '/all' ||
                                    property.path === '/profile-settings' ||
                                    property.path === '/allOrders'
                                )
                                    return (
                                        <Route
                                            index
                                            element={<property.element />}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                        />
                                    );
                                return (
                                    <Route
                                        path={property.layout + property.path}
                                        element={<property.element />}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                    />
                                );
                            })}
                        </Route>
                    );
                }
                return <Route path={prop.layout + prop.path} element={<prop.element />} key={uuidv4()} />;
            }
            return null;
        });
    };
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route element={<PublicRoute />}>
                        <Route path="/auth" element={<AuthLayout />}>
                            {getRoutes(routes, '/auth')}
                        </Route>
                    </Route>
                    <Route>
                        <Route path="/tracking" element={<TrackingLayout />}>
                            {getRoutes(routes, '/tracking')}
                        </Route>
                    </Route>
                    <Route element={<PrivateRoute />} key={uuidv4()}>
                        <Route path="/" element={<Layout />}>
                            {getRoutes(routes, '/')}
                        </Route>
                    </Route>
                    <Route>
                        <Route path="/integrations" element={<ApiLayout />}>
                            {getRoutes(routes, '/integrations')}
                        </Route>
                    </Route>
                    <Route element={<PrivateRoute redirectionLink={window.location.href} />}>
                        <Route path="/redirects" element={<ApiLayout />}>
                            {getRoutes(routes, '/redirects')}
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
