import React, { useState } from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

const TrackingAccordion: React.FC<{ title: any; content: any }> = ({ title, content }) => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <div
            className={`rounded-xl mx-4 my-2 p-1 border border-[#DDE0E7] dark:border-0 ${
                accordionOpen ? 'bg-[#E4EFFF] dark:bg-[#020323] ' : 'bg-white dark:bg-[#020438]'
            }`}
        >
            <button
                type="button"
                onClick={() => setAccordionOpen(!accordionOpen)}
                className={`p-1 flex justify-between w-full items-center '}`}
            >
                <div className="w-full">{title}</div>
                {accordionOpen ? (
                    <MdKeyboardArrowUp className="text-[#76849D] dark:text-white" />
                ) : (
                    <MdKeyboardArrowDown className="text-[#76849D] dark:text-white" />
                )}
            </button>
            <div
                className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
                    accordionOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                }`}
            >
                <div className="overflow-hidden ">
                    <div className="p-1">{content}</div>
                </div>
            </div>
        </div>
    );
};

export default TrackingAccordion;
