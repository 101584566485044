export const logistioCountries = [
    {
        code: 'AE',
        label: 'United Arab Emirates',
        arabicLabel: 'الإمارات العربية المتحدة',
        currency: 'AED',
        phone: '971',
    },
    { code: 'KW', label: 'Kuwait', arabicLabel: 'الكويت', currency: 'KWD', phone: '965' },
    { code: 'SA', label: 'Saudi Arabia', arabicLabel: 'المملكة العربية السعودية', currency: 'SAR', phone: '966' },
    { code: 'QA', label: 'Qatar', arabicLabel: 'دولة قطر', currency: 'QAR', phone: '974' },
    { code: 'OM', label: 'Oman', arabicLabel: 'سلطنة عمان', currency: 'OMR', phone: '968' },
    { code: 'BH', label: 'Bahrain', arabicLabel: 'البحرين', currency: 'BHD', phone: '973' },
];

// export const countries = [
//     { code: 'AD', label: 'Andorra', phone: '376' },
//     {
//         code: 'AE',
//         label: 'United Arab Emirates',
//         phone: '971',
//     },
//     { code: 'AF', label: 'Afghanistan', phone: '93' },
//     {
//         code: 'AG',
//         label: 'Antigua and Barbuda',
//         phone: '1-268',
//     },
//     { code: 'AI', label: 'Anguilla', phone: '1-264' },
//     { code: 'AL', label: 'Albania', phone: '355' },
//     { code: 'AM', label: 'Armenia', phone: '374' },
//     { code: 'AO', label: 'Angola', phone: '244' },
//     { code: 'AQ', label: 'Antarctica', phone: '672' },
//     { code: 'AR', label: 'Argentina', phone: '54' },
//     { code: 'AS', label: 'American Samoa', phone: '1-684' },
//     { code: 'AT', label: 'Austria', phone: '43' },
//     {
//         code: 'AU',
//         label: 'Australia',
//         phone: '61',
//         suggested: true,
//     },
//     { code: 'AW', label: 'Aruba', phone: '297' },
//     { code: 'AX', label: 'Alland Islands', phone: '358' },
//     { code: 'AZ', label: 'Azerbaijan', phone: '994' },
//     {
//         code: 'BA',
//         label: 'Bosnia and Herzegovina',
//         phone: '387',
//     },
//     { code: 'BB', label: 'Barbados', phone: '1-246' },
//     { code: 'BD', label: 'Bangladesh', phone: '880' },
//     { code: 'BE', label: 'Belgium', phone: '32' },
//     { code: 'BF', label: 'Burkina Faso', phone: '226' },
//     { code: 'BG', label: 'Bulgaria', phone: '359' },
//     { code: 'BH', label: 'Bahrain', phone: '973' },
//     { code: 'BI', label: 'Burundi', phone: '257' },
//     { code: 'BJ', label: 'Benin', phone: '229' },
//     { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
//     { code: 'BM', label: 'Bermuda', phone: '1-441' },
//     { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
//     { code: 'BO', label: 'Bolivia', phone: '591' },
//     { code: 'BR', label: 'Brazil', phone: '55' },
//     { code: 'BS', label: 'Bahamas', phone: '1-242' },
//     { code: 'BT', label: 'Bhutan', phone: '975' },
//     { code: 'BV', label: 'Bouvet Island', phone: '47' },
//     { code: 'BW', label: 'Botswana', phone: '267' },
//     { code: 'BY', label: 'Belarus', phone: '375' },
//     { code: 'BZ', label: 'Belize', phone: '501' },
//     {
//         code: 'CA',
//         label: 'Canada',
//         phone: '1',
//         suggested: true,
//     },
//     {
//         code: 'CC',
//         label: 'Cocos (Keeling) Islands',
//         phone: '61',
//     },
//     {
//         code: 'CD',
//         label: 'Congo, Democratic Republic of the',
//         phone: '243',
//     },
//     {
//         code: 'CF',
//         label: 'Central African Republic',
//         phone: '236',
//     },
//     {
//         code: 'CG',
//         label: 'Congo, Republic of the',
//         phone: '242',
//     },
//     { code: 'CH', label: 'Switzerland', phone: '41' },
//     { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
//     { code: 'CK', label: 'Cook Islands', phone: '682' },
//     { code: 'CL', label: 'Chile', phone: '56' },
//     { code: 'CM', label: 'Cameroon', phone: '237' },
//     { code: 'CN', label: 'China', phone: '86' },
//     { code: 'CO', label: 'Colombia', phone: '57' },
//     { code: 'CR', label: 'Costa Rica', phone: '506' },
//     { code: 'CU', label: 'Cuba', phone: '53' },
//     { code: 'CV', label: 'Cape Verde', phone: '238' },
//     { code: 'CW', label: 'Curacao', phone: '599' },
//     { code: 'CX', label: 'Christmas Island', phone: '61' },
//     { code: 'CY', label: 'Cyprus', phone: '357' },
//     { code: 'CZ', label: 'Czech Republic', phone: '420' },
//     {
//         code: 'DE',
//         label: 'Germany',
//         phone: '49',
//         suggested: true,
//     },
//     { code: 'DJ', label: 'Djibouti', phone: '253' },
//     { code: 'DK', label: 'Denmark', phone: '45' },
//     { code: 'DM', label: 'Dominica', phone: '1-767' },
//     {
//         code: 'DO',
//         label: 'Dominican Republic',
//         phone: '1-809',
//     },
//     { code: 'DZ', label: 'Algeria', phone: '213' },
//     { code: 'EC', label: 'Ecuador', phone: '593' },
//     { code: 'EE', label: 'Estonia', phone: '372' },
//     { code: 'EG', label: 'Egypt', phone: '20' },
//     { code: 'EH', label: 'Western Sahara', phone: '212' },
//     { code: 'ER', label: 'Eritrea', phone: '291' },
//     { code: 'ES', label: 'Spain', phone: '34' },
//     { code: 'ET', label: 'Ethiopia', phone: '251' },
//     { code: 'FI', label: 'Finland', phone: '358' },
//     { code: 'FJ', label: 'Fiji', phone: '679' },
//     {
//         code: 'FK',
//         label: 'Falkland Islands (Malvinas)',
//         phone: '500',
//     },
//     {
//         code: 'FM',
//         label: 'Micronesia, Federated States of',
//         phone: '691',
//     },
//     { code: 'FO', label: 'Faroe Islands', phone: '298' },
//     {
//         code: 'FR',
//         label: 'France',
//         phone: '33',
//         suggested: true,
//     },
//     { code: 'GA', label: 'Gabon', phone: '241' },
//     { code: 'GB', label: 'United Kingdom', phone: '44' },
//     { code: 'GD', label: 'Grenada', phone: '1-473' },
//     { code: 'GE', label: 'Georgia', phone: '995' },
//     { code: 'GF', label: 'French Guiana', phone: '594' },
//     { code: 'GG', label: 'Guernsey', phone: '44' },
//     { code: 'GH', label: 'Ghana', phone: '233' },
//     { code: 'GI', label: 'Gibraltar', phone: '350' },
//     { code: 'GL', label: 'Greenland', phone: '299' },
//     { code: 'GM', label: 'Gambia', phone: '220' },
//     { code: 'GN', label: 'Guinea', phone: '224' },
//     { code: 'GP', label: 'Guadeloupe', phone: '590' },
//     { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
//     { code: 'GR', label: 'Greece', phone: '30' },
//     {
//         code: 'GS',
//         label: 'South Georgia and the South Sandwich Islands',
//         phone: '500',
//     },
//     { code: 'GT', label: 'Guatemala', phone: '502' },
//     { code: 'GU', label: 'Guam', phone: '1-671' },
//     { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
//     { code: 'GY', label: 'Guyana', phone: '592' },
//     { code: 'HK', label: 'Hong Kong', phone: '852' },
//     {
//         code: 'HM',
//         label: 'Heard Island and McDonald Islands',
//         phone: '672',
//     },
//     { code: 'HN', label: 'Honduras', phone: '504' },
//     { code: 'HR', label: 'Croatia', phone: '385' },
//     { code: 'HT', label: 'Haiti', phone: '509' },
//     { code: 'HU', label: 'Hungary', phone: '36' },
//     { code: 'ID', label: 'Indonesia', phone: '62' },
//     { code: 'IE', label: 'Ireland', phone: '353' },
//     { code: 'IL', label: 'Israel', phone: '972' },
//     { code: 'IM', label: 'Isle of Man', phone: '44' },
//     { code: 'IN', label: 'India', phone: '91' },
//     {
//         code: 'IO',
//         label: 'British Indian Ocean Territory',
//         phone: '246',
//     },
//     { code: 'IQ', label: 'Iraq', phone: '964' },
//     {
//         code: 'IR',
//         label: 'Iran, Islamic Republic of',
//         phone: '98',
//     },
//     { code: 'IS', label: 'Iceland', phone: '354' },
//     { code: 'IT', label: 'Italy', phone: '39' },
//     { code: 'JE', label: 'Jersey', phone: '44' },
//     { code: 'JM', label: 'Jamaica', phone: '1-876' },
//     { code: 'JO', label: 'Jordan', phone: '962' },
//     {
//         code: 'JP',
//         label: 'Japan',
//         phone: '81',
//         suggested: true,
//     },
//     { code: 'KE', label: 'Kenya', phone: '254' },
//     { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
//     { code: 'KH', label: 'Cambodia', phone: '855' },
//     { code: 'KI', label: 'Kiribati', phone: '686' },
//     { code: 'KM', label: 'Comoros', phone: '269' },
//     {
//         code: 'KN',
//         label: 'Saint Kitts and Nevis',
//         phone: '1-869',
//     },
//     {
//         code: 'KP',
//         label: "Korea, Democratic People's Republic of",
//         phone: '850',
//     },
//     { code: 'KR', label: 'Korea, Republic of', phone: '82' },
//     { code: 'KW', label: 'Kuwait', phone: '965' },
//     { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
//     { code: 'KZ', label: 'Kazakhstan', phone: '7' },
//     {
//         code: 'LA',
//         label: "Lao People's Democratic Republic",
//         phone: '856',
//     },
//     { code: 'LB', label: 'Lebanon', phone: '961' },
//     { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
//     { code: 'LI', label: 'Liechtenstein', phone: '423' },
//     { code: 'LK', label: 'Sri Lanka', phone: '94' },
//     { code: 'LR', label: 'Liberia', phone: '231' },
//     { code: 'LS', label: 'Lesotho', phone: '266' },
//     { code: 'LT', label: 'Lithuania', phone: '370' },
//     { code: 'LU', label: 'Luxembourg', phone: '352' },
//     { code: 'LV', label: 'Latvia', phone: '371' },
//     { code: 'LY', label: 'Libya', phone: '218' },
//     { code: 'MA', label: 'Morocco', phone: '212' },
//     { code: 'MC', label: 'Monaco', phone: '377' },
//     {
//         code: 'MD',
//         label: 'Moldova, Republic of',
//         phone: '373',
//     },
//     { code: 'ME', label: 'Montenegro', phone: '382' },
//     {
//         code: 'MF',
//         label: 'Saint Martin (French part)',
//         phone: '590',
//     },
//     { code: 'MG', label: 'Madagascar', phone: '261' },
//     { code: 'MH', label: 'Marshall Islands', phone: '692' },
//     {
//         code: 'MK',
//         label: 'Macedonia, the Former Yugoslav Republic of',
//         phone: '389',
//     },
//     { code: 'ML', label: 'Mali', phone: '223' },
//     { code: 'MM', label: 'Myanmar', phone: '95' },
//     { code: 'MN', label: 'Mongolia', phone: '976' },
//     { code: 'MO', label: 'Macao', phone: '853' },
//     {
//         code: 'MP',
//         label: 'Northern Mariana Islands',
//         phone: '1-670',
//     },
//     { code: 'MQ', label: 'Martinique', phone: '596' },
//     { code: 'MR', label: 'Mauritania', phone: '222' },
//     { code: 'MS', label: 'Montserrat', phone: '1-664' },
//     { code: 'MT', label: 'Malta', phone: '356' },
//     { code: 'MU', label: 'Mauritius', phone: '230' },
//     { code: 'MV', label: 'Maldives', phone: '960' },
//     { code: 'MW', label: 'Malawi', phone: '265' },
//     { code: 'MX', label: 'Mexico', phone: '52' },
//     { code: 'MY', label: 'Malaysia', phone: '60' },
//     { code: 'MZ', label: 'Mozambique', phone: '258' },
//     { code: 'NA', label: 'Namibia', phone: '264' },
//     { code: 'NC', label: 'New Caledonia', phone: '687' },
//     { code: 'NE', label: 'Niger', phone: '227' },
//     { code: 'NF', label: 'Norfolk Island', phone: '672' },
//     { code: 'NG', label: 'Nigeria', phone: '234' },
//     { code: 'NI', label: 'Nicaragua', phone: '505' },
//     { code: 'NL', label: 'Netherlands', phone: '31' },
//     { code: 'NO', label: 'Norway', phone: '47' },
//     { code: 'NP', label: 'Nepal', phone: '977' },
//     { code: 'NR', label: 'Nauru', phone: '674' },
//     { code: 'NU', label: 'Niue', phone: '683' },
//     { code: 'NZ', label: 'New Zealand', phone: '64' },
//     { code: 'OM', label: 'Oman', phone: '968' },
//     { code: 'PA', label: 'Panama', phone: '507' },
//     { code: 'PE', label: 'Peru', phone: '51' },
//     { code: 'PF', label: 'French Polynesia', phone: '689' },
//     { code: 'PG', label: 'Papua New Guinea', phone: '675' },
//     { code: 'PH', label: 'Philippines', phone: '63' },
//     { code: 'PK', label: 'Pakistan', phone: '92' },
//     { code: 'PL', label: 'Poland', phone: '48' },
//     {
//         code: 'PM',
//         label: 'Saint Pierre and Miquelon',
//         phone: '508',
//     },
//     { code: 'PN', label: 'Pitcairn', phone: '870' },
//     { code: 'PR', label: 'Puerto Rico', phone: '1' },
//     {
//         code: 'PS',
//         label: 'Palestine, State of',
//         phone: '970',
//     },
//     { code: 'PT', label: 'Portugal', phone: '351' },
//     { code: 'PW', label: 'Palau', phone: '680' },
//     { code: 'PY', label: 'Paraguay', phone: '595' },
//     { code: 'QA', label: 'Qatar', phone: '974' },
//     { code: 'RE', label: 'Reunion', phone: '262' },
//     { code: 'RO', label: 'Romania', phone: '40' },
//     { code: 'RS', label: 'Serbia', phone: '381' },
//     { code: 'RU', label: 'Russian Federation', phone: '7' },
//     { code: 'RW', label: 'Rwanda', phone: '250' },
//     { code: 'SA', label: 'Saudi Arabia', phone: '966' },
//     { code: 'SB', label: 'Solomon Islands', phone: '677' },
//     { code: 'SC', label: 'Seychelles', phone: '248' },
//     { code: 'SD', label: 'Sudan', phone: '249' },
//     { code: 'SE', label: 'Sweden', phone: '46' },
//     { code: 'SG', label: 'Singapore', phone: '65' },
//     { code: 'SH', label: 'Saint Helena', phone: '290' },
//     { code: 'SI', label: 'Slovenia', phone: '386' },
//     {
//         code: 'SJ',
//         label: 'Svalbard and Jan Mayen',
//         phone: '47',
//     },
//     { code: 'SK', label: 'Slovakia', phone: '421' },
//     { code: 'SL', label: 'Sierra Leone', phone: '232' },
//     { code: 'SM', label: 'San Marino', phone: '378' },
//     { code: 'SN', label: 'Senegal', phone: '221' },
//     { code: 'SO', label: 'Somalia', phone: '252' },
//     { code: 'SR', label: 'Suriname', phone: '597' },
//     { code: 'SS', label: 'South Sudan', phone: '211' },
//     {
//         code: 'ST',
//         label: 'Sao Tome and Principe',
//         phone: '239',
//     },
//     { code: 'SV', label: 'El Salvador', phone: '503' },
//     {
//         code: 'SX',
//         label: 'Sint Maarten (Dutch part)',
//         phone: '1-721',
//     },
//     {
//         code: 'SY',
//         label: 'Syrian Arab Republic',
//         phone: '963',
//     },
//     { code: 'SZ', label: 'Swaziland', phone: '268' },
//     {
//         code: 'TC',
//         label: 'Turks and Caicos Islands',
//         phone: '1-649',
//     },
//     { code: 'TD', label: 'Chad', phone: '235' },
//     {
//         code: 'TF',
//         label: 'French Southern Territories',
//         phone: '262',
//     },
//     { code: 'TG', label: 'Togo', phone: '228' },
//     { code: 'TH', label: 'Thailand', phone: '66' },
//     { code: 'TJ', label: 'Tajikistan', phone: '992' },
//     { code: 'TK', label: 'Tokelau', phone: '690' },
//     { code: 'TL', label: 'Timor-Leste', phone: '670' },
//     { code: 'TM', label: 'Turkmenistan', phone: '993' },
//     { code: 'TN', label: 'Tunisia', phone: '216' },
//     { code: 'TO', label: 'Tonga', phone: '676' },
//     { code: 'TR', label: 'Turkey', phone: '90' },
//     {
//         code: 'TT',
//         label: 'Trinidad and Tobago',
//         phone: '1-868',
//     },
//     { code: 'TV', label: 'Tuvalu', phone: '688' },
//     {
//         code: 'TW',
//         label: 'Taiwan, Republic of China',
//         phone: '886',
//     },
//     {
//         code: 'TZ',
//         label: 'United Republic of Tanzania',
//         phone: '255',
//     },
//     { code: 'UA', label: 'Ukraine', phone: '380' },
//     { code: 'UG', label: 'Uganda', phone: '256' },
//     {
//         code: 'US',
//         label: 'United States',
//         phone: '1',
//         suggested: true,
//     },
//     { code: 'UY', label: 'Uruguay', phone: '598' },
//     { code: 'UZ', label: 'Uzbekistan', phone: '998' },
//     {
//         code: 'VA',
//         label: 'Holy See (Vatican City State)',
//         phone: '379',
//     },
//     {
//         code: 'VC',
//         label: 'Saint Vincent and the Grenadines',
//         phone: '1-784',
//     },
//     { code: 'VE', label: 'Venezuela', phone: '58' },
//     {
//         code: 'VG',
//         label: 'British Virgin Islands',
//         phone: '1-284',
//     },
//     {
//         code: 'VI',
//         label: 'US Virgin Islands',
//         phone: '1-340',
//     },
//     { code: 'VN', label: 'Vietnam', phone: '84' },
//     { code: 'VU', label: 'Vanuatu', phone: '678' },
//     { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
//     { code: 'WS', label: 'Samoa', phone: '685' },
//     { code: 'XK', label: 'Kosovo', phone: '383' },
//     { code: 'YE', label: 'Yemen', phone: '967' },
//     { code: 'YT', label: 'Mayotte', phone: '262' },
//     { code: 'ZA', label: 'South Africa', phone: '27' },
//     { code: 'ZM', label: 'Zambia', phone: '260' },
//     { code: 'ZW', label: 'Zimbabwe', phone: '263' },
// ];
// length 252
// All countries
export const countries = [
    { label: 'Afghanistan', code: 'AF', phone: 93, currency: 'AFN' },
    { label: 'Aland Islands', code: 'AX', phone: 358, currency: 'EUR' },
    { label: 'Albania', code: 'AL', phone: 355, currency: 'ALL' },
    { label: 'Algeria', code: 'DZ', phone: 213, currency: 'DZD' },
    { label: 'American Samoa', code: 'AS', phone: 1684, currency: 'USD' },
    { label: 'Andorra', code: 'AD', phone: 376, currency: 'EUR' },
    { label: 'Angola', code: 'AO', phone: 244, currency: 'AOA' },
    { label: 'Anguilla', code: 'AI', phone: 1264, currency: 'XCD' },
    { label: 'Antarctica', code: 'AQ', phone: 672, currency: 'AAD' },
    { label: 'Antigua and Barbuda', code: 'AG', phone: 1268, currency: 'XCD' },
    { label: 'Argentina', code: 'AR', phone: 54, currency: 'ARS' },
    { label: 'Armenia', code: 'AM', phone: 374, currency: 'AMD' },
    { label: 'Aruba', code: 'AW', phone: 297, currency: 'AWG' },
    { label: 'Australia', code: 'AU', phone: 61, currency: 'AUD' },
    { label: 'Austria', code: 'AT', phone: 43, currency: 'EUR' },
    { label: 'Azerbaijan', code: 'AZ', phone: 994, currency: 'AZN' },
    { label: 'Bahamas', code: 'BS', phone: 1242, currency: 'BSD' },
    { label: 'Bahrain', code: 'BH', phone: 973, currency: 'BHD' },
    { label: 'Bangladesh', code: 'BD', phone: 880, currency: 'BDT' },
    { label: 'Barbados', code: 'BB', phone: 1246, currency: 'BBD' },
    { label: 'Belarus', code: 'BY', phone: 375, currency: 'BYN' },
    { label: 'Belgium', code: 'BE', phone: 32, currency: 'EUR' },
    { label: 'Belize', code: 'BZ', phone: 501, currency: 'BZD' },
    { label: 'Benin', code: 'BJ', phone: 229, currency: 'XOF' },
    { label: 'Bermuda', code: 'BM', phone: 1441, currency: 'BMD' },
    { label: 'Bhutan', code: 'BT', phone: 975, currency: 'BTN' },
    { label: 'Bolivia', code: 'BO', phone: 591, currency: 'BOB' },
    { label: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', phone: 599, currency: 'USD' },
    { label: 'Bosnia and Herzegovina', code: 'BA', phone: 387, currency: 'BAM' },
    { label: 'Botswana', code: 'BW', phone: 267, currency: 'BWP' },
    { label: 'Bouvet Island', code: 'BV', phone: 55, currency: 'NOK' },
    { label: 'Brazil', code: 'BR', phone: 55, currency: 'BRL' },
    { label: 'British Indian Ocean Territory', code: 'IO', phone: 246, currency: 'USD' },
    { label: 'Brunei Darussalam', code: 'BN', phone: 673, currency: 'BND' },
    { label: 'Bulgaria', code: 'BG', phone: 359, currency: 'BGN' },
    { label: 'Burkina Faso', code: 'BF', phone: 226, currency: 'XOF' },
    { label: 'Burundi', code: 'BI', phone: 257, currency: 'BIF' },
    { label: 'Cambodia', code: 'KH', phone: 855, currency: 'KHR' },
    { label: 'Cameroon', code: 'CM', phone: 237, currency: 'XAF' },
    { label: 'Canada', code: 'CA', phone: 1, currency: 'CAD' },
    { label: 'Cape Verde', code: 'CV', phone: 238, currency: 'CVE' },
    { label: 'Cayman Islands', code: 'KY', phone: 1345, currency: 'KYD' },
    { label: 'Central African Republic', code: 'CF', phone: 236, currency: 'XAF' },
    { label: 'Chad', code: 'TD', phone: 235, currency: 'XAF' },
    { label: 'Chile', code: 'CL', phone: 56, currency: 'CLP' },
    { label: 'China', code: 'CN', phone: 86, currency: 'CNY' },
    { label: 'Christmas Island', code: 'CX', phone: 61, currency: 'AUD' },
    { label: 'Cocos (Keeling) Islands', code: 'CC', phone: 672, currency: 'AUD' },
    { label: 'Colombia', code: 'CO', phone: 57, currency: 'COP' },
    { label: 'Comoros', code: 'KM', phone: 269, currency: 'KMF' },
    { label: 'Congo', code: 'CG', phone: 242, currency: 'XAF' },
    { label: 'Congo, Democratic Republic of the Congo', code: 'CD', phone: 242, currency: 'CDF' },
    { label: 'Cook Islands', code: 'CK', phone: 682, currency: 'NZD' },
    { label: 'Costa Rica', code: 'CR', phone: 506, currency: 'CRC' },
    { label: "Cote D'Ivoire", code: 'CI', phone: 225, currency: 'XOF' },
    { label: 'Croatia', code: 'HR', phone: 385, currency: 'HRK' },
    { label: 'Cuba', code: 'CU', phone: 53, currency: 'CUP' },
    { label: 'Curacao', code: 'CW', phone: 599, currency: 'ANG' },
    { label: 'Cyprus', code: 'CY', phone: 357, currency: 'EUR' },
    { label: 'Czech Republic', code: 'CZ', phone: 420, currency: 'CZK' },
    { label: 'Denmark', code: 'DK', phone: 45, currency: 'DKK' },
    { label: 'Djibouti', code: 'DJ', phone: 253, currency: 'DJF' },
    { label: 'Dominica', code: 'DM', phone: 1767, currency: 'XCD' },
    { label: 'Dominican Republic', code: 'DO', phone: 1809, currency: 'DOP' },
    { label: 'Ecuador', code: 'EC', phone: 593, currency: 'USD' },
    { label: 'Egypt', code: 'EG', phone: 20, currency: 'EGP' },
    { label: 'El Salvador', code: 'SV', phone: 503, currency: 'USD' },
    { label: 'Equatorial Guinea', code: 'GQ', phone: 240, currency: 'XAF' },
    { label: 'Eritrea', code: 'ER', phone: 291, currency: 'ERN' },
    { label: 'Estonia', code: 'EE', phone: 372, currency: 'EUR' },
    { label: 'Ethiopia', code: 'ET', phone: 251, currency: 'ETB' },
    { label: 'Falkland Islands (Malvinas)', code: 'FK', phone: 500, currency: 'FKP' },
    { label: 'Faroe Islands', code: 'FO', phone: 298, currency: 'DKK' },
    { label: 'Fiji', code: 'FJ', phone: 679, currency: 'FJD' },
    { label: 'Finland', code: 'FI', phone: 358, currency: 'EUR' },
    { label: 'France', code: 'FR', phone: 33, currency: 'EUR' },
    { label: 'French Guiana', code: 'GF', phone: 594, currency: 'EUR' },
    { label: 'French Polynesia', code: 'PF', phone: 689, currency: 'XPF' },
    { label: 'French Southern Territories', code: 'TF', phone: 262, currency: 'EUR' },
    { label: 'Gabon', code: 'GA', phone: 241, currency: 'XAF' },
    { label: 'Gambia', code: 'GM', phone: 220, currency: 'GMD' },
    { label: 'Georgia', code: 'GE', phone: 995, currency: 'GEL' },
    { label: 'Germany', code: 'DE', phone: 49, currency: 'EUR' },
    { label: 'Ghana', code: 'GH', phone: 233, currency: 'GHS' },
    { label: 'Gibraltar', code: 'GI', phone: 350, currency: 'GIP' },
    { label: 'Greece', code: 'GR', phone: 30, currency: 'EUR' },
    { label: 'Greenland', code: 'GL', phone: 299, currency: 'DKK' },
    { label: 'Grenada', code: 'GD', phone: 1473, currency: 'XCD' },
    { label: 'Guadeloupe', code: 'GP', phone: 590, currency: 'EUR' },
    { label: 'Guam', code: 'GU', phone: 1671, currency: 'USD' },
    { label: 'Guatemala', code: 'GT', phone: 502, currency: 'GTQ' },
    { label: 'Guernsey', code: 'GG', phone: 44, currency: 'GBP' },
    { label: 'Guinea', code: 'GN', phone: 224, currency: 'GNF' },
    { label: 'Guinea-Bissau', code: 'GW', phone: 245, currency: 'XOF' },
    { label: 'Guyana', code: 'GY', phone: 592, currency: 'GYD' },
    { label: 'Haiti', code: 'HT', phone: 509, currency: 'HTG' },
    { label: 'Heard Island and McDonald Islands', code: 'HM', phone: 0, currency: 'AUD' },
    { label: 'Holy See (Vatican City State)', code: 'VA', phone: 39, currency: 'EUR' },
    { label: 'Honduras', code: 'HN', phone: 504, currency: 'HNL' },
    { label: 'Hong Kong', code: 'HK', phone: 852, currency: 'HKD' },
    { label: 'Hungary', code: 'HU', phone: 36, currency: 'HUF' },
    { label: 'Iceland', code: 'IS', phone: 354, currency: 'ISK' },
    { label: 'India', code: 'IN', phone: 91, currency: 'INR' },
    { label: 'Indonesia', code: 'ID', phone: 62, currency: 'IDR' },
    { label: 'Iran, Islamic Republic of', code: 'IR', phone: 98, currency: 'IRR' },
    { label: 'Iraq', code: 'IQ', phone: 964, currency: 'IQD' },
    { label: 'Ireland', code: 'IE', phone: 353, currency: 'EUR' },
    { label: 'Isle of Man', code: 'IM', phone: 44, currency: 'GBP' },
    // { label: 'Israel', code: 'IL', phone: 972, currency: 'ILS' },
    { label: 'Italy', code: 'IT', phone: 39, currency: 'EUR' },
    { label: 'Jamaica', code: 'JM', phone: 1876, currency: 'JMD' },
    { label: 'Japan', code: 'JP', phone: 81, currency: 'JPY' },
    { label: 'Jersey', code: 'JE', phone: 44, currency: 'GBP' },
    { label: 'Jordan', code: 'JO', phone: 962, currency: 'JOD' },
    { label: 'Kazakhstan', code: 'KZ', phone: 7, currency: 'KZT' },
    { label: 'Kenya', code: 'KE', phone: 254, currency: 'KES' },
    { label: 'Kiribati', code: 'KI', phone: 686, currency: 'AUD' },
    { label: "Korea, Democratic People's Republic of", code: 'KP', phone: 850, currency: 'KPW' },
    { label: 'Korea, Republic of', code: 'KR', phone: 82, currency: 'KRW' },
    { label: 'Kosovo', code: 'XK', phone: 383, currency: 'EUR' },
    { label: 'Kuwait', code: 'KW', phone: 965, currency: 'KWD' },
    { label: 'Kyrgyzstan', code: 'KG', phone: 996, currency: 'KGS' },
    { label: "Lao People's Democratic Republic", code: 'LA', phone: 856, currency: 'LAK' },
    { label: 'Latvia', code: 'LV', phone: 371, currency: 'EUR' },
    { label: 'Lebanon', code: 'LB', phone: 961, currency: 'LBP' },
    { label: 'Lesotho', code: 'LS', phone: 266, currency: 'LSL' },
    { label: 'Liberia', code: 'LR', phone: 231, currency: 'LRD' },
    { label: 'Libyan Arab Jamahiriya', code: 'LY', phone: 218, currency: 'LYD' },
    { label: 'Liechtenstein', code: 'LI', phone: 423, currency: 'CHF' },
    { label: 'Lithuania', code: 'LT', phone: 370, currency: 'EUR' },
    { label: 'Luxembourg', code: 'LU', phone: 352, currency: 'EUR' },
    { label: 'Macao', code: 'MO', phone: 853, currency: 'MOP' },
    { label: 'Macedonia, the Former Yugoslav Republic of', code: 'MK', phone: 389, currency: 'MKD' },
    { label: 'Madagascar', code: 'MG', phone: 261, currency: 'MGA' },
    { label: 'Malawi', code: 'MW', phone: 265, currency: 'MWK' },
    { label: 'Malaysia', code: 'MY', phone: 60, currency: 'MYR' },
    { label: 'Maldives', code: 'MV', phone: 960, currency: 'MVR' },
    { label: 'Mali', code: 'ML', phone: 223, currency: 'XOF' },
    { label: 'Malta', code: 'MT', phone: 356, currency: 'EUR' },
    { label: 'Marshall Islands', code: 'MH', phone: 692, currency: 'USD' },
    { label: 'Martinique', code: 'MQ', phone: 596, currency: 'EUR' },
    { label: 'Mauritania', code: 'MR', phone: 222, currency: 'MRO' },
    { label: 'Mauritius', code: 'MU', phone: 230, currency: 'MUR' },
    { label: 'Mayotte', code: 'YT', phone: 262, currency: 'EUR' },
    { label: 'Mexico', code: 'MX', phone: 52, currency: 'MXN' },
    { label: 'Micronesia, Federated States of', code: 'FM', phone: 691, currency: 'USD' },
    { label: 'Moldova, Republic of', code: 'MD', phone: 373, currency: 'MDL' },
    { label: 'Monaco', code: 'MC', phone: 377, currency: 'EUR' },
    { label: 'Mongolia', code: 'MN', phone: 976, currency: 'MNT' },
    { label: 'Montenegro', code: 'ME', phone: 382, currency: 'EUR' },
    { label: 'Montserrat', code: 'MS', phone: 1664, currency: 'XCD' },
    { label: 'Morocco', code: 'MA', phone: 212, currency: 'MAD' },
    { label: 'Mozambique', code: 'MZ', phone: 258, currency: 'MZN' },
    { label: 'Myanmar', code: 'MM', phone: 95, currency: 'MMK' },
    { label: 'Namibia', code: 'NA', phone: 264, currency: 'NAD' },
    { label: 'Nauru', code: 'NR', phone: 674, currency: 'AUD' },
    { label: 'Nepal', code: 'NP', phone: 977, currency: 'NPR' },
    { label: 'Netherlands', code: 'NL', phone: 31, currency: 'EUR' },
    { label: 'Netherlands Antilles', code: 'AN', phone: 599, currency: 'ANG' },
    { label: 'New Caledonia', code: 'NC', phone: 687, currency: 'XPF' },
    { label: 'New Zealand', code: 'NZ', phone: 64, currency: 'NZD' },
    { label: 'Nicaragua', code: 'NI', phone: 505, currency: 'NIO' },
    { label: 'Niger', code: 'NE', phone: 227, currency: 'XOF' },
    { label: 'Nigeria', code: 'NG', phone: 234, currency: 'NGN' },
    { label: 'Niue', code: 'NU', phone: 683, currency: 'NZD' },
    { label: 'Norfolk Island', code: 'NF', phone: 672, currency: 'AUD' },
    { label: 'Northern Mariana Islands', code: 'MP', phone: 1670, currency: 'USD' },
    { label: 'Norway', code: 'NO', phone: 47, currency: 'NOK' },
    { label: 'Oman', code: 'OM', phone: 968, currency: 'OMR' },
    { label: 'Pakistan', code: 'PK', phone: 92, currency: 'PKR' },
    { label: 'Palau', code: 'PW', phone: 680, currency: 'USD' },
    { label: 'Palestinian Territory, Occupied', code: 'PS', phone: 970, currency: 'ILS' },
    { label: 'Panama', code: 'PA', phone: 507, currency: 'PAB' },
    { label: 'Papua New Guinea', code: 'PG', phone: 675, currency: 'PGK' },
    { label: 'Paraguay', code: 'PY', phone: 595, currency: 'PYG' },
    { label: 'Peru', code: 'PE', phone: 51, currency: 'PEN' },
    { label: 'Philippines', code: 'PH', phone: 63, currency: 'PHP' },
    { label: 'Pitcairn', code: 'PN', phone: 64, currency: 'NZD' },
    { label: 'Poland', code: 'PL', phone: 48, currency: 'PLN' },
    { label: 'Portugal', code: 'PT', phone: 351, currency: 'EUR' },
    { label: 'Puerto Rico', code: 'PR', phone: 1787, currency: 'USD' },
    { label: 'Qatar', code: 'QA', phone: 974, currency: 'QAR' },
    { label: 'Reunion', code: 'RE', phone: 262, currency: 'EUR' },
    { label: 'Romania', code: 'RO', phone: 40, currency: 'RON' },
    { label: 'Russian Federation', code: 'RU', phone: 7, currency: 'RUB' },
    { label: 'Rwanda', code: 'RW', phone: 250, currency: 'RWF' },
    { label: 'Saint Barthelemy', code: 'BL', phone: 590, currency: 'EUR' },
    { label: 'Saint Helena', code: 'SH', phone: 290, currency: 'SHP' },
    { label: 'Saint Kitts and Nevis', code: 'KN', phone: 1869, currency: 'XCD' },
    { label: 'Saint Lucia', code: 'LC', phone: 1758, currency: 'XCD' },
    { label: 'Saint Martin', code: 'MF', phone: 590, currency: 'EUR' },
    { label: 'Saint Pierre and Miquelon', code: 'PM', phone: 508, currency: 'EUR' },
    { label: 'Saint Vincent and the Grenadines', code: 'VC', phone: 1784, currency: 'XCD' },
    { label: 'Samoa', code: 'WS', phone: 684, currency: 'WST' },
    { label: 'San Marino', code: 'SM', phone: 378, currency: 'EUR' },
    { label: 'Sao Tome and Principe', code: 'ST', phone: 239, currency: 'STD' },
    { label: 'Saudi Arabia', code: 'SA', phone: 966, currency: 'SAR' },
    { label: 'Senegal', code: 'SN', phone: 221, currency: 'XOF' },
    { label: 'Serbia', code: 'RS', phone: 381, currency: 'RSD' },
    { label: 'Serbia and Montenegro', code: 'CS', phone: 381, currency: 'RSD' },
    { label: 'Seychelles', code: 'SC', phone: 248, currency: 'SCR' },
    { label: 'Sierra Leone', code: 'SL', phone: 232, currency: 'SLL' },
    { label: 'Singapore', code: 'SG', phone: 65, currency: 'SGD' },
    { label: 'St Martin', code: 'SX', phone: 721, currency: 'ANG' },
    { label: 'Slovakia', code: 'SK', phone: 421, currency: 'EUR' },
    { label: 'Slovenia', code: 'SI', phone: 386, currency: 'EUR' },
    { label: 'Solomon Islands', code: 'SB', phone: 677, currency: 'SBD' },
    { label: 'Somalia', code: 'SO', phone: 252, currency: 'SOS' },
    { label: 'South Africa', code: 'ZA', phone: 27, currency: 'ZAR' },
    { label: 'South Georgia and the South Sandwich Islands', code: 'GS', phone: 500, currency: 'GBP' },
    { label: 'South Sudan', code: 'SS', phone: 211, currency: 'SSP' },
    { label: 'Spain', code: 'ES', phone: 34, currency: 'EUR' },
    { label: 'Sri Lanka', code: 'LK', phone: 94, currency: 'LKR' },
    { label: 'Sudan', code: 'SD', phone: 249, currency: 'SDG' },
    { label: 'Suriname', code: 'SR', phone: 597, currency: 'SRD' },
    { label: 'Svalbard and Jan Mayen', code: 'SJ', phone: 47, currency: 'NOK' },
    { label: 'Swaziland', code: 'SZ', phone: 268, currency: 'SZL' },
    { label: 'Sweden', code: 'SE', phone: 46, currency: 'SEK' },
    { label: 'Switzerland', code: 'CH', phone: 41, currency: 'CHF' },
    { label: 'Syrian Arab Republic', code: 'SY', phone: 963, currency: 'SYP' },
    { label: 'Taiwan, Province of China', code: 'TW', phone: 886, currency: 'TWD' },
    { label: 'Tajikistan', code: 'TJ', phone: 992, currency: 'TJS' },
    { label: 'Tanzania, United Republic of', code: 'TZ', phone: 255, currency: 'TZS' },
    { label: 'Thailand', code: 'TH', phone: 66, currency: 'THB' },
    { label: 'Timor-Leste', code: 'TL', phone: 670, currency: 'USD' },
    { label: 'Togo', code: 'TG', phone: 228, currency: 'XOF' },
    { label: 'Tokelau', code: 'TK', phone: 690, currency: 'NZD' },
    { label: 'Tonga', code: 'TO', phone: 676, currency: 'TOP' },
    { label: 'Trinidad and Tobago', code: 'TT', phone: 1868, currency: 'TTD' },
    { label: 'Tunisia', code: 'TN', phone: 216, currency: 'TND' },
    { label: 'Turkey', code: 'TR', phone: 90, currency: 'TRY' },
    { label: 'Turkmenistan', code: 'TM', phone: 7370, currency: 'TMT' },
    { label: 'Turks and Caicos Islands', code: 'TC', phone: 1649, currency: 'USD' },
    { label: 'Tuvalu', code: 'TV', phone: 688, currency: 'AUD' },
    { label: 'Uganda', code: 'UG', phone: 256, currency: 'UGX' },
    { label: 'Ukraine', code: 'UA', phone: 380, currency: 'UAH' },
    { label: 'United Arab Emirates', code: 'AE', phone: 971, currency: 'AED' },
    { label: 'United Kingdom', code: 'GB', phone: 44, currency: 'GBP' },
    { label: 'United States', code: 'US', phone: 1, currency: 'USD' },
    { label: 'United States Minor Outlying Islands', code: 'UM', phone: 1, currency: 'USD' },
    { label: 'Uruguay', code: 'UY', phone: 598, currency: 'UYU' },
    { label: 'Uzbekistan', code: 'UZ', phone: 998, currency: 'UZS' },
    { label: 'Vanuatu', code: 'VU', phone: 678, currency: 'VUV' },
    { label: 'Venezuela', code: 'VE', phone: 58, currency: 'VEF' },
    { label: 'Viet Nam', code: 'VN', phone: 84, currency: 'VND' },
    { label: 'Virgin Islands, British', code: 'VG', phone: 1284, currency: 'USD' },
    { label: 'Virgin Islands, U.s.', code: 'VI', phone: 1340, currency: 'USD' },
    { label: 'Wallis and Futuna', code: 'WF', phone: 681, currency: 'XPF' },
    { label: 'Western Sahara', code: 'EH', phone: 212, currency: 'MAD' },
    { label: 'Yemen', code: 'YE', phone: 967, currency: 'YER' },
    { label: 'Zambia', code: 'ZM', phone: 260, currency: 'ZMW' },
    { label: 'Zimbabwe', code: 'ZW', phone: 263, currency: 'ZWL' },
];
export const currencies = [
    { name: 'Afghan Afghani', code: 'AFA' },
    { name: 'Albanian Lek', code: 'ALL' },
    { name: 'Algerian Dinar', code: 'DZD' },
    { name: 'Angolan Kwanza', code: 'AOA' },
    { name: 'Argentine Peso', code: 'ARS' },
    { name: 'Armenian Dram', code: 'AMD' },
    { name: 'Aruban Florin', code: 'AWG' },
    { name: 'Australian Dollar', code: 'AUD' },
    { name: 'Azerbaijani Manat', code: 'AZN' },
    { name: 'Bahamian Dollar', code: 'BSD' },
    { name: 'Bahraini Dinar', code: 'BHD' },
    { name: 'Bangladeshi Taka', code: 'BDT' },
    { name: 'Barbadian Dollar', code: 'BBD' },
    { name: 'Belarusian Ruble', code: 'BYR' },
    { name: 'Belgian Franc', code: 'BEF' },
    { name: 'Belize Dollar', code: 'BZD' },
    { name: 'Bermudan Dollar', code: 'BMD' },
    { name: 'Bhutanese Ngultrum', code: 'BTN' },
    { name: 'Bitcoin', code: 'BTC' },
    { name: 'Bolivian Boliviano', code: 'BOB' },
    { name: 'Bosnia-Herzegovina Convertible Mark', code: 'BAM' },
    { name: 'Botswanan Pula', code: 'BWP' },
    { name: 'Brazilian Real', code: 'BRL' },
    { name: 'British Pound Sterling', code: 'GBP' },
    { name: 'Brunei Dollar', code: 'BND' },
    { name: 'Bulgarian Lev', code: 'BGN' },
    { name: 'Burundian Franc', code: 'BIF' },
    { name: 'Cambodian Riel', code: 'KHR' },
    { name: 'Canadian Dollar', code: 'CAD' },
    { name: 'Cape Verdean Escudo', code: 'CVE' },
    { name: 'Cayman Islands Dollar', code: 'KYD' },
    { name: 'CFA Franc BCEAO', code: 'XOF' },
    { name: 'CFA Franc BEAC', code: 'XAF' },
    { name: 'CFP Franc', code: 'XPF' },
    { name: 'Chilean Peso', code: 'CLP' },
    { name: 'Chilean Unit of Account', code: 'CLF' },
    { name: 'Chinese Yuan', code: 'CNY' },
    { name: 'Colombian Peso', code: 'COP' },
    { name: 'Comorian Franc', code: 'KMF' },
    { name: 'Congolese Franc', code: 'CDF' },
    { name: 'Costa Rican Colón', code: 'CRC' },
    { name: 'Croatian Kuna', code: 'HRK' },
    { name: 'Cuban Convertible Peso', code: 'CUC' },
    { name: 'Czech Republic Koruna', code: 'CZK' },
    { name: 'Danish Krone', code: 'DKK' },
    { name: 'Djiboutian Franc', code: 'DJF' },
    { name: 'Dominican Peso', code: 'DOP' },
    { name: 'East Caribbean Dollar', code: 'XCD' },
    { name: 'Egyptian Pound', code: 'EGP' },
    { name: 'Eritrean Nakfa', code: 'ERN' },
    { name: 'Estonian Kroon', code: 'EEK' },
    { name: 'Ethiopian Birr', code: 'ETB' },
    { name: 'Euro', code: 'EUR' },
    { name: 'Falkland Islands Pound', code: 'FKP' },
    { name: 'Fijian Dollar', code: 'FJD' },
    { name: 'Gambian Dalasi', code: 'GMD' },
    { name: 'Georgian Lari', code: 'GEL' },
    { name: 'German Mark', code: 'DEM' },
    { name: 'Ghanaian Cedi', code: 'GHS' },
    { name: 'Gibraltar Pound', code: 'GIP' },
    { name: 'Greek Drachma', code: 'GRD' },
    { name: 'Guatemalan Quetzal', code: 'GTQ' },
    { name: 'Guinean Franc', code: 'GNF' },
    { name: 'Guyanaese Dollar', code: 'GYD' },
    { name: 'Haitian Gourde', code: 'HTG' },
    { name: 'Honduran Lempira', code: 'HNL' },
    { name: 'Hong Kong Dollar', code: 'HKD' },
    { name: 'Hungarian Forint', code: 'HUF' },
    { name: 'Icelandic Króna', code: 'ISK' },
    { name: 'Indian Rupee', code: 'INR' },
    { name: 'Indonesian Rupiah', code: 'IDR' },
    { name: 'Iranian Rial', code: 'IRR' },
    { name: 'Iraqi Dinar', code: 'IQD' },
    { name: 'Israeli New Sheqel', code: 'ILS' },
    { name: 'Italian Lira', code: 'ITL' },
    { name: 'Jamaican Dollar', code: 'JMD' },
    { name: 'Japanese Yen', code: 'JPY' },
    { name: 'Jordanian Dinar', code: 'JOD' },
    { name: 'Kazakhstani Tenge', code: 'KZT' },
    { name: 'Kenyan Shilling', code: 'KES' },
    { name: 'Kuwaiti Dinar', code: 'KWD' },
    { name: 'Kyrgystani Som', code: 'KGS' },
    { name: 'Laotian Kip', code: 'LAK' },
    { name: 'Latvian Lats', code: 'LVL' },
    { name: 'Lebanese Pound', code: 'LBP' },
    { name: 'Lesotho Loti', code: 'LSL' },
    { name: 'Liberian Dollar', code: 'LRD' },
    { name: 'Libyan Dinar', code: 'LYD' },
    { name: 'Litecoin', code: 'LTC' },
    { name: 'Lithuanian Litas', code: 'LTL' },
    { name: 'Macanese Pataca', code: 'MOP' },
    { name: 'Macedonian Denar', code: 'MKD' },
    { name: 'Malagasy Ariary', code: 'MGA' },
    { name: 'Malawian Kwacha', code: 'MWK' },
    { name: 'Malaysian Ringgit', code: 'MYR' },
    { name: 'Maldivian Rufiyaa', code: 'MVR' },
    { name: 'Mauritanian Ouguiya', code: 'MRO' },
    { name: 'Mauritian Rupee', code: 'MUR' },
    { name: 'Mexican Peso', code: 'MXN' },
    { name: 'Moldovan Leu', code: 'MDL' },
    { name: 'Mongolian Tugrik', code: 'MNT' },
    { name: 'Moroccan Dirham', code: 'MAD' },
    { name: 'Mozambican Metical', code: 'MZM' },
    { name: 'Myanmar Kyat', code: 'MMK' },
    { name: 'Namibian Dollar', code: 'NAD' },
    { name: 'Nepalese Rupee', code: 'NPR' },
    { name: 'Netherlands Antillean Guilder', code: 'ANG' },
    { name: 'New Taiwan Dollar', code: 'TWD' },
    { name: 'New Zealand Dollar', code: 'NZD' },
    { name: 'Nicaraguan Córdoba', code: 'NIO' },
    { name: 'Nigerian Naira', code: 'NGN' },
    { name: 'North Korean Won', code: 'KPW' },
    { name: 'Norwegian Krone', code: 'NOK' },
    { name: 'Omani Rial', code: 'OMR' },
    { name: 'Pakistani Rupee', code: 'PKR' },
    { name: 'Panamanian Balboa', code: 'PAB' },
    { name: 'Papua New Guinean Kina', code: 'PGK' },
    { name: 'Paraguayan Guarani', code: 'PYG' },
    { name: 'Peruvian Nuevo Sol', code: 'PEN' },
    { name: 'Philippine Peso', code: 'PHP' },
    { name: 'Polish Zloty', code: 'PLN' },
    { name: 'Qatari Rial', code: 'QAR' },
    { name: 'Romanian Leu', code: 'RON' },
    { name: 'Russian Ruble', code: 'RUB' },
    { name: 'Rwandan Franc', code: 'RWF' },
    { name: 'Salvadoran Colón', code: 'SVC' },
    { name: 'Samoan Tala', code: 'WST' },
    { name: 'São Tomé and Príncipe Dobra', code: 'STD' },
    { name: 'Saudi Riyal', code: 'SAR' },
    { name: 'Serbian Dinar', code: 'RSD' },
    { name: 'Seychellois Rupee', code: 'SCR' },
    { name: 'Sierra Leonean Leone', code: 'SLL' },
    { name: 'Singapore Dollar', code: 'SGD' },
    { name: 'Slovak Koruna', code: 'SKK' },
    { name: 'Solomon Islands Dollar', code: 'SBD' },
    { name: 'Somali Shilling', code: 'SOS' },
    { name: 'South African Rand', code: 'ZAR' },
    { name: 'South Korean Won', code: 'KRW' },
    { name: 'South Sudanese Pound', code: 'SSP' },
    { name: 'Special Drawing Rights', code: 'XDR' },
    { name: 'Sri Lankan Rupee', code: 'LKR' },
    { name: 'St. Helena Pound', code: 'SHP' },
    { name: 'Sudanese Pound', code: 'SDG' },
    { name: 'Surinamese Dollar', code: 'SRD' },
    { name: 'Swazi Lilangeni', code: 'SZL' },
    { name: 'Swedish Krona', code: 'SEK' },
    { name: 'Swiss Franc', code: 'CHF' },
    { name: 'Syrian Pound', code: 'SYP' },
    { name: 'Tajikistani Somoni', code: 'TJS' },
    { name: 'Tanzanian Shilling', code: 'TZS' },
    { name: 'Thai Baht', code: 'THB' },
    { name: "Tongan Pa'anga", code: 'TOP' },
    { name: 'Trinidad & Tobago Dollar', code: 'TTD' },
    { name: 'Tunisian Dinar', code: 'TND' },
    { name: 'Turkish Lira', code: 'TRY' },
    { name: 'Turkmenistani Manat', code: 'TMT' },
    { name: 'Ugandan Shilling', code: 'UGX' },
    { name: 'Ukrainian Hryvnia', code: 'UAH' },
    { name: 'United Arab Emirates Dirham', code: 'AED' },
    { name: 'Uruguayan Peso', code: 'UYU' },
    { name: 'US Dollar', code: 'USD' },
    { name: 'Uzbekistan Som', code: 'UZS' },
    { name: 'Vanuatu Vatu', code: 'VUV' },
    { name: 'Venezuelan BolÃvar', code: 'VEF' },
    { name: 'Vietnamese Dong', code: 'VND' },
    { name: 'Yemeni Rial', code: 'YER' },
    { name: 'Zambian Kwacha', code: 'ZMK' },
    { name: 'Zimbabwean dollar', code: 'ZWL' },
];
export const contryCode = countries.map((country) => country.code);
