/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line no-unused-expressions */
import React from 'react';
import { Box } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { showNewOptionField } from '../Sourcing.styles';
import 'react-medium-image-zoom/dist/styles.css';

type OptionType = 'Size' | 'Color' | 'Material' | 'Style';

const NewOptionField = (props: any) => {
    const {
        formik,
        setErrorMessage,
        options,
        setOptions,
        usedOptions,
        setUsedOptions,
        selectedOptions,
        setShowNewOption,
    } = props;
    const updateNewOption = (e: SelectChangeEvent<String>) => {
        setErrorMessage('');
        const keyIndex = usedOptions.findIndex((el: string) => el === e.target.value);
        if (keyIndex < 0) {
            const newOptions = options;
            newOptions[e.target.value as OptionType].push('');
            setOptions({
                ...newOptions,
            });
            formik.setFieldValue('options', newOptions);

            setUsedOptions([...usedOptions, e.target.value]);
            setShowNewOption(false);
        } else {
            setErrorMessage(`${e.target.value} already exist`);
        }
    };
    return (
        <Box sx={showNewOptionField}>
            <ListIcon sx={{ color: 'textColor.variantColor' }} />
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label1">options</InputLabel>
                <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select1"
                    label="options"
                    name="options"
                    onChange={(e: SelectChangeEvent<String>) => updateNewOption(e)}
                >
                    {selectedOptions.map((option: string) => (
                        <MenuItem value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default NewOptionField;
