/* eslint-disable no-underscore-dangle */
import React from 'react';
import UserAvatar from 'components/avatar/UserAvatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { Box, IconButton, Typography } from '@mui/material';
// import { getCustomer } from 'services/customer';
// import { getCurrentUser } from 'utils/helpers/auth';
// import { AllUserProps } from 'pages/profileSettings/ProfileSettings.types';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'utils/helpers/auth';

const AvatarMenu: React.FC<{ name: string; isMedium?: boolean; isLarge?: boolean }> = ({ name, isMedium, isLarge }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const [customer, setCustomer] = useState<AllUserProps>();

    // const customerId = getCurrentUser()._id;
    // const getName = `${customer?.firstName} ${customer?.lastName}`;
    // const name = getName.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

    // useEffect(() => {
    //     getCustomer(customerId).then((res) => {
    //         setCustomer(res.data);
    //     });
    // }, []);

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <UserAvatar
                    label={name}
                    isCollapsed
                    image={getUser().profileImage}
                    isMedium={isMedium}
                    isLarge={isLarge}
                />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'primaryBackground',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Typography
                    variant="subtitle2"
                    sx={{ fontSize: '14px', textAlign: 'center', pt: 0.5, pb: 1, pr: 1, pl: 1 }}
                >
                    {name}
                </Typography>
                <Divider sx={{ mr: 2, ml: 2 }} />
                <MenuItem onClick={() => navigate('/settings')}>
                    <ListItemIcon>
                        <AccountCircleOutlinedIcon />
                    </ListItemIcon>
                    Profile Settings
                </MenuItem>
                <Box
                    onClick={() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        localStorage.removeItem('user');
                        localStorage.removeItem('storeId');
                        localStorage.removeItem('values');
                        localStorage.removeItem('newVariants');
                        navigate('/auth/login');
                    }}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <ExitToAppOutlinedIcon />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Box>
            </Menu>
        </>
    );
};

export default AvatarMenu;
