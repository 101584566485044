import axios, { AxiosResponse } from 'axios';
import { getBearerToken, isAuthenticated } from 'utils/helpers/auth';

const headersAxios = {};
if (isAuthenticated()) {
    const { headers } = await getBearerToken();
    Object.assign(headersAxios, { Authorization: headers.Authorization });
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: headersAxios,
});

const onFulfilled = (config: AxiosResponse<any, any>) => {
    return config;
};

const onRejected = (error: any) => {
    if (error && error?.response && error?.response?.status === 401) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('refreshToken');
        const baseURL = window.location.origin;
        window.location.assign(`${baseURL}/auth/login`);
        // Add tost toast to explain for customer the logout .
    }
    return Promise.reject(error);
};

axiosInstance.interceptors.response.use(onFulfilled, onRejected);

export default axiosInstance;
