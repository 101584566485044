/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Chip, Box, TextField, Typography, Alert, Tooltip } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import Checkbox from '@mui/material/Checkbox';
import Stepper from '@mui/material/Stepper';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import ProductImageUpload from 'components/productImageUpload/ProductImageUpload';
import CustomButton from 'components/customButton/CustomButton';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProductImageUploading from 'components/productImageUploading/ProductImageUploading';
import InputField from 'components/inputField/InputField';
import CustomCounter from 'components/counter/Counter';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomError from 'components/customError/CustomError';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'utils/axios/axios';
import { addVariantsValidation, basicInfoValidation } from 'utils/validations/productValidation';
import { getCombinations } from 'utils/helpers/product';
import { getProduct, updateProduct } from 'services/product';
import { countries, logistioCountries } from 'utils/countryList';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import { getCurrentUser } from 'utils/helpers/auth';
import AlertTitle from '@mui/material/AlertTitle';
import StockConfiguration from 'pages/productSourcing/LogistioBulk/StockConfiguration';
import { IVariant } from 'utils/Interfaces/variant.interface';
import toast from 'react-hot-toast';
import Layers from '../../assets/layers.svg';
import {
    addNewSelectOption,
    addVariantQuantityDescription,
    addVariantQuantityError,
    addVariantQuantityWrapper,
    addVariantWrapper,
    basicInfoWrapper,
    deleteSelectOption,
    footerButtonsStyle,
    footerContainer,
    footerWrapper,
    newOptionSelectContainer,
    newOptionSelectWrapper,
    optionFieldError,
    optionFieldsContainer,
    optionFieldsWrapper,
    optionInputField,
    optionSelect,
    optionStyle,
    productDesignContainer,
    productDesignImagesContainer,
    productDesignWrapper,
    selectOptionFieldMode,
    showNewOptionField,
    sourcingProcessGridStyle,
    sourcingProcessStepDescriptionStyle,
    sourcingProcessStepLabel,
    sourcingProcessStepLabelStyle,
    sourcingProcessStepperStyle,
    sourcingProcessSubtitles,
    sourcingProcessWrapper,
    variantsTableHeadWrapper,
    variantsTableRows,
    variantKeyMode,
    variantDeleteIcon,
} from '../productSourcing/Sourcing.styles';

const EditProduct = () => {
    const navigate = useNavigate();
    const location: any = useLocation();
    const [allImages, setAllImages] = useState<any>([]);
    const [validate, setValidate] = useState<Yup.AnyObjectSchema>();
    const [steps, setSteps] = useState<{ label: string; description: string }[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const collapsed = useOutletContext();

    const [_progressCount, setProgressCount] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [variants, _setVariants] = useState<any>([]);
    const [displayError, setDisplayError] = useState<any>();
    const [showNewOption, setShowNewOption] = useState(false);
    const [checkVariants, setCheckVariants] = useState(false);
    const [currentProduct, setCurrentProduct] = useState<any>();

    const [options, setOptions] = useState<{ Size: string[]; Color: string[]; Material: string[]; Style: string[] }>({
        Size: [],
        Color: [],
        Material: [],
        Style: [],
    });

    const [selectedOptions, _setSelectedOptions] = useState(['Size', 'Color', 'Material', 'Style']);
    const [keys, setKeys] = useState<any[]>([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [allCombinations, setAllCombinations] = useState<string[]>([]);
    const [newVariants, setNewVariants] = useState<any>();
    const [variantErrors, setVariantErrors] = useState<any>([]);
    const [stockConfigErrors, setStockConfigErrors] = useState<string[]>([]);
    const [validStockConfig, setValidStockConfig] = useState(false);

    useEffect(() => {
        getProduct(location.state.productId)
            .then((res) => {
                setCurrentProduct(res.data.product);
                /**
                 * 🏷️ If the product type is `logistio bulk`
                 * we need to add a stock configuration step
                 */
                if (res.data.product.type === 3) {
                    setSteps([
                        {
                            label: 'Step 1',
                            description: 'Basic Information',
                        },
                        {
                            label: 'Step 2',
                            description: `Variants`,
                        },
                        {
                            label: 'Step 3',
                            description: `Stock configuration`,
                        },
                        {
                            label: 'Step 4',
                            description: 'Product Design',
                        },
                    ]);
                } else {
                    setSteps([
                        {
                            label: 'Step 1',
                            description: 'Basic Information',
                        },
                        {
                            label: 'Step 2',
                            description: `Variants`,
                        },
                        {
                            label: 'Step 3',
                            description: 'Product Design',
                        },
                    ]);
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    }, []);
    useEffect(() => {
        if (currentProduct) {
            setQuantity(currentProduct.quantity);
            setAllImages(currentProduct.design);
            if (currentProduct.options) {
                if (currentProduct.quantity > 0) setCheckVariants(false);
                else {
                    setCheckVariants(true);
                }
                const currentOptions = { ...currentProduct.options };
                const newCurrentOptions = { ...currentOptions };
                Object.keys(currentOptions).map((option) => {
                    if (currentOptions[option].length === 0) {
                        delete newCurrentOptions[option];
                    }
                });
                setOptions(newCurrentOptions);
                setKeys(Object.keys(newCurrentOptions));
                setNewVariants(() => {
                    // 1. get the variants prop in the expected shap
                    const variants: { [key: string]: { quantity: string; disabled: false } } = {};
                    if (currentProduct.variants.length > 0) {
                        currentProduct.variants.forEach((variant: IVariant) => {
                            variants[variant.name] = {
                                quantity: variant.quantity!.toString(),
                                disabled: false,
                            };
                        });
                    }
                    return variants;
                });
            }
        }
    }, [currentProduct]);
    const titleSwitch = (title: any) => {
        switch (title) {
            case 'dropshipping':
                DynamicTitle('DropShipping Sourcing');
                break;
            case 'logistioBulk':
                DynamicTitle('Logistio Bulk Sourcing');
                break;
            case 'bulk':
                DynamicTitle('Bulk Sourcing');
                break;
            default:
                DynamicTitle('');
        }
    };

    titleSwitch(location.state.sourcing);

    const calculate = () => {
        const arr = { ...options };
        const x = Object.keys(arr)
            .map((key) => {
                if (arr[key as 'Size' | 'Color' | 'Material' | 'Style']?.length) {
                    const a = arr[key as 'Size' | 'Color' | 'Material' | 'Style'];
                    const h = a.filter((e: any) => e && e.length);
                    return h;
                }
            })
            .filter((e: any) => e && e.length);

        setAllCombinations(getCombinations(x));
    };
    useEffect(() => {
        const data: any = {};
        allCombinations &&
            allCombinations.map((combination, index) => {
                data[combination as string] = {
                    quantity: currentProduct.variants[index]?.quantity ? currentProduct.variants[index].quantity : 0,
                    disabled: false,
                    name: combination,
                    id: currentProduct.variants[index] ? currentProduct.variants[index]._id : '',
                    product: currentProduct._id,
                    customer: getCurrentUser()._id,
                };
            });
        const err = [];
        if (currentProduct && currentProduct.type !== 1) {
            for (let i = 0; i < Object.keys(data).length; i++) {
                if (data[Object.keys(data)[i]].quantity === 0) err[i] = '*Quantity must be > 0';
            }
            setVariantErrors(err);
        }

        setNewVariants({ ...data });
    }, [allCombinations]);

    const [editMode, setEditMode] = useState<any>([]);

    useEffect(() => {
        calculate();
    }, [options]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckVariants(event.target.checked);
        // setNewVariants({});
        // setOptions({
        //     Size: [],
        //     Color: [],
        //     Material: [],
        //     Style: [],
        // });
    };
    const shippingToArrayCreator = (shippingToArray: any) => {
        const newArray = shippingToArray[0].split(',');
        newArray.forEach((country: string, index: number) => {
            newArray[index] = {
                code: '',
                label: country,
                phone: '',
            };
        });
        return newArray;
    };
    useEffect(() => {
        if (activeStep === 1) {
            if (quantity > 0) setDisplayError(false);
            if (newVariants) {
                if (Object.keys(newVariants).length > 0) {
                    setDisplayError(false);
                    setQuantity(0);
                } else if (activeStep === 1 && quantity === 0 && Object.keys(newVariants).length === 0)
                    setDisplayError(true);
            }
        }
    }, [options, quantity, newVariants]);

    return (
        <Box sx={sourcingProcessWrapper}>
            {currentProduct && currentProduct.status === 1 ? (
                <Formik
                    initialValues={{
                        productName: currentProduct.name,
                        productLink: currentProduct.refrenceLink,
                        description: currentProduct.description,
                        address: currentProduct.address ? currentProduct.address : '',
                        city: currentProduct.city ? currentProduct.city : '',
                        state: currentProduct.state ? currentProduct.state : '',
                        zipCode: currentProduct.zipCode ? currentProduct.zipCode : '',
                        country: currentProduct.country ? currentProduct.country : '',
                        photo: '',
                        type: currentProduct.type ? currentProduct.type : '',
                        shippingTo:
                            (currentProduct?.shippingTo?.[0].includes(',')
                                ? currentProduct?.shippingTo?.[0]?.split(',')
                                : currentProduct?.shippingTo
                            )?.map((elt: any) => {
                                return logistioCountries.find((country: any) => country.label === elt);
                            }) || [],
                        size: '',
                        color: '',
                        quantity: currentProduct.quantity ? currentProduct.quantity : 0,
                        options: currentProduct.options
                            ? currentProduct.options
                            : {
                                  Size: [],
                                  Color: [],
                                  Material: [],
                                  Style: [],
                              },
                        stockConfig: currentProduct.stockConfigs as {
                            warehouseId: string;
                            variant?: string;
                            quantity: string;
                            country: 'United Arab Emirates' | 'Qatar' | 'Kuwait' | 'Saudi Arabia' | '';
                        }[],
                    }}
                    onSubmit={(values) => {
                        if (activeStep === 0) {
                            setActiveStep((s) => s + 1);
                        } else if (activeStep === 1 || (activeStep === 2 && currentProduct.type === 3)) {
                            if (
                                variantErrors.filter((value: any) => value === '' || value === undefined).length ===
                                variantErrors.length
                            ) {
                                setActiveStep((s) => s + 1);
                            }
                        } else if (activeStep === steps.length - 1) {
                            const formData = new FormData();
                            formData.append('name', values.productName);
                            allImages.map((file: any) => {
                                if (file.name) {
                                    return formData.append(file.name, file.file);
                                }
                            });
                            const oldImages = allImages.filter((file: any) => !file.name);
                            formData.append('design', JSON.stringify(oldImages));
                            formData.append('variants', JSON.stringify(newVariants));
                            formData.append('description', values.description);
                            formData.append('quantity', String(quantity));
                            formData.append('refrenceLink', values.productLink);
                            formData.append('shippingTo', values.shippingTo.map((obj: any) => obj.label).join(','));
                            formData.append(
                                'type',
                                currentProduct.type === 1 ? '1' : currentProduct.type === 2 ? '2' : '3',
                            );
                            // Add stock config if logistio bulk
                            if (currentProduct.type === 3) {
                                // Make sure we filter out configs containing empty fields
                                const stockConfigData: {
                                    warehouseId: string;
                                    variant?: string;
                                    quantity: string;
                                    country: 'United Arab Emirates' | 'Qatar' | 'Kuwait' | 'Saudi Arabia' | '';
                                }[] = [];
                                values.stockConfig.forEach((config) => {
                                    if (Object.values(config).every((field) => !!field)) {
                                        stockConfigData.push(config);
                                    }
                                });
                                formData.append('stockConfigs', JSON.stringify(stockConfigData));
                            }
                            formData.append('options', JSON.stringify(options));
                            if (currentProduct.type === 2) {
                                formData.append('city', values.city);
                                formData.append('province', values.state);
                                formData.append('zip', values.zipCode);
                                formData.append('address', values.address);
                            }
                            updateProduct({ id: currentProduct._id, updatedData: formData })
                                .then(() => {
                                    toast.success('Product Added Succefully');
                                })
                                .catch((err) => {
                                    toast.error(err?.response?.data?.errors?.message);
                                });
                        }
                    }}
                    validationSchema={validate}
                >
                    {(formik) => (
                        <Form>
                            <Grid container spacing={2} sx={sourcingProcessGridStyle}>
                                <Grid item xs={12} lg={2.5}>
                                    <CustomCard
                                        body={
                                            <Box>
                                                <Box sx={sourcingProcessSubtitles}>Progress</Box>
                                                <Stepper
                                                    activeStep={activeStep}
                                                    orientation="vertical"
                                                    sx={sourcingProcessStepperStyle}
                                                >
                                                    {steps.map((step) => (
                                                        <Step key={step.label}>
                                                            <StepLabel sx={sourcingProcessStepLabel}>
                                                                <Box sx={sourcingProcessStepLabelStyle}>
                                                                    {step.label}
                                                                </Box>
                                                                <Box sx={sourcingProcessStepDescriptionStyle}>
                                                                    {step.description}
                                                                </Box>
                                                            </StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </Box>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} lg={9.5}>
                                    {activeStep === 0 ? (
                                        <CustomCard
                                            body={
                                                <Box sx={basicInfoWrapper}>
                                                    <Box sx={sourcingProcessSubtitles}>Basic Information</Box>
                                                    <Box>
                                                        <InputField
                                                            label="Product Name"
                                                            placeholder="Product Name"
                                                            {...formik.getFieldProps('productName')}
                                                            name="productName"
                                                        />

                                                        <CustomError name="productName" component="Box" />
                                                    </Box>

                                                    <Box>
                                                        <InputField
                                                            label="Product Link"
                                                            placeholder="Product Link"
                                                            {...formik.getFieldProps('productLink')}
                                                            name="productLink"
                                                        />
                                                        <CustomError name="productLink" component="Box" />
                                                    </Box>

                                                    {currentProduct.type === 3 ? (
                                                        <Box>
                                                            <Autocomplete
                                                                multiple
                                                                id="fixed-tags-demo"
                                                                {...formik.getFieldProps('shippingTo')}
                                                                onChange={(_, newValue) => {
                                                                    formik.setFieldValue('shippingTo', newValue);
                                                                }}
                                                                options={logistioCountries}
                                                                getOptionLabel={(option) => option.label}
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            label={option.label}
                                                                            {...getTagProps({ index })}
                                                                        />
                                                                    ))
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="sShipping to"
                                                                        placeholder="Shipping to"
                                                                    />
                                                                )}
                                                            />
                                                            <Box>
                                                                <CustomError name="shippingTo" component="Box" />
                                                            </Box>
                                                        </Box>
                                                    ) : currentProduct.type === 2 ? (
                                                        <Box>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} lg={12}>
                                                                    <InputField
                                                                        label="Shipping Address"
                                                                        placeholder="Address"
                                                                        {...formik.getFieldProps('address')}
                                                                        name="address"
                                                                    />
                                                                    <CustomError name="address" component="Box" />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} lg={3}>
                                                                    <InputField
                                                                        label="City"
                                                                        placeholder="City"
                                                                        {...formik.getFieldProps('city')}
                                                                        name="city"
                                                                    />
                                                                    <CustomError name="city" component="Box" />
                                                                </Grid>
                                                                <Grid item xs={12} lg={3}>
                                                                    <InputField
                                                                        label="State"
                                                                        placeholder="State"
                                                                        {...formik.getFieldProps('state')}
                                                                        name="state"
                                                                    />
                                                                    <CustomError name="state" component="Box" />
                                                                </Grid>
                                                                <Grid item xs={12} lg={3}>
                                                                    <InputField
                                                                        label="Zip Code"
                                                                        placeholder="Code"
                                                                        {...formik.getFieldProps('zipCode')}
                                                                        name="zipCode"
                                                                    />
                                                                    <CustomError name="zipCode" component="Box" />
                                                                </Grid>
                                                                <Grid item xs={12} lg={3}>
                                                                    <Typography variant="body2">
                                                                        Shipping Country
                                                                    </Typography>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="fixed-tags-demo"
                                                                        {...formik.getFieldProps('shippingTo')}
                                                                        onChange={(_, newValue) => {
                                                                            formik.setFieldValue(
                                                                                'shippingTo',
                                                                                newValue,
                                                                            );
                                                                        }}
                                                                        options={countries}
                                                                        getOptionLabel={(option) => option.label}
                                                                        renderOption={(props, option) => (
                                                                            <Box
                                                                                component="li"
                                                                                sx={{
                                                                                    '& > img': { mr: 2, flexShrink: 0 },
                                                                                }}
                                                                                {...props}
                                                                            >
                                                                                <img
                                                                                    loading="lazy"
                                                                                    width="20"
                                                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                                    alt=""
                                                                                />
                                                                                {option.label} ({option.code}) +
                                                                                {option.phone}
                                                                            </Box>
                                                                        )}
                                                                        renderTags={(tagValue, getTagProps) =>
                                                                            tagValue.map((option, index) => (
                                                                                <Chip
                                                                                    label={option.label}
                                                                                    {...getTagProps({ index })}
                                                                                />
                                                                            ))
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="shipping to"
                                                                                placeholder="Shipping to"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <CustomError name="shippingTo" component="Box" />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <Autocomplete
                                                                multiple
                                                                id="fixed-tags-demo"
                                                                {...formik.getFieldProps('shippingTo')}
                                                                onChange={(_, newValue) => {
                                                                    formik.setFieldValue('shippingTo', newValue);
                                                                }}
                                                                options={countries}
                                                                getOptionLabel={(option) => option.label}
                                                                renderOption={(props, option) => (
                                                                    <Box
                                                                        component="li"
                                                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                                        {...props}
                                                                    >
                                                                        <img
                                                                            loading="lazy"
                                                                            width="20"
                                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                            alt=""
                                                                        />
                                                                        {option.label} ({option.code}) +{option.phone}
                                                                    </Box>
                                                                )}
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            label={option.label}
                                                                            {...getTagProps({ index })}
                                                                        />
                                                                    ))
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Shipping to"
                                                                        placeholder="Shipping to"
                                                                    />
                                                                )}
                                                            />
                                                            <Box>
                                                                <CustomError name="shippingTo" component="Box" />
                                                            </Box>
                                                        </Box>
                                                    )}

                                                    <Box>
                                                        <InputField
                                                            label="Description"
                                                            placeholder="Description"
                                                            multiline
                                                            {...formik.getFieldProps('description')}
                                                            name="description"
                                                        />
                                                        <CustomError name="description" component="Box" />
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    ) : activeStep === 1 ? (
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={currentProduct.type !== 1 ? 9 : 12}>
                                                    {checkVariants === false ? (
                                                        <Box>
                                                            <Typography variant="body1">
                                                                This product has no variants
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '10px',
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={checkVariants}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />{' '}
                                                                <Typography variant="body1">
                                                                    This product has options, such as size or color or
                                                                    material ...
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <CustomCard
                                                            body={
                                                                <Box sx={addVariantWrapper}>
                                                                    <Box sx={sourcingProcessSubtitles}>Add Variant</Box>

                                                                    <Box>
                                                                        <Box>
                                                                            {!options.Size &&
                                                                            !options.Color &&
                                                                            !options.Material &&
                                                                            !options.Style ? (
                                                                                <Box sx={optionSelect}>
                                                                                    <ListIcon
                                                                                        sx={{
                                                                                            color: 'textColor.variantColor',
                                                                                        }}
                                                                                    />
                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel id="demo-simple-select-label">
                                                                                            options
                                                                                        </InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-label"
                                                                                            id="demo-simple-select"
                                                                                            label="options"
                                                                                            placeholder="options"
                                                                                            value={keys[0]}
                                                                                            onChange={(e) => {
                                                                                                const keyIndex =
                                                                                                    keys.findIndex(
                                                                                                        (el) =>
                                                                                                            el ===
                                                                                                            e.target
                                                                                                                .value,
                                                                                                    );
                                                                                                if (keyIndex < 0) {
                                                                                                    const newOptions =
                                                                                                        options;
                                                                                                    newOptions[
                                                                                                        e.target
                                                                                                            .value as
                                                                                                            | 'Size'
                                                                                                            | 'Color'
                                                                                                            | 'Material'
                                                                                                            | 'Style'
                                                                                                    ] = [];
                                                                                                    newOptions[
                                                                                                        e.target
                                                                                                            .value as
                                                                                                            | 'Size'
                                                                                                            | 'Color'
                                                                                                            | 'Material'
                                                                                                            | 'Style'
                                                                                                    ].push('');
                                                                                                    setOptions({
                                                                                                        ...newOptions,
                                                                                                    });
                                                                                                    formik.setFieldValue(
                                                                                                        'options',
                                                                                                        newOptions,
                                                                                                    );

                                                                                                    setKeys([
                                                                                                        ...keys,
                                                                                                        e.target.value,
                                                                                                    ]);
                                                                                                }
                                                                                            }}
                                                                                            name="here"
                                                                                        >
                                                                                            {selectedOptions.map(
                                                                                                (option) => (
                                                                                                    <MenuItem
                                                                                                        value={option}
                                                                                                    >
                                                                                                        {option}
                                                                                                    </MenuItem>
                                                                                                ),
                                                                                            )}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            ) : (
                                                                                keys.map((option, index) => {
                                                                                    return options[
                                                                                        option as
                                                                                            | 'Size'
                                                                                            | 'Color'
                                                                                            | 'Material'
                                                                                            | 'Style'
                                                                                    ].length ? (
                                                                                        <Box
                                                                                            sx={newOptionSelectWrapper}
                                                                                        >
                                                                                            <Box
                                                                                                sx={
                                                                                                    newOptionSelectContainer
                                                                                                }
                                                                                            >
                                                                                                <ListIcon
                                                                                                    sx={{
                                                                                                        color: 'textColor.variantColor',
                                                                                                    }}
                                                                                                />
                                                                                                {editMode[index] &&
                                                                                                editMode[index] ===
                                                                                                    true ? (
                                                                                                    <Box
                                                                                                        sx={optionStyle}
                                                                                                    >
                                                                                                        {option}
                                                                                                    </Box>
                                                                                                ) : (
                                                                                                    <FormControl
                                                                                                        fullWidth
                                                                                                    >
                                                                                                        <InputLabel id="demo-simple-select-label">
                                                                                                            options
                                                                                                        </InputLabel>
                                                                                                        <Select
                                                                                                            labelId="demo-simple-select-label"
                                                                                                            id="demo-simple-select"
                                                                                                            label="options"
                                                                                                            placeholder="options"
                                                                                                            {...formik.getFieldProps(
                                                                                                                'options',
                                                                                                            )}
                                                                                                            value={
                                                                                                                option
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e,
                                                                                                            ) => {
                                                                                                                setErrorMessage(
                                                                                                                    '',
                                                                                                                );
                                                                                                                const i =
                                                                                                                    keys.findIndex(
                                                                                                                        (
                                                                                                                            el,
                                                                                                                        ) =>
                                                                                                                            el ===
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                    );
                                                                                                                if (
                                                                                                                    i <
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    const newOptions =
                                                                                                                        options;
                                                                                                                    newOptions[
                                                                                                                        e
                                                                                                                            .target
                                                                                                                            .value as
                                                                                                                            | 'Size'
                                                                                                                            | 'Color'
                                                                                                                            | 'Material'
                                                                                                                            | 'Style'
                                                                                                                    ] =
                                                                                                                        newOptions[
                                                                                                                            option as
                                                                                                                                | 'Size'
                                                                                                                                | 'Color'
                                                                                                                                | 'Material'
                                                                                                                                | 'Style'
                                                                                                                        ];
                                                                                                                    newOptions[
                                                                                                                        option as
                                                                                                                            | 'Size'
                                                                                                                            | 'Color'
                                                                                                                            | 'Material'
                                                                                                                            | 'Style'
                                                                                                                    ] =
                                                                                                                        [];

                                                                                                                    setOptions(
                                                                                                                        {
                                                                                                                            ...newOptions,
                                                                                                                        },
                                                                                                                    );

                                                                                                                    const k =
                                                                                                                        keys;
                                                                                                                    const iOption =
                                                                                                                        keys.findIndex(
                                                                                                                            (
                                                                                                                                el,
                                                                                                                            ) =>
                                                                                                                                el ===
                                                                                                                                option,
                                                                                                                        );
                                                                                                                    iOption >
                                                                                                                    -1
                                                                                                                        ? (k[
                                                                                                                              iOption
                                                                                                                          ] =
                                                                                                                              e.target.value)
                                                                                                                        : null;
                                                                                                                    setKeys(
                                                                                                                        [
                                                                                                                            ...keys,
                                                                                                                        ],
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    setErrorMessage(
                                                                                                                        `${e.target.value} already exists`,
                                                                                                                    );
                                                                                                                }
                                                                                                                //
                                                                                                            }}
                                                                                                            name="options"
                                                                                                        >
                                                                                                            {selectedOptions.map(
                                                                                                                (
                                                                                                                    option,
                                                                                                                ) => (
                                                                                                                    <MenuItem
                                                                                                                        value={
                                                                                                                            option
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            option
                                                                                                                        }
                                                                                                                    </MenuItem>
                                                                                                                ),
                                                                                                            )}
                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                )}

                                                                                                {option &&
                                                                                                    editMode[index] !==
                                                                                                        false && (
                                                                                                        <Box
                                                                                                            sx={
                                                                                                                deleteSelectOption
                                                                                                            }
                                                                                                        >
                                                                                                            <Tooltip title="Delete Variant">
                                                                                                                <DeleteForeverIcon
                                                                                                                    sx={{
                                                                                                                        color: 'textColor.errorColor',
                                                                                                                    }}
                                                                                                                    onClick={() => {
                                                                                                                        const o =
                                                                                                                            options;
                                                                                                                        o[
                                                                                                                            option as
                                                                                                                                | 'Size'
                                                                                                                                | 'Color'
                                                                                                                                | 'Material'
                                                                                                                                | 'Style'
                                                                                                                        ] =
                                                                                                                            [];
                                                                                                                        setOptions(
                                                                                                                            {
                                                                                                                                ...o,
                                                                                                                            },
                                                                                                                        );
                                                                                                                        const k =
                                                                                                                            keys;
                                                                                                                        k.splice(
                                                                                                                            index,
                                                                                                                            1,
                                                                                                                        );
                                                                                                                        setKeys(
                                                                                                                            [
                                                                                                                                ...k,
                                                                                                                            ],
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                        </Box>
                                                                                                    )}
                                                                                            </Box>
                                                                                            <Box
                                                                                                sx={optionFieldsWrapper(
                                                                                                    editMode,
                                                                                                    index,
                                                                                                )}
                                                                                            >
                                                                                                {options[
                                                                                                    option as
                                                                                                        | 'Size'
                                                                                                        | 'Color'
                                                                                                        | 'Material'
                                                                                                        | 'Style'
                                                                                                ].map(
                                                                                                    (variant, ind) => {
                                                                                                        return (
                                                                                                            <Box>
                                                                                                                {editMode[
                                                                                                                    index
                                                                                                                ] &&
                                                                                                                editMode[
                                                                                                                    index
                                                                                                                ] ===
                                                                                                                    true ? (
                                                                                                                    variant !==
                                                                                                                        '' && (
                                                                                                                        <Chip
                                                                                                                            label={
                                                                                                                                variant
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )
                                                                                                                ) : (
                                                                                                                    <Box
                                                                                                                        sx={
                                                                                                                            optionFieldsContainer
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <AppsIcon
                                                                                                                            sx={{
                                                                                                                                color: 'textColor.variantColor',
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <Box
                                                                                                                            sx={
                                                                                                                                optionInputField
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <InputField
                                                                                                                                defaultValue={
                                                                                                                                    variant
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    variant
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    e,
                                                                                                                                ) => {
                                                                                                                                    const newOpt =
                                                                                                                                        options;
                                                                                                                                    newOpt[
                                                                                                                                        option as
                                                                                                                                            | 'Size'
                                                                                                                                            | 'Color'
                                                                                                                                            | 'Material'
                                                                                                                                            | 'Style'
                                                                                                                                    ][
                                                                                                                                        ind
                                                                                                                                    ] =
                                                                                                                                        e.target.value;
                                                                                                                                    setOptions(
                                                                                                                                        {
                                                                                                                                            ...newOpt,
                                                                                                                                        },
                                                                                                                                    );
                                                                                                                                    formik.setFieldValue(
                                                                                                                                        'options',
                                                                                                                                        newOpt,
                                                                                                                                    );
                                                                                                                                    if (
                                                                                                                                        options[
                                                                                                                                            option as
                                                                                                                                                | 'Size'
                                                                                                                                                | 'Color'
                                                                                                                                                | 'Material'
                                                                                                                                                | 'Style'
                                                                                                                                        ][
                                                                                                                                            options[
                                                                                                                                                option as
                                                                                                                                                    | 'Size'
                                                                                                                                                    | 'Color'
                                                                                                                                                    | 'Material'
                                                                                                                                                    | 'Style'
                                                                                                                                            ]
                                                                                                                                                .length -
                                                                                                                                                1
                                                                                                                                        ] &&
                                                                                                                                        options[
                                                                                                                                            option as
                                                                                                                                                | 'Size'
                                                                                                                                                | 'Color'
                                                                                                                                                | 'Material'
                                                                                                                                                | 'Style'
                                                                                                                                        ][
                                                                                                                                            options[
                                                                                                                                                option as
                                                                                                                                                    | 'Size'
                                                                                                                                                    | 'Color'
                                                                                                                                                    | 'Material'
                                                                                                                                                    | 'Style'
                                                                                                                                            ]
                                                                                                                                                .length -
                                                                                                                                                1
                                                                                                                                        ]
                                                                                                                                            .length
                                                                                                                                    ) {
                                                                                                                                        const newOptions =
                                                                                                                                            options;
                                                                                                                                        newOptions[
                                                                                                                                            option as
                                                                                                                                                | 'Size'
                                                                                                                                                | 'Color'
                                                                                                                                                | 'Material'
                                                                                                                                                | 'Style'
                                                                                                                                        ].push(
                                                                                                                                            '',
                                                                                                                                        );
                                                                                                                                        setOptions(
                                                                                                                                            {
                                                                                                                                                ...newOptions,
                                                                                                                                            },
                                                                                                                                        );
                                                                                                                                        formik.setFieldValue(
                                                                                                                                            'options',
                                                                                                                                            newOptions,
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </Box>
                                                                                                                        {variant !==
                                                                                                                            '' && (
                                                                                                                            <Box
                                                                                                                                sx={
                                                                                                                                    deleteSelectOption
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Tooltip title="Delete Option">
                                                                                                                                    <DeleteForeverIcon
                                                                                                                                        sx={{
                                                                                                                                            color: 'textColor.errorColor',
                                                                                                                                        }}
                                                                                                                                        onClick={() => {
                                                                                                                                            const o =
                                                                                                                                                options;
                                                                                                                                            o[
                                                                                                                                                option as
                                                                                                                                                    | 'Size'
                                                                                                                                                    | 'Color'
                                                                                                                                                    | 'Material'
                                                                                                                                                    | 'Style'
                                                                                                                                            ].splice(
                                                                                                                                                ind,
                                                                                                                                                1,
                                                                                                                                            );
                                                                                                                                            setOptions(
                                                                                                                                                {
                                                                                                                                                    ...o,
                                                                                                                                                },
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Tooltip>
                                                                                                                            </Box>
                                                                                                                        )}
                                                                                                                    </Box>
                                                                                                                )}
                                                                                                            </Box>
                                                                                                        );
                                                                                                    },
                                                                                                )}
                                                                                            </Box>

                                                                                            <Box
                                                                                                sx={
                                                                                                    selectOptionFieldMode
                                                                                                }
                                                                                            >
                                                                                                <CustomButton
                                                                                                    sizeType="small"
                                                                                                    styleType="secondary"
                                                                                                    label={
                                                                                                        editMode[
                                                                                                            index
                                                                                                        ] &&
                                                                                                        editMode[
                                                                                                            index
                                                                                                        ] === true
                                                                                                            ? 'edit'
                                                                                                            : 'done'
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        const x =
                                                                                                            editMode;
                                                                                                        editMode[
                                                                                                            index
                                                                                                        ] &&
                                                                                                        editMode[
                                                                                                            index
                                                                                                        ] === true
                                                                                                            ? (x[
                                                                                                                  index
                                                                                                              ] = false)
                                                                                                            : (x[
                                                                                                                  index
                                                                                                              ] = true);
                                                                                                        setEditMode([
                                                                                                            ...x,
                                                                                                        ]);
                                                                                                    }}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    ) : null;
                                                                                })
                                                                            )}
                                                                        </Box>

                                                                        {showNewOption && (
                                                                            <Box sx={showNewOptionField}>
                                                                                <ListIcon
                                                                                    sx={{
                                                                                        color: 'textColor.variantColor',
                                                                                    }}
                                                                                />
                                                                                <FormControl fullWidth>
                                                                                    <InputLabel id="demo-simple-select-label1">
                                                                                        options
                                                                                    </InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label1"
                                                                                        id="demo-simple-select1"
                                                                                        label="options"
                                                                                        name="options"
                                                                                        onChange={(e) => {
                                                                                            setErrorMessage('');
                                                                                            const keyIndex =
                                                                                                keys.findIndex(
                                                                                                    (el) =>
                                                                                                        el ===
                                                                                                        e.target.value,
                                                                                                );
                                                                                            if (keyIndex < 0) {
                                                                                                const newOptions =
                                                                                                    options;
                                                                                                newOptions[
                                                                                                    e.target.value as
                                                                                                        | 'Size'
                                                                                                        | 'Color'
                                                                                                        | 'Material'
                                                                                                        | 'Style'
                                                                                                ] = [];
                                                                                                newOptions[
                                                                                                    e.target.value as
                                                                                                        | 'Size'
                                                                                                        | 'Color'
                                                                                                        | 'Material'
                                                                                                        | 'Style'
                                                                                                ].push('');
                                                                                                setOptions({
                                                                                                    ...newOptions,
                                                                                                });
                                                                                                formik.setFieldValue(
                                                                                                    'options',
                                                                                                    newOptions,
                                                                                                );

                                                                                                setKeys([
                                                                                                    ...keys,
                                                                                                    e.target.value,
                                                                                                ]);
                                                                                                setShowNewOption(false);
                                                                                            } else {
                                                                                                setErrorMessage(
                                                                                                    `${e.target.value} already exist`,
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {selectedOptions.map(
                                                                                            (option) => (
                                                                                                <MenuItem
                                                                                                    value={option}
                                                                                                >
                                                                                                    {option}
                                                                                                </MenuItem>
                                                                                            ),
                                                                                        )}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                        <Box sx={optionFieldError}>{errorMessage}</Box>
                                                                        <Box
                                                                            sx={addNewSelectOption}
                                                                            onClick={() => {
                                                                                setShowNewOption(true);
                                                                            }}
                                                                        >
                                                                            <PostAddIcon />{' '}
                                                                            <Box> Add another option </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                {currentProduct.type !== 1 && (
                                                    <Grid item xs={12} lg={3}>
                                                        <CustomCard
                                                            body={
                                                                <Box sx={addVariantQuantityWrapper}>
                                                                    <Box>
                                                                        <img src={Layers} alt="" />
                                                                    </Box>
                                                                    <Box sx={addVariantQuantityDescription}>
                                                                        If you don’t have variants for this product,
                                                                        Please specify the total quantity.
                                                                    </Box>
                                                                    <CustomCounter
                                                                        quantity={quantity}
                                                                        setQuantity={setQuantity}
                                                                    />
                                                                    {displayError && (
                                                                        <Box sx={addVariantQuantityError}>
                                                                            Please specify how many Items you want to
                                                                            source or add some variants.
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {/* heeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeere */}
                                            {Object.keys(newVariants).length > 0 && (
                                                <CustomCard
                                                    body={
                                                        <Box>
                                                            <Box sx={variantsTableHeadWrapper}>
                                                                <Box>Index</Box>
                                                                <Box>Variants</Box>
                                                                <Box>{currentProduct.type !== 1 && 'Quantity'}</Box>
                                                                <Box>Action</Box>
                                                            </Box>
                                                            {Object.keys(newVariants).map((key: string, index: any) => {
                                                                return (
                                                                    <Box sx={variantsTableRows(newVariants, key)}>
                                                                        <Box sx={variantKeyMode(newVariants, key)}>
                                                                            Variant&nbsp;{index + 1}
                                                                        </Box>

                                                                        <Box sx={variantKeyMode(newVariants, key)}>
                                                                            {key}
                                                                        </Box>

                                                                        {currentProduct.type !== 1 ? (
                                                                            Object.keys(newVariants[key as string]).map(
                                                                                (k: string) => {
                                                                                    // AHAWA
                                                                                    // eslint-disable-next-line eqeqeq
                                                                                    if (k == 'quantity')
                                                                                        return (
                                                                                            <Box>
                                                                                                <InputField
                                                                                                    value={
                                                                                                        newVariants[
                                                                                                            key as string
                                                                                                        ][k as string]
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        const x =
                                                                                                            newVariants;
                                                                                                        x[
                                                                                                            key as string
                                                                                                        ][k as string] =
                                                                                                            e.target.value;
                                                                                                        setNewVariants({
                                                                                                            ...x,
                                                                                                        });
                                                                                                        if (
                                                                                                            currentProduct.type !==
                                                                                                            1
                                                                                                        ) {
                                                                                                            if (
                                                                                                                isNaN(
                                                                                                                    Number(
                                                                                                                        e
                                                                                                                            .target
                                                                                                                            .value,
                                                                                                                    ),
                                                                                                                )
                                                                                                            ) {
                                                                                                                const err =
                                                                                                                    [
                                                                                                                        ...variantErrors,
                                                                                                                    ];
                                                                                                                err[
                                                                                                                    index
                                                                                                                ] =
                                                                                                                    'Number field';
                                                                                                                setVariantErrors(
                                                                                                                    err,
                                                                                                                );
                                                                                                            } else if (
                                                                                                                e.target
                                                                                                                    .value
                                                                                                                    .length ===
                                                                                                                0
                                                                                                            ) {
                                                                                                                const err =
                                                                                                                    [
                                                                                                                        ...variantErrors,
                                                                                                                    ];
                                                                                                                err[
                                                                                                                    index
                                                                                                                ] =
                                                                                                                    'Required';
                                                                                                                setVariantErrors(
                                                                                                                    err,
                                                                                                                );
                                                                                                            } else if (
                                                                                                                Number(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value,
                                                                                                                ) === 0
                                                                                                            ) {
                                                                                                                const err =
                                                                                                                    [
                                                                                                                        ...variantErrors,
                                                                                                                    ];
                                                                                                                err[
                                                                                                                    index
                                                                                                                ] =
                                                                                                                    '*Quantity must be > 0';
                                                                                                                setVariantErrors(
                                                                                                                    err,
                                                                                                                );
                                                                                                            } else {
                                                                                                                const err =
                                                                                                                    [
                                                                                                                        ...variantErrors,
                                                                                                                    ];
                                                                                                                err[
                                                                                                                    index
                                                                                                                ] = '';
                                                                                                                setVariantErrors(
                                                                                                                    err,
                                                                                                                );
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        newVariants[
                                                                                                            key as string
                                                                                                        ].disabled ||
                                                                                                        currentProduct.type ===
                                                                                                            1
                                                                                                    }
                                                                                                />
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        color: 'textColor.errorColor',
                                                                                                        fontSize:
                                                                                                            '12px',
                                                                                                    }}
                                                                                                >
                                                                                                    {' '}
                                                                                                    {
                                                                                                        variantErrors[
                                                                                                            index
                                                                                                        ]
                                                                                                    }
                                                                                                </Box>
                                                                                            </Box>
                                                                                        );
                                                                                },
                                                                            )
                                                                        ) : (
                                                                            <Box />
                                                                        )}
                                                                        <Box sx={variantDeleteIcon}>
                                                                            {newVariants[key as string].disabled ? (
                                                                                <ReplayIcon
                                                                                    onClick={() => {
                                                                                        const h = newVariants;
                                                                                        h[key as string].disabled =
                                                                                            false;
                                                                                        setNewVariants({ ...h });
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Tooltip title="Delete Variant Row">
                                                                                    <DeleteForeverIcon
                                                                                        sx={{
                                                                                            color: 'textColor.errorColor',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            const h = newVariants;
                                                                                            h[key as string].disabled =
                                                                                                true;
                                                                                            setNewVariants({ ...h });
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    }
                                                    extraStyle={{ marginTop: '20px' }}
                                                />
                                            )}
                                        </Box>
                                    ) : activeStep === 2 && currentProduct.type === 3 ? (
                                        // If logistio bulk then we need to show the stock config step
                                        <StockConfiguration
                                            variants={newVariants}
                                            noVariantsQuantity={
                                                !Object.keys(newVariants).length &&
                                                (quantity || currentProduct.quantity)
                                            }
                                            errors={stockConfigErrors}
                                            setErrors={setStockConfigErrors}
                                            setValidStockConfig={setValidStockConfig}
                                            validStockConfigCheck={validStockConfig}
                                            shippingTo={formik?.getFieldProps('shippingTo')?.value}
                                        />
                                    ) : (
                                        <Box sx={productDesignWrapper}>
                                            <CustomCard
                                                body={
                                                    <Box>
                                                        <Box sx={sourcingProcessSubtitles}>Product Design</Box>
                                                        {allImages.length > 0 ? (
                                                            <Box sx={productDesignImagesContainer}>
                                                                {allImages.length > 0 &&
                                                                    allImages.map((image: any, index: number) => (
                                                                        <ProductImageUploading
                                                                            title={image.name ? image.name : ''}
                                                                            img={image.url ? image.url : image}
                                                                            imgName={image.name ? image.name : image}
                                                                            progress={100}
                                                                            index={index}
                                                                            allImages={allImages}
                                                                            setAllImages={setAllImages}
                                                                        />
                                                                    ))}
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <ProductImageUpload
                                                                    name="photo"
                                                                    onChange={(e) => {
                                                                        const file =
                                                                            e.target.files instanceof FileList &&
                                                                            e.target.files[0];
                                                                        if (file) {
                                                                            formik.setFieldValue('photo', file);
                                                                            const reader = new FileReader();
                                                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                                                            reader.onload = () => {
                                                                                setAllImages([
                                                                                    ...allImages,
                                                                                    {
                                                                                        file,
                                                                                        name: file.name,
                                                                                        url: URL.createObjectURL(file),
                                                                                    },
                                                                                ]);
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                            const formData = new FormData();
                                                                            formData.append(
                                                                                'image',
                                                                                URL.createObjectURL(file),
                                                                            );
                                                                            const config = {
                                                                                onUploadProgress: (
                                                                                    progressEvent: any,
                                                                                ) => {
                                                                                    const completed =
                                                                                        (progressEvent.loaded /
                                                                                            progressEvent.total) *
                                                                                        50;

                                                                                    setProgressCount(completed);
                                                                                },
                                                                                onDownloadProgress: (
                                                                                    progressEvent: any,
                                                                                ) => {
                                                                                    const progress =
                                                                                        50 +
                                                                                        (progressEvent.loaded /
                                                                                            progressEvent.total) *
                                                                                            50;

                                                                                    setProgressCount(progress);
                                                                                },
                                                                            };
                                                                            axios.post(
                                                                                'https://httpbin.org/post',
                                                                                formData,
                                                                                config,
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <Box>
                                                                    <CustomError name="photo" component="Box" />
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                }
                                                extraStyle={allImages.length > 0 && productDesignContainer}
                                            />
                                            {allImages.length > 0 && (
                                                <CustomCard
                                                    body={
                                                        <ProductImageUpload
                                                            onChange={(e) => {
                                                                const file =
                                                                    e.target.files instanceof FileList &&
                                                                    e.target.files[0];
                                                                if (file) {
                                                                    formik.setFieldValue('photo', file);
                                                                    const reader = new FileReader();
                                                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                                                    reader.onload = () => {
                                                                        setAllImages([
                                                                            ...allImages,
                                                                            {
                                                                                file,
                                                                                name: file.name,
                                                                                url: URL.createObjectURL(file),
                                                                            },
                                                                        ]);
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }
                                                            }}
                                                            userDisplayedImage={allImages}
                                                        />
                                                    }
                                                />
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                            <div className=" fixed bottom-0 right-[0px] left-[220px] p-[17px] ">
                                <Box sx={footerContainer}>
                                    <Box>
                                        <CustomButton
                                            sizeType="small"
                                            styleType="tertiary"
                                            label="Cancel"
                                            onClick={() => navigate('/products')}
                                        />
                                    </Box>
                                    <Box sx={footerButtonsStyle}>
                                        {activeStep > 0 && (
                                            <CustomButton
                                                sizeType="medium"
                                                styleType="primary"
                                                label="< Previous"
                                                onClick={() => activeStep > 0 && setActiveStep((s) => s - 1)}
                                            />
                                        )}
                                        <CustomButton
                                            type="submit"
                                            styleType="primary"
                                            label={activeStep === steps.length - 1 ? 'Update' : 'Next >'}
                                            sizeType={activeStep === steps.length - 1 ? 'medium' : 'small'}
                                            disabled={activeStep === 2 && !validStockConfig}
                                            onClick={() => {
                                                if (
                                                    activeStep === 1 &&
                                                    displayError === true &&
                                                    currentProduct.type !== 1
                                                )
                                                    setValidate(addVariantsValidation);
                                                else if (activeStep === 1 && (variants.length > 0 || quantity > 0))
                                                    setValidate(Yup.object().shape({}));
                                                else if (activeStep === 0)
                                                    setValidate(basicInfoValidation(currentProduct.type));
                                                // else if (activeStep === 2) setValidate(productDesignValidation);
                                                else setValidate(Yup.object().shape({}));
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <Alert severity="info" sx={{ mt: 2 }}>
                        <AlertTitle sx={{ color: '#1B5773' }}>INFO</AlertTitle>
                        Product is not pending any more. You can no longer edit this product.
                    </Alert>
                </>
            )}
        </Box>
    );
};

export default EditProduct;
