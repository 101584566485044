/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Button, Modal } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import React, { useState } from 'react';
import { IPayments } from 'services/payments';
import BankPayment from '../bankPayment';
import { IPaymentActions } from '../payments.types';

const PaymentActions = (props: IPaymentActions) => {
    const [ModalDetails, setModalDetails] = useState(false);
    const DetailsModalOpen = () => {
        setModalDetails(true);
    };
    const DetailsModalClose = () => {
        setModalDetails(false);
    };
    const { payment, setRows, currentPage } = props;

    return (
        <>
            <Modal dismissible show={ModalDetails} popup onClose={DetailsModalClose}>
                <Modal.Header>Payment</Modal.Header>
                <Modal.Body>
                    <BankPayment paymentData={payment as IPayments} setRows={setRows} currentPage={currentPage} />
                </Modal.Body>
            </Modal>
            <Button onClick={DetailsModalOpen} pill size="xs">
                <HiInformationCircle className="mr-2 h-5 w-5" color="white" />
                Details
            </Button>
        </>
    );
};
export default PaymentActions;
