/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawler/generalDrawler';
import React, { useEffect, useState } from 'react';
import { TbTruckDelivery } from 'react-icons/tb';
import { getStockShipmentsByStockId, IShippment, IStock, stockStatusMatch } from 'services/stock';
import { IoMdCloseCircle } from 'react-icons/io';
import DataTable from 'components/tables/tables';
import { TableColDef } from 'components/tables/tables.types';
import { Badge, Button } from 'flowbite-react';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { FaRegCopy } from 'react-icons/fa6';
import { copyToClickboard } from 'utils/copyToClickBoard';
import ShippingHistory from '../shippingHistory/shippingHistory';

const statusColorMatchType = {
    1: 'info', // new
    2: 'purple', // ready for pickup
    3: 'pink', // shipping
    30: 'pink', // Stock received by carrier
    31: 'pink', // Sent to GuangZhou Airport
    32: 'pink', // Instation Scan GZ
    33: 'pink', // Arrived in loading warehouse
    34: 'pink', // Sent To HongKong
    35: 'pink', // Instation Scan HK
    36: 'pink', // Departed on Flight
    37: 'pink', // Arrived destination Airport
    38: 'pink', // Customs clearance start
    39: 'pink', // Customs Released_Import
    40: 'pink', // Arrive to headquarters warehouse
    4: 'success', // shipped
};

const ShipmentDetails: React.FC<{ data: IStock }> = ({ data }) => {
    const [shipmentDetails, setShipmentDetails] = useState<IShippment[]>([]);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (data?._id && openDetails) {
            const toastId = toast.loading('Loading stock shipments details data...');
            setIsLoading(true);
            getStockShipmentsByStockId(data?._id)
                .then((res) => {
                    toast.dismiss(toastId);
                    setShipmentDetails(res.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error('Something went wrong', { id: toastId });
                });
        }
    }, [data._id, openDetails]);

    const columns: TableColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Date',
            fieldType: 'render',
            renderCell: (params: IShippment) =>
                params?.createdAt ? <p>{format(new Date(params?.createdAt), 'dd/MM/yyyy')}</p> : <p>-</p>,
        },

        {
            field: 'quantity',
            headerName: 'Qty',
            fieldType: 'render',
            renderCell: (params: IShippment) => (params?.quantity ? <p>{params.quantity}</p> : <p>-</p>),
        },

        {
            field: 'lgstTrackingCode',
            headerName: 'Tracking Code',
            fieldType: 'render',
            renderCell: (params: IShippment) =>
                params?.lgstTrackingCode ? (
                    <div className="flex items-center gap-2">
                        <p className="font-bold">{params?.lgstTrackingCode}</p>
                        <Button
                            size="l"
                            color="white"
                            title="Copy Tracking Code to clipboard"
                            onClick={() => {
                                copyToClickboard(params?.lgstTrackingCode || '', 'Tracking Code');
                            }}
                        >
                            <FaRegCopy className="w-4 h-4" />
                        </Button>
                    </div>
                ) : (
                    <p> -</p>
                ),
        },

        {
            field: 'status',
            headerName: 'Status',
            fieldType: 'render',
            renderCell: (params: IShippment) => {
                const statusValue = params?.status?.toString() as '1' | '2' | '3' | '4';
                return (
                    <Badge color={statusColorMatchType[statusValue]} size="sm" style={{ width: 'fit-content' }}>
                        {stockStatusMatch[statusValue]}
                    </Badge>
                );
            },
        },

        {
            field: 'Details',
            headerName: '',
            fieldType: 'render',
            renderCell: (params) => (
                <>
                    <ShippingHistory data={params} />
                </>
            ),
        },
    ];

    return (
        <>
            <TbTruckDelivery className="w-5 h-5 cursor-pointer" onClick={() => setOpenDetails(true)} />
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Shipping details</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetails(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <div className="flex items-center w-full my-7">
                        <p className="text-xl font-medium">
                            {data?.product?.itemKind === 'variant'
                                ? data?.parentProduct?.restockFor?.name || data?.parentProduct?.name
                                : data?.product?.name}
                        </p>
                        {data?.product?.itemKind === 'variant' && (
                            <p className="text-l font-medium pt-2 pl-2">{data?.product?.name || ''}</p>
                        )}
                    </div>

                    <DataTable
                        rows={shipmentDetails}
                        setRows={setShipmentDetails}
                        columns={columns}
                        pageState={{
                            isLoading,
                            total: 0,
                            pageSize: 0,
                            page: 0,
                            count: 0,
                        }}
                        setPageState={() => {}}
                    />
                </div>
            </GeneralDrawer>
        </>
    );
};
export default ShipmentDetails;
