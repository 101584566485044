import { phoneNumbersLength } from 'utils/helpers/vnlin/phoneNumbersLength';
import * as Yup from 'yup';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const orderUpdateCoordinateSchema = Yup.object().shape({
    firstName: Yup.string().required('required Filed'),
    lastName: Yup.string().optional(),
    address1: Yup.string().required('required Filed'),
    address2: Yup.string().optional(),
    country: Yup.string().required('required Filed'),
    city: Yup.string().required('required Filed'),
    zip: Yup.string().optional(),
    phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone Number required')
        .test(
            'phone-length',
            'Phone number length is not valid for the selected country',
            function validatePhoneLength(value) {
                const country = (this?.parent?.country || '') as keyof typeof phoneNumbersLength;
                const expectedLength = phoneNumbersLength?.[country];
                return expectedLength ? value?.replace(/\D/g, '')?.length === expectedLength : true;
            },
        ),
    totalPrice: Yup.number().required('Orders must have a price'),
    orderRef: Yup.string().optional(),
});
