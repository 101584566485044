import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputFieldProps } from './InputField.types';
import { LabelStyling, StyledTextField } from './InputField.styles';

interface State {
    password: string;
    showPassword: boolean;
}
const InputField: React.FC<InputFieldProps> = ({
    className,
    label,
    onBlur,
    InputType,
    placeholder,
    value,
    name,
    multiline,
    onChange,
    inputSize,
    // inputStyle,
    disabled,
    ref,
}) => {
    const [values, setValues] = React.useState<State>({
        password: '',
        showPassword: InputType !== 'password',
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <Box>
            <Typography variant="body2" sx={LabelStyling}>
                {label}
            </Typography>

            <OutlinedInput
                // id="outlined-adornment-password"
                ref={ref}
                sx={StyledTextField}
                size={inputSize}
                value={value}
                name={name}
                type={values.showPassword ? 'text' : 'password'}
                onChange={onChange}
                onBlur={onBlur}
                multiline={multiline}
                endAdornment={
                    // eslint-disable-next-line no-nested-ternary
                    InputType === 'password' ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : InputType === 'search' ? (
                        <InputAdornment position="end">
                            <IconButton aria-label="search" onMouseDown={handleMouseDownPassword} edge="end">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : null
                }
                placeholder={placeholder}
                className={className}
                disabled={disabled}
            />
        </Box>
    );
};

export default InputField;
