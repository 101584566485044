import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getCustomer = async (id: any) => {
    const config = await getBearerToken();
    const customer = await axios.get(`${BACKEND_URL}/customers/${id}`, config);
    return customer;
};

export const updateCustomer = async (customerId: string, data: any) => {
    const config = await getBearerToken();
    const customerUpdate = await axios.put(`${BACKEND_URL}/customers/${customerId}`, data, config);
    return customerUpdate;
};
