/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { Box, LinearProgress } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import { v4 as uuidv4 } from 'uuid';
import Stepper from '@mui/material/Stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import CustomButton from 'components/customButton/CustomButton';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { addVariantsValidation, basicInfoValidation } from 'utils/validations/productValidation';
import { getCombinations } from 'utils/helpers/product';
import { addProduct } from 'services/product';
import DynamicTitle from 'utils/helpers/DynamicTitle';
import toast from 'react-hot-toast';
import { IProduct } from 'utils/Interfaces/products.interface';
import { logistioCountries } from 'utils/countryList';
import {
    footerButtonsStyle,
    footerContainer,
    footerWrapper,
    sourcingProcessGridStyle,
    sourcingProcessStepDescriptionStyle,
    sourcingProcessStepLabel,
    sourcingProcessStepLabelStyle,
    sourcingProcessStepperStyle,
    sourcingProcessSubtitles,
} from '../Sourcing.styles';
import StockConfiguration from './StockConfiguration';
import 'react-medium-image-zoom/dist/styles.css';
import StepZero from './StepZero';
import StepOne from './StepOne';
import StepThree from './StepThree';

const SourcingProcess = () => {
    const location = useLocation();
    const updatedProduct = location?.state?.updatedProduct as IProduct;
    const navigate = useNavigate();
    const previousPath = useRef(location.pathname);
    const [allImages, setAllImages] = useState<{ file?: File; name?: string; url: string; new?: false }[]>([]);
    const [validate, setValidate] = useState<Yup.AnyObjectSchema | Yup.ArraySchema<any>>();
    const [activeStep, setActiveStep] = useState(0);
    // const collapsed = useOutletContext();
    const [steps, setSteps] = useState<{ label: string; description: string }[]>([]);
    const [progressCount, setProgressCount] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [variants, setVariants] = useState<any>([]);
    const [checkVariants, setCheckVariants] = useState(false);
    const [stockConfigErrors, setStockConfigErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const [variantErrors, setVariantErrors] = useState<any>([]);
    const [allCombinations, setAllCombinations] = useState<string[]>([]);
    const [newVariants, setNewVariants] = useState<any>(
        localStorage.getItem('newVariants') ? JSON.parse(localStorage.getItem('newVariants') || '') : {},
    );
    const [validStockConfig, setValidStockConfig] = useState(false);
    const formRef = useRef<any>();
    const newVref = useRef<any>();
    newVref.current = newVariants;
    const [formikInit, setFormikInit] = useState(
        localStorage.getItem('values')
            ? JSON.parse(localStorage.getItem('values') || '')
            : {
                  productName: '',
                  productLink: '',
                  description: '',
                  address: '',
                  city: '',
                  state: '',
                  zipCode: '',
                  country: '',
                  photo: '',
                  type: '',
                  shippingTo: [],
                  size: '',
                  color: '',
                  quantity: 0,
                  options: {
                      Size: [],
                      Color: [],
                      Material: [],
                      Style: [],
                  },
                  stockConfig: [],
              },
    );
    const [usedOptions, setUsedOptions] = useState<any[]>([]);
    const [initialOptions, setInitialOption] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState(['Size', 'Color', 'Material', 'Style']);
    const [options, setOptions] = useState<{ Size: string[]; Color: string[]; Material: string[]; Style: string[] }>(
        localStorage.getItem('values') && JSON.parse(localStorage.getItem('values') || '').options
            ? JSON.parse(localStorage.getItem('values') || '').options
            : {
                  Size: [],
                  Color: [],
                  Material: [],
                  Style: [],
              },
    );

    useEffect(() => {
        if (localStorage.getItem('values')) {
            const formikOldData = JSON.parse(localStorage.getItem('values') || '');
            if (formikOldData.options) {
                setCheckVariants(true);
                const generatedKeys: any = [];
                Object.keys(formikOldData.options).map((option: string) => {
                    if (formikOldData.options[option].length) generatedKeys.push(option);
                });
                setUsedOptions(generatedKeys);
            }
        }
    }, []);
    DynamicTitle('Logistio Bulk Sourcing');

    useEffect(() => {
        if (updatedProduct) {
            setInitialOption(
                Object.entries(updatedProduct?.options).reduce<string[]>((acc, [key, values]) => {
                    values?.forEach((value: string) => {
                        if (value) {
                            acc.push(`${key}_${value}`);
                        }
                    });
                    return acc;
                }, []),
            );

            setFormikInit({
                productName: updatedProduct?.name || '',
                productLink: updatedProduct?.refrenceLink || '',
                description: updatedProduct?.description || '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                photo: updatedProduct?.design?.[0] || '',
                type: updatedProduct?.type || '',
                shippingTo:
                    (updatedProduct?.shippingTo?.[0].includes(',')
                        ? updatedProduct?.shippingTo?.[0]?.split(',')
                        : updatedProduct?.shippingTo
                    )?.map((elt: any) => {
                        return logistioCountries.find((country: any) => country.label === elt);
                    }) || [],
                size: '',
                color: '',
                quantity: updatedProduct?.quantity || 0,
                options: updatedProduct?.options,
                stockConfig: updatedProduct?.stockConfigs || [],
            });

            setQuantity(updatedProduct?.quantity || 0);
            setCheckVariants(updatedProduct?.variants?.length > 0);
            setUsedOptions(
                Object.entries(updatedProduct?.options)
                    ?.reduce(
                        (acc, [key, elt]) => {
                            if (elt?.length) acc.push(key);
                            return acc;
                        },
                        [''],
                    )
                    ?.filter(Boolean),
            );
            setSelectedOptions(
                Object.entries(updatedProduct?.options)
                    ?.reduce(
                        (acc, [key, elt]) => {
                            if (elt?.length) acc.push(key);
                            return acc;
                        },
                        [''],
                    )
                    ?.filter(Boolean),
            );

            setVariants(updatedProduct?.variants || []);
            setNewVariants(updatedProduct?.variants || []);
            setOptions(updatedProduct.options as any);
            setAllImages(
                updatedProduct?.design.map((elt: any) => {
                    return {
                        url: elt,
                        name: elt,
                        file: elt,
                        new: false,
                    };
                }) || [],
            );
            setVariantErrors([]);
        }
    }, [updatedProduct]);

    useEffect(() => {
        // set logistioBulk form steps
        setSteps([
            {
                label: 'Step 1',
                description: 'Basic Information',
            },
            {
                label: 'Step 2',
                description: `Variants`,
            },
            {
                label: 'Step 3',
                description: `Stock configuration`,
            },
            {
                label: 'Step 4',
                description: 'Product Design',
            },
        ]);
    }, []);

    const calculate = () => {
        const arr = { ...options };
        const x = Object.keys(arr)
            .map((key) => {
                if (arr[key as 'Size' | 'Color' | 'Material' | 'Style']?.length) {
                    const a = arr[key as 'Size' | 'Color' | 'Material' | 'Style'];
                    const h = a.filter((e: any) => e && e.length);
                    return h;
                }
            })
            .filter((e: any) => e && e.length);
        setAllCombinations(getCombinations(x));
    };
    useEffect(() => {
        const data: any = {};
        allCombinations &&
            allCombinations.map((combination) => {
                const variant = (updatedProduct?.variants as IProduct[])?.find(
                    (elt: IProduct) => elt?.name === combination,
                ) as IProduct;
                data[combination as string] = {
                    quantity:
                        variant?.quantity ||
                        newVariants[combination as keyof typeof newVariants]?.quantity ||
                        (localStorage.getItem('newVariants') &&
                            JSON.parse(localStorage.getItem('newVariants') || '')[combination]?.quantity) ||
                        0,
                    disabled: false,
                    key: uuidv4(),
                    ...(updatedProduct && variant
                        ? {
                              productHeight: variant.productHeight,
                              productWeight: variant.productWeight,
                              productWidth: variant.productWidth,
                              productLength: variant.productLength,
                              declarationAmount: variant.declarationAmount,
                              chineseName: variant.chineseName,
                          }
                        : {}),
                };
            });

        setNewVariants({ ...data });
    }, [allCombinations]);

    useEffect(() => {
        calculate();
    }, [options, usedOptions]);

    const productOrVariantsCheckBoxHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckVariants(event.target.checked);
        setNewVariants({});
        setOptions({
            Size: [],
            Color: [],
            Material: [],
            Style: [],
        });
    };

    const submitFn = (values: any) => {
        newVref.current;
        if (activeStep === 0) {
            setActiveStep((s) => s + 1);
        } else if (activeStep === 1 || activeStep === 2) {
            if (variantErrors.filter((value: any) => value === '').length === variantErrors.length) {
                setActiveStep((s) => s + 1);
            }
        } else if (activeStep === steps.length - 1) {
            const toastLoader = toast.loading('Uploading product data in progress...');
            setIsLoading(true);
            const formData = new FormData();
            formData.append('name', updatedProduct ? `${values.productName} - restock` : values.productName);
            allImages.map((file: any) => {
                return formData.append(file.name, file.file);
            });
            formData.append('variants', JSON.stringify(newVariants));
            formData.append('description', values.description);
            formData.append('quantity', String(quantity));
            formData.append('refrenceLink', values.productLink);
            formData.append('shippingTo', values.shippingTo.map((obj: any) => obj.label).join(','));
            formData.append('type', '3');
            formData.append('options', JSON.stringify(options));
            if (updatedProduct) {
                formData.append('restockFor', updatedProduct?._id);
                formData.append('isRestock', 'true');
                formData.append('restockSku', updatedProduct?.globalSKU || '');
                // to upload the images also from updated product design
                formData.append(
                    'previousImage',
                    allImages
                        ?.filter((elt) => !elt.new)
                        ?.map((elt) => elt?.url)
                        ?.toString(),
                );
                updatedProduct?.productHeight && formData.append('productHeight', updatedProduct.productHeight);
                updatedProduct?.productWeight && formData.append('productWeight', updatedProduct.productWeight);
                updatedProduct?.productWidth && formData.append('productWidth', updatedProduct.productWidth);
                updatedProduct?.productLength && formData.append('productLength', updatedProduct.productLength);
                updatedProduct?.declarationAmount &&
                    formData.append('declarationAmount', `${updatedProduct.declarationAmount}`);
                updatedProduct?.chineseName && formData.append('chineseName', updatedProduct.chineseName);
            }
            // Make sure we filter out configs containing empty fields
            const stockConfigData: {
                warehouseId: string;
                variant?: string;
                quantity: string;
                country: 'United Arab Emirates' | 'Qatar' | 'Kuwait' | 'Saudi Arabia' | '';
            }[] = [];
            values.stockConfig.forEach((config: any) => {
                if (Object.values(config).every((field) => !!field)) {
                    stockConfigData.push(config);
                }
            });
            formData.append('stockConfigs', JSON.stringify(stockConfigData));

            // Pending UI: Request progress config
            const config = {
                onUploadProgress: (progressEvent: any) => {
                    const progress = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                    setProgressCount(progress);
                },
                onDownloadProgress: (progressEvent: any) => {
                    const progress = Math.round(50 + (progressEvent.loaded * 50) / progressEvent.total);

                    setProgressCount(progress);
                },
            };

            addProduct(formData, config)
                .then(() => {
                    toast.success('Product Added Succefully', { id: toastLoader });
                    updatedProduct ? navigate('/restock') : navigate('/products');
                    // setTimeout(() => {
                    //     navigate('/products');
                    // }, 2000);
                })
                .catch((err) => {
                    toast.error(err.response.data.errors.message, { id: toastLoader });
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                    setProgressCount(0);
                    localStorage.removeItem('values');
                    localStorage.removeItem('newVariants');
                });
        }
    };

    const handleTabClose = (event: any) => {
        event.preventDefault();
        if (formRef?.current?.values) localStorage.setItem('values', JSON.stringify(formRef.current.values));
        if (newVref.current) localStorage.setItem('newVariants', JSON.stringify(newVref.current));
        // eslint-disable-next-line no-param-reassign
        event.returnValue = 'Are you sure you want to exit?';
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    useEffect(() => {
        return () => {
            if (
                !updatedProduct &&
                window.location.pathname !== previousPath?.current &&
                JSON.parse(localStorage.getItem('values') || '{}')?.productName &&
                // eslint-disable-next-line no-alert
                !confirm('Would you want to preserve the data ?')
            ) {
                localStorage.removeItem('values');
                localStorage.removeItem('newVariants');
            }
        };
    }, []);

    useEffect(() => {
        if (updatedProduct) return;
        if (formRef?.current?.values) localStorage.setItem('values', JSON.stringify(formRef.current.values));
        if (newVref.current) localStorage.setItem('newVariants', JSON.stringify(newVref.current));
    }, [formRef?.current?.values]);

    const resetFormData = (resetForm: any) => {
        localStorage.removeItem('values');
        localStorage.removeItem('newVariants');
        resetForm();
        setOptions({
            Size: [],
            Color: [],
            Material: [],
            Style: [],
        });
        setUsedOptions([]);
        setFormikInit({
            productName: '',
            productLink: '',
            description: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            photo: '',
            type: '',
            shippingTo: [],
            size: '',
            color: '',
            quantity: 0,
            options: {
                Size: [],
                Color: [],
                Material: [],
                Style: [],
            },
            stockConfig: [],
        });
        setAllCombinations([]);
        setNewVariants({});
        setQuantity(0);
        setVariants([]);
        setAllImages([]);
        setErrorMessage('');
        setDisplayError(false);
        setVariantErrors([]);
        setSelectedOptions(['Size', 'Color', 'Material', 'Style']);
        setStockConfigErrors([]);
        setCheckVariants(false);
        setActiveStep(0);
    };
    return (
        <div className="h-full flex flex-col justify-between ">
            <div className="h-full  flex flex-col justify-between">
                <Formik
                    initialValues={formikInit}
                    onSubmit={submitFn}
                    validationSchema={validate}
                    innerRef={formRef}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form className="h-full flex flex-col justify-between">
                            <div>
                                <Grid container spacing={2} sx={sourcingProcessGridStyle}>
                                    <Grid item xs={12} lg={2.5}>
                                        <CustomCard
                                            body={
                                                <Box>
                                                    <Box sx={sourcingProcessSubtitles}>Progress</Box>
                                                    <Stepper
                                                        activeStep={activeStep}
                                                        orientation="vertical"
                                                        sx={sourcingProcessStepperStyle}
                                                    >
                                                        {steps.map((step) => (
                                                            <Step key={step.label}>
                                                                <StepLabel sx={sourcingProcessStepLabel}>
                                                                    <Box sx={sourcingProcessStepLabelStyle}>
                                                                        {step.label}
                                                                    </Box>
                                                                    <Box sx={sourcingProcessStepDescriptionStyle}>
                                                                        {step.description}
                                                                    </Box>
                                                                </StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </Box>
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={9.5}>
                                        {activeStep === 0 ? (
                                            <StepZero formik={formik} isRestock={!!updatedProduct} />
                                        ) : activeStep === 1 ? (
                                            <StepOne
                                                formik={formik}
                                                checkVariants={checkVariants}
                                                productOrVariantsCheckBoxHandleChange={
                                                    productOrVariantsCheckBoxHandleChange
                                                }
                                                options={options}
                                                setOptions={setOptions}
                                                usedOptions={usedOptions}
                                                setUsedOptions={setUsedOptions}
                                                selectedOptions={selectedOptions}
                                                errorMessage={errorMessage}
                                                setErrorMessage={setErrorMessage}
                                                quantity={quantity}
                                                setQuantity={setQuantity}
                                                newVariants={newVariants}
                                                setNewVariants={setNewVariants}
                                                displayError={displayError}
                                                setDisplayError={setDisplayError}
                                                variantErrors={variantErrors}
                                                setVariantErrors={setVariantErrors}
                                                isRestock={!!updatedProduct}
                                                initialOptions={initialOptions}
                                            />
                                        ) : activeStep === 2 ? (
                                            <StockConfiguration
                                                variants={newVariants}
                                                noVariantsQuantity={quantity}
                                                errors={stockConfigErrors}
                                                setErrors={setStockConfigErrors}
                                                setValidStockConfig={setValidStockConfig}
                                                validStockConfigCheck={validStockConfig}
                                                shippingTo={formik?.getFieldProps('shippingTo')?.value}
                                            />
                                        ) : (
                                            <StepThree
                                                isRestock={!!updatedProduct}
                                                formik={formik}
                                                allImages={allImages}
                                                setAllImages={setAllImages}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Box sx={footerWrapper()}>
                                    {isLoading && (
                                        <LinearProgress
                                            variant="determinate"
                                            value={progressCount}
                                            sx={{ width: '100%', margin: '10px 0px' }}
                                        />
                                    )}

                                    <Box sx={footerContainer}>
                                        <Box sx={footerButtonsStyle}>
                                            <CustomButton
                                                sizeType="small"
                                                styleType="tertiary"
                                                label="Cancel"
                                                onClick={() =>
                                                    updatedProduct ? navigate('/products') : navigate('/sourcing')
                                                }
                                            />
                                            <CustomButton
                                                sizeType="small"
                                                styleType="tertiary"
                                                label="Reset"
                                                onClick={() => resetFormData(formik.resetForm)}
                                            />
                                        </Box>
                                        <Box sx={footerButtonsStyle}>
                                            {activeStep > 0 && (
                                                <CustomButton
                                                    sizeType="medium"
                                                    styleType="primary"
                                                    label="< Previous"
                                                    onClick={() => activeStep > 0 && setActiveStep((s) => s - 1)}
                                                />
                                            )}

                                            <CustomButton
                                                type="submit"
                                                styleType="primary"
                                                label={activeStep === steps.length - 1 ? 'Submit request' : 'Next >'}
                                                sizeType={activeStep === steps.length - 1 ? 'medium' : 'small'}
                                                disabled={
                                                    (activeStep === 2 && !validStockConfig) ||
                                                    (activeStep === 1 &&
                                                        (
                                                            Object.values(newVariants) as {
                                                                quantity: number;
                                                                disabled: boolean;
                                                            }[]
                                                        ).some((variant) => variant?.quantity < 1)) ||
                                                    isLoading
                                                }
                                                onClick={() => {
                                                    // const atLeastOneIsNotDisabled = false;
                                                    // for (const key in newVariants) {
                                                    //     if (newVariants[key].disabled === true) {
                                                    //         atLeastOneIsNotDisabled = true;
                                                    //         break;
                                                    //     }
                                                    // }

                                                    // const atLeastOneIsNotDisabled = Object.keys(newVariants).some(
                                                    //     (disabledVariant: any) =>
                                                    //         newVariants[disabledVariant].disabled === false,
                                                    // );

                                                    if (activeStep === 1 && displayError === true) {
                                                        setValidate(addVariantsValidation);
                                                        toast.error(
                                                            'No variants or Quantity has been added, please specify how many items do you want',
                                                        );
                                                        // } else if (
                                                        //     activeStep === 1 &&
                                                        //     // displayError === false &&
                                                        //     Object.keys(newVariants).length > 0 &&
                                                        //     atLeastOneIsNotDisabled &&
                                                        //     location.state.sourcing !== 'dropshipping'
                                                        // ) {
                                                        //     // setValidate(addVariantsValidation);
                                                        //     setNotifType('error');
                                                        //     setNotifMessage(formik.errors.quantity as string);
                                                        //     setOpen(true);
                                                    } else if (
                                                        activeStep === 1 &&
                                                        (variants.length > 0 || quantity > 0)
                                                    )
                                                        setValidate(Yup.object().shape({}));
                                                    else if (activeStep === 0)
                                                        setValidate(basicInfoValidation('logistioBulk'));
                                                    // else if (activeStep === 2) {
                                                    //     setValidate(stockConfigValidation);
                                                    // }
                                                    else setValidate(Yup.object().shape({}));
                                                    // else if (activeStep === 2) setValidate(productDesignValidation);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SourcingProcess;
