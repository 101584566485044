import React, { useState } from 'react';
import { Badge, Modal } from 'flowbite-react';
import { IOrder } from 'services/order';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { actionReason, validActionStatus } from '../Orders.styles';

const CallCenterDetails: React.FC<{ data: IOrder }> = ({ data }) => {
    const [openModal, setOpenModal] = useState(false);

    const centerAgentFeedback = () => {
        if (data.callCenterFeedback && data.callCenterFeedback.length > 0) {
            return data.callCenterFeedback.map((feed, index) => (
                <div key={feed}>
                    {index + 1}: {feed}
                </div>
            ));
        }
        return <div>No feedback from call center agent...</div>;
    };

    return (
        <>
            <Modal
                dismissible
                show={openModal}
                popup
                onClose={() => setOpenModal(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999]',
                        },
                    },
                }}
            >
                <Modal.Header>Call Center Feedback</Modal.Header>
                <Modal.Body className="!p-1 overflow-y-auto ">
                    <div className=" p-2">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Calls Attempts
                                    </th>
                                    <td className="px-6 py-4">
                                        <Badge>{data?.callCenterAttempt ?? '0'}</Badge>
                                    </td>
                                </tr>
                                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Order status action reason
                                    </th>
                                    <td className="px-6 py-4">
                                        {data?.actionReason ? (
                                            <Badge
                                                color={
                                                    validActionStatus.includes(data?.actionReason) ? 'failure' : 'info'
                                                }
                                            >
                                                {actionReason(data?.actionReason)}
                                            </Badge>
                                        ) : (
                                            '--'
                                        )}
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className="w-1/4 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        Center Agent feedbacks
                                    </th>
                                    <td className="px-6 py-4">{centerAgentFeedback()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <MdOutlineSupportAgent className="w-5 h-5 cursor-pointer" onClick={() => setOpenModal(true)} />
        </>
    );
};

export default CallCenterDetails;
