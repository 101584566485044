import React from 'react';
import { ICustomer } from 'utils/Interfaces/customer.interface';
import PersonalInformation from './PersonalInformation';
import ProfilePicture from './profilePicture';

const ProfilePicPart: React.FC<{
    customerId: string;
    customer: ICustomer;
    setCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>;
}> = ({ customer, customerId, setCustomer }) => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            <div className="">
                <p className=" text-xl font-medium">Personal Information </p>
                <p className="text-base dark:text-gray-500 text-gray-600 ">
                    You can modify your personal information by providing your first and last name, uploading a profile
                    image, and adding your phone number.
                </p>
            </div>
            <div className="col-span-2 flex flex-col gap-10">
                <div>
                    <ProfilePicture
                        id={customerId}
                        profImg={customer?.profileImage as string}
                        setCustomer={setCustomer}
                    />
                </div>

                <div>
                    <PersonalInformation
                        firstName={customer?.firstName}
                        lastName={customer?.lastName}
                        phone={customer?.phone}
                        id={customerId}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProfilePicPart;
