/* eslint-disable no-nested-ternary */
import React from 'react';
// import { ButtonProps } from './CustomButton.types';
interface ButtonProps {
    type: 'main' | 'secondary' | 'confirmation';
    children?: React.ReactNode;
    className?: string;
    icon?: any;
    label: string;
    onClick: any;
}
const CustomButton: React.FC<ButtonProps> = ({ type, children, className, icon, label, ...rest }) => {
    const buttonStyles = {
        main: 'bg-main hover:bg-main-hover text-white',
        secondary: 'bg-secondary hover:bg-secondary-hover text-white',
        confirmation: 'bg-confirm hover:bg-confirm-hover text-white',
    };

    return (
        // eslint-disable-next-line react/button-has-type
        <button className={`rounded py-2 px-4 font-medium  ${className} ${buttonStyles[type]}`} {...rest}>
            {children}
            {icon && <img src={icon} alt={label} />}

            {label}
        </button>
    );
};

export default CustomButton;
