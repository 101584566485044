import axios from 'utils/axios/axios';
import { getBearerToken } from 'utils/helpers/auth';
import { IWarning } from 'utils/Interfaces/warnings.interface';

export const checkStocksWarnings = async () => {
    const config = await getBearerToken();
    const response = await axios.get<IWarning[]>(`/warnings/checkLowStocks`, config);
    return response.data;
};

export const getActiveWarnings = async () => {
    const config = await getBearerToken();
    const response = await axios.get<IWarning[]>(`/warnings/getActive`, config);
    return response.data;
};
