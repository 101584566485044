import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from 'utils/helpers/auth';

const PublicRoute = () => {
    const isAuth = isAuthenticated();
    return isAuth ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoute;
