/* eslint-disable no-underscore-dangle */

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CustomCard from 'components/customCard/CustomCard';
import CustomError from 'components/customError/CustomError';
import InputField from 'components/inputField/InputField';
import ImageProofVerification from 'components/imageProofVerfication/imageProofVerification';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { walletVerif } from 'utils/validations/walletVerification';
import { chargeWallet, getWalletOperations } from 'services/wallet';
import { socket } from 'utils/socketIO';
import { getCurrentUser } from 'utils/helpers/auth';
import GeneralButton from 'components/customButton/generalButton';
import toast from 'react-hot-toast';
import { verificationCard } from './wallet.styles';
import { WalletVerifProps } from './wallet.types';

const WalletCardVerification: React.FC<WalletVerifProps> = ({ setOperationList }) => {
    const [verifImage, setVerifImage] = useState<{
        file: File | null;
        name: string;
        // url: URL.createObjectURL(file),
        targetKey: 'verificationImage';
    } | null>(null);
    const [urlImage, setUrlImage] = useState('');
    const [submitLoader, setSubmitLoader] = useState(false);

    const chargeMyWallet = (values: any) => {
        setSubmitLoader(true);
        chargeWallet(values)
            .then((response) => {
                const operationId = response.data.walletOperation._id;
                socket.emit('newWalletOperation', {
                    customer: getCurrentUser()._id,
                    about: 41,
                    walletOperation: operationId,
                });
                setUrlImage('');
                setVerifImage(null);
                toast.success('Amount Request is under Review');
                setSubmitLoader(false);
            })
            .then(() => {
                getWalletOperations({ page: 1, limit: 100 }, {}).then((res) => {
                    setOperationList(res.data.response.docs);
                });
            })
            .catch(() => {
                toast.success('Something went wrong');
                setUrlImage('');
                setSubmitLoader(false);
            });
    };

    return (
        <>
            <Box>
                <CustomCard
                    extraStyle={verificationCard}
                    body={
                        <>
                            <Formik
                                initialValues={{
                                    accountName: '',
                                    amount: '',
                                    verificationImage: '',
                                    status: 1,
                                    operationType: 'IN',
                                    operationCurrency: '',
                                }}
                                onSubmit={(values, onSubmitProps) => {
                                    const formData = new FormData();
                                    formData.append('accountName', values.accountName);
                                    formData.append('amount', values.amount);
                                    formData.append('status', String(values.status));
                                    formData.append('operationType', values.operationType);
                                    formData.append('operationCurrency', values.operationCurrency);
                                    if (verifImage && verifImage.file) {
                                        formData.append(verifImage.name, verifImage.file, verifImage.targetKey);
                                    }

                                    chargeMyWallet(formData);

                                    onSubmitProps.setSubmitting(false);
                                    onSubmitProps.resetForm();
                                }}
                                validationSchema={walletVerif}
                            >
                                {(formik) => (
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <InputField
                                                        inputSize="small"
                                                        label="Payer name"
                                                        placeholder="Payer name"
                                                        InputType="text"
                                                        // returns onChange, onBlur and value related to this input field
                                                        {...formik.getFieldProps('accountName')}
                                                        name="accountName"
                                                    />
                                                    <CustomError name="accountName" component="div" />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <InputField
                                                        inputSize="small"
                                                        label="Amount"
                                                        placeholder="Amount"
                                                        InputType="text"
                                                        // returns onChange, onBlur and value related to this input field
                                                        {...formik.getFieldProps('amount')}
                                                        name="amount"
                                                    />
                                                    <CustomError name="amount" component="div" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
                                                    <div>
                                                        <div>
                                                            <div className="flex justify-between items-center">
                                                                <div className="flex items-center">
                                                                    <Typography variant="subtitle1">
                                                                        Upload Proof of Payment :
                                                                    </Typography>
                                                                    <div>
                                                                        <IconButton
                                                                            color="primary"
                                                                            aria-label="upload picture"
                                                                            component="label"
                                                                        >
                                                                            <input
                                                                                hidden
                                                                                accept="image/png, image/jpg, image/jpeg, .pdf"
                                                                                type="file"
                                                                                name="verificationImage"
                                                                                onChange={(e) => {
                                                                                    const file =
                                                                                        e.target.files &&
                                                                                        e.target.files[0];
                                                                                    if (file) {
                                                                                        formik.setFieldValue(
                                                                                            'verificationImage',
                                                                                            file,
                                                                                        );
                                                                                        const reader = new FileReader();
                                                                                        setUrlImage(
                                                                                            URL.createObjectURL(file),
                                                                                        );
                                                                                        reader.onload = () => {
                                                                                            setVerifImage({
                                                                                                file,
                                                                                                name: file.name,
                                                                                                // url: URL.createObjectURL(file),
                                                                                                targetKey:
                                                                                                    'verificationImage',
                                                                                            });
                                                                                        };
                                                                                        reader.readAsDataURL(file);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <PhotoCamera />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <CustomError name="verificationImage" component="div" />

                                                            <div className=" flex justify-center items-center">
                                                                <ImageProofVerification
                                                                    verifImage={verifImage}
                                                                    urlImage={urlImage}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
                                                    <Typography variant="body2">Currency</Typography>
                                                    <FormControl sx={{ width: '100%' }} size="small">
                                                        <InputLabel id="demo-simple-select-label">
                                                            Select Currency
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Operation Type"
                                                            {...formik.getFieldProps('operationCurrency')}
                                                        >
                                                            <MenuItem value="USD">US Dollar</MenuItem>
                                                            <MenuItem value="EUR">Euro</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <CustomError name="operationCurrency" component="div" />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="flex justify-end">
                                            <GeneralButton
                                                color="blue"
                                                type="submit"
                                                disabled={formik.isSubmitting || submitLoader}
                                                isProcessing={submitLoader}
                                            >
                                                Top up wallet
                                            </GeneralButton>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }
                />
            </Box>
        </>
    );
};

export default WalletCardVerification;
