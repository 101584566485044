/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { trackOrders } from 'services/publicServices/track';
import { IoCloseOutline } from 'react-icons/io5';

interface TrackingNumbersProps {
    trackingCodes: string[];
    setTrackingData: any;
    setTrackingCodes: any;
}
const TrackingNumbers = ({ trackingCodes, setTrackingData, setTrackingCodes }: TrackingNumbersProps) => {
    const [trackingNumbers, setTrackingNumbers] = useState<string[]>(trackingCodes);

    useEffect(() => {
        if (trackingCodes) setTrackingNumbers(trackingCodes);
    }, [trackingCodes]);

    const handleKeyDown = (e: any) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            setTrackingNumbers([...trackingNumbers, '']);
        }
    };
    const handleChange = (e: any, index: number) => {
        const { value } = e.target;
        const newTrackingNumbers = [...trackingNumbers];
        newTrackingNumbers[index] = value;
        setTrackingNumbers(newTrackingNumbers);
    };

    const handleRemove = (index: any) => {
        const newTrackingNumbers = trackingNumbers.filter((_, i) => i !== index);
        setTrackingNumbers(newTrackingNumbers);
    };

    const handleTrackCode = async () => {
        const regex = /^(LGST|LGSTS)\d+$/;
        const validTrackingCodes = trackingNumbers.filter((elt: string) => {
            if (!regex.test(elt) && elt) {
                toast.error(`${elt} doesn't match the required Logistio format`);
                return false;
            }
            if (elt) {
                return true;
            }
            return false;
        });

        const toastId = toast.loading('Getting your tracking data...');
        await trackOrders(validTrackingCodes)
            .then((res) => {
                toast.dismiss(toastId);
                setTrackingData(res.data);
                setTrackingCodes(Object.keys(res.data));
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong!', { id: toastId });
            });
    };

    return (
        <div className="p-4 bg-dark-800 rounded-lg w-96 border border-white h-fit bg-[#FFFFFF] dark:bg-[#020438]">
            <h4 className="text-[#000246] dark:text-white font-work-sans text-sm leading-4 my-2 pb-2">
                Tracking numbers
            </h4>
            <div className="border rounded p-2 h-40 overflow-y-auto bg-dark-900" onKeyDown={handleKeyDown}>
                {trackingNumbers?.map((number, index) => (
                    <div key={index} className="flex items-center justify-between mb-2">
                        <div className="mr-2">
                            <span className="text-xs text-[#C1C8D2] dark:text-[#C1C8D2] font-work-sans ">
                                {index + 1}.
                            </span>
                        </div>
                        <input
                            autoFocus
                            className="dark:bg-transparent text-[#42516B] dark:text-white  border-none  leading-3 outline-none w-full"
                            value={number}
                            onChange={(e) => handleChange(e, index)}
                        />
                        {trackingNumbers?.length > 1 && (
                            <button
                                className="w-4 h-4  rounded-full flex items-center justify-center bg-[#C6CCD7] dark:bg-[#C6CCD7] font-bold text-s text-gray-400 hover:text-red-500"
                                onClick={() => handleRemove(index)}
                            >
                                <IoCloseOutline className="text-white dark:text-[#667085] font-bold text-xs w-13 h-13" />
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <button className="mt-4 w-full py-2 bg-blue-600 text-white rounded" onClick={handleTrackCode}>
                Track
            </button>
        </div>
    );
};

export default TrackingNumbers;
