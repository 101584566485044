import React from 'react';

const QuotationLoadingBlock = () => {
    const loadingBlocks = () => {
        const block = [];
        for (let i = 0; i < 5; i += 1) {
            block.push(
                <>
                    <div
                        key="secondCharginBlocksforQuotation"
                        role="status"
                        className=" p-4 mt-2 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-lg shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
                    >
                        <div className="flex items-center justify-between">
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-14" />
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14" />
                        </div>
                    </div>
                </>,
            );
        }
        return block;
    };

    return (
        <>
            <div className="flex justify-end">
                <div className="w-44 h-10 bg-gray-400 animate-pulse rounded-lg mb-2 " />
            </div>

            <div
                key="charginBlocksforQuotation"
                role="status"
                className=" p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-lg shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-between">
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                    <div className="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12" />
                </div>
            </div>
            {loadingBlocks()}
        </>
    );
};

export default QuotationLoadingBlock;
