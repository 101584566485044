import Drawer from '@mui/material/Drawer';
import React from 'react';
import { GeneralDrawerProps } from './generalDrawer.types';

const GeneralDrawer: React.FC<GeneralDrawerProps> = ({ open, onClose, anchor, children, ...rest }) => {
    return (
        <>
            <Drawer anchor={anchor} open={open} {...rest} onClose={() => onClose(false)}>
                {children}
            </Drawer>
        </>
    );
};

export default GeneralDrawer;
