/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import { Button } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { IProduct } from 'utils/Interfaces/products.interface';
import Modal from 'components/modal/Modal';
import PaymentModal from 'pages/quotation/paymentModal';
import { getCustomer } from 'services/customer';
import { getCurrentUser } from 'utils/helpers/auth';

interface GroupedQuotation {
    firstNonHiddenIdx: number | null;
    country: string;
    options: IProduct['quotation'][number]['options'];
    variants: IProduct['quotation'][number]['variants'];
}

const groupQuotationByCountries = (quotationsData: IProduct['quotation']) => {
    // 1. get available coutries list
    const availableCountries = new Set<string>();
    quotationsData.forEach((quotation) => {
        quotation.options.forEach((opt) => {
            availableCountries.add(opt.country);
        });
    });
    // 2. iterate the quotations data and group it by country (key: country)
    // 2.1 init group
    const groupedQuotationsByCountry = new Map<string, GroupedQuotation>();
    availableCountries.forEach((country) => {
        groupedQuotationsByCountry.set(country, { country, options: [], variants: [], firstNonHiddenIdx: null });
    });

    // 2.2 group quotations by country
    quotationsData.forEach((quotation) => {
        quotation.options.forEach((opt) => {
            const draft = groupedQuotationsByCountry.get(opt.country)!;
            draft.options.push(opt);
            if (quotation.variants.length > 0) {
                draft.variants.push(quotation.variants[0]);
            }
            groupedQuotationsByCountry.set(opt.country, draft);
        });
    });
    // 3. get the first non hidden option for each quotation (used to ui only)
    availableCountries.forEach((country) => {
        const quotation = groupedQuotationsByCountry.get(country);
        if (quotation) {
            quotation?.options.forEach((option, idx) => {
                if (!option.isHidden && quotation.firstNonHiddenIdx === null) {
                    quotation.firstNonHiddenIdx = idx;
                }
            });
            groupedQuotationsByCountry.set(country, quotation);
        }
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resultArr = [...groupedQuotationsByCountry].map(([_key, value]) => value);
    return resultArr;
};

const ProductPayment: React.FC<{
    product: IProduct;
    stylebtn?: string;
    withoutBtn?: boolean;
    openModal?: boolean;
    setopenModal?: any;
}> = ({ product, stylebtn = '', withoutBtn, openModal, setopenModal }) => {
    const [totalPrice, setTotalPrice] = useState<any>();
    const [paymentModal, setPaymentModal] = useState(false);

    const totalPriceSum = () => {
        let groupedQuotations: GroupedQuotation[] = [];
        if (product.quotation && product.quotation.length > 0) {
            groupedQuotations = groupQuotationByCountries(product.quotation);
        }
        // setAcceptedQuotations(groupedQuotations);

        // setCustomerVariants(res.data.product.variants);
        // setProductStatus(res.data.product.status);
        let quotationTotalPrice = 0;
        if (groupedQuotations.length > 0) {
            groupedQuotations.forEach((quotation) => {
                quotation.options.forEach((option, idx) => {
                    if (option.accepted) {
                        quotationTotalPrice += Number(option.unitPrice) * Number(option.quantity);
                    }
                    if (idx === quotation.options.length - 1 && quotation.options.some((opt) => opt.accepted)) {
                        quotationTotalPrice += Number(option.totalShippingLineFee);
                    }
                });
            });
        }
        setTotalPrice(quotationTotalPrice);
        // return quotationTotalPrice;
    };

    const [customerCurrency, setCustomerCurrency] = useState('');

    useEffect(() => {
        getCustomer(getCurrentUser()._id).then((res) => {
            setCustomerCurrency(res?.data.currency);
        });
    }, []);

    useEffect(() => {
        if (!openModal) return;
        totalPriceSum();
        setPaymentModal(true);
    }, [openModal]);
    return (
        <>
            <Modal
                open={paymentModal}
                setOpen={(e) => {
                    setopenModal && setopenModal(e);
                    setPaymentModal(e);
                }}
                body={
                    <PaymentModal
                        totalPrice={totalPrice}
                        product={product.id as string}
                        setOpenModal={(e) => {
                            setopenModal && setopenModal(e);
                            setPaymentModal(e);
                        }}
                        customerCurrency={customerCurrency}
                    />
                }
            />
            {!withoutBtn && (
                <Button
                    size="xs"
                    title="Pay product now"
                    onClick={() => {
                        totalPriceSum();
                        setPaymentModal(true);
                    }}
                    // gradientMonochrome="lime"
                    className={`${stylebtn} !bg-confirm !hover:bg-confirm-hover !focus:bg-confirm-focus`}
                >
                    Pay
                </Button>
            )}
        </>
    );
};

export default ProductPayment;
