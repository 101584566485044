/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Badge, Timeline } from 'flowbite-react';
import { HiCalendar } from 'react-icons/hi';
import moment from 'moment';
import { statusDisplay } from './OrderElements';
import { HistoryProps } from './orders.types';

export const StatusTimeline: React.FC<HistoryProps> = ({ history, order }) => {
    const feedBacksMatch: { [key: string]: number } = {};
    let count = 1;
    history.history.forEach((el) => {
        if (el.newStatus === 3 || el.newStatus === 4) {
            // eslint-disable-next-line no-underscore-dangle
            feedBacksMatch[el._id!] = count;
            count += 1;
        }
    });
    return (
        <div className="realative p-6 overflow-y-auto relative h-full w-full  md:h-auto max-w-2xl">
            <Timeline
                theme={{
                    root: {
                        direction: {
                            vertical: 'relative border-l border-gray-200 dark:border-gray-600',
                        },
                    },
                }}
            >
                <Timeline.Item>
                    <Timeline.Point
                        icon={HiCalendar}
                        theme={{
                            marker: {
                                icon: {
                                    wrapper:
                                        'absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-cyan-200 ring-8 ring-white dark:bg-cyan-900 dark:ring-[#374151]',
                                },
                            },
                        }}
                    />
                    <Timeline.Content>
                        <Timeline.Time>{moment(order?.createdAt).format('DD MMMM YYYY , HH:mm:ss')}</Timeline.Time>
                        <>
                            {history.history[0] ? (
                                <>
                                    <Timeline.Title>
                                        <div className="flex flex-wrap gap-2">
                                            {statusDisplay[history.history[0].oldStatus]?.statusIcon}
                                        </div>
                                    </Timeline.Title>
                                    <Timeline.Body />
                                </>
                            ) : (
                                <>
                                    <Timeline.Title>
                                        <div className="flex flex-wrap gap-2">
                                            {order?.status && statusDisplay[String(order.status)]?.statusIcon}
                                        </div>
                                    </Timeline.Title>
                                    <Timeline.Body />
                                </>
                            )}
                        </>
                    </Timeline.Content>
                </Timeline.Item>
                {history?.history?.map((histo: any, idx) => {
                    return (
                        <Timeline.Item key={histo._id}>
                            <Timeline.Point
                                icon={HiCalendar}
                                theme={{
                                    marker: {
                                        icon: {
                                            wrapper:
                                                'absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-cyan-200 ring-8 ring-white dark:bg-cyan-900 dark:ring-[#374151]',
                                        },
                                    },
                                }}
                            />
                            <Timeline.Content>
                                <Timeline.Time>
                                    {history.history.length - 1 === idx
                                        ? moment(order.updatedAt).format('DD MMMM YYYY , HH:mm:ss')
                                        : moment(histo?.createdAt).format('DD MMMM YYYY , HH:mm:ss')}
                                </Timeline.Time>
                                <Timeline.Title>
                                    <div className="flex flex-wrap gap-2">
                                        {(histo?.newStatus && statusDisplay[histo?.newStatus]?.statusIcon) || (
                                            <Badge color="dark" size="sm">
                                                {`Unsupported Status ${histo?.newStatus}`}
                                            </Badge>
                                        )}
                                    </div>
                                </Timeline.Title>
                                <Timeline.Body>
                                    {history.history.length - 1 === idx && order.statusDescription ? (
                                        <div className="flex flex-wrap gap-2 py-2">
                                            <Badge size="sm">{order.statusDescription}</Badge>
                                        </div>
                                    ) : (
                                        (histo.newStatus === 10 ||
                                            histo.newStatus === 105 ||
                                            histo.newStatus === 11) && (
                                            <div className="flex flex-wrap gap-2 py-2">
                                                <Badge size="sm">{histo.description}</Badge>
                                            </div>
                                        )
                                    )}
                                    {(histo.newStatus === 3 || histo.newStatus === 4) && (
                                        <div className="flex flex-wrap gap-2">
                                            <Badge color="gray">
                                                {feedBacksMatch[histo._id!] && histo?.order?.callCenterFeedback
                                                    ? histo?.order?.callCenterFeedback[feedBacksMatch[histo._id!] - 1]
                                                    : 'Feedback not specified'}
                                            </Badge>
                                        </div>
                                    )}
                                </Timeline.Body>
                            </Timeline.Content>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </div>
    );
};
export default StatusTimeline;
